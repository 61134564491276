/* tslint:disable */
/* eslint-disable */
/**
 * SelmaSME API
 * This definition is the API between customer SME-Gui and the persistence layer for the application NOT FOR V1 When customer logg-in to Selma-EN, we will create a processId with applicant CustomerId (from BankID). When a customer added attachment to the application, we don\'t have a CaseId i LP, should we add a LP-Service to create/reserve a caseId to be able to attach a document to the caseId in DM.
 *
 * The version of the OpenAPI document: 0.7.4
 * Contact: developer@landshypotek.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ChildType,
    ChildTypeFromJSON,
    ChildTypeFromJSONTyped,
    ChildTypeToJSON,
    HouseholdMemberType,
    HouseholdMemberTypeFromJSON,
    HouseholdMemberTypeFromJSONTyped,
    HouseholdMemberTypeToJSON,
} from './';

/**
 * Household should be loosely coupled in the model. We have to support many customerId in a household, this is done by adding customerId to the array customerIds.
 * @export
 * @interface HouseholdType
 */
export interface HouseholdType {
    /**
     * application model unique identification
     * @type {string}
     * @memberof HouseholdType
     */
    processId: string;
    /**
     * Hosuehold identity key
     * @type {string}
     * @memberof HouseholdType
     */
    householdId: string;
    /**
     * array of householdmembers
     * @type {Array<HouseholdMemberType>}
     * @memberof HouseholdType
     */
    householdMembers?: Array<HouseholdMemberType>;
    /**
     * Number of child in this household
     * @type {number}
     * @memberof HouseholdType
     */
    numberOfChildsAtHome?: number;
    /**
     * holds all child in a household
     * @type {Array<ChildType>}
     * @memberof HouseholdType
     */
    childs?: Array<ChildType>;
    /**
     * number of cars in this houshold.
     * @type {number}
     * @memberof HouseholdType
     */
    numberOfCars?: number;
    /**
     * Underhållskostnad för barn i annat hushåll betalande, underhållskostander barn
     * @type {number}
     * @memberof HouseholdType
     */
    childMaintenaceCost?: number;
    /**
     * Erhållen underhållskostnad för barn, erhållen underhållskostnad
     * @type {number}
     * @memberof HouseholdType
     */
    receiveChildMaintenanceCost?: number;
    /**
     * Totala barnomsorgskostnaden per månad.
     * @type {number}
     * @memberof HouseholdType
     */
    childCareAmount?: number;
}

export function HouseholdTypeFromJSON(json: any): HouseholdType {
    return HouseholdTypeFromJSONTyped(json, false);
}

export function HouseholdTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): HouseholdType {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'processId': json['processId'],
        'householdId': json['householdId'],
        'householdMembers': !exists(json, 'householdMembers') ? undefined : ((json['householdMembers'] as Array<any>).map(HouseholdMemberTypeFromJSON)),
        'numberOfChildsAtHome': !exists(json, 'numberOfChildsAtHome') ? undefined : json['numberOfChildsAtHome'],
        'childs': !exists(json, 'childs') ? undefined : ((json['childs'] as Array<any>).map(ChildTypeFromJSON)),
        'numberOfCars': !exists(json, 'numberOfCars') ? undefined : json['numberOfCars'],
        'childMaintenaceCost': !exists(json, 'childMaintenaceCost') ? undefined : json['childMaintenaceCost'],
        'receiveChildMaintenanceCost': !exists(json, 'receiveChildMaintenanceCost') ? undefined : json['receiveChildMaintenanceCost'],
        'childCareAmount': !exists(json, 'childCareAmount') ? undefined : json['childCareAmount'],
    };
}

export function HouseholdTypeToJSON(value?: HouseholdType | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'processId': value.processId,
        'householdId': value.householdId,
        'householdMembers': value.householdMembers === undefined ? undefined : ((value.householdMembers as Array<any>).map(HouseholdMemberTypeToJSON)),
        'numberOfChildsAtHome': value.numberOfChildsAtHome,
        'childs': value.childs === undefined ? undefined : ((value.childs as Array<any>).map(ChildTypeToJSON)),
        'numberOfCars': value.numberOfCars,
        'childMaintenaceCost': value.childMaintenaceCost,
        'receiveChildMaintenanceCost': value.receiveChildMaintenanceCost,
        'childCareAmount': value.childCareAmount,
    };
}


