import React, { Component, useState } from 'react';
import { Case } from '../../../models/SelmaModels';
import { AppComponent } from '../../AppComponent';
import { PageContent, PageStyle } from '../../layout/PageContent/PageContent';
import { T } from '../../widget/display/T';
import { THtml } from '../../widget/display/THtml';
import { AdminCaseStatus } from '../AdminCaseStatus/AdminCaseStatus';

import './AdminPage.scss';
import { AdminCasesSearch } from '../../../models/extendedlpo/Admin';
import { Button } from '../../widget/input/Button/Button';
import { Checkbox } from '../../widget/input/Checkbox/Checkbox';
import { StepState } from '../../case/StepState';
import { AdminCasePage } from '../AdminCasePage/AdminCasePage';
import { FormUnit } from '../../layout/FormUnit/FormUnit';
import { FormUnitRow } from '../../layout/FormUnitRow/FormUnitRow';
import { Heading } from '../../widget/display/Heading/Heading';
import { Textbox } from '../../widget/input/Textbox/Textbox';
import { Label } from '../../widget/display/Label/Label';

interface Props {
	history: {
		push: (string) => void;
	};
}

interface State {
	cases?: Case[];
	case?: Case;
	searching: boolean;
	noMore:boolean;
	search:AdminCasesSearch;
}

export class AdminPage extends AppComponent<Props, State> {

	constructor(props: Props) {
		super(props);
		this.state = {
			searching: false,
			noMore: false,
			search: {
				pageSize: 5,
				pageIndex: 0,
				appliedCases: true,
				ongoingCases: false,
				closedCases: false,
			}
		};
	}

	componentDidMount() {
		this.searchNew();
	}

	searchNew = () => {
		this.state.search.pageIndex = 0;
		this.setState({
			cases: [],
			noMore: false,
		},
			() => {
				this.searchForCases();
			}
		);
	}

	searchMore = () => {
		this.state.search.pageIndex++;
		return this.searchForCases();
	}

	searchForCases = () => {
		this.setState({
			searching: true
		})

		return this.application.services.caseService
		.adminSearchCases(this.state.search)
		.then(result => {
			if( result && result.length ) {
				let cases = this.state.cases;
				if( cases )
					cases = cases.concat(result);
				else 
					cases = result;
				this.setState({
					cases: cases
				})
			}
			this.setState({
				searching: false,
				noMore: result.length<this.state.search.pageSize
			})
			return result;
		}, error => {
			this.setState({
				searching: false
			})
		})
	}

	selectedCase = (c: Case): void => {
		this.setState({
			case: c
		}, () => {
			this.redirectToCase(c);
		})
	};

	redirectToCase(c: Case): any {
		//this.props.history.push('/admin/case');
	}

	searchCaseNumberChanged = (event: React.ChangeEvent<HTMLInputElement>):void => {
		let value = event.target.value;
		let search = this.state.search;
		search.caseNumber = value;
		this.setState({
			search: search
		})
		this.searchNew();
	}

	searchCustomerIdChanged = (event: React.ChangeEvent<HTMLInputElement>):void => {
		let value = event.target.value;
		let search = this.state.search;
		search.customerId = value;
		this.setState({
			search: search
		})
		this.searchNew();
	}

	appliedCasesChanged = (event: React.ChangeEvent<HTMLInputElement>): void => {
		let checked = event.target.checked;
		let search = this.state.search;
		search.appliedCases = checked;
		this.setState({
			search: search
		})
		this.searchNew();
	}

	ongoingCasesChanged = (event: React.ChangeEvent<HTMLInputElement>): void => {
		let checked = event.target.checked;
		let search = this.state.search;
		search.ongoingCases = checked;
		this.setState({
			search: search
		})
		this.searchNew();
	}

	closedCasesChanged = (event: React.ChangeEvent<HTMLInputElement>): void => {
		let checked = event.target.checked;
		let search = this.state.search;
		search.closedCases = checked;
		this.setState({
			search: search
		})
		this.searchNew();
	}

	leftCase = (c:Case) => {
		this.setState({
			case: undefined
		})
	}

	render() {
		const caseService = this.application.services.caseService;

		const c = this.state.case;
		const cases = this.state.cases;

		if( cases && c ) {
			return (
				<AdminCasePage cases={cases} case={c} leaveCase={this.leftCase}/>
			)
		}

		const neverSearched = cases===undefined;
		const hasCases = cases && cases.length > 0 ? true : false;
		const searching = this.state.searching;
		const search = this.state.search;
		const canLoadMore = hasCases && !this.state.noMore;

		return (
			<div className="Page AdminPage">
				<PageContent style={PageStyle.Transparent}>
					<div className="AdminPage_Header">
						<Heading level={1} labelTextkey="Admin_Title"/>
						<p>
							<THtml k="Admin_Description" />
						</p>

						<div>
							<FormUnitRow>
								<span>
									<Label labelText="Ärendenummer:"/>
									<Textbox id="searchCaseNumber" value={this.state.search.caseNumber||''} maxlength={10} handleChange={this.searchCaseNumberChanged}/>
								</span>
								<span>
									<Label labelText="Person-/org.nummer:"/>
									<Textbox id="searchCustomerId" value={this.state.search.customerId||''} maxlength={20} handleChange={this.searchCustomerIdChanged}/>
								</span>
								<span>
									<br/>
									<Button onClick={(e) => {this.searchNew()}} label="Sök"></Button>
								</span>
							</FormUnitRow>
						</div>
						<div>
							<FormUnitRow>
								<Checkbox checked={search.appliedCases} label="Ansökningar" handleChange={this.appliedCasesChanged} />
								<Checkbox checked={search.ongoingCases} label="Startade av kund" handleChange={this.ongoingCasesChanged} />
								<Checkbox checked={search.closedCases} label="Stängda av handläggare" handleChange={this.closedCasesChanged}/>
							</FormUnitRow>
						</div>
					</div>

					<div className="AdminPage_Main">
						<div className="Cases">
							{ !searching && !hasCases && !neverSearched &&
								<Heading level={2} labelTextkey="Admin_NoCasesToHandle"/>
							}

							{ hasCases && cases &&
								cases.map((x) => (
									<div
										key={x.id}
										className={
											'Case ' + (c && c.id == x.id ? 'Highlight' : '')
										}>
										<AdminCaseStatus
											case={x}
											selectedCase={this.selectedCase}
										/>
									</div>
								))
							}

							{ searching &&
								<div>
									<T k="Admin_SearchingForCases" />
								</div>
							}

							{canLoadMore &&
								<div>
									<br/>
									<br/>
									<Button className="Button full-width" onClick={(e) => {this.searchMore()}} label="Ladda fler" enabled={!searching}></Button>
								</div>
							}
						</div>
					</div>
				</PageContent>
			</div>
		);
	}
}
