import React from 'react';
import {
	Case,
	HouseholdMember,
} from '../../../../../models/SelmaModels';
import { AppComponent } from '../../../../AppComponent';
import { T } from '../../../../widget/display/T';
import {
	FormUnit,
	FormUnitSize,
	FormUnitPadding,
} from '../../../../layout/FormUnit/FormUnit';
import Table from '../../../../widget/display/Table/Table';
import { Heading } from '../../../../widget/display/Heading/Heading';
import { UiModelConverter } from '../../../../../models/UiModelConverter';

interface Props {
	householdMembers: HouseholdMember[] | undefined;
	case: Case;
}

export class HouseholdMembersSummary extends AppComponent<Props> {
		applicantService  = this.application.services.applicantService; //(c: Case, ssn:string): Applicant | undefined {
	
	constructor(props: Props) {
		super(props);
	}

	getHouseholdMember(member:string | undefined) {

		if (!member) return [];

		const c = this.props.case;
		const applicant = this.applicantService.getApplicantBySsn(
			c,
			member
		);
		if (!applicant) return [];

		return [`${applicant.firstName||''} ${applicant.lastName||''}`, UiModelConverter.prettySsn(applicant.customerId)];
	}

	render() {
		const m = this.props.householdMembers;
		if (!m) return null;

		return (
			<FormUnit size={FormUnitSize.Large} padding={FormUnitPadding.Normal}>
				<Heading level={3} labelTextkey="Summary_Household_Members"/>
				<Table
					headingRow={[
						<T key="Name" k="Summary_Household_Member_Name" />,
						<T key="SSN" k="Summary_Household_Member_SSN" />,
					]}
					tableRows={m.map(({ householdMember }) =>
						this.getHouseholdMember(householdMember)
					)}
				/>
			</FormUnit>
		);
	}
}
