import React from 'react';
import ClassNames from 'classnames';
import { Checkbox } from '../Checkbox/Checkbox';
import { BaseComponent } from '../../../BaseComponent';
import { uniqueId } from 'lodash';

import './CheckboxGroup.scss';

export interface CheckboxOption {
	value?: string;
	checked?: boolean;
	label?: string;
	labelTextkey?: string;
	enabled?: boolean;
}

interface Props {
	className?: string;
	enabled?: boolean;
	handleBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
	handleChange: (value: string[]) => void;
	options: CheckboxOption[];
	orientation?: string;
	value: string[];
	hasErrors?: boolean;
	touched?: boolean;
	dirty?: boolean;
}

export class CheckboxGroup extends BaseComponent<Props> {
	static defaultProps = {
		orientation: 'horizontal',
		className: 'CheckboxGroup',
	};

	id: string;

	constructor(props: Props) {
		super(props);

		this.id = uniqueId('checkboxGroup_');
	}

	handleChange = (event) => {
		const target = event.currentTarget;
		const valueArray = [...this.props.value] || [];

		if (target.checked) {
			valueArray.push(target.value);
		} else {
			valueArray.splice(valueArray.indexOf(target.value), 1);
		}

		this.props.handleChange(valueArray);
	}

	isChecked = (value?: string) => {
		if (!value) return undefined;
		return this.props.value.indexOf(value) > -1 ? true : false;
	}

	render() {

		const classNames = ClassNames(
			this.props.className,
			{ Horizontal: this.props.orientation === 'horizontal' },
			{ Vertical: this.props.orientation === 'vertical' }
		);

		return (
			<div className={classNames}>
				{this.props.options.map((checkbox, index) => {
					return (
						<Checkbox
							key={`${this.id}_checkbox_${index}`}
							value={checkbox.value}
							label={checkbox.label}
							labelTextkey={checkbox.labelTextkey}
							handleChange={this.handleChange}
							handleBlur={this.props.handleBlur}
							enabled={checkbox.enabled}
							checked={this.isChecked(checkbox.value)}
							hasErrors={this.props.hasErrors}
							touched={this.props.touched}
							dirty={this.props.dirty}
						/>
					);
				})}
			</div>
		);
	}
}

