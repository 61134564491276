import React from 'react';
import ClassNames from 'classnames';
import './PageContent.scss';

export enum PageStyle {
	Gray,
	Transparent,
	Blue
}

interface Props {
	style: PageStyle;
	className?: string;
}

export const PageContent: React.FunctionComponent<Props> = (props) => {
	const classNames = ClassNames(
		'PageContent',
		props.className,
		PageStyle[props.style]
	);

	return <div className={classNames}>{props.children}</div>;
};
