import React from 'react';
import {
	Case,
	Company,
	Budget,
	BudgetYear,
} from '../../../../../models/SelmaModels';
import { AppComponent } from '../../../../AppComponent';
import NumberFormat from 'react-number-format';
import { uiBudgetYearItems } from '../../../../../models/uimodels/UIBudgetYearItem';
import { T } from '../../../../widget/display/T';
import './ManualBudgetSummary.scss';
import { getUIName } from '../../../../../models/uimodels/UINamed';

interface Props {
	case: Case;
	company: Company;
	budget: Budget;
}

export class ManualBudgetSummary extends AppComponent<Props> {
	constructor(props: Props) {
		super(props);
	}

	renderRow = (bys: BudgetYear[], col: number, rowType: string) => {

		let columnName = 'value' + col;

		let hasValue = false;
		if( bys ) {
			for( let i = 0; i<bys.length; i++ ) {
				let by = bys[i];
				let value = by[columnName];
				if( value===undefined)
					continue;
				else {
					hasValue = true;
					break;
				}
			}
		}
		if( !hasValue )
			return null;

		const textService = this.application.services.textService;
		const uq = this.application.services.idService.newIdString();
		return (
			<tr key={uq + '+col'} className={rowType === 'value' ? 'ValueRow' : 'CalcRow'}>
				<td className="LabelCell">{getUIName(uiBudgetYearItems[col - 1], textService)}</td>
				{bys &&
					bys.map((x, ix) => {
						let id = uq + 'byv_' + ix + '_' + x.year;
						let value = x[columnName];
						return (
							<td key={id}>
								<NumberFormat
									id={id + '_money'}
									value={value}
									thousandSeparator=" "
									suffix=" kr"
									displayType="text"
								/>
							</td>
						);
					})}
			</tr>
		);
	};


	render() {
		let company = this.props.company;
		let bys = this.props.budget.budgetYears || [];

		let row = this.renderRow;

		return (
			<div className="ManualBudgetSummary">
				<table>
					<thead>
						<tr>
							<th className="LabelCell">
								<T k="Budget_BudgetFor" /> {company.name}
							</th>
							{bys.map((x) => {
								return <th key={x.year}>{x.year}</th>;
							})}
						</tr>
					</thead>
					<tbody>
						{row(bys, 1, 'value')}
						{row(bys, 2, 'value')}
						{row(bys, 3, 'value')}
						{row(bys, 4, 'value')}
						{row(bys, 5, 'value')}
						{row(bys, 6, 'value')}
						{row(bys, 7, 'value')}
						{row(bys, 8, 'sum')}
						{row(bys, 9, 'value')}
						{row(bys, 10, 'value')}
						{row(bys, 11, 'value')}
						{row(bys, 12, 'value')}
						{row(bys, 13, 'sum')}
						{row(bys, 14, 'sum')}
						{row(bys, 15, 'value')}
						{row(bys, 16, 'value')}
						{row(bys, 17, 'sum')}
						{row(bys, 18, 'sum')}
						{row(bys, 19, 'value')}
						{row(bys, 20, 'value')}
						{row(bys, 21, 'sum')}
						{row(bys, 22, 'value')}
						{row(bys, 23, 'value')}
						{row(bys, 24, 'value')}
						{row(bys, 25, 'sum')}
					</tbody>
				</table>
			</div>
		);
	}
}
