/* tslint:disable */
/* eslint-disable */
/**
 * SelmaSME API
 * This definition is the API between customer SME-Gui and the persistence layer for the application NOT FOR V1 When customer logg-in to Selma-EN, we will create a processId with applicant CustomerId (from BankID). When a customer added attachment to the application, we don\'t have a CaseId i LP, should we add a LP-Service to create/reserve a caseId to be able to attach a document to the caseId in DM.
 *
 * The version of the OpenAPI document: 0.7.4
 * Contact: developer@landshypotek.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BudgetYearType,
    BudgetYearTypeFromJSON,
    BudgetYearTypeFromJSONTyped,
    BudgetYearTypeToJSON,
} from './';

/**
 * Type definition for companyBudget
 * @export
 * @interface BudgetType
 */
export interface BudgetType {
    /**
     * application model unique identification
     * @type {string}
     * @memberof BudgetType
     */
    processId: string;
    /**
     * Type definition for company economy
     * @type {string}
     * @memberof BudgetType
     */
    companyEconomyId?: string;
    /**
     * Budget years
     * @type {Array<BudgetYearType>}
     * @memberof BudgetType
     */
    budgetYears?: Array<BudgetYearType>;
}

export function BudgetTypeFromJSON(json: any): BudgetType {
    return BudgetTypeFromJSONTyped(json, false);
}

export function BudgetTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): BudgetType {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'processId': json['processId'],
        'companyEconomyId': !exists(json, 'companyEconomyId') ? undefined : json['companyEconomyId'],
        'budgetYears': !exists(json, 'budgetYears') ? undefined : ((json['budgetYears'] as Array<any>).map(BudgetYearTypeFromJSON)),
    };
}

export function BudgetTypeToJSON(value?: BudgetType | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'processId': value.processId,
        'companyEconomyId': value.companyEconomyId,
        'budgetYears': value.budgetYears === undefined ? undefined : ((value.budgetYears as Array<any>).map(BudgetYearTypeToJSON)),
    };
}


