import React from 'react';
import { CompanyEconomy, Case } from '../../../../models/SelmaModels';
import { Company } from "../../../../models/selmamodels/Company";
import { AppComponent } from '../../../AppComponent';
import { Label } from '../../../widget/display/Label/Label';
import { RadiobuttonGroup } from '../../../widget/input/RadiobuttonGroup/RadiobuttonGroup';
import { Validation } from '../../../widget/display/Validation/Validation';

interface Props {
	case: Case;
	company: Company;
	companyEconomy: CompanyEconomy;
	hasRevenueChanged: (value:boolean|undefined, CompanyEconomy: CompanyEconomy) => void;
}

const hasRevenueOptions = [
	{
		group: 'hasRevenue',
		id: 'ja',
		label: 'Ja',
		value: 'ja',
	},
	{
		group: 'hasRevenue',
		id: 'nej',
		label: 'Nej',
		value: 'nej',
	},
];


export class CompanyHasRevenue extends AppComponent<Props> {
	companyEconomyService = this.application.services.companyEconomyService;

	constructor(props: Props) {
		super(props);

		this.observeUpdate(this.props.company);
		this.observeUpdate(this.props.companyEconomy);
	}

	hasRevenueChanged = (value: string) => {
		const hasRevenue = value === 'ja';
		const hasNotRevenue = value === 'nej';

		let company = this.props.company;
		let companyEconomy = this.props.companyEconomy;

		let companyEconomyService = this.companyEconomyService;
		if( hasNotRevenue ) {
			companyEconomyService.setNoRevenueYears(companyEconomy);
		} else if ( hasRevenue ) {
			let companyService = this.application.services.companyService;
			companyEconomyService.ensureRequiredRevenueYears(companyEconomy);
			companyService.ensureMinimumBusinessFocuses(company);
		}

		if( this.props.hasRevenueChanged)
			this.props.hasRevenueChanged(hasRevenue, this.props.companyEconomy);
	};

	render() {
		let companyEconomy = this.props.companyEconomy;
		let hasRevenue:boolean|undefined = this.companyEconomyService.hasRevenue(companyEconomy);

		let hasRevenueValue: string | undefined;
		if (hasRevenue === true) {
			hasRevenueValue = 'ja';
		} else if (hasRevenue === false) {
			hasRevenueValue = 'nej';
		}

		return (
			<>
				<Label labelTextkey="Company_HasRevenue" required={true} />
				<Validation
					forObject={companyEconomy}
					errors={[
						{
							validator: this.companyEconomyService.hasRevenueSelected,
							textkey: 'Error_SelectValue',
						},
					]}>
					<RadiobuttonGroup
						handleChange={this.hasRevenueChanged}
						options={hasRevenueOptions}
						value={hasRevenueValue}
					/>
				</Validation>
			</>
		);
	}
}
