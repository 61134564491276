import React from 'react';
import { Application } from '../../../models/AppModels';
import './App.scss';
import { Grid } from "react-flexbox-grid";
import Bottom from '../../layout/Bottom/Bottom';
import PageRouter from '../PageRouter';
import { LoadingPage } from './../LoadingPage';
import { AppComponent } from '../../AppComponent';
import { BrowserRouter } from 'react-router-dom';
import Top from '../../layout/Top/Top';
import ReactBreakpoints from 'react-breakpoints';
import { Case } from '../../../models/SelmaModels';
import ScrollToTop from '../ScrollToTop';
import EbbotChat from './EbbotChat';
import { AuthProvider } from "react-oidc-context";
import Oidc from "../../login/Oidc";
interface Props { }

interface State {
	application: Application;
	loaded: boolean;
}

export const Breakpoints = {
	xxs: 0,
	xs: 492,
	smxs: 515,
	sm: 726,
	md: 980,
	lg: 1280,
	xl: 1690,
};



export default class App extends AppComponent<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			application: Application.alloc(),
			loaded: false,
		};
		this.state.application.init()
			.then((app) => {
				this.setState({ loaded: true });
				this.observeUpdate(app.services.stateService.state);
				this.observeUpdate(app.services.stateService.state.session);
				this.observeUpdate(app.services.stateService.state.session.login);
				this.observeUpdate(app.services.stateService.state.cases);
			});
	}

	render() {
		const stateService = this.state.application.services.stateService;
		const sessionService = this.state.application.services.sessionService;
		const configurationService = this.state.application.services.configurationService;
		const textService = this.state.application.services.textService;

		const loaded = stateService && stateService.state.loaded ? true : false;
		if (!loaded) return <LoadingPage />;
		const loggedIn = sessionService && sessionService.isLoggedIn();
		const loggedOut = sessionService && sessionService.didLogOut();
		const isAdmin = sessionService && sessionService.isAdmin();
		const maintenanceModeFromNils = textService.textOrDefault('Settings_MaintenanceMode', '') === '1';
		const maintenanceMode = configurationService.maintenanceMode() || maintenanceModeFromNils;
		const ebbotEnabled = configurationService.ebbotChatEnabled();
		const ebbotBotId = configurationService.ebbotChatBotId();
		const oidcSettings = configurationService.oidcSettings();
		const useLocalLogin = configurationService.useLocalLogin();

		const removePayLoadFromUri = () => { window.history.replaceState({},document.title,window.location.pathname) };
		
		const oidcConfig = {
			authority: oidcSettings.authority,
			client_id: oidcSettings.clientId,
			redirect_uri: oidcSettings.redirectUri,
			automaticSilentRenew: oidcSettings.automaticSilentRenew,
            scope: oidcSettings.scope,
            monitorSession: true,
			fetchRequestCredentials: oidcSettings.fetchRequestCredentials
		};
		let cases;
		if (loggedIn && stateService.state.cases) {
			cases = stateService.state.cases;
		}

		let c: Case | undefined;
		if (loggedIn && stateService.state.case) {
			c = stateService.state.case;
		}

		let canSignIn = false;
		if(loggedIn && sessionService)
			canSignIn = sessionService.getLogin().canSignIn;

		return (
			<>
				{loaded && <EbbotChat enabled={ebbotEnabled} botId={ebbotBotId} />}
				<div className="App">
				<AuthProvider onSigninCallback={removePayLoadFromUri} {...oidcConfig}>
					<ReactBreakpoints breakpoints={Breakpoints}>
						<BrowserRouter>
							<ScrollToTop>
								{!useLocalLogin && <Oidc />}
								<Grid fluid={true} className="PageContainer">
									{loaded && <Top />}
									<PageRouter
										loaded={loaded}
										maintenanceMode={maintenanceMode}
										loggedIn={loggedIn}
										loggedOut={loggedOut}
										isAdmin={isAdmin}
										cases={cases}
										case={c}
										canSignIn={canSignIn}
										useLocalLogin={useLocalLogin}
									/>
									{loaded && <Bottom />}
								</Grid>
							</ScrollToTop>
						</BrowserRouter>
					</ReactBreakpoints>
				</AuthProvider>
				</div>
			</>
		);
	}
}

