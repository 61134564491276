import { BaseService } from './BaseService';
import { BusinessCategory, Case, Applicant } from '../models/SelmaModels';
import { Company, Principal } from "../models/selmamodels/Company";
import { SMELPOService } from './SMELPOService';
import { StateService } from './StateService';
import { ValidationResult } from '../utils/ValidationResult';
import { GuardService } from './GuardService';
import { CompanyEconomyService } from './CompanyEconomyService';
import { IdService } from './IdService';
import { ApplicantService } from './ApplicantService';
import { ConvertService } from './ConvertService';
import { UiModelConverter } from '../models/UiModelConverter';

/*
Handles all logic for Company.
*/
export class CompanyService extends BaseService {

	companyEconomyService?:CompanyEconomyService;

	constructor(private stateService: StateService, 
		private smelpoService: SMELPOService,
		private guardService: GuardService, 
		private idService:IdService,
		private applicantService: ApplicantService,
		private convertService:ConvertService) {
		super();
	}

	update(company: Company|BusinessCategory|Case): void {
		this.stateService.update(company);
	}

	normalizeOrgnr(orgNr:string):string {
		let normalizedOrgNr = orgNr.trim().replace(/-/g,'');
		return normalizedOrgNr;
	}

	compareOrgnr(a:string, b:string):boolean {
		return this.normalizeOrgnr(a)===this.normalizeOrgnr(b);
	}

	getDisplayName(company: Company): string {
		let name:string = "";
		if( company.name ) {
			name = company.name;
		} else if( company.orgNumber ) {
			name = UiModelConverter.prettyOrgNr(company.orgNumber);
		} else {
			name = "ID " + company.id;
		}
		return name;
	}

	addCompany(c:Case, company:Company) : Company {

		this.ensureMinimumBusinessFocuses(company);

		let existingCompany = this.getCompanyByOrgnr(c, company.orgNumber);
		if( !existingCompany ) {
			if( !c.companies )
				c.companies = [company];
			else 
				c.companies.push(company);
			
			this.update(company);
			this.update(c);
			return company;
		}
		else 
			return existingCompany;
	}

	removeCompany(c:Case, company:Company) {
		if( c.companies ) {
			this.stateService.listRemove(c.companies, company);
			if( this.companyEconomyService ) {
				let ce = this.companyEconomyService.getCompanyEconomyByCompanyId(c, company.id);
				if( ce ) {
					this.companyEconomyService.removeCompanyEconomy(c, ce);
				}
			}
			if( c.attachmentRequests ) {
				var attachmentRequestsToRemove = c.attachmentRequests.filter(ar => {
					if( !ar.customerId || !company.orgNumber )
						return false;
					return this.guardService.compareCustomerId(ar.customerId, company.orgNumber);
				});

				if( attachmentRequestsToRemove && attachmentRequestsToRemove.length>0 ) {
					attachmentRequestsToRemove.forEach(ar => {
						if( c.attachmentRequests )
							this.stateService.listRemove(c.attachmentRequests, ar);
					})
				}
			}

			this.update(c);
		}
	}

	getCompanyByOrgnr(c:Case, orgNr:string|undefined): Company|undefined {
		if( !c.companies || c.companies.length===0 || !orgNr)
			return undefined;
		return this.getCompanyByOrgnrInArray(c.companies, orgNr);
	}

	getCompanyByOrgnrInArray(companies:Company[], orgNr:string): Company|undefined {
		if( !companies || companies.length===0)
			return undefined;
		const g = this.guardService;
		const orgNrIsAlsoSsn = g.isValidSsn(orgNr);
		orgNr = this.normalizeOrgnr(orgNr);

		let r = companies.filter(x => {
			if( !x.orgNumber )
				return false;
			const n = this.normalizeOrgnr(x.orgNumber);
			if( !n )
				return false;
			if( n===orgNr )
				return true;
			
			// Special case when matching 
			if( orgNrIsAlsoSsn && g.isValidSsn(x.orgNumber)) {
				const nOrgNr = this.convertService.toNormalizedSsn(orgNr);
				const nOrgNumber = this.convertService.toNormalizedSsn(x.orgNumber);
				const eq = g.compareSsn(nOrgNr, nOrgNumber);
				if( eq )
					return true;
			}

			return false;
		});
		if( r.length===1 )
			return r[0];
		else
			return undefined;
	}

	getCompanyById(c: Case, companyId: string): Company|undefined {
		if( !c.companies || c.companies.length===0 || !companyId)
			return undefined;
		return this.getCompanyByIdInArray(c.companies, companyId);
	}

	getCompanyByIdInArray(companies:Company[], companyId:string): Company|undefined {
		if( !companies || companies.length===0)
			return undefined;
		let r = companies.filter(x => {
				return x.id===companyId;
		});
		if( r.length===1 )
			return r[0];
		else
			return undefined;
	}

	addCompaniesToArrayByOrgnr(companies: Company[], availableCompanies: Company[]) : void {
		if( !availableCompanies || availableCompanies.length===0)
			return;
		availableCompanies.forEach(x => {
			if( !x.orgNumber )
				return;
			let existingCompany = this.getCompanyByOrgnrInArray(companies, x.orgNumber);
			if( !existingCompany )
				companies.push(x);
		});
	}

	getApplicantCompany(c:Case, applicant: Applicant) : Company | undefined {
		let applicantCompany = this.getCompanyByOrgnr(c, applicant.customerId);
		if( !applicantCompany ) {
			let companies = this.getSelectedCompanies(c, applicant);
			if( companies && companies.length>0 ) {
				return companies[0];
			}
		}
		return applicantCompany;
	}

	getMySelectedCompanies(c: Case): Company[] | undefined {
		const applicant = this.applicantService.getMyApplicant(c);
		return this.getSelectedCompanies(c, applicant);
	}

	
	getSelectedCompanies(c: Case, applicant:Applicant): Company[] | undefined {
		if( !c.companies )
			return undefined;
		else {
			let r = this.getSelectedCompaniesInArray(c.companies, applicant);
			return r;
		}
	}

	getMySelectedCompany(c: Case): Company | undefined {
		const mySelectedCompanies = this.getMySelectedCompanies(c);
		if( mySelectedCompanies && mySelectedCompanies.length>0 ) {
			return mySelectedCompanies[0];
		}
		return;
	}

	getSelectedCompaniesInArray(companies: Company[], applicant:Applicant): Company[] | undefined {
		if( !companies || companies.length===0)
			return undefined;

		let r = companies.filter(x => {
			return this.isSelectedByApplicant(x, applicant);
		});
		if( r.length>0 )
			return r;
		else
			return undefined;
	}

	isSelectedByApplicant(company:Company, applicant:Applicant):boolean {
		let r = this.getPrincipalByCustomerId(company,applicant.customerId);
		return r?true:false;
	}

	getPrincipalByCustomerId(company:Company,customerId:string):Principal|undefined {
		if( !company.principals )
			return;
		const g = this.guardService;
		let r = company.principals.find(x => g.compareSsn(x.customerId||'',customerId));
		return r;
	}

	addPrincipalFromApplicant(company:Company, applicant:Applicant):Principal {
		if( !company.principals )
			company.principals = [];
		else {
			let p = this.getPrincipalByCustomerId(company, applicant.customerId);
			if( p )
				return p;
		}
		let principal = {
			customerId:applicant.customerId,
			name: this.applicantService.getPersonFullName(applicant)
		}
		company.principals.push(principal);
		return principal;
	}

	validateHasSelectedCompany(c: Case) : ValidationResult {

		let vr = this.validateHasCompanies(c);
		if( vr.failed() )
			return vr;

		vr.add({validator:this.hasSelectedCompany, object:c, ok: this.hasSelectedCompany(c)});
		return vr;
	}


	isPhysicum(company: Company):boolean {
		return company.legalGroupCode!==undefined && company.legalGroupCode!==null && company.legalGroupCode==="EF";
	}

	isJuridicum(company: Company):boolean {
		return company.legalGroupCode!==undefined && company.legalGroupCode!==null && company.legalGroupCode!=="EF";
	}

	anyPhysicumCompanies(companies: Company[]):boolean {
		return companies.filter(x => {
			return this.isPhysicum(x);
		}).length>0;
	}

	hasCompanies(c: Case): boolean {
		return c.companies?true:false;
	}
	validateHasCompanies(c: Case): ValidationResult {
		var vr = new ValidationResult();
		vr.add({ validator:this.hasCompanies, object: c, ok: this.hasCompanies(c) });
		return vr;
	}

	hasMinimumBusinessFocuses(company: Company):boolean {
		if( company.businessFocuses && company.businessFocuses.length>0 )
			return true;
		return false;
	}

	ensureMinimumBusinessFocuses(company: Company) {
		if( !this.hasMinimumBusinessFocuses(company)) {
			this.addNewBusinessFocus(company);
		}
	}

	setBusiniessFocusesPart(company: Company, businessCategory: BusinessCategory, value: number|undefined) {

		if( value!==undefined && company.businessFocuses && company.businessFocuses.length===2) {
			let otherBusinessFocus = company.businessFocuses[0]===businessCategory?company.businessFocuses[1]:company.businessFocuses[0];
			otherBusinessFocus.businessPart = 100-value;
			this.updateBusinessFocus(company, otherBusinessFocus);
		}

		businessCategory.businessPart = value;
		this.updateBusinessFocus(company, businessCategory);
	}

	getPossibleMainDirectionBusinessFocuses(company:Company) : BusinessCategory[] {
		if( !company.businessFocuses )
			return [];
		if( company.businessFocuses.length<2 )
			return [];

		const maxBusinessPart = company.businessFocuses.map(x => x.businessPart||0).reduce((agg,x) => Math.max(agg,x), 0);
		const countMaxBusinessPart = company.businessFocuses.filter(x => x.businessPart===maxBusinessPart);
		if( countMaxBusinessPart.length>=2 )
			return countMaxBusinessPart;
		
		return [];
	}

	needsToSpecifyMainDirectionForBusiniessFocuses(company:Company) : boolean {
		return this.getPossibleMainDirectionBusinessFocuses(company).length>1;
	}

	hasSpecifiedMainDirectionForBusiniessFocuses(company:Company) : boolean {
		let r = this.getPossibleMainDirectionBusinessFocuses(company).filter(x => x.mainDirection===true);
		if( r.length===1 )
			return true;
		return false;
	}

	setBusinessFocusMainDirection(company:Company, businessFocus:BusinessCategory):void {
		if( !company.businessFocuses )
			return;

		let changed = false;
		company.businessFocuses.forEach(x => {
			const currentMainDirection = x.mainDirection===true;
			const newMainDirection = x===businessFocus;
			if( currentMainDirection!==newMainDirection ) {
				x.mainDirection = newMainDirection;
				this.update(x);
				changed = true;
			}
		});

		if( changed ) {
			this.update(company);
		}
	}

	validateHasMinimumBussinessFocuses(company: Company): ValidationResult {
		let vr = new ValidationResult();
		vr.add({validator: this.hasMinimumBusinessFocuses, object: company, ok: this.hasMinimumBusinessFocuses(company) })
		return vr;
	}

	businessFocusHasCategory(businessFocus: BusinessCategory) :boolean {
		const g = this.guardService;
		return g.hasValue(businessFocus.businessCategoryCode);
	}

	businessFocusHasBusinessPart(businessFocus: BusinessCategory) :boolean {
		const g = this.guardService;
		return g.isGreaterThan(businessFocus.businessPart, 0);
	}

	companyBusinessFocusBusinessPartSumIsCorrect(company:Company, sum:number) : boolean {
		return sum===100;
	}

	validateBussinessFocuses(company: Company): ValidationResult {
		let vr = new ValidationResult();
		if( company.businessFocuses ) {
			company.businessFocuses.forEach(x => {
				vr.add({validator: this.businessFocusHasCategory, object: x, ok: this.businessFocusHasCategory(x) })
			})

			let businessPartSum = 0;
			company.businessFocuses.forEach(x => {
				vr.add({validator: this.businessFocusHasBusinessPart, object: x, ok: this.businessFocusHasBusinessPart(x) })
				const businessPart = x.businessPart?x.businessPart:0;
				businessPartSum += businessPart;
			});
			vr.add({validator: this.companyBusinessFocusBusinessPartSumIsCorrect, object: company, args:[businessPartSum], 
				ok: this.companyBusinessFocusBusinessPartSumIsCorrect(company, businessPartSum) });

			if( this.needsToSpecifyMainDirectionForBusiniessFocuses(company) ) {
				let hasSpecifiedMainDirectionForBusiniessFocuses = this.hasSpecifiedMainDirectionForBusiniessFocuses(company);
				let r = this.getPossibleMainDirectionBusinessFocuses(company);
				r.forEach(x => {
					vr.add({validator: this.hasSpecifiedMainDirectionForBusiniessFocuses, object: x, ok: hasSpecifiedMainDirectionForBusiniessFocuses })
				})
			}
		}

		return vr;
	}

	hasSelectedCompany(c: Case) :boolean {
		const r = this.getMySelectedCompanies(c);
		const hasSelectedCompany = r && r.length>0 ? true : false;
		if( hasSelectedCompany )
			return true;
		else {
			if( c.applicants && c.applicants.filter(x => x.willBuyAgriForestryProperty===true).length>0 ) {
				return true;
			}
			else 
				return false;
		}
	}

	getBusinessFocusesPartSum(company:Company) : number {
		if( !company.businessFocuses )
			return 0;
		let sum = company.businessFocuses.reduce((agg,x) => agg+(x.businessPart||0), 0);
		return sum;
	}

	addNewBusinessFocus(company:Company) {
		if( !company.businessFocuses ) {
			company.businessFocuses = [];
		}
		let businessFocus:BusinessCategory = {
			id: this.idService.newGuid(),
		};
		if( company.businessFocuses.length===0) {
			businessFocus.businessPart = 100;
		} else {
			let sum = this.getBusinessFocusesPartSum(company);
			if( sum<100 ) {
				businessFocus.businessPart = 100-sum;
			}
		}
		this.stateService.listAdd(company.businessFocuses, businessFocus);
		this.update(company);
	}

	removeBusinessFocus(company: Company, businessCategory: BusinessCategory) {
		if( !company.businessFocuses ) {
			return;
		}

		this.stateService.listRemove(company.businessFocuses, businessCategory);
		this.update(company);

		if( company.businessFocuses.length===1 ) {
			company.businessFocuses[0].businessPart = 100;
			this.update(company.businessFocuses[0]);
		}
	}

	updateBusinessFocus(company: Company, businessFocus: BusinessCategory) {
		this.update(company);
		this.update(businessFocus); 
	}


}
