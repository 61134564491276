/* tslint:disable */
/* eslint-disable */
/**
 * SelmaSME API
 * This definition is the API between customer SME-Gui and the persistence layer for the application NOT FOR V1 When customer logg-in to Selma-EN, we will create a processId with applicant CustomerId (from BankID). When a customer added attachment to the application, we don\'t have a CaseId i LP, should we add a LP-Service to create/reserve a caseId to be able to attach a document to the caseId in DM.
 *
 * The version of the OpenAPI document: 0.7.4
 * Contact: developer@landshypotek.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Taxerad ägare
 * @export
 * @interface TaxedOwnerType
 */
export interface TaxedOwnerType {
    /**
     * Unique identifier
     * @type {string}
     * @memberof TaxedOwnerType
     */
    taxedOwnerId: string;
    /**
     * Taxerad ägare till fastigheten
     * @type {string}
     * @memberof TaxedOwnerType
     */
    taxedOwner: string;
}

export function TaxedOwnerTypeFromJSON(json: any): TaxedOwnerType {
    return TaxedOwnerTypeFromJSONTyped(json, false);
}

export function TaxedOwnerTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): TaxedOwnerType {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'taxedOwnerId': json['taxedOwnerId'],
        'taxedOwner': json['taxedOwner'],
    };
}

export function TaxedOwnerTypeToJSON(value?: TaxedOwnerType | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'taxedOwnerId': value.taxedOwnerId,
        'taxedOwner': value.taxedOwner,
    };
}


