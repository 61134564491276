/* eslint-disable react/display-name */
import React  from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import { LoadingPage } from './LoadingPage';
import HomePage from '../home/HomePage/HomePage';
import AccessDenied from '../AccessDenied/AccessDenied'
import { Case } from '../../models/SelmaModels';
import { AppComponent } from '../AppComponent';
import CasePage from '../case/CasePage';
import { MaintenancePage } from './MaintenancePage';
import { AdminPage } from '../admin/AdminPage/AdminPage';
import { Row, Col } from 'react-flexbox-grid';
import Locallogin from '../login/Local/Locallogin';

interface Props {
	loaded: boolean;
	loggedIn: boolean;
	loggedOut: boolean;
	isAdmin: boolean;
	cases?: Case[];
	case?: Case;
	maintenanceMode: boolean;
	canSignIn: boolean;
	useLocalLogin: boolean;
}

const routes = [
	{
		path: '/accessdenied',
		key: 'accessdenied',
		exact: true,
		strict: false,
		routeIsProtected: false,
		component: (props) => <AccessDenied {...props} />,
	},
	{
		path: '/',
		key: 'home',
		exact: true,
		strict: false,
		routeIsProtected: true,
		component: (props) => <HomePage {...props} />,
	},
	{
		path: '/case',
		key: 'case',
		exact: true,
		strict: false,
		routeIsProtected: true,
		requiredProp: 'case',
		component: (props) => <CasePage {...props} />,
	},
	{
		path: '/admin',
		key: 'admin',
		exact: true,
		strict: false,
		routeIsProtected: true,
		mustBeAdmin: true,
		component: (props) => <AdminPage {...props} />,
	}
];



function ProtectedRoute({ Component, exact, strict, path, ...rest }) {

	if(rest.useLocalLogin && !rest.loggedIn && path !== "/locallogin")
		return <Redirect to="/locallogin" />;
	
	if(!rest.canSignIn && rest.loggedIn && path != "/accessdenied") {
		return <Redirect to="/accessdenied" />;
	}

	if (rest.requiredProp && typeof rest[rest.requiredProp] === 'undefined') {
		return <Redirect to="/" />;
	}

	// Check admin access or redirect to startpage
	if (rest.mustBeAdmin === true && rest.isAdmin !== true) {
		return <Redirect to="/" />;
	}
	
	return (
		<Route
			path={path}
			exact={exact}
			strict={strict}
			{...rest}
			render={(props) => rest.loggedIn
				? <Component {...{ ...props, ...rest }} />
				: (
					(rest.loggedOut && path != "/accessdenied") && <Redirect
					to={{
						pathname: "/accessdenied",
						state: { from: props.location }
					}}
				/>
				)
			}
		/>
	);
}

export default class PageRouter extends AppComponent<Props> {
	render() {
		if (!this.props.loaded) return <LoadingPage />;
		if (this.props.maintenanceMode) return <MaintenancePage />;

		const loggedIn = this.props.loggedIn;
		const loggedOut = this.props.loggedOut;
		const isAdmin = this.props.isAdmin;
		const canSignIn = this.props.canSignIn;
		const useLocalLogin = this.props.useLocalLogin;

		//	if (!loggedIn) return <LoginPage />;

		let c;
		if (loggedIn && this.props.case) c = this.props.case;

		let cases: Case[];
		if (loggedIn && this.props.cases) cases = this.props.cases;
		else cases = [];

		if(useLocalLogin) {
			routes.push({
				path: '/locallogin',
				key: 'locallogin',
				exact: true,
				strict: false,
				routeIsProtected: false,
				component: (props) => <Locallogin {...props} />,
			})
		}

		// ROUTING WITH ROUTER
		return (
			<Row className="MainContent">
				<Col xs={12}>
					<Switch>
						{routes.map(({ component: Component, exact, path, key, requiredProp, strict, routeIsProtected, ...rest }) => {
							const props = {
								...rest,
								case: c,
								cases,
								path,
								exact,
								strict,
								Component,
								loggedIn,
								loggedOut,
								isAdmin,
								requiredProp,
								canSignIn,
								useLocalLogin
							};

							if (routeIsProtected) {
								return <ProtectedRoute key={key} {...props} />;
							}

							if (
								requiredProp &&
								typeof this.props[requiredProp] === 'undefined'
							) {
								return <Redirect key={key} to="/" />;
							}

							return (

								<Route
									key={key}
									exact={exact}
									strict={strict}
									path={path}
									render={(routeProps) => (
										<Component {...{ ...props, ...routeProps }} />
									)}
								/>
							);
						})
						}
					</Switch>
				</Col>
			</Row>
		);
	}
}
