import React from 'react';
import Accordion from '../../../../layout/Accordion/Accordion';
import { T } from '../../../../widget/display/T';
import {
	FormUnit,
	FormUnitSize,
	FormUnitPadding,
} from '../../../../layout/FormUnit/FormUnit';
import { LoanService } from '../../../../../services/LoanService';
import { AppComponent } from '../../../../AppComponent';
import { LoanAmountSummary } from './LoanAmountSummary';
import { LoanTakeOverSummary } from './LoanTakeOverSummary';
import { LoanAimsSummary } from './LoanAimsSummary';
import { LoanDownpaymentSummary } from './LoanDownpaymentSummary';
import { Heading } from '../../../../widget/display/Heading/Heading';


export default class LoanSummary extends AppComponent {
	loanService: LoanService;

	constructor(props) {
		super(props);
		this.loanService = this.application.services.loanService;
	}

	static defaultProps = {
		accordionOpen: false,
		formUnitSize: FormUnitSize.Large,
	};

	render() {
		const loan = this.props.case.loan;
		if (!loan) return false;

		const askAboutDownpayment = this.loanService.askAboutDownpayment(loan);

		return (
			<div className="LoanSummary">
				<FormUnit
					size={this.props.formUnitSize}
					padding={FormUnitPadding.Small}>
					<Accordion
						title={<Heading level={2} labelTextkey="Summary_Loan_Title"/>}
						open={this.props.accordionOpen}>
						<LoanAmountSummary loan={loan} />
						<LoanTakeOverSummary loan={loan} />
						<LoanAimsSummary loan={loan} />
						{ askAboutDownpayment &&
							<LoanDownpaymentSummary loan={loan} />
						}
					</Accordion>
				</FormUnit>
			</div>
		);
	}
}
