import React, { useCallback, useState } from 'react';
import { StepContext } from '../../../case/CaseSteps';

interface Props {
	context: StepContext;
	//immidiateAllValidators?: boolean;
	immidiateAllValidatorsVisibleBefore?: Date;
}

export interface StepFormData {
	context?: StepContext;
	touches: string[];
	//immidiateAllValidators:boolean;
	immidiateAllValidatorsVisibleBefore?: Date;
}

const StepFormContext = React.createContext<StepFormData>({
	context: undefined,
	touches: [],
	//immidiateAllValidators: false,
	immidiateAllValidatorsVisibleBefore: undefined
});
export default StepFormContext;

export const StepForm: React.FunctionComponent<Props> = (props) => {

	const theFormData: StepFormData = {
		context: props.context,
		touches: [],
		//immidiateAllValidators : props.immidiateAllValidators === true,
		immidiateAllValidatorsVisibleBefore: props.immidiateAllValidatorsVisibleBefore
	};

	return (
		<StepFormContext.Provider value={theFormData}>
			{props.children}
		</StepFormContext.Provider>
	);
};
