import React from 'react';
import { AppComponent } from '../AppComponent';
import { PageContent, PageStyle } from '../layout/PageContent/PageContent';
import { THtml } from '../widget/display/THtml';
import { Heading } from '../widget/display/Heading/Heading';

interface Props {
}

interface State {
}

export class MaintenancePage extends AppComponent<Props, State> {
	constructor(props: Props) {
		super(props);
	}

	render() {
		return (
			<div className="Page LoginPage">
				<PageContent style={PageStyle.Gray}>
					<div className="full-width">
						<Heading level={1} labelTextkey="Maintenance_Title"/>
						<br />
						<p>
							<THtml k="Maintenance_Description" />
						</p>
					</div>
				</PageContent>
			</div>
		);
	}
}
