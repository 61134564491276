import OidcService from "../services/OidcService";
import { BaseService } from './BaseService';
import { StateService } from './StateService';
import { ConfigurationService } from './ConfigurationService';
import { ServerSession } from "./OidcService"

export interface Session {
	login?: Login;
	logout?: Logout;
}

export interface Login {
	time: Date;
	ssn: string;
	admin: boolean;
	firstName?: string;
	lastName?: string;
	canSignIn: boolean,
	isUnderAge: boolean
}

export interface Logout {
	time: Date;
	reason: LogoutReason
}

export enum LogoutReason {
	User = 1, // User choose to logout
	Timeout = 2, // Session timed out.
	System = 3 // System forced logout b/c error or maintenance.
}

export class SessionService extends BaseService {
	private stateService: StateService;
	private oidcService: OidcService;

	sessionLoopHandle: any | undefined;

	constructor(stateService: StateService, oidcService: OidcService, private configurationService: ConfigurationService) {
		super();
		this.stateService = stateService;
		this.oidcService = oidcService
	}

	private startSessionLoop() {
		if (this.sessionLoopHandle)
			return; // already started.
		this.sessionLoopHandle = setInterval(() => {
			this.oidcService.getSession().then(result => {
				this.handleServerSession(result);
			})
		}, 60 * 1000);
	}

	private stopSessionLoop() {
		if (this.sessionLoopHandle) {
			clearInterval(this.sessionLoopHandle);
			this.sessionLoopHandle = undefined;
		}
	}

	// Call this after ok login.
	loadSession(): Promise<ServerSession> {
		return this.oidcService.getSession().then(result => {
			this.handleServerSession(result);
			if (result.isLoggedIn) {
				this.startSessionLoop();
			}
			return result;
		})
	}

	handleServerSession(serverSession: ServerSession) {
		if (!serverSession.isLoggedIn) {
			if (this.isLoggedIn()) {
				this.logout(LogoutReason.Timeout);
				//console.log('User was logged out via server session.');
			}
		} else {
			if (!this.isLoggedIn()) {
				//console.log('User was logged in via server session.');
				let newLogin = this.createNewLogin(serverSession);
				this.setNewLogin(newLogin);
			}
		}
	}

	isLoggedIn(): boolean {
		return this.stateService.state.session.login !== undefined;
	}

	isAdmin(): boolean {
		if (!this.isLoggedIn())
			return false;

		var login = this.getLogin();
		return login?.admin === true;
	}

	getLogin(): Login {
		let login = this.stateService.state.session.login;
		if (!login) {
			throw new Error('Not logged in.');
		}
		return login;
	}

	getSsn(): string {
		const login = this.getLogin();
		const ssn = login.ssn;
		return ssn;
	}


	getLogout(): Logout | undefined {
		if (this.isLoggedIn())
			throw new Error('Not logged out.');

		let logout = this.stateService.state.session.logout;
		return logout;
	}

	didLogOut(): boolean {
		return this.stateService.state.session.logout !== undefined;
	}

	logout(reason: LogoutReason = LogoutReason.User): Promise<Session> {
		let state = this.stateService.state;
		let session = state.session;
		session.login = undefined;
		session.logout = {
			time: new Date(),
			reason: reason
		};
		state.cases = undefined;
		state.case = undefined;
		this.stateService.update(session);
		this.stateService.update(state);
		this.stopSessionLoop();
		return Promise.resolve(session);
	}

	private createNewLogin(serverSession: ServerSession): Login {
		const ss = serverSession;
		let login = {
			time: new Date(),
			ssn: ss.ssn,
			admin: ss.isAdmin,
			firstName: ss.firstName,
			lastName: ss.lastName,
			canSignIn: ss.canSignIn,
			isUnderAge: ss.isUnderAge
		};
		return login;
	}

	private setNewLogin(login: Login) {
		let session = this.stateService.state.session;
		let prevLogin = session.login;
		session.login = login;
		session.logout = undefined;
		this.stateService.replace(prevLogin, login);
		this.stateService.update(session);
		return session;
	}

	// private handleLoginSuccess = (result: BidLoginResult): BidLoginResult => {
	// 	let session = this.stateService.state.session;
	// 	return result;

	// 	//todo här ska man inte sättas som inloggad, man har endast begärt att få börja bli inloggad och påbörat bankid-identifiering via externa bankid-applikationen

	// 	//let prevLogin = session.login;
	// 	// let login = session.login = {
	// 	// 	time: new Date(),
	// 	// 	ssn: result.ssn,
	// 	// 	admin: true,
	// 	// 	token: result.token,
	// 	// 	firstName: result.firstName,
	// 	// 	lastName: result.lastName
	// 	// };
	// 	//session.logout = undefined;

	// 	//this.stateService.replace(prevLogin, login);
	// 	//this.stateService.update(session);
	// 	//return session;
	// }

	// private handleLoginError = (result: BidLoginResult): BidLoginResult => {
	// 	return result;
	// 	// let session = this.stateService.state.session;
	// 	// let prevLogin = session.login;
	// 	// return session;
	// }

	// loginMobile(ssn: string): Promise<BidLoginResult> {
	// 	return this.bidService.login(ssn, 'MOBILE')
	// 		.then(this.handleLoginSuccess, this.handleLoginError);
	// }

	// loginLocal(ssn: string): Promise<BidLoginResult> {
	// 	return this.bidService.login(ssn, 'SAME')
	// 		.then(this.handleLoginSuccess, this.handleLoginError);
	// }
	// checkLoginState(loginToken: string): Promise<BidCheckLoginResult> {
	// 	return this.bidService.checkLogin(loginToken);
	// }
	// loginWithAutostartToken(autostartToken: string): void {
	// 	return this.bidService.redirectToBankIDApp(autostartToken);
	// }
	// collect(startToken: string): Promise<QrCodeResult> {
	// 	return this.bidService.collect(startToken);
	// }
}
