import React from 'react';
import { Case as SelmaCase } from '../../../models/SelmaModels';
import { AppComponent } from '../../AppComponent';
import { Button } from '../../widget/input/Button/Button';
import './AdminCaseStatus.scss';
import { T } from '../../widget/display/T';

interface Props {
	case: SelmaCase;
	selectedCase: (c: SelmaCase) => void;
}

export class AdminCaseStatus extends AppComponent<Props> {
	constructor(props: Props) {
		super(props);
	}

	selectedCase = (event: React.MouseEvent<Element, MouseEvent>): void => {
		this.props.selectedCase(this.props.case);
	};

	render() {
		const c = this.props.case;

		return (
			<div className="AdminCaseStatus">
				<div className="Body">
					<div className="CaseInfo">
						<h3><T k="Admin_Case" /> {c.number}</h3>
						<div>
							{c.lpCaseId && 
							<div className="CaseId">
								<b><T k="Admin_Case_LPCaseId" />:</b>{' '}{c.lpCaseId}
							</div>
							}
							<div className="CaseId">
								<b><T k="Admin_Case_Status" />:</b>{' '}{c.status}
							</div>
							<div className="CaseId">
								<b><T k="Admin_Case_Created" />:</b>{' '}{c.createdDate.toLocaleDateString()}
							</div>
							<div className="CaseId">
								<b><T k="Admin_Case_Changed" />:</b>{' '}{c.lastAccessedDate.toLocaleDateString()}
							</div>
						</div>
					</div>
					<div className="CaseCompanies">
						<ul className="Companies">
							{c.companies &&
								c.companies.map((x, ix) => (
									<li className={[
										'Company'
									].join(' ')}
										key={ix}>
										{x.name}, {x.orgNumber}
									</li>
								))}
						</ul>
					</div>
					<div className="CaseApplicants">
						<ul className="Applicants">
							{c.applicants &&
								c.applicants.map((x, ix) => (
									<li className="Applicant" key={ix}>
										{x.firstName} {x.lastName}
									</li>
								))}
						</ul>
					</div>
				</div>
				<div className="Footer">
					<Button
						className="Button full-width"
						labelTextkey="Admin_SelectCase"
						onClick={this.selectedCase}
					/>
				</div>
			</div>
		);
	}
}
