import React from 'react';
import { Case } from '../../../../../models/SelmaModels';
import { AppComponent } from '../../../../AppComponent';
import Accordion from '../../../../layout/Accordion/Accordion';
import { HouseholdMembersSummary } from './HouseholdMembersSummary';
import { HouseholdChildrenSummary } from './HouseholdChildrenSummary';
import { HouseholdChildMaintenanceSummary } from './HouseholdChildMaintenanceSummary';
import { HouseholdMaintenanceCostsSummary } from './HouseholdMaintenanceCostsSummary';
import { HouseholdExtLoansSummary } from './HouseholdExtLoansSummary';
import {
	FormUnit,
	FormUnitSize,
	FormUnitPadding,
} from '../../../../layout/FormUnit/FormUnit';
import { Heading } from '../../../../widget/display/Heading/Heading';

interface Props {
	case: Case;
	accordionOpen?: boolean;
	formUnitSize?: FormUnitSize;
}

export class HouseholdSummary extends AppComponent<Props> {
	constructor(props: Props) {
		super(props);
	}

	static defaultProps = {
		accordionOpen: false,
		formUnitSize: FormUnitSize.Large,
	};

	render() {
		const c = this.props.case;
		if (!c.households) return null;

		return (
			<div className="HouseholdSummary">
				<FormUnit
					size={this.props.formUnitSize}
					padding={FormUnitPadding.Small}>
					<Accordion
						title={<Heading level={2} labelTextkey="Summary_Household_Title"/>}
						open={this.props.accordionOpen}>
						{c.households.map((x, ix) => {
							return (
								<div key={`household_${x.id}_${ix}`}>
									<HouseholdMembersSummary
										householdMembers={x.members}
										case={c}
									/>
									<HouseholdChildrenSummary household={x} />
									<HouseholdChildMaintenanceSummary household={x} />
								</div>
							);
						})}
						<HouseholdMaintenanceCostsSummary case={c} />
						<HouseholdExtLoansSummary case={c} />
					</Accordion>
				</FormUnit>
			</div>
		);
	}
}
