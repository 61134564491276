/* tslint:disable */
/* eslint-disable */
/**
 * SelmaSME API
 * This definition is the API between customer SME-Gui and the persistence layer for the application NOT FOR V1 When customer logg-in to Selma-EN, we will create a processId with applicant CustomerId (from BankID). When a customer added attachment to the application, we don\'t have a CaseId i LP, should we add a LP-Service to create/reserve a caseId to be able to attach a document to the caseId in DM.
 *
 * The version of the OpenAPI document: 0.7.4
 * Contact: developer@landshypotek.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TypeOfLoan,
    TypeOfLoanFromJSON,
    TypeOfLoanFromJSONTyped,
    TypeOfLoanToJSON,
} from './';

/**
 * Externa lån kunden har i andra kreditinstitut, detta exkluderar LH-lån
 * @export
 * @interface ExtLoanType
 */
export interface ExtLoanType {
    /**
     * application model unique identification
     * @type {string}
     * @memberof ExtLoanType
     */
    processId: string;
    /**
     * A list of external loan owners (cusotmerId)
     * @type {Array<string>}
     * @memberof ExtLoanType
     */
    extLoanOwners?: Array<string>;
    /**
     * Type definitions for external loans
     * @type {string}
     * @memberof ExtLoanType
     */
    extloanId: string;
    /**
     * 
     * @type {TypeOfLoan}
     * @memberof ExtLoanType
     */
    typeOfLoan?: TypeOfLoan;
    /**
     * Kreditinstitut, Bank, Loan originator
     * @type {string}
     * @memberof ExtLoanType
     */
    extCreditInstitute?: string;
    /**
     * Clearing number (has been deprecated)
     * @type {string}
     * @memberof ExtLoanType
     */
    extloanClearing?: string;
    /**
     * Loan number
     * @type {string}
     * @memberof ExtLoanType
     */
    extloanNumber?: string;
    /**
     * externa loan, amount
     * @type {number}
     * @memberof ExtLoanType
     */
    extloanAmount?: number;
    /**
     * Redeem loans - Lösa lånet i annan bank
     * @type {boolean}
     * @memberof ExtLoanType
     */
    extRedeemLoan?: boolean;
    /**
     * Monthly cost for Studielån
     * @type {number}
     * @memberof ExtLoanType
     */
    extMonthlyCost?: number;
}

export function ExtLoanTypeFromJSON(json: any): ExtLoanType {
    return ExtLoanTypeFromJSONTyped(json, false);
}

export function ExtLoanTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExtLoanType {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'processId': json['processId'],
        'extLoanOwners': !exists(json, 'extLoanOwners') ? undefined : json['extLoanOwners'],
        'extloanId': json['extloanId'],
        'typeOfLoan': !exists(json, 'typeOfLoan') ? undefined : TypeOfLoanFromJSON(json['typeOfLoan']),
        'extCreditInstitute': !exists(json, 'extCreditInstitute') ? undefined : json['extCreditInstitute'],
        'extloanClearing': !exists(json, 'extloanClearing') ? undefined : json['extloanClearing'],
        'extloanNumber': !exists(json, 'extloanNumber') ? undefined : json['extloanNumber'],
        'extloanAmount': !exists(json, 'extloanAmount') ? undefined : json['extloanAmount'],
        'extRedeemLoan': !exists(json, 'extRedeemLoan') ? undefined : json['extRedeemLoan'],
        'extMonthlyCost': !exists(json, 'extMonthlyCost') ? undefined : json['extMonthlyCost'],
    };
}

export function ExtLoanTypeToJSON(value?: ExtLoanType | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'processId': value.processId,
        'extLoanOwners': value.extLoanOwners,
        'extloanId': value.extloanId,
        'typeOfLoan': TypeOfLoanToJSON(value.typeOfLoan),
        'extCreditInstitute': value.extCreditInstitute,
        'extloanClearing': value.extloanClearing,
        'extloanNumber': value.extloanNumber,
        'extloanAmount': value.extloanAmount,
        'extRedeemLoan': value.extRedeemLoan,
        'extMonthlyCost': value.extMonthlyCost,
    };
}


