import { UIKeyed } from "./UIKeyed";
import { UINamed } from "./UINamed";
import { TypeOfLoan } from "../../libs/SMELPOClient";

export interface UIExtLoanType extends UIKeyed, UINamed {
	id: number;
	type: TypeOfLoan;
}

export const uiExtLoanTypes:UIExtLoanType[] = [
	{ key: "STUDENT", id: 0, name: "Studielån", nameTextkey: "LoanType_STUDENT", type:TypeOfLoan.Studieln }, // STUDENT // (Studielån)
	{ key: "ACCOUNTCREDIT", id: 2, name: "Kontokredit/Kortkredit", nameTextkey: "LoanType_ACCOUNTCREDIT", type:TypeOfLoan.KontoKortkredit  }, // ACCOUNTCREDIT // (Kort/Kontokredit)
	{ key: "INSTALLMENT", id: 3, name: "Avbetalningskredit/Billån/Båtlån", nameTextkey: "LoanType_INSTALLMENT", type:TypeOfLoan.AvbetalningskreditBillnBtln  }, // INSTALLMENT // (Avbetalning/Leasing)
	//{ key: 4, name: "Bolån", nameTextkey: "LoanType_MORTGAGE", type:TypeOfLoan.Studieln  }, //MORTGAGE // (Bolån)
	{ key: "SECURITY", id: 5, name: "Borgen", nameTextkey: "LoanType_SECURITY", type:TypeOfLoan.Borgenstagande  }, //SECURITY // (Borgen)
	{ key: "GUARANTEES", id: 6, name: "Garantier", nameTextkey: "LoanType_GUARANTEES", type:TypeOfLoan.Garantier  }, //GUARANTEES // (Garanti)
	{ key: "BUSINESS",  id: 8, name: "Näringskredit", nameTextkey: "LoanType_BUSINESS", type:TypeOfLoan.Nringskredit  }, //BUSINESS // (Näringskredit)
	{ key: "BLANCO", id: 1, name: "Blancolån/Övriga lån", nameTextkey: "LoanType_BLANCO", type:TypeOfLoan.BlancokreditVrigaLn  } //UNSECURED // (Blancolån)
];
