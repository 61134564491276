
export interface Loan {
	// processId?: ProcessId;
	id: string; // GUID, loanId
	number?: string; // loanNumber
	// purposeOfLoan?: MainPurposes;
	amount?: number; // loanAmount // obsolete.
	aims?: Aim[];
	takeoverLoans?: TakeoverLoan[];
	takeOver?: boolean;
	downpaymentOther?: string;
	downpaymentType?: DownpaymentSource;
	downPaymentAmount?: number;
	purposeDescription?: string;
}

/*
export enum MainPurposes {
	BUY, //    Kp = <any> 'Köp',
	TAKEOVER, //    Vertag = <any> 'Övertag',
	EXTENSION, //    UtkningLn = <any> 'Utökning lån',
	UNPLEDGED //  UtlningMotObelnat = <any> 'Utlåning mot obelånat'
}
*/

export enum DownpaymentSource {
	SALE_OF_PROPERTY,
	SAVINGS,
	BLANCO_LOAN,
	OTHER
}

export interface Aim {
	id: string; // guid //aimId: AimId;
	aimCategory?: string; // purposeText
	aimDetail?: string; // aimText
	loanAmountPart?: number; // money, part of loan.amount
}

// export enum AimType {
// 	FastighetskpPrivatbostad = <any>'Fastighetsköp - privatbostad',
// 	FastighetskpAnnanFastighet = <any>'Fastighetsköp - annan fastighet',
// 	FastighetskpJordbruk = <any>'Fastighetsköp - jordbruk',
// 	FinansieringBefintligBostad = <any>'Finansiering befintlig bostad',
// 	FinansieringBefintligBostadOmlggningInternt = <any>'Finansiering befintlig bostad (omläggning internt)',
// 	NytillbyggnadrenoveringMjlkstall = <any>'Ny/tillbyggnad/renovering mjölkstall'
// }

export interface TakeoverLoan {
	id: string; // guid //takeoverLoanId;
	creditInstitute?: string; // Bank
	loanNumber?: string; // Lånenummer hos nuvarande bank
	debtAmount?: number; // Skuld I annan bank
	aimCategory?: string; // # Laddas från mainpurposes.json
	aimDetail?: string; //' # Laddas från mainpurposes.json
	//interest?: number; //' # Dessa används inte idag men är räntan kunden betalar på nuvarande lån i annan bank som ska lösas
	//period?: number; //' # Används inte idag med avser den räntebindningstid kunden har på nuvarande lån i annan bank som ska lösas
}
