import React, { RefObject } from 'react';
import './RadiobuttonTable.scss';
import { isObject } from 'lodash';
import { Radiobutton } from '../Radiobutton/Radiobutton';
import { uniqueId } from 'lodash';
import { BaseComponent } from '../../../BaseComponent';
import { ScrollOverflowIndicator } from '../../display/ScrollOverflowIndicator/ScrollOverflowIndicator';

export interface TableData {
	head: Array<string>;
	body: Array<Array<TableDataCell|string>>;
}


export interface TableDataCell {
	type?: string;
	value?: string;
	enabled?: boolean;
	tag?: any;
}

interface Props {
	className?: string;
	error?: boolean;
	value: string;
	tableData: TableData;
	handleBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
	handleChange: (cell: TableDataCell) => void;
	radiobuttonColumnNumber?: number;
	touched?: boolean;
	hasErrors?: boolean;
	dirty?: boolean;
}

export class RadiobuttonTable extends BaseComponent<Props> {
	id: string;
	nodeRef: RefObject<HTMLTableElement>;

	constructor(props: Props) {
		super(props);

		this.id = uniqueId('radiobuttonTable_');
		this.nodeRef = React.createRef();

		this.state = {
			hasOverflow: false,
		};
	}

	static defaultProps = {
		className: 'RadiobuttonTable',
		touched: false,
		hasErrors: false,
		dirty: false,
	};

	componentDidMount() {
		this.hasOverflow();
		window.addEventListener('resize', this.hasOverflow);
	}

	componendWillUnmount() {
		window.removeEventListener('resize', this.hasOverflow);
	}

	hasOverflow = () => {
		if (!this.nodeRef) return null;
		const element = this.nodeRef.current;

		if (!element) return null;

		this.setState({
			hasOverflow: element.scrollWidth > element.clientWidth,
		});
	};

	handleChange = (event: React.ChangeEvent<HTMLInputElement>, cell: TableDataCell) => {
		event.stopPropagation();
		//const target = event.currentTarget;
		//const value = target.value || '';
		const value = cell.value||'';
		this.props.handleChange(cell);
	};

	render() {

		return (
			<>
				<div className="RadiobuttonTableWrapper" ref={this.nodeRef}>
					<table className={this.props.className}>
						<thead>
							<tr>
								{this.props.tableData.head.map((column, headIndex) => {
									return <th key={`${this.id}_head_${headIndex}`}>{column}</th>;
								})}
							</tr>
						</thead>
						<tbody>
							{this.props.tableData.body.map((row, rowIndex) => {
								return (
									<tr key={`${this.id}_row_${rowIndex}`} onClick={(event:React.MouseEvent<HTMLTableRowElement>) => {
										event.stopPropagation();
										const radiobutton = event.currentTarget.getElementsByTagName('input');
										const value = radiobutton[0].value;
										//this.props.handleChange(value);
									}}>
										{row.map((cell, cellIndex) => {
											const cellObj = (cell as TableDataCell);
											if (isObject(cell)) {
												switch (cellObj.type) {
													
													case 'radio':
														return (
															<td
																key={`${
																	this.id
																}_row_${rowIndex}_cell_${cellIndex}`}>
																<Radiobutton
																	group={this.id}
																	value={cellObj.value||''}
																	checked={cellObj.value==this.props.value}
																	handleChange={(event) => {this.handleChange(event, cellObj)}}
																	handleBlur={this.props.handleBlur}
																	enabled={cellObj.enabled}
																	hasErrors={this.props.hasErrors}
																	touched={this.props.touched}
																/>
															</td>
														);
												}
											} else {
												return (
													<td
														key={`${
															this.id
														}_row_${rowIndex}_cell_${cellIndex}`}>
														{cell}
													</td>
												);
											}
										})}
									</tr>
								);
							})}
						</tbody>
					</table>
				</div>
				{this.state.hasOverflow ? <ScrollOverflowIndicator /> : null}
			</>
		);
	}
}
