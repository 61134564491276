/* tslint:disable */
/* eslint-disable */
/**
 * SelmaSME API
 * This definition is the API between customer SME-Gui and the persistence layer for the application NOT FOR V1 When customer logg-in to Selma-EN, we will create a processId with applicant CustomerId (from BankID). When a customer added attachment to the application, we don\'t have a CaseId i LP, should we add a LP-Service to create/reserve a caseId to be able to attach a document to the caseId in DM.
 *
 * The version of the OpenAPI document: 0.7.4
 * Contact: developer@landshypotek.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BusinessCategoryType,
    BusinessCategoryTypeFromJSON,
    BusinessCategoryTypeFromJSONTyped,
    BusinessCategoryTypeToJSON,
    PrincipalType,
    PrincipalTypeFromJSON,
    PrincipalTypeFromJSONTyped,
    PrincipalTypeToJSON,
} from './';

/**
 * Type of company instance
 * @export
 * @interface CompanyType
 */
export interface CompanyType {
    /**
     * application model unique identification
     * @type {string}
     * @memberof CompanyType
     */
    processId: string;
    /**
     * generated uuid in the model
     * @type {string}
     * @memberof CompanyType
     */
    companyId: string;
    /**
     * Organisation number from external service, swedish ssn (UC or SAP for existing companies)
     * @type {string}
     * @memberof CompanyType
     */
    orgNumber?: string;
    /**
     * Full name of the company from external services or BusinessName in SAP.
     * @type {string}
     * @memberof CompanyType
     */
    companyName?: string;
    /**
     * Status code from roaring API
     * @type {string}
     * @memberof CompanyType
     */
    statusCode?: string;
    /**
     * Status text high description
     * @type {string}
     * @memberof CompanyType
     */
    statusTextHigh?: string;
    /**
     * date when company was established, from external service or existing customer in SAP
     * @type {string}
     * @memberof CompanyType
     */
    created?: string;
    /**
     * Driftinriktning
     * @type {Array<BusinessCategoryType>}
     * @memberof CompanyType
     */
    businessFocuses?: Array<BusinessCategoryType>;
    /**
     * SNI-code from external company service roaring.io
     * @type {string}
     * @memberof CompanyType
     */
    industriCode?: string;
    /**
     * SNI-Text from external company service, category text
     * @type {string}
     * @memberof CompanyType
     */
    industriText?: string;
    /**
     * Legal Group code, available Legal Group codes for a company. The information is displayed as a code with a descriptive text. Source is roaring.io OVR  Övriga  Other HB/KB  Handelsbolag  Trading company AB  Privat aktiebolag  Limited company EF  Enskild firma  Sole proprietorship
     * @type {string}
     * @memberof CompanyType
     */
    leagalGroupCode?: string;
    /**
     * Legal Group Text, descriptive text
     * @type {string}
     * @memberof CompanyType
     */
    legalGroupText?: string;
    /**
     * Customer has selected this company for the loan to apply for
     * @type {boolean}
     * @memberof CompanyType
     */
    selectedCompany?: boolean;
    /**
     * list of huvudmän
     * @type {Array<PrincipalType>}
     * @memberof CompanyType
     */
    principals?: Array<PrincipalType>;
    /**
     * Broken fiscal year, brutet räkenskapsår (true/false) should only be visible for legalGroupCode AB
     * @type {boolean}
     * @memberof CompanyType
     */
    brokenFiscalYear?: boolean;
    /**
     * Bokslutsdatum
     * @type {string}
     * @memberof CompanyType
     */
    fiscalYearEndDate?: string;
}

export function CompanyTypeFromJSON(json: any): CompanyType {
    return CompanyTypeFromJSONTyped(json, false);
}

export function CompanyTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyType {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'processId': json['processId'],
        'companyId': json['companyId'],
        'orgNumber': !exists(json, 'orgNumber') ? undefined : json['orgNumber'],
        'companyName': !exists(json, 'companyName') ? undefined : json['companyName'],
        'statusCode': !exists(json, 'statusCode') ? undefined : json['statusCode'],
        'statusTextHigh': !exists(json, 'statusTextHigh') ? undefined : json['statusTextHigh'],
        'created': !exists(json, 'created') ? undefined : json['created'],
        'businessFocuses': !exists(json, 'businessFocuses') ? undefined : ((json['businessFocuses'] as Array<any>).map(BusinessCategoryTypeFromJSON)),
        'industriCode': !exists(json, 'industriCode') ? undefined : json['industriCode'],
        'industriText': !exists(json, 'industriText') ? undefined : json['industriText'],
        'leagalGroupCode': !exists(json, 'leagalGroupCode') ? undefined : json['leagalGroupCode'],
        'legalGroupText': !exists(json, 'legalGroupText') ? undefined : json['legalGroupText'],
        'selectedCompany': !exists(json, 'selectedCompany') ? undefined : json['selectedCompany'],
        'principals': !exists(json, 'principals') ? undefined : ((json['principals'] as Array<any>).map(PrincipalTypeFromJSON)),
        'brokenFiscalYear': !exists(json, 'brokenFiscalYear') ? undefined : json['brokenFiscalYear'],
        'fiscalYearEndDate': !exists(json, 'fiscalYearEndDate') ? undefined : json['fiscalYearEndDate'],
    };
}

export function CompanyTypeToJSON(value?: CompanyType | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'processId': value.processId,
        'companyId': value.companyId,
        'orgNumber': value.orgNumber,
        'companyName': value.companyName,
        'statusCode': value.statusCode,
        'statusTextHigh': value.statusTextHigh,
        'created': value.created,
        'businessFocuses': value.businessFocuses === undefined ? undefined : ((value.businessFocuses as Array<any>).map(BusinessCategoryTypeToJSON)),
        'industriCode': value.industriCode,
        'industriText': value.industriText,
        'leagalGroupCode': value.leagalGroupCode,
        'legalGroupText': value.legalGroupText,
        'selectedCompany': value.selectedCompany,
        'principals': value.principals === undefined ? undefined : ((value.principals as Array<any>).map(PrincipalTypeToJSON)),
        'brokenFiscalYear': value.brokenFiscalYear,
        'fiscalYearEndDate': value.fiscalYearEndDate,
    };
}


