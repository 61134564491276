import React from 'react';
import NumberFormat, { NumberFormatValues } from 'react-number-format';
import './Percent.scss';

interface Props {
	className?: string;
	id?: string;
	name?: string;
	handleBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
	handleChange?: (values: NumberFormatValues) => void;
	type?: string;
	value?: number;
	min?: number;
	max?: number;
	enabled?: boolean;
	suffix?: string;
	displayType?: 'input' | 'text';
	hasErrors?: boolean;
	touched?: boolean;
	dirty?: boolean;
}

export const Percent: React.FunctionComponent<Props> = (props) => {

	const min = props.min??0;
	const max = props.max??100;
	let value = props.value;
	if( value !== undefined ) {
		if(value < min)
			value = min;
		else if( value > max )
			value = max;
	}

	let handleChange = (values: NumberFormatValues) => {
		if( props.handleChange )
			props.handleChange(values);
	}

	return (
		<NumberFormat
			className={props.className}
			id={props.id}
			value={value}
			onValueChange={handleChange}
			onBlur={props.handleBlur}
			suffix={props.suffix}
			displayType={props.displayType}
			allowNegative={false}
			decimalScale={0}
			min={min}
			max={max}
		/>
	);
};

// Default props
Percent.defaultProps = {
	className: 'Percent',
	type: 'text',
	suffix: ' %',
	min: 0,
	max: 100,
	displayType: 'input',
	touched: false,
	dirty: false,
	hasErrors: false,
};
