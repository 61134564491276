import { UINamed } from "./UINamed";

export interface UIMonth extends UINamed {
	id: number;
}

export const uiLongMonths: UIMonth[] = [
	{ id: 1, name: 'Januari', nameTextkey: 'Month_Long_January' },
	{ id: 2, name: 'Februari', nameTextkey: 'Month_Long_February' },
	{ id: 3, name: 'Mars', nameTextkey: 'Month_Long_Mars' },
	{ id: 4, name: 'April', nameTextkey: 'Month_Long_April' },
	{ id: 5, name: 'Maj', nameTextkey: 'Month_Long_May' },
	{ id: 6, name: 'Juni', nameTextkey: 'Month_Long_June' },
	{ id: 7, name: 'Juli', nameTextkey: 'Month_Long_July' },
	{ id: 8, name: 'Augusti', nameTextkey: 'Month_Long_August' },
	{ id: 9, name: 'September', nameTextkey: 'Month_Long_September' },
	{ id: 10, name: 'Oktober', nameTextkey: 'Month_Long_October' },
	{ id: 11, name: 'November', nameTextkey: 'Month_Long_November' },
	{ id: 12, name: 'December', nameTextkey: 'Month_Long_December' },
];

export const uiShortMonths: UIMonth[] = [
	{ id: 1, name: 'Jan', nameTextkey: 'Month_Short_January' },
	{ id: 2, name: 'Feb', nameTextkey: 'Month_Short_February' },
	{ id: 3, name: 'Mar', nameTextkey: 'Month_Short_Mars' },
	{ id: 4, name: 'Apr', nameTextkey: 'Month_Short_April' },
	{ id: 5, name: 'Maj', nameTextkey: 'Month_Short_May' },
	{ id: 6, name: 'Jun', nameTextkey: 'Month_Short_June' },
	{ id: 7, name: 'Jul', nameTextkey: 'Month_Short_July' },
	{ id: 8, name: 'Aug', nameTextkey: 'Month_Short_August' },
	{ id: 9, name: 'Sep', nameTextkey: 'Month_Short_September' },
	{ id: 10, name: 'Okt', nameTextkey: 'Month_Short_October' },
	{ id: 11, name: 'Nov', nameTextkey: 'Month_Short_November' },
	{ id: 12, name: 'Dec', nameTextkey: 'Month_Short_December' },
];
