import React from 'react';
import { BaseComponent } from '../../../BaseComponent';
import { Checkbox } from '../Checkbox/Checkbox';
import { T } from '../../display/T';

import './CheckboxText.scss';
import { THtml } from '../../display/THtml';

interface Props {
	className?: string;
	checked: boolean;
	enabled?: boolean;
	handleCheckedChange?: (checked: boolean) => void;
	handleChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
	handleBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
	textKey?: string;
	textIsHtml?:boolean;
	touched?: boolean;
	hasErrors?: boolean;
	dirty?: boolean;
}

export class CheckboxText extends BaseComponent<Props> {
	constructor(props: Props) {
		super(props);

		this.state = {
			checked: this.props.checked,
		};
	}

	handleClick = (event: React.MouseEvent<HTMLParagraphElement, MouseEvent>): void => {
		let changeEvent:React.ChangeEvent<any> = {
			...event,
		}
		this.handleChange(changeEvent);
	}

	handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {	
		if (this.props.enabled) {
			this.setState (
				{ checked: !this.state.checked}, 
				() => {
					if( this.props.handleChange)
						this.props.handleChange(event);
					if( this.props.handleCheckedChange ) {
						this.props.handleCheckedChange(
							this.state.checked
						);
					}
				}
			)
		}
	};

	static defaultProps = {
		className: 'CheckboxText',
		enabled: true,
		checked: false,
		touched: false,
		hasErrors: false,
		dirty: false,
	};


	render() {

		return (
			<div className={this.props.className}>
				<Checkbox
					checked={this.state.checked}
					handleChange={this.handleChange}
					handleBlur={this.props.handleBlur}
					enabled={this.props.enabled}
					touched={this.props.touched}
					hasErrors={this.props.hasErrors}
					dirty={this.props.dirty}
					// eslint-disable-next-line react/no-string-refs
					ref="cbRef"
				/>
				{this.props.textKey ? (
					<p onClick={this.handleClick} className={(!this.props.enabled) ? "disabled": undefined}>
						{this.props.textIsHtml===true && <THtml k={this.props.textKey} />}
						{this.props.textIsHtml!==true && <T k={this.props.textKey} />}
					</p>
				) : null}
			</div>
		);
	}
}

