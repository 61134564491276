import React from 'react';
import ClassNames from 'classnames';
import './FormUnit.scss';

export enum FormUnitPadding {
	None = 'PaddingNone',
	Small = 'PaddingSmall',
	Normal = 'PaddingNormal',
	Large = 'PaddingLarge',
	XL = 'PaddingXL',
}

export enum FormUnitSize {
	Xlarge = 'SizeXLarge',
	Large = 'SizeLarge',
	Normal = 'SizeNormal',
	Small = 'SizeSmall',
	XSmall = 'SizeXSmall',
}

export enum FormLeftMargin {
	None = 'LeftMarginNone',
	Normal = 'LeftMarginNormal',
}

interface Props {
	className?: string;
	padding?: FormUnitPadding;
	leftMargin?: FormLeftMargin;
	size?: FormUnitSize;
	flex?: boolean;
}
 
export const FormUnit: React.FunctionComponent<Props> = (props) => {
    const classNames = ClassNames(
			props.className,
			props.size,
			props.padding,
			props.leftMargin,
			{ 'Flex': props.flex }
		);


	return (
		<div
			className={classNames}>
			{props.children}
		</div>
	);
};

FormUnit.defaultProps = {
	flex: false,
	className: 'FormUnit',
	size: FormUnitSize.Normal,
	padding: FormUnitPadding.Normal,
	leftMargin: FormLeftMargin.None
};
