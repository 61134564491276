import React from 'react';
import './Textarea.scss';
import ClassNames from 'classnames';

interface Props {
	id?: string;
	className?: string;
	value?: string;
	onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
	onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
	enabled?: boolean;
	touched?: boolean;
	hasErrors?: boolean;
	size?: 'small' | 'medium' | 'large';
	disableResize?: boolean;
	maxlength?: number;
}

export const Textarea: React.FunctionComponent<Props> = (props) => {

	const classNames = ClassNames(
		props.className,
		{
			error: props.touched && props.hasErrors,
			small: props.size === 'small',
			medium: props.size === 'medium',
			large: props.size === 'large',
			'no-resize': props.disableResize === true
		}
	);

	return (
		<textarea
			id={props.id}
			className={classNames}
			value={props.value}
			onChange={props.onChange}
			disabled={props.enabled === false}
			maxLength={props.maxlength}
		/>
	);
};

Textarea.defaultProps = {
	className: 'Textarea',
	hasErrors: false,
	touched: false,
	size: 'small'
}
