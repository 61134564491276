import React, { Children } from 'react';
import { AppComponent } from '../AppComponent';
import { Button } from '../widget/input/Button/Button';
import { ButtonGroup, ButtonGroupLayout } from '../widget/input/ButtonGroup/ButtonGroup';
import { StepContext } from './CaseSteps';
import { Messages, MessageItem } from '../widget/display/Messages/Messages';
import { T } from '../widget/display/T';

interface Props {
	context: StepContext;
	canGoHome?: boolean;
	canGoToPrev?: boolean;
	canGoToNext?: boolean;
	goHomeTextkey?: string;
	goToPrevTextkey?: string;
	goToNextTextkey?: string;
	onGoHome?: () => void;
	onGoToPrev?: () => void;
	onGoToNext?: () => void;
	onTriggerImmidiateValidation?: () => void;
}

interface State {
	hasTouchedNextButton?: boolean;
}

export class StepFooter extends AppComponent<Props, State> {
	constructor(props: Props) {
		super(props);

		this.state = {
			hasTouchedNextButton: false
		}

	}

	innerGoToNext = (): void => {

		let stepIsValid = this.props.context.getValidation().ok();
		if (stepIsValid === false) {
			this.setState({ hasTouchedNextButton: true })

			// Trigger function for 'visible' validation of validators (if implemented in parent)
			if (this.props.onTriggerImmidiateValidation)
				this.props.onTriggerImmidiateValidation();

			return
		}

		// Trigger goToNext function
		if (this.props.onGoToNext)
			this.props.onGoToNext();

	}


	render() {

		const canSave = this.props.context.canSave();

		let goHomeTextkey = this.props.goHomeTextkey
			? this.props.goHomeTextkey
			: 'GoHome';
		if (canSave) {
			goHomeTextkey += "WithSave";
		}

		let displayGenericErrorMessage = this.state.hasTouchedNextButton === true && this.props.context.getValidation().ok() !== true;
		// let goToPrevTextkey = this.props.goToPrevTextkey
		// 	? this.props.goToPrevTextkey
		// 	: 'GoToPrev';
		let goToNextTextkey = this.props.goToNextTextkey
			? this.props.goToNextTextkey
			: 'GoToNext';

		return (
			<div className="StepFooter">
				<ButtonGroup layout={ButtonGroupLayout.Stretch}>
					{this.props.canGoHome !== undefined && (
						<Button
							id="goHome"
							className="Button outline big green"
							labelTextkey={goHomeTextkey}
							onClick={this.props.onGoHome}
							enabled={
								this.props.canGoHome === true && this.props.onGoHome
									? true
									: false
							}
						/>
					)}

					{this.props.canGoToNext !== undefined && (
						<>
							<Button
								id="goToNext"
								className="Button big green"
								labelTextkey={goToNextTextkey}
								onClick={() => this.innerGoToNext()}
							/>
							<Messages visible={displayGenericErrorMessage} items={[{ textkey: "GoToNext_Invalid" }]} />
						</>
					)}
				</ButtonGroup>

			</div>
		)

	}
}
