
import React, {useState, useEffect} from "react"
import { useAuth } from "react-oidc-context";
import { useHistory } from 'react-router-dom'
import { Application } from "../../models/AppModels";
import StatusBar from "../layout/StatusBar/StatusBar";
import { Modal, Props } from '../../components/layout/Modal/Modal';
import { Button } from '../../components/widget/input/Button/Button';
import { T } from "../widget/display/T";

const Oidc = () => {
    const history = useHistory();
    const application = Application.instance;
    const sessionService = application.services.sessionService;
    const configService = application.services.configurationService;
    const auth = useAuth();
    const [hasTriedSignin, setHasTriedSignin] = useState(false);
    const [token, setToken] = useState<string | undefined>("");
    const [showModal, setShowModal] = useState<boolean>(false);

    const modalSettings :Props = {
      overlayCloseOnClick: false,
      handleClose: () => {
        setShowModal(false);
      }
    }

    const setCookie = (name, value): Promise<boolean> => {
      return new Promise((resolve) => {
        const d = new Date();
        d.setTime(d.getTime() + ((auth.user?.expires_at ?? 0) * 24 * 60 * 60 * 1000));
        document.cookie = name + "=" + value + ";" + "expires=" + d.toUTCString() + ";path=/";
        resolve(true);
      });
    }

    const deleteAllCookies = () => {
      return new Promise((resolve) => {
        const cookies = document.cookie.split(";");
        for (let i = 0; i < cookies.length; i++) {
          const cookie = cookies[i];
          const eqPos = cookie.indexOf("=");
          const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
          document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/";
        }
        resolve(true);
      });
    }

    const signInSilent = () => {
      auth.signinSilent().then(e => {
        if(e === null) {
          auth.signinRedirect();
        }
        setShowModal(false);
      })
    }
    
    const signOut = () => {
      sessionService.logout().then(_ => {
        deleteAllCookies().then(_ => {
          auth.signoutRedirect({"post_logout_redirect_uri" : configService.oidcSettings().postLogoutRedirectUri}) 
        })
      })
    } 

    useEffect(() => {
        if (
          !auth.isAuthenticated &&
          !auth.activeNavigator &&
          !auth.isLoading &&
          !hasTriedSignin
        ) {
          auth.signinRedirect();
          setHasTriedSignin(true);
        }
      }, [auth, hasTriedSignin]);

      useEffect(() => {
        if(auth.user?.access_token !== undefined) {
          setCookie("sme_user_access_token", auth.user?.access_token).then(x => {
            sessionService.loadSession().then((result) => {
              if(result.canSignIn)
                history.push('/');
              else
              { 
                history.push('/accessdenied')
              }
            });
          });
          setToken(auth.user?.access_token);
        }
      }, [auth.user?.access_token, token]);

      const handleSessionChange = () => {
        auth.removeUser();
      };

      useEffect(() => {
        if (auth.isAuthenticated) {
          auth.events.addUserSignedOut(handleSessionChange);
        }
        return () => {
          auth.events.removeUserSignedOut(handleSessionChange);
        };
      }, [auth]);
    
      React.useEffect(() => {
        return auth.events.addAccessTokenExpiring(() => {
            setShowModal(true);
        })
    }, [auth.events, auth.signinSilent]);

      
    return (
        <>
        {showModal && <Modal {...modalSettings}>
          <h3><T k={"Oidc_Modal_Heading"}/></h3>
            <p><T k={"Oidc_Modal_Text"}/></p>
            <Button labelTextkey="Oidc_Modal_Btn_LogIn" name="LogIn" onClick={ signInSilent } />
            <br />
            <Button labelTextkey="Oidc_Modal_Btn_LogOut" name="Logout" onClick={ signOut } />
          </Modal>
        }   
            {auth.activeNavigator === "signinSilent" && (<StatusBar Type="info" TextKey="Oidc_SigninSilent"/>)}
            {auth.activeNavigator === "signoutRedirect" && (<StatusBar Type="info" TextKey="Oidc_SignoutRedirect"/>)}
            {auth.isLoading && (<StatusBar Type="info" TextKey="Oidc_Loading"/>)}
            {auth.error && <StatusBar Type="error" TextKey="Oidc_Error"/>}
        </>
    )
}

export default Oidc