import { LPEmploymentEnum } from "../selmamodels/Applicant";
import { UIKeyed } from "./UIKeyed";
import { UINamed } from "./UINamed";

export interface UIEmploymentType extends UIKeyed, UINamed {
	type: LPEmploymentEnum;
}

export const uiEmploymentType: UIEmploymentType[] = [
	{ key: 'PERMANENT', name: 'Anställning, tillsvidareanställning', nameTextkey:"UIEmploymentType_PERMANENT", type: LPEmploymentEnum.PERMANENT },
	{ key: 'TEMPORARY', name: 'Tidbegränsad anställning', nameTextkey:"UIEmploymentType_TEMPORARY", type: LPEmploymentEnum.TEMPORARY},
	{ key: 'PROBATIONARY', name: ' Provanställning', nameTextkey:"UIEmploymentType_PROBATIONARY", type: LPEmploymentEnum.PROBATIONARY },
	{ key: 'SELF_EMPLOYED', name: 'Eget företag', nameTextkey:"UIEmploymentType_SELF_EMPLOYED", type: LPEmploymentEnum.SELF_EMPLOYED },
	{ key: 'PENSION', name: 'Inkomst av pension', nameTextkey:"UIEmploymentType_PENSION", type: LPEmploymentEnum.PENSION },
	{ key: 'UNEMPLOYMENT_INSURANCE', name: 'Arbetslöshetskassa', nameTextkey:"UIEmploymentType_UNEMPLOYMENT_INSURANCE", type: LPEmploymentEnum.UNEMPLOYMENT_INSURANCE },
	{ key: 'STUDENT_AID', name: 'Studiestöd', nameTextkey:"UIEmploymentType_STUDENT_AID", type: LPEmploymentEnum.STUDENT_AID },
];
