/* tslint:disable */
/* eslint-disable */
/**
 * SelmaSME API
 * This definition is the API between customer SME-Gui and the persistence layer for the application NOT FOR V1 When customer logg-in to Selma-EN, we will create a processId with applicant CustomerId (from BankID). When a customer added attachment to the application, we don\'t have a CaseId i LP, should we add a LP-Service to create/reserve a caseId to be able to attach a document to the caseId in DM.
 *
 * The version of the OpenAPI document: 0.7.4
 * Contact: developer@landshypotek.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ValueType,
    ValueTypeFromJSON,
    ValueTypeFromJSONTyped,
    ValueTypeToJSON,
} from './';

/**
 * Avser ett enskilt budgetår
 * @export
 * @interface BudgetYearType
 */
export interface BudgetYearType {
    /**
     * unique identifier for a specifict budget
     * @type {string}
     * @memberof BudgetYearType
     */
    budgetId?: string;
    /**
     * Budget avser år, ska generas av API, föregående år - 2 år. Value1-7 är intäkter och summeras i Loan Process
     * @type {number}
     * @memberof BudgetYearType
     */
    budgetyear?: number;
    /**
     * 
     * @type {ValueType}
     * @memberof BudgetYearType
     */
    budget?: ValueType;
}

export function BudgetYearTypeFromJSON(json: any): BudgetYearType {
    return BudgetYearTypeFromJSONTyped(json, false);
}

export function BudgetYearTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): BudgetYearType {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'budgetId': !exists(json, 'budgetId') ? undefined : json['budgetId'],
        'budgetyear': !exists(json, 'budgetyear') ? undefined : json['budgetyear'],
        'budget': !exists(json, 'budget') ? undefined : ValueTypeFromJSON(json['budget']),
    };
}

export function BudgetYearTypeToJSON(value?: BudgetYearType | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'budgetId': value.budgetId,
        'budgetyear': value.budgetyear,
        'budget': ValueTypeToJSON(value.budget),
    };
}


