import React from 'react';
import { Case } from '../../../../../models/SelmaModels';
import { AppComponent } from '../../../../AppComponent';
import Accordion from '../../../../layout/Accordion/Accordion';
import {
	FormUnit,
	FormUnitSize,
	FormUnitPadding,
} from '../../../../layout/FormUnit/FormUnit';
import { CollateralRealEstateSummary } from './CollateralRealEstateSummary';
import { CollateralEUSupportSummary } from './CollateralEUSupportSummary';
import { CollateralGuarantorsSummary } from './CollateralGurantorsSummary';
import { Heading } from '../../../../widget/display/Heading/Heading';

interface Props {
	case: Case;
	accordionOpen?: boolean;
	formUnitSize?: FormUnitSize;
}

export class CollateralSummary extends AppComponent<Props> {
	constructor(props: Props) {
		super(props);
	}

	static defaultProps = {
		accordionOpen: false,
		formUnitSize: FormUnitSize.Large
	};

	render() {
		const c = this.props.case;
		const hasCollateral = this.application.services.collateralService.hasCollateral(c);
		const hasEuSupport = this.application.services.euSupportService.hasEuSupport(c);
		const hasGuarantor = this.application.services.guarantorService.hasGuarantor(c);

		if( !hasCollateral && !hasEuSupport && !hasGuarantor )
			return null;

		return (
			<div className="CollateralSummary">
				<FormUnit
					size={this.props.formUnitSize}
					padding={FormUnitPadding.Small}>
					<Accordion
						title={<Heading level={2} labelTextkey="Summary_Collateral_Title"/>}
						open={this.props.accordionOpen}>
						<CollateralRealEstateSummary case={c} />
						<CollateralEUSupportSummary case={c} />
						<CollateralGuarantorsSummary case={c} />
					</Accordion>
				</FormUnit>
			</div>
		);
	}
}
