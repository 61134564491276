import { BaseService } from './BaseService';
import { Case, KYC, Household, AttachmentRequest, Attachment, Collateral } from '../models/SelmaModels';
import { Applicant } from "../models/selmamodels/Applicant";
import { SMELPOService } from './SMELPOService';
import { StateService } from './StateService';
import { ValidationResult } from '../utils/ValidationResult';
import { GuardService } from './GuardService';
import { IdService } from './IdService';
import { Company } from '../models/selmamodels/Company';
import { LpoModelConverter } from '../models/LpoModelConverter';
import { ConfigurationService } from './ConfigurationService';
import { StakeholderType, CustomerIdType } from '../libs/SMELPOClient';
import { CompanyService } from './CompanyService';
import { SearchApplicantResult } from '../models/extendedlpo/ApplicantSearch';
import { HouseholdService } from './HouseholdService';
export interface SearchForApplicantResult {
	applicant?: Applicant;
	companies?: Company[];
	tooManyRequests: boolean
}

export class ApplicantService extends BaseService {

	companyService?: CompanyService;
	householdService?: HouseholdService;

	constructor(private stateService: StateService, private smelpoService: SMELPOService,
		private guardService: GuardService, private idService: IdService,
		private configurationService: ConfigurationService) {
		super();
	}

	update(obj: Applicant | KYC | Case): void {
		this.stateService.update(obj);
	}


	newApplicant(c: Case, ssn: string): Applicant {
		let applicant: Applicant = {
			id: this.idService.newGuid(),
			customerId: ssn,
		};
		return applicant;
	}

	newKyc(): KYC {
		return {
			id: this.idService.newGuid(),
			approvedCreditCheck: false,
			approvedInfoHandling: false,
			approvedInfoSharing: false
		}
	}

	ensureKyc(applicant: Applicant): KYC {
		if (applicant.kyc) {
			return applicant.kyc;
		} else {
			const kyc = this.newKyc();
			applicant.kyc = kyc;
			this.update(applicant);
			return kyc;
		}

	}

	addApplicant(c: Case, applicant: Applicant): Applicant {
		if (!c.applicants) {
			c.applicants = [applicant];
			this.ensureCustomer(c, applicant.customerId);
			this.afterApplicantChange(c);
			this.update(c);
			return applicant;
		} else {
			this.ensureCustomer(c, applicant.customerId);
			let existingApplicant = this.getApplicantBySsn(c, applicant.customerId);
			if (existingApplicant) {
				return existingApplicant;
			} else {
				c.applicants.push(applicant);
				this.afterApplicantChange(c);
				this.update(c);
				return applicant;
			}
		}
	}

	getCustomer(c: Case, customerId: string): CustomerIdType | undefined {
		if (!c.customers)
			return;
		let r = c.customers.find(x => { return this.guardService.compareCustomerId(x.customerId || '', customerId) });
		return r;
	}

	ensureCustomer(c: Case, customerId: string): void {
		if (!c.customers)
			c.customers = [];
		let customer = this.getCustomer(c, customerId);
		if (!customer) {
			c.customers.push({
				customerId: customerId,
				customerAdded: new Date().toUTCString()
			});
			this.update(c);
		}
	}

	removeCustomer(c: Case, customerId: string): void {
		let customer = this.getCustomer(c, customerId);
		if (customer) {
			this.stateService.listRemove(c.customers, customer);
		}
	}

	hasApplicants(c: Case): boolean {
		if (c.applicants && c.applicants.length > 0)
			return true;
		return false;
	}

	getApplicants(c: Case): Applicant[] {
		if (!c.applicants)
			return [];
		return c.applicants;
	}

	getPhysicumApplicants(c: Case): Applicant[] {
		let applicants = this.getApplicants(c);
		if (applicants.length === 0)
			return applicants;

		let r = applicants.filter(x => {
			let isPP = this.isPhysicum(c, x);
			return isPP;
		});
		return r;
	}

	setWillBuyAgriForestryProperty(c: Case, applicant: Applicant, checked: boolean) {
		if (applicant.willBuyAgriForestryProperty === checked)
			return;

		applicant.willBuyAgriForestryProperty = checked;

		if (checked) {
			// Remove current selected company, if any.
			const companyService = this.companyService;
			if (companyService) {
				const company = companyService.getApplicantCompany(c, applicant);
				if (company) {
					companyService.removeCompany(c, company);
				}
			}
		}
		this.afterApplicantChange(c);
		this.update(applicant);
	}

	validateMyApplicantKycApproval(c: Case): ValidationResult {
		let myApplicant = this.getMyApplicant(c);
		return this.validateApplicantKycApproval(myApplicant);
	}
	validateApplicantKycApproval(applicant: Applicant): ValidationResult {
		let vr = new ValidationResult();
		vr.add({ validator: this.hasKycApprovedCreditCheck, object: applicant, ok: this.hasKycApprovedCreditCheck(applicant) });
		vr.add({ validator: this.hasKycApprovedInfoHandling, object: applicant, ok: this.hasKycApprovedInfoHandling(applicant) });
		vr.add({ validator: this.hasKycApprovedInfoSharing, object: applicant, ok: this.hasKycApprovedInfoSharing(applicant) });
		return vr;
	}

	hasKycApprovedCreditCheck(applicant: Applicant): boolean {
		return applicant && applicant.kyc && applicant.kyc.approvedCreditCheck ? true : false;
	}
	hasKycApprovedInfoHandling(applicant: Applicant): boolean {
		return applicant && applicant.kyc && applicant.kyc.approvedInfoHandling ? true : false;
	}
	hasKycApprovedInfoSharing(applicant: Applicant): boolean {
		return applicant && applicant.kyc && applicant.kyc.approvedInfoSharing ? true : false;
	}

	myApplicantHasGivenFullApproval(c: Case): boolean {
		let applicant = this.getMyApplicant(c);
		if (!applicant || !applicant.kyc)
			return false;

		return this.applicantHasGivenFullApproval(applicant);
	}

	applicantHasGivenFullApproval(applicant: Applicant): boolean {
		let kyc = applicant.kyc;
		if (!kyc)
			return false;
		return kyc.approvedCreditCheck === true
			&& kyc.approvedInfoHandling === true
			&& kyc.approvedInfoSharing === true;
	}

	allApplicantsHaveGivenFullApproval(c: Case): boolean {
		if (!c.applicants) {
			return true;
		}
		for (let applicant of c.applicants) {
			if (applicant && !this.applicantHasGivenFullApproval(applicant)) {
				return false;
			}
		}
		return true;
	}

	getApplicantByCustomerId(c: Case, customerId: string): Applicant | undefined {
		if (!c.applicants)
			return;

		let applicants = c.applicants.filter(x => x.customerId === customerId);
		if (applicants && applicants.length === 1)
			return applicants[0];
		else
			return;
	}

	getApplicantBySsn(c: Case, ssn: string): Applicant | undefined {
		if (!c.applicants)
			return;
		const g = this.guardService;
		let applicants = c.applicants.filter(x => x.customerId && g.compareSsn(x.customerId, ssn))
		if (applicants && applicants.length === 1)
			return applicants[0];
		else
			return;
	}

	getMyApplicant(c: Case): Applicant {
		if (!c.applicants)
			throw new Error("No applicants on case.");

		if (!this.stateService.state.session || !this.stateService.state.session.login)
			throw new Error("No session and login.");

		let login = this.stateService.state.session.login;
		let applicant = this.getApplicantBySsn(c, login.ssn);
		if (!applicant)
			throw new Error("No my applicant.");
		return applicant;
	}

	getMainApplicant(c: Case): Applicant {
		if (!c.applicants)
			throw new Error("No main applicant on case (no applicants).");

		if (c.applicants.length === 1)
			return c.applicants[0];

		let customer: CustomerIdType | undefined;

		c.customers.forEach(x => {
			if (!customer)
				customer = x;
			else if (x.customerAdded && customer.customerAdded
				&& x.customerAdded < customer.customerAdded) {
				customer = x;
			}
		})

		let applicant = customer && customer.customerId
			? this.getApplicantBySsn(c, customer.customerId) : undefined;

		if (!applicant)
			throw new Error("No main applicant on case.");

		return applicant;
	}


	removeApplicant = (c: Case, applicant: Applicant): void => {
		if (!c.applicants)
			return;

		const g = this.guardService;
		this.stateService.listRemove(c.applicants, applicant);

		this.removeCustomer(c, applicant.customerId);

		// Remove applicant from households.
		if (c.households) {
			let householdsToRemove: Household[] = [];
			c.households.forEach(hh => {
				if (hh.members) {
					let member = hh.members.find(x => { return g.compareCustomerId(x.householdMember || '', applicant.customerId); });
					if (member) {
						this.stateService.listRemove(hh.members, member);
						if (hh.members.length === 0) {
							householdsToRemove.push(hh); // household lost its only member.
						}
					}
				}
			})

			// Remove households that lost their last member.
			householdsToRemove.forEach(x => {
				if (this.householdService)
					this.householdService.removeHousehold(c, x);
			});
		}

		// Remove applicant from extloans.
		if (c.extLoans) {
			c.extLoans.forEach(x => {
				if (x.loanOwners) {
					let loanOwner = x.loanOwners.find(o => { return g.compareCustomerId(o.customerId, applicant.customerId); });
					if (loanOwner) {
						this.stateService.listRemove(x.loanOwners, loanOwner);
					}
				}
			})
		}

		// Remove applicant from maintenance cost/house.
		if (c.maintenanceCosts) {
			c.maintenanceCosts.forEach(x => {
				if (x.typeofhouses) {
					x.typeofhouses.forEach(h => {
						if (h.loanOwners) {
							let loanOwner = h.loanOwners.find(o => { return g.compareCustomerId(o.customerId, applicant.customerId); });
							if (loanOwner) {
								this.stateService.listRemove(h.loanOwners, loanOwner);
							}
						}
					})
				}
			})
		}

		// Remove attachments.
		if (c.attachments) {
			let remove: Attachment[] = [];
			c.attachments.forEach(x => {
				if (x.customerId && g.compareCustomerId(x.customerId, applicant.customerId)) {
					remove.push(x);
				}
			})
			remove.forEach(x => {
				if (c.attachments)
					this.stateService.listRemove(c.attachments, x);
			});
		}

		// Remove attachment requests.
		if (c.attachmentRequests) {
			let remove: AttachmentRequest[] = [];
			c.attachmentRequests.forEach(x => {
				if (x.customerId && g.compareCustomerId(x.customerId, applicant.customerId)) {
					remove.push(x);
				}
			})
			remove.forEach(x => {
				if (c.attachmentRequests)
					this.stateService.listRemove(c.attachmentRequests, x);
			});
		}

		// Remove collateral estate of applicant.
		if (c.collaterals) {
			let remove: Collateral[] = [];
			c.collaterals.forEach(x => {
				if (x.customerId && g.compareCustomerId(x.customerId, applicant.customerId)) {
					remove.push(x);
				}
			});
			remove.forEach(x => {
				if (c.collaterals)
					this.stateService.listRemove(c.collaterals, x);
			});
		}

		// Remove company connected to applicant, and all its related data.
		if (this.companyService) {
			let company = this.companyService.getApplicantCompany(c, applicant);
			if (company) {
				this.companyService.removeCompany(c, company);
			}
		}

		this.afterApplicantChange(c);

		this.update(c);
	}

	// Perform changes after set of applicants changed.
	private afterApplicantChange(c: Case): void {
		const householdService = this.householdService;
		if (householdService) {
			const shouldHaveHouseholds = householdService.shouldHaveHouseholds(c);
			if (shouldHaveHouseholds)
				householdService.ensureHouseholds(c);
			else {
				householdService.removeAllHouseholds(c);
			}
		}
	}

	canRemoveApplicant(c: Case, applicant: Applicant) {
		const myApplicant = this.getMyApplicant(c);
		const mainApplicant = this.getMainApplicant(c);
		const isMe = applicant === myApplicant;
		const isMain = applicant === mainApplicant;
		const canNotDelete = isMe || isMain || !c.applicants || c.applicants.length <= 1;
		const canDelete = !canNotDelete;
		return canDelete;
	}


	getFullName(c: Case, applicant: Applicant): string {
		if (this.companyService) {
			const company = this.companyService.getApplicantCompany(c, applicant);
			if (company) {
				return this.companyService.getDisplayName(company)
			}
		}
		return this.getPersonFullName(applicant);
	}

	getPersonFullName(applicant: Applicant): string {
		let name = applicant.firstName;
		if (!name || name.length === 0)
			name = applicant.lastName;
		else if (applicant.lastName && applicant.lastName.length > 0)
			name = name + " " + applicant.lastName;
		if (!name)
			name = "";
		return name;
	}

	validateApplicant(applicant: Applicant): ValidationResult {
		const g = this.guardService;
		let vf = this.validateApplicant;
		let vr = new ValidationResult();
		vr.add({ validator: vf, ok: g.hasValue(applicant.customerId) });
		vr.add({ validator: vf, ok: g.hasValue(applicant.firstName) });
		vr.add({ validator: vf, ok: g.hasValue(applicant.lastName) });
		return vr;
	}



	validateApplicantContact(applicant: Applicant): ValidationResult {

		// TODO: Only if applicant is P or EF, not AB.

		let vr = new ValidationResult();
		vr.add({ validator: this.hasValidEmail, object: applicant, ok: this.hasValidEmail(applicant) });
		vr.add({ validator: this.hasMatchingConfirmEmail, object: applicant, ok: this.hasMatchingConfirmEmail(applicant) });
		vr.add({ validator: this.hasValidMobilePhone, object: applicant, ok: this.hasValidMobilePhone(applicant) });
		return vr;
	}

	hasValidEmail(applicant: Applicant): boolean {
		const g = this.guardService;
		return applicant.email && g.isValidEmail(applicant.email) ? true : false;
	}

	hasMatchingConfirmEmail(applicant: Applicant): boolean {
		if (!applicant.email && !applicant.emailConfirm)
			return true;

		let a = applicant.email || '';
		let b = applicant.emailConfirm || '';

		return a.toLowerCase() === b.toLowerCase();
	}

	hasValidMobilePhone(applicant: Applicant): boolean {
		const g = this.guardService;
		return applicant.mobilePhone && g.isValidMobileNumber(applicant.mobilePhone) ? true : false;
	}

	reformatMobilePhone(applicant: Applicant):void {
		const numericMobilePhone = applicant.mobilePhone?.replace(/\D/g, '') ?? '';

		if (numericMobilePhone.length === 10) {
			applicant.mobilePhone = numericMobilePhone.slice(0, 3) + '-' + numericMobilePhone.slice(3);
			this.update(applicant);
		}
	}

	validateApplicantPep(applicant: Applicant): ValidationResult {
		// TODO: Only if applicant is P or EF, not AB.
		let vr = new ValidationResult();
		vr.add({ validator: this.hasKycPep, object: applicant, ok: this.hasKycPep(applicant) });
		vr.add({ validator: this.hasKycPepRelated, object: applicant, ok: this.hasKycPepRelated(applicant) });
		vr.add({ validator: this.hasKycAmericanCitizen, object: applicant, ok: this.hasKycAmericanCitizen(applicant) });
		vr.add({ validator: this.hasKycCrsTaxObligation, object: applicant, ok: this.hasKycCrsTaxObligation(applicant) });
		return vr;
	}

	hasKycPep(applicant: Applicant): boolean {
		const g = this.guardService;
		return applicant.kyc && g.hasValue(applicant.kyc.isPep) ? true : false
	}

	hasKycPepRelated(applicant: Applicant): boolean {
		const g = this.guardService;
		return applicant.kyc && g.hasValue(applicant.kyc.isPepRelated) ? true : false
	}

	hasKycAmericanCitizen(applicant: Applicant): boolean {
		const g = this.guardService;
		return applicant.kyc && g.hasValue(applicant.kyc.isAmericanCitizen) ? true : false
	}

	hasKycCrsTaxObligation(applicant: Applicant): boolean {
		const g = this.guardService;
		return applicant.kyc && g.hasValue(applicant.kyc.isCrsTaxObligated) ? true : false
	}

	hasSelectedHighRiskCategory(applicant: Applicant): boolean {
		return applicant &&
			applicant.kyc !== undefined &&
			this.guardService.hasValue(applicant.kyc.highRiskCategoryCode);
	}

	hasSelectedHighRiskCategoryAndItIsRisky(applicant: Applicant): boolean {
		let highRiskCategoryCode = this.getHighRiskCategory(applicant);
		return highRiskCategoryCode && highRiskCategoryCode !== '0' ? true : false;
	}

	getHighRiskCategory(applicant: Applicant): string | undefined {
		return applicant && applicant.kyc && applicant.kyc.highRiskCategoryCode;
	}

	validateHasSelectedHighRiskCategory(applicant: Applicant): ValidationResult {
		let vr = new ValidationResult();
		vr.add({ validator: this.hasSelectedHighRiskCategory, object: applicant, ok: this.hasSelectedHighRiskCategory(applicant) });
		return vr;
	}

	clearHighRiskCategory(applicant: Applicant): void {
		if (applicant &&
			applicant.kyc !== undefined &&
			this.guardService.hasValue(applicant.kyc.highRiskCategoryCode)) {
			applicant.kyc.highRiskCategoryCode = undefined;
			this.update(applicant);
			this.update(applicant.kyc);
		}
	}

	selectHighRiskCategory(applicant: Applicant, value: string) {
		if (!applicant.kyc)
			applicant.kyc = this.newKyc();
		if (applicant.kyc) {
			applicant.kyc.highRiskCategoryCode = value;
			this.update(applicant);
			this.update(applicant.kyc);
		}
	}

	toSearchForApplicantResult(result: SearchApplicantResult): SearchForApplicantResult {
		let r: SearchForApplicantResult = {
			applicant: LpoModelConverter.toApplicantOrUndefined(result.applicant),
			companies: LpoModelConverter.toCompanies(result.companies),
			tooManyRequests: false
		}
		return r;
	}

	searchForApplicant(c: Case, ssnOrOrgNumber: string): Promise<SearchForApplicantResult> {
		return this.smelpoService
			.searchApplicant(c.id, ssnOrOrgNumber)
			.then(result => {
				let r = this.toSearchForApplicantResult(result);
				return r;
			});
	}

	isPhysicum(c: Case, applicant: Applicant): boolean {
		let start = applicant.customerId.substr(0, 2);
		let isPhysicum = start === "18" || start === "19" || start === "20" || start === "21" || start === "10";
		if (this.companyService) {
			let company = this.companyService.getApplicantCompany(c, applicant);
			if (company && this.companyService.isJuridicum(company)) {
				isPhysicum = false;
			}
		}
		return isPhysicum;
	}

	isJuridicum(c: Case, applicant: Applicant): boolean {
		return !this.isPhysicum(c, applicant);
	}

	isPrivatePerson(c: Case, applicant: Applicant): boolean {
		return this.isPhysicum(c, applicant)
			&& !this.isPersonalCompany(c, applicant);
	}

	isPersonalCompany(c: Case, applicant: Applicant): boolean {
		if (this.isPhysicum(c, applicant)) {
			if (this.companyService) {
				let company = this.companyService.getApplicantCompany(c, applicant);
				if (company && this.companyService.isPhysicum(company)) {
					return true;
				}
			}
		}
		return false;
	}

	// isCompany(c:Case, applicant: Applicant) : boolean {
	// 	let company = this.companyService && 
	// 		this.companyService.getApplicantCompany(c, applicant);
	// 	if( company ) {
	// 		return true;
	// 	}
	// 	return false;
	// }

	isJuridicumCompany(c: Case, applicant: Applicant): boolean {
		if (!this.companyService)
			return false;

		const companyService = this.companyService;

		if (this.isJuridicum(c, applicant)) {
			let company = companyService.getApplicantCompany(c, applicant);
			if (company && companyService.isJuridicum(company)) {
				return true;
			}
		} else {
			// BUGFIX:
			if (c.companies) {
				let selectedCompanies = companyService.getSelectedCompaniesInArray(c.companies, applicant);
				if (selectedCompanies && selectedCompanies.length > 0) {
					let ret = selectedCompanies.filter(x => { return companyService.isJuridicum(x); });
					if (ret.length > 0)
						return true;
				}
			}
		}
		return false;
	}

	showPepForApplicant(a:Applicant): boolean {
		const { isAmericanCitizen, isCrsTaxObligated, isPep, isPepRelated } = a.kyc ?? {}
		if(a.kyc == undefined || (isAmericanCitizen == undefined && isCrsTaxObligated == undefined && isPep == undefined && isPepRelated == undefined)) {
			return false;
		}
		return true;
	}

}
