import React from 'react';
import ReactSelect, { components } from 'react-select';
import SelectCustomStyles from './SelectCustomStyles';
import { T } from '../../display/T';
import './Select.scss';
import { Application } from '../../../../models/AppModels';

const customStyles = SelectCustomStyles;

const DropdownIndicator = (props) => {
	return (
		<components.DropdownIndicator {...props}>
			<img src="/images/select-arrows-white.svg" />
		</components.DropdownIndicator>
	);
};

const ClearIndicator = (props) => {
	return (
		<components.ClearIndicator {...props}>
			<img src="/images/select-cross-white.svg" />
		</components.ClearIndicator>
	);
};

const GetText = (key) => {
	return <T k={key} />;
};

export interface SelectOption {
	value: string;
	label: string;
}

interface Props {
	className?: string;
	enabled?: boolean;
	handleBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
	handleChange: (value: string) => void;
	id: string;
	isMulti?: boolean;
	isSearchable?: boolean;
	noSelectableOptionsTextkey: string;
	options: SelectOption[];
	placeholderTextkey: string;
	value: string;
	hasErrors?: boolean;
	touched?: boolean;
	dirty?: boolean;
}

export const Select: React.FunctionComponent<Props> = (props) => {

	let value;

	if (props.options) {
		value = props.options.find((option) => option.value === props.value);
		if (value === undefined) {
			value = '';
		}
	} else {
		value = '';
	}

	//const noSelectableOptionText = GetText(props.noSelectableOptionsTextkey);
	let noSelectableOptionsText:string|null = null;
	if( props.noSelectableOptionsTextkey) {
		let t = Application.instance.services.textService.text(props.noSelectableOptionsTextkey);
		if( t ) 
			noSelectableOptionsText = t;
	}

	return (
		<ReactSelect
			autocomlete="off"
			className={props.className}
			styles={customStyles(props.hasErrors, props.touched)}
			options={props.options}
			inputId={props.id}
			components={{ DropdownIndicator, ClearIndicator }}
			placeholder={GetText(props.placeholderTextkey)}
			noOptionsMessage={() => noSelectableOptionsText }
			value={value}
			isSearchable={props.isSearchable}
			isMulti={props.isMulti}
			isDisabled={props.enabled === false ? true : false}
			onChange={(option) => props.handleChange(option.value)}
			onBlur={props.handleBlur}
			inputProps={{autocomplete:'off'}}
		/>
	);
};

Select.defaultProps = {
	className: 'Select',
	isSearchable: false,
	isMulti: false,
	enabled: true,
	touched: false,
	hasErrors: false,
	dirty: false,
};
