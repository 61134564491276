import React from 'react';
import { T } from '../T';
import { Required } from '../Required/Required';
import { Help } from '../Help/Help';
import { Application } from '../../../../models/AppModels';
import './Label.scss';

interface Props {
	labelTextkey?: string;
	labelText?: string;
	for?: string;
	className?: string;
	required?: boolean; // adds a marker for required fields
}

export const Label: React.FunctionComponent<Props> = (props) => {

	const helpTextkey = (props.labelTextkey) ? props.labelTextkey + '_Help' : null;
	const hasValidHelpTextkey = (helpTextkey && Application.instance.services.textService.text(helpTextkey))
			? true
			: false;

	return (
		<label
			className={props.className}
			htmlFor={props.for}
			data-textkey={props.labelTextkey}>
			{props.labelTextkey && <T k={props.labelTextkey} />}
			{props.labelText && props.labelText}
			{props.required && <Required />}
			{hasValidHelpTextkey && (
				<Help helpTextkey={props.labelTextkey + '_Help'} />
			)}
		</label>
	);
};


// Default props
Label.defaultProps = {
	className: 'Label',
};
