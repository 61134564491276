/* tslint:disable */
/* eslint-disable */
/**
 * SelmaSME API
 * This definition is the API between customer SME-Gui and the persistence layer for the application NOT FOR V1 When customer logg-in to Selma-EN, we will create a processId with applicant CustomerId (from BankID). When a customer added attachment to the application, we don\'t have a CaseId i LP, should we add a LP-Service to create/reserve a caseId to be able to attach a document to the caseId in DM.
 *
 * The version of the OpenAPI document: 0.7.4
 * Contact: developer@landshypotek.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ErrorModel,
    ErrorModelFromJSON,
    ErrorModelToJSON,
    LinksAll,
    LinksAllFromJSON,
    LinksAllToJSON,
    ProcessType,
    ProcessTypeFromJSON,
    ProcessTypeToJSON,
    ProcessesType,
    ProcessesTypeFromJSON,
    ProcessesTypeToJSON,
} from '../models';

export interface AddProcessRequest {
    process?: ProcessType;
}

export interface DeleteProcessRequest {
    xProcessID: string;
    caseIdStatus: DeleteProcessCaseIdStatusEnum;
}

export interface GetProcessAllRequest {
    xProcessID: string;
}

export interface GetProcessesRequest {
    customerId: string;
}

export interface ReserveCaseIdRequest {
    xProcessID: string;
    caseIdStatus: ReserveCaseIdCaseIdStatusEnum;
    customerId: string;
}

export interface SaveProcessAllRequest {
    processSaveAll?: LinksAll;
}

export interface UpdateProcessStatusRequest {
    xProcessID: string;
    caseIdStatus: UpdateProcessStatusCaseIdStatusEnum;
    caseId?: string;
}

/**
 * no description
 */
export class ProcessesApi extends runtime.BaseAPI {

    /**
     * This operation will return a processid that has to be used i header X-process-ID for all other requests in all APIs. As parameter we will allways be using STARTEDBYAPPLICANT
     * Operation will be invoked when a customer want to create a new application
     */
    async addProcessRaw(requestParameters: AddProcessRequest): Promise<runtime.ApiResponse<ProcessType>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("application", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/v1/addprocess`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ProcessTypeToJSON(requestParameters.process),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ProcessTypeFromJSON(jsonValue));
    }

    /**
     * This operation will return a processid that has to be used i header X-process-ID for all other requests in all APIs. As parameter we will allways be using STARTEDBYAPPLICANT
     * Operation will be invoked when a customer want to create a new application
     */
    async addProcess(requestParameters: AddProcessRequest): Promise<ProcessType> {
        const response = await this.addProcessRaw(requestParameters);
        return await response.value();
    }

    /**
     * The customer has choice to end the application process, the whole X-process-ID will be CLOSEDBYAPPLICANT and will be removed during the batch thinning process in Loan Process. A call to LP is required to set ststus on the reserved caseid.
     * Set status CLOSEDBYAPPLICANT at the X-process-ID in the model
     */
    async deleteProcessRaw(requestParameters: DeleteProcessRequest): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.xProcessID === null || requestParameters.xProcessID === undefined) {
            throw new runtime.RequiredError('xProcessID','Required parameter requestParameters.xProcessID was null or undefined when calling deleteProcess.');
        }

        if (requestParameters.caseIdStatus === null || requestParameters.caseIdStatus === undefined) {
            throw new runtime.RequiredError('caseIdStatus','Required parameter requestParameters.caseIdStatus was null or undefined when calling deleteProcess.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xProcessID !== undefined && requestParameters.xProcessID !== null) {
            headerParameters['X-process-ID'] = String(requestParameters.xProcessID);
        }

        if (requestParameters.caseIdStatus !== undefined && requestParameters.caseIdStatus !== null) {
            headerParameters['caseIdStatus'] = String(requestParameters.caseIdStatus);
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("application", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/v1/deleteprocess`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * The customer has choice to end the application process, the whole X-process-ID will be CLOSEDBYAPPLICANT and will be removed during the batch thinning process in Loan Process. A call to LP is required to set ststus on the reserved caseid.
     * Set status CLOSEDBYAPPLICANT at the X-process-ID in the model
     */
    async deleteProcess(requestParameters: DeleteProcessRequest): Promise<object> {
        const response = await this.deleteProcessRaw(requestParameters);
        return await response.value();
    }

    /**
     * Return all data for all object in the activiti process regardless if the contains any information, empty objects will return null
     * Return all information object for a given processid
     */
    async getProcessAllRaw(requestParameters: GetProcessAllRequest): Promise<runtime.ApiResponse<LinksAll>> {
        if (requestParameters.xProcessID === null || requestParameters.xProcessID === undefined) {
            throw new runtime.RequiredError('xProcessID','Required parameter requestParameters.xProcessID was null or undefined when calling getProcessAll.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xProcessID !== undefined && requestParameters.xProcessID !== null) {
            headerParameters['X-process-ID'] = String(requestParameters.xProcessID);
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("application", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/v1/getprocess`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => LinksAllFromJSON(jsonValue));
    }

    /**
     * Return all data for all object in the activiti process regardless if the contains any information, empty objects will return null
     * Return all information object for a given processid
     */
    async getProcessAll(requestParameters: GetProcessAllRequest): Promise<LinksAll> {
        const response = await this.getProcessAllRaw(requestParameters);
        return await response.value();
    }

    /**
     * Return a list of active applications for a specific customer.
     * get a list of active processes for a in:body parameter customerId
     */
    async getProcessesRaw(requestParameters: GetProcessesRequest): Promise<runtime.ApiResponse<ProcessesType>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling getProcesses.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("application", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/v1/getprocesses`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.customerId as any,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ProcessesTypeFromJSON(jsonValue));
    }

    /**
     * Return a list of active applications for a specific customer.
     * get a list of active processes for a in:body parameter customerId
     */
    async getProcesses(requestParameters: GetProcessesRequest): Promise<ProcessesType> {
        const response = await this.getProcessesRaw(requestParameters);
        return await response.value();
    }

    /**
     * Reserve a CaseId in Loan Process to be able to use attachment i DM. In this case parameter caseIdStatus should be set to enumeration of STARTEDBYAPPLICANT
     * Reserve a CaseId in Loan Process
     */
    async reserveCaseIdRaw(requestParameters: ReserveCaseIdRequest): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.xProcessID === null || requestParameters.xProcessID === undefined) {
            throw new runtime.RequiredError('xProcessID','Required parameter requestParameters.xProcessID was null or undefined when calling reserveCaseId.');
        }

        if (requestParameters.caseIdStatus === null || requestParameters.caseIdStatus === undefined) {
            throw new runtime.RequiredError('caseIdStatus','Required parameter requestParameters.caseIdStatus was null or undefined when calling reserveCaseId.');
        }

        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling reserveCaseId.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xProcessID !== undefined && requestParameters.xProcessID !== null) {
            headerParameters['X-process-ID'] = String(requestParameters.xProcessID);
        }

        if (requestParameters.caseIdStatus !== undefined && requestParameters.caseIdStatus !== null) {
            headerParameters['caseIdStatus'] = String(requestParameters.caseIdStatus);
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("application", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/v1/reserveeaseid`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.customerId as any,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Reserve a CaseId in Loan Process to be able to use attachment i DM. In this case parameter caseIdStatus should be set to enumeration of STARTEDBYAPPLICANT
     * Reserve a CaseId in Loan Process
     */
    async reserveCaseId(requestParameters: ReserveCaseIdRequest): Promise<string> {
        const response = await this.reserveCaseIdRaw(requestParameters);
        return await response.value();
    }

    /**
     * To be able to implement a service that save all information object
     * Save all information object for a given processid
     */
    async saveProcessAllRaw(requestParameters: SaveProcessAllRequest): Promise<runtime.ApiResponse<object>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("application", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/v1/saveprocess`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LinksAllToJSON(requestParameters.processSaveAll),
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * To be able to implement a service that save all information object
     * Save all information object for a given processid
     */
    async saveProcessAll(requestParameters: SaveProcessAllRequest): Promise<object> {
        const response = await this.saveProcessAllRaw(requestParameters);
        return await response.value();
    }

    /**
     * When we create/reserve a caseId in LP we also have to set the status
     * set status on the reserved caseId
     */
    async updateProcessStatusRaw(requestParameters: UpdateProcessStatusRequest): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.xProcessID === null || requestParameters.xProcessID === undefined) {
            throw new runtime.RequiredError('xProcessID','Required parameter requestParameters.xProcessID was null or undefined when calling updateProcessStatus.');
        }

        if (requestParameters.caseIdStatus === null || requestParameters.caseIdStatus === undefined) {
            throw new runtime.RequiredError('caseIdStatus','Required parameter requestParameters.caseIdStatus was null or undefined when calling updateProcessStatus.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xProcessID !== undefined && requestParameters.xProcessID !== null) {
            headerParameters['X-process-ID'] = String(requestParameters.xProcessID);
        }

        if (requestParameters.caseIdStatus !== undefined && requestParameters.caseIdStatus !== null) {
            headerParameters['caseIdStatus'] = String(requestParameters.caseIdStatus);
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("application", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/v1/updateprocessstatus`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.caseId as any,
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * When we create/reserve a caseId in LP we also have to set the status
     * set status on the reserved caseId
     */
    async updateProcessStatus(requestParameters: UpdateProcessStatusRequest): Promise<object> {
        const response = await this.updateProcessStatusRaw(requestParameters);
        return await response.value();
    }

}

/**
    * @export
    * @enum {string}
    */
export enum DeleteProcessCaseIdStatusEnum {
    CLOSEDBYAPPLICANT = 'CLOSEDBYAPPLICANT',
    STARTEDBYAPPLICANT = 'STARTEDBYAPPLICANT',
    CLOSEDBYOFFICER = 'CLOSEDBYOFFICER',
    CLOSEDBYTHINNING = 'CLOSEDBYTHINNING',
    READYFOROFFICER = 'READYFOROFFICER'
}
/**
    * @export
    * @enum {string}
    */
export enum ReserveCaseIdCaseIdStatusEnum {
    CLOSEDBYAPPLICANT = 'CLOSEDBYAPPLICANT',
    STARTEDBYAPPLICANT = 'STARTEDBYAPPLICANT',
    CLOSEDBYOFFICER = 'CLOSEDBYOFFICER',
    CLOSEDBYTHINNING = 'CLOSEDBYTHINNING',
    READYFOROFFICER = 'READYFOROFFICER'
}
/**
    * @export
    * @enum {string}
    */
export enum UpdateProcessStatusCaseIdStatusEnum {
    CLOSEDBYAPPLICANT = 'CLOSEDBYAPPLICANT',
    STARTEDBYAPPLICANT = 'STARTEDBYAPPLICANT',
    CLOSEDBYOFFICER = 'CLOSEDBYOFFICER',
    CLOSEDBYTHINNING = 'CLOSEDBYTHINNING',
    READYFOROFFICER = 'READYFOROFFICER'
}
