import React from 'react';
import { Case } from '../../../../../models/SelmaModels';
import { AppComponent } from '../../../../AppComponent';
import Accordion from '../../../../layout/Accordion/Accordion';
import {
	FormUnit,
	FormUnitSize,
	FormUnitPadding,
} from '../../../../layout/FormUnit/FormUnit';
import { ManualBudgetSummary } from './ManualBudgetSummary';
import { ProvidedAttachments } from '../../StepBudget/ProvidedAttachments';
import { Heading } from '../../../../widget/display/Heading/Heading';

interface Props {
	case: Case;
	accordionOpen?: boolean;
	formUnitSize?: FormUnitSize;
}

export class BudgetSummary extends AppComponent<Props> {
	constructor(props: Props) {
		super(props);
	}

	static defaultProps = {
		accordionOpen: false,
		formUnitSize: FormUnitSize.Large,
	};

	render() {
		const attachmentService = this.application.services.attachmentService;
		const c = this.props.case;
		const showManualBudgets = c.budgets && c.budgets.length>0 ? true:false;
		const attachments = attachmentService.getAttachmentsByType(c, 'COMPANYBUDGET');
		const showAttachments = attachments && attachments.length>0 ? true:false;

		if( !showAttachments && !showManualBudgets )
			return null;
		
		return (
			<div className="BudgetSummary">
				<FormUnit
					size={this.props.formUnitSize}
					padding={FormUnitPadding.Small}>
					<Accordion
						title={<Heading level={2} labelTextkey="Summary_Budget_Title"/>}
						open={this.props.accordionOpen}>

						{showAttachments && attachments &&
							<>
							<FormUnit size={FormUnitSize.Xlarge}>
								<ProvidedAttachments case={c} attachments={attachments} showType={true} />
							</FormUnit>
							</>
						}
						
						{showManualBudgets &&
							c.companyEconomies &&
							c.companyEconomies.map((x, ix) => {
								var company = this.application.services.companyService.getCompanyById(c,x.companyId);
								if (!company) return null;
								var budget = this.application.services.budgetService.getBudgetByCompanyEconomy(c, x);
								if (!budget) return null;
								var uq = this.application.services.idService.newIdString();
								return (
									<FormUnit
										size={this.props.formUnitSize}
										padding={FormUnitPadding.Small}
										key={uq + '_manualBudget_' + x.companyId}>
										<Accordion
											title={<Heading level={3} labelText={company.name +', '+ company.orgNumber}/>}
											>
											<ManualBudgetSummary
												key={
													uq +
													'_companyEconomy_manualBudget_' +
													budget.companyEconomyId
												}
												case={c}
												company={company}
												budget={budget}
											/>
										</Accordion>
									</FormUnit>
								);
							})}
					</Accordion>
				</FormUnit>
			</div>
		);
	}
}
