import React from 'react';
import { PageContent, PageStyle } from '../layout/PageContent/PageContent';
import { Heading } from '../widget/display/Heading/Heading';
import { withRouter, RouteComponentProps } from 'react-router';
import './AccessDenied.scss';
import { THtml } from '../widget/display/THtml';
import { Application } from "../../models/AppModels";

interface AccessDeniedProps extends RouteComponentProps<any> {}

const AccessDenied = (props: AccessDeniedProps) => {
    const application = Application.instance;
    var sessionService = application.services.sessionService;
    var isLoggedIn = sessionService.isLoggedIn();

	return (
		<div className="Page AccessDeniedPage">
            {
                isLoggedIn && (<PageContent style={PageStyle.Gray}>
                    <div className="Content">
                        <Heading
                            level={1}
                            labelTextkey="Access_Denied_Heading"
                        />
                        <p>
                            <THtml k="Access_Denied_Text" />
                        </p>
                    </div>
                </PageContent>)
            }
		</div>
	);
};

export default withRouter(AccessDenied);
