import React, { Component } from 'react';
import { Case, Guarantor } from '../../../../models/SelmaModels';
import { AppComponent } from '../../../AppComponent';
import { TextboxType, Textbox } from '../../../widget/input/Textbox/Textbox';
import { FormUnit, FormUnitSize, FormUnitPadding } from '../../../layout/FormUnit/FormUnit';
import { Label } from '../../../widget/display/Label/Label';
import { SSN } from '../../../widget/input/SSN/SSN';
import { NumberFormatValues } from 'react-number-format';
import { Button } from '../../../widget/input/Button/Button';
import { T } from '../../../widget/display/T';
import { DeleteButton } from '../../../widget/input/DeleteButton/DeleteButton';
import { Heading } from '../../../widget/display/Heading/Heading';
import {
	HeaderButton,
	HeaderButtonLayout,
} from '../../../layout/HeaderButton/HeaderButton';
import { Validation } from '../../../widget/display/Validation/Validation';
import { TabPanels } from '../../../widget/display/TabPanels/TabPanels';

interface Props {
	case: Case;
}

export class CollateralGuarantors extends AppComponent<Props> {
	guarantorService = this.application.services.guarantorService;

	constructor(props: Props) {
		super(props);

		let c = this.props.case;

		this.observeUpdate(c);

		if (c.guarantors) {
			c.guarantors.forEach((x) => {
				this.observeUpdate(x);
			});
		}
	}

	guarantorNameChanged = (
		event: React.ChangeEvent<HTMLInputElement>,
		guarantor: Guarantor
	): void => {
		const value = event.target.value;
		guarantor.name = value;
		this.guarantorService.update(guarantor);
	};
	guarantorPhoneChanged = (
		event: React.ChangeEvent<HTMLInputElement>,
		guarantor: Guarantor
	): void => {
		const value = event.target.value;
		guarantor.phone = value;
		this.guarantorService.update(guarantor);
	};
	guarantorSsnChanged = (
		value: NumberFormatValues,
		guarantor: Guarantor
	): void => {
		guarantor.guarantorCustomerId = value.formattedValue;
		this.guarantorService.update(guarantor);
	};

	addGuarantorClicked = (
		event: React.MouseEvent<HTMLButtonElement, MouseEvent>
	): void => {
		let guarantor = this.guarantorService.newGuarantor();
		this.observeUpdate(guarantor);
		this.guarantorService.addGuarantor(this.props.case, guarantor);
	};

	removeGuarantorClicked = (
		event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
		guarantor: Guarantor
	): void => {
		this.guarantorService.removeGuarantor(this.props.case, guarantor);
	};

	render() {
		let guarantors = this.props.case.guarantors;
		const guarantorsAreValid = this.guarantorService.validateGuarantors(this.props.case).ok();
		let canAdd = guarantors && guarantors.length < 10 && guarantorsAreValid;
		const canRemove = guarantors && guarantors.length > 1;

		const tabs =
			guarantors &&
			guarantors.map((x, ix) => {
				return {
					id: x.id,
					label: x.name || 'Borgensman ' + (ix + 1),
				};
			});

		return (
			<div className="CollateralGuarantors">
				<FormUnit size={FormUnitSize.Large}>
					<Heading
						level={3}
						labelTextkey="Collateral_Guarantor_Title"
					/>
					<TabPanels tabs={tabs}>
						{guarantors &&
							guarantors.map((x, ix) => {
								let uq = ix.toString() + ix;
								return (
									<div
										className="CollateralGuarantor Panel"
										key={`guarantor_${ix}`}>
										<HeaderButton layout={HeaderButtonLayout.Stretch}>
											<Heading
												level={3}
												labelTextkey="Collateral_CollateralTypes_BORGENSMAN"
												labelText={' ' + (ix + 1)}
											/>
											{canRemove && (
												<DeleteButton
													labelTextkey="Collateral_Guarantor_Remove"
													questionTextkey="Collateral_Guarantor_Remove_Q"
													onClick={(e) => this.removeGuarantorClicked(e, x)}
												/>
											)}
										</HeaderButton>
										<FormUnit>
											<Label
												for={uq + 'guarantorName'}
												labelTextkey="Collateral_Guarantor_Name"
												required={true}
											/>
											<Validation
												forObject={x}
												errors={[
													{
														validator: this.application.services
															.guarantorService.hasName,
														textkey: 'Collateral_Guarantor_EnterName',
													},
												]}>
												<Textbox
													id={uq + 'guarantorName'}
													value={x.name || ''}
													handleChange={(e) =>
														this.guarantorNameChanged(e, x)
													}
													maxlength={100}
												/>
											</Validation>
										</FormUnit>
										<FormUnit size={FormUnitSize.Small}>
											<Label
												for={uq + 'guarantorPhone'}
												labelTextkey="Collateral_Guarantor_Phone"
												required={true}
											/>
											<Validation
												forObject={x}
												errors={[
													{
														validator: this.application.services
															.guarantorService.hasPhone,
														textkey: 'Collateral_Guarantor_EnterPhone',
													},
												]}>
												<Textbox
													id={uq + 'guarantorPhone'}
													type={TextboxType.tel}
													value={x.phone}
													handleChange={(e) =>
														this.guarantorPhoneChanged(e, x)
													}
													maxlength={20}
												/>
											</Validation>
										</FormUnit>
										<FormUnit size={FormUnitSize.Small}>
											<Label
												for={uq + 'guarantorSsn'}
												labelTextkey="Collateral_Guarantor_SSN"
												required={true}
											/>
											<Validation
												forObject={x}
												errors={[
													{
														validator: this.application.services.guarantorService.hasCustomerId,
														textkey: 'Collateral_Guarantor_EnterSSN',
													}
												]}>
												<SSN
													id={uq + 'guarantorSsn'}
													value={x.guarantorCustomerId}
													handleChange={(e) =>
														this.guarantorSsnChanged(e, x)
													}
												/>
											</Validation>
										</FormUnit>
									</div>
								);
							})}
					</TabPanels>
				</FormUnit>
				<FormUnit size={FormUnitSize.Large} padding={FormUnitPadding.None}>
					<Button
						labelTextkey="Collateral_Guarantor_Add"
						className="Button plus-icon"
						onClick={this.addGuarantorClicked}
						enabled={canAdd}
						style='link'
					/>
				</FormUnit>
			</div>
		);
	}
}
