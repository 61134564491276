import React from 'react';
import ClassNames from 'classnames';
import { BaseComponent } from '../../../BaseComponent';
import { uniqueId } from 'lodash';
import { T } from '../../display/T';
import './Checkbox.scss';

interface Props {
	checked?: boolean;
	className?: string;
	enabled?: boolean;
	handleBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
	handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
	label?: string;
	labelTextkey?: string;
	name?: string;
	type?: string;
	value?: string;
	touched?: boolean;
	hasErrors?: boolean;
	dirty?: boolean;
}

export class Checkbox extends BaseComponent<Props> {
	id: string;

	constructor(props: Props) {
		super(props);

		this.id = uniqueId('checkbox_');
	}

	static defaultProps = {
		type: 'checkbox',
		className: 'Checkbox',
		enabled: true,
		touched: false,
		hasErrors: false,
		dirty: false,
	};

	render () {

  const classNames = ClassNames(this.props.className, {
		error: this.props.touched && this.props.hasErrors,
	});

		return (
			<div className={classNames}>
				<input
					checked={this.props.checked}
					disabled={this.props.enabled === false}
					id={this.id}
					name={this.props.name}
					onBlur={this.props.handleBlur}
					onChange={this.props.handleChange}
					type={this.props.type}
					value={this.props.value}
				/>
				<label htmlFor={this.id}>
					{this.props.labelTextkey ? (
						<T k={this.props.labelTextkey} />
					) : (
						this.props.label
					)}
				</label>
				{this.props.children}
			</div>
		);
	}

};


