import { TextService } from "../../services/TextService"

export interface UINamed {
	name: string;
	nameTextkey: string;
}

export function getUIName(uiNamed: UINamed, textService: TextService) : string {
	var name = textService.text(uiNamed.nameTextkey);
	if( name )
		return name;
	else
		return uiNamed.name;
}
