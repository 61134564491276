/* tslint:disable */
/* eslint-disable */
/**
 * SelmaSME API
 * This definition is the API between customer SME-Gui and the persistence layer for the application NOT FOR V1 When customer logg-in to Selma-EN, we will create a processId with applicant CustomerId (from BankID). When a customer added attachment to the application, we don\'t have a CaseId i LP, should we add a LP-Service to create/reserve a caseId to be able to attach a document to the caseId in DM.
 *
 * The version of the OpenAPI document: 0.7.4
 * Contact: developer@landshypotek.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Member in a household
 * @export
 * @interface HouseholdMemberType
 */
export interface HouseholdMemberType {
    /**
     * Member in a household (customerId) personnummer
     * @type {string}
     * @memberof HouseholdMemberType
     */
    householdMember?: string;
}

export function HouseholdMemberTypeFromJSON(json: any): HouseholdMemberType {
    return HouseholdMemberTypeFromJSONTyped(json, false);
}

export function HouseholdMemberTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): HouseholdMemberType {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'householdMember': !exists(json, 'householdMember') ? undefined : json['householdMember'],
    };
}

export function HouseholdMemberTypeToJSON(value?: HouseholdMemberType | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'householdMember': value.householdMember,
    };
}


