import React from 'react';
import jQuery from 'jquery';
import ClassNames from 'classnames';
import { Media } from 'react-breakpoints';
import { Breakpoints } from '../../../app/App/App';
import { NavLink } from 'react-router-dom';
import { BaseComponent } from '../../../BaseComponent';
import { Login } from '../../../../services/SessionService';
import { Button } from '../../input/Button/Button';
import { debounce, isNil } from 'lodash';
import { T } from '../T';

import './MobileNav.scss';

interface Props {
	isLoggedIn: boolean;
	login?: Login;
	isOnHomePage:boolean;
	logout: () => void;
	handleClickOnHome: (event) => void;
	compactTop: boolean;
}

export class MobileNav extends BaseComponent<Props> {
	constructor(props: Props) {
		super(props);

		this.state = {
			open: false,
		};
	}

	mobileNavButton = React.createRef<HTMLButtonElement>();
	mobileNavMenu = React.createRef<HTMLDivElement>();

	/* MENU FUNCTIONS */
	closeMobileNav = () => {
		this.setState({
			open: false,
		});
	};

	openMobileNav = () => {
		this.setState({
			open: true,
		});
	};

	toggleMobileNav = () => {
		this.setState((prevState) => ({
			open: !prevState.open,
		}));
	};

	/* EVENT HANDLERS */
	handleResize = (event) => {
		const windowWidth = event.target.outerWidth;

		if (windowWidth > Breakpoints.xs) {
			this.closeMobileNav();
		}
	};

	handleBodyClick = (event) => {
		const notClickOnMenuButton = isNil(
			jQuery(event.target).closest('#MobileNavButton')
		);
		const notClickOnMenu = isNil(
			jQuery(event.target).closest('#MobileNavMenu')
			);

		if (notClickOnMenuButton && notClickOnMenu) {
			this.closeMobileNav();
		}
	};

	handleMobileNavClick = (event) => {
		this.toggleMobileNav();
	};

	handleMenuButtonClick = (event) => {
		this.toggleMobileNav();
	};

	/* LIFECYCLE EVENTS */
	componentDidMount() {
		document.body.addEventListener('click', this.handleBodyClick);
		window.addEventListener('resize', debounce(this.handleResize, 150));
	}

	componentWillUnmount() {
		document.body.removeEventListener('click', this.handleBodyClick);
		window.removeEventListener('resize', debounce(this.handleResize, 150));
	}

	render() {

		const mobileMenuClassNames = ClassNames('MobileNav_Menu', {Compact: this.props.compactTop});

		return (
			<Media>
				{({ breakpoints, currentBreakpoint }) => {
					if (breakpoints[currentBreakpoint] > breakpoints.smxs) {
						return null;
					} else {
						return (
							<div className="MobileNav">
								<button
									id="MobileNavButton"
									className="MobileNav_Button"
									onClick={this.handleMenuButtonClick}
									ref={this.mobileNavButton}
								/>
								{this.state.open ? (
									<nav
										className={mobileMenuClassNames}
										id="MobileNavMenu"
										ref={this.mobileNavMenu}>
										<ul>
											{this.props.isLoggedIn && this.props.login ? (
												<li>
													<span className="LoggedInText">
														<span className="UserName">
															{this.props.login.firstName}{' '}
															{this.props.login.lastName}
														</span>
													</span>
												</li>
											) : null}
											{!this.props.isOnHomePage &&
											<li>
												<NavLink
													to="/"
													className="HomeLink"
													onClick={(event) => {
														this.closeMobileNav();
														this.props.handleClickOnHome(event);
													}}>
													<T k="Home_Link" />
												</NavLink>
											</li>
											}
											{this.props.isLoggedIn && this.props.login ? (
												<li>
													<Button
														name="LogoutButton"
														className="Button transparent full-width lock-icon"
														labelTextkey="Login_Logout"
														onClick={(event) => {
															this.closeMobileNav();
															this.props.logout();
														}}
													/>
												</li>
											) : null}
										</ul>
									</nav>
								) : null}
							</div>
						);
					}
				}}
			</Media>
		);
	}
}
