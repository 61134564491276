import { BaseComponent } from '../BaseComponent';
import { withRouter } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';


class ScrollToTop extends BaseComponent<RouteComponentProps> {
	componentDidUpdate(prevProps) {
		if (this.props.location !== prevProps.location) {
			window.scrollTo(0, 0);
		}
	}

	render() {
		return this.props.children;
	}
}

export default withRouter(ScrollToTop);
