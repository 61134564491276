/* tslint:disable */
/* eslint-disable */
/**
 * SelmaSME API
 * This definition is the API between customer SME-Gui and the persistence layer for the application NOT FOR V1 When customer logg-in to Selma-EN, we will create a processId with applicant CustomerId (from BankID). When a customer added attachment to the application, we don\'t have a CaseId i LP, should we add a LP-Service to create/reserve a caseId to be able to attach a document to the caseId in DM.
 *
 * The version of the OpenAPI document: 0.7.4
 * Contact: developer@landshypotek.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ProcessType,
    ProcessTypeFromJSON,
    ProcessTypeFromJSONTyped,
    ProcessTypeToJSON,
} from './';

/**
 * Get a list of processes and all associated customerId
 * @export
 * @interface ProcessesType
 */
export interface ProcessesType {
    /**
     * Return a list of type processType
     * @type {Array<ProcessType>}
     * @memberof ProcessesType
     */
    processes?: Array<ProcessType>;
}

export function ProcessesTypeFromJSON(json: any): ProcessesType {
    return ProcessesTypeFromJSONTyped(json, false);
}

export function ProcessesTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProcessesType {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'processes': !exists(json, 'processes') ? undefined : ((json['processes'] as Array<any>).map(ProcessTypeFromJSON)),
    };
}

export function ProcessesTypeToJSON(value?: ProcessesType | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'processes': value.processes === undefined ? undefined : ((value.processes as Array<any>).map(ProcessTypeToJSON)),
    };
}


