import React from 'react';
import Tooltip from 'react-tooltip-lite';
import { THtml } from '../THtml';

import './Help.scss';

interface Props {
	className?: string;
	direction?: string;
	eventOff?: string;
	eventOn?: string;
	eventToggle?: string;
	forceDirection?: boolean;
	helpTextkey?: string;
	helpText?: string;
	tipContentHover?: boolean;
	useHover?: boolean;
}

export const Help: React.FunctionComponent<Props> = (props) => {

	return (
		<Tooltip
			arrow={true}
			arrowSize={8}
			background="#ddd8cd"
			className={props.className}
			color="#4a4646"
			content={
				<div className="wrapper">
					{props.helpTextkey && <THtml k={props.helpTextkey} />} 
					{props.helpText}
				</div>
			}
			direction={props.direction}
			distance={15}
			eventOn={props.eventOn}
			eventOff={props.eventOff}
			eventToggle={props.eventToggle}
			forceDirection={props.forceDirection}
			hoverDelay={100}
			padding="0"
			tagName="div"
			tipContentHover={props.tipContentHover}
			useDefaultStyles={false}
			useHover={props.useHover}>
			<div className="icon" />
		</Tooltip>
	);
}

Help.defaultProps = {
	className: 'Help',
	direction: 'down',
	eventOn: '',
	eventOff: '',
	eventToggle: '',
	forceDirection: false,
	tipContentHover: true,
	useHover: true,
};
