/* tslint:disable */
/* eslint-disable */
/**
 * SelmaSME API
 * This definition is the API between customer SME-Gui and the persistence layer for the application NOT FOR V1 When customer logg-in to Selma-EN, we will create a processId with applicant CustomerId (from BankID). When a customer added attachment to the application, we don\'t have a CaseId i LP, should we add a LP-Service to create/reserve a caseId to be able to attach a document to the caseId in DM.
 *
 * The version of the OpenAPI document: 0.7.4
 * Contact: developer@landshypotek.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ApplicantEmployed,
    ApplicantEmployedFromJSON,
    ApplicantEmployedFromJSONTyped,
    ApplicantEmployedToJSON,
    ApplicantLPEmployment,
    ApplicantLPEmploymentFromJSON,
    ApplicantLPEmploymentFromJSONTyped,
    ApplicantLPEmploymentToJSON,
    ContactInformationType,
    ContactInformationTypeFromJSON,
    ContactInformationTypeFromJSONTyped,
    ContactInformationTypeToJSON,
    StakeholderType,
    StakeholderTypeFromJSON,
    StakeholderTypeFromJSONTyped,
    StakeholderTypeToJSON,
} from './';

/**
 * Type of applicant instance
 * @export
 * @interface ApplicantType
 */
export interface ApplicantType {
    /**
     * application model unique identification
     * @type {string}
     * @memberof ApplicantType
     */
    processId: string;
    /**
     * customerId is swedish ssn or corporate organisation number
     * @type {string}
     * @memberof ApplicantType
     */
    customerId: string;
    /**
     * unique id for each applicant in the credit case
     * @type {string}
     * @memberof ApplicantType
     */
    applicantId: string;
    /**
     * Full name of the customer/applicant
     * @type {string}
     * @memberof ApplicantType
     */
    applicantName?: string;
    /**
     * Street address of Applicant
     * @type {string}
     * @memberof ApplicantType
     */
    applicantAddress?: string;
    /**
     * Postal code
     * @type {string}
     * @memberof ApplicantType
     */
    applicantPostalCode?: string;
    /**
     * Postal city
     * @type {string}
     * @memberof ApplicantType
     */
    applicantPostAddress?: string;
    /**
     * 
     * @type {StakeholderType}
     * @memberof ApplicantType
     */
    stakeholderType?: StakeholderType;
    /**
     * 
     * @type {ContactInformationType}
     * @memberof ApplicantType
     */
    contactInformation?: ContactInformationType;
    /**
     * 
     * @type {ApplicantEmployed}
     * @memberof ApplicantType
     */
    applicantEmployed?: ApplicantEmployed;
    /**
     * 
     * @type {ApplicantLPEmployment}
     * @memberof ApplicantType
     */
    applicantLPEmployment?: ApplicantLPEmployment;
    /**
     * Member in Landshypotek Ekonomiska förening (true/false). Tänkt som befitlig medlem, behöver koppling till SAP.
     * @type {boolean}
     * @memberof ApplicantType
     */
    applicantMember?: boolean;
    /**
     * Applicant want to be notified by sms (true/false)
     * @type {boolean}
     * @memberof ApplicantType
     */
    applicantBySms?: boolean;
    /**
     * Applicant want to be notified by eMail (true/false)
     * @type {boolean}
     * @memberof ApplicantType
     */
    applicantByeMail?: boolean;
    /**
     * Personens namn har eller avser köpa en jord och skogsbruksfastighet.
     * @type {boolean}
     * @memberof ApplicantType
     */
    applicantCollateralToBuy?: boolean;
}

export function ApplicantTypeFromJSON(json: any): ApplicantType {
    return ApplicantTypeFromJSONTyped(json, false);
}

export function ApplicantTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApplicantType {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'processId': json['processId'],
        'customerId': json['customerId'],
        'applicantId': json['applicantId'],
        'applicantName': !exists(json, 'applicantName') ? undefined : json['applicantName'],
        'applicantAddress': !exists(json, 'applicantAddress') ? undefined : json['applicantAddress'],
        'applicantPostalCode': !exists(json, 'applicantPostalCode') ? undefined : json['applicantPostalCode'],
        'applicantPostAddress': !exists(json, 'applicantPostAddress') ? undefined : json['applicantPostAddress'],
        'stakeholderType': !exists(json, 'stakeholderType') ? undefined : StakeholderTypeFromJSON(json['stakeholderType']),
        'contactInformation': !exists(json, 'contactInformation') ? undefined : ContactInformationTypeFromJSON(json['contactInformation']),
        'applicantEmployed': !exists(json, 'applicantEmployed') ? undefined : ApplicantEmployedFromJSON(json['applicantEmployed']),
        'applicantLPEmployment': !exists(json, 'applicantLPEmployment') ? undefined : ApplicantLPEmploymentFromJSON(json['applicantLPEmployment']),
        'applicantMember': !exists(json, 'applicantMember') ? undefined : json['applicantMember'],
        'applicantBySms': !exists(json, 'applicantBySms') ? undefined : json['applicantBySms'],
        'applicantByeMail': !exists(json, 'applicantByeMail') ? undefined : json['applicantByeMail'],
        'applicantCollateralToBuy': !exists(json, 'applicantCollateralToBuy') ? undefined : json['applicantCollateralToBuy'],
    };
}

export function ApplicantTypeToJSON(value?: ApplicantType | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'processId': value.processId,
        'customerId': value.customerId,
        'applicantId': value.applicantId,
        'applicantName': value.applicantName,
        'applicantAddress': value.applicantAddress,
        'applicantPostalCode': value.applicantPostalCode,
        'applicantPostAddress': value.applicantPostAddress,
        'stakeholderType': StakeholderTypeToJSON(value.stakeholderType),
        'contactInformation': ContactInformationTypeToJSON(value.contactInformation),
        'applicantEmployed': ApplicantEmployedToJSON(value.applicantEmployed),
        'applicantLPEmployment': ApplicantLPEmploymentToJSON(value.applicantLPEmployment),
        'applicantMember': value.applicantMember,
        'applicantBySms': value.applicantBySms,
        'applicantByeMail': value.applicantByeMail,
        'applicantCollateralToBuy': value.applicantCollateralToBuy,
    };
}


