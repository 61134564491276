import { UIKeyed } from "./UIKeyed";
import { UINamed } from "./UINamed";
import { EUSupportEnum } from "../SelmaModels";

export interface UIEUSupport extends UIKeyed, UINamed {
	type: EUSupportEnum;
}

export const uiEUSupports:UIEUSupport[] = [
	{ key:'EU', name:'EU-stöd', nameTextkey:"UIEUSupport_EU", type:EUSupportEnum.EU },
	{ key:'EUNAT', name:'EU-stöd och nationellt stöd', nameTextkey:"UIEUSupport_EUNAT", type:EUSupportEnum.EUNAT },
	{ key:'EUNAT_NOMILK', name:'EU-stöd och nationellt stöd, utan mjölk', nameTextkey:"UIEUSupport_EUNAT_NOMILK", type:EUSupportEnum.EUNAT_NOMILK },
	{ key:'NAT_NOMILK', name:'Nationellt stöd, utan mjölk', nameTextkey:"UIEUSupport_NAT_NOMILK", type:EUSupportEnum.NAT_NOMILK }
];

