
export interface ValidationResultItem {
	validator: Function;
	object?: any;
	args?: any[];
	ok: boolean;
}

export class ValidationResult {

	private items: ValidationResultItem[];

	constructor(validator?: Function) {
		this.items = [];
	}

	add(item: ValidationResultItem): void {
		this.items.push(item);
	}

	addItem(item: ValidationResultItem): void {
		this.items.push(item);
	}

	addMultiple(items: ValidationResultItem[]): void {
		items.forEach(x => {
			this.items.push(x)
		});
	}

	addResult(result: ValidationResult): void {
		this.addMultiple(result.items);
	}

	getItems() {
		return this.items;
	}

	getCount(): number {
		return this.items.length;
	}

	anyItems(): boolean {
		return this.items && this.items.length>0;
	}

	getOkCount(): number {
		return this.items.filter(x => x.ok).length;
	}

	getFailCount(): number {
		return this.items.filter(x => !x.ok).length;
	}

	// Checks if one specifik validator is ok.
	validatorOk(validator: Function): boolean {
		let vri = this.getValidatorResult(validator);
		return vri && vri.ok ? true : false;
	}

	getValidatorResult(validator: Function): ValidationResultItem|undefined {
		for( let item of this.items ) {
			if( item.validator===validator )
				return item;
		}
	}

	// Returns a value between 0 and 1, ok/total.
	getOkRate(): number {
		let total = this.getCount();
		if (total === 0)
			return 1;
		let ok = this.getOkCount();
		return ok / total;
	}

	ok(): boolean {
		for( let item of this.items ) {
			if( !item.ok )
				return false;
		}
		return true;
	}

	failed(): boolean {
		for( let item of this.items ) {
			if( !item.ok )
				return true;
		}
		return false;
	}
}
