import React from 'react';
import { T } from '../T';
import { Application } from '../../../../models/AppModels';
import { Required } from '../Required/Required';
import { Help } from '../Help/Help';
import './Heading.scss';

interface Props {
	level:number; 
	labelTextkey?: string;
	labelText?: string;
	helpTextkey?: string;
	helpText?: string;
	className?: string; 
	required?: boolean; // adds a marker for required fields
}

export const Heading: React.FunctionComponent<Props> = (props) => {

	const hasValidHelpTextkey =
		props.helpTextkey && Application.instance.services.textService.text(props.helpTextkey)
			? true
			: false;


	const helpTextkey = props.helpTextkey || props.labelTextkey + "_Help";
	const helpText = props.helpText;

	let content = () => {
		return (
			<>
				{props.labelTextkey && <T k={props.labelTextkey} />}
				{props.labelText && props.labelText}
				{props.required && <Required />}
				{(hasValidHelpTextkey || helpText) && (
					<Help helpTextkey={helpTextkey} helpText={helpText} />
				)}
			</>
		);
	}

	const level = props.level;

	return (
		<>
		{level===1 &&<h1 className={props.className} data-textkey={props.labelTextkey}>{content()}</h1>}
		{level===2 &&<h2 className={props.className} data-textkey={props.labelTextkey}>{content()}</h2>}
		{level===3 &&<h3 className={props.className} data-textkey={props.labelTextkey}>{content()}</h3>}
		{level===4 &&<h4 className={props.className} data-textkey={props.labelTextkey}>{content()}</h4>}
		</>
	);
};

// Default props
Heading.defaultProps = {
	className: 'Heading',
};
