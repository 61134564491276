import { BaseService } from './BaseService';
import { Case, AttachmentRequest, Attachment } from '../models/SelmaModels';
import { SMELPOService } from './SMELPOService';
import { StateService } from './StateService';
import { ValidationResult } from '../utils/ValidationResult';
import { GuardService } from './GuardService';
import { IdService } from './IdService';
import { ApplicantService } from './ApplicantService';
import { CompanyService } from './CompanyService';
import { PersonalEconomyService } from './PersonalEconomyService';
import { uiAttachmentTypes } from '../models/uimodels/UIAttachmentType';
import { UiModelConverter } from '../models/UiModelConverter';
import { EuSupportService } from './EuSupportService';
import { LoanService } from './LoanService';
import { CompanyEconomyService } from './CompanyEconomyService';
import { CollateralService } from './CollateralService';
import { TextService } from './TextService';
import { getUIName } from '../models/uimodels/UINamed';
import { ConfigurationService } from './ConfigurationService';
import { BudgetService } from './BudgetService';

/*
Handles all logic for attachments.
*/
export class AttachmentService extends BaseService {

	private url: string;
	public budgetService?: BudgetService;

	constructor(
		private stateService: StateService,
		private smelpoService: SMELPOService,
		private guardService: GuardService,
		private applicantService: ApplicantService,
		private personalEconomyService: PersonalEconomyService,
		private companyService: CompanyService,
		private companyEconomyService: CompanyEconomyService,
		private configurationService: ConfigurationService,
		private collateralService: CollateralService,
		private idService: IdService,
		private loanService: LoanService,
		private textService: TextService,
		url: string) {
		super();
		this.url = url + "/attachments";
		this.stateService = stateService;
		this.smelpoService = smelpoService;
		this.guardService = guardService;
	}

	private update(item: Case | AttachmentRequest): void {
		this.stateService.update(item);
	}

	private postFile<T>(
		op: string,
		request: AttachmentRequest,
		c: Case,
		file: File
	): Promise<T> {
		var formData = new FormData();

		formData.append('customerId', request.customerId || '');
		formData.append('requestType', request.requestType);
		formData.append('requestVariation', request.requestVariation || '');
		formData.append('required', request.required === true ? 'true' : 'false');
		formData.append('processId', c.id);
		formData.append('file', file);

		return fetch(this.url + '/' + op, {
			method: 'POST',
			cache: 'no-cache',
			credentials: 'include', //include cookies
			//mode: 'cors',
			headers: {
				Accept: 'application/json',
				//'Content-Type': 'application/json'
			},
			body: formData,
		})
			.then((result) => {
				return result.json();
			})
			.then((result) => {
				let postResult: T = result;
				return postResult;
			});
	}

	private get<T>(op: string): Promise<T> {
		return fetch(this.url + '/' + op, {
			method: "POST",
			cache: 'no-cache',
			credentials: 'include', //include cookies
			//mode: 'cors',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			//body: file
		})
			.then(result => {
				return result.json();
			})
			.then((result) => {
				let bidResult: T = result;
				return bidResult;
			});
	}

	private post<T>(op: string): Promise<T> {
		return fetch(this.url + '/' + op, {
			method: "POST",
			cache: 'no-cache',
			credentials: 'include', //include cookies
			//mode: 'cors',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			//body: file
		})
			.then(result => {
				return result.json();
			})
			.then((result) => {
				let bidResult: T = result;
				return bidResult;
			});
	}


	downloadAttachment(c: Case, attachmentId: string): void {
		this.redirectToDownloadUrl(c.id, attachmentId);
	}

	removeAttachment(c: Case, attachmentId: string): Promise<Case> {
		return this.post<boolean>("delete?id=" + attachmentId + "&processId=" + c.id)
			.then(result => {
				if (result) {
					if (c.attachments) {
						let a = this.findAttachmentInArray(c.attachments, attachmentId);
						if (a) {
							this.stateService.listRemove(c.attachments, a);
							this.update(c);
						}
					}
				}
				return c;
			})
	}

	findAttachmentInArray(attachments: Attachment[], attachmentId: string): Attachment | undefined {
		let r = attachments.filter(x => {
			return x.id == attachmentId;
		})
		if (r.length === 1)
			return r[0];
		else
			return;
	}

	private redirectToDownloadUrl(processId: string, fileId: string) {
		const protectAgainstPageLeave = this.configurationService.protectAgainstPageLeave();
		if (protectAgainstPageLeave)
			this.configurationService.setProtectAgainstPageLeave(false);
		const url = this.url + '/' + "download?processId=" + encodeURIComponent(processId) + "&id=" + encodeURIComponent(fileId);
		document.location.href = url;
		if (protectAgainstPageLeave)
			this.configurationService.setProtectAgainstPageLeave(true);
	}

	uploadAttachment(c: Case, attachmentRequest: AttachmentRequest, file: File): Promise<Attachment> {
		return this.postFile<Attachment>("upload", attachmentRequest, c, file)
			.then((result: Attachment) => {
				if (!c.attachments)
					c.attachments = [];
				const a = result;
				c.attachments.push(a);
				this.update(c);
				return result;
			});
	}

	private addAttachmentRequestNoUpdate(c: Case, attachmentRequest: AttachmentRequest): void {
		if (!c.attachmentRequests)
			c.attachmentRequests = [];

		c.attachmentRequests.push(attachmentRequest);
	}

	addAttachmentRequest(c: Case, attachmentRequest: AttachmentRequest): void {
		this.addAttachmentRequestNoUpdate(c, attachmentRequest);
		this.update(c);
	}

	ensureAttachmentRequest(c: Case, attachmentRequest: AttachmentRequest, considerRequestVariation: boolean = true): void {
		if (!c.attachmentRequests) {
			c.attachmentRequests = [attachmentRequest];
		} else {
			let ars = this.findAttachmentRequests(c, attachmentRequest.requestType, attachmentRequest.requestVariation, attachmentRequest.customerId, considerRequestVariation);
			if (ars.length === 0) {
				this.addAttachmentRequest(c, attachmentRequest);
			} else {
				var ar = ars[0];
				if (ar.required !== attachmentRequest.required) {
					ar.required = attachmentRequest.required; // update missmatching value.
					this.update(ar);
					this.update(c);
				}
			}
		}
	}

	getAttachmentRequestsByType(c: Case, requestType: string): AttachmentRequest[] | undefined {
		if (!c.attachmentRequests) return;
		let r = c.attachmentRequests.filter(x => x.requestType === requestType);
		return r;
	}

	getAttachmentsByType(c: Case, requestType: string): Attachment[] {
		if (!c.attachments) return [];
		let r = c.attachments.filter(x => x.requestType === requestType);
		return r;
	}

	private findAttachmentRequests(c: Case, requestType: string, requestVariation: string, customerId: string | undefined, considerRequestVariation: boolean = false): AttachmentRequest[] {
		if (!c.attachmentRequests) return [];
		let r = c.attachmentRequests.filter(x => {
			const sameRequstVariation = (x.requestVariation || '') == (requestVariation || '');
			if (!considerRequestVariation || sameRequstVariation) {
				if (x.requestType === requestType) {
					if ((customerId || '') == (x.customerId || '')) {
						return true;
					}
				}
			}
			return false;
		});
		return r;
	}


	findAttachments(c: Case, requestType: string, requestVariation: string, customerId: string | undefined): Attachment[] {
		if (!c.attachments) return [];
		let r = c.attachments.filter(x => {
			//if( x.requestType===requestType && (x.requestVariation||'')==(requestVariation||'') ) {
			if (this.compareEffectiveRequestType(x.requestType || '', requestType)) {
				if ((customerId || '') == (x.customerId || '')) {
					return true;
				}
			}
			return false;
		});
		return r;
	}

	hasAttachments(c: Case, requestType: string, requestVariation: string, customerId: string | undefined): boolean {
		let r = this.findAttachments(c, requestType, requestVariation, customerId);
		if (r.length > 0)
			return true;
		else
			return false;
	}

	attachmentRequestHasAttachments(c: Case, x: AttachmentRequest): boolean {
		let r = this.hasAttachments(c, x.requestType, x.requestVariation, x.customerId);
		return r;
	}

	validateAttachmentRequests(c: Case): ValidationResult {
		let vr = new ValidationResult();
		if (c.attachmentRequests && c.attachmentRequests.length > 0) {
			vr.addResult(this.validateAttachmentRequestsInArray(c, c.attachmentRequests));
		}
		return vr;
	}

	validateAttachmentRequestsByRequestType(c: Case, requestType: string): ValidationResult {
		let vr = new ValidationResult();
		if (c.attachmentRequests && c.attachmentRequests.length > 0) {
			let r = c.attachmentRequests.filter(x => this.compareEffectiveRequestType(x.requestType, requestType));
			vr.addResult(this.validateAttachmentRequestsInArray(c, r));
		}
		return vr;
	}

	// Returns true if request types are effectively the same.
	compareEffectiveRequestType(requestTypeA: string, requestTypeB: string): boolean {
		return this.getEffectiveRequestType(requestTypeA) == this.getEffectiveRequestType(requestTypeB);
	}

	getEffectiveRequestType(requestType: string): string {
		if (requestType == "COMPANYFINREPORT" || requestType == "COMPANYTAXRETURN")
			return "ANNUALACCOUNT";
		return requestType;
	}

	private validateAttachmentRequestsInArray(c: Case, attachmentRequests: AttachmentRequest[]): ValidationResult {
		const vr = new ValidationResult();
		if (attachmentRequests.length > 0) {
			attachmentRequests.forEach(x => {
				if (x.required === true) {
					vr.add({ validator: this.attachmentRequestHasAttachments, object: x, ok: this.attachmentRequestHasAttachments(c, x) });
					//console.log("validateAttachmentRequestsInArray", x, this.attachmentRequestHasAttachments(c, x))
				}
			})
		}
		return vr;
	}



	/*
	SSL-103, SSL-80
	COMPANYBUDGET - Budget	Omsättning i företaget = JA, och ej manuellt ifylld budget
	COMPANYTAXRETURN - Inkomstdeklaration med samtliga bilagor	Omsättning i företaget= JA (om inte bokslut är uppladdat)
	COMPANYFINREPORT - Bokslut med resultat- och balansräkning 	Omsättning i företaget=JA (och inte inkomstdeklaration är uppladdad)
	BUILDINGESTIMATE - Kalkyl för utbyggnad	Minst ett syfte i ärendet är utbyggnad
	BUILDINGESTIMATE - Kalkyl för renovering	Minst ett syfte i ärendet är renovering
	BUILDINGESTIMATE - Kalkyl för nybyggnation	Minst ett syfte i ärendet är nybyggnation
	FORESTRYPLAN - Skogsbruksplan	Manuellt, info till kund "Om det är en skogsfastighet...." 
	PROOFOFEMPLOYMENT - Anställningsbevis	Har en ny tjänst - anställd < 12 mån.
	EUSUPPORT - Beräkningsunderlaget från rådgivningsföretag	Har angivit EU-stöd som säkerhet
	EUSUPPORT - Jordbruksverkets mottagningskvitto	Har angivit EU-stöd som säkerhet
	EUSUPPORT - Föregående årsbesked från Jordbruksverket	Har angivit EU-stöd som säkerhet
	EUSUPPORT - Sammanställning Sam-internet 	Har angivit EU-stöd som säkerhet
	OTHER - Andra bilagor (*inkl förklaring, typ "Om du har andra bilagor som kan vara av värde i kreditprövningen kan du även skicka in dessa".)	Känner att inget av ovanstående passar in men har behov att bifoga material för att ladda upp dokument.
	*/
	/*
	"UIAttachmentType_FORESTRYPLAN": "Skogsbruksplan",
	"UIAttachmentType_PROOFOFEMPLOYMENT": "Anställningsbevis",
	"UIAttachmentType_EUSUPPORT":"EU-stöd",
	"UIAttachmentType_COMPANYBUDGET": "Företagsbudget",
	"UIAttachmentType_COMPANYTAXRETURN": "Inkomstdeklaration med samtliga bilagor",
	"UIAttachmentType_COMPANYFINREPORT": "Bokslut med resultat- och balansräkning",
	"UIAttachmentType_BUILDINGESTIMATE": "Byggnadskalkyl",
	"UIAttachmentType_OTHER": "Övrigt",
	"UIAttachmentType_ANNUALACCOUNT": "Inkomst med samtliga bilagor eller bokslut med resultat och balansräkning",
	*/
	ensureCorrectAttachmentRequests(c: Case) {

		if (this.budgetService) {
			this.budgetService.ensureBudgetAttachmentRequests(c);
		}

		// Inkomstdeklaration med samtliga bilagor	Omsättning i företaget=JA (om inte bokslut är uppladdat)
		// Bokslut med resultat- och balansräkning 	Omsättning i företaget=JA (och inte inkomstdeklaration är uppladdad)
		if (c.companies && c.companyEconomies) {
			const companyEconomyService = this.companyEconomyService;
			c.companies.forEach(company => {
				const companyEconomy = companyEconomyService.getCompanyEconomy(c, company);
				if (!companyEconomy)
					return;

				const hasRevenue = companyEconomyService.hasRevenue(companyEconomy);
				if (hasRevenue) {
					const requestVariation = company.name || UiModelConverter.prettyOrgNr(company.orgNumber);
					const customerId = company.orgNumber;

					// Begär ANNUALACCOUNT istället för finreport/taxreturn
					this.ensureAttachmentRequest(c, {
						id: this.idService.newGuid(),
						customerId: customerId,
						requestType: "ANNUALACCOUNT",
						requestVariation: '',
						required: true
					})


					//if( !hasUploadedCompanyTaxReturn ) {
					// this.ensureAttachmentRequest(c, {
					// 	id: this.idService.newGuid(),
					// 		customerId: customerId,
					// 		requestType:"COMPANYFINREPORT",
					// 		requestVariation: companyFinReportVariationText,
					// 		required: !hasUploadedCompanyTaxReturn
					// })
					//}
					//if( !hasUploadedCompanyFinReport ) {
					// this.ensureAttachmentRequest(c, {
					// 	id: this.idService.newGuid(),
					// 		customerId: customerId,
					// 		requestType:"COMPANYTAXRETURN",
					// 		requestVariation: companyTaxReturnVariationText,
					// 		required: !hasUploadedCompanyFinReport
					// })
					//}
				}
			});
		}


		// Kalkyl för nybyggnation	Minst ett syfte i ärendet är nybyggnation
		// Kalkyl för utbyggnad	Minst ett syfte i ärendet är utbyggnad
		// Kalkyl för renovering	Minst ett syfte i ärendet är renovering
		if (c.loan && c.loan.aims) {
			const loanService = this.loanService;

			c.loan.aims.forEach(x => {
				const needsAttachment = (x.aimCategory == "Nybyggnad" || x.aimCategory == "Renovering" || x.aimCategory == "Tillbyggnad") && x.aimDetail && x.aimDetail != "";
				if (needsAttachment) {
					const aimCategoryName = loanService.getAimCategoryName(x.aimCategory);
					const aimDetailName = loanService.getAimDetailName(x.aimCategory, x.aimDetail);
					let requestVariation = aimCategoryName + ", " + aimDetailName;

					this.ensureAttachmentRequest(c, {
						id: this.idService.newGuid(),
						customerId: undefined,
						requestType: "BUILDINGESTIMATE",
						requestVariation: requestVariation,
						required: true
					})
				}
			});
		}


		// Anställningsbevis	Har en ny tjänst - anställd < 12 mån.
		if (c.applicants) {
			c.applicants.forEach(applicant => {
				const isPersonOrPersonalCompany = this.applicantService.isPhysicum(c, applicant);
				if (isPersonOrPersonalCompany) {
					const pe = this.personalEconomyService.getPersonalEconomy(c, applicant);
					if (pe) {
						const needsProofOfEmployment = this.personalEconomyService.needsProofOfEmployment(pe);
						if (needsProofOfEmployment) {
							this.ensureAttachmentRequest(c, {
								id: this.idService.newGuid(),
								customerId: applicant.customerId,
								requestType: "PROOFOFEMPLOYMENT",
								requestVariation: "",
								required: true
							})
						}
					}
				}
			});
		}

		// Skogsbruksplan.
		if (c.collaterals && c.collaterals.length > 0) {
			const collateralService = this.collateralService;
			// USE IN THE FUTURE.
			// c.collaterals.forEach(x => {
			// 	const needsForestryPlan = collateralService.needsForestryPlan(x);
			// 	if( needsForestryPlan ) {
			// 		this.ensureAttachmentRequest(c,{
			// 			id: this.idService.newGuid(),
			// 				requestType:"FORESTRYPLAN",
			// 				requestVariation: "Skogsbruksplan",
			// 				required: true
			// 		});
			// 	}
			// })

			// Simple logic. See SSL-419.
			this.ensureAttachmentRequest(c, {
				id: this.idService.newGuid(),
				requestType: "FORESTRYPLAN",
				requestVariation: "Skogsbruksplan",
				required: false
			});
		}



		// Beräkningsunderlaget från rådgivningsföretag:	Har angivit EU-stöd som säkerhet
		// Jordbruksverkets mottagningskvitto:	Har angivit EU-stöd som säkerhet
		// Föregående årsbesked från Jordbruksverket:	Har angivit EU-stöd som säkerhet
		// Sammanställning Sam-internet: 	Har angivit EU-stöd som säkerhet
		if (c.euSupports && c.euSupports.length > 0) {

			c.euSupports.forEach(x => {

				//let variator = ""; // TODO: maybe add euType name here.

				this.ensureAttachmentRequest(c, {
					id: this.idService.newGuid(),
					requestType: "EUSUPPORT",
					requestVariation: "Beräkningsunderlag från rådgivningsföretag",
					required: true
				});

				// DISME-558 Ändra texter i SME - legacy support for old variation text "Jordbruksverkets mottagningskvitto" 20210621 /RH
				//TODO: Remove the if-statement after 20210921
				let hasLegacyBoardOfAgricultureRecieptAttachmentRequest = this.findAttachmentRequests(c, "EUSUPPORT", "Jordbruksverkets mottagningskvitto", undefined, true).length > 0;
				if (hasLegacyBoardOfAgricultureRecieptAttachmentRequest == false) {
					this.ensureAttachmentRequest(c, {
						id: this.idService.newGuid(),
						requestType: "EUSUPPORT",
						//requestVariation:"Jordbruksverkets mottagningskvitto",
						requestVariation: "Mottagningskvitto från Jordbruksverket",
						required: true
					});
				}

				this.ensureAttachmentRequest(c, {
					id: this.idService.newGuid(),
					requestType: "EUSUPPORT",
					requestVariation: "Föregående årsbesked från Jordbruksverket",
					required: true
				});


				// DISME-558 Ändra texter i SME - legacy support for old variation text "Sammanställning Sam-internet" 20210621 /RH
				//TODO: Remove the if-statement after 20210921
				let hasLegacySamInternetAttachmentRequest = this.findAttachmentRequests(c, "EUSUPPORT", "Sammanställning Sam-internet", undefined, true).length > 0;
				if (hasLegacySamInternetAttachmentRequest == false) {
					this.ensureAttachmentRequest(c, {
						id: this.idService.newGuid(),
						requestType: "EUSUPPORT",
						//requestVariation: "Sammanställning Sam-internet",
						requestVariation: "Sammanställning Sam-internet (samansökan)",
						required: true
					});
				}
			})
		}

		// Övrigt.
		this.ensureAttachmentRequest(c, {
			id: this.idService.newGuid(),
			requestType: "OTHER",
			requestVariation: "",
			required: false
		});

		this.stateService.update(c);
	}

	getAttachmentRequestTypeDisplayName(attachmentRequest: AttachmentRequest): string {
		let name = this.getAttachmentRequestTypeName(attachmentRequest.requestType);
		// if( attachmentRequest.requestVariation && attachmentRequest.requestVariation.length>0 ) {
		// 	name += ', '+attachmentRequest.requestVariation;
		// }
		if (attachmentRequest.customerId && attachmentRequest.customerId.length > 0) {
			name += ' ' + this.textService.textOrEmpty("For") + ' ' + UiModelConverter.prettyCustomerId(attachmentRequest.customerId);
		}
		return name;
	}

	getAttachmentRequestTypeName(requestType: string): string {
		const r = uiAttachmentTypes.filter(x => {
			return x.key == requestType;
		});
		if (r.length === 1)
			return getUIName(r[0], this.textService);
		return "";
	}

	getAttachmentTypeDisplayName(attachment: Attachment): string {
		let name = this.getAttachmentRequestTypeName(attachment.requestType || '');
		// if( attachment.requestVariation && attachment.requestVariation.length>0 ) {
		// 	name += ' '+attachment.requestVariation;
		// }
		if (attachment.customerId && attachment.customerId.length > 0) {
			name += ' ' + this.textService.textOrEmpty("For") + ' ' + UiModelConverter.prettyCustomerId(attachment.customerId);
		}
		return name;
	}

	filterRequiredAttachmentRequests(attachmentRequests: AttachmentRequest[] | undefined, required: boolean | undefined): AttachmentRequest[] {
		if (!attachmentRequests || attachmentRequests.length === 0)
			return [];
		if (required == undefined)
			return attachmentRequests;
		let r = attachmentRequests.filter(x => x.required === required);
		return r;
	}

	filterOutAttachments(attachments: Attachment[] | undefined, removeAttachments: any[] | undefined): Attachment[] | undefined {
		if (!attachments || attachments.length === 0 || !removeAttachments || removeAttachments.length === 0)
			return attachments;

		let r = attachments.filter(x => {
			return removeAttachments.findIndex(remove => x === remove) === -1;
		})
		return r;
	}

	filterOutAttachmentsByRequestType(attachments: Attachment[] | undefined, requestType: string): Attachment[] | undefined {
		if (!attachments || attachments.length === 0)
			return attachments;

		let r = attachments.filter(x => {
			return x.requestType != requestType;
		})
		return r;
	}

	filterOutAttachmentRequestsByRequestType(attachmentRequests: AttachmentRequest[] | undefined, requestType: string): AttachmentRequest[] | undefined {
		if (!attachmentRequests || attachmentRequests.length === 0)
			return attachmentRequests;

		let r = attachmentRequests.filter(x => {
			return x.requestType != requestType;
		})
		return r;
	}

	getAttachmentsByAttachmentRequest(c: Case, attachmentRequest: AttachmentRequest): Attachment[] {
		if (!c.attachments)
			return [];
		let x = attachmentRequest;
		let r = this.findAttachments(c, x.requestType, x.requestVariation, x.customerId);
		return r;
	}

	getAttachmentsByAttachmentRequests(c: Case, attachmentRequests: AttachmentRequest[]): Attachment[] {
		if (!c.attachments)
			return [];
		let attachments: Attachment[] = [];
		attachmentRequests.forEach(x => {
			let r = this.getAttachmentsByAttachmentRequest(c, x);
			if (r.length > 0)
				r.forEach(y => { attachments.push(y); });
		})
		return attachments;
	}

}
