import React from 'react';
import './FormUnitFieldAndButton.scss';

interface Props {
	className?: string;
}

export const FormUnitFieldAndButton: React.FunctionComponent<Props> = (props) => {

	return (
		<div className={props.className}>
			{props.children}
		</div>
	);
};

FormUnitFieldAndButton.defaultProps = {
	className: 'FormUnitFieldAndButton',
}
