/* tslint:disable */
/* eslint-disable */
/**
 * SelmaSME API
 * This definition is the API between customer SME-Gui and the persistence layer for the application NOT FOR V1 When customer logg-in to Selma-EN, we will create a processId with applicant CustomerId (from BankID). When a customer added attachment to the application, we don\'t have a CaseId i LP, should we add a LP-Service to create/reserve a caseId to be able to attach a document to the caseId in DM.
 *
 * The version of the OpenAPI document: 0.7.4
 * Contact: developer@landshypotek.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Employeed or Not Employeed, from SAP on existing customer and from UC? or entered by customer. employment mapping to Loan Process PERMANENT - Anställd, tills-vidare-anställd TEMPORARY - Tidbegränsad anställning PROBATIONARY - Provanställning UNEMPLOYMENT_INSURANCE - Arbetslöshetskassa SELF_EMPLOYED - Eget företag STUDENT_AID - Studiestöd PENSION - Inkomst av pension
 * @export
 * @enum {string}
 */
export enum ApplicantEmployed {
    PERMANENT = 'PERMANENT',
    TEMPORARY = 'TEMPORARY',
    PROBATIONARY = 'PROBATIONARY',
    UNEMPLOYMENTINSURANCE = 'UNEMPLOYMENT_INSURANCE',
    SELFEMPLOYED = 'SELF_EMPLOYED',
    STUDENTAID = 'STUDENT_AID',
    PENSION = 'PENSION'
}

export function ApplicantEmployedFromJSON(json: any): ApplicantEmployed {
    return ApplicantEmployedFromJSONTyped(json, false);
}

export function ApplicantEmployedFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApplicantEmployed {
    return json as ApplicantEmployed;
}

export function ApplicantEmployedToJSON(value?: ApplicantEmployed | null): any {
    return value as any;
}

