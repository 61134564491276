import { BaseService } from './BaseService';
import { Case } from '../models/SelmaModels';
import { Collateral } from "../models/selmamodels/Collateral";
import { SMELPOService } from './SMELPOService';
import { StateService } from './StateService';
import { ValidationResult } from '../utils/ValidationResult';
import { GuardService } from './GuardService';
import { IdService } from './IdService';

/*
Handles all logic for Collateral.
*/
export class CollateralService extends BaseService {

	private stateService: StateService;
	private smelpoService: SMELPOService;
	private guardService: GuardService;

	constructor(stateService: StateService, smelpoService: SMELPOService, 
		guardService: GuardService, private idService:IdService) {
		super();
		this.stateService = stateService;
		this.smelpoService = smelpoService;
		this.guardService = guardService;
	}

	update(collateral: Collateral|Case): void {
		this.stateService.update(collateral);
	}

	addCollateral(c: Case, collateral: Collateral): void {
		if (!c.collaterals) 
			c.collaterals = [];

		this.stateService.listAdd(c.collaterals, collateral);
		this.update(c);
	}

	removeCollateral(c: Case, collateral: Collateral): void {
		if( !c.collaterals )
			return;
		this.stateService.listRemove(c.collaterals, collateral);
		this.update(c);
	}

	removeAllCollaterals(c: Case): void {
		if( !c.collaterals || c.collaterals.length===0 )
			return;
		c.collaterals = undefined;
		this.update(c);
	}

	hasCollateral(c: Case): boolean {
		return this.guardService.hasLengthGreaterThanZero(c.collaterals);
	}

	validateCollateral(c: Case, x: Collateral): ValidationResult {
		let vr = new ValidationResult();

		if(!x.postponeCollateralCode) {
			vr.add({validator:this.hasMunicipality, object:x, ok: this.hasMunicipality(x)});
			vr.add({validator:this.hasCode, object:x, ok: this.hasCode(x)});
		}

		return vr;
	}

	validateCollaterals(c:Case): ValidationResult {
		let vr = new ValidationResult();
		if ( c.collaterals ) {
			c.collaterals.forEach(x => {
				var r = this.validateCollateral(c, x);
				vr.addResult(r);
			});
		}
		return vr;
	}

	hasMunicipality(x: Collateral): boolean {
		return !this.guardService.isUnvaluedOrEmpty(x.municipality);
	}

	hasCode(x: Collateral): boolean {
		return !this.guardService.isUnvaluedOrEmpty(x.code);
	}

	needsForestryPlan(x: Collateral):boolean {
		return false; // TODO: implement.
	}

	ensureMinimumCollaterals(c: Case): void {
		if( !c.collaterals )
			c.collaterals = [];

		if( c.collaterals.length<1 ) {
			let collateral:Collateral = this.newCollateral();
			c.collaterals.push(collateral);
			this.update(c);
		}
	}

	newCollateral(): Collateral {
		let collateral:Collateral = {};
		return collateral;
	}

}
