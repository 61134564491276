import React from 'react';
import { Case } from '../../../models/SelmaModels';
import { FormUnitSize, FormUnit, FormUnitPadding } from '../../layout/FormUnit/FormUnit';
import { AppComponent } from '../../AppComponent';
import Accordion from '../../layout/Accordion/Accordion';
import { Heading } from '../../widget/display/Heading/Heading';
import { SelectedCompanySummary } from '../../case/steps/StepSummary/CompanySummary/SelectedCompanySummary';
import { ProvidedAttachments } from '../../case/steps/StepBudget/ProvidedAttachments';

interface Props {
	case: Case;
	accordionOpen?: boolean;
	formUnitSize?: FormUnitSize;
}

export class AttachmentSection extends AppComponent<Props> {
	constructor(props: Props) {
		super(props);
	}

	static defaultProps = {
		accordionOpen: false,
		formUnitSize: FormUnitSize.Large
	};

	render() {
		const c = this.props.case;
		const attachmentService = this.application.services.attachmentService;

		const attachments = attachmentService.filterOutAttachmentsByRequestType(c.attachments||[], 'COMPANYBUDGET'); // All attachments that are not budgets.
		if( !attachments || attachments.length===0 )
			return null;

		return (
			<div className="AttachmentSection">
				<FormUnit
					size={this.props.formUnitSize}
					padding={FormUnitPadding.Small}>
					<Accordion open={this.props.accordionOpen}
						title={<Heading level={2} labelTextkey="Admin_Attachment_Title"/>}>
						<FormUnit size={this.props.formUnitSize}>
							{attachments && 
							<ProvidedAttachments case={c} attachments={attachments} />
							}
						</FormUnit>
					</Accordion>
				</FormUnit>
			</div>
		);
	}
}
