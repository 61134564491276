import React from 'react';
import { Company } from "../../../../models/selmamodels/Company";
import { AppComponent } from '../../../AppComponent';
import {
	RadiobuttonTable,
	TableData,
	TableDataCell,
} from '../../../widget/input/RadiobuttonTable/RadiobuttonTable';
import { UiModelConverter } from '../../../../models/UiModelConverter';
import { Validation } from '../../../widget/display/Validation/Validation';
import { Applicant } from '../../../../models/selmamodels/Applicant';
import './CompanySelect.scss';

// export enum Category {
// 	company,
// 	applicant
// }

// export interface RadioValue {
// 	value: string,
// 	category: Category
// }

interface Props {
	companies: Company[];
	applicant?: Applicant | undefined;
	selectedCompany?: Company;
	selectedApplicant?: Applicant;
	companySelected?: (company: Company, companies: Company[]) => void;
	applicantSelected?: (applicant:Applicant) => void;
	handleBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
}


export class CompanySelect extends AppComponent<Props> {
	constructor(props: Props) {
		super(props);

		this.props.companies.forEach(x => {
			this.observeUpdate(x);
		});
	}

	static defaultProps = {
		name: 'CompanySelect',
	};

	companyOrApplicantSelected = (cell: TableDataCell): void => {
		
		//const value: RadioValue = JSON.parse(valueString) as RadioValue; // TODO: GET RID OF THIS STUPID IDEA!

		if(!cell || !cell.tag)
			return;

		const isCompany = cell.tag=="company";

		if (isCompany) {
			const orgNr = cell.value||'';
			const companyService = this.application.services.companyService;
			const company = companyService.getCompanyByOrgnrInArray(this.props.companies, orgNr);
			if (company) {
				if (this.props.companySelected) {
					this.props.companySelected(company, this.props.companies);
				}
			}
		} else {
			if (this.props.applicantSelected && this.props.applicant) {
				this.props.applicantSelected(this.props.applicant);
			}
		}
	};

	makeTableData(companies: Company[], applicant: Applicant | undefined): TableData {
		let textService = this.application.services.textService;
		let tableData: TableData = {
			head: textService.textsOrEmpty([
				'Select',
				'Company_Orgnr_Or_Applicant_SSN',
				'Company_CompanyName_Or_ApplicantName'
			]),
			body: companies
				.filter((x) => x.orgNumber && x.orgNumber.length > 0)
				.map((x) => [
					{ 
						type: 'radio', 
						value: x.orgNumber,
						tag: "company"
					},
					UiModelConverter.prettyOrgNr(x.orgNumber),
					x.name||''
				]),
		};

		// Applicant exists and has no physicum companies
		const companyService = this.application.services.companyService;
		if (applicant && !companyService.anyPhysicumCompanies(companies)) {
			const applicantService = this.application.services.applicantService;

			let row = [
				//{ type: 'radio', id: applicant.customerId, value: { value: applicant.customerId, category: Category.applicant } },
				{ 
					type: 'radio', 
					value: applicant.customerId,
					tag: "applicant"
				},
				UiModelConverter.prettyCustomerId(applicant.customerId),
				applicantService.getPersonFullName(applicant)
			];

			tableData.body.push(row);
		}
		return tableData;
	}

	render() {
		const companyService = this.application.services.companyService;
		const selectedCompany = this.props.selectedCompany; //companyService.getSelectedCompanyInArray(this.props.companies);
		const selectedApplicant = this.props.selectedApplicant;
		let customerId = '';
		if (selectedCompany && selectedCompany.orgNumber ) 
			customerId = selectedCompany.orgNumber;
		if( selectedApplicant && selectedApplicant.customerId ) 
			customerId = selectedApplicant.customerId;

		const tableData = this.makeTableData(this.props.companies, this.props.applicant);


		return (
			<Validation
				forObject={selectedCompany}
				errors={[
					{
						validator: companyService.hasSelectedCompany,
						textkey: 'Error_SelectValue',
					},
				]}>
				<RadiobuttonTable
					value={customerId}
					className="RadiobuttonTable CompanySelectTable"
					handleChange={this.companyOrApplicantSelected}
					handleBlur={this.props.handleBlur}
					tableData={tableData}
				/>

			</Validation>
		);
	}
}
