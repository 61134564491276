import React, { RefObject } from 'react';
import { Case, Budget, BudgetYear } from '../../../../models/SelmaModels';
import { AppComponent } from '../../../AppComponent';
import { Money } from '../../../widget/input/Money/Money';
import NumberFormat, { NumberFormatValues } from 'react-number-format';
import { Company } from '../../../../models/selmamodels/Company';
import { T } from '../../../widget/display/T';
import { uiBudgetYearItems } from '../../../../models/uimodels/UIBudgetYearItem';

import './ManualBudget.scss';
import { ScrollOverflowIndicator } from '../../../widget/display/ScrollOverflowIndicator/ScrollOverflowIndicator';
import { getUIName } from '../../../../models/uimodels/UINamed';

interface Props {
	case: Case;
	company: Company;
	budget: Budget;
	id: number;
}

export class ManualBudget extends AppComponent<Props> {
	companyEconomyService = this.application.services.companyEconomyService;
	id;
	nodeRef: RefObject<HTMLTableElement>;

	constructor(props: Props) {
		super(props);

		this.state = {
			hasOverflow: false,
		};

		this.id = this.application.services.idService.newIdString();

		if (this.props.budget.budgetYears) {
			this.props.budget.budgetYears.forEach((budgetYear) => {
				this.observeUpdate(budgetYear);
				this.calculateSums(budgetYear);
			});
		}

		this.nodeRef = React.createRef();
	}

	hasOverflow = () => {
		if (!this.nodeRef) return null;
		const element = this.nodeRef.current;

		if (!element) return null;

		this.setState({
			hasOverflow: element.scrollWidth > element.clientWidth,
		});
	};

	componentDidMount() {
		this.hasOverflow();
		window.addEventListener('resize', this.hasOverflow);
	}

	componendWillUnmount() {
		window.removeEventListener('resize', this.hasOverflow);
	}

	handleValueChange(
		e: NumberFormatValues,
		budgetYear: BudgetYear,
		row: number
	) {
		// Get input value
		let value = e.floatValue;
		let prop = 'value' + row;
		budgetYear[prop] = value;

		// Calculate sums
		budgetYear = this.calculateSums(budgetYear);

		// Write to model
		this.companyEconomyService.update(budgetYear);
	}

	// undefined-is-zero
	uz(n: number | undefined): number {
		if (n === undefined) return 0;
		return n;
	}

	calculateSums(budgetYear: BudgetYear) {
		const calculations = {
			// Omsättning totalt (Totala intäkter) summa value1-7
			value8: (by: BudgetYear) => {
				return (
					this.uz(by.value1) +
					this.uz(by.value2) +
					this.uz(by.value3) +
					this.uz(by.value4) +
					this.uz(by.value5) +
					this.uz(by.value6) +
					this.uz(by.value7)
				);
			},
			// Summa kostnader (summa value9-12)"
			value13: (by: BudgetYear) => {
				return (
					this.uz(by.value9) +
					this.uz(by.value10) +
					this.uz(by.value11) +
					this.uz(by.value12)
				);
			},
			// Resultat före avskrivningar (value8 + (-value13))
			value14: (by: BudgetYear) => {
				return this.uz(by.value8) + this.uz(by.value13);
			},
			// Summa avskrivningar (-value15) + (-value16)
			value17: (by: BudgetYear) => {
				return this.uz(by.value15) + this.uz(by.value16);
			},
			// Resultat före avskrivningar value14 + (-value17)
			value18: (by: BudgetYear) => {
				return this.uz(by.value14) + this.uz(by.value17);
			},
			// Resultat finansiella poster (value18) + value19 + (-value20)
			value21: (by: BudgetYear) => {
				return this.uz(by.value18) + this.uz(by.value19) + this.uz(by.value20);
			},
			// Åretsresultat (value21) + (value22+value23+value24)
			value25: (by: BudgetYear) => {
				return (
					this.uz(by.value21) +
					(this.uz(by.value22) + this.uz(by.value23) + this.uz(by.value24))
				);
			},
		};

		for (var calculation in calculations) {
			budgetYear[calculation] = calculations[calculation](budgetYear);
		}

		return budgetYear;
	}

	handleValueBlur(
		e: React.FocusEvent<HTMLInputElement>,
		budgetYear: BudgetYear,
		row: number
	) {
		this.companyEconomyService.update(budgetYear);
	}

	renderRow = (bys: BudgetYear[], row: number, rowType: string) => {
		const budgetYearItem = uiBudgetYearItems[row - 1];
		const textService = this.application.services.textService;

		return (
			<tr
				key={'id_' + this.id + '_row' + row}
				className={rowType === 'edit' ? 'ValueRow' : 'CalcRow'}>
				<td className="LabelCell">{getUIName(uiBudgetYearItems[row - 1], textService)}</td>
				{bys &&
					bys.map((x, ix) => {
						let id = 'id_' + this.id + '_year_' + x.year + '_row_' + row;
						let value = x['value' + row];

						return (
							<td key={id}>
								{rowType === 'edit' ? (
									<Money
										id={id + '_money'}
										value={value}
										valueType={budgetYearItem.valueType}
										handleBlur={(e) => {
											this.handleValueBlur(e, x, row);
										}}
										handleChange={(e) => {
											this.handleValueChange(e, x, row);
										}}
									/>
								) : (
									<NumberFormat
										id={id + '_money'}
										value={value}
										thousandSeparator=" "
										suffix=" kr"
										displayType="text"
									/>
								)}
							</td>
						);
					})}
			</tr>
		);
	};

	render() {
		const company = this.props.company;
		const bys = this.props.budget.budgetYears || [];
		const row = this.renderRow;

		return (
			<>
				<div className="ManualBudgetWrapper" ref={this.nodeRef}>
					<table className="ManualBudget" key={'manualBudget_' + this.id}>
						<thead>
							<tr>
								<th className="LabelCell">
									<T k="Budget_BudgetFor" /> {company.name}
								</th>
								{bys.map((x, ix) => {
									return <th key={x.year}>{x.year}</th>;
								})}
							</tr>
						</thead>
						<tbody>
							{row(bys, 1, 'edit')}
							{row(bys, 2, 'edit')}
							{row(bys, 3, 'edit')}
							{row(bys, 4, 'edit')}
							{row(bys, 5, 'edit')}
							{row(bys, 6, 'edit')}
							{row(bys, 7, 'edit')}
							{row(bys, 8, 'calc')}
							{row(bys, 9, 'edit')}
							{row(bys, 10, 'edit')}
							{row(bys, 11, 'edit')}
							{row(bys, 12, 'edit')}
							{row(bys, 13, 'calc')}
							{row(bys, 14, 'calc')}
							{row(bys, 15, 'edit')}
							{row(bys, 16, 'edit')}
							{row(bys, 17, 'calc')}
							{row(bys, 18, 'calc')}
							{row(bys, 19, 'edit')}
							{row(bys, 20, 'edit')}
							{row(bys, 21, 'calc')}
							{row(bys, 22, 'edit')}
							{row(bys, 23, 'edit')}
							{row(bys, 24, 'edit')}
							{row(bys, 25, 'calc')}
						</tbody>
					</table>
				</div>
				{this.state.hasOverflow ? <ScrollOverflowIndicator /> : null}
			</>
		);
	}
}
