/* tslint:disable */
/* eslint-disable */
/**
 * SelmaSME API
 * This definition is the API between customer SME-Gui and the persistence layer for the application NOT FOR V1 When customer logg-in to Selma-EN, we will create a processId with applicant CustomerId (from BankID). When a customer added attachment to the application, we don\'t have a CaseId i LP, should we add a LP-Service to create/reserve a caseId to be able to attach a document to the caseId in DM.
 *
 * The version of the OpenAPI document: 0.7.4
 * Contact: developer@landshypotek.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    LoanOwnerType,
    LoanOwnerTypeFromJSON,
    LoanOwnerTypeFromJSONTyped,
    LoanOwnerTypeToJSON,
    TypeOfHouse,
    TypeOfHouseFromJSON,
    TypeOfHouseFromJSONTyped,
    TypeOfHouseToJSON,
} from './';

/**
 * Typ av hus
 * @export
 * @interface MaintenanceHouseType
 */
export interface MaintenanceHouseType {
    /**
     * 
     * @type {TypeOfHouse}
     * @memberof MaintenanceHouseType
     */
    typeOfHouse?: TypeOfHouse;
    /**
     * Avser att behålla huset
     * @type {boolean}
     * @memberof MaintenanceHouseType
     */
    keepHouse?: boolean;
    /**
     * Avser att behålla huset
     * @type {boolean}
     * @memberof MaintenanceHouseType
     */
    loanInOtherInstitute?: boolean;
    /**
     * Lösa lånet
     * @type {boolean}
     * @memberof MaintenanceHouseType
     */
    redeemLoan?: boolean;
    /**
     * Lånet är hos kredit institut
     * @type {string}
     * @memberof MaintenanceHouseType
     */
    creditInstitute?: string;
    /**
     * Clearing number
     * @type {string}
     * @memberof MaintenanceHouseType
     */
    loanClearing?: string;
    /**
     * Lånenummer hos de kredit institut där lånet ska lösas
     * @type {string}
     * @memberof MaintenanceHouseType
     */
    instituteLoanNumber?: string;
    /**
     * list of customerId
     * @type {Array<LoanOwnerType>}
     * @memberof MaintenanceHouseType
     */
    loanOwners?: Array<LoanOwnerType>;
    /**
     * Lånet är på belopp
     * @type {number}
     * @memberof MaintenanceHouseType
     */
    houseLoanAmount?: number;
    /**
     * Driftkostnad
     * @type {number}
     * @memberof MaintenanceHouseType
     */
    maintenanceCost?: number;
}

export function MaintenanceHouseTypeFromJSON(json: any): MaintenanceHouseType {
    return MaintenanceHouseTypeFromJSONTyped(json, false);
}

export function MaintenanceHouseTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): MaintenanceHouseType {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'typeOfHouse': !exists(json, 'typeOfHouse') ? undefined : TypeOfHouseFromJSON(json['typeOfHouse']),
        'keepHouse': !exists(json, 'keepHouse') ? undefined : json['keepHouse'],
        'loanInOtherInstitute': !exists(json, 'loanInOtherInstitute') ? undefined : json['loanInOtherInstitute'],
        'redeemLoan': !exists(json, 'redeemLoan') ? undefined : json['redeemLoan'],
        'creditInstitute': !exists(json, 'creditInstitute') ? undefined : json['creditInstitute'],
        'loanClearing': !exists(json, 'loanClearing') ? undefined : json['loanClearing'],
        'instituteLoanNumber': !exists(json, 'instituteLoanNumber') ? undefined : json['instituteLoanNumber'],
        'loanOwners': !exists(json, 'loanOwners') ? undefined : ((json['loanOwners'] as Array<any>).map(LoanOwnerTypeFromJSON)),
        'houseLoanAmount': !exists(json, 'houseLoanAmount') ? undefined : json['houseLoanAmount'],
        'maintenanceCost': !exists(json, 'maintenanceCost') ? undefined : json['maintenanceCost'],
    };
}

export function MaintenanceHouseTypeToJSON(value?: MaintenanceHouseType | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'typeOfHouse': TypeOfHouseToJSON(value.typeOfHouse),
        'keepHouse': value.keepHouse,
        'loanInOtherInstitute': value.loanInOtherInstitute,
        'redeemLoan': value.redeemLoan,
        'creditInstitute': value.creditInstitute,
        'loanClearing': value.loanClearing,
        'instituteLoanNumber': value.instituteLoanNumber,
        'loanOwners': value.loanOwners === undefined ? undefined : ((value.loanOwners as Array<any>).map(LoanOwnerTypeToJSON)),
        'houseLoanAmount': value.houseLoanAmount,
        'maintenanceCost': value.maintenanceCost,
    };
}


