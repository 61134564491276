
export interface UIBusinessCategory {
	BusinessCategoryID: string;
	BusinessCategory: string;
}

export interface UIBusinessDirection {
	BusinessDirectionID: string;
	BusinessDirection: string;
}

// This array is provided as-is from LHB.
export let uiBusinessCategories: UIBusinessCategory[] = [
	{
		"BusinessCategoryID": "1",
		"BusinessCategory": "Jordbruksväxter"
	},
	{
		"BusinessCategoryID": "2",
		"BusinessCategory": "Trädgårdsväxter"
	},
	{
		"BusinessCategoryID": "3",
		"BusinessCategory": "Nötkreatur"
	},
	{
		"BusinessCategoryID": "4",
		"BusinessCategory": "Får och getter"
	},
	{
		"BusinessCategoryID": "5",
		"BusinessCategory": "Svinproduktion"
	},
	{
		"BusinessCategoryID": "6",
		"BusinessCategory": "Fjäderfäskötsel"
	},
	{
		"BusinessCategoryID": "7",
		"BusinessCategory": "Hästverksamhet"
	},
	{
		"BusinessCategoryID": "8",
		"BusinessCategory": "Annan djurskötsel"
	},
	{
		"BusinessCategoryID": "9",
		"BusinessCategory": "Prod av skog"
	},
	{
		"BusinessCategoryID": "10",
		"BusinessCategory": "Förädling av skog"
	},
	{
		"BusinessCategoryID": "11",
		"BusinessCategory": "Övrik skogsverksam."
	},
	{
		"BusinessCategoryID": "12",
		"BusinessCategory": "Maskinenteprenad"
	},
	{
		"BusinessCategoryID": "13",
		"BusinessCategory": "Lokaler"
	},
	{
		"BusinessCategoryID": "14",
		"BusinessCategory": "Turism"
	},
	{
		"BusinessCategoryID": "15",
		"BusinessCategory": "Tillverkning"
	},
	{
		"BusinessCategoryID": "16",
		"BusinessCategory": "Tjäns. - konsultv"
	},
	{
		"BusinessCategoryID": "17",
		"BusinessCategory": "Övrigt"
	},
	{
		"BusinessCategoryID": "101",
		"BusinessCategory": "Jordbruksväxter"
	},
	{
		"BusinessCategoryID": "102",
		"BusinessCategory": "Trädgård, grönsaker, frukt & bär"
	},
	{
		"BusinessCategoryID": "103",
		"BusinessCategory": "Mjölkproduktion"
	},
	{
		"BusinessCategoryID": "104",
		"BusinessCategory": "Nötkreatur (kött etc.)"
	},		
	{
		"BusinessCategoryID": "105",
		"BusinessCategory": "Svinproduktion"
	},
	{
		"BusinessCategoryID": "106",
		"BusinessCategory": "Fjäderfäproduktion"
	},
	{
		"BusinessCategoryID": "107",
		"BusinessCategory": "Hästverksamhet"
	},
	{
		"BusinessCategoryID": "108",
		"BusinessCategory": "Annan djurskötsel"
	},
	{
		"BusinessCategoryID": "109",
		"BusinessCategory": "Skog - Produktion/Förädling/övrigt"
	},
	{
		"BusinessCategoryID": "110",
		"BusinessCategory": "Maskinentreprenad"
	},
	{
		"BusinessCategoryID": "111",
		"BusinessCategory": "Elproduktion"
	},
	{
		"BusinessCategoryID": "112",
		"BusinessCategory": "Förädling av egna produkter/Konferens & Upplevelseverksamhet"
	},
	{
		"BusinessCategoryID": "113",
		"BusinessCategory": "Inkomst av tjänst och kapital/övrigt"
	},
	{
		"BusinessCategoryID": "114",
		"BusinessCategory": "Arrende- och hyresintäkter"
	}			
];
