import { BaseService } from './BaseService';
import { Case, Summary } from '../models/SelmaModels';
import { StateService } from './StateService';
import { ValidationResult } from '../utils/ValidationResult';
import { GuardService } from './GuardService';
import { IdService } from './IdService';
import { CaseIdStatus } from '../libs/SMELPOClient';

/*
Handles all logic for Summary.
*/
export class SummaryService extends BaseService {
	constructor(private stateService: StateService, 
		private guardService: GuardService, private idService:IdService) {
		super();
	} 

	
	update(x: Summary|Case): void {
		this.stateService.update(x);
	}

	ensureSummary(c:Case) : Summary {
		if( !c.summary ) {
			c.summary = {
			}
			this.update(c.summary);
		}
		return c.summary;
	}

	canApproveCase(c:Case) : boolean {
		return this.hasCheckedSummaryApproveInformationHandling(c)
			&& this.hasCheckedSummaryGuranteeCorrectInformation(c);
	}

	hasCheckedSummaryApproveInformationHandling(c:Case) : boolean {
		if( c.status===CaseIdStatus.STARTEDBYAPPLICANT && c.summary ) {
			return c.summary.approveInformationHandling===true;
		}
		return false;
	}

	hasCheckedSummaryGuranteeCorrectInformation(c:Case) : boolean {
		if( c.status===CaseIdStatus.STARTEDBYAPPLICANT && c.summary ) {
			return c.summary.guaranteeCorrectInformation===true;
		}
		return false;
	}

	hasSentCaseToApproval(c:Case) : boolean {
		if( c.status!==CaseIdStatus.STARTEDBYAPPLICANT ) {
			return true;
		}
		return false;
	}

	validateSummary(c:Case) : ValidationResult {
		let vr = new ValidationResult();
		if( c.status===CaseIdStatus.STARTEDBYAPPLICANT ) {
			vr.add({validator:this.hasCheckedSummaryApproveInformationHandling, object:c, ok: this.hasCheckedSummaryApproveInformationHandling(c)});
			vr.add({validator:this.hasCheckedSummaryGuranteeCorrectInformation, object:c, ok: this.hasCheckedSummaryGuranteeCorrectInformation(c)});
			//vr.add({validator:this.hasSentCaseToApproval, object:c, ok: this.hasSentCaseToApproval(c)});
		} else if( c.status===CaseIdStatus.READYFOROFFICER || c.status===CaseIdStatus.CLOSEDBYOFFICER ) {
			vr.add({validator:this.hasSentCaseToApproval, object:c, ok: this.hasSentCaseToApproval(c)});
		}
		return vr;
	}
}
