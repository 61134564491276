/* tslint:disable */
/* eslint-disable */
/**
 * SelmaSME API
 * This definition is the API between customer SME-Gui and the persistence layer for the application NOT FOR V1 When customer logg-in to Selma-EN, we will create a processId with applicant CustomerId (from BankID). When a customer added attachment to the application, we don\'t have a CaseId i LP, should we add a LP-Service to create/reserve a caseId to be able to attach a document to the caseId in DM.
 *
 * The version of the OpenAPI document: 0.7.4
 * Contact: developer@landshypotek.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * <h3>StakeholderType</h3><br>Maps to Loan Process stakeholders<br><b>\'Borgensman\'<br>\'Driftbolag\'<br>\'Maka/Make/regpartner\'<br>\'sambo\'<br>\'ägarandel i pant\'<br>\'ägare juridiskt över 50%\'<br>\'övriga med ekonomisk intressegemenskap\'<br>\'SOKANDE\'</b> - betyder att de är en sökande på lånet.<br>
 * @export
 * @enum {string}
 */
export enum StakeholderType {
    SOKANDE = 'SOKANDE',
    INTRESSENT = 'INTRESSENT',
    BORGENSMAN = 'BORGENSMAN',
    DRIFTBOLAG = 'DRIFTBOLAG',
    MAKEMAKAREGPART = 'MAKEMAKAREGPART',
    AGAREJURIDIKO50 = 'AGAREJURIDIKO50',
    AGARANDELIPANT = 'AGARANDELIPANT',
    SAMBO = 'SAMBO',
    EKONOMISKINTRESSEGEMENSKAP = 'EKONOMISKINTRESSEGEMENSKAP'
}

export function StakeholderTypeFromJSON(json: any): StakeholderType {
    return StakeholderTypeFromJSONTyped(json, false);
}

export function StakeholderTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): StakeholderType {
    return json as StakeholderType;
}

export function StakeholderTypeToJSON(value?: StakeholderType | null): any {
    return value as any;
}

