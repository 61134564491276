/* tslint:disable */
/* eslint-disable */
/**
 * SelmaSME API
 * This definition is the API between customer SME-Gui and the persistence layer for the application NOT FOR V1 When customer logg-in to Selma-EN, we will create a processId with applicant CustomerId (from BankID). When a customer added attachment to the application, we don\'t have a CaseId i LP, should we add a LP-Service to create/reserve a caseId to be able to attach a document to the caseId in DM.
 *
 * The version of the OpenAPI document: 0.7.4
 * Contact: developer@landshypotek.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * This represent loan in other institute to redeem
 * @export
 * @interface TakeoverLoanType
 */
export interface TakeoverLoanType {
    /**
     * application model unique identification
     * @type {string}
     * @memberof TakeoverLoanType
     */
    processId?: string;
    /**
     * takeoverLoanId identity
     * @type {string}
     * @memberof TakeoverLoanType
     */
    takeoverLoanId: string;
    /**
     * Lånet är hos kredit institut
     * @type {string}
     * @memberof TakeoverLoanType
     */
    creditInstitute?: string;
    /**
     * This will only be a temporary loanNumber, when can we reserve a loan number in SAP
     * @type {string}
     * @memberof TakeoverLoanType
     */
    loanNumber?: string;
    /**
     * Dept of loan in other institute to redeem
     * @type {number}
     * @memberof TakeoverLoanType
     */
    debtAmount?: number;
    /**
     * Ändamål och syfte ska sättas på respektive lån som går över till LP. I de fall där det inte specifiseras ska handläggaren göra det innan överföring till SAP. Detta för att de ska bli rätt i uppföljning ned i SAS och den finsiella rapporteringen.
     * @type {string}
     * @memberof TakeoverLoanType
     */
    purposeText?: string;
    /**
     * Delsyfte på respektive lån, läses från mainPurposes.json
     * @type {string}
     * @memberof TakeoverLoanType
     */
    aimText?: string;
    /**
     * Aktuell ränta hos nuvarande institute inför lösen
     * @type {number}
     * @memberof TakeoverLoanType
     */
    interest?: number;
    /**
     * Aktuell bindningstid hos nuvarande institue inför lösen
     * @type {string}
     * @memberof TakeoverLoanType
     */
    conditionchangedate?: string;
}

export function TakeoverLoanTypeFromJSON(json: any): TakeoverLoanType {
    return TakeoverLoanTypeFromJSONTyped(json, false);
}

export function TakeoverLoanTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): TakeoverLoanType {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'processId': !exists(json, 'processId') ? undefined : json['processId'],
        'takeoverLoanId': json['takeoverLoanId'],
        'creditInstitute': !exists(json, 'creditInstitute') ? undefined : json['creditInstitute'],
        'loanNumber': !exists(json, 'loanNumber') ? undefined : json['loanNumber'],
        'debtAmount': !exists(json, 'debtAmount') ? undefined : json['debtAmount'],
        'purposeText': !exists(json, 'purposeText') ? undefined : json['purposeText'],
        'aimText': !exists(json, 'aimText') ? undefined : json['aimText'],
        'interest': !exists(json, 'interest') ? undefined : json['interest'],
        'conditionchangedate': !exists(json, 'conditionchangedate') ? undefined : json['conditionchangedate'],
    };
}

export function TakeoverLoanTypeToJSON(value?: TakeoverLoanType | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'processId': value.processId,
        'takeoverLoanId': value.takeoverLoanId,
        'creditInstitute': value.creditInstitute,
        'loanNumber': value.loanNumber,
        'debtAmount': value.debtAmount,
        'purposeText': value.purposeText,
        'aimText': value.aimText,
        'interest': value.interest,
        'conditionchangedate': value.conditionchangedate,
    };
}


