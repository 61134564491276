import React from 'react';
import { Applicant } from '../../../../models/selmamodels/Applicant';
import { AppComponent } from '../../../AppComponent';
import { FormUnit, FormUnitSize } from '../../../layout/FormUnit/FormUnit';
import { Label } from '../../../widget/display/Label/Label';
import { Textbox, TextboxType } from '../../../widget/input/Textbox/Textbox';
import { ApplicantService } from '../../../../services/ApplicantService';
import { Validation } from '../../../widget/display/Validation/Validation';

interface Props {
	applicant: Applicant;
}

interface State {
	email?: string;
	confirmEmail?: string;
	showConfirmEmail: boolean;
	notifyBy: string[];
}

export class ApplicantContact extends AppComponent<Props, State> {
	applicantService: ApplicantService;

	constructor(props: Props) {
		super(props);

		this.applicantService = this.application.services.applicantService;

		const applicant = this.props.applicant;

		this.observeUpdate(applicant);

		let initialNotifyBy: string[] = [];

		if (applicant.notifyByMobilePhone) initialNotifyBy.push('MobilePhone');
		if (applicant.notifyByEmail) initialNotifyBy.push('Email');
		
		this.state = {
			email: applicant.email,
			confirmEmail: applicant.emailConfirm,
			showConfirmEmail: applicant.email!==applicant.emailConfirm,
			notifyBy: initialNotifyBy,
		};
	}

	mobilePhoneChanged = (event: React.ChangeEvent<HTMLInputElement>): void => {
		this.props.applicant.mobilePhone = event.target.value;
		this.applicantService.update(this.props.applicant);
	};

	mobilePhoneBlurred = (event: React.ChangeEvent<HTMLInputElement>): void => {
		this.applicantService.reformatMobilePhone(this.props.applicant)
	};

	emailChanged = (event: React.ChangeEvent<HTMLInputElement>): void => {
		const value = event.target.value;
		this.setState(
			{
				email: value,
				showConfirmEmail: true,
			},
			this.tryUpdateEmail
		);

		this.props.applicant.email = value;
		this.applicantService.update(this.props.applicant);
	};
	emailConfirmChanged = (event: React.ChangeEvent<HTMLInputElement>): void => {
		const value = event.target.value;
		this.setState(
			{
				confirmEmail: value,
				showConfirmEmail: true,
			},
			this.tryUpdateEmail
		);

		this.props.applicant.emailConfirm = value;
		this.applicantService.update(this.props.applicant);
	};
	stateEmailMatchesConfirm(): boolean {
		return this.state.email === this.state.confirmEmail;
	}
	tryUpdateEmail() {
	}

	handleNotifyByChange = (values: string[]) => {
		this.setState({
			notifyBy: values,
		});

		this.props.applicant.notifyByMobilePhone = values.indexOf('MobilePhone') > -1 ? true : false;
		this.props.applicant.notifyByEmail = ((values.indexOf('Email') > -1)) ? true : false;

		this.applicantService.update(this.props.applicant);
	}

	render() {
		const a = this.props.applicant;
		const uq = a.customerId + '_';

		return (
			<div className="ApplicantContact">
				<FormUnit size={FormUnitSize.Small}>
					<Label
						for={uq + 'mobilePhone'}
						labelTextkey="Applicant_MobilePhone"
						required={true}
					/>
					<Validation
						forObject={a}
						errors={[
							{
								validator: this.applicantService.hasValidMobilePhone,
								textkey: 'Applicant_MobilePhone_Error',
							},
						]}>
						<Textbox
							id={uq + 'mobilePhone'}
							type={TextboxType.tel}
							value={a.mobilePhone || ''}
							handleChange={this.mobilePhoneChanged}
							handleBlur={this.mobilePhoneBlurred}
							maxlength={20}
						/>
					</Validation>
				</FormUnit>
				<FormUnit>
					<Label
						for={uq + 'email'}
						labelTextkey="Applicant_Email"
						required={true}
					/>
					<Validation
						forObject={a}
						errors={[
							{
								validator: this.applicantService.hasValidEmail,
								textkey: 'Applicant_Email_Error',
							},
						]}>
						<Textbox
							id={uq + 'email'}
							type={TextboxType.email}
							value={a.email || ''}
							handleChange={this.emailChanged}
							maxlength={200}
						/>
					</Validation>
				</FormUnit>
				{this.state.showConfirmEmail && (
					<FormUnit>
						<Label
							for={uq + 'emailConfirm'}
							labelTextkey="Applicant_EmailConfirm"
							required={true}
						/>
						<Validation
							forObject={a}
							errors={[
								{
									validator: this.applicantService.hasMatchingConfirmEmail,
									textkey: 'Applicant_EmailConfirm_NoMatch',
								},
							]}>
							<Textbox
								id={uq + 'emailConfirm'}
								type={TextboxType.email}
								value={a.emailConfirm || ''}
								handleChange={this.emailConfirmChanged}
								maxlength={200}
							/>
						</Validation>
					</FormUnit>
				)}
			</div>
		);
	}
}
