import React from 'react';

import { Media } from 'react-breakpoints';

import './StatusBar.scss';

interface Props {
	className?: string;
	children: React.ReactNode | any;
}

export const StatusBar: React.FunctionComponent<Props> = (props) => {
	return (
		<div className={props.className}>
			<Media>
				{({ breakpoints, currentBreakpoint }) => {
					switch (breakpoints[currentBreakpoint]) {
						case breakpoints.xl:
							return props.children({ size: 48 });
						case breakpoints.lg:
							return props.children({ size: 48 });
						case breakpoints.md:
							return props.children({ size: 48 });
						case breakpoints.sm:
							return props.children({ size: 48 });
						case breakpoints.smxs:
							return props.children({ size: 48 });
						case breakpoints.xs:
							return props.children({ size: 48 });
						default:
							return props.children({ size: 24 });
					}
				}}
			</Media>
		</div>
	);
};

StatusBar.defaultProps = {
	className: 'StatusBar',
};
