/* tslint:disable */
/* eslint-disable */
/**
 * SelmaSME API
 * This definition is the API between customer SME-Gui and the persistence layer for the application NOT FOR V1 When customer logg-in to Selma-EN, we will create a processId with applicant CustomerId (from BankID). When a customer added attachment to the application, we don\'t have a CaseId i LP, should we add a LP-Service to create/reserve a caseId to be able to attach a document to the caseId in DM.
 *
 * The version of the OpenAPI document: 0.7.4
 * Contact: developer@landshypotek.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    RevenueType,
    RevenueTypeFromJSON,
    RevenueTypeFromJSONTyped,
    RevenueTypeToJSON,
} from './';

/**
 * Type definition for company economy
 * @export
 * @interface CompanyEconomyType
 */
export interface CompanyEconomyType {
    /**
     * application model unique identification
     * @type {string}
     * @memberof CompanyEconomyType
     */
    processId: string;
    /**
     * generated uuid in the model
     * @type {string}
     * @memberof CompanyEconomyType
     */
    companyId: string;
    /**
     * Type definition for company economy
     * @type {string}
     * @memberof CompanyEconomyType
     */
    companyEconomyId: string;
    /**
     * Customer category return by SAS (kundklass Not V1)
     * @type {number}
     * @memberof CompanyEconomyType
     */
    customerCategory?: number;
    /**
     * Årsomsättning
     * @type {Array<RevenueType>}
     * @memberof CompanyEconomyType
     */
    revenues?: Array<RevenueType>;
}

export function CompanyEconomyTypeFromJSON(json: any): CompanyEconomyType {
    return CompanyEconomyTypeFromJSONTyped(json, false);
}

export function CompanyEconomyTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyEconomyType {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'processId': json['processId'],
        'companyId': json['companyId'],
        'companyEconomyId': json['companyEconomyId'],
        'customerCategory': !exists(json, 'customerCategory') ? undefined : json['customerCategory'],
        'revenues': !exists(json, 'Revenues') ? undefined : ((json['Revenues'] as Array<any>).map(RevenueTypeFromJSON)),
    };
}

export function CompanyEconomyTypeToJSON(value?: CompanyEconomyType | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'processId': value.processId,
        'companyId': value.companyId,
        'companyEconomyId': value.companyEconomyId,
        'customerCategory': value.customerCategory,
        'Revenues': value.revenues === undefined ? undefined : ((value.revenues as Array<any>).map(RevenueTypeToJSON)),
    };
}


