import React, { Component } from 'react';
import { AppComponent } from '../AppComponent';
import { T } from '../widget/display/T';
import { THtml } from '../widget/display/THtml';
import { Heading } from '../widget/display/Heading/Heading';
import { StepContext } from './CaseSteps';

interface Props {
	context: StepContext;
	titleTextkey: string;
	descriptionTextkey: string;
}

export class StepHeader extends AppComponent<Props> {
	constructor(props: Props) {
		super(props);
	}

	render() {
		const c = this.props.context.case;
		let helpText;
		if(c.number) {
			helpText = this.application.services.textService.textOrEmpty("Home_CaseN") + c.number;
			
			helpText += ",  ändrat " + c.lastAccessedDate.toLocaleString();
		}

		return (
			<div className="StepHeader">
				<Heading level={1} labelTextkey={this.props.titleTextkey} helpText={helpText} />
				<p>
					<THtml k={this.props.descriptionTextkey}/>
				</p>
			</div>
		);
	}
}
