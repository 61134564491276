import React from 'react';
import { T } from '../../widget/display/T';
import { Modal } from '../Modal/Modal';
import {
	ButtonGroup,
	ButtonGroupLayout,
} from '../../widget/input/ButtonGroup/ButtonGroup';
import { Button } from '../../widget/input/Button/Button';
import '../Modal/Modal.scss';
import { THtml } from '../../widget/display/THtml';

interface Props {
	modal: ModalProps;
}

export interface ModalProps {
	isOpen: boolean;
	titleTextkey: string;
	questionTextkey: string;
	handleClickYes: (
		event: React.MouseEvent<HTMLButtonElement, MouseEvent>
	) => void;
	handleClickNo: (
		event: React.MouseEvent<HTMLButtonElement, MouseEvent>
	) => void;
	handleClose: (
		event:
			| React.MouseEvent<HTMLButtonElement, MouseEvent>
			| React.MouseEvent<HTMLDivElement, MouseEvent>
	) => void;
}

export const SaveModal: React.FunctionComponent<Props> = (props) => {
	const modalProps = { ...props.modal };

	return props.modal && props.modal.isOpen ? (
		<Modal overlayCloseOnClick={true} handleClose={modalProps.handleClose}>
			<div className="ModalContent">
				<h1>
					{modalProps.titleTextkey ? (
						<THtml k={modalProps.titleTextkey} />
					) : null}
				</h1>
				<p>
					{modalProps.questionTextkey ? (
						<THtml k={modalProps.questionTextkey} />
					) : null}
				</p>
			</div>
			<div className="ModalFooter">
				<ButtonGroup layout={ButtonGroupLayout.Stretch}>
					<Button
						style="normal"
						labelTextkey="Yes"
						onClick={modalProps.handleClickYes}
						autofocus={true}
					/>
					<Button
						style="normal"
						labelTextkey="No"
						onClick={modalProps.handleClickNo}
					/>
				</ButtonGroup>
			</div>
		</Modal>
	) : null;
};
