import React from 'react';
import { Case, ExtLoan } from '../../../../models/SelmaModels';
import { AppComponent } from '../../../AppComponent';
import { Button } from '../../../widget/input/Button/Button';
import { Panel } from '../../../layout/Panel/Panel';
import {
	HeaderButton,
	HeaderButtonLayout,
} from '../../../layout/HeaderButton/HeaderButton';
import { DeleteButton } from '../../../widget/input/DeleteButton/DeleteButton';
import { HouseholdExtLoan } from './HouseholdExtLoan';
import { Heading } from '../../../widget/display/Heading/Heading';
import {
	FormUnit,
	FormUnitSize,
	FormUnitPadding,
} from '../../../layout/FormUnit/FormUnit';
import { TabPanels } from '../../../widget/display/TabPanels/TabPanels';
import { ValidationResult } from '../../../../utils/ValidationResult';

interface Props {
	case: Case;
}

export class HouseholdExtLoans extends AppComponent<Props> {
	extLoanService = this.application.services.extLoanService;

	constructor(props: Props) {
		super(props);
		this.observeUpdate(this.props.case);
	}

	addExtLoanServicerClicked = (): void => {
		let guarantor = this.extLoanService.newExtLoan();
		this.extLoanService.addExtLoan(this.props.case, guarantor);
	};

	removeExtLoanServicerClicked = (
		event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
		extLoan: ExtLoan
	): void => {
		this.extLoanService.removeExtLoan(this.props.case, extLoan);
	};

	canAddExtLoan = () => {

		const c = this.props.case;
		
		// Allow add if no extloans exists (=none is invalid)
		if (!c.extLoans || c.extLoans.length === 0)
			return true;

		const svc = this.application.services.extLoanService;

		let vr = new ValidationResult();
		c.extLoans.forEach(l => {
			vr.addResult(svc.validateExtLoan(c, l));
		});

		return vr.ok();
	};

	render() {
		const c = this.props.case;
		const textService = this.application.services.textService;
		let canAdd = this.canAddExtLoan();

		const tabs =
			c.extLoans &&
			c.extLoans.map((x, ix) => {
				return {
					id: x.id,
					label: textService.textOrEmpty('Household_ExtLoan') + " " + (ix + 1),
				};
			});

		return (
			<div className="ExtLoans">
				<FormUnit
					size={FormUnitSize.Large}
					padding={FormUnitPadding.Normal}>
					<Heading level={2} labelTextkey="Household_ExtLoan_Title" />

					{c.extLoans && (
						<TabPanels tabs={tabs}>
							{c.extLoans.map((x, ix) => {
								return (
									<Panel key={ix}>
										<HeaderButton layout={HeaderButtonLayout.Stretch}>
											<Heading
												level={3}
												labelTextkey="Household_ExtLoan"
												labelText={' ' + (ix + 1)}
											/>
											<DeleteButton
												labelTextkey="Household_ExtLoan_Remove"
												questionTextkey="Household_ExtLoan_Remove_Q"
												onClick={(e) =>
													this.removeExtLoanServicerClicked(e, x)
												}
											/>
										</HeaderButton>
										<HouseholdExtLoan case={c} extLoan={x} />
									</Panel>
								);
							})}
						</TabPanels>
					)}
					<FormUnit
						size={FormUnitSize.Large}
						padding={FormUnitPadding.Normal}>
						<Button
							labelTextkey="Household_ExtLoan_Add"
							className="Button plus-icon"
							onClick={this.addExtLoanServicerClicked}
							enabled={canAdd}
							style='link'
						/>
					</FormUnit>
				</FormUnit>
			</div>
		);
	}
}
