import React from 'react';
import { Loan } from '../../../../models/selmamodels/Loan';
import { AppComponent } from '../../../AppComponent';
import { SelectOption } from '../../../widget/input/Select/Select';
import { LoanTakeOver } from './LoanTakeOver';
import { Button } from '../../../widget/input/Button/Button';
import { uiLoanAimCategories, uiBanks } from '../../../../models/UiModels';
import { LoanService } from '../../../../services/LoanService';
import { getUIName } from '../../../../models/uimodels/UINamed';

interface Props {
	loan: Loan;
}

export class LoanTakeOvers extends AppComponent<Props> {
	aimCategoryOptions: SelectOption[];
	bankOptions: SelectOption[];
	loanService: LoanService;

	constructor(props: Props) {
		super(props);

		this.loanService = this.application.services.loanService;

		// Create drop down options in two levels.
		this.aimCategoryOptions = uiLoanAimCategories.map((x) => {
			const bcOption = {
				label: x.name,
				value: x.id,
			};
			(bcOption as any).detailOptions = x.details.map((y) => {
				const bdOption = {
					label: y.name,
					value: y.id,
				};
				return bdOption;
			});
			return bcOption;
		});

		// Create bank drop down options
		const textService = this.application.services.textService;
		this.bankOptions = uiBanks.map((bank) => {
			return {
				label: getUIName(bank, textService),
				value: bank.key
			};
		});
	}

	addLoanTakeOver = (): void => {
		this.loanService.addNewLoanTakeOver(this.props.loan);
	}

	render() {
		const loanService = this.loanService;
		const loan = this.props.loan;
		const takeoverLoans = loan.takeoverLoans || [];
		const canDelete = true;
		const enableAddTakeoverLoan = loanService.validateTakeoverLoans(loan).ok();

		return (
			<div className="LoanTakeOvers">
				{takeoverLoans.map((takeoverLoan, ix) => {
					return (
						<LoanTakeOver
							key={`loanTakeOver_${ix}_${takeoverLoan.id}`}
							aimCategoryOptions={this.aimCategoryOptions}
							loan={loan}
							takeoverLoan={takeoverLoan}
							index={ix}
							canDelete={canDelete}
							bankOptions={this.bankOptions}
						/>
					);
				})}
				<Button
					id="addLoanTakeOver"
					className="Button plus-icon"
					name="addLoanTakeOver"
					labelTextkey="Loan_AddLoanTakeOver"
					onClick={this.addLoanTakeOver}
					enabled={enableAddTakeoverLoan}
					style='link'
				/>
			</div>
		);
	}
}
