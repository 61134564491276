import { UiLanguageService } from '../services/UiLanguageService';
import { SessionService } from '../services/SessionService';
import { SMELPOService } from '../services/SMELPOService';
import { TextService } from '../services/TextService';
import { ConfigurationService, Configuration } from '../services/ConfigurationService';
import { GuardService } from '../services/GuardService';
import { StateService } from '../services/StateService';
import { CaseService } from '../services/CaseService';
import { ApplicantService } from '../services/ApplicantService';
import { StepService } from '../services/StepService';
import { CompanyEconomyService } from '../services/CompanyEconomyService';
import { CompanyService } from '../services/CompanyService';
import { LoanService } from '../services/LoanService';
import { HouseholdService } from '../services/HouseholdService';
import { CollateralService } from '../services/CollateralService';
import { IdService } from '../services/IdService';
import { ArrayService } from '../services/ArrayService';
import { PersonalEconomyService } from '../services/PersonalEconomyService';
import { GuarantorService } from '../services/GuarantorService';
import { EuSupportService } from '../services/EuSupportService';
import { ExtLoanService } from '../services/ExtLoanService';
import { MaintenanceCostService } from '../services/MaintenanceCostService';
import { AttachmentService } from '../services/AttachmentService';
import { BudgetService } from '../services/BudgetService';
import { SummaryService } from '../services/SummaryService';
import { ConvertService } from '../services/ConvertService';
import KycService from '../services/KycService';
import OidcService from '../services/OidcService';

export class Application {

	services: ApplicationServices;

	private constructor() {
		this.services = {} as ApplicationServices;
	}

	static instance: Application;
	static alloc(): Application {
		return this.instance = new Application();
	}

	init(): Promise<Application> {
		const stateService = new StateService();
		const configurationService = new ConfigurationService(stateService);

		let configuration: Configuration;
		let textService: TextService;
		return configurationService.load("/config.json")
			.then(result => {
				configuration = result;

				const uiLanguageService = new UiLanguageService(configurationService);
				textService = new TextService(stateService, uiLanguageService);
				const loadingTexts = textService.useLanguage("sv-SE");
				return loadingTexts;

			}).then(uiLanguage => {
				const oidcService = new OidcService(configuration.oidcBaseUrl);
				const sessionService = new SessionService(stateService, oidcService, configurationService);
				const smelpoService = new SMELPOService(configuration.lpBaseUrl, sessionService);
				const guardService = new GuardService();
				const idService = new IdService();
				const arrayService = new ArrayService();
				const convertService = new ConvertService(guardService);
				guardService.convertService = convertService;
				const applicantService = new ApplicantService(stateService, smelpoService, guardService, idService, configurationService);
				const kycService = new KycService(configuration.lpBaseUrl, applicantService);
				const personalEconomyService = new PersonalEconomyService(stateService, smelpoService, guardService, idService);
				const loanService = new LoanService(this, stateService, smelpoService, guardService);
				const caseService = new CaseService(stateService, smelpoService, guardService, applicantService, kycService, idService, sessionService, configurationService);
				const collateralService = new CollateralService(stateService, smelpoService, guardService, idService);
				const guarantorService = new GuarantorService(stateService, smelpoService, guardService, idService);
				const euSupportService = new EuSupportService(stateService, smelpoService, guardService, idService, textService);
				const householdService = new HouseholdService(stateService, smelpoService, guardService, applicantService, idService);
				applicantService.householdService = householdService;
				const extLoanService = new ExtLoanService(stateService, smelpoService, guardService, applicantService, idService);
				const maintenanceCostService = new MaintenanceCostService(stateService, smelpoService, guardService, applicantService, idService);
				const companyService = new CompanyService(stateService, smelpoService, guardService, idService, applicantService, convertService);
				applicantService.companyService = companyService;
				householdService.companyService = companyService;
				householdService.extLoanService = extLoanService;
				householdService.maintenanceCostService = maintenanceCostService;
				const companyEconomyService = new CompanyEconomyService(stateService, smelpoService, guardService, caseService, companyService, idService, applicantService);
				companyService.companyEconomyService = companyEconomyService;
				const attachmentService = new AttachmentService(stateService, smelpoService, guardService, applicantService, personalEconomyService,
					companyService, companyEconomyService, configurationService, collateralService, idService, loanService, textService, configuration.smeBaseUrl);
				caseService.attachmentService = attachmentService;
				const budgetService = new BudgetService(stateService, smelpoService, guardService, idService, companyEconomyService, attachmentService);
				companyEconomyService.budgetService = budgetService;
				attachmentService.budgetService = budgetService;
				const summaryService = new SummaryService(stateService, guardService, idService);
				const stepService = new StepService(this, textService, applicantService);

				this.services = {
					stateService: stateService,
					configurationService: configurationService,
					textService: textService,
					sessionService: sessionService,
					guardService: guardService,
					idService: idService,
					arrayService: arrayService,
					convertService: convertService,
					caseService: caseService,
					loanService: loanService,
					applicantService: applicantService,
					personalEconomyService: personalEconomyService,
					householdService: householdService,
					maintenanceCostService: maintenanceCostService,
					extLoanService: extLoanService,
					collateralService: collateralService,
					guarantorService: guarantorService,
					euSupportService: euSupportService,
					companyService: companyService,
					companyEconomyService: companyEconomyService,
					budgetService: budgetService,
					attachmentService: attachmentService,
					summaryService: summaryService,
					stepService: stepService,
					kycService: kycService,
					oidcService: oidcService

				} as ApplicationServices;
				return this.services;
			}).then(services => {
				stateService.state.loaded = true;
				stateService.update(stateService.state);
				//return this.services.sessionService.loadSession();
			}).then(() => {
				return this;
			});
	}
}

export interface ApplicationServices {
	stateService: StateService;
	configurationService: ConfigurationService;
	textService: TextService;
	sessionService: SessionService;
	idService: IdService;
	arrayService: ArrayService;
	convertService: ConvertService;
	guardService: GuardService;
	caseService: CaseService;
	applicantService: ApplicantService;
	personalEconomyService: PersonalEconomyService;
	loanService: LoanService;
	companyService: CompanyService;
	companyEconomyService: CompanyEconomyService;
	budgetService: BudgetService;
	collateralService: CollateralService;
	guarantorService: GuarantorService;
	euSupportService: EuSupportService;
	householdService: HouseholdService;
	maintenanceCostService: MaintenanceCostService;
	extLoanService: ExtLoanService;
	attachmentService: AttachmentService;
	summaryService: SummaryService;
	stepService: StepService;
	kycService: KycService;
	oidcService: OidcService;
}

