import React from 'react';
import { Case, EUSupport, EUSupportEnum } from '../../../../models/SelmaModels';
import { AppComponent } from '../../../AppComponent';
import { FormUnit, FormUnitSize } from '../../../layout/FormUnit/FormUnit';
import { Select, SelectOption } from '../../../widget/input/Select/Select';
import { THtml } from '../../../widget/display/THtml';
import { Money } from '../../../widget/input/Money/Money';
import { NumberFormatValues } from 'react-number-format';
import { Label } from '../../../widget/display/Label/Label';
import { uiEUSupports } from '../../../../models/UiModels';
import { Validation } from '../../../widget/display/Validation/Validation';
import { Heading } from '../../../widget/display/Heading/Heading';
import { TextService } from '../../../../services/TextService';
import { getUIName } from '../../../../models/uimodels/UINamed';

interface Props {
	case: Case;
}

export class CollateralEUSupports extends AppComponent<Props> {
	euSupportService = this.application.services.euSupportService;
	year: string;
	euSupportTypeOptions: SelectOption[];

	constructor(props: Props) {
		super(props);
		let c = this.props.case
		this.observeUpdate(c);
		this.year = new Date().getFullYear().toString();

		const textService = this.application.services.textService;
		this.euSupportTypeOptions = uiEUSupports.map((x) => {
			return {
				value: x.key,
				label: getUIName(x, textService),
			};
		});

		if( c.euSupports ) {
			c.euSupports.forEach(x => {
				this.observeUpdate(x);
			})
		}
	}

	euSupportAmountChanged = (
		values: NumberFormatValues,
		euSupport: EUSupport
	): void => {
		euSupport.supportAmount = values.floatValue;
		this.euSupportService.update(euSupport);
	};

	euSupportTypeChanged = (value: string, euSupport: EUSupport): void => {
		let euSupportType = EUSupportEnum[value];
		euSupport.euType = euSupportType;
		this.euSupportService.update(euSupport);
	};

	render() {
		let euSupports = this.props.case.euSupports;
		if (euSupports)
			euSupports = euSupports.filter(
				(x) => x.supportYear && x.supportYear === this.year
			);

		return (
			<div className="CollateralEUSupports">
				<FormUnit size={FormUnitSize.Large}>
					<Heading
						level={3}
						labelTextkey="Collateral_EuSupport_Title"
					/>
					{euSupports &&
						euSupports.map((x, ix) => {
							const euSupportTypeValue: string = x.euType
								? EUSupportEnum[x.euType]
								: '';

							return (
								<div
									className="CollateralEUSupport"
									key={`eusupport_${ix}`}>
									<FormUnit>
										<Label
											for="euSupportType"
											labelTextkey="Collateral_EuSupport_SupportType"
											required={true}
										/>
										<Validation
											forObject={x}
											errors={[
												{
													validator: this.application.services
														.euSupportService.hasType,
													textkey: 'Error_SelectValue',
												},
											]}>
											<Select
												id={'euSupportType'}
												value={euSupportTypeValue}
												options={this.euSupportTypeOptions}
												handleChange={(e) => {
													this.euSupportTypeChanged(e, x);
												}}
												placeholderTextkey="Collateral_EuSupport_SelectSupportType"
												noSelectableOptionsTextkey="Select_NoOptions"
											/>
										</Validation>
									</FormUnit>
									<FormUnit size={FormUnitSize.Small}>
										<Label
											for="euSupportAmount"
											labelTextkey="Collateral_EuSupport_SupportAmount"
											required={true}
										/>
										<Validation
											forObject={x}
											errors={[
												{
													validator: this.application.services
														.euSupportService.hasAmount,
													textkey: 'Error_EnterAmount',
												},
											]}>
											<Money
												id="euSupportAmount"
												value={x.supportAmount}
												min={0}
												handleChange={(e) =>
													this.euSupportAmountChanged(e, x)
												}
											/>
										</Validation>
									</FormUnit>
									<FormUnit>
										<THtml k="Collateral_EuSupport_SupportYear" />
										{/* {x.supportYear} */}
									</FormUnit>
								</div>
							);
						})}
				</FormUnit>
			</div>
		);
	}
}
