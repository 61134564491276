import React from 'react';
import { Applicant, Case } from '../../../../../models/SelmaModels';
import { AppComponent } from '../../../../AppComponent';
import { PersonApplicantItemSummary } from './PersonApplicantItemSummary';
import { PersonalCompanyApplicantItemSummary } from './PersonalCompanyApplicantItemSummary';
import { IncorporatedCompanyApplicantItemSummary } from './IncorporatedCompanyApplicantItemSummary';

interface Props {
	case:Case,
	applicant: Applicant;
	hideCompanyInformation?:boolean;
}

export class ApplicantItemSummary extends AppComponent<Props> {
	constructor(props: Props) {
		super(props);
	}

	render() {
		const applicantService = this.application.services.applicantService;
		const companyService = this.application.services.companyService;
		const c = this.props.case;
		const applicant = this.props.applicant;
		const hideCompanyInformation = this.props.hideCompanyInformation||false;

		const isJuridicumCompany = applicantService.isJuridicumCompany(c, applicant);
		const isPersonalCompany = applicantService.isPersonalCompany(c, applicant);
		const applicantCompany = companyService.getApplicantCompany(c, applicant);
		const isPrivatePerson = (applicantCompany?false:true);

		return (
			<>
				{isPrivatePerson &&
					<PersonApplicantItemSummary case={c} applicant={applicant} />
				}
				{isPersonalCompany &&
					<PersonalCompanyApplicantItemSummary case={c} applicant={applicant} hideCompanyInformation={hideCompanyInformation}/>
				}
				{isJuridicumCompany &&
					<IncorporatedCompanyApplicantItemSummary case={c} applicant={applicant} hideCompanyInformation={hideCompanyInformation}/>
				}
			</>
		);
	}
}
