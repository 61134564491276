export default function(hasErrors, touched) {
	return {
		control: (providedStyles, state) => ({
			...providedStyles,
			borderWidth: 0,
			borderRadius: 0,
			backgroundColor: touched && hasErrors ? '#FFF8F8' : '#FFF',
			boxShadow: '0',
			minHeight: '42px',
			outline: state.isFocused ? '5px auto #4292AF' : null,
		}),
		option: (providedStyles, state) => ({
			...providedStyles,
			border: 0,
			backgroundColor: state.isDisabled
				? null
				: state.isSelected
				? '#D7E6EA'
				: state.isFocused
				? '#E9F2F5'
				: null,
			color: '#4A4646',
			minHeight: '42px',
			fontSize: '1rem',
			lineHeight: '26px',
			padding: '8px 16px',
			':hover': {
				backgroundColor: '#E9F2F5',
			},
		}),
		singleValue: (providedStyles, state) => ({
			...providedStyles,
			color: '#4A4646',
			fontSize: '1rem',
			lineHeight: '26px',
			opacity: state.isDisabled ? 0.5 : 1,
			transition: 'opacity 300ms',
		}),
		placeholder: (providedStyles, state) => ({
			...providedStyles,
			color: '#4A4646',
			fontSize: '1rem',
			lineHeight: '26px',
			opacity: state.isDisabled ? 0.5 : 1,
			transition: 'opacity 300ms',
		}),
		valueContainer: (providedStyles, state) => ({
			...providedStyles,
			minHeight: '42px',
			height: '42px',
			padding: '8px 16px',
			borderColor:
				touched && hasErrors
					? '#E16548'
					: state.menuIsOpen
					? '#4292AF'
					: '#D5D4D4',
			'&:hover': { borderColor: '#4292AF' },
			borderStyle: 'solid',
			borderLeftWidth: '1px',
			borderTopWidth: '1px',
			borderBottomWidth: '1px',
			borderRightWidth: '0',
		}),
		menuList: (providedStyles, state) => ({
			...providedStyles,
			margin: 0,
			padding: 0,
		}),
		menu: (providedStyles, state) => ({
			...providedStyles,
			borderRadius: 0,
			margin: 0,
			padding: 0,
		}),
		indicatorsContainer: (providedStyles, state) => ({
			...providedStyles,
		}),
		indicatorSeparator: (providedStyles, state) => ({
			...providedStyles,
			display: 'none',
		}),
		dropdownIndicator: (providedStyles, state) => ({
			...providedStyles,
			width: '42px',
			height: '42px',
			justifyContent: 'center',
			alignItems: 'center',
			color: '#FFF',
			backgroundColor: '#4292AF',
			borderStyle: 'solid',
			borderLeftWidth: '0',
			borderTopWidth: '1px',
			borderBottomWidth: '1px',
			borderRightWidth: '1px',
			borderColor: touched && hasErrors ? '#E16548' : '#4292AF',
		}),
		clearIndicator: (providedStyles, state) => ({
			...providedStyles,
			width: '42px',
			height: '42px',
			justifyContent: 'center',
			alignItems: 'center',
			color: '#FFF',
			backgroundColor: '#4292AF',
		}),
	};
};
