/* tslint:disable */
/* eslint-disable */
/**
 * SelmaSME API
 * This definition is the API between customer SME-Gui and the persistence layer for the application NOT FOR V1 When customer logg-in to Selma-EN, we will create a processId with applicant CustomerId (from BankID). When a customer added attachment to the application, we don\'t have a CaseId i LP, should we add a LP-Service to create/reserve a caseId to be able to attach a document to the caseId in DM.
 *
 * The version of the OpenAPI document: 0.7.4
 * Contact: developer@landshypotek.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * customers in a processid, a list array of customerId
 * @export
 * @interface CustomerIdType
 */
export interface CustomerIdType {
    /**
     * customerId is swedish ssn or corporate organisation number
     * @type {string}
     * @memberof CustomerIdType
     */
    customerId?: string;
    /**
     * timestamp when customer has been added to the application, to hold (huvudsökande, medsökande) order
     * @type {string}
     * @memberof CustomerIdType
     */
    customerAdded?: string;
}

export function CustomerIdTypeFromJSON(json: any): CustomerIdType {
    return CustomerIdTypeFromJSONTyped(json, false);
}

export function CustomerIdTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomerIdType {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'customerId': !exists(json, 'customerId') ? undefined : json['customerId'],
        'customerAdded': !exists(json, 'customerAdded') ? undefined : json['customerAdded'],
    };
}

export function CustomerIdTypeToJSON(value?: CustomerIdType | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'customerId': value.customerId,
        'customerAdded': value.customerAdded,
    };
}


