import React from 'react';
import { AppComponent } from '../../../AppComponent';
import { Case } from '../../../../models/SelmaModels';
import { FormUnit, FormUnitSize } from '../../../layout/FormUnit/FormUnit';
import { HouseholdExtLoans } from './HouseholdExtLoans';
import { HouseholdMaintenanceCosts } from './HouseholdMaintenanceCosts';
import { Households } from './Households';
import { StepContext } from '../../CaseSteps';
import { StepFooter } from '../../StepFooter';
import { StepHeader } from '../../StepHeader';
import { StepForm } from '../../../widget/display/StepForm/StepForm';
import { THtml } from '../../../widget/display/THtml';

interface Props {
	case: Case;
	context: StepContext;
	immidiateAllValidatorsVisibleBefore?: Date;
}

export class StepHousehold extends AppComponent<Props> {
	constructor(props: Props) {
		super(props);
		
		this.state = {
			immidiateAllValidatorsVisibleBefore: new Date()
		};
	}

	componentDidMount() {
		const c = this.props.case;
		// const householdService = this.application.services.householdService;
		// const shouldHaveHouseholds = householdService.shouldHaveHouseholds(c);
		// if( shouldHaveHouseholds )
		// 	this.application.services.householdService.ensureHouseholds(c);
		// else {
		// 	this.application.services.householdService.removeAllHouseholds(c);
		// }
	}

	triggerImmidiateValidation():void {
		this.setState({ immidiateAllValidatorsVisibleBefore: new Date() })
	}

	render() {
		const c = this.props.case; 
		const householdService = this.application.services.householdService;

		const hasHouseholds = householdService.hasHouseholds(c);
		const shouldHaveHouseholds = householdService.shouldHaveHouseholds(c);

		const validationResult = this.application.services.stepService.validateStepHousehold(c);
		const canGoToNext = validationResult.ok();
		const immidiateAllValidatorsVisibleBefore = this.state.immidiateAllValidatorsVisibleBefore;

		return (
			<div className="StepHousehold Step">
				<StepHeader
					context={this.props.context}
					titleTextkey="Household_Title"
					descriptionTextkey="Household_Description"
				/>

				<StepForm 
					context={this.props.context}
					immidiateAllValidatorsVisibleBefore={immidiateAllValidatorsVisibleBefore}>

					{ hasHouseholds &&
						<>
						<Households case={c} />
						<HouseholdMaintenanceCosts case={c} />
						<HouseholdExtLoans case={c} />
						</>
					}

					{ !shouldHaveHouseholds &&
						<FormUnit size={FormUnitSize.Large}>
							<p>
								<THtml k="Household_NoneNeeded"/>
							</p>
						</FormUnit>
					}

					<StepFooter
						context={this.props.context}
						canGoHome={true}
						canGoToNext={canGoToNext}
						onGoHome={this.props.context.goHome}
						onGoToNext={this.props.context.goNext}
						onTriggerImmidiateValidation={() => this.triggerImmidiateValidation() }
					/>
				</StepForm>
			</div>
		);
	}
}
