import React from 'react';
import { Case } from '../../../../../models/SelmaModels';
import { AppComponent } from '../../../../AppComponent';
import {
	FormUnit,
	FormUnitSize,
	FormUnitPadding,
} from '../../../../layout/FormUnit/FormUnit';

import { T } from '../../../../widget/display/T';
import Table from '../../../../widget/display/Table/Table';
import { Heading } from '../../../../widget/display/Heading/Heading';


interface Props {
	case: Case;
}

export class CollateralGuarantorsSummary extends AppComponent<Props> {

	constructor(props: Props) {
		super(props);
	}

	render() {
		const c = this.props.case;
		const hasGuarantor = this.application.services.guarantorService.hasGuarantor(c);
		if (!c.guarantors || !hasGuarantor) return null;

		return (
			<FormUnit size={FormUnitSize.Large} padding={FormUnitPadding.Normal}>
				<Heading level={3} labelTextkey="Summary_Collateral_Gurantors"/>
				<Table
					headingRow={[
						<T key="Summary_Collateral_Gurantors_Name" k="Summary_Collateral_Gurantors_Name" />,
						<T key="Summary_Collateral_Gurantors_Phone" k="Summary_Collateral_Gurantors_Phone" />,
						<T key="Summary_Collateral_Gurantors_SSN" k="Summary_Collateral_Gurantors_SSN" />,
					]}
					tableRows={c.guarantors.map(
						({ name, phone, guarantorCustomerId }) => [
							name,
							phone,
							guarantorCustomerId,
						]
					)}
				/>
			</FormUnit>
		);
	}
}
