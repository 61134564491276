/* tslint:disable */
/* eslint-disable */
/**
 * SelmaSME API
 * This definition is the API between customer SME-Gui and the persistence layer for the application NOT FOR V1 When customer logg-in to Selma-EN, we will create a processId with applicant CustomerId (from BankID). When a customer added attachment to the application, we don\'t have a CaseId i LP, should we add a LP-Service to create/reserve a caseId to be able to attach a document to the caseId in DM.
 *
 * The version of the OpenAPI document: 0.7.4
 * Contact: developer@landshypotek.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * typ av annat boende
 * @export
 * @enum {string}
 */
export enum TypeOfHouse {
    Villa = 'Villa',
    Hyresrtt = 'Hyresrätt',
    VrigtBoende = 'Övrigt Boende',
    Landbruksfastighet = 'Landbruksfastighet',
    Bostadsrtt = 'Bostadsrätt',
    Fritidshus = 'Fritidshus'
}

export function TypeOfHouseFromJSON(json: any): TypeOfHouse {
    return TypeOfHouseFromJSONTyped(json, false);
}

export function TypeOfHouseFromJSONTyped(json: any, ignoreDiscriminator: boolean): TypeOfHouse {
    return json as TypeOfHouse;
}

export function TypeOfHouseToJSON(value?: TypeOfHouse | null): any {
    return value as any;
}

