import React from 'react';
import { AppComponent } from '../../../AppComponent';
import { StepHeader } from '../../StepHeader';
import { Case, Applicant, Loan } from '../../../../models/SelmaModels';
import { Label } from '../../../widget/display/Label/Label';
import {
	FormUnit,
	FormUnitSize,
	FormUnitPadding,
} from '../../../layout/FormUnit/FormUnit';
import { StepFooter } from '../../StepFooter';
import { StepContext } from '../../CaseSteps';
import { ApplicantApproval } from './ApplicantApproval';
import NumberFormat, { NumberFormatValues } from 'react-number-format';
import { LoanNewAims } from './LoanNewAims';
import { LoanDownpayment } from './LoanDownpayment';
import { THtml } from '../../../widget/display/THtml';
import { LoanService } from '../../../../services/LoanService';
import { Validation } from '../../../widget/display/Validation/Validation';
import { Heading } from '../../../widget/display/Heading/Heading';
import StepFormContext, { StepForm } from '../../../widget/display/StepForm/StepForm';
import {
	CheckboxGroup,
	CheckboxOption,
} from '../../../widget/input/CheckboxGroup/CheckboxGroup';
import { LoanTakeOvers } from './LoanTakeOvers';
import { FormUnitRow, Justify } from '../../../layout/FormUnitRow/FormUnitRow';
import "./StepLoan.scss";
import { ButtonGroup, ButtonGroupLayout } from '../../../widget/input/ButtonGroup/ButtonGroup';
import { Modal, OverlayStyles } from '../../../layout/Modal/Modal';
import { Button } from '../../../widget/input/Button/Button';
import { Textarea } from '../../../widget/input/Textarea/Textarea';
import { CaseService } from '../../../../services/CaseService';

interface Props {
	case: Case;
	context: StepContext;
}

interface State {
	mainPurposeTouched: boolean;
	showClearAimsModal: boolean;
	showClearTakeoverLoansModal: boolean;
	immidiateAllValidatorsVisibleBefore?:Date;
}

export class StepLoan extends AppComponent<Props, State> {

	loanService: LoanService;
	myApplicant: Applicant;
	caseService: CaseService;

	constructor(props: Props) {
		super(props);

		this.loanService = this.application.services.loanService;
		this.caseService = this.application.services.caseService;
		const applicantService = this.application.services.applicantService;
		const c = this.props.case;
		this.myApplicant = applicantService.getMyApplicant(c);

		this.state = { 
			mainPurposeTouched:false, 
			showClearAimsModal:false, 
			showClearTakeoverLoansModal:false,
			immidiateAllValidatorsVisibleBefore:undefined
		};
	}

	componentDidMount() {
		this.observeUpdate(this.myApplicant);

		const c = this.props.case;
		const loan = this.loanService.ensureLoan(c); // Move somewhere else?
		this.observeUpdate(c.loan);
	}

	mainLoanPurposeChanged = (values: string[]): void => {
		const loan = this.props.case.loan;
		if (!loan) return;

		const takeoverChecked = values.filter(x=>x=="TAKEOVERLOAN").length>0;
		const newloanChecked = values.filter(x=>x=="NEWLOAN").length>0; // aims

		const loanService = this.loanService;
		const hasTakeoverLoans = loanService.hasTakeoverLoans(loan);
		const hasAims = loanService.hasAims(loan);

		if( takeoverChecked ) {
			if( !hasTakeoverLoans ) {
				// Add default takeoverloan.
				this.loanService.ensureMinimumTakeoverLoans(loan);
			}
		} else {
			if( hasTakeoverLoans ) {
				// Ask about deleting all takeoverloans.
				this.confirmRemoveAllTakeOverLoans(loan);
			}
		}

		if( newloanChecked ) {
			if( !hasAims ) {
				// Add default aims.
				this.loanService.ensureMinimumLoanAims(loan);
			}
		} else {
			if( hasAims ) {
				// Ask about deleting all aims.
				this.confirmRemoveAllAims(loan);
			}
		}
	};

	mainLoanPurposeBlur = (event: React.FocusEvent<HTMLInputElement>): void | undefined => {
		// if you leave (blur) the last checkbox in list then we need to validate.
		if(event.target.value=="NEWLOAN") {
			this.setState({ mainPurposeTouched:true });
		}
	}

	purpouseDescriptionOnChange = (event: React.ChangeEvent<HTMLTextAreaElement>): void | undefined => {
		let loan = this.props.case.loan;
		if(!loan) return;
		loan.purposeDescription = event.target.value;
		this.loanService.update(loan);
	}

	confirmRemoveAllTakeOverLoans(loan:Loan) {
		// TODO: Confirm with modal.
		//this.loanService.removeAllTakeOverLoans(loan);
		this.setState({showClearTakeoverLoansModal:true});
	}

	confirmRemoveAllAims(loan:Loan) {
		// TODO: Confirm with modal.
		//this.loanService.removeAllAims(loan);
		this.setState({showClearAimsModal:true});
	}

	triggerImmidiateValidation():void {
		this.setState({ immidiateAllValidatorsVisibleBefore : new Date() })
	}

	render() {
		const c = this.props.case;
		const loan = c.loan;
		if (!loan) return null;

		const loanService = this.loanService;
		const applicant = this.myApplicant;
		const askAboutDownpayment = loanService.askAboutDownpayment(loan);

		const validationResult = this.props.context.getValidation();
		const loanAmount = loanService.getLoanAmount(loan);

		const hasTakeoverLoans = loanService.hasTakeoverLoans(loan);
		const hasAims = loanService.hasAims(loan);
		const showSum = hasTakeoverLoans || hasAims;
		const mainLoanPurposesOptions: CheckboxOption[] = [
			{
				value: 'TAKEOVERLOAN',
				checked: hasTakeoverLoans,
				labelTextkey: 'Loan_TakeOver',
			},
			{
				value: 'NEWLOAN',
				checked: hasAims,
				labelTextkey: 'Loan_New',
			}
		];

		const mainLoanPurposesValues = mainLoanPurposesOptions.filter(x => x.checked).map(x => x.value||'');
		const immidiateAllValidatorsVisibleBefore = this.state.immidiateAllValidatorsVisibleBefore;

		return (
			<div className="StepLoan Step">
				<StepHeader
					context={this.props.context}
					titleTextkey="Loan_Title"
					descriptionTextkey="Loan_Description"
				/>

				<StepForm context={this.props.context} immidiateAllValidatorsVisibleBefore={immidiateAllValidatorsVisibleBefore}>
					{/* <FormUnit size={FormUnitSize.Large}>
						<Heading level={2} labelTextkey="Loan_PurposeOfLoan" />
					</FormUnit> */}

					<FormUnit size={FormUnitSize.Xlarge}>
						<Validation forObject={loan} immediate={this.state.mainPurposeTouched}
							errors={[
								{
									validator: loanService.hasSubLoans,
									textkey: 'Loan_NoSubLoans',
								}
							]}>
							<CheckboxGroup
								options={mainLoanPurposesOptions}
								handleChange={this.mainLoanPurposeChanged}
								value={mainLoanPurposesValues}
								handleBlur={this.mainLoanPurposeBlur}
							/>
						</Validation>
					</FormUnit>

					{hasTakeoverLoans && 
					<FormUnit size={FormUnitSize.Xlarge}>
						<FormUnit padding={FormUnitPadding.Normal}>
							<Heading level={2} labelTextkey="Loan_TakeOverLoanPurpose" />
						</FormUnit>
						<LoanTakeOvers loan={loan} />
					</FormUnit>
					}

					{hasAims && 
					<FormUnit size={FormUnitSize.Xlarge}>
						<FormUnit padding={FormUnitPadding.Normal}>
							<Heading level={2} labelTextkey="Loan_NewLoanPurpose" />
						</FormUnit>
						<LoanNewAims loan={loan} />
					</FormUnit>
					}

					{showSum &&
					<FormUnit size={FormUnitSize.Normal}>
						<FormUnit padding={FormUnitPadding.Small}>
							<Heading level={3} labelTextkey="Loan_Amount" />
						</FormUnit>
						<NumberFormat
							renderText={(value) => <span className="LoanAmount">{value}</span>}
							thousandSeparator=" "
							suffix=" kr"
							displayType="text"
							value={loanAmount}
						/>
						{loanAmount>0 && 
							<Validation 
								forObject={loan}
								immediate={true}
								errors={[
									{
										validator: loanService.hasValidLoanAmount,
										textkey: 'Loan_Amount_Invalid',
									},
								]} />
						}
					</FormUnit>
					}

					{askAboutDownpayment && (
						<FormUnit>
							<p>
								<THtml k="Loan_Downpayment_Information" />
							</p>
							<Label labelTextkey="Loan_Downpayment" />
							<LoanDownpayment loan={loan} />
						</FormUnit>
					)}

					{(hasTakeoverLoans || hasAims) && (
						<FormUnit size={FormUnitSize.Xlarge}>
							<Label labelTextkey="Loan_PurpouseDescription" />
							<Validation 
								forObject={loan} 
								errors={[{
									validator: this.loanService.hasValidPurposeDescription,
									textkey: 'Loan_PurpouseDescription_Invalid'
								}]}>
								<Textarea 
									value={loan.purposeDescription}
									onChange={this.purpouseDescriptionOnChange} 
									size='medium'
									maxlength={10000}
								/>
							</Validation>
						</FormUnit>
					)}

					{applicant && <ApplicantApproval applicant={applicant} />}

				</StepForm>

				<StepFooter
					context={this.props.context}
					canGoHome={true}
					canGoToNext={validationResult.ok()}
					onGoHome={this.props.context.goHome}
					onGoToNext={this.props.context.goNext}
					onTriggerImmidiateValidation={() => this.triggerImmidiateValidation() }
				/>

				{ this.state.showClearAimsModal &&
					<Modal
						handleClose={x => {this.setState({showClearAimsModal:false})}}
						overlayCloseOnClick={true}
						overlayStyle={OverlayStyles.Darken}>
						<div className="ModalContent">
							<Heading level={1} labelTextkey="Loan_RemoveAllAims_Title"/>
							<p><THtml k="Loan_RemoveAllAims" /></p>
						</div>
						<div className="ModalFooter">
							<ButtonGroup layout={ButtonGroupLayout.Stretch}>
								<Button
									style="normal"
									labelTextkey="Yes"
									onClick={x => this.loanService.removeAllAims(loan)}
								/>
								<Button
									style="normal"
									labelTextkey="No"
									onClick={ x => { this.setState({showClearAimsModal:false})}}
									autofocus={true}
								/>
							</ButtonGroup>
						</div>
					</Modal>
				}
				{ this.state.showClearTakeoverLoansModal &&
					<Modal
						handleClose={x => {this.setState({showClearTakeoverLoansModal:false})}}
						overlayCloseOnClick={true}
						overlayStyle={OverlayStyles.Darken}>
						<div className="ModalContent">
							<Heading level={1} labelTextkey="Loan_RemoveAllTakeoverLoans_Title"/>
							<p><THtml k="Loan_RemoveAllTakeoverLoans" /></p>
						</div>
						<div className="ModalFooter">
							<ButtonGroup layout={ButtonGroupLayout.Stretch}>
								<Button
									style="normal"
									labelTextkey="Yes"
									onClick={x => this.loanService.removeAllTakeOverLoans(loan)}
								/>
								<Button
									style="normal"
									labelTextkey="No"
									onClick={ x => { this.setState({showClearTakeoverLoansModal:false})}}
									autofocus={true}
								/>
							</ButtonGroup>
						</div>
					</Modal>
				}

			</div>
		);
	}
}
