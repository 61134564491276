/* tslint:disable */
/* eslint-disable */
/**
 * SelmaSME API
 * This definition is the API between customer SME-Gui and the persistence layer for the application NOT FOR V1 When customer logg-in to Selma-EN, we will create a processId with applicant CustomerId (from BankID). When a customer added attachment to the application, we don\'t have a CaseId i LP, should we add a LP-Service to create/reserve a caseId to be able to attach a document to the caseId in DM.
 *
 * The version of the OpenAPI document: 0.7.4
 * Contact: developer@landshypotek.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * ChildType, belong to household
 * @export
 * @interface ChildType
 */
export interface ChildType {
    /**
     * identity for child
     * @type {string}
     * @memberof ChildType
     */
    childId: string;
    /**
     * Barns ålder i hushållet
     * @type {number}
     * @memberof ChildType
     */
    childsAge?: number;
    /**
     * barnet bor i hushållet heltid/deltid (true/false)
     * @type {boolean}
     * @memberof ChildType
     */
    partInHousehold?: boolean;
}

export function ChildTypeFromJSON(json: any): ChildType {
    return ChildTypeFromJSONTyped(json, false);
}

export function ChildTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChildType {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'childId': json['childId'],
        'childsAge': !exists(json, 'childsAge') ? undefined : json['childsAge'],
        'partInHousehold': !exists(json, 'partInHousehold') ? undefined : json['partInHousehold'],
    };
}

export function ChildTypeToJSON(value?: ChildType | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'childId': value.childId,
        'childsAge': value.childsAge,
        'partInHousehold': value.partInHousehold,
    };
}


