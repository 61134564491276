import React from 'react';
import ClassNames from 'classnames';
import { T } from '../../display/T';
import { uniqueId } from 'lodash';
import { BaseComponent } from '../../../BaseComponent';
import './Radiobutton.scss';


interface Props {
	checked: boolean;
	className?: string;
	dirty?: boolean;
	enabled?: boolean;
	group: string;
	handleBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
	handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
	hasErrors?: boolean;
	label?: string;
	labelTextkey?: string;
	touched?: boolean;
	type?: string;
	value: string | RadioValue;
}


export interface RadioValue {
	value: string,
}


export class Radiobutton extends BaseComponent<Props> {
	id: string;

	constructor(props: Props) {
		super(props);

		this.id = uniqueId('radiobutton_');
	}

	static defaultProps = {
		label: '',
		className: 'Radiobutton',
		type: 'radio',
		enabled: true,
		touched: false,
		hasErrors: false,
		dirty: false
	};
	

	render() {
		const classNames = ClassNames(this.props.className, {
			error: this.props.touched && this.props.hasErrors,
		});

		return (
			<div className={classNames}>
				<input
					checked={this.props.checked}
					disabled={this.props.enabled === false}
					id={this.id}
					name={this.props.group}
					onBlur={this.props.handleBlur}
					onChange={this.props.handleChange}
					type={this.props.type}
					value={(typeof this.props.value === 'string') ? this.props.value : JSON.stringify(this.props.value)}
				/>
				<label htmlFor={this.id}>
					{this.props.labelTextkey ? (
						<T k={this.props.labelTextkey} />
					) : (
						this.props.label
					)}
				</label>
			</div>
		);
	}
}
