import { object } from "prop-types";

export type ObsMatch = (obj:any) => boolean;
export type ObsCallback = (event: IObsEvent) => void;
export type UnobserveHandle = () => void;

// A registered observation.
export interface IObs {
	match: ObsMatch;
	callback: ObsCallback;
	//obj: any;
}

// Event emitted on observation of change.
export interface IObsEvent {
	observer: IObs;
	obj: any;
	change: ObsChange;
}

// Type of change that can occur.
export enum ObsChange {
	add,
	update,
	remove,
}

function objectContains (object:object, find:any):boolean {
	for (var property in object) {
		const val = object[property];
		if( val===find )
			return true;
		
		if( val instanceof Object ) {
			const r = objectContains(val, find);
			if( r )
				return true;
		}
		else if( val instanceof Array ) {
			for( var i=0; i<val.length; i++ ) {
				const arrVal = val[i];
				// eslint-disable-next-line @typescript-eslint/no-use-before-define
				const r = objectSelfOrContains(arrVal,find);
				if(r)
					return true;
			}
		} 
	}
	return false;
}

function objectSelfOrContains (object:object, find:any):boolean {
	if( object===find )
		return true;
	else
		return objectContains(object, find);
}


export class Objserver {
	observers: Array<IObs>;

	constructor() {
		this.observers = [];
	}

	update(obj: any): void {
		this.emitChange(obj, ObsChange.update);
	}

	add(obj: any): void {
		this.emitChange(obj, ObsChange.add);
	}

	remove(obj: any): void {
		this.emitChange(obj, ObsChange.remove);
	}

	replace(oldObj: any, newObj: any): void {
		this.emitChange(oldObj, ObsChange.remove);
		this.emitChange(newObj, ObsChange.add);
	}

	private emitChange(obj: any, change: ObsChange): void {

		const observers = this.observers;
		for( let i=observers.length-1; i>=0; i--) {
			const observer = observers[i];
			if (!observer.match(obj))
				continue;

			let callback = observer.callback;
			let event = <IObsEvent>{
				change: change,
				observer: observer,
				obj: obj,
			}
			try {
				callback(event);
			}
			catch (ex) {
				//console.log("ObjServer emit change error. "+observer.match.name, ex);
			}
		}
	}

	observe(obj:any, callback:ObsCallback): UnobserveHandle {
		return this.observeInternal({
			match: (x:any) => x===obj,
			callback:callback
		});
	}

	observeDeep(obj:any, callback:ObsCallback): UnobserveHandle {
		return this.observeInternal({
			match: (x) => objectSelfOrContains(obj,x),
			callback:callback
		});
	}

	observeInternal(observer: IObs): UnobserveHandle {
		if (!observer.match)
			throw new Error("No matcher in observer.");
		if (!observer.callback)
			throw new Error("No callback in observer.");

		this.observers.push(observer);

		let u = this.unobserve.bind(this);
		return function () {
			u(observer);
		};
	}

	unobserve(observer: IObs): void {
		this.observers = this.observers.filter(x => x !== observer);
	}

}
