import { UIKeyed } from "./UIKeyed";
import { UINamed } from "./UINamed";

export interface UIAttachmentType extends UIKeyed, UINamed {
}

export const uiAttachmentTypes:UIAttachmentType[] = [
	{ key: 'FORESTRYPLAN', name: "Skogsbruksplan", nameTextkey: "UIAttachmentType_FORESTRYPLAN" }, // SKOGSBRUKSPLAN
	{ key: 'PROOFOFEMPLOYMENT', name: "Anställningsbevis", nameTextkey: "UIAttachmentType_PROOFOFEMPLOYMENT" }, // ANSTÄLLNINGSBEVIS
	{ key: 'EUSUPPORT', name: "EU-stöd", nameTextkey: "UIAttachmentType_EUSUPPORT" }, // EU-STÖD
	{ key: 'COMPANYBUDGET', name: "Företagsbudget", nameTextkey: "UIAttachmentType_COMPANYBUDGET" }, // Företagsbudget
	{ key: 'COMPANYTAXRETURN', name: "Inkomstdeklaration med samtliga bilagor", nameTextkey: "UIAttachmentType_COMPANYTAXRETURN" }, // Inkomstdeklaration med samtliga bilagor
	{ key: 'COMPANYFINREPORT', name: "Bokslut med resultat- och balansräkning", nameTextkey: "UIAttachmentType_COMPANYFINREPORT" }, // Bokslut med resultat- och balansräkning 
	{ key: 'ANNUALACCOUNT', name:"Inkomst med samtliga bilagor eller bokslut med resultat och balansräkning", nameTextkey: "UIAttachmentType_ANNUALACCOUNT"}, // En sammanslagning av COMPANYTAXRETURN och COMPANYFINREPORT
	{ key: 'BUILDINGESTIMATE', name: "Byggnadskalkyl", nameTextkey: "UIAttachmentType_BUILDINGESTIMATE" }, // Kalkyl för nybyggnad, utbyggnad, renovering. Se Aims.
	{ key: 'OTHER', name: "Övrigt", nameTextkey: "UIAttachmentType_OTHER" } 
];
