import { BaseService } from './BaseService';
import { StateService } from './StateService';

export interface Oidc {
	authority: string,
	clientId: string,
	redirectUri: string,
	automaticSilentRenew: string,
	scope: string,
	postLogoutRedirectUri: string,
	fetchRequestCredentials: string
}

export interface Configuration {
	maintenanceMode: boolean;
	useLocalLogin: boolean;
	smeBaseUrl: string;
	bidBaseUrl: string;
	oidcBaseUrl: string,
	lpBaseUrl: string;
	ebbotChatEnabled: boolean;
	ebbotChatBotId: string;
	akkaUrl: string,
	oidc: Oidc
}


export class ConfigurationService extends BaseService {

	private configuration?: Configuration; // current config.
	private stateService: StateService;
	private protectAgainstPageLeaveEnabled: boolean = true;

	constructor(stateService: StateService) {
		super();
		this.stateService = stateService;
	}

	load(url: string): Promise<Configuration> {
		let promise = fetch(`${url}?cache=${Date.now()}`)
			.then((result) => {
				return result.json();
			}).then((result: Configuration) => {
				let prevConfig = this.configuration;
				this.configuration = result;
				this.stateService.state.configuration = this.configuration;
				this.stateService.replace(prevConfig, this.configuration);
				return this.configuration;
			});
		return promise;
	}

	getConfiguration(): Configuration {
		if (!this.configuration)
			throw 'No config';
		return this.configuration;
	}

	maintenanceMode(): boolean {
		return this.configuration && this.configuration.maintenanceMode === true ? true : false;
	}

	ebbotChatEnabled(): boolean {
		return this.configuration && this.configuration.ebbotChatEnabled === true ? true : false;
	}

	ebbotChatBotId(): string {
		return this.configuration?.ebbotChatBotId ?? '';
	}

	oidcSettings(): Oidc {
		return this.configuration?.oidc ?? {} as Oidc;
	}

	protectAgainstPageLeave(): boolean {
		return this.protectAgainstPageLeaveEnabled;
	}

	setProtectAgainstPageLeave(enabled: boolean): void {
		this.protectAgainstPageLeaveEnabled = enabled;
	}

	useLocalLogin(): boolean {
		if(this.configuration)
			return process.env.NODE_ENV === 'development' && this.configuration.useLocalLogin
		return false;
	}
}
