import React, { Component } from 'react';
import { Case, MaintenanceCost, MaintenanceHouse, Applicant, LoanOwner } from '../../../../models/SelmaModels';
import { AppComponent } from '../../../AppComponent';
import { SelectOption, Select } from '../../../widget/input/Select/Select';
import { FormUnit, FormUnitSize, FormUnitPadding } from '../../../layout/FormUnit/FormUnit';
import { Label } from '../../../widget/display/Label/Label';
import { Money } from '../../../widget/input/Money/Money';
import { NumberFormatValues } from 'react-number-format';
import { RadiobuttonGroup, RadioOption } from '../../../widget/input/RadiobuttonGroup/RadiobuttonGroup';
import { uiEstateTypes } from '../../../../models/UiModels';
import { ApplicantSelect } from './ApplicantSelect';
import { Percent } from '../../../widget/input/Percent/Percent';
import { TypeOfHouse } from '../../../../libs/SMELPOClient';
import { Validation } from '../../../widget/display/Validation/Validation';
import { getUIName } from '../../../../models/uimodels/UINamed';

interface Props {
	case: Case;
	maintenanceCost: MaintenanceCost;
	maintenanceHouse: MaintenanceHouse;
}


const keepHouseOptions:RadioOption[]  = [
	{
		group: 'keepHouse',
		label: 'Ja',
		value: 'yes',
	},
	{
		group: 'keepHouse',
		label: 'Nej',
		value: 'no',
	},
]; 


const redeemOptions:RadioOption[] = [
	{
		group: 'redeem',
		label: 'Ja',
		value: 'yes',
	},
	{
		group: 'redeem',
		label: 'Nej',
		value: 'no',
	},
]; 

const loanInOtherInstituteOptions:RadioOption[] = [
	{
		group: 'loanInOtherInstitute',
		label: 'Ja',
		value: 'yes',
	},
	{
		group: 'loanInOtherInstitute',
		label: 'Nej',
		value: 'no',
	},
]; 



export class HouseholdMaintenanceHouse extends AppComponent<Props> {
	householdService = this.application.services.householdService;
	maintenanceCostService = this.application.services.maintenanceCostService;
	estateTypeOptions:SelectOption[];
	redeemOptions:RadioOption[];
	keepHouseOptions:RadioOption[];
	loanInOtherInstituteOptions:RadioOption[];
	uqid: string;

	constructor(props: Props) {
		super(props);

		this.observeUpdate(this.props.maintenanceCost);
		this.observeUpdate(this.props.maintenanceHouse);

		let uq = this.uqid = this.application.services.idService.newIdString();

		const textService = this.application.services.textService;
		this.estateTypeOptions = uiEstateTypes.map(x => {
			return {
				value: x.key,
				label: getUIName(x, textService)
			}
		});

		this.redeemOptions = redeemOptions.map(x => {
			return {
				group: uq+x.group,
				label:x.label,
				value:x.value
			}
		});

		this.keepHouseOptions = keepHouseOptions.map(x => {
			return {
				group: uq+x.group,
				label:x.label,
				value:x.value
			}
		});

		this.loanInOtherInstituteOptions = loanInOtherInstituteOptions.map(x => {
			return {
				group: uq+x.group,
				label:x.label,
				value:x.value
			}
		});
	}

	estateTypeChanged(e: string, maintenanceHouse: MaintenanceHouse) {
		let uiEstateType = uiEstateTypes.find(x=>x.key===e);
		if( uiEstateType ) {
			maintenanceHouse.houseType = uiEstateType.type;
			this.maintenanceCostService.update(maintenanceHouse);
		}
	}
	
	amountChanged = (e: NumberFormatValues, maintenanceHouse: MaintenanceHouse): void => {
		maintenanceHouse.houseLoanAmount = e.floatValue;
		this.maintenanceCostService.update(maintenanceHouse); 
	}

	maintenanceCostChanged = (e: NumberFormatValues, maintenanceHouse: MaintenanceHouse): void => {
		maintenanceHouse.maintenanceCost = e.floatValue;
		this.maintenanceCostService.update(maintenanceHouse);
	}

	ownedPartChanged = (e: NumberFormatValues, maintenanceHouse: MaintenanceHouse, loanOwner:LoanOwner): void => {
		loanOwner.ownershipPart = e.floatValue;
		this.maintenanceCostService.update(maintenanceHouse);
		this.maintenanceCostService.update(loanOwner);
	}

	keepHouseChanged = (value: string) => {
		let maintenanceHouse = this.props.maintenanceHouse;
		const isChecked = value == 'yes';
		maintenanceHouse.keepHouse = isChecked;
		this.maintenanceCostService.update(maintenanceHouse);
	}

	redeemChanged = (value: string) => {
		let maintenanceHouse = this.props.maintenanceHouse;
		const isChecked = value == 'yes';
		maintenanceHouse.redeemLoan = isChecked;
		this.maintenanceCostService.update(maintenanceHouse);
	}

	loanInOtherInstituteChanged = (value: string) => {
		let maintenanceHouse = this.props.maintenanceHouse;
		const isChecked = value == 'yes';
		maintenanceHouse.loanInOtherInstitute = isChecked;
		this.maintenanceCostService.update(maintenanceHouse);
	}

	ownersSelectionChanged = (values: string[]) : void => {
		const maintenanceCostService = this.application.services.maintenanceCostService;
		const h = this.props.maintenanceHouse;
		const currentLoanOwnerCount = h.loanOwners?h.loanOwners.length:0;
		if( values.length>0 ) {
			if( !h.loanOwners )
				h.loanOwners = [];
			
			// Add missing loan owners.
			values.forEach(customerId => {
				const loanOwner = maintenanceCostService.ensureLoanOwner(h, customerId);
			})

			// Remove superflous  loan owners.
			for(let i=h.loanOwners.length-1; i>=0; i-- ) {
				let loanOwner = h.loanOwners[i];
				let value = values.find(x => this.application.services.guardService.compareCustomerId(x, loanOwner.customerId));
				if( !value )
					maintenanceCostService.removeLoanOwner(h, loanOwner);
			}
			
		} else {
			h.loanOwners = undefined;
		}

		this.maintenanceCostService.update(h);
	}

	customerName(customerId: string):string {
		const c = this.props.case;
		const applicantService = this.application.services.applicantService;
		const applicant = applicantService.getApplicantByCustomerId(c, customerId);
		if( !applicant )
			return customerId;
		const name = applicantService.getFullName(c, applicant);
		return name;
	}


	yesNoValue(val:boolean|undefined):string|undefined {
		if( val===true )
			return 'yes';
		else if( val===false )
			return 'no';
		else 
			return undefined;
	}

	render() {
		const c = this.props.case;
		const maintenanceHouse = this.props.maintenanceHouse;

		const maintenanceCostService = this.application.services.maintenanceCostService;

		let estateTypeValue = '';
		let uiEstateType = uiEstateTypes.find(x=> x.type===maintenanceHouse.houseType)
		if( uiEstateType )
			estateTypeValue = uiEstateType.key;
		const isTenancy = maintenanceHouse.houseType==TypeOfHouse.Hyresrtt; //estateTypeValue=='TENANCY';
		const isApartment = maintenanceHouse.houseType==TypeOfHouse.Bostadsrtt; //estateTypeValue=='APARTMENT';

		let keepHouseValue = this.yesNoValue(maintenanceHouse.keepHouse);
		let redeemValue = this.yesNoValue(maintenanceHouse.redeemLoan);
		let loanInOtherInstituteValue = this.yesNoValue(maintenanceHouse.loanInOtherInstitute);

		const keepHouse = maintenanceHouse.keepHouse===true;
		const loanInOtherInstitute = maintenanceHouse.loanInOtherInstitute===true;

		const household = this.householdService.getFirstHousehold(c);
		let ownersApplicants:Applicant[] = this.householdService.getHouseholdMembersApplicants(c, household)||[];
		let ownersCustomerIds:string[] = 
			maintenanceHouse.loanOwners
			?maintenanceHouse.loanOwners.map(x => x.customerId)
			:[];

		return (
			<div className="HouseholdMaintenanceHouse">
				<FormUnit>
					<Label
						for={'estateType_' + this.uqid}
						labelTextkey="Household_MHouse_EstateType"
						required={true}
					/>
					<Validation
						forObject={maintenanceHouse}
						errors={[
							{
								validator: maintenanceCostService.hasSetHouseType,
								textkey: 'Household_MHouse_SelectEstateType',
							},
						]}>
						<Select
							id={'estateType_' + this.uqid}
							value={estateTypeValue}
							options={this.estateTypeOptions}
							handleChange={(e) => {
								this.estateTypeChanged(e, maintenanceHouse);
							}}
							placeholderTextkey="Household_MHouse_SelectEstateType"
							noSelectableOptionsTextkey="Select_NoOptions"
						/>
					</Validation>
				</FormUnit>
				<FormUnit>
					<Label
						for={'keepHouse_' + this.uqid}
						labelTextkey="Household_MCost_KeepHouse"
						required={true}
					/>
					<Validation
						forObject={maintenanceHouse}
						errors={[
							{
								validator: maintenanceCostService.hasSetKeepHouse,
								textkey: 'Household_MCost_SelectKeepHouse',
							},
						]}>
						<RadiobuttonGroup
							handleChange={this.keepHouseChanged}
							options={this.keepHouseOptions}
							value={keepHouseValue}
						/>
					</Validation>
				</FormUnit>
				{keepHouse && uiEstateType && (
					<>
					{ (isTenancy || isApartment) &&
					<FormUnit padding={FormUnitPadding.Small}>
						{ isTenancy &&
						<Label for={'maintenanceCost_' + this.uqid} labelTextkey="Household_MHouse_TenancyCost" required={true} />
						}
						{ isApartment &&
						<Label for={'maintenanceCost_' + this.uqid} labelTextkey="Household_MHouse_ApartmentCost" required={true}/>
						}
						<FormUnit size={FormUnitSize.Small} padding={FormUnitPadding.None}>
							<Validation
								forObject={maintenanceHouse}
								errors={[
									{
										validator: maintenanceCostService.hasMaintenanceCost,
										textkey: 'Error_EnterAmount',
									},
								]}>
									<Money id={'maintenanceCost_' + this.uqid} suffix=" kr/månad" value={maintenanceHouse.maintenanceCost} min={0}
										handleChange={(e) => this.maintenanceCostChanged(e, maintenanceHouse)}/>
							</Validation>
						</FormUnit>
					</FormUnit>
					}

					{ !isTenancy &&
						<>
							<FormUnit>
								<Label for={'loanInOtherInstitute_' + this.uqid} labelTextkey="Household_MCost_LoanInOtherInstitute" required={true} />
								<Validation
									forObject={maintenanceHouse}
									errors={[
										{
											validator: maintenanceCostService.hasSetLoanInOtherInstitute,
											textkey: 'Household_MCost_SelectLoanInOtherInstitute',
										},
									]}>
									<RadiobuttonGroup
										handleChange={this.loanInOtherInstituteChanged}
										options={this.loanInOtherInstituteOptions}
										value={loanInOtherInstituteValue}
									/>
								</Validation>
							</FormUnit>
						</>
					}
					</>
				)}
				{!isTenancy && keepHouse && loanInOtherInstitute && (
					<>
					<FormUnit>
						<Label for={'redeem_' + this.uqid} labelTextkey="Household_MCost_Redeem" required={true} />
						<Validation
							forObject={maintenanceHouse}
							errors={[
								{
									validator: maintenanceCostService.hasSetRedeemHouseLoan,
									textkey: 'Household_MCost_SelectRedeemHouseLoan'
								},
							]}>
							<RadiobuttonGroup
								handleChange={this.redeemChanged}
								options={this.redeemOptions}
								value={redeemValue}
							/>
						</Validation>
					</FormUnit>
					<FormUnit size={FormUnitSize.Small}>
						<Label for={'amount_' + this.uqid} labelTextkey="Household_MHouse_LoanAmount" required={true}/>
						<Validation
							forObject={maintenanceHouse}
							errors={[
								{
									validator: maintenanceCostService.hasHouseLoanAmount,
									textkey: 'Household_MCost_EnterHouseLoanAmount'
								},
							]}>
							<Money id={'amount_' + this.uqid} value={maintenanceHouse.houseLoanAmount} min={0}
								handleChange={(e) => this.amountChanged(e, maintenanceHouse)}/>
						</Validation>
					</FormUnit>
					<FormUnit>
						<Label for={'owners_' + this.uqid} labelTextkey="Household_MHouse_LoanOwner" required={true} />
						<Validation
							forObject={maintenanceHouse}
							errors={[
								{
									validator: maintenanceCostService.hasHouseLoanOwners,
									textkey: 'Household_MCost_SelectHouseLoanOwners'
								},
							]}>
							<ApplicantSelect
								applicants={ownersApplicants}
								selectedCustomerIds={ownersCustomerIds}
								selectionChanged={this.ownersSelectionChanged}
								autoSelectIfSingleApplicant={true}
							/>
						</Validation>
					</FormUnit>
					{maintenanceHouse.loanOwners && maintenanceHouse.loanOwners.map(x => {
						return (
							<FormUnit key={'ownedPartFormUnit_' + this.uqid + "_" + x.customerId}>
								<Label for={'ownedPart_' + this.uqid + "_" + x.customerId} required={true}
									labelTextkey="Household_MHouse_OwnedPart" labelText={" "+this.customerName(x.customerId)}/>
								<Validation
									forObject={x}
									errors={[
										{
											validator: maintenanceCostService.hasHouseLoanOwnersOwnershipPart,
											textkey: 'Household_MHouse_EnterOwnedPart'
										},
										{
											validator: maintenanceCostService.hasHouseLoanOwnersOwnershipPartBelow100InSum,
											textkey: 'Household_MHouse_OwnedPartTooHigh'
										}
									]}>
									<Percent id={'ownedPart_' + this.uqid + "_" + x.customerId} value={x.ownershipPart}
										handleChange={(e) => this.ownedPartChanged(e, maintenanceHouse, x)}/>
								</Validation>
							</FormUnit>
						);
					})}
					</>
				)}
			</div>
		);
	}


}
