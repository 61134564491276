import React from 'react';
import { Loan } from '../../../../../models/SelmaModels';
import { AppComponent } from '../../../../AppComponent';
import {
	FormUnit,
	FormUnitSize,
	FormUnitPadding,
} from '../../../../layout/FormUnit/FormUnit';

import KeyValueList from '../../../../widget/display/KeyValueList/KeyValueList';
import NumberFormat from 'react-number-format';
import { T } from '../../../../widget/display/T';

interface Props {
	loan: Loan;
}

export class LoanAmountSummary extends AppComponent<Props> {
	constructor(props: Props) {
		super(props);
	}

	render() {
		const loanService = this.application.services.loanService;
		const loan = this.props.loan;
		const loanAmount = loanService.getLoanAmount(loan);

		return (
			<FormUnit size={FormUnitSize.Large} padding={FormUnitPadding.Small}>
				<KeyValueList>
					{[
						{
							key: <T k="Summary_Loan_Amount" />,
							value: (
								<NumberFormat
									thousandSeparator=" "
									suffix=" kr"
									displayType="text"
									value={loanAmount}
								/>
							),
						}
					]}
				</KeyValueList>
			</FormUnit>
		);
	}
}
