import { BaseService } from './BaseService';

export class ArrayService extends BaseService {

	constructor() {
		super();
	}

	first<T>(list:T[], match: (item:T) => boolean): T|undefined {
		for(let i = 0, len=list.length; i<len; i++ ) {
			const item = list[i];
			if( match(item) )
				return item;
		}
		return undefined;
	}

	any<T>(list:T[], match: (item:T) => boolean): boolean {
		for(let i = 0, len=list.length; i<len; i++ ) {
			const item = list[i];
			if( match(item) )
				return true;
		}
		return false;
	}

	static groupBy<K, V>(list: Array<V>, keyGetter: (input: V) => K) : Map<K, Array<V>> {

		const map = new Map<K, Array<V>>();

		list.forEach((item) => {
			const key = keyGetter(item);
			const collection = map.get(key);
			if (!collection) {
				map.set(key, [item]);
			} else {
				collection.push(item);
			}
		});

		return map;
	}
}
