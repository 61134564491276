import React from 'react';
import { Case } from '../../../../../models/SelmaModels';
import { AppComponent } from '../../../../AppComponent';
import {
	FormUnit,
	FormUnitSize,
	FormUnitPadding,
} from '../../../../layout/FormUnit/FormUnit';
import { T } from '../../../../widget/display/T';
import Table from '../../../../widget/display/Table/Table';
import { UiModelConverter } from '../../../../../models/UiModelConverter';
import { Heading } from '../../../../widget/display/Heading/Heading';

interface Props {
	case: Case;
}

export class SelectedCompanySummary extends AppComponent<Props> {
	constructor(props: Props) {
		super(props);
	}
	
	render() {
		const c = this.props.case;

		const companyService = this.application.services.companyService;
		let companies = companyService.getMySelectedCompanies(c);
		if (!companies || companies.length===0) return null;

		const tableRowsData = companies
			//.filter((c) => c.selectedCompany)
			.map(({ orgNumber, name, created }) => [orgNumber, name, UiModelConverter.prettyDateString(created)]);

		return (
			c.companies && (
				<FormUnit size={FormUnitSize.Large} padding={FormUnitPadding.Small}>
					<Heading level={3} labelTextkey="Summary_Company"/>
					<Table
						className="CompanySummary__Table"
						headingRow={[
							<T key="Company_Orgnr" k="Company_Orgnr" />,
							<T key="Company_CompanyName" k="Company_CompanyName" />,
							<T key="Company_Created" k="Company_Created" />,
						]}
						tableRows={tableRowsData}
					/>
				</FormUnit>
			)
		);
	}
}
