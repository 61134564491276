/* tslint:disable */
/* eslint-disable */
/**
 * SelmaSME API
 * This definition is the API between customer SME-Gui and the persistence layer for the application NOT FOR V1 When customer logg-in to Selma-EN, we will create a processId with applicant CustomerId (from BankID). When a customer added attachment to the application, we don\'t have a CaseId i LP, should we add a LP-Service to create/reserve a caseId to be able to attach a document to the caseId in DM.
 *
 * The version of the OpenAPI document: 0.7.4
 * Contact: developer@landshypotek.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TypeOfEmployeement,
    TypeOfEmployeementFromJSON,
    TypeOfEmployeementFromJSONTyped,
    TypeOfEmployeementToJSON,
} from './';

/**
 * personal economy for KALP us
 * @export
 * @interface PersonalEconomyType
 */
export interface PersonalEconomyType {
    /**
     * application model unique identification
     * @type {string}
     * @memberof PersonalEconomyType
     */
    processId: string;
    /**
     * customerId is swedish ssn or corporate organisation number
     * @type {string}
     * @memberof PersonalEconomyType
     */
    customerId: string;
    /**
     * Type definitions for personal economy
     * @type {string}
     * @memberof PersonalEconomyType
     */
    personalEconomyId: string;
    /**
     * Årlig inkomst från näringsverksamhet
     * @type {number}
     * @memberof PersonalEconomyType
     */
    yearlyIncome?: number;
    /**
     * Angiven inkomst av tjänst
     * @type {number}
     * @memberof PersonalEconomyType
     */
    income?: number;
    /**
     * 
     * @type {TypeOfEmployeement}
     * @memberof PersonalEconomyType
     */
    typeOfEmployeement?: TypeOfEmployeement;
    /**
     * -> Arbetsgivare
     * @type {string}
     * @memberof PersonalEconomyType
     */
    employeer?: string;
    /**
     * Anställd sedan år
     * @type {number}
     * @memberof PersonalEconomyType
     */
    yearOfEmployment?: number;
    /**
     * anställd i månad, om anställningen < 2 år.
     * @type {number}
     * @memberof PersonalEconomyType
     */
    monthOfEmployment?: number;
}

export function PersonalEconomyTypeFromJSON(json: any): PersonalEconomyType {
    return PersonalEconomyTypeFromJSONTyped(json, false);
}

export function PersonalEconomyTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): PersonalEconomyType {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'processId': json['processId'],
        'customerId': json['customerId'],
        'personalEconomyId': json['personalEconomyId'],
        'yearlyIncome': !exists(json, 'YearlyIncome') ? undefined : json['YearlyIncome'],
        'income': !exists(json, 'Income') ? undefined : json['Income'],
        'typeOfEmployeement': !exists(json, 'TypeOfEmployeement') ? undefined : TypeOfEmployeementFromJSON(json['TypeOfEmployeement']),
        'employeer': !exists(json, 'Employeer') ? undefined : json['Employeer'],
        'yearOfEmployment': !exists(json, 'yearOfEmployment') ? undefined : json['yearOfEmployment'],
        'monthOfEmployment': !exists(json, 'monthOfEmployment') ? undefined : json['monthOfEmployment'],
    };
}

export function PersonalEconomyTypeToJSON(value?: PersonalEconomyType | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'processId': value.processId,
        'customerId': value.customerId,
        'personalEconomyId': value.personalEconomyId,
        'YearlyIncome': value.yearlyIncome,
        'Income': value.income,
        'TypeOfEmployeement': TypeOfEmployeementToJSON(value.typeOfEmployeement),
        'Employeer': value.employeer,
        'yearOfEmployment': value.yearOfEmployment,
        'monthOfEmployment': value.monthOfEmployment,
    };
}


