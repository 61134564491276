/* tslint:disable */
/* eslint-disable */
/**
 * SelmaSME API
 * This definition is the API between customer SME-Gui and the persistence layer for the application NOT FOR V1 When customer logg-in to Selma-EN, we will create a processId with applicant CustomerId (from BankID). When a customer added attachment to the application, we don\'t have a CaseId i LP, should we add a LP-Service to create/reserve a caseId to be able to attach a document to the caseId in DM.
 *
 * The version of the OpenAPI document: 0.7.4
 * Contact: developer@landshypotek.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Type of Guarantor instance
 * @export
 * @interface GuarantorType
 */
export interface GuarantorType {
    /**
     * application model unique identification
     * @type {string}
     * @memberof GuarantorType
     */
    processId: string;
    /**
     * TBD
     * @type {string}
     * @memberof GuarantorType
     */
    guarantorId: string;
    /**
     * TBD
     * @type {string}
     * @memberof GuarantorType
     */
    guarantorName?: string;
    /**
     * TBD
     * @type {string}
     * @memberof GuarantorType
     */
    guarantorPhone?: string;
    /**
     * TBD
     * @type {string}
     * @memberof GuarantorType
     */
    guarantorCustomerId?: string;
}

export function GuarantorTypeFromJSON(json: any): GuarantorType {
    return GuarantorTypeFromJSONTyped(json, false);
}

export function GuarantorTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): GuarantorType {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'processId': json['processId'],
        'guarantorId': json['guarantorId'],
        'guarantorName': !exists(json, 'guarantorName') ? undefined : json['guarantorName'],
        'guarantorPhone': !exists(json, 'guarantorPhone') ? undefined : json['guarantorPhone'],
        'guarantorCustomerId': !exists(json, 'guarantorCustomerId') ? undefined : json['guarantorCustomerId'],
    };
}

export function GuarantorTypeToJSON(value?: GuarantorType | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'processId': value.processId,
        'guarantorId': value.guarantorId,
        'guarantorName': value.guarantorName,
        'guarantorPhone': value.guarantorPhone,
        'guarantorCustomerId': value.guarantorCustomerId,
    };
}


