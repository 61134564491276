import React, { Children } from 'react';
import { Case, MaintenanceCost } from '../../../../models/SelmaModels';
import { AppComponent } from '../../../AppComponent';
import { HouseholdMaintenanceCost } from './HouseholdMaintenanceCost';
import { Button } from '../../../widget/input/Button/Button';
import { Panel } from '../../../layout/Panel/Panel';
import {
	HeaderButton,
	HeaderButtonLayout,
} from '../../../layout/HeaderButton/HeaderButton';
import { DeleteButton } from '../../../widget/input/DeleteButton/DeleteButton';
import { Heading } from '../../../widget/display/Heading/Heading';
import {
	FormUnit,
	FormUnitSize,
	FormUnitPadding,
} from '../../../layout/FormUnit/FormUnit';
import { TabPanels } from '../../../widget/display/TabPanels/TabPanels';
import { ValidationResult } from '../../../../utils/ValidationResult';

interface Props {
	case: Case;
}

export class HouseholdMaintenanceCosts extends AppComponent<Props> {
	constructor(props: Props) {
		super(props);
		this.observeUpdate(this.props.case);
	}

	addMaintenanceCostClicked = (
		event: React.MouseEvent<HTMLButtonElement, MouseEvent>
	): void => {
		const c = this.props.case;
		let myApplicant = this.application.services.applicantService.getMyApplicant(
			c
		);
		const maintenanceCostService = this.application.services
			.maintenanceCostService;
		let maintenanceCost = maintenanceCostService.newMaintenanceCost(
			c,
			myApplicant
		);
		maintenanceCostService.addMaintenanceCost(c, maintenanceCost);
	};

	removeMaintenanceCostClicked = (
		event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
		maintenanceCost: MaintenanceCost
	): void => {
		const maintenanceCostService = this.application.services
			.maintenanceCostService;
		maintenanceCostService.removeMaintenanceCost(
			this.props.case,
			maintenanceCost
		);
	};

	canAddMaintenanceCost = () => {

		const c = this.props.case;
		
		// Allow add if no maintenance costs exists (=none is invalid)
		if (!c.maintenanceCosts || c.maintenanceCosts.length === 0)
			return true;

		const svc = this.application.services.maintenanceCostService;

		let vr = new ValidationResult();
		c.maintenanceCosts.forEach(m => {
			vr.addResult(svc.validateMaintenanceCost(c,m));
		});

		return vr.ok();
	};

	render() {
		const c = this.props.case;
		const textService = this.application.services.textService;
		let canAdd = this.canAddMaintenanceCost();

		const tabs =
			c.maintenanceCosts &&
			c.maintenanceCosts.map((x, ix) => {
				return {
					id: x.id,
					label: textService.textOrEmpty('Household_MaintenanceCost') + " " + (ix + 1),
				};
			});

		return (
			<div className="MaintenanceCosts">
				<FormUnit size={FormUnitSize.Large}>
					<Heading
						level={2}
						labelTextkey="Household_MCost_Title"
					/>
					{c.maintenanceCosts && (
						<TabPanels tabs={tabs}>
							{c.maintenanceCosts.map((x, ix) => {
								return (
									<Panel key={ix}>
										<HeaderButton layout={HeaderButtonLayout.Stretch}>
											<Heading
												level={3}
												labelTextkey="Household_MaintenanceCost"
												labelText={' ' + (ix + 1)}
											/>
											<DeleteButton
												labelTextkey="Household_MCost_Remove"
												questionTextkey="Household_MCost_Remove_Q"
												onClick={(e) =>
													this.removeMaintenanceCostClicked(e, x)
												}
											/>
										</HeaderButton>
										<HouseholdMaintenanceCost
											case={c}
											maintenanceCost={x}
										/>
									</Panel>
								);
							})}
						</TabPanels>
					)}
					<FormUnit
						size={FormUnitSize.Large}
						padding={FormUnitPadding.Normal}>
						<Button
							labelTextkey="Household_MCost_Add"
							className="Button plus-icon"
							onClick={this.addMaintenanceCostClicked}
							enabled={canAdd}
							style='link'
						/>
					</FormUnit>
				</FormUnit>
			</div>
		);
	}
}
