import React from 'react';
import ClassNames from 'classnames';
import './FormUnitRow.scss';

export enum Justify {
	FlexStart = 'flex-start',
	FlexEnd = 'flex-end',
	Center = 'center',
	SpaceBetween = 'space-between',
	SpaceAround = 'space-around',
	SpaceEvenly = 'space-evenly',
}

export enum AlignItems {
	FlexStart = 'flex-start',
	FlexEnd = 'flex-end',
	Center = 'center',
}

export interface layout {
	layout: Justify;
	alignItems: AlignItems
}

interface Props {
	className?: string;
	layout?: Justify;
	alignItems?: AlignItems;
	touched?: boolean;
	hasErrors?: boolean;
	dirty?: boolean;
}

export const FormUnitRow: React.FunctionComponent<Props> = (props) => {
	const classNames = ClassNames(props.className, {
		error: props.touched && props.hasErrors,
	});

	const style = {
		justifyContent: props.layout,
		alignItems: props.alignItems
	};

	return (
		<div className={classNames} style={style}>
			{props.children}
		</div>
	);
};

FormUnitRow.defaultProps = {
	className: 'FormUnitRow',
	layout: Justify.SpaceBetween,
	alignItems: AlignItems.FlexStart
};
