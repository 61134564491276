import React from 'react';
import {
	Case,
	MaintenanceHouse,
	LoanOwner,
	MaintenanceCost,
	Applicant,
} from '../../../../../models/SelmaModels';
import { AppComponent } from '../../../../AppComponent';
import { T } from '../../../../widget/display/T';
import {
	FormUnit,
	FormUnitSize,
	FormUnitPadding,
} from '../../../../layout/FormUnit/FormUnit';
import KeyValueList from '../../../../widget/display/KeyValueList/KeyValueList';
import { Panel } from '../../../../layout/Panel/Panel';
import NumberFormat from 'react-number-format';
import { uiEstateTypes } from '../../../../../models/uimodels/UIEstateType';
import { Heading } from '../../../../widget/display/Heading/Heading';
import { TypeOfHouse } from '../../../../../libs/SMELPOClient';
import { UiModelConverter } from '../../../../../models/UiModelConverter';

interface Props {
	case: Case;
}

export class HouseholdMaintenanceCostsSummary extends AppComponent<Props> {
	constructor(props: Props) {
		super(props);
	}

	getHouses(maintenanceHouse: MaintenanceHouse[] | undefined) {
		if (!maintenanceHouse) return [];

		return maintenanceHouse.map((x, ix) => {
			return [x.houseType, x.houseLoanAmount];
		});
	}

	getHouseTypeText(houseType: TypeOfHouse | undefined) {
		if (!houseType) return null;
		
		const uiEstateType = uiEstateTypes.find(
			(x) => houseType === x.type
		);
		if( uiEstateType )
			return uiEstateType.name;
		return "";
	}

	getLoanOwners(loanOwners:LoanOwner[] | undefined, c:Case) {
		if(!loanOwners) return null;

		let applicantString = '';

		const applicantService = this.application.services.applicantService;

		loanOwners.forEach((lo, ix) => {
			let x:Applicant|undefined = applicantService.getApplicantByCustomerId(c, lo.customerId);
			if (!x) 
				return null;

			if( applicantString.length>0 )
				applicantString += ', ';

			applicantString += `${x.firstName||''} ${x.lastName||''} (${UiModelConverter.prettySsn(x.customerId)})`;
			if( lo.ownershipPart )
				applicantString += " med ägarandel "+lo.ownershipPart + "%";
		})

		return applicantString;
	}

	render() {
		const c = this.props.case;

		if (!c.maintenanceCosts) return null;

		return (
			<FormUnit size={FormUnitSize.Large} padding={FormUnitPadding.Normal}>
				<Heading level={3} labelTextkey="Summary_Household_MaintenanceCosts"/>
				{c.maintenanceCosts.map((x, ix) => (
					<Panel key={`maintenanceCost_${x.id}_${ix}`}>
						{x.typeofhouses &&
							x.typeofhouses.map(
								(x, ix) => {
									let keyValueList = this.makeKeyValueList(c,x);
									return (
									<KeyValueList key={ix}>
										{keyValueList}
									</KeyValueList>
								)
							})
						}
					</Panel>
				))}
			</FormUnit>
		);
	}


	makeKeyValueList(c:Case, x: MaintenanceHouse): any[] {

		const keepHouse = x.keepHouse?true:false;

		let kvs = [
			{
				key: (
					<T k="Summary_Household_MCosts_EstateType" />
				),
						value: this.getHouseTypeText(x.houseType),
			},
			{
				key: (
					<T k="Summary_Household_MCosts_KeepHouse" />
				),
				value: keepHouse ? <T k="Yes" /> : <T k="No" />,
			}
		];

		if( !keepHouse )
			return kvs;

		if( x.houseType===TypeOfHouse.Hyresrtt ) {
			kvs.push({
				key: (
					<T k="Household_MHouse_TenancyCost" />
				),
				value: (
					<NumberFormat
						thousandSeparator=" "
						suffix=" kr"
						displayType="text"
						value={x.maintenanceCost}
					/>
				)
			});
		}
		else {
			if( x.houseType===TypeOfHouse.Bostadsrtt ) {
				kvs.push({
					key: (
						(x.houseType===TypeOfHouse.Bostadsrtt)
						?<T k="Household_MHouse_ApartmentCost" />
						:<T k="Summary_Household_MCosts_MaintenanceCost" />
					),
					value: (
						<NumberFormat
							thousandSeparator=" "
							suffix=" kr"
							displayType="text"
							value={x.maintenanceCost}
						/>
					)
				});
			}

			const loanInOtherInstitute = x.loanInOtherInstitute?true:false;

			kvs.push({
				key: (
					<T k="Summary_Household_MCosts_LoanInOtherInstitute" />
				),
				value: loanInOtherInstitute ? (
					<T k="Yes" />
				) : (
					<T k="No" />
				),
			});
			if( loanInOtherInstitute ) {
				kvs.push({
					key: (
						<T k="Summary_Household_MCosts_RedeemLoan" />
					),
							value: x.redeemLoan ? (
								<T k="Yes" />
							) : (
								<T k="No" />
							),
				});
				kvs.push({
					key: (
						<T k="Summary_Household_MCosts_LoanAmount" />
					),
					value: (
						<NumberFormat
							thousandSeparator=" "
							suffix=" kr"
							displayType="text"
							value={x.houseLoanAmount}
						/>
					)
				});

				kvs.push({
					key: (
						<T k="Summary_Household_MCosts_LoanOwner" />
					),
					value: this.getLoanOwners(x.loanOwners, c),
				});
			}

		}

		return kvs;
	}
}

