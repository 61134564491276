import React from 'react';
import { ApplicantContactSummary } from './ApplicantContactSummary';
import { ApplicantIncomeSummary } from './ApplicantIncomeSummary';
import { ApplicantPepSummary } from './ApplicantPepSummary';
import { Case, Applicant } from '../../../../../models/SelmaModels';
import { AppComponent } from '../../../../AppComponent';
import { CompanyHasRevenueSummary } from '../CompanySummary/CompanyHasRevenueSummary';
import { CompanyHighRiskBusinessSummary } from '../CompanySummary/CompanyHighRiskBusinessSummary';
import { CompanyRevenueSummary } from '../CompanySummary/CompanyRevenueSummary';
import { CompanyBusinessFocusesSummary } from '../CompanySummary/CompanyBusinessFocusesSummary';
import { Heading } from '../../../../widget/display/Heading/Heading';
import { UiModelConverter } from '../../../../../models/UiModelConverter';

interface Props {
	case: Case;
	applicant: Applicant;
	hideCompanyInformation?:boolean;
}

export class PersonalCompanyApplicantItemSummary extends AppComponent<Props> {

	constructor(props: Props) {
		super(props);
	}

	render() {
		const personalEconomyService = this.application.services.personalEconomyService;
		const applicantService = this.application.services.applicantService;
		const companyEconomyService = this.application.services.companyEconomyService;
		const companyService = this.application.services.companyService;
		const c = this.props.case;
		const applicant = this.props.applicant;
		const hideCompanyInformation = this.props.hideCompanyInformation||false;
		const company = companyService.getApplicantCompany(c, applicant);
		const personalEconomy = personalEconomyService.getPersonalEconomy(c, applicant);
		const companyEconomy = companyEconomyService.getCompanyEconomy(c, company);
		const hasRevenue = companyEconomy && companyEconomyService.hasRevenue(companyEconomy);
		const showRevenue = hasRevenue===true?true:false;

		return (
			<>
				{!hideCompanyInformation &&
				<Heading level={3} labelTextkey="Summary_PersonalCompany_Title" labelText={" "+UiModelConverter.prettySsn(applicant.customerId)}/>
				}

				{!hideCompanyInformation && companyEconomy &&
				<CompanyHasRevenueSummary companyEconomy={companyEconomy}/>
				}
				{!hideCompanyInformation && showRevenue &&
				<CompanyHighRiskBusinessSummary case={c} applicant={applicant}/>
				}
				{!hideCompanyInformation && company && showRevenue &&
				<>
				<CompanyRevenueSummary case={c} company={company}/>
				<CompanyBusinessFocusesSummary company={company}/>
				</>
				}

				<ApplicantContactSummary case={c} applicant={applicant} />
				{personalEconomy && 
				<ApplicantIncomeSummary case={c} applicant={applicant} />
				}

				{
					applicantService.showPepForApplicant(applicant) && <ApplicantPepSummary applicant={applicant} />
				}
			</>
		);
	}
}
