import React from 'react';
import { Case } from '../../../../models/SelmaModels';
import { AppComponent } from '../../../AppComponent';
import { StepHeader } from '../../StepHeader';
import { StepContext } from '../../CaseSteps';
import { StepFooter } from '../../StepFooter';
import { CompanySummary } from './CompanySummary/CompanySummary';
import LoanSummary from './LoanSummary/LoanSummary';
import { ApplicantsSummary } from './ApplicantsSummary/ApplicantsSummary';
import { CollateralSummary } from './CollateralSummary/CollateralSummary';
import { HouseholdSummary } from './HouseholdSummary/HouseholdSummary';
import { BudgetSummary } from './BudgetSummary/BudgetSummary';
import { ApproveApplication } from './ApproveApplication';
import { FormUnit, FormUnitSize } from '../../../layout/FormUnit/FormUnit';
import { Button } from '../../../widget/input/Button/Button';

import './StepSummary.scss';
import { CaseIdStatus } from '../../../../libs/SMELPOClient/models/CaseIdStatus';
import { ButtonGroup, ButtonGroupLayout } from '../../../widget/input/ButtonGroup/ButtonGroup';
import { MessageItem, Messages } from '../../../widget/display/Messages/Messages';
import StepFormContext, { StepForm } from '../../../widget/display/StepForm/StepForm';

interface Props {
	case: Case;
	context: StepContext;
}

interface State {
	hasTouchedNextButton:boolean;
	immidiateAllValidatorsVisibleBefore?:Date;
}

export class StepSummary extends AppComponent<Props, State> {

	constructor(props: Props) {
		super(props);

		this.state = { 
			hasTouchedNextButton:false,
			immidiateAllValidatorsVisibleBefore:undefined
		};
	}

	componentDidMount() {
		const caseService = this.application.services.caseService;
		caseService.update(this.props.case); // trigger re-validation to avoid allowing customer to send in unfinished case.
	}

	trySendApplication = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {

		let stepIsValid = this.props.context.getValidation().ok();
		if ( stepIsValid === false )
		{
			this.setState({hasTouchedNextButton : true, immidiateAllValidatorsVisibleBefore:new Date()})
			return;
		}

		this.sendApplication();
	}

	sendApplication = () => {
		
		const caseService = this.application.services.caseService;
		const attachmentService = this.application.services.attachmentService;
		const c = this.props.case;
		attachmentService.ensureCorrectAttachmentRequests(c);

		this.application.services.caseService
		.saveCase(c)
		.then(result => {
			return caseService.sendInApplication(this.props.case)
			.then(() => {
				this.props.context.goNext();
			});
		})
	}

	goNext = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
		this.props.context.goNext();
	}

	render() {
		const c = this.props.case;
		const summaryService = this.application.services.summaryService;
		const canSeeApprove = this.props.case.status===CaseIdStatus.STARTEDBYAPPLICANT;
		const canGoNext = this.props.case.status===CaseIdStatus.READYFOROFFICER
			|| this.props.case.status===CaseIdStatus.CLOSEDBYOFFICER;
		let canApprove = summaryService.canApproveCase(c);
		if( canApprove ) {
			canApprove = this.props.context.getValidation().ok();
		}

		let showApproveNotValidError = canApprove == false && this.state.hasTouchedNextButton == true;
		let approveButtonShoulLookEnabled = canApprove || this.state.hasTouchedNextButton == false;
		const immidiateAllValidatorsVisibleBefore = this.state.immidiateAllValidatorsVisibleBefore;

		return (
			<div className="StepSummary Step">
				<StepHeader context={this.props.context} 
					titleTextkey="Summary_Title" descriptionTextkey="Summary_Description" />

				<CompanySummary case={c} />
				<LoanSummary case={c} /> 
				<ApplicantsSummary case={c} hideMyApplicant={true}/>
				<CollateralSummary case={c} />
				<HouseholdSummary case={c} />
				<BudgetSummary case={c} />

				{canSeeApprove &&
				<>

					<StepForm context={this.props.context} immidiateAllValidatorsVisibleBefore={immidiateAllValidatorsVisibleBefore}>
						<ApproveApplication case={c} />
					</StepForm>

					<FormUnit size={FormUnitSize.Large}>
						<Button id="sendApplication" 
							className="Button big full-width" 
							labelTextkey="Summary_SendApplication" 
							onClick={this.trySendApplication} 
							enabled={approveButtonShoulLookEnabled}/>
					</FormUnit>

					<Messages visible={showApproveNotValidError} items={[
						{ 
							textkey: "GoToNext_InvalidCase", ok : false }
						]} />

				</>
				}
				{canGoNext && 
					<>
					<br/>
					<FormUnit size={FormUnitSize.Large}>
						<ButtonGroup layout={ButtonGroupLayout.Stretch}>
							<Button
								id="goNext"
								className="Button big green"
								labelTextkey="GoToNext"
								onClick={this.goNext}
							/>
						</ButtonGroup>
					</FormUnit>
					</>
				}
			</div>
		);
	}
}
