import { BaseService } from './BaseService';
import { Case, Aim, DownpaymentSource } from '../models/SelmaModels';
import { Loan, TakeoverLoan } from "../models/selmamodels/Loan";
import { SMELPOService } from './SMELPOService';
import { StateService } from './StateService';
import { ValidationResult } from '../utils/ValidationResult';
import { GuardService } from './GuardService';
import { Application } from '../models/AppModels';
import { uiLoanAimCategories, UILoanAimCategory } from '../models/UiModels';

export class LoanService extends BaseService {
	private stateService: StateService;
	private smelpoService: SMELPOService;
	private guardService: GuardService;

	constructor(private application: Application, stateService: StateService, smelpoService: SMELPOService, guardService: GuardService) {
		super();
		this.stateService = stateService;
		this.smelpoService = smelpoService;
		this.guardService = guardService;
	}

	update(loan: Loan|TakeoverLoan): void {
		this.stateService.update(loan);
	}

	validateLoan(loan: Loan): ValidationResult {
		let vr = new ValidationResult();
		return vr;
	}

	hasAmount(loan: Loan): boolean {
		return loan &&
			this.guardService.isGreaterThanZero(this.getLoanAmount(loan));
	}

	hasLoan(c: Case): boolean {
		return c.loan?true:false;
	}

	ensureLoan(c: Case): Loan {
		if( !c.loan ) {
			c.loan = { id: this.application.services.idService.newGuid()};
		}
		return c.loan;
	}

	getMinimumLoanAmount(c: Case) {
		return 50000; // the minimum amount in total to borrow per process.
	}

	getMinimumSubLoanAmount() {
		return 10000; // the minimum amount to borrow per aim or takeoverLoan.
	}

	getMaximumLoanAmount(c: Case) {
		return 1000*1000*1000;
	}

	hasTakeoverLoans(loan: Loan): boolean {
		return this.guardService.hasLengthGreaterThanZero(loan.takeoverLoans);
	}

	hasAims(loan: Loan): boolean {
		return this.guardService.hasLengthGreaterThanZero(loan.aims);
	}

	hasSubLoans(loan: Loan): boolean {
		return this.hasTakeoverLoans(loan) || this.hasAims(loan);
	}

	// setLoanAmount(c: Case, loan: Loan, amount: number): void {
	// 	if( amount>=0) {
	// 		const prevAmount = loan.amount||0;
	// 		if( prevAmount!=amount ) {
	// 			loan.amount = amount;
	// 			this.update(loan);
	// 		}

	// 		if( loan.aims && loan.aims.length===1 ) {
	// 			const aim = loan.aims[0];
	// 			if( this.guardService.isUnvaluedOrZero(aim.loanAmountPart) || aim.loanAmountPart===prevAmount ) {
	// 				if( aim.loanAmountPart != amount) {
	// 					aim.loanAmountPart = amount;
	// 					this.stateService.update(aim);
	// 				}
	// 			}
	// 		}

	// 	}
	// }

	getLoanAmount(loan:Loan): number {
		let amountSum = this.getAimsAmountSum(loan) + this.getTakeoverLoansDebtSum(loan);
		return amountSum;
	}

	getAimsAmountSum(loan:Loan): number {
		let amountSum = 0;
		if( loan && loan.aims ) {
			loan.aims.forEach(x => {
				amountSum += x.loanAmountPart||0;
			});
		}
		return amountSum;
	}

	getTakeoverLoansDebtSum(loan:Loan): number {
		let amountSum = 0;
		if( loan && loan.takeoverLoans ) {
			loan.takeoverLoans.forEach(x => {
				amountSum += x.debtAmount||0;
			});
		}
		return amountSum;
	}


	hasValidLoanAmount(c: Case): boolean {
		const g = this.guardService;
		if( !c || !c.loan )
			return false;

		const loan = c.loan;
		const loanAmount = this.getLoanAmount(loan);
		return loanAmount && 
			g.isGreaterThanOrEqual(loanAmount, this.getMinimumLoanAmount(c)) 
			? true : false;
	}

	// getAimSum(loan: Loan): number {
	// 	if( !loan.aims ) return 0;
	// 	const sum = loan.aims.reduce((acc,val)=> {
	// 		return acc + (val.loanAmountPart||0);
	// 	}, 0);
	// 	return sum;
	// }

/****************************************** */
/* LOAN AIMS */
/****************************************** */
	addNewAim(loan: Loan): Aim {
		if( !loan.aims ) {
			loan.aims = [];
		}
		// const loanAmount = loan.amount||0;
		// const aimSum = this.getAimsAmountSum(loan);
		const aim = {
			id: this.application.services.idService.newGuid(),
			loanAmountPart: undefined
		};
		this.stateService.listAdd(loan.aims, aim);
		this.update(loan);
		return aim;
	}

	updateAim(loan: Loan, aim: Aim) {
		this.update(loan);
		this.stateService.update(aim);
	}

	removeAim(loan: Loan, aim: Aim) {
		if( !loan || !loan.aims ) {
			return;
		}

		this.stateService.listRemove(loan.aims, aim);
		this.update(loan);
	}

	removeAllAims(loan: Loan):void {
		if( !loan || !loan.aims ) {
			return;
		}

		loan.aims = undefined;
		this.update(loan);
	}

	setAimLoanAmountPart(loan: Loan, aim: Aim, amountPart: number|undefined) {
		aim.loanAmountPart = amountPart;
		this.updateAim(loan, aim);
	}

	getMaximumAimAmount() {
		return 1000*1000*1000;
	}

	
	// hasMinimumLoanParts(loan: Loan) : boolean {
	// 	return loan.aims && loan.aims.length>0?true:false;
	// }

	ensureMinimumLoanAims(loan: Loan) {
		if( this.guardService.isUnvaluedOrEmpty(loan.aims) ) {
			this.addNewAim(loan);
		}
	}


/****************************************** */
/* LOAN TAKEOVERS */
/****************************************** */
	addNewLoanTakeOver(loan: Loan): TakeoverLoan {
		if( !loan.takeoverLoans ) {
			loan.takeoverLoans = [];
		}

		const takeoverLoan = {
			id: this.application.services.idService.newGuid()
		};

		this.stateService.listAdd(loan.takeoverLoans, takeoverLoan);
		this.update(loan);
		return takeoverLoan;
	}

	ensureMinimumTakeoverLoans(loan: Loan) {
		if( this.guardService.isUnvaluedOrEmpty(loan.takeoverLoans) ) {
			this.addNewLoanTakeOver(loan);
		}
	}

	updateLoanTakeOver(loan: Loan, takeoverLoan: TakeoverLoan) {
		this.update(loan);
		this.stateService.update(takeoverLoan);
	}

	removeLoanTakeOver(loan: Loan, takeoverLoan: TakeoverLoan) {
		if( !loan || !loan.takeoverLoans ) {
			return;
		}

		this.stateService.listRemove(loan.takeoverLoans, takeoverLoan);
		this.update(loan);
	}

	removeAllTakeOverLoans(loan: Loan):void {
		if( !loan || !loan.takeoverLoans ) {
			return;
		}

		loan.takeoverLoans = undefined;
		this.update(loan);
	}

	setLoanTakeOverDebtAmount(loan: Loan, takeoverLoan: TakeoverLoan, debtAmount: number|undefined) {
		takeoverLoan.debtAmount = debtAmount;
		this.updateLoanTakeOver(loan, takeoverLoan);
	}

	setLoanTakeOverBank(loan: Loan, takeoverLoan: TakeoverLoan, creditInstitute: string) {
		takeoverLoan.creditInstitute = creditInstitute;
		this.updateLoanTakeOver(loan, takeoverLoan);
	}

	setLoanTakeOverLoanNumber(loan: Loan, takeoverLoan: TakeoverLoan, loanNumber: string) {
		takeoverLoan.loanNumber = loanNumber;
		this.updateLoanTakeOver(loan, takeoverLoan);
	}


	askAboutDownpayment(loan: Loan) : boolean {
		if( !loan.aims || loan.aims.length===0 ) return false;

		const r = loan.aims.filter(x => x.aimCategory==="Fastighetsköp");
		return r.length>0;
	}

	askAboutDownpaymentOther(loan: Loan) : boolean {
		if( !this.askAboutDownpayment(loan) ) return false;
		if( loan.downpaymentType === DownpaymentSource.OTHER ) return true;
		return false;
	}


	getMaximumTakeoverLoanDebtAmount() {
		return 1000*1000*1000;
	}

	clearTakeoverLoanDetails(takeoverLoan: TakeoverLoan) {
		takeoverLoan.loanNumber = undefined;
		takeoverLoan.creditInstitute = undefined;
		this.update(takeoverLoan);
	}

	hasTakeoverLoanDetails(takeoverLoan: TakeoverLoan): boolean | undefined {
		const g = this.guardService;
		return g.hasValue(takeoverLoan.loanNumber) || g.hasValue(takeoverLoan.creditInstitute);
	}

	hasValidPurposeDescription(l:Loan):boolean {
		const g = this.guardService;

		if (g.isUnvaluedOrEmpty(l.purposeDescription))
			return true;

		return g.hasLengthLessThan(l.purposeDescription, 3500);
	}


	// === Validation ===

	validateCaseLoan(c: Case): ValidationResult {
		const vr = new ValidationResult();
		const loanService = this;

		// Must have loan sum.
		const loan = c.loan;
		const hasLoan = loanService.hasLoan(c);
		vr.add({ validator: loanService.hasLoan, object:c, ok: hasLoan});
		if( loan && hasLoan ) {
			// Must have at least one sub-loan (takeover or aim).
			vr.add({ validator: loanService.hasSubLoans, object:c.loan, ok:loanService.hasSubLoans(loan) });

			// Loan sum must be greater than 1000 SEK and even to 1000 SEK
			vr.add({ validator: loanService.hasValidLoanAmount, object:c.loan, ok:loanService.hasValidLoanAmount(c) });
			
			// Purpose description must not be longer than 3500 chars
			vr.add({ validator: loanService.hasValidPurposeDescription, object:c.loan, ok:loanService.hasValidPurposeDescription(loan) });

			// Must have at least one aim.
			// vr.add({ validator: loanService.hasMinimumLoanAims, object:c.loan, 
			// 	ok: (c.loan && loanService.hasMinimumLoanAims(c.loan))?true:false });

			vr.addResult(loanService.validateAimsAndDownpayment(loan));
			vr.addResult(loanService.validateTakeoverLoans(loan));
		}

		return vr;
	}

	aimHasCategory(x: Aim): boolean {
		const g = this.guardService;
		return g.hasLengthGreaterThan(x.aimCategory,0);
	}

	aimHasDetail(x: Aim): boolean {
		const g = this.guardService;
		return g.hasLengthGreaterThan(x.aimDetail,0);
	}

	aimHasAmount(x: Aim): boolean {
		const g = this.guardService;
		return g.isGreaterThanZero(x.loanAmountPart);
	}

	aimHasValidAmount(x: Aim): boolean {
		const g = this.guardService;
		return g.isGreaterThanOrEqual(x.loanAmountPart, this.getMinimumSubLoanAmount());
	}

	takeoverLoanHasCategory(x: TakeoverLoan): boolean {
		const g = this.guardService;
		return g.hasLengthGreaterThan(x.aimCategory,0);
	}

	takeoverLoanHasDetail(x: TakeoverLoan): boolean {
		const g = this.guardService;
		return g.hasLengthGreaterThan(x.aimDetail,0);
	}

	takeoverLoanHasAmount(x: TakeoverLoan): boolean {
		const g = this.guardService;
		return g.isGreaterThanZero(x.debtAmount);
	}

	takeoverLoanHasValidAmount(x: TakeoverLoan): boolean {
		const g = this.guardService;
		return g.isGreaterThanOrEqual(x.debtAmount, this.getMinimumSubLoanAmount());
	}

	aimsHaveValidAmountSum(loan: Loan): boolean {
		const g = this.guardService;
		const sum = this.getAimsAmountSum(loan);
		return g.isGreaterThanZero(sum);
	}

	takeoverLoansHaveValidAmountSum(loan: Loan): boolean {
		const g = this.guardService;
		const sum = this.getTakeoverLoansDebtSum(loan);
		return g.isGreaterThanZero(sum);
	}

	validateTakeoverLoans(loan: Loan): ValidationResult {
		const vr = new ValidationResult();

		if( loan.takeoverLoans && this.hasTakeoverLoans(loan) ) {
			loan.takeoverLoans.forEach(x => {
				vr.addResult(this.validateTakeoverLoan(x));
			});
			vr.add({ validator: this.takeoverLoansHaveValidAmountSum, object:loan, ok: this.takeoverLoansHaveValidAmountSum(loan)}); 
		}

		return vr;
	}
	validateTakeoverLoan(x: TakeoverLoan): ValidationResult {
		const vr = new ValidationResult();
		vr.add({ validator: this.takeoverLoanHasCategory, object:x, ok: this.takeoverLoanHasCategory(x)});
		vr.add({ validator: this.takeoverLoanHasDetail, object:x, ok: this.takeoverLoanHasDetail(x)});
		vr.add({ validator: this.takeoverLoanHasAmount, object:x, ok: this.takeoverLoanHasAmount(x)});
		vr.add({ validator: this.takeoverLoanHasValidAmount, object:x, ok: this.takeoverLoanHasValidAmount(x)});
		return vr; 
	}

	validateAimsAndDownpayment(loan: Loan): ValidationResult {
		const vr = new ValidationResult();

		// Every aim must have detail code.
		// Every aim must have amount.
		// Sum of all aim amount must be equal to loan amount.
		if( loan.aims && this.hasAims(loan) ) {
			loan.aims.forEach(x => {
				vr.addResult(this.validateAim(x));
			});
			vr.add({ validator: this.aimsHaveValidAmountSum, object:loan, ok: this.aimsHaveValidAmountSum(loan)}); 
		}


		// If at least one aim category is Fastighetsköp ...
		if( this.askAboutDownpayment(loan) ) {
			//   DownpaymentSource must be selected.

			vr.add({ validator: this.hasDownpaymentType, object:loan, ok: this.hasDownpaymentType(loan)});

			if( this.askAboutDownpaymentOther(loan) ) {
				//   If DownpaymentSource is OTHER ...
				//     DownpaymentSourceText must have length > 0.
				vr.add({ validator: this.hasDownpaymentOther, object:loan, ok: this.hasDownpaymentOther(loan)});
			}
		}

		return vr;
	}
	validateAim(x: Aim): ValidationResult {
		const vr = new ValidationResult();
		vr.add({ validator: this.aimHasCategory, object:x, ok: this.aimHasCategory(x)});
		vr.add({ validator: this.aimHasDetail, object:x, ok: this.aimHasDetail(x)});
		vr.add({ validator: this.aimHasAmount, object:x, ok: this.aimHasAmount(x)});
		vr.add({ validator: this.aimHasValidAmount, object:x, ok: this.aimHasValidAmount(x)});
		return vr;
	}

	hasDownpaymentType(loan: Loan): boolean {
		const g = this.guardService;
		return g.hasValue(loan.downpaymentType);
	}
	hasDownpaymentOther(loan: Loan): boolean {
		const g = this.guardService;
		return !g.isUnvaluedOrEmpty(loan.downpaymentOther);
	}


	// === Value look up tools ===

	getUiAimCategory(aimCategory: string | undefined): UILoanAimCategory|undefined {
		if( !aimCategory || aimCategory === "") return;

		const uiAimCategory = uiLoanAimCategories.find((x) => x.id === aimCategory);
		return uiAimCategory;
	}

	getAimCategoryName(aimCategory: string | undefined): string {
		const uiAimCategory = this.getUiAimCategory(aimCategory);
		if( !uiAimCategory ) return "";
		return uiAimCategory.name;
	}

	getAimDetailName(aimCategory: string | undefined, aimDetail: string | undefined): string {
		const uiAimCategory = this.getUiAimCategory(aimCategory);
		if( !uiAimCategory ) return "";

		const uiAimDetail = uiAimCategory.details.find((x) => x.id === aimDetail);
		if( !uiAimDetail ) return "";

		return uiAimDetail.name;
	}

}
