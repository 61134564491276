import React, { FunctionComponent } from 'react';
import Classnames from 'classnames';
import './KeyValueList.scss';

interface Props {
	className?: string;
	children: any;
};

export interface KeyValue {
	key: string|React.ReactNode,
	value: string|React.ReactNode
}

const KeyValueList: FunctionComponent<Props> = ({ className, children }) => {
	const classNames = Classnames([
		'KeyValueList',
		className,
	]);

	return !children ? null : (
		<ul className={classNames}>
			{children &&
				children
					.filter((x) => (x ? true : false))
					.map(({ key, value }, index) => (
						<li key={`list-item-${index}`}>
							<span className="Key">{key}</span>
							<span className="Value">{value}</span>
						</li>
					))}
		</ul>
	);
}

export default KeyValueList;
