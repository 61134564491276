import React from 'react';
import { Case as SelmaCase } from '../../../models/SelmaModels';
import { T } from '../../widget/display/T';
import { AppComponent } from '../../AppComponent';
import { Button } from '../../widget/input/Button/Button';
import { StatusIndicator } from '../../widget/display/StatusIndicator/StatusIndicator';

import './HomeCase.scss';
import { DeleteButton } from '../../widget/input/DeleteButton/DeleteButton';

interface Props {
	case: SelmaCase;
	selectedCase: (c: SelmaCase) => void;
	deletedCase: (c: SelmaCase) => void;
}

export class HomeCase extends AppComponent<Props> {
	constructor(props: Props) {
		super(props);
	}

	selectedCase = (event: React.MouseEvent<Element, MouseEvent>): void => {
		this.props.selectedCase(this.props.case);
	};

	deleteCase = (event: React.MouseEvent<Element, MouseEvent>): void => {
		this.props.deletedCase(this.props.case);
	};

	render() {

		const c = this.props.case;
		const stepService = this.application.services.stepService;
		const caseService = this.application.services.caseService;

		const startStep = stepService.getStartStep(c);
		const canDelete = caseService.canDeleteCase(c)

		let ordinal = 0;
		let progress = 0;
		let statusText = '';
		let needsComplement;
		if (startStep) {
			ordinal = stepService.getStepOrdinal(startStep, c);
			progress = stepService.calcStepProgress(startStep, c);
			statusText = stepService.getStepStatusText(startStep);
			needsComplement = stepService.stepNeedsComplement(startStep, c);
		}

		const euSupportService = this.application.services.euSupportService;

		const isServerCase = caseService.isServerCase(c);

		return (
			<div className="HomeCase">
				<div className="Header">
					<h3>
						{isServerCase && <><T k="Home_CaseN" />{this.props.case.number}</>}
						{!isServerCase && <T k="Home_NewCase" />}
					</h3>
					{canDelete &&
						<>
							<DeleteButton labelTextkey="Home_DeleteCase" onClick={this.deleteCase} />
						</>
					}
				</div>
				<div className="Body">
					<div className="Status">
						<StatusIndicator ordinal={ordinal} progress={progress} size={48} icon={needsComplement ? 'warning' : undefined} />
						<div className="StatusText">{statusText}</div>
					</div>
					<ul className="Items">
						{this.props.case.applicants &&
							this.props.case.applicants.map((x, ix) => (
								<li className="Item Applicant" key={ix}>
									{x.firstName || ''} {x.lastName || ''}
								</li>
							))
						}
						{/*this.props.case.companies && false &&
							this.props.case.companies.map((x, ix) => (
								<li className="Item Company" key={ix}>
									{x.name||''} {x.orgNumber||''}
								</li>
							))
							*/}
						{c.collaterals && c.collaterals.map((x, ix) => (
							<li key={ix} className="Item Estate">{x.name || ''} {x.municipality || ''}</li>
						))
						}
						{c.euSupports && c.euSupports.map((x, ix) => (
							<li key={ix} className="Item EuSupport">{euSupportService.getEuTypeDisplayName(x.euType)}</li>
						))
						}
						{c.guarantors && c.guarantors.map((x, ix) => (
							<li key={ix} className="Item Guarantor">{x.name || ''}</li>
						))
						}
					</ul>
				</div>
				<div className="Footer">
					<Button
						className="Button full-width"
						labelTextkey="Home_SelectCase"
						onClick={this.selectedCase}
					/>
				</div>
			</div>
		);
	}
}
