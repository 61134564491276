export class UiModelConverter {

	static prettySsn(ssn: string): string {
		if( ssn.length===12 )
			return ssn.substr(0,8)+'-'+ssn.substr(8,4);
		else
			return ssn;
	}

	static prettyOrgNr(orgNr: string|undefined): string {
		if( !orgNr )
			return '';
		else if( orgNr.length===10 )
			return orgNr.substr(0,6)+'-'+orgNr.substr(6,4);
		else
			return orgNr;
	}

	static prettyCustomerId(customerId: string|undefined): string {
		if( !customerId )
			return '';
		else if( customerId.length===12 )
			return this.prettySsn(customerId);
		else if( customerId.length===10 )
			return this.prettyOrgNr(customerId);
		return customerId;
	}

	static prettyDateString(date: string|Date|undefined): string {
		if( !date || date===null )
			return '';
		else if( typeof(date) == 'string' ) {
			let d = Date.parse(date as string);
			date = new Date(d);
		}
	
		if( date instanceof Date ) {
			return date.toLocaleDateString();
		} else {
			return '?';
		}
	}

	static numberToIntegerThousandSeparatedString (value: number, separator: string = "\u0020"): string {
		if (value===undefined || value === null)
			return "";

		var v = Math.round(value);

		if (v > -1000 && v < 1000)
			return value.toString();

		var str = value.toString();

		// Add thousands separator. 
		// Originally it was the unicode character "\u2009" == THIN SPACE
		// but it was replaced with a regular space "\u0200" since Safari on iOS
		// couldn't render thin space.
		for (var i = str.length - 3; i > 0; i -= 3) {
			str = str.substring(0, i) + separator + str.substring(i);
		}

		return str;
	};
	
	// static integerThousandSeparatedStringToNumber(value: string): number {
	// 	if (angular.isUndefined(value) || value === null)
	// 		return null;

	// 	value = value.replace(/\D/g, "");

	// 	var num = parseFloat(value);
	// 	if (isNaN(num))
	// 		return null;

	// 	return num;
	// }
	
			// Takes an ssn on any form. returns it in normalized form: YYYYMMDDNNNN.
	static toNormalizedSsn(ssn: string): string {
		if (!ssn || !ssn.toLowerCase)
			return ssn;
		if (ssn.length === 12) // YYYYMMDDNNNN
			return ssn;
		if (ssn.length === 13) // YYYYMMDD-NNNN
			return ssn.replace(/-/, "");
		if (ssn.length === 10)// YYMMDDNNNN
			return "19" + ssn;
		if (ssn.length === 11)// YYMMDD-NNNN
			return "19" + ssn.replace(/-/, "");
		return ssn;
	}
	
		// Takes an ssn on any form. returns it in normalized form: YYYYMMDD-NNNN.
		// toPrettyNormalizedSsn: function (ssn: string): string {
		// 	if (!angular.isString(ssn))
		// 		return ssn;
		// 	ssn = svc.toNormalizedSsn(ssn);
		// 	if (ssn.length === 12) // YYYYMMDDNNNN
		// 		ssn = ssn.substr(0, 8) + "-" + ssn.substr(8, 4); // YYYYMMDD-NNNN
		// 	return ssn;
		// },

	static getSsnDate(ssn: string): Date|undefined {
		if (!ssn || ssn.length === 0)
			return;

		ssn = UiModelConverter.toNormalizedSsn(ssn.replace('-', ''));
		if (ssn.length !== 12)
			return;

		let year = parseInt(ssn.substr(0, 4));
		let month = parseInt(ssn.substr(4, 2));
		let day = parseInt(ssn.substr(6, 2));

		return new Date(year, month, day);
	};

	static getAgeByDate(date: Date, now?: Date) : number {
		if (!now)
			now = new Date();

		var ageDifMs = now.getTime() - date.getTime();
		var ageDate = new Date(ageDifMs); // miliseconds from epoch
		return Math.abs(ageDate.getUTCFullYear() - 1970);
	};

	static getAgeBySsn(ssn: string): number {
		let ssnDate = UiModelConverter.getSsnDate(ssn);
		if (!ssnDate)
			return -9999;

		return UiModelConverter.getAgeByDate(ssnDate);
	};

	// toIsoDateString: function(value: Date): string {
	// 	let yyyy = value.getFullYear().toString();
	// 	let mm = (value.getMonth() + 1).toString(); // getMonth() is zero-based 
	// 	let dd = value.getDate().toString();
	// 	return yyyy + "-" + (mm[1] ? mm : "0" + mm[0]) + "-" + (dd[1] ? dd : "0" + dd[0]) + "T00:00:00.000Z";
	// }
	
}