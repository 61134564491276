
import { Case, KycApplicant } from "../models/SelmaModels";
import { ValidationResult } from "../utils/ValidationResult";
import { ApplicantService } from "./ApplicantService";
import { BaseService } from "./BaseService";


interface GetKYCStatusResponse {
    caseId: string,
    applicants: KycApplicant[]
}

export default class KycService extends BaseService  {

    private applicantService: ApplicantService;
    private url: string;

    constructor(url: string, applicantService: ApplicantService) {
		super();
        this.applicantService = applicantService;
        this.url = url;
	} 

    validateStatus(status:string):boolean 
    { 
        return status === "Completed" || status === "COMPLETED"
    }

    private validateKyc({questionnaireStatus} :KycApplicant) : boolean {
        return this.validateStatus(questionnaireStatus);
    }

    private validateKycInArray(x: KycApplicant[]): ValidationResult {
        const vr = new ValidationResult();
		if (x.length > 0) {
			x.forEach(x => {
				vr.add({ validator: this.validateKyc, object: x, ok: this.validateKyc(x) });
			})
		}
        else {
            vr.add({ validator: () => {}, object: x, ok: false})
        }
		return vr;
    }

    showKycForCase(c:Case): boolean {
        const showPepForApplicants = c.applicants?.map(a => this.applicantService.showPepForApplicant(a)).filter(x => x === true);
		if(showPepForApplicants == undefined || showPepForApplicants.length === 0) {
			return true;
		}		
		return false;
    }

    validateCaseKyc(c: Case): ValidationResult {
        
		let vr = new ValidationResult();
		if (c.kycApplicantsStatus) {
			vr.addResult(this.validateKycInArray(c.kycApplicantsStatus));
		}
		return vr;
	}

    private async getKycStatus(caseId: string|undefined):Promise<GetKYCStatusResponse> {
        try  {
            
            if(caseId) {
                var result = await fetch(`${this.url}/loanProcess/getkycstatus/${caseId}`, {
                    method:'GET',
                    cache: 'no-cache',
                    credentials: "include",
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }});
                const data: GetKYCStatusResponse = await result.json();
    
                if(data.applicants == null || data.applicants == undefined) 
                    data.applicants = [];
    
                return data;
            }

            return {
                caseId: caseId ?? '',
                applicants: []
            };
        }
        catch {
            return {
                caseId: caseId ?? '',
                applicants: []
            };
        }
    }

    async getKycForCase(c: Case): Promise<GetKYCStatusResponse> { return await this.getKycStatus(c.lpCaseId); }
}