import React from 'react';
import { Case, AttachmentRequest, Attachment } from '../../../../models/SelmaModels';
import { ArrayService } from '../../../../services/ArrayService';
import { AppComponent } from '../../../AppComponent';
import { Icon, IconType } from '../../../widget/display/Icon/Icon';
import { T } from '../../../widget/display/T';
import { THtml } from '../../../widget/display/THtml';
import { ProvidedAttachments } from './ProvidedAttachments';
import { RequestedAttachment } from './RequestedAttachment';

interface AttachmentRequestExtendend extends AttachmentRequest {
	mappedRequestType:string,
	group:string;
}

interface Props {
	case: Case;
	attachmentRequests: AttachmentRequest[];
	requiredOnly?: boolean;
	attachments?: Attachment[];
}

interface State {
	attachmentRequests: Map<string, AttachmentRequestExtendend[]>
}

export class RequestedAttachments extends AppComponent<Props, State> {

	constructor(props: Props) {
		super(props);
		this.observeUpdate(this.props.case);

		const attachmentService = this.application.services.attachmentService;
		const attachmentRequests = attachmentService.filterRequiredAttachmentRequests(this.props.attachmentRequests, this.props.requiredOnly);

		// group upload fields DISME-536
		const uiAttachmentRequests = this.groupAttachmentRequests(attachmentRequests);

		this.state = {
			attachmentRequests: uiAttachmentRequests
		}
	}

	groupAttachmentRequests = (attachmentRequests: AttachmentRequest[]): Map<string, AttachmentRequestExtendend[]> => {

		// Map to extended class so we can store some extra info
		let mappedRequests = attachmentRequests.map((req, index) => {
			
			const rt = req.requestType;
			// treat/map COMPANYFINREPORT and COMPANYTAXRETURN as ANNUALACCOUNT for backwards compability
			const mappedRequestType = this.application.services.attachmentService.getEffectiveRequestType(rt);
			const group = mappedRequestType + "|" + (req.customerId || '') // group by type per customer
			return {
				...req,
				mappedRequestType: mappedRequestType,
				group: group
			} as AttachmentRequestExtendend;
		})

		return ArrayService.groupBy(mappedRequests, x => x.group);
	}

	getSortedGroupedAttacmentRequests = (): string[] => {
		const keys = Array.from(this.state.attachmentRequests.keys());
		const sortedKeys = keys.sort((a,b) => {
			if( a==b )
				return 0;
			if( a=='OTHER|' ) // hack to ensure OTHER is last.
				return 1;
			if( b=='OTHER|' )
				return -1;
			let comp = a.localeCompare(b);
			return comp;
		})
		return sortedKeys;
	}

	render() {
		const c = this.props.case;
		const attachmentService = this.application.services.attachmentService;
		
		return (
			<div className="RequestedAttachments">
				{this.getSortedGroupedAttacmentRequests().map(key => {

					const uiAttachmentRequests = this.state.attachmentRequests.get(key);
					const firstAttachmentRequest = uiAttachmentRequests![0];
					const firstType = firstAttachmentRequest.mappedRequestType;
					const uploadTextKey = "Upload_Text_" + firstType;
					const requiredAttachmentRequests = uiAttachmentRequests?.filter(x => x.required);
					const required = requiredAttachmentRequests && requiredAttachmentRequests.length > 0;
					const showWarning = required && !attachmentService.attachmentRequestHasAttachments(c, firstAttachmentRequest);

					let bullets:string[]|undefined = undefined;
					if( firstType !== "OTHER" && uiAttachmentRequests ) {
						const a = uiAttachmentRequests.filter(x => x.requestVariation && x.requestVariation!=="");
						if( a && a.length ) {
							const b = ArrayService.groupBy(a, x => x.requestVariation);
							bullets = Array.from(b.keys());
						}
					}

					return (
						<div className="FormUnit PaddingNormal" key={key}>
							<h3>
								{showWarning && <Icon iconType={IconType.Warning}/>}
								{firstAttachmentRequest && attachmentService.getAttachmentRequestTypeDisplayName(firstAttachmentRequest)} 
								{required && <> (<T k="Required"/>)</>}
							</h3>
							<div className="Paragraph">
								<THtml k={uploadTextKey} />

								{bullets && bullets.length>0 &&
									<ul>
										{bullets.map((bullet, index) => <li key={bullet}>{bullet}</li>)	}
									</ul>
								}
							</div>
							
							{(uiAttachmentRequests) ?
								<RequestedAttachment key={key} case={c} attachmentRequest={firstAttachmentRequest} />
								: null}
								<br/>
							{(this.props.attachments) ? 
								<ProvidedAttachments case={c} attachments={this.props.attachments} requestType={firstType} customerId={firstAttachmentRequest.customerId} allowDelete={true} />
								: null}
						</div>
					)
				})}

			</div>
		);
	}
}
