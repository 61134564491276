import React from 'react';
import {
	Household,
} from '../../../../../models/SelmaModels';
import { AppComponent } from '../../../../AppComponent';
import { T } from '../../../../widget/display/T';
import {
	FormUnit,
	FormUnitSize,
	FormUnitPadding,
} from '../../../../layout/FormUnit/FormUnit';
import Table from '../../../../widget/display/Table/Table';
import { Heading } from '../../../../widget/display/Heading/Heading';

interface Props {
	household: Household;
}

export class HouseholdChildrenSummary extends AppComponent<Props> {

	constructor(props: Props) {
		super(props);
	}


	render() {
		const h = this.props.household;
		if (!h.children || h.children.length===0) return null;

		return (
			<FormUnit size={FormUnitSize.Normal} padding={FormUnitPadding.Normal}>
				<Heading level={3} labelTextkey="Summary_Household_ChildrenAtHome"/>
				<Table
					headingRow={[
						<T key="Summary_Household_ChildAge" k="Summary_Household_ChildAge" />,
						<T key="Summary_Household_ChildTimePart" k="Summary_Household_ChildTimePart" />,
					]}
					tableRows={h.children.map(({ childsAge, partInHousehold }) => [
						childsAge?childsAge+ " år":"",
						partInHousehold===true ? (
							<T k="Summary_Household_PartTime" />
						) : (
							<T k="Summary_Household_FullTime" />
						),
					])}
				/>
			</FormUnit>
		);
	}
}
