import { BaseComponent } from './BaseComponent';
import { Application } from '../models/AppModels';
import { Objserver, ObsCallback } from '../utils/Objserver';

interface TExtendedState {
	refreshed?: Date;
}

export abstract class AppComponent<TProps = any, TState = any, TSS = any>
	extends BaseComponent<TProps, TState & TExtendedState, TSS>  {

	protected application: Application;

	constructor(props: TProps) {
		super(props);
		this.application = Application.instance;
	}

	onUnmount(func):void {
		let cwu = this.componentWillUnmount;
		this.componentWillUnmount = function () {
			if (cwu) cwu();
			func();
		}
	}

	observeUpdate(obj: any):void {
		this.specificObserveUpdate(
			Application.instance.services.stateService.stateObjserver, obj);
	}
	observeDeepUpdate(obj: any):void {
		this.specificObserveDeepUpdate(
			Application.instance.services.stateService.stateObjserver, obj);
	}

	observe(obj: any, func: ObsCallback):void {
		this.specificObserve(
			Application.instance.services.stateService.stateObjserver, obj, func);
	}
	observeDeep(obj: any, func: ObsCallback):void {
		this.specificObserveDeep(
			Application.instance.services.stateService.stateObjserver, obj, func);
	}

	protected refresh():void {
		// @ts-ignore
		this.setState({
			refreshed: new Date()
		});
	}

	private specificObserveUpdate(objserver: Objserver, obj: any):void {
		const t = this;
		this.observe(obj, (event) => {
			// @ts-ignore
			t.setState({
				refreshed: new Date()
			})
		})
	}
	private specificObserveDeepUpdate(objserver: Objserver, obj: any):void {
		const t = this;
		this.observeDeep(obj, (event) => {
			// @ts-ignore
			t.setState({
				refreshed: new Date()
			})
		})
	}

	private specificObserve(objserver: Objserver, obj: any, func: ObsCallback):void {
		if (!obj)
			return;
		let unobserve = objserver.observe(obj, func);
		this.onUnmount(unobserve);
	}
	private specificObserveDeep(objserver: Objserver, obj: any, func: ObsCallback):void {
		if (!obj)
			return;
		let unobserve = objserver.observeDeep(obj, func);
		this.onUnmount(unobserve);
	}

}
