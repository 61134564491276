import { BaseService } from './BaseService';
import { Case, PersonalEconomy, LPEmploymentEnum } from '../models/SelmaModels';
import { Applicant } from "../models/selmamodels/Applicant";
import { SMELPOService } from './SMELPOService';
import { StateService } from './StateService';
import { ValidationResult } from '../utils/ValidationResult';
import { GuardService } from './GuardService';
import { IdService } from './IdService';

export class PersonalEconomyService extends BaseService {
	private stateService: StateService;
	private smelpoService: SMELPOService;
	private guardService: GuardService;

	constructor(stateService: StateService, smelpoService: SMELPOService, guardService: GuardService, private idService:IdService) {
		super();
		this.stateService = stateService;
		this.smelpoService = smelpoService;
		this.guardService = guardService;
	}

	update(personalEconomy: PersonalEconomy|Case): void {
		this.stateService.update(personalEconomy);
	}

	
	addPersonalEconomy(c:Case, personalEconomy: PersonalEconomy): void {
		if( !c.personalEconomies )
			c.personalEconomies = [];

		c.personalEconomies.push(personalEconomy);
		this.update(c);
	}

	newPersonalEconomy(c:Case, applicant: Applicant): PersonalEconomy {
		const personalEconomy = {
			id: this.idService.newGuid(),
			processId: c.id,
			customerId: applicant.customerId
		}
		return personalEconomy;
	}

	ensurePersonalEconomy(c:Case, applicant: Applicant): PersonalEconomy {
		let personalEconomy = this.getPersonalEconomy(c, applicant);
		if( !personalEconomy ) {
			personalEconomy = this.newPersonalEconomy(c, applicant);
			this.addPersonalEconomy(c, personalEconomy);
		}
		return personalEconomy;
	}

	getPersonalEconomy(c:Case, applicant: Applicant): PersonalEconomy | undefined {
		if( !c.personalEconomies || c.personalEconomies.length===0 )
			return undefined;
		
		const r = c.personalEconomies.filter(x => x.customerId===applicant.customerId);
		if( r.length===1 )
			return r[0];
		return undefined;
	}

	setTypeOfEmployment(c:Case, personalEconomy: PersonalEconomy, typeOfEmployment:LPEmploymentEnum|undefined): void {
		if( personalEconomy.typeOfEmployment===typeOfEmployment) {
			return; // Already set.
		}

		personalEconomy.typeOfEmployment = typeOfEmployment;

		if( this.mayHaveIncomeOfEmployment(personalEconomy)) {
			if( !this.needsEmployerInformation(personalEconomy) ) {
				this.clearEmployerInformation(personalEconomy);
			}
		}

		this.update(personalEconomy);
	}

	needsIncomeOfEmployment(personalEconomy: PersonalEconomy): boolean {
		if( personalEconomy.typeOfEmployment===LPEmploymentEnum.PERMANENT
			|| personalEconomy.typeOfEmployment===LPEmploymentEnum.TEMPORARY
			|| personalEconomy.typeOfEmployment===LPEmploymentEnum.PROBATIONARY
			|| personalEconomy.typeOfEmployment===LPEmploymentEnum.PENSION	
			|| personalEconomy.typeOfEmployment===LPEmploymentEnum.STUDENT_AID
			|| personalEconomy.typeOfEmployment===LPEmploymentEnum.UNEMPLOYMENT_INSURANCE ) {
			return true;
		}
		return false;
	}

	mayHaveIncomeOfEmployment(personalEconomy: PersonalEconomy): boolean {
		if( personalEconomy.typeOfEmployment===LPEmploymentEnum.SELF_EMPLOYED ) {
			return true;
		}
		return false;
	}

	needsEmployerInformation(personalEconomy: PersonalEconomy): boolean {
		if( personalEconomy.typeOfEmployment===LPEmploymentEnum.PERMANENT
			|| personalEconomy.typeOfEmployment===LPEmploymentEnum.TEMPORARY
			|| personalEconomy.typeOfEmployment===LPEmploymentEnum.SELF_EMPLOYED
			|| personalEconomy.typeOfEmployment===LPEmploymentEnum.PROBATIONARY 
			) {
			return true;
		}
		return false;
	}

	setYearOfEmployment(personalEconomy: PersonalEconomy, year: number|undefined) {
		personalEconomy.yearOfEmployment = year;
		if ( !this.needsMonthOfEmployment(personalEconomy) ) {
			personalEconomy.monthOfEmployment = undefined;
		}
		this.update(personalEconomy);
	}

	needsMonthOfEmployment(personalEconomy: PersonalEconomy): boolean {
		if( !this.needsEmployerInformation(personalEconomy) )
			return false;

		const thisYear = new Date().getFullYear();
		if( personalEconomy.yearOfEmployment && personalEconomy.yearOfEmployment>=(thisYear-1) ) {
			return true;
		}
		return false;
	}

	clearEmploymentInformation(personalEconomy: PersonalEconomy):void {
		this.clearIncomeOfEmployment(personalEconomy);
		this.clearEmployerInformation(personalEconomy);
	}

	clearIncomeOfEmployment(personalEconomy: PersonalEconomy):void {
		personalEconomy.income = undefined;
		this.update(personalEconomy);
	}

	clearEmployerInformation(personalEconomy: PersonalEconomy):void {
		personalEconomy.employer = undefined;
		personalEconomy.yearOfEmployment = undefined;
		personalEconomy.monthOfEmployment = undefined;
		this.update(personalEconomy);
	}

	hasEmploymentInformation(personalEconomy: PersonalEconomy):boolean {
		const g = this.guardService;
		return g.hasValue(personalEconomy.income)
			|| g.hasValue(personalEconomy.employer)
			|| g.hasValue(personalEconomy.yearOfEmployment)
			|| g.hasValue(personalEconomy.monthOfEmployment);
	}

	getEmploymentDate(pe: PersonalEconomy):Date|undefined {
		if( !this.needsEmployerInformation(pe) )
			return;

		let year = pe.yearOfEmployment;
		if( !year )
			return;

		const needsMonthOfEmployment = this.needsMonthOfEmployment(pe);
		if( needsMonthOfEmployment && !pe.monthOfEmployment)
			return;

		let month = needsMonthOfEmployment?(pe.monthOfEmployment||1):1;

		let date = new Date(year, month, 1);
		return date;
	}

	needsProofOfEmployment(pe: PersonalEconomy):boolean {
		if( pe.typeOfEmployment===LPEmploymentEnum.PERMANENT ) {
			const date = this.getEmploymentDate(pe);
			const now = new Date();
			const limit = new Date(now.getFullYear()-1, now.getMonth(), now.getDate());
			if( date && this.guardService.isAfterDate(date, limit)) {
				return true;
			}
		}
		return false;
	}

	validateApplicantPersonalEconomy(c:Case, applicant: Applicant):ValidationResult {
		let vr = new ValidationResult();
		const hasPersonalEconomy = this.hasPersonalEconomy(c, applicant);
		vr.add({validator:this.hasPersonalEconomy, object:applicant, ok:hasPersonalEconomy});
		if( hasPersonalEconomy ) {
			let personalEconomy = this.getPersonalEconomy(c, applicant);
			if( personalEconomy)
				vr.addResult(this.validatePersonalEconomy(personalEconomy));
		}
		return vr;
	}
	hasPersonalEconomy(c:Case, applicant: Applicant): boolean {
		let r = this.getPersonalEconomy(c, applicant);
		return r ? true:false;
	}

	private validatePersonalEconomy(personalEconomy: PersonalEconomy):ValidationResult {
		let vr = new ValidationResult();
		let hasTypeOfEmployement = this.hasTypeOfEmployment(personalEconomy);
		vr.add({validator:this.hasTypeOfEmployment, object:personalEconomy, ok:hasTypeOfEmployement});

		if( this.needsIncomeOfEmployment(personalEconomy) ) {
			vr.add({validator:this.hasIncome, object:personalEconomy, ok:this.hasIncome(personalEconomy)});
			if( this.needsEmployerInformation(personalEconomy) ) {
				this.validateEmployerInformation(vr, personalEconomy);
			}
		}
		else if ( this.mayHaveIncomeOfEmployment(personalEconomy) ) {
			if( this.hasEmploymentInformation(personalEconomy)) {
				if( this.needsEmployerInformation(personalEconomy) ) {
					this.validateEmployerInformation(vr, personalEconomy);
				}
			}
		}

		return vr;
	}
	private validateEmployerInformation(vr:ValidationResult, personalEconomy: PersonalEconomy):void {
		vr.add({validator:this.hasEmployer, object:personalEconomy, ok:this.hasEmployer(personalEconomy)});
		vr.add({validator:this.hasEmploymentYear, object:personalEconomy, ok:this.hasEmploymentYear(personalEconomy)});
		if( this.needsMonthOfEmployment(personalEconomy) ) {
			vr.add({validator:this.hasEmploymentMonth, object:personalEconomy, ok:this.hasEmploymentMonth(personalEconomy)});
		}
	}
	hasTypeOfEmployment(personalEconomy: PersonalEconomy):boolean {
		const g = this.guardService;
		return g.hasValue(personalEconomy.typeOfEmployment);
	}
	hasIncome(personalEconomy: PersonalEconomy):boolean {
		const g = this.guardService;
		return g.isGreaterThanOrEqual(personalEconomy.income, 0);
	}
	hasEmployer(personalEconomy: PersonalEconomy):boolean {
		const g = this.guardService;
		return g.hasLengthGreaterThan(personalEconomy.employer, 0);
	}
	hasEmploymentYear(personalEconomy: PersonalEconomy):boolean {
		const g = this.guardService;
		const year = personalEconomy.yearOfEmployment;
		if( g.isNumber(year) && year && year > 1900 ) {
			const thisYear = new Date().getFullYear();
			if( year<=thisYear )
				return true;
		}
		return false;
	}
	hasEmploymentMonth(personalEconomy: PersonalEconomy):boolean {
		const g = this.guardService;
		const month = personalEconomy.monthOfEmployment;
		return g.isNumber(month) && month && month>=1 && month<=12 ? true:false;
	}
}
