import { Case } from '../../models/SelmaModels';
import { StepDefinition, StepService } from '../../services/StepService';
import { ValidationResult } from '../../utils/ValidationResult';

export interface StepState {
	step:StepDefinition;
	validationResult?: ValidationResult;
}

export class CaseStepper {

	definitions: StepDefinition[];
	definition?: StepDefinition;
	states:StepState[];
	switching: boolean = false;

	onStepped?: (stepState: CaseStepper, c: Case, step?: StepDefinition) => void;

	constructor(private stepService: StepService, private c: Case) {
		this.definitions = [];
		this.states = [];
		this.initStepDefinitions();
		this.initToStart();
	}

	initStepDefinitions = ():void => {
		this.definitions = this.stepService.getStepDefinitions(this.c);
		this.states = this.definitions.map(x => {
			return {
				step:x,
				validationResult: undefined
			}
		})
	}

	private initToStart = ():void => {
		this.definition =  this.definitions[0];
		this.definition = this.stepService.getStartStep(this.c);
		this.switching = false;
	}

	goToFirst = ():void => {
		let next =  this.definitions[0];
		if (next)
			this.tryStepTo(next);
	}

	goPrev = ():void => {
		let step = this.definition;
		if( !step )
			return;
		let ix = this.definitions.indexOf(step);
		let prev = this.getStep(ix - 1);
		if (prev)
			this.tryStepFromTo(step, prev);
	};

	goNext = ():void => {
		let step = this.definition;
		if( !step )
			return;
		let ix = this.definitions.indexOf(step);
		let next = this.getStep(ix + 1);
		if (next)
			this.tryStepFromTo(step, next);
	};

	goTo(stepToGoTo: StepDefinition):void {
		let step = this.definition;
		let next = stepToGoTo; //this.getStep(stepToGoTo.ordinal);
		if( step===next )
			return;
		if (next) {
			if( step )
				this.tryStepFromTo(step, next);
			else 
				this.tryStepTo(next);
		}
	}

	validateCurrent():void {
		if( this.definition)
			this.validate(this.definition);
	}
	validate(step:StepDefinition):void {
		let state = this.states.find(x => x.step===step);
		if( state ) {
			state.validationResult = this.stepService.validateStep(step, this.c);
		}
	}

	clearCurrentValidation():void {
		if( this.definition)
			this.clearValidation(this.definition);
	}
	clearValidation(step:StepDefinition):void {
		let state = this.states.find(x => x.step===step);
		if( state ) {
			state.validationResult = undefined;
		}
	}

	getCurrentValidation():ValidationResult|undefined {
		if( this.definition )
			return this.getValidation(this.definition);
	}
	getValidation(step:StepDefinition):ValidationResult {
		let state = this.states.find(x => x.step===step);
		if(state) {
			if( !state.validationResult ) {
				state.validationResult = this.stepService.validateStep(step, this.c);
			}
			return state.validationResult;
		}
		return new ValidationResult();
	}



	private tryStepFromTo(from: StepDefinition, to: StepDefinition) : Promise<any> {
		if( !this.canLeaveStep(from) )
			return Promise.resolve(null);;
		if( !this.canGoToStep(to) )
			return Promise.resolve(null);
		this.validate(from); 
		this.setLoading(true);
		let promise = this.saveStep(from)
		.then(result => {
			this.setStep(to);
			this.validate(to); 
			this.setLoading(false);
		}, error => {
			this.setLoading(false);
		});
		return promise;
	}
	private tryStepTo(to: StepDefinition):void {
		if( !this.canGoToStep(to) )
			return;
		this.setLoading(true);
		this.setStep(to);
		this.validate(to);
		this.setLoading(false);
	}
	private setLoading(enabled: boolean): void {
		this.switching = enabled;
		this.triggerStepped();
	}
	private canLeaveStep(step: StepDefinition): boolean {
		return this.stepService.canLeaveStep(step, this.c);
	}
	private canGoToStep(step: StepDefinition): boolean {
		return this.stepService.canGoToStep(step, this.c);
	}
	private saveStep(step: StepDefinition): Promise<any> {
		return this.stepService.saveStep(step, this.c);
	}
	private setStep(step: StepDefinition): void {
		this.definition = step;
		this.triggerStepped();
	}
	// private getStepByOrdinal(ordinal: number): StepDefinition | undefined {
	// 	if (ordinal < 1)
	// 		return undefined;
	// 	let a = this.definitions.filter((x) => x.ordinal === ordinal);
	// 	if (a.length === 1)
	// 		return a[0];
	// 	return;
	// }
	private getStep(index: number): StepDefinition | undefined {
		if (index < 0)
			return undefined;
		if (index >= this.definitions.length)
			return undefined;
		let a = this.definitions[index];
		return a;
	}
	private triggerStepped() {
		if (this.onStepped) {
			this.onStepped(this, this.c, this.definition);
			window.scrollTo(0, 0);
		}
	}
}
