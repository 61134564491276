import React from 'react';
import ClassNames from 'classnames';
import NumberFormat, { NumberFormatValues } from 'react-number-format';
import { ValueType } from '../../../../models/uimodels/UIBudgetYearItem';

import './Money.scss';

interface Props {
	className?: string;

	displayType?: 'input' | 'text';
	enabled?: boolean;
	handleBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
	handleChange: (values: NumberFormatValues) => void;
	id?: string;
	max?: number;
	min?: number;
	name?: string;
	placeholder?: string;
	suffix?: string;
	thousandGroupStyle?: 'thousand' | 'lakh' | 'wan';
	thousandSeparator?: boolean | string;
	type?: string;
	value?: number;
	valueType?: ValueType;
	hasErrors?: boolean;
	touched?: boolean;
	dirty?: boolean;
}

export const Money: React.FunctionComponent<Props> = (props) => {
	let value = props.value;

	if (value !== undefined) {
		switch (props.valueType) {
			case 'positive':
				if (value < 0) value = value * -1;
				break;
			case 'negative':
				if (value > 0) value = value * -1;
				break;
		}

		if (props.min !== undefined && value < props.min) {
			value = props.min;
		} else if (props.max !== undefined && value > props.max) {
			value = props.max;
		}
	}

  const classNames = ClassNames(props.className, {
		error: props.touched && props.hasErrors,
	});

	return (
		<NumberFormat
			className={classNames}
			id={props.id}
			value={value}
			onValueChange={props.handleChange}
			onBlur={props.handleBlur}
			thousandSeparator={props.thousandSeparator}
			thousandsGroupStyle={props.thousandGroupStyle}
			decimalScale={0}
			suffix={props.suffix}
			placeholder={props.suffix}
			displayType={props.displayType}
			max={props.max}
			min={props.min}
			disabled={props.enabled===false?true:false}
		/>
	);
};

Money.defaultProps = {
	className: 'Money',
	type: 'text',
	thousandSeparator: ' ',
	thousandGroupStyle: 'thousand',
	suffix: ' kr',
	displayType: 'input',
	valueType: ValueType.both,
	enabled: true,
	touched: false,
	dirty: false,
	hasErrors: false,
};
