/* tslint:disable */
/* eslint-disable */
/**
 * SelmaSME API
 * This definition is the API between customer SME-Gui and the persistence layer for the application NOT FOR V1 When customer logg-in to Selma-EN, we will create a processId with applicant CustomerId (from BankID). When a customer added attachment to the application, we don\'t have a CaseId i LP, should we add a LP-Service to create/reserve a caseId to be able to attach a document to the caseId in DM.
 *
 * The version of the OpenAPI document: 0.7.6
 * Contact: developer@landshypotek.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * TBD
 * @export
 * @interface BusinessCategoryType
 */
export interface BusinessCategoryType {
    /**
     * unique identity for businessCategoryType
     * @type {string}
     * @memberof BusinessCategoryType
     */
    businessId?: string;
    /**
     * Huvudrubrik för driftinriktning
     * @type {string}
     * @memberof BusinessCategoryType
     */
    businessCategory?: string;
    /**
     * Del av huvudsaklig driftinriktning
     * @type {number}
     * @memberof BusinessCategoryType
     */
    businessPart?: number;
    /**
     * Vald huvuddrifitinriktning av kund, kan vara vara ett val oavsett antal driftinriktningar.
     * @type {boolean}
     * @memberof BusinessCategoryType
     */
    mainBusiness?: boolean;
}

/**
 * Check if a given object implements the BusinessCategoryType interface.
 */
export function instanceOfBusinessCategoryType(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function BusinessCategoryTypeFromJSON(json: any): BusinessCategoryType {
    return BusinessCategoryTypeFromJSONTyped(json, false);
}

export function BusinessCategoryTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): BusinessCategoryType {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'businessId': !exists(json, 'businessId') ? undefined : json['businessId'],
        'businessCategory': !exists(json, 'businessCategory') ? undefined : json['businessCategory'],
        'businessPart': !exists(json, 'businessPart') ? undefined : json['businessPart'],
        'mainBusiness': !exists(json, 'mainBusiness') ? undefined : json['mainBusiness'],
    };
}

export function BusinessCategoryTypeToJSON(value?: BusinessCategoryType | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'businessId': value.businessId,
        'businessCategory': value.businessCategory,
        'businessPart': value.businessPart,
        'mainBusiness': value.mainBusiness,
    };
}

