import React from 'react';
import { Case, Attachment } from '../../../../models/SelmaModels';
import { AppComponent } from '../../../AppComponent';
import { Button } from '../../../widget/input/Button/Button';
import { DeleteButton } from '../../../widget/input/DeleteButton/DeleteButton';
import './ProvidedAttachment.scss';

interface Props {
	case: Case;
	attachment:Attachment;
	allowDelete?:boolean;
	showType?: boolean;
}

export class ProvidedAttachment extends AppComponent<Props> {
	attachmentService = this.application.services.attachmentService;

	constructor(props: Props) {
		super(props);
	}

	downloadFile = () : void => {
		this.attachmentService.downloadAttachment(this.props.case, this.props.attachment.id);
	}

	deleteFile = () : void => {
		this.attachmentService.removeAttachment(this.props.case, this.props.attachment.id);
	}

	render() {
		const attachment = this.props.attachment;
		const canDelete = true;
		return (
			<tr className="ProvidedAttachment">
				{this.props.showType && 
				<td>
					{this.attachmentService.getAttachmentTypeDisplayName(attachment)}
				</td>
				}
				<td>
					<Button style={'link'} label={attachment.fileName}
						className="Button blue download-icon" 
						onClick={this.downloadFile} /></td>
				<td className="align-right">{this.application.services.convertService.formatBytes(attachment.fileSize)}</td>
				{ this.props.allowDelete &&
					<td className="align-right">
						<DeleteButton labelTextkey="Budget_ProvidedAttachments_Remove" onClick={this.deleteFile} enabled={canDelete}/>
					</td>
				}
			</tr>
		);
	}
}
