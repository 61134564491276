import React from 'react';
import { Applicant as SelmaApplicant, Case, Applicant, Company } from '../../../../models/SelmaModels';
import { AppComponent } from '../../../AppComponent';
import { FormUnit } from '../../../layout/FormUnit/FormUnit';
import { ApplicantContact } from './ApplicantContact';
import { CompanyRevenueChunk } from '../StepCompany/CompanyRevenueChunk';
import { DeleteButton } from '../../../widget/input/DeleteButton/DeleteButton';
import { Heading } from '../../../widget/display/Heading/Heading';
import { HeaderButton, HeaderButtonLayout } from '../../../layout/HeaderButton/HeaderButton';
import { ApplicantApproval } from '../StepLoan/ApplicantApproval';
import { UiModelConverter } from '../../../../models/UiModelConverter';

interface Props {
	case: Case;
	applicant: SelmaApplicant;
	company: Company;
	removeApplicant: (applicant: Applicant) => void;
}

export class IncorporatedCompanyApplicantItem extends AppComponent<Props> {
	myApplicant: SelmaApplicant;

	constructor(props: Props) {
		super(props);

		this.observeUpdate(this.props.applicant);
		this.observeUpdate(this.props.company);

		this.myApplicant = this.application.services.applicantService.getMyApplicant(this.props.case);
	}

	componentDidMount() {
		const c = this.props.case;
		const company = this.props.company;
		const companyEconomyService = this.application.services.companyEconomyService;
		companyEconomyService.ensureCompanyEconomy(c, company);
	}


	render() {
		const companyService = this.application.services.companyService;
		const companyEconomyService = this.application.services.companyEconomyService;
		const applicantService = this.application.services.applicantService;

		const c = this.props.case;
		const applicant = this.props.applicant;
		const company = this.props.company;
		const companyEconomy = companyEconomyService.getCompanyEconomy(c, company);

		const isMe = this.props.applicant===this.myApplicant;

		const companyDisplayName = companyService.getDisplayName(company);

		const canRemove = applicantService.canRemoveApplicant(c, applicant); 

		return (
			<div className="IncorporatedCompanyApplicantItem">
				<HeaderButton layout={HeaderButtonLayout.Stretch}>
					<Heading level={2} labelText={companyDisplayName}/>
					{canRemove &&
						<DeleteButton labelTextkey="Applicant_RemoveApplicant"onClick={() => {this.props.removeApplicant(applicant);}}/>
					}
				</HeaderButton>

				<div className="HeaderButton Stretch">
					<Heading level={3} labelText={UiModelConverter.prettyOrgNr(company.orgNumber)}/>
				</div>

				<FormUnit>
					<ApplicantContact applicant={this.props.applicant}/>
				</FormUnit>

				{ !isMe && companyEconomy &&
				<CompanyRevenueChunk applicant={applicant} company={company} companyEconomy={companyEconomy} />
				}
				{ !isMe &&
				<ApplicantApproval applicant={this.props.applicant}/>
				}
			</div>
		);
	}
}
