import { IdService } from "../services/IdService";

import { Case, Company, Loan, Aim, Applicant, CompanyEconomy, 
	Budget, Revenue, EUSupport, KYC, PersonalEconomy, LPEmploymentEnum, Guarantor,
	EUSupportEnum as SmeEUSupportType, BudgetYear as SmeBudgetYear,
	ExtLoan, Household, MaintenanceCost, MaintenanceHouse, 
	Child, HouseholdMember, ExtLoanOwner, LoanOwner, DownpaymentSource, BusinessCategory, Collateral, TaxedOwner, Principal } from "./SelmaModels";

import { CompanyType, LoanType, AimType, KycInformationType, ApplicantType, ProcessType, 
	CaseIdStatus, LinksAll, CompanyEconomyType, BudgetType, EUType,
	RevenueType, EUSupportType, PersonalEconomyType, HouseholdType, ChildType, HouseholdMemberType, 
	GuarantorType, ExtLoanType, TypeOfEmployeement, MaintenanceCostType, MaintenanceHouseType, 
	TypeOfHouse, DownPaymentType, BusinessCategoryType, BudgetYearType, CollateralType, TaxedOwnerType, PrincipalType, TakeoverLoanType} from "../libs/SMELPOClient";

import { LoanOwnerType } from "../libs/SMELPOClient/models/LoanOwnerType";
import { AttachmentRequest, Attachment } from "./selmamodels/Attachment";
import { LpoAttachmentRequest, LpoAttachment } from "./extendedlpo/Attachment";
import { TakeoverLoan } from "./selmamodels/Loan";


export class LpoModelConverter {
	static idService = new IdService();

	static toCase(process:ProcessType) : Case {
		let createdDate = this.fromDateString(process.processCreatedDate);
		let lastAccessedDate = this.fromDateString(process.lastAccessed);
		let c: Case = {
			id: process.processId,
			number: process.referenceId||0,
			version: process.processVersion||'v1',
			lpCaseId: process.caseId,
			status: process.caseIdStatus?process.caseIdStatus:CaseIdStatus.STARTEDBYAPPLICANT,
			customers: process.customers||[],
			createdDate: createdDate?createdDate:new Date(),
			kycApplicantsStatus: [],
			lastAccessedDate: lastAccessedDate?lastAccessedDate:new Date(),
		}
		return c;
	}

	static fromDateString(str: string | undefined): Date|undefined {
		if( !str )
			return;

		let date = new Date(str);
		return date;
	}

	static linksAllToCase(linksAll:LinksAll) : Case {
		if( !linksAll.processes ||linksAll.processes.length!==1 )
			throw 'Not single links all'
		let process = linksAll.processes[0];

		let c: Case = this.toCase(process);
		c.applicants = this.toApplicants(linksAll.applicants, linksAll.kycinformations);
		c.loan = linksAll.loans?this.toLoan(linksAll.loans[0]):undefined;
		c.companies = this.toCompanies(linksAll.companies);
		c.companyEconomies = this.toCompanyEconomies(linksAll.companyeconomies);
		c.collaterals = this.toCollaterals(linksAll.collaterals);
		c.euSupports = this.toEuSupports(linksAll.eusupports);
		c.extLoans = this.toExtLoans(linksAll.extloans);
		c.budgets = this.toBudgets(linksAll.budgets);
		c.guarantors = this.toGuarantors(linksAll.guarantors);
		c.households = this.toHouseholds(linksAll.households);
		c.personalEconomies = this.toPersonalEconomies(linksAll.personaleconomies);
		c.maintenanceCosts = this.toMaintenanceCosts(linksAll.maintenancecosts);
		c.attachments = this.toAttachments((<any>linksAll).attachments);
		c.attachmentRequests = this.toAttachmentRequests((<any>linksAll).attachmentRequests);
		return c;
	}
	static toCollaterals(collaterals: CollateralType[] | undefined): Collateral[] | undefined {
		if( !collaterals) return;
		return collaterals.map(x => {
			return {
				id: x.collateralId,
				customerId: x.customerId,
				code: x.collateralCode, 
				name: x.collateralName, 
				municipality: x.collateralMunicipality,
				street: x.collateralStreet,
				//age: x.collateralAge,
				areal: x.collateralAreal,
				useAsCollateral: x.useAsCollateral,
				buyCollateral: x.buyCollateral,
				taxedOwners: this.toTaxedOwners(x.taxedOwners)
			}
		})
	}
	static toTaxedOwners(taxedOwners: TaxedOwnerType[] | undefined): TaxedOwner[]|undefined {
		if( !taxedOwners) return;
		return taxedOwners.map(x => {
			return {
				id: x.taxedOwnerId,
				customerId: x.taxedOwner
			}
		})
	}
	static toBudgets(budgets: BudgetType[] | undefined): Budget[] | undefined {
		if( !budgets ) return;
		return budgets.map(x => {
			return this.toBudget(x);
		})
	}
	static toAttachmentRequests(attachmentRequests: LpoAttachmentRequest[]|undefined): AttachmentRequest[] | undefined {
		if( !attachmentRequests) return;
		return attachmentRequests.map(x => {
			return {
				id: x.id,
				customerId: x.customerId,
				requestType: x.requestType,
				requestVariation: x.requestVariation,
				required: x.required
			}
		})
	}
	static toAttachments(attachments: LpoAttachment[]| undefined): Attachment[] | undefined {
		if( !attachments) return;
		return attachments.map(x => {
			return {
				id: x.id,
				customerId: x.customerId,
				requestType: x.requestType,
				requestVariation: x.requestVariation,
				mimeType: x.mimeType,
				fileName: x.fileName,
				fileSize: x.fileSize
			}
		})
	}
	static toMaintenanceCosts(maintenancecosts: MaintenanceCostType[] | undefined): MaintenanceCost[] | undefined {
		if( !maintenancecosts)
			return;
		return maintenancecosts.map(x=> {
			return this.toMaintenanceCost(x);
		})
	}
	static toMaintenanceCost(x: MaintenanceCostType): MaintenanceCost {
		return {
			id: x.maintenanceCostId,
			customerId: x.customerId,
			typeofhouses: this.toMaintenanceHouses(x.typeofhouses)
		}
	}
	static toMaintenanceHouses(typeofhouses: MaintenanceHouseType[] | undefined): MaintenanceHouse[] | undefined {
		if( !typeofhouses )
			return;
		return typeofhouses.map(x=> {
			return this.toMaintenanceHouse(x);
		})
	}
	static toMaintenanceHouse(x: MaintenanceHouseType): MaintenanceHouse {
		return {
			houseType: x.typeOfHouse, //this.toHouseTypeCode(x.typeOfHouse),
			keepHouse: x.keepHouse,
			loanInOtherInstitute: x.loanInOtherInstitute,
			redeemLoan: x.redeemLoan,
			loanOwners: this.toLoanOwners(x.loanOwners),
			houseLoanAmount: x.houseLoanAmount,
			maintenanceCost: x.maintenanceCost 
		}
	}
	static toLoanOwners(extLoanOwners: LoanOwnerType[] | undefined): LoanOwner[] | undefined {
		if( !extLoanOwners)
			return;
		return extLoanOwners.map(x => {
			return {
				customerId : x.customerId||'',
				ownershipPart: x.ownershipPart
			}
		})
	}
	// static toHouseTypeCode(houseType: TypeOfHouse | undefined): string | undefined {
	// 	if( !houseType ) return;
	// 	if( houseType==TypeOfHouse.Villa) return 'HOUSE';
	// 	if( houseType==TypeOfHouse.VrigtBoende) return 'OTHER';
	// 	if( houseType==TypeOfHouse.Hyresrtt) return 'TENANCY';
	// 	if( houseType==TypeOfHouse.Landbruksfastighet) return 'FARM';
	// 	if( houseType==TypeOfHouse.Bostadsrtt) return 'APARTMENT';
	// 	if( houseType==TypeOfHouse.Fritidshus) return 'VACATIONHOUSE';
	// }
	static toPersonalEconomies(personaleconomies: PersonalEconomyType[] | undefined): PersonalEconomy[] | undefined {
		if( !personaleconomies)
			return;
		return personaleconomies.map(x=> {
			return this.toPersonalEconomy(x);
		})
	}
	static toPersonalEconomy(x: PersonalEconomyType): PersonalEconomy {
		return {
			id: x.personalEconomyId,
			customerId: x.customerId, // guid
			//sapEmployment?: SAPEmploymentEnum; // originally defined in Applicant
			//lpEmployment?: LPEmploymentEnum; // originally defined in Applicant
			income: x.income, // // SEK / month
			yearlyIncome: x.yearlyIncome, // SEK / year
			typeOfEmployment: this.toTypeOfEmployment(x.typeOfEmployeement), // EmployeementType;
			employer: x.employeer, // name of company
			yearOfEmployment: x.yearOfEmployment,
			monthOfEmployment: x.monthOfEmployment
		}
	}
	static toTypeOfEmployment(typeOfEmployeement: TypeOfEmployeement | undefined): LPEmploymentEnum | undefined {
		const t = typeOfEmployeement;
		if( !t )
			return;

		if( t===TypeOfEmployeement.PERMANENT) return LPEmploymentEnum.PERMANENT; // - Anställd, tills-vidare-anställd 
		if( t===TypeOfEmployeement.TEMPORARY) return LPEmploymentEnum.TEMPORARY; // - Tidbegränsad anställning 
		if( t===TypeOfEmployeement.PROBATIONARY) return LPEmploymentEnum.PROBATIONARY; // - Provanställning 
		if( t===TypeOfEmployeement.UNEMPLOYMENTINSURANCE) return LPEmploymentEnum.UNEMPLOYMENT_INSURANCE; // - Arbetslöshetskassa 
		if( t===TypeOfEmployeement.SELFEMPLOYED) return LPEmploymentEnum.SELF_EMPLOYED; //- Eget företag 
		if( t===TypeOfEmployeement.STUDENTAID) return LPEmploymentEnum.STUDENT_AID; // - Studiestöd 
		if( t===TypeOfEmployeement.PENSION) return LPEmploymentEnum.PENSION; // - Inkomst av pension
	}
	static toHouseholds(households: HouseholdType[] | undefined): Household[] | undefined {
		if( !households)
			return;
		return households.map(x=> {
			return this.toHousehold(x);
		})
	}
	static toHousehold(x: HouseholdType): Household {
		return {
			id: x.householdId,
			members: this.toHouseholdMembers(x.householdMembers),
			numberOfChildrenAtHome: x.numberOfChildsAtHome, // integer
			children: this.toChildren(x.childs),
			numberOfCars: x.numberOfCars, // integer
			childMaintenanceCost: x.childMaintenaceCost, // Money, SEK/year
			childMaintenanceIncome: x.receiveChildMaintenanceCost, // Money, SEK/year
			childCareAmount: x.childCareAmount
		}
	}
	static toChildren(childs: ChildType[] | undefined): Child[] | undefined {
		if( !childs )
			return;
		return childs.map(x => {
			return {
				id: x.childId,
				childsAge:x.childsAge,
				partInHousehold:x.partInHousehold
			}
		})
	}
	static toHouseholdMembers(householdMembers: HouseholdMemberType[] | undefined): HouseholdMember[] | undefined {
		if( !householdMembers )
			return;
		
		return householdMembers.map(x => {
			return this.toHouseholdMember(x);

		})
		
	}
	static toHouseholdMember(x: HouseholdMemberType): HouseholdMember {
		return {
			//id: x.householdMemberId||'',  // TODO: should not be undefined-able.
			householdMember: x.householdMember
		}
	}
	static toGuarantors(guarantors: GuarantorType[] | undefined): Guarantor[] | undefined {
		if( !guarantors)
			return;
		return guarantors.map(x=> {
			return this.toGuarantor(x);
		})
	}
	static toGuarantor(x: GuarantorType): Guarantor {
		return {
			id: x.guarantorId||'',
			name: x.guarantorName,
			phone: x.guarantorPhone,
			guarantorCustomerId: x.guarantorCustomerId	
		}
	}
	static toExtLoans(extloans: ExtLoanType[] | undefined): ExtLoan[] | undefined {
		if( !extloans)
			return;
		return extloans.map(x => {
			return this.toExtLoan(x);
		})
	}
	static toExtLoan(x: ExtLoanType): ExtLoan {
		return {
			id: x.extloanId,
			type: x.typeOfLoan,
			loanOwners: this.toExtLoanOwners(x.extLoanOwners),
			creditInstitute: x.extCreditInstitute,
			loanClearing: x.extloanClearing, // extloanClearing
			loanNumber: x.extloanNumber, // extloanNumber
			amount: x.extloanAmount, // SEK, extloanAmount
			monthlyCost: x.extMonthlyCost,
			redeemLoan: x.extRedeemLoan // extRedeemLoan, skall lånet lösas?
		}
	}
	static toExtLoanOwners(extLoanOwners: string[] | undefined): ExtLoanOwner[] | undefined {
		if( !extLoanOwners)
			return;
		return extLoanOwners.map(x => {
			return {
				customerId : x
			}
		})
	}
	static toEuSupports(euSupports: EUSupportType[] | undefined): EUSupport[] | undefined {
		if( !euSupports )
			return;
		return euSupports.map(x => {
			return this.toEuSupport(x);
		})
	}
	static toEuSupport(euSupport: EUSupportType): EUSupport {
		const e = euSupport;
		return {
				id: e.euId,
				euType: this.toEUSupportType( e.euType),
				supportAmount: e.supportAmount,
				supportYear: e.supportYear,
		}
	}
	static toEUSupportType(euType: EUType | undefined): SmeEUSupportType | undefined {
		if( !euType)
			return;
		if( euType==EUType.EUStd)
			return SmeEUSupportType.EU;
		if( euType==EUType.EUStdOchNationelltStd)
			return SmeEUSupportType.EUNAT;
		if( euType==EUType.EUStdOchNationelltStdUtanMjlk)
			return SmeEUSupportType.EUNAT_NOMILK;
		if( euType==EUType.NationellStdUtanMjlk)
			return SmeEUSupportType.NAT_NOMILK;
	}
	static toCompanyEconomies(companyeconomies: CompanyEconomyType[] | undefined): CompanyEconomy[] | undefined {
		if( !companyeconomies )
			return;
		return companyeconomies.map(x=> {
			//let budget:BudgetType|undefined = this.getBudgetTypeByCompanyEconomyType(budgets, x);
			return this.toCompanyEconomy(x);
		})
	}
	static toCompanyEconomy(companyEconomy: CompanyEconomyType): CompanyEconomy {
		const cet = companyEconomy;
		const ce:CompanyEconomy = {
			id: cet.companyEconomyId,
			companyId: cet.companyId,
			customerCategory: cet.customerCategory,
			revenues: this.toRevenues(cet.revenues), // NOT IN USE.
		}
		return ce;
	}
	static toBudget(budget: BudgetType):Budget {
		return {
			companyEconomyId: budget.companyEconomyId||'',
			budgetYears: this.toBudgetYears(budget.budgetYears) // TODO: LHB must fix.
		}
	}
	static toBudgetYears(budgetYears: BudgetYearType[] | undefined): SmeBudgetYear[] | undefined {
		if( !budgetYears ) return;
		return budgetYears.map(x => {
			return this.toBudgetYear(x);
		})
	}
	static toBudgetYear(x: BudgetYearType): SmeBudgetYear {
		let v = x.budget||{};
		return {
			budgetId: x.budgetId,
			year: x.budgetyear,
			value1:  v.value1,
			value2:  v.value2,
			value3:  v.value3,
			value4:  v.value4,
			value5:  v.value5,
			value6:  v.value6,
			value7:  v.value7,
			value8:  v.value8,
			value9:  v.value9,
			value10: v.value10,
			value11: v.value11,
			value12: v.value12,
			value13: v.value13,
			value14: v.value14,
			value15: v.value15,
			value16: v.value16,
			value17: v.value17,
			value18: v.value18,
			value19: v.value19,
			value20: v.value20,
			value21: v.value21,
			value22: v.value22,
			value23: v.value23,
			value24: v.value24,
			value25: v.value25
		}
	}
	static toRevenues(revenues: RevenueType[] | undefined): Revenue[] | undefined {
		if( !revenues) return;
		return revenues.map(x => {
			return {
				year: x.revenueYear,
				revenue: x.revenue
			}
		})
	}

	static toLoan(loan: LoanType): Loan {

		let tol:TakeoverLoanType[]|undefined = loan.takeoverloans;
		// if( loan.takeoverloans && loan.takeoverloans.takeoverloans) {
		// 	tol = loan.takeoverloans.takeoverloans;
		// }
		
		return {
			id: loan.loanId,
			number: loan.loanNumber,
			amount: loan.loanAmount, // obsolete
			aims: this.toAims(loan.aims),
			takeoverLoans: this.toTakeoverLoans(tol), // TODO.
			takeOver: loan.loanTakeOver, // obsolete
			downpaymentOther: loan.downPaymentOther,
			downpaymentType: this.toDownpaymentSource(loan.downPaymentType),
			downPaymentAmount: loan.downPaymentAmount,
			purposeDescription: loan.purposeDescription
		}
	}
	static toDownpaymentSource(downPaymentType: DownPaymentType | undefined): DownpaymentSource | undefined {
		const d = downPaymentType;
		if( d==DownPaymentType.Blanco)
			return DownpaymentSource.BLANCO_LOAN;
		else if( d==DownPaymentType.FrsljningBostad)
			return DownpaymentSource.SALE_OF_PROPERTY;
		else if( d==DownPaymentType.Sparande)
			return DownpaymentSource.SAVINGS;
		else if( d==DownPaymentType.Vrigt)
			return DownpaymentSource.OTHER;
		else
			return;
	}
	static toAims(aims: AimType[] | undefined): Aim[] | undefined {
		if( !aims ) return;
		return aims.map(x => {
			const aimCategory:string|undefined = x.purposeText;
			const aimDetail:string|undefined = x.aimText;
			return <Aim> {
				id: x.aimId,
				loanAmountPart: x.loanAmountPart,
				aimCategory: aimCategory,
				aimDetail: aimDetail
			}
		})
	}
	static toTakeoverLoans(takeoverLoans: TakeoverLoanType[] | undefined): TakeoverLoan[] | undefined {
		if( !takeoverLoans ) return;
		return takeoverLoans.map(x => {
			const aimCategory:string|undefined = x.purposeText;
			const aimDetail:string|undefined = x.aimText;
			return <TakeoverLoan> {
				id: x.takeoverLoanId,
				aimCategory: aimCategory,
				aimDetail: aimDetail,
				debtAmount: x.debtAmount,
				creditInstitute: x.creditInstitute,
				loanNumber: x.loanNumber
				//interest: x.interest,
				//period: x.period
			}
		})
	}
	// static toMainPurpose(purposeOfLoan: MainPurposes | undefined): SmeMainPurposes | undefined {
	// 	if( !purposeOfLoan )
	// 		return;
	// 	if( purposeOfLoan==MainPurposes.Kp)
	// 		return SmeMainPurposes.BUY;
	// 	if( purposeOfLoan==MainPurposes.UtkningLn)
	// 		return SmeMainPurposes.EXTENSION;
	// 	if( purposeOfLoan==MainPurposes.UtlningMotObelnat)
	// 		return SmeMainPurposes.UNPLEDGED;
	// 	if( purposeOfLoan==MainPurposes.Vertag)
	// 		return SmeMainPurposes.TAKEOVER;
	// }
	static toApplicants(applicants: ApplicantType[] | undefined, kycs:KycInformationType[]|undefined): Applicant[] | undefined {
		if( !applicants )
			return;

		return applicants.map(x => {
			let kycResult = kycs?kycs.filter(k => k.customerId==x.customerId):[];
			let kyc = kycResult.length==1?kycResult[0]:undefined;
			return this.toApplicant(x, kyc);
		});
	}

	static toApplicant(applicant:ApplicantType, kyc?:KycInformationType|undefined) : Applicant {
		const x = applicant;

		let firstName = x.applicantName||'';
		let lastName;
		let spPos = firstName.lastIndexOf(' ');
		if( spPos>0 ) {
			lastName = firstName.slice(spPos).trim();
			firstName = firstName.slice(0,spPos).trim();
		}

		let email = x.contactInformation?x.contactInformation.applicanteMail:undefined;

		let a:Applicant = {
			id: x.applicantId,
			firstName: firstName,
			lastName: lastName,
			customerId: x.customerId,
			streetAddress: x.applicantAddress,
			postalAddress: x.applicantPostAddress,
			postalCode: x.applicantPostalCode,
			email: email,
			emailConfirm: email,
			mobilePhone: x.contactInformation?x.contactInformation.applicantMobileNumber:undefined,
			stakeholderType: x.stakeholderType,
			kyc: this.toKyc(kyc),
			notifyByEmail: x.applicantByeMail,
			notifyByMobilePhone: x.applicantBySms,
			willBuyAgriForestryProperty: x.applicantCollateralToBuy
		}
		return a;
	}
	static toApplicantOrUndefined(applicant:ApplicantType|undefined, kyc?:KycInformationType|undefined) : Applicant|undefined {
		if( !applicant )
			return; 
		return this.toApplicant(applicant, kyc);
	}
	static toKyc(kyc: KycInformationType | undefined): KYC | undefined {
		if( !kyc )
			return undefined;
		return {
			id:kyc.kycId||'',
			highRiskCategoryCode: kyc.kycHighRiskIndustryText,
			approvedInfoSharing: kyc.kycUCAware, // ucAware
			approvedCreditCheck: kyc.kycAcceptUC, // ucAccepted
			approvedInfoHandling: kyc.kycAcceptGDPR, // gdprAccepted
			approvedInfoSharingDB: kyc.kycUCAware, // ucAware
			approvedCreditCheckDB: kyc.kycAcceptUC, // ucAccepted
			approvedInfoHandlingDB: kyc.kycAcceptGDPR, // gdprAccepted			
			isPep: kyc.kycPublicFunction,
			//isPepFunction: kyc.kycRelatedPublicFunction,
			isPepRelated: kyc.kycRelatedPublicFunction,
			//isPepRelatedFunction: kyc.kycRelatedPublicFunction,
			isAmericanCitizen: kyc.kycAmericanCitizen,
			isCrsTaxObligated: kyc.kycCrsTaxObligation
		}
	}

	static toCompany(company:CompanyType|undefined) : Company|undefined {
		if( !company )
			return;
		const x = company;
		let c:Company = {
			id: x.companyId,
			orgNumber: x.orgNumber,
			name: x.companyName,
			statusCode: x.statusCode,
			highRiskCode: x.statusTextHigh,
			created:x.created?new Date(x.created):undefined,
			businessFocuses: this.toBusinessCategories(x.businessFocuses),
			industriCode: x.industriCode,
			industriText: x.industriText,
			//selectedCompany: x.selectedCompany,
			legalGroupCode: x.leagalGroupCode,
			legalGroupText: x.legalGroupText,
			brokenFiscalYear: x.brokenFiscalYear,
			fiscalYearEndDate: x.fiscalYearEndDate,
			principals: this.toPrincipals(x.principals)
		}
		return c;
	}

	static toPrincipals(principals: PrincipalType[] | undefined): Principal[] | undefined {
		if( !principals) return;
		return principals.map(x => {
			return {
				customerId: x.customerId,
				name: x.principalName
			}
		})
	}

	static toBusinessCategories(businessCategories: BusinessCategoryType[] | undefined): BusinessCategory[] | undefined {
		if( !businessCategories) return;
		return businessCategories.map(x => {
			return {
				id: x.businessId||this.idService.newGuid(),
				businessCategoryCode: x.businessCategory,
				businessCategoryText: x.businessCategory,
				businessPart: x.businessPart,
				mainDirection: x.mainBusiness
			}
		})
	}

	static toCompanies(companies:CompanyType[]|undefined) : Company[]|undefined {
		if( !companies)
			return;
		let r = companies.filter(x=>x!==undefined).map(x => this.toCompany(x));
		return r as Company[];
	}



}