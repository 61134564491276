import React from 'react';
import { Case, MaintenanceCost } from '../../../../models/SelmaModels';
import { AppComponent } from '../../../AppComponent';
import { HouseholdMaintenanceHouse } from './HouseholdMaintenanceHouse';

interface Props {
	case: Case;
	maintenanceCost: MaintenanceCost;
}

export class HouseholdMaintenanceCost extends AppComponent<Props> {

	constructor(props: Props) {
		super(props);

		this.observeUpdate(this.props.maintenanceCost);

		this.application.services.maintenanceCostService.ensureMaintenanceHouse(this.props.case, this.props.maintenanceCost);
	}


	render() {
		const c = this.props.case;
		let maintenanceCost = this.props.maintenanceCost;

		return ( 
			<div className="HouseholdMaintenanceCost">
				{ maintenanceCost.typeofhouses && maintenanceCost.typeofhouses.map((x,ix) => {
					return (
						<HouseholdMaintenanceHouse key={ix} case={c} maintenanceCost={maintenanceCost} maintenanceHouse={x}/>
					);
				})}
			</div>
		);
	}


}
