import { DownpaymentSource } from "../SelmaModels";
import { UIKeyed } from "./UIKeyed";
import { UINamed } from "./UINamed";

export interface UILoanDownpaymentType extends UIKeyed, UINamed {
	type: DownpaymentSource;
}

export const uiLoanDownpaymentTypes: UILoanDownpaymentType[] = [
	{ key: 'SALE_OF_PROPERTY', name: 'Försäljning bostad', nameTextkey:"UILoanDownpaymentType_SALE_OF_PROPERTY", type: DownpaymentSource.SALE_OF_PROPERTY },
	{ key: 'SAVINGS', name: 'Sparande', nameTextkey:"UILoanDownpaymentType_SAVINGS",type: DownpaymentSource.SAVINGS },
	{ key: 'BLANCO', name: 'Blancolån', nameTextkey:"UILoanDownpaymentType_BLANCO",type: DownpaymentSource.BLANCO_LOAN },
	{ key: 'OTHER', name: 'Övrigt', nameTextkey:"UILoanDownpaymentType_OTHER",type: DownpaymentSource.OTHER },
];