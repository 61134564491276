import React from 'react';
import { Case, Applicant } from '../../../../../models/SelmaModels';
import { AppComponent } from '../../../../AppComponent';
import {
	FormUnit,
	FormUnitSize,
	FormUnitPadding,
} from '../../../../layout/FormUnit/FormUnit';
import { T } from '../../../../widget/display/T';
import KeyValueList from '../../../../widget/display/KeyValueList/KeyValueList';
import { uiHighRiskCategories } from '../../../../../models/UiModels';

interface Props {
	case: Case;
	applicant:Applicant;
}

export class CompanyHighRiskBusinessSummary extends AppComponent<Props> {
	constructor(props: Props) {
		super(props);
	}

	isHighRiskBusiness(applicant: Applicant|undefined) {
		const applicantService = this.application.services.applicantService;
		let isHighRiskBusiness: boolean | undefined;

		if (applicant) {
			isHighRiskBusiness = applicantService.hasSelectedHighRiskCategoryAndItIsRisky(applicant);
			return isHighRiskBusiness;
		}

		return undefined;
	}

	render() {
		const applicantService = this.application.services.applicantService;

		const c = this.props.case;
		const applicant = this.props.applicant;

		let isHighRiskBusiness = this.isHighRiskBusiness(applicant);
		let highRiskBusinessCode = applicantService.getHighRiskCategory(applicant);
		let highRiskBusinessName = '';
		if( highRiskBusinessCode ) {
			let r = uiHighRiskCategories.filter(x => {return x.id.toString()===highRiskBusinessCode });
			if( r.length===1 ) {
				highRiskBusinessName = r[0].name;
			}
		}

		return (
			<FormUnit size={FormUnitSize.Large} padding={FormUnitPadding.Small}>
				<KeyValueList>
					{[
						{
							key: <T k="Summary_Company_HighRiskBusiness" />,
							value: isHighRiskBusiness ? (
								highRiskBusinessName
							) : (
								<T k="No" />
							),
						},
					]}
				</KeyValueList>
			</FormUnit>
		);
	}
}
