import React from 'react';
import { Applicant } from '../../../../../models/SelmaModels';
import { AppComponent } from '../../../../AppComponent';
import { FormUnit, FormUnitSize, FormUnitPadding } from '../../../../layout/FormUnit/FormUnit';
import { T } from '../../../../widget/display/T';

import { ApplicantService } from '../../../../../services/ApplicantService';
import KeyValueList from '../../../../widget/display/KeyValueList/KeyValueList';

interface Props {
	applicant: Applicant;
}

export class ApplicantPepSummary extends AppComponent<Props> {
	applicantService:ApplicantService;

	constructor(props: Props) {
		super(props);

		this.applicantService = this.application.services.applicantService;
	}

	checkPep(applicant, pepParam) {
		if (applicant.kyc) {
			if (applicant.kyc[pepParam]) {
				return 'Ja';
			} else {
				return 'Nej';
			}
		}
		return null;
	}

	render() {
		const applicant = this.props.applicant;

		return (
			<FormUnit size={FormUnitSize.Large} padding={FormUnitPadding.Normal}>
				<h3><T k="Summary_Applicant_Pep"/></h3>
				<KeyValueList>
					{[
						{
							key: <T k="Summary_Applicant_Is_Pep" />,
							value: this.checkPep(applicant, 'isPep')
						},
						{
							key: <T k="Summary_Applicant_Pep_Related" />,
							value: this.checkPep(applicant, 'isPepRelated')
						},
						{
							key: <T k="Summary_Applicant_AmericanCitizen" />,
							value: this.checkPep(applicant, 'isAmericanCitizen')
						}
					]}
				</KeyValueList>
			</FormUnit>
		);
	}
}
