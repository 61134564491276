import React from 'react';
import { Case, Collateral } from '../../../../models/SelmaModels';
import { uiMunicipalities } from '../../../../models/UiModels';
import { AppComponent } from '../../../AppComponent';
import { FormUnitRow } from '../../../layout/FormUnitRow/FormUnitRow';
import { Label } from '../../../widget/display/Label/Label';
import { Validation } from '../../../widget/display/Validation/Validation';
import { Button } from '../../../widget/input/Button/Button';
import { DeleteButton } from '../../../widget/input/DeleteButton/DeleteButton';
import { Select, SelectOption } from '../../../widget/input/Select/Select';
import { Textbox } from '../../../widget/input/Textbox/Textbox';
import { Checkbox } from '../../../widget/input/Checkbox/Checkbox';

interface Props {
	case: Case;
}
interface State { }

export class CollateralCollaterals extends AppComponent<Props, State> {
	municipalityOptions: SelectOption[];
	collateralService = this.application.services.collateralService;

	constructor(props: Props) {
		super(props);

		this.state = {};

		// get municipality options
		this.municipalityOptions = uiMunicipalities.map((x) => {
			let municipalityOption = {
				label: x.name,
				value: x.name,
			};
			return municipalityOption;
		});
		this.municipalityOptions.sort((a, b) => {
			return a.label > b.label ? 1 : -1;
		});

	}

	collateralMunicipalityChanged = (
		value: string,
		collateral: Collateral
	): void => {
		collateral.municipality = value;
		this.collateralService.update(collateral);
	};

	collateralCodeChanged = (
		event: React.ChangeEvent<HTMLInputElement>,
		collateral: Collateral
	): void => {
		let value = event.target.value;
		collateral.code = value;
		this.collateralService.update(collateral);
	};

	addCollateralClicked = (
		event: React.MouseEvent<HTMLButtonElement, MouseEvent>
	): void => {
		const c = this.props.case;

		let collateral = this.collateralService.newCollateral();
		this.collateralService.addCollateral(c, collateral);
	};

	removeCollateralClicked = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, collateral: Collateral): void => {
		this.collateralService.removeCollateral(this.props.case, collateral);
	}

	postponeCollateralCodeChange = (
		event: React.ChangeEvent<HTMLInputElement>,
		collateral: Collateral
	): void => {
		collateral.postponeCollateralCode = event.target.checked;
		collateral.code = undefined;
		collateral.municipality = undefined;
		this.collateralService.update(collateral);
	};

	render() {

		let c = this.props.case;
		const collaterals = c.collaterals;
		let addedCollateralsIsValid = this.collateralService.validateCollaterals(c).ok();
		let canAddCollateral = c.collaterals && c.collaterals.length <= 10 && addedCollateralsIsValid;

		if (!collaterals || collaterals.length < 1) return null;

		return (
			<div className="collaterals">

				{collaterals && collaterals.map((x, ix) => {

					let collateral = x;
					let municipality = collateral.municipality || '';
					let code = collateral.code || '';
					let showDelete = collaterals.length > 1;
					let postponeCollateralCode = collateral.postponeCollateralCode || false;

					return (
						<>
							<FormUnitRow className="FormUnitRow margin-bottom-zero" key={`collateralrow_${ix}`}>
								<div className="FormUnitRow_Section">
									<Label
										labelTextkey="Collateral_Municipality"
										for={`addCollateralMunicipality_${ix}`}
										required={!postponeCollateralCode}
									/>
									<Validation
										forObject={collateral}
										errors={[
											{
												validator: !postponeCollateralCode ? this.application.services.collateralService
													.hasMunicipality : () => null,
												textkey: 'Collateral_Guarantor_SelectMunicipality',
											},
										]}>
										<Select
											id={`addCollateralMunicipality_${ix}`}
											value={municipality}
											handleChange={(e) => this.collateralMunicipalityChanged(e, x)}
											placeholderTextkey="Collateral_Municipality"
											noSelectableOptionsTextkey="Select_NoOptions"
											options={this.municipalityOptions}
											isSearchable={true}
											enabled={!postponeCollateralCode}
										/>
									</Validation>
								</div>
								<div className="FormUnitRow_Section">
									<Label
										labelTextkey="Collateral_CollateralCode"
										for={`addCollateralCode_${ix}`}
										required={!postponeCollateralCode}
									/>
									<Validation
										forObject={collateral}
										errors={[
											{
												validator: !postponeCollateralCode ? this.application.services.collateralService
													.hasCode : () => null,
												textkey: 'Collateral_Guarantor_EnterCollateralCode',
											},
										]}>
										<Textbox
											id={`addCollateralCode_${ix}`}
											value={code}
											handleChange={(e) => this.collateralCodeChanged(e, x)}
											placeholderTextkey="Collateral_CollateralCode"
											maxlength={80}
											enabled={!postponeCollateralCode}
										/>
									</Validation>
								</div>

								{showDelete && (
									<div className="FormUnitRow_Section Button">
										<DeleteButton
											labelTextkey="Collateral_RemoveCollateral"
											onClick={(e) => this.removeCollateralClicked(e, x)}
										/>
									</div>
								)}
							</FormUnitRow>

							<FormUnitRow>
								<div className="FormUnitRow_Section">
									<Checkbox
										labelTextkey="Collateral_Postpone_CollateralCode"
										checked={postponeCollateralCode}
										handleChange={(e) => this.postponeCollateralCodeChange(e, x)}
									/>
								</div>
							</FormUnitRow>
						</>
					)
				})}
				<FormUnitRow>
					<div>
						<Button
							className="Button icon plus-icon"
							id="addCollateral"
							enabled={canAddCollateral}
							labelTextkey="Collateral_Add"
							onClick={this.addCollateralClicked}
							style='link'
						/>
					</div>
				</FormUnitRow>
			</div>
		)

	}
}
