import React from 'react';
import ClassNames from 'classnames';
import { Radiobutton } from '../Radiobutton/Radiobutton';
import { BaseComponent } from '../../../BaseComponent';
import { uniqueId } from 'lodash';

import './RadiobuttonGroup.scss';

export interface RadioOption {
	enabled?: boolean;
	group: string;
	label?: string;
	labelTextkey?: string;
	value: string;
}

interface Props {
	className?: string;
	enabled?: boolean;
	handleBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
	handleChange: (value: string) => void;
	options: Array<RadioOption>;
	orientation?: string;
	value?: string;
	touched?: boolean;
	hasErrors?: boolean;
	dirty?: boolean;
}

export class RadiobuttonGroup extends BaseComponent<Props> {
	id: string;

	constructor(props: Props) {
		super(props);

		this.id = uniqueId('radiobuttonGroup_');
	}

	static defaultProps = {
		enabled: true,
		orientation: 'horizontal',
		className: 'RadiobuttonGroup',
		touched: false,
		hasErrors: false,
		dirty: false,
	};

	handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const value = event.target.value || '';

		this.props.handleChange(value);
	};

	render() {
		const classNames = ClassNames(
			this.props.className,
			{	error: this.props.touched && this.props.hasErrors},
			{Horizontal: this.props.orientation === 'horizontal'},
			{Vertical: this.props.orientation === 'vertical'}
		);

		return (
			<div className={classNames}>
				{this.props.options.map((radio, index) => {
					return (
						<Radiobutton
							key={`${this.id}_checkbox_${index}`}
							value={radio.value}
							label={radio.label}
							labelTextkey={radio.labelTextkey}
							group={radio.group}
							handleChange={this.handleChange}
							handleBlur={this.props.handleBlur}
							checked={radio.value === this.props.value}
							enabled={radio.enabled}
							hasErrors={this.props.hasErrors}
							touched={this.props.touched}
							dirty={this.props.dirty}
						/>
					);
				})}
			</div>
		);
	}
}
