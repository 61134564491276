// tslint:disable
/**
 * SelmaSME API
 * This definition is the API between customer SME-Gui and the persistence layer for the application NOT FOR V1 When customer logg-in to Selma-EN, we will create a processId with applicant CustomerId (from BankID). When a customer added attachment to the application, we don\'t have a CaseId i LP, should we add a LP-Service to create/reserve a caseId to be able to attach a document to the caseId in DM.
 *
 * The version of the OpenAPI document: 0.7.5
 * Contact: developer@landshypotek.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Attachment, AttachmentRequest } from '../../../models/SelmaModels';
import { exists, mapValues } from '../runtime';
import {
    ApplicantType,
    ApplicantTypeFromJSON,
    ApplicantTypeToJSON,
    BudgetType,
    BudgetTypeFromJSON,
    BudgetTypeToJSON,
    CollateralType,
    CollateralTypeFromJSON,
    CollateralTypeToJSON,
    CompanyEconomyType,
    CompanyEconomyTypeFromJSON,
    CompanyEconomyTypeToJSON,
    CompanyType,
    CompanyTypeFromJSON,
    CompanyTypeToJSON,
    EUSupportType,
    EUSupportTypeFromJSON,
    EUSupportTypeToJSON,
    ExtLoanType,
    ExtLoanTypeFromJSON,
    ExtLoanTypeToJSON,
    GuarantorType,
    GuarantorTypeFromJSON,
    GuarantorTypeToJSON,
    HouseholdType,
    HouseholdTypeFromJSON,
    HouseholdTypeToJSON,
    KycInformationType,
    KycInformationTypeFromJSON,
    KycInformationTypeToJSON,
    LoanType,
    LoanTypeFromJSON,
    LoanTypeToJSON,
    MaintenanceCostType,
    MaintenanceCostTypeFromJSON,
    MaintenanceCostTypeToJSON,
    PersonalEconomyType,
    PersonalEconomyTypeFromJSON,
    PersonalEconomyTypeToJSON,
    ProcessType,
    ProcessTypeFromJSON,
    ProcessTypeToJSON,
} from './';

/**
 * Used for getprocessall and saveprocessall
 * @export
 * @interface LinksAll
 */
export interface LinksAll {
    /**
     * Return a list of type processType
     * @type {Array<ProcessType>}
     * @memberof LinksAll
     */
    processes?: Array<ProcessType>;
    /**
     * List of applicantType
     * @type {Array<ApplicantType>}
     * @memberof LinksAll
     */
    applicants?: Array<ApplicantType>;
    /**
     * Array list of loanType (Loan)
     * @type {Array<LoanType>}
     * @memberof LinksAll
     */
    loans?: Array<LoanType>;
    /**
     * Type definition for external loans
     * @type {Array<ExtLoanType>}
     * @memberof LinksAll
     */
    extloans?: Array<ExtLoanType>;
    /**
     * List of companyType
     * @type {Array<CompanyType>}
     * @memberof LinksAll
     */
    companies?: Array<CompanyType>;
    /**
     * return all available households for a customer
     * @type {Array<HouseholdType>}
     * @memberof LinksAll
     */
    households?: Array<HouseholdType>;
    /**
     * Array list of type collateralType
     * @type {Array<CollateralType>}
     * @memberof LinksAll
     */
    collaterals?: Array<CollateralType>;
    /**
     * -> Get a list of PersonalEconomyType
     * @type {Array<PersonalEconomyType>}
     * @memberof LinksAll
     */
    personaleconomies?: Array<PersonalEconomyType>;
    /**
     * -> Type definition for company economies, returns array of CompanyEconomyType
     * @type {Array<CompanyEconomyType>}
     * @memberof LinksAll
     */
    companyeconomies?: Array<CompanyEconomyType>;
    /**
     * Array of budgetType for processId with dirrent companyEconomyID
     * @type {Array<BudgetType>}
     * @memberof LinksAll
     */
    budgets?: Array<BudgetType>;
    /**
     * Return a list of type kycInformationType
     * @type {Array<KycInformationType>}
     * @memberof LinksAll
     */
    kycinformations?: Array<KycInformationType>;
    /**
     * TBD
     * @type {Array<GuarantorType>}
     * @memberof LinksAll
     */
    guarantors?: Array<GuarantorType>;
    /**
     * TBD
     * @type {Array<EUSupportType>}
     * @memberof LinksAll
     */
    eusupports?: Array<EUSupportType>;
    /**
     * TBD
     * @type {Array<MaintenanceCostType>}
     * @memberof LinksAll
     */
    maintenancecosts?: Array<MaintenanceCostType>;

    // LIMETTA EXTENSION.
    attachments?: Attachment[];
    attachmentRequests?: AttachmentRequest[];
}

export function LinksAllFromJSON(json: any): LinksAll {
    return {
        'processes': !exists(json, 'processes') ? undefined : (json['processes'] as Array<any>).map(ProcessTypeFromJSON),
        'applicants': !exists(json, 'applicants') ? undefined : (json['applicants'] as Array<any>).map(ApplicantTypeFromJSON),
        'loans': !exists(json, 'loans') ? undefined : (json['loans'] as Array<any>).map(LoanTypeFromJSON),
        'extloans': !exists(json, 'extloans') ? undefined : (json['extloans'] as Array<any>).map(ExtLoanTypeFromJSON),
        'companies': !exists(json, 'companies') ? undefined : (json['companies'] as Array<any>).map(CompanyTypeFromJSON),
        'households': !exists(json, 'households') ? undefined : (json['households'] as Array<any>).map(HouseholdTypeFromJSON),
        'collaterals': !exists(json, 'collaterals') ? undefined : (json['collaterals'] as Array<any>).map(CollateralTypeFromJSON),
        'personaleconomies': !exists(json, 'personaleconomies') ? undefined : (json['personaleconomies'] as Array<any>).map(PersonalEconomyTypeFromJSON),
        'companyeconomies': !exists(json, 'companyeconomies') ? undefined : (json['companyeconomies'] as Array<any>).map(CompanyEconomyTypeFromJSON),
        'budgets': !exists(json, 'budgets') ? undefined : (json['budgets'] as Array<any>).map(BudgetTypeFromJSON),
        'kycinformations': !exists(json, 'kycinformations') ? undefined : (json['kycinformations'] as Array<any>).map(KycInformationTypeFromJSON),
        'guarantors': !exists(json, 'guarantors') ? undefined : (json['guarantors'] as Array<any>).map(GuarantorTypeFromJSON),
        'eusupports': !exists(json, 'eusupports') ? undefined : (json['eusupports'] as Array<any>).map(EUSupportTypeFromJSON),
        'maintenancecosts': !exists(json, 'maintenancecosts') ? undefined : (json['maintenancecosts'] as Array<any>).map(MaintenanceCostTypeFromJSON),
        // LIMETTA EXTENSION.
        'attachments': json.attachments,
        'attachmentRequests': json.attachmentRequests
    };
}

export function LinksAllToJSON(value?: LinksAll): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'processes': value.processes === undefined ? undefined : (value.processes as Array<any>).map(ProcessTypeToJSON),
        'applicants': value.applicants === undefined ? undefined : (value.applicants as Array<any>).map(ApplicantTypeToJSON),
        'loans': value.loans === undefined ? undefined : (value.loans as Array<any>).map(LoanTypeToJSON),
        'extloans': value.extloans === undefined ? undefined : (value.extloans as Array<any>).map(ExtLoanTypeToJSON),
        'companies': value.companies === undefined ? undefined : (value.companies as Array<any>).map(CompanyTypeToJSON),
        'households': value.households === undefined ? undefined : (value.households as Array<any>).map(HouseholdTypeToJSON),
        'collaterals': value.collaterals === undefined ? undefined : (value.collaterals as Array<any>).map(CollateralTypeToJSON),
        'personaleconomies': value.personaleconomies === undefined ? undefined : (value.personaleconomies as Array<any>).map(PersonalEconomyTypeToJSON),
        'companyeconomies': value.companyeconomies === undefined ? undefined : (value.companyeconomies as Array<any>).map(CompanyEconomyTypeToJSON),
        'budgets': value.budgets === undefined ? undefined : (value.budgets as Array<any>).map(BudgetTypeToJSON),
        'kycinformations': value.kycinformations === undefined ? undefined : (value.kycinformations as Array<any>).map(KycInformationTypeToJSON),
        'guarantors': value.guarantors === undefined ? undefined : (value.guarantors as Array<any>).map(GuarantorTypeToJSON),
        'eusupports': value.eusupports === undefined ? undefined : (value.eusupports as Array<any>).map(EUSupportTypeToJSON),
        'maintenancecosts': value.maintenancecosts === undefined ? undefined : (value.maintenancecosts as Array<any>).map(MaintenanceCostTypeToJSON),
        // LIMETTA EXTENSION.
        'attachments': value.attachments === undefined ? undefined : value.attachments,
        'attachmentRequests': value.attachmentRequests === undefined ? undefined : value.attachmentRequests,
    };
}


