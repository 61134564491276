import React from 'react';
import './StatusBar.scss';
import { T } from '../../widget/display/T';

interface StatusBarProps {
	Type: string;
	Message?: string | undefined;
    TextKey?: string | undefined
}

const StatusBar = ({ Type, Message = undefined, TextKey=undefined }: StatusBarProps) => {
	return (
		<div className="statusBar">
			<p className={Type}>
                {TextKey && <T k={TextKey}/>}
                {!TextKey && (Message ?? "")}
            </p>
		</div>
	);
};

export default StatusBar;
