import React from 'react';
import ClassNames from 'classnames';
import { Application } from '../../../../models/AppModels';
import './Textbox.scss';

export enum TextboxType {
	email = 'email',
	number = 'number',
	password = 'password',
	tel = 'tel',
	text = 'text',
}

interface Props {
	className?: string;
	dirty?: boolean;
	enabled?: boolean;
	handleBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
	handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
	hasErrors?: boolean;
	id?: string;
	name?: string;
	placeholderTextkey?: string;
	touched?: boolean;
	type?: TextboxType;
	value?: string;
	maxlength?:number;
}

export const Textbox: React.FunctionComponent<Props> = (props) => {

	let placeholder: string | undefined = undefined;
	if (props.placeholderTextkey)
		placeholder = Application.instance.services.textService.text(props.placeholderTextkey);

	const classNames = ClassNames(
		props.className,
		{ error: props.touched && props.hasErrors }
	);

	return (
		<input
			className={classNames}
			id={props.id}
			name={props.name}
			onBlur={props.handleBlur}
			onChange={props.handleChange}
			placeholder={placeholder}
			type={props.type}
			value={props.value}
			// defaultValue={props.value}
			disabled={props.enabled === false}
			maxLength={props.maxlength}
		/>
	);
};

// Default props
Textbox.defaultProps = {
	className: 'Textbox',
	type: TextboxType.text,
	enabled: true,
	touched: false,
	dirty: false,
	hasErrors: false,
};
