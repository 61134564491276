import React from 'react';
import {
	Household,
} from '../../../../../models/SelmaModels';
import { AppComponent } from '../../../../AppComponent';
import { T } from '../../../../widget/display/T';
import {
	FormUnit,
	FormUnitSize,
	FormUnitPadding,
} from '../../../../layout/FormUnit/FormUnit';
import KeyValueList from '../../../../widget/display/KeyValueList/KeyValueList';
import NumberFormat from 'react-number-format';
import { Heading } from '../../../../widget/display/Heading/Heading';


interface Props {
	household: Household;
}

export class HouseholdChildMaintenanceSummary extends AppComponent<Props> {
	constructor(props: Props) {
		super(props);
	}

	render() {
		const h = this.props.household;

		if (!h.childMaintenanceCost && !h.childMaintenanceIncome && !h.childCareAmount) return null;

		return (
			<>
				{h.childCareAmount &&
				<FormUnit size={FormUnitSize.Large} padding={FormUnitPadding.Normal}>
					<KeyValueList>
						{[
							{
								key: <T k="Summary_Household_ChildCareAmount" />,
								value: (
									<NumberFormat
										thousandSeparator=" "
										suffix=" kr/månad"
										displayType="text"
										value={h.childCareAmount?Math.ceil(h.childCareAmount):undefined}
									/>
								),
							}
						]}
					</KeyValueList>
				</FormUnit>
				}
				{!(!h.childMaintenanceCost && !h.childMaintenanceIncome) &&
				<FormUnit size={FormUnitSize.Large} padding={FormUnitPadding.Normal}>
					<Heading level={3} labelTextkey="Summary_Household_ChildMaintenance"/>
					<KeyValueList>
						{[
							{
								key: <T k="Summary_Household_ChildMaintenanceCost" />,
								value: (
									<NumberFormat
										thousandSeparator=" "
										suffix=" kr/månad"
										displayType="text"
										value={h.childMaintenanceCost?Math.ceil(h.childMaintenanceCost/12):undefined}
									/>
								),
							},
							{
								key: <T k="Summary_Household_ChildMaintenanceIncome" />,
								value: (
									<NumberFormat
										thousandSeparator=" "
										suffix=" kr/månad"
										displayType="text"
										value={h.childMaintenanceIncome?Math.ceil(h.childMaintenanceIncome/12):undefined}
									/>
								),
							},
						]}
					</KeyValueList>
				</FormUnit>
				}
			</>
		);
	}
}
