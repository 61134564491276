import React from 'react';
import ClassNames from 'classnames';
import NumberFormat, { NumberFormatValues} from 'react-number-format';
import './Age.scss';

interface Props {
	className?: string;
	id?: string;
	name?: string;
	handleBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
	handleChange?: (values: NumberFormatValues) => void;
	type?: string;
	value?: number;
	enabled?: boolean;
	suffix?: string;
	displayType?: 'input' | 'text'; 
	min: number,
	max: number,
	hasErrors?: boolean;
	touched?: boolean;
	dirty?: boolean;
}

export const Age: React.FunctionComponent<Props> = (props) => {

	const classNames = ClassNames(props.className, {
		error: props.touched && props.hasErrors,
	});

	let value = props.value;
	if (value !== undefined) {
		if (props.min !== undefined && value < props.min) {
			value = props.min;
		} else if (props.max !== undefined && value > props.max) {
			value = props.max;
		}
	}

  return (
		<NumberFormat
			className={classNames}
			id={props.id}
			value={value}
			onValueChange={props.handleChange}
			onBlur={props.handleBlur}
			suffix={props.suffix}
			displayType={props.displayType}
			min={props.min}
			max={props.max}
			decimalScale={0}
		/>
	);
};

// Default props
Age.defaultProps = {
	className: 'Age',
	type: 'text',
	suffix: ' år',
	displayType: 'input',
	min: 0,
	max: 150
};
