import { UINamed } from "./UINamed";

export interface UIHighRiskCategory extends UINamed {
	id: number;
}

export const uiHighRiskCategories: UIHighRiskCategory[] = [
	{ id: 0, name: 'Nej, inget av nedanstående', nameTextkey: 'HighRiskCategory_EMPTY' },
	{
		id: 1,
		name: 'Utvinning av uran- och/eller toriummalm/Vapenhandlare',
		nameTextkey: 'HighRiskCategory_07210',
	},
	{
		id: 2,
		name: 'Sprängämnestillverkning/Vapenhandlare',
		nameTextkey: 'HighRiskCategory_20510',
	},
	{
		id: 3,
		name: 'Tillverkning av kärnbränsle/Vapenhandlare',
		nameTextkey: 'HighRiskCategory_24460',
	},
	{
		id: 4,
		name: 'Tillverkning av vapen och ammunition/Vapenhandlare',
		nameTextkey: 'HighRiskCategory_25400',
	},
	{
		id: 5,
		name: 'Tillverkning av militära stridsfordon/Vapenhandlare',
		nameTextkey: 'HighRiskCategory_30400',
	},
	{
		id: 6,
		name: 'Specialiserad butikshandel med ur',
		nameTextkey: 'HighRiskCategory_47771',
	},
	{
		id: 7,
		name:
			'Specialiserad butikshandel med guldsmedsvaror och smycken (av ädel metall)',
		nameTextkey: 'HighRiskCategory_47772',
	},
	{
		id: 8,
		name: 'Specialiserad butikshandel med konst samt galleriverksamhet',
		nameTextkey: 'HighRiskCategory_47783',
	},
	{
		id: 9,
		name: 'Specialiserad butikshandel med mynt och frimärken',
		nameTextkey: 'HighRiskCategory_47784',
	},
	{
		id: 10,
		name: 'Specialiserad butikshandel med antikviteter',
		nameTextkey: 'HighRiskCategory_47791',
	},
	{ id: 11, name: 'Auktioner i butik', nameTextkey: 'HighRiskCategory_47793' },
	{ id: 12, name: 'Auktioner på Internet', nameTextkey: 'HighRiskCategory_47917' },
	{
		id: 13,
		name:
			'Postorderhandel och detaljhandel på Internet med övriga varor (apoteksartiklar)',
		nameTextkey: 'HighRiskCategory_47919',
	},
	{
		id: 14,
		name: 'Auktioner ej i butiker eller på Internet',
		nameTextkey: 'HighRiskCategory_47994',
	},
	{
		id: 15,
		name: 'Valutaväxling, växlingskontor',
		nameTextkey: 'HighRiskCategory_66120',
	},
	{ id: 16, name: 'Betalningsförmedling', nameTextkey: 'HighRiskCategory_66190' },
	{ id: 17, name: 'Fastighetsförmedling', nameTextkey: 'HighRiskCategory_68310' },
	{ id: 18, name: 'Humanitära insatser', nameTextkey: 'HighRiskCategory_88994' },
	{
		id: 19,
		name: 'Spel- och vadhållningsverksamhet',
		nameTextkey: 'HighRiskCategory_92000',
	},
	{
		id: 101,
		name: 'Handel, service och reparation av motorfordon, motorcyklar, båt mm',
		nameTextkey: 'HighRiskCategory_45'
	},
	{
		id: 102,
		name: 'Taxiverksamhet',
		nameTextkey: 'HighRiskCategory_49'
	},
	{
		id: 103,
		name: 'Butikshandel med klockor, smycken, konst, antikviteter, auktioner mm',
		nameTextkey: 'HighRiskCategory_4777'
	},
	{
		id: 104,
		name: 'Gruvdrift, stenkol',
		nameTextkey: 'HighRiskCategory_5100'
	},
	{
		id: 105,
		name: 'Gruvdrift, brytning brunkol',
		nameTextkey: 'HighRiskCategory_5200'
	},
	{
		id: 106,
		name: 'Förvaltning och uthyrning av egna eller arrenderade fastigheter',
		nameTextkey: 'HighRiskCategory_6820'
	},
	{
		id: 107,
		name: 'Juridisk verksamhet',
		nameTextkey: 'HighRiskCategory_6910'
	},
	{
		id: 108,
		name: 'Revision, bokföring, skatterådgivning',
		nameTextkey: 'HighRiskCategory_6920'
	},
	{
		id: 109,
		name: 'Gruvdrift, järnmalm',
		nameTextkey: 'HighRiskCategory_7100'
	},
	{
		id: 110,
		name: 'Utvinning av uran- och toriummalm',
		nameTextkey: 'HighRiskCategory_7210'
	},
	{
		id: 111,
		name: 'Gruvdrift, icke-järnmalm (utom uranmalm och toriummalm)',
		nameTextkey: 'HighRiskCategory_7290'
	},
	{
		id: 113,
		name: 'Petroleumraffinering',
		nameTextkey: 'HighRiskCategory_19200'
	},
	{
		id: 114,
		name: 'Industrigasframställning',
		nameTextkey: 'HighRiskCategory_20110'
	},
	{
		id: 115,
		name: 'Sprängämnestillverkning',
		nameTextkey: 'HighRiskCategory_20510'
	},
	{
		id: 116,
		name: 'Tillverkning av kärnbränsle',
		nameTextkey: 'HighRiskCategory_24460'
	},
	{
		id: 117,
		name: 'Tillverkning av vapen och ammunition/Vapenhandlare',
		nameTextkey: 'HighRiskCategory_25400'
	},
	{
		id: 118,
		name: 'Tillverkning av militära stridsfordon',
		nameTextkey: 'HighRiskCategory_30400'
	},
	{
		id: 119,
		name: 'Övriga anläggningsarbeten',
		nameTextkey: 'HighRiskCategory_42990'
	},
	{
		id: 120,
		name: 'Uthyrning av bygg- och anläggningsmaskiner',
		nameTextkey: 'HighRiskCategory_43991'
	},
	{
		id: 121,
		name: 'Övrig specialiserad bygg- och anläggningsverksamhet',
		nameTextkey: 'HighRiskCategory_43999'
	},
	{
		id: 122,
		name: 'Provisionshandel med bränsle, malm, metaller och industrikemikalier',
		nameTextkey: 'HighRiskCategory_46120'
	},
	{
		id: 123,
		name: 'Partihandel med diverse andra maskiner och diverse annan utrustning',
		nameTextkey: 'HighRiskCategory_46699'
	},
	{
		id: 124,
		name: 'Partihandel med bränsle',
		nameTextkey: 'HighRiskCategory_46710'
	},
	{
		id: 125,
		name: 'Specialiserad detaljhandel med drivmedel',
		nameTextkey: 'HighRiskCategory_47300'
	},
	{
		id: 126,
		name: 'Butikshandel med konst samt galleriverksamhet',
		nameTextkey: 'HighRiskCategory_47783'
	},
	{
		id: 127,
		name: 'Butikshandel med mynt och frimärken',
		nameTextkey: 'HighRiskCategory_47784'
	},
	{
		id: 128,
		name: 'Vapen för krigsändamål-partihandel vapen och ammunition',
		nameTextkey: 'HighRiskCategory_47789'
	},
	{
		id: 129,
		name: 'Butikshandel med antikviteter',
		nameTextkey: 'HighRiskCategory_47791'
	},
	{
		id: 130,
		name: 'Auktioner i butik',
		nameTextkey: 'HighRiskCategory_47793'
	},
	{
		id: 131,
		name: 'Auktioner på Internet',
		nameTextkey: 'HighRiskCategory_47917'
	},
	{
		id: 132,
		name: 'Postorderhandel och detaljhandel på Internet med övriga varor',
		nameTextkey: 'HighRiskCategory_47919'
	},
	{
		id: 133,
		name: 'Auktioner ej i butiker eller på Internet',
		nameTextkey: 'HighRiskCategory_47994'
	},
	{
		id: 134,
		name: 'Restaurang, fik, gårdförsäljning mm',
		nameTextkey: 'HighRiskCategory_56100'
	},
	{
		id: 135,
		name: 'Värdepappersfonder',
		nameTextkey: 'HighRiskCategory_64301'
	},
	{
		id: 136,
		name: 'Annan kreditgivning',
		nameTextkey: 'HighRiskCategory_64920'
	},
	{
		id: 137,
		name: 'Handel med och förvaltning av värdepapper, för egen räkning (med tillstånd från Finansinspektionen)',
		nameTextkey: 'HighRiskCategory_64992'
	},
	{
		id: 138,
		name: 'Förvaltning och handel med värdepapper för annans räkning i mindre krets',
		nameTextkey: 'HighRiskCategory_64993'
	},
	{
		id: 139,
		name: 'Drift av auktoriserade marknadsplatser för värdepapper och råvaror',
		nameTextkey: 'HighRiskCategory_66110'
	},
	{
		id: 140,
		name: 'Valutaväxling, växlingskontor',
		nameTextkey: 'HighRiskCategory_66120'
	},
	{
		id: 141,
		name: 'Andra stödtjänster till finansiella tjänster utom försäkring och pensionsfondsverksamhet',
		nameTextkey: 'HighRiskCategory_66190'
	},
	{
		id: 142,
		name: 'Fondförvaltning av värdepappersfonder, för annans räkning (tillstånd krävs från Finansinspektionen)',
		nameTextkey: 'HighRiskCategory_66301'
	},
	{
		id: 143,
		name: 'Fastighetsförmedling',
		nameTextkey: 'HighRiskCategory_68310'
	},
	{
		id: 144,
		name: 'Övriga företagstjänster',
		nameTextkey: 'HighRiskCategory_82990'
	},
	{
		id: 145,
		name: 'Utrikesförvaltning',
		nameTextkey: 'HighRiskCategory_84210'
	},
	{
		id: 146,
		name: 'Heldygnsvård med boende för barn och ungdomar',
		nameTextkey: 'HighRiskCategory_87901'
	},
	{
		id: 147,
		name: 'Öppna sociala insatser för äldre personer',
		nameTextkey: 'HighRiskCategory_88101'
	},
	{
		id: 148,
		name: 'Öppna sociala insatser för personer med funktionshinder',
		nameTextkey: 'HighRiskCategory_88102'
	},
	{
		id: 149,
		name: 'Humanitära insatser',
		nameTextkey: 'HighRiskCategory_88994'
	},
	{
		id: 150,
		name: 'Drift av flyktingförläggning',
		nameTextkey: 'HighRiskCategory_88995'
	},
	{
		id: 151,
		name: 'Spel- och vadhållningsverksamhet',
		nameTextkey: 'HighRiskCategory_92000'
	},
	{
		id: 152,
		name: 'Skönhetsbehandling, manikyr, mm',
		nameTextkey: 'HighRiskCategory_96022'
	},
	{
		id: 153,
		name: 'Massage, kroppsvård mm',
		nameTextkey: 'HighRiskCategory_96040'
	},
	{
		id: 154,
		name: 'Verksamhet vid internationella organisationer, utländska ambassader o.d.',
		nameTextkey: 'HighRiskCategory_99000'
	}
];


/*
[
	"Andra stödtjänster till finansiella tjänster utom försäkring och pensionsfondsverksamhet",
	"Annan kreditgivning",
	"Auktioner ej i butiker eller på Internet",
	"Auktioner i butik",
	"Auktioner på Internet",
	"Butikshandel med antikviteter",
	"Butikshandel med klockor, smycken, konst, antikviteter, auktioner mm",
	"Butikshandel med konst samt galleriverksamhet",
	"Butikshandel med mynt och frimärken",
	"Drift av auktoriserade marknadsplatser för vardepapper och råvaror",
	"Drift av flyktingförläggning",
	"Fastighetsförmedling",
	"Fondförvaltning av värdepappersfonder, för annans räkning (tillstånd krävs från Finansinspektionen)",
	"Förvaltning och handel med värdepapper för annans räkning i mindre krets",
	"Förvaltning och uthyrning av egna eller arrenderade fastigheter",
	"Gruvdrift, brytning brunkol",
	"Gruvdrift, icke-järnmalm (utom uranmalm och toriummalm)",
	"Gruvdrift, järnmalm",
	"Gruvdrift, stenkol",
	"Handel med och förvaltning av värdepapper för egen räkning (med tillstånd från Finansinspektionen)",
	"Handel, service och reparation av motorfordon, motorcyklar, båt mm",
	"Heldygnsvård med boende för barn och ungdomar",
	"Humanitära insatser",
	"Industrigasframställning",
	"Juridisk verksamhet",
	"Massage, kroppsvård mm",
	"Oppna sociala insatser för personer med funktionshinder",
	"Oppna sociala insatser för äldre personer",
	"Ovriga företagstjänster",
	"Partihandel med bränslen",
	"Partihandel med diverse andra maskiner och diverse annan utrustning",
	"Petroleumraffinering",
	"Postorderhandel och detaljhandel på Internet med övriga varor",
	"Provisionshandel med bränsle, malm, metaller och industrikemikalier",
	"Restaurang, fik, gårdsförsäljning mm",
	"Revision, bokföring, skatterådgivning",
	"Skönhetsbehandling, manikyr, mm",
	"Specialiserad detaljhandel med drivmedel",
	"Spel- och vadhållningsverksamhet",
	"Sprängämnestillverkning",
	"Taxiverksamhet",
	"Tillverkning av kärnbränsle",
	"Tillverkning av militära stridsfordon",
	"Tillverkning av vapen och ammunitionNapenhandlare",
	"Uthyrning av bygg- och anläggningsmaskiner",
	"Utrikesförvaltning",
	"Utvinning av uran- och toriummalm",
	"Valutaväxling, växlingskontor",
	"Vapen för krigsändamål-partihandel vapen och ammunition",
	"Verksamhet vid internationella organisationer, utländska ambassader o d",
	"Värdepappersfonder",
	"Övrig specialiserad bygg- och anläggningsverksamhet",
	"Övriga anläggningsarbeten"
];
*/