import React from 'react';
import { BaseComponent } from '../../../BaseComponent';
import { SelectOption, Select } from '../Select/Select';
import { uiLongMonths } from '../../../../models/uimodels/UIMonths';

interface Props {
	id: string;
	value?: number;
	handleBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
	handleChange?: (month: number) => void;
	hasErrors?: boolean;
	touched?: boolean;
	dirty?: boolean;
}

export class SelectMonth extends BaseComponent<Props> {
	options: SelectOption[];

	constructor(props: Props) {
		super(props);

		this.options = uiLongMonths.map((x) => {
			return {
				label: x.name,
				value: x.id.toString(),
			};
		});
	}

	monthChanged = (value: string) => {
		if (this.props.handleChange) this.props.handleChange(parseInt(value));
	};

	render() {
		let value = this.props.value ? this.props.value.toString() : '';
		return (
			<Select
				id={this.props.id}
				value={value}
				options={this.options}
				handleChange={this.monthChanged}
				handleBlur={this.props.handleBlur}
				placeholderTextkey="Select_Month"
				noSelectableOptionsTextkey="Select_NoOptions"
				hasErrors={this.props.hasErrors}
				touched={this.props.touched}
				dirty={this.props.dirty}
			/>
		);
	}
}
