import { BaseService } from "./BaseService";
import { GuardService } from "./GuardService";

export class ConvertService extends BaseService {
	private guard:GuardService;

	constructor(guardService:GuardService) {
		super();
		this.guard = guardService;
	}

	numberToIntegerThousandSeparatedString(value: number, separator: string = "\u0020"): string {
		if (this.guard.isUndefined(value) || value === null)
			return "";

		var v = Math.round(value);

		if (v > -1000 && v < 1000)
			return value.toString();

		var str = value.toString();

		// Add thousands separator. 
		// Originally it was the unicode character "\u2009" == THIN SPACE
		// but it was replaced with a regular space "\u0200" since Safari on iOS
		// couldn't render thin space.
		for (var i = str.length - 3; i > 0; i -= 3) {
			str = str.substring(0, i) + separator + str.substring(i);
		}

		return str;
	}

	integerThousandSeparatedStringToNumber(value: string): number|null {
		if (this.guard.isUndefined(value) || value === null)
			return null;

		value = value.replace(/\D/g, "");

		var num = parseFloat(value);
		if (isNaN(num))
			return null;

		return num;
	}

	// Takes an ssn on any form. returns it in normalized form: YYYYMMDDNNNN.
	toNormalizedSsn(ssn: string): string {
		if (!this.guard.isString(ssn))
			return ssn;
		if (ssn.length === 12) // YYYYMMDDNNNN
			return ssn;
		if (ssn.length === 13) // YYYYMMDD-NNNN
			return ssn.replace(/-/, "");
		if (ssn.length === 10)// YYMMDDNNNN
			return "19" + ssn;
		if (ssn.length === 11)// YYMMDD-NNNN
			return "19" + ssn.replace(/-/, "");
		return ssn;
	}

	// Takes an ssn on any form. returns it in normalized form: YYYYMMDD-NNNN.
	toPrettyNormalizedSsn(ssn: string): string {
		if (!this.guard.isString(ssn))
			return ssn;
		ssn = this.toNormalizedSsn(ssn);
		if (ssn.length === 12) // YYYYMMDDNNNN
			ssn = ssn.substr(0, 8) + "-" + ssn.substr(8, 4); // YYYYMMDD-NNNN
		return ssn;
	}

	getSsnDate(ssn: string): Date|null {
		if (!ssn || ssn.length === 0)
			return null;

		ssn = this.toNormalizedSsn(ssn.replace('-', ''));
		if (ssn.length !== 12)
			return null;

		let year = parseInt(ssn.substr(0, 4));
		let month = parseInt(ssn.substr(4, 2));
		let day = parseInt(ssn.substr(6, 2));

		return new Date(year, month, day);
	}

	getAgeByDate(date: Date, now?: Date):number {
		if (!now)
			now = new Date();

		var ageDifMs = now.getTime() - date.getTime();
		var ageDate = new Date(ageDifMs); // miliseconds from epoch
		return Math.abs(ageDate.getUTCFullYear() - 1970);
	}

	getAgeBySsn(ssn: string): number {
		let ssnDate = this.getSsnDate(ssn);
		if (!ssnDate)
			return -9999;

		return this.getAgeByDate(ssnDate);
	}

	toIsoDateString(value: Date): string {
		let yyyy = value.getFullYear().toString();
		let mm = (value.getMonth() + 1).toString(); // getMonth() is zero-based 
		let dd = value.getDate().toString();
		return yyyy + "-" + (mm[1] ? mm : "0" + mm[0]) + "-" + (dd[1] ? dd : "0" + dd[0]) + "T00:00:00.000Z";
	}

	
	formatBytes = (bytes:number, decimals:number = 0): string => {
		if (bytes === 0) return '0 bytes';

		const k = 1000;
		const dm = decimals < 0 ? 0 : decimals;
		const sizes = ['bytes', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

		const i = Math.floor(Math.log(bytes) / Math.log(k));

		return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
	}
}