import { BaseService } from './BaseService';
import {
	Case,
	CompanyEconomy,
	Budget,
	BudgetYear,
} from '../models/SelmaModels';
import { SMELPOService } from './SMELPOService';
import { StateService } from './StateService';
import { GuardService } from './GuardService';
import { IdService } from './IdService';
import { CompanyEconomyService } from './CompanyEconomyService';
import { UiModelConverter } from '../models/UiModelConverter';
import { AttachmentService } from './AttachmentService';
import { ValidationResult } from '../utils/ValidationResult';

/*
Handles all logic for Budget, BudgetYears.
*/
export class BudgetService extends BaseService {

	private stateService: StateService;
	private smelpoService: SMELPOService;
	private guardService: GuardService;

	constructor(
		stateService: StateService, 
		smelpoService: SMELPOService,
		guardService: GuardService, 
		private idService: IdService,
		private companyEconomyService: CompanyEconomyService,
		private attachmentService: AttachmentService
	) {
		super();
		this.stateService = stateService;
		this.smelpoService = smelpoService;
		this.guardService = guardService;
	}

	update(companyEconomy: Case | CompanyEconomy | Budget | BudgetYear): void {
		this.stateService.update(companyEconomy);
	}

	getBudgetByCompanyEconomy(c:Case, companyEconomy:CompanyEconomy) : Budget|undefined {
		if( !c.budgets )
			return;
		const r = c.budgets.filter(x => x.companyEconomyId===companyEconomy.id);
		if( r.length===1 ) 
			return r[0];
		else
			return;
	}

	ensureBudgetsForAllCompanyEconomies(c: Case): void {
		if( !c.companyEconomies )
			return;
		c.companyEconomies.forEach(x => {
			this.ensureBudget(c, x);
		})
	}

	ensureBudget(c: Case, companyEconomy:CompanyEconomy): Budget {
		let budget = this.getBudgetByCompanyEconomy(c, companyEconomy);
		if( !budget ) {
			budget = {
				companyEconomyId: companyEconomy.id
			};
			this.addBudget(c,budget);
		}

		this.ensureRequiredBudgetYears(companyEconomy, budget);
		return budget;
	}

	addBudget(c: Case, budget: Budget):void {
		if (!c.budgets) {
			c.budgets = [];
		}
		this.stateService.listAdd(c.budgets, budget);
		this.update(c);
	}

	removeBudget(c: Case, budget: Budget):void {
		if (!c.budgets)
			return;

		this.stateService.listRemove(c.budgets, budget);
		this.stateService.update(c);
	}

	// TODO: Different budget year requirements for different types of companies?
	private getRequiredYearsOfBudget(): number[] {
		const thisYear = new Date().getFullYear();
		const minYearsOfBudget = 2;
		const firstYear = thisYear;
		const lastYear = thisYear + minYearsOfBudget-1;

		let years: number[] = [];
		for (let year = firstYear; year <= lastYear; year++) {
			years.push(year);
		}
		return years;
	}

	getRequiredBudgetYears(
		budget: Budget
	): { year: number; budgetYear: BudgetYear | undefined }[] {
		let years = this.getRequiredYearsOfBudget();
		let r = years.map((year) => {
			return {
				year: year,
				budgetYear: this.getBudgetYearByYear(budget, year),
			};
		});
		return r;
	}

	private ensureRequiredBudgetYears(
		companyEconomy: CompanyEconomy,
		budget: Budget
	): void {
		this.getRequiredYearsOfBudget().forEach((year) => {
			this.ensureBudgetYear(companyEconomy, budget, year);
		});
	}

	private ensureBudgetYear(
		companyEconomy: CompanyEconomy,
		budget: Budget,
		year: number
	): BudgetYear {
		let budgetYear: BudgetYear | undefined;
		if (!budget.budgetYears) budget.budgetYears = [];
		else budgetYear = this.getBudgetYearByYear(budget, year);
		if (!budgetYear) {
			budgetYear = {
				year: year,
			};
			budget.budgetYears.push(budgetYear);
			this.update(budgetYear);
			this.update(companyEconomy);
		}
		return budgetYear;
	}

	getBudgetYearByYear(budget: Budget, year: number): BudgetYear | undefined {
		if (!budget.budgetYears) return;
		let brs = budget.budgetYears.filter((x) => x.year === year);
		if (brs.length === 1) {
			return brs[0];
		} else {
			return;
		}
	}

	ensureBudgetAttachmentRequests(c:Case) {
		if( c.companies ) {
			c.companies.forEach(company => {
				
				let v = company.name||UiModelConverter.prettyOrgNr(company.orgNumber);

				let years = this.getRequiredYearsOfBudget();
				years.forEach(year => { 
					const requestVariation = v + ", år " + year;
					this.attachmentService.ensureAttachmentRequest(c, {
						id: this.idService.newGuid(),
						customerId: company.orgNumber,
						requestType:"COMPANYBUDGET",
						requestVariation: requestVariation
					});
				})
			});
		}
	}


	validateBudgets(c: Case): ValidationResult {
		const vr = new ValidationResult();
		if( c.companyEconomies ) {
			c.companyEconomies.forEach(companyEconomy => {
				const companyEconomyHasBudget = this.companyEconomyHasBudget(c, companyEconomy);
				vr.add({object:companyEconomy, validator:this.companyEconomyHasBudget, ok: companyEconomyHasBudget});
			});
		}
		return vr;
	}

	companyEconomyHasBudget(c:Case, companyEconomy: CompanyEconomy):boolean {
		return this.getBudgetByCompanyEconomy(c, companyEconomy)!==undefined;
	}

}
