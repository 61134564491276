import React from 'react';
import { Applicant, KYC } from '../../../../models/SelmaModels';
import { AppComponent } from '../../../AppComponent';
import { FormUnit, FormUnitSize } from '../../../layout/FormUnit/FormUnit';
import { Label } from '../../../widget/display/Label/Label';
import { T } from '../../../widget/display/T';
import { RadiobuttonGroup, RadioOption } from '../../../widget/input/RadiobuttonGroup/RadiobuttonGroup';
import { ApplicantService } from '../../../../services/ApplicantService';
import { Heading } from '../../../widget/display/Heading/Heading';
import { Validation } from '../../../widget/display/Validation/Validation';

interface Props {
	applicant: Applicant;
}

const PepYouOptions = [
	{
		group: 'pepYou',
		id: 'yes',
		value: 'yes',
		label: 'Ja',
	},
	{
		group: 'pepYou',
		id: 'no',
		value: 'no',
		label: 'Nej'
	},
];

const PepRelatedOptions = [
	{
		group: 'pepRelated',
		id: 'yes',
		value: 'yes',
		label: 'Ja',
	},
	{
		group: 'pepRelated',
		id: 'no',
		value: 'no',
		label: 'Nej',
	},
];

const AmericanCitizenOptions = [
	{
		group: 'americanCitizen',
		id: 'yes',
		value: 'yes',
		label: 'Ja',
	},
	{
		group: 'americanCitizen',
		id: 'no',
		value: 'no',
		label: 'Nej',
	},
];

const CrsTaxObligationOptions = [
	{
		group: 'CrsTaxObligation',
		id: 'yes',
		value: 'yes',
		label: 'Ja',
	},
	{
		group: 'CrsTaxObligation',
		id: 'no',
		value: 'no',
		label: 'Nej',
	},
];


export class ApplicantPep extends AppComponent<Props> {
	applicantService:ApplicantService;
	pepYouOptions:RadioOption[];
	pepRelatedOptions:RadioOption[];
	americanCitizenOptions:RadioOption[];
	crsTaxObligationOptions:RadioOption[];
	id: number;

	constructor(props: Props) {
		super(props);

		this.applicantService = this.application.services.applicantService;

		this.observeUpdate(this.props.applicant);

		this.id = this.application.services.idService.newId();

		this.pepYouOptions = this.mapRadiogroupOptionsWithId(PepYouOptions, this.id.toString());
		this.pepRelatedOptions = this.mapRadiogroupOptionsWithId(PepRelatedOptions, this.id.toString());
		this.americanCitizenOptions = this.mapRadiogroupOptionsWithId(AmericanCitizenOptions, this.id.toString());
		this.crsTaxObligationOptions = this.mapRadiogroupOptionsWithId(CrsTaxObligationOptions, this.id.toString());
	}

	mapRadiogroupOptionsWithId = (options:RadioOption[], id: string) : RadioOption[] => {
		return options.map(x => {
			let k = id+"_";
			return {
				group: k+x.group,
				value: x.value,
				label: x.label
			}
		});
	}

	ensureKyc() : KYC {
		if (!this.props.applicant.kyc) {
			this.props.applicant.kyc = {
				id: this.application.services.idService.newGuid(),
				approvedCreditCheck: false,
				approvedInfoHandling: false,
				approvedInfoSharing: false
			};
			this.observeUpdate(this.props.applicant.kyc);
		}
		return this.props.applicant.kyc;
	}

	pepYouChanged = (value: string): void => {
		let kyc = this.ensureKyc();
		kyc.isPep = value==='yes'?true:false;
		this.application.services.applicantService.update(this.props.applicant);
	};

	pepRelatedChanged = (value: string): void => {
		let kyc = this.ensureKyc();
		kyc.isPepRelated = value==='yes'?true:false;
		this.application.services.applicantService.update(this.props.applicant);
	};

	americanCitizenChanged = (value: string): void => {
		let kyc = this.ensureKyc();
		kyc.isAmericanCitizen = value==='yes'?true:false;
		this.application.services.applicantService.update(this.props.applicant);
	};

	crsTaxObligationChanged = (value: string): void => {
		let kyc = this.ensureKyc();
		kyc.isCrsTaxObligated = value==='yes'?true:false;
		this.application.services.applicantService.update(this.props.applicant);
	};	

	render() {
		let kyc = this.ensureKyc();

		const applicant = this.props.applicant;

		let pepYouValue:string|undefined = undefined;
		if( kyc.isPep === true )
			pepYouValue = 'yes';
		else if( kyc.isPep === false )
			pepYouValue = 'no';

		let pepRelatedValue:string|undefined = undefined;
		if( kyc.isPepRelated === true )
			pepRelatedValue = 'yes';
		else if( kyc.isPepRelated === false )
			pepRelatedValue = 'no';

		let americanCitizenValue:string|undefined = undefined;
		if( kyc.isAmericanCitizen === true )
			americanCitizenValue = 'yes';
		else if( kyc.isAmericanCitizen === false )
			americanCitizenValue = 'no';

		let crsTaxObligationValue:string|undefined = undefined;
		if( kyc.isCrsTaxObligated === true )
			crsTaxObligationValue = 'yes';
		else if( kyc.isCrsTaxObligated === false )
			crsTaxObligationValue = 'no';		

		return (
			<div className="ApplicantPep">
				<FormUnit size={FormUnitSize.Large}>
					<Heading
						level={3}
						labelTextkey="Applicant_Pep_RequiredQuestion"
					/>
				</FormUnit>
				<FormUnit size={FormUnitSize.Large}>
					<Label labelTextkey="Applicant_Pep" required={true} />
					<p>
						<T k="Applicant_Pep_You" />
					</p>
					<Validation
						forObject={applicant}
						errors={[
							{
								validator: this.applicantService.hasKycPep,
								textkey: 'Error_SelectValue'
							}
						]}>
						<RadiobuttonGroup
							orientation={'horizontal'}
							value={pepYouValue}
							options={this.pepYouOptions}
							handleChange={this.pepYouChanged}
						/>
					</Validation>
				</FormUnit>

				<FormUnit size={FormUnitSize.Large}>
					<Label
						labelTextkey="Applicant_Pep_Related"
						required={true}
					/>
					<p>
						<T k="Applicant_Pep_Related_Description" />
					</p>
					<Validation
						forObject={applicant}
						errors={[
							{
								validator: this.applicantService.hasKycPepRelated,
								textkey: 'Error_SelectValue'
							}
						]}>
						<RadiobuttonGroup
							orientation={'horizontal'}
							value={pepRelatedValue}
							options={this.pepRelatedOptions}
							handleChange={this.pepRelatedChanged}
						/>
					</Validation>
				</FormUnit>

				<FormUnit size={FormUnitSize.Large}>
					<Label
						labelTextkey="Applicant_AmericanCitizen"
						required={true}
					/>
					<p>
						<T k="Applicant_AmericanCitizen_Description" />
					</p>
					<Validation
						forObject={applicant}
						errors={[
							{
								validator: this.applicantService.hasKycAmericanCitizen,
								textkey: 'Error_SelectValue'
							}
						]}>
						<RadiobuttonGroup
							orientation={'horizontal'}
							value={americanCitizenValue}
							options={this.americanCitizenOptions}
							handleChange={this.americanCitizenChanged}
						/>
					</Validation>
				</FormUnit>

				<FormUnit size={FormUnitSize.Large}>
					<Label
						labelTextkey="Applicant_CrsTaxObligation"
						required={true}
					/>
					<p>
						<T k="Applicant_CrsTaxObligation_Description" />
					</p>
					<Validation
						forObject={applicant}
						errors={[
							{
								validator: this.applicantService.hasKycCrsTaxObligation,
								textkey: 'Error_SelectValue'
							}
						]}>
						<RadiobuttonGroup
							orientation={'horizontal'}
							value={crsTaxObligationValue}
							options={this.crsTaxObligationOptions}
							handleChange={this.crsTaxObligationChanged}
						/>
					</Validation>
				</FormUnit>
			</div>
		);
	}
}
