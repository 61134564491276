import React from 'react';
import { Loan } from '../../../../../models/SelmaModels';
import { AppComponent } from '../../../../AppComponent';
import {
	FormUnit,
	FormUnitSize,
	FormUnitPadding,
} from '../../../../layout/FormUnit/FormUnit';
import { T } from '../../../../widget/display/T';
import { uiLoanDownpaymentTypes } from '../../../../../models/UiModels';
import KeyValueList from '../../../../widget/display/KeyValueList/KeyValueList';

interface Props {
	loan: Loan;
}

export class LoanDownpaymentSummary extends AppComponent<Props> {
	constructor(props: Props) {
		super(props);
	}

	getDownpaymentText () {
		const loan = this.props.loan;

		if (
			loan.downpaymentType === undefined ||
			loan.downpaymentType === null
		)
			return false;

		let downpaymentType = uiLoanDownpaymentTypes[loan.downpaymentType].name;

		const loanService = this.application.services.loanService;
		const askAboutDownpaymentOther = loanService.askAboutDownpaymentOther(loan);
		if( askAboutDownpaymentOther )
			downpaymentType += `, ${loan.downpaymentOther}`;

		return downpaymentType;
	}


	render() {
		return (
			<FormUnit size={FormUnitSize.Large} padding={FormUnitPadding.Small}>
				<h3>
				</h3>
				<KeyValueList>
					{[
						{
							key: <T k="Summary_Loan_Downpayment_Source" />,
							value: this.getDownpaymentText()
						},
					]}
				</KeyValueList>
			</FormUnit>
		);
	}
}
