import React from 'react';
import { Case, Company } from '../../../../models/SelmaModels';
import { Applicant } from "../../../../models/selmamodels/Applicant";
import { AppComponent } from '../../../AppComponent';
import { FormUnit, FormUnitSize, FormUnitPadding } from '../../../layout/FormUnit/FormUnit';
import { Label } from '../../../widget/display/Label/Label';
import { T } from '../../../widget/display/T';
import { ApplicantService, SearchForApplicantResult } from '../../../../services/ApplicantService';
import { THtml } from '../../../widget/display/THtml';
import { Button } from '../../../widget/input/Button/Button';
import { CompanySelect } from '../StepCompany/CompanySelect';
import { Textbox } from '../../../widget/input/Textbox/Textbox';
import { Heading } from '../../../widget/display/Heading/Heading';
import { FormUnitFieldAndButton } from '../../../layout/FormUnitFieldAndButton/FormUnitFieldAndButton';

interface Props {
	case: Case;
	addApplicant: (applicant: Applicant, company: Company | undefined, persOrgNumber: string) => void;
}

interface State {
	persOrgNumber: string;
	searchResult?: SearchForApplicantResult;
	selectedCompany?: Company;
	selectedApplicant?: Applicant;
}

export class AddApplicant extends AppComponent<Props, State> {
	applicantService: ApplicantService;

	constructor(props: Props) {
		super(props);

		this.applicantService = this.application.services.applicantService;

		this.state = {
			persOrgNumber: ""
		}
	}

	persOrgNumberChanged = (event: React.ChangeEvent<HTMLInputElement>): void => {
		let value = event.target.value;

		const inputType: string = event.nativeEvent['inputType'] || '';

		if (inputType.startsWith('delete')) {
			this.setState({
				persOrgNumber: value,
				searchResult: undefined,
				selectedCompany: undefined,
				selectedApplicant: undefined
			});
			return;
		}

		value = value.trim();

		const civicNumberRegex = /^(19|20)\d{2}(0[1-9]|1[0-2])(0[1-9]|[12][0-9]|3[01])$/;
		if (civicNumberRegex.test(value)) {
			value = value + '-';
		}

		const civicNumberWithDigitsRegex = /^(19|20)\d{2}(0[1-9]|1[0-2])(0[1-9]|[12][0-9]|3[01])\d{4}$/;
		if (civicNumberWithDigitsRegex.test(value)) {
			value = `${value.substring(0, 8)}-${value.substring(8)}`;
		}

		this.setState({
			persOrgNumber: value,
			searchResult: undefined,
			selectedCompany: undefined,
			selectedApplicant: undefined
		});
	};

	getSearchTerm(): string {
		let searchTerm = this.state.persOrgNumber || '';
		searchTerm = searchTerm.trim();
		searchTerm = searchTerm.replace(new RegExp("[ \\t-]+", "g"), "");
		return searchTerm;
	}

	searchApplicant = (): void => {
		let searchTerm = this.getSearchTerm();
		if (!searchTerm || searchTerm == "")
			return;

		this.applicantService
			.searchForApplicant(this.props.case, searchTerm)
			.then(x => {
				// Found no companies and one person.
				if (x && x.applicant && (!x.companies || x.companies.length === 0)) {
					this.addApplicant(x.applicant, undefined);
					return;
				}

				// Found single company.
				if (x && x.applicant && x.companies && x.companies.length === 1) {
					const guardService = this.application.services.guardService;
					let company = x.companies[0];
					if (company.orgNumber && company.legalGroupCode && guardService.compareCustomerId(company.orgNumber, searchTerm) && company.legalGroupCode != "EF") {
						this.addApplicant(x.applicant, company);
						return;
					}
				}

				// Show result for user to resolve.
				this.setState({
					searchResult: x
				});
			})
			.catch(() => {
				this.setState({
					searchResult: {
						tooManyRequests: true
					}
				});
			});
	}

	addApplicant = (applicant: Applicant, company: Company | undefined): void => {
		let persOrgNumber = this.state.persOrgNumber;
		this.setState({
			persOrgNumber: '',
			searchResult: undefined,
			selectedCompany: undefined,
			selectedApplicant: undefined
		},
			() => {
				this.props.addApplicant(applicant, company, persOrgNumber);
				this.scrollToApplicantsTabs();
			});
	}

	companySelected = (company: Company, companies: Company[]): void => {
		if (company) {
			this.setState({
				selectedCompany: company,
				selectedApplicant: undefined
			});
		}
	};

	applicantSelected = (applicant: Applicant): void => {
		this.setState({
			selectedCompany: undefined,
			selectedApplicant: applicant
		});
	};

	addPersonOrCompanyAsApplicant = (): void => {
		if (this.state.selectedApplicant) {
			this.addPrivatePersonAsApplicant();
		} else {
			this.addSelectedCompanyAsApplicant();
		}
	}

	addSelectedCompanyAsApplicant = (): void => {
		if (this.state.searchResult && this.state.searchResult.applicant && this.state.selectedCompany) {
			this.addApplicant(this.state.searchResult.applicant, this.state.selectedCompany);
			this.scrollToApplicantsTabs();
		}
	}

	addPrivatePersonAsApplicant = (): void => {
		if (this.state.searchResult && this.state.searchResult.applicant) {
			this.addApplicant(this.state.searchResult.applicant, undefined);
			this.scrollToApplicantsTabs();
		}
	}

	// canAddApplicantAsPrivatePerson(searchResult:SearchForApplicantResult|undefined, persOrgNumber:string):boolean {
	// 	const g = this.application.services.guardService;

	// 	const hasValidSsn = g.isValidSsn(persOrgNumber);
	// 	const foundValidApplicant = searchResult && searchResult.applicant?true:false;
	// 	const searchResultContainsApplicantEF = (searchResult?.companies?.filter(x => x.legalGroupCode === "EF" && g.compareCustomerId(x.orgNumber ?? "", persOrgNumber)).length ?? 0) > 0;

	// 	return hasValidSsn
	// 		&& foundValidApplicant
	// 		&& searchResultContainsApplicantEF === false;
	// }

	scrollToApplicantsTabs() {
		window.requestAnimationFrame(function () {
			const yOffset = -150;
			const el = document.getElementById("ApplicantTabsAnchor")
			if (el) {
				const y = el.getBoundingClientRect().top + window.pageYOffset + yOffset;
				console.log("scroll into view");
				window.scrollTo({ top: y, behavior: 'smooth' });
			}
		})
	}

	render() {
		const g = this.application.services.guardService;

		const persOrgNumber = this.state.persOrgNumber;
		const searchResult = this.state.searchResult;
		const selectedCompany = this.state.selectedCompany;
		const selectedApplicant = this.state.selectedApplicant;
		const searchTerm = this.getSearchTerm();
		const hasSearchResultData = searchResult &&
			searchResult.applicant &&
			searchResult.companies &&
			searchResult.companies.length > 0;

		const canSearch = !searchResult && searchTerm.length >= 10 && (g.isValidSsn(searchTerm) || g.isValidOrgNr(searchTerm));
		const canAdd = (selectedCompany || selectedApplicant) ? true : false;
		//const canAddApplicantAsPrivatePerson = this.canAddApplicantAsPrivatePerson(searchResult, persOrgNumber);
		const showSearchButton = hasSearchResultData ? false : true;

		// let selectedApplicant:Applicant|undefined;
		// if( this.state.selectedApplicant && this.state.searchResult)
		// 	selectedApplicant = this.state.searchResult.applicant;


		return (
			<div className="AddApplicant">
				<FormUnit size={FormUnitSize.Large}>
					<Heading
						level={2}
						labelTextkey="Applicant_AddApplicant_Title"
					/>
					<p>
						<THtml k="Applicant_AddApplicant_Description" />
					</p>
				</FormUnit>

				<FormUnit padding={FormUnitPadding.None} size={FormUnitSize.Large}>
					<Label
						for="persOrgNumber"
						labelTextkey="Applicant_AddApplicant_PersOrgNumber"
					/>
					<FormUnitFieldAndButton className="FormUnitFieldAndButton AddApplicantPersonNumber">
						<Textbox
							id="persOrgNumber"
							value={persOrgNumber}
							handleChange={this.persOrgNumberChanged}
							maxlength={20}
						/>
						{showSearchButton && (
							<Button
								labelTextkey="Applicant_AddApplicant"
								className="Button"
								onClick={this.searchApplicant}
								enabled={canSearch}
							/>
						)}
					</FormUnitFieldAndButton>
				</FormUnit>

				{(searchResult && !hasSearchResultData && !searchResult.tooManyRequests) &&
					<p>
						<THtml k="Applicant_AddApplicant_NoHits" />
					</p>
				}
				{(searchResult && searchResult.tooManyRequests) &&
					<p>
						<THtml k="Error_TooManyRequests" />
					</p>
				}

				{searchResult && hasSearchResultData && searchResult.companies && (
					<>
						<FormUnit size={FormUnitSize.Large}>
							<CompanySelect
								companies={searchResult.companies}
								selectedCompany={selectedCompany}
								selectedApplicant={selectedApplicant}
								companySelected={this.companySelected}
								applicantSelected={this.applicantSelected}
								applicant={searchResult.applicant}
							/>
						</FormUnit>

						<FormUnit size={FormUnitSize.Large} padding={FormUnitPadding.None}>
							<FormUnitFieldAndButton>
								<Button
									labelTextkey="Applicant_AddSelectedApplicant"
									className="Button plus-icon"
									onClick={this.addPersonOrCompanyAsApplicant}
									enabled={canAdd}
								/>
								{/*canAddApplicantAsPrivatePerson && (
										<Button
											labelTextkey="Applicant_AddApplicantAsPrivatePerson"
											className="Button plus-icon"
											onClick={this.addApplicantAsPrivatePerson}
										/>
									)*/}
							</FormUnitFieldAndButton>
						</FormUnit>
					</>
				)}
			</div>
		);
	}
}
