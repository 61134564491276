import { BaseService } from './BaseService';
import {
	Case,
	CompanyEconomy,
	Budget,
	BudgetYear,
	Applicant,
} from '../models/SelmaModels';
import { Company, Revenue } from '../models/selmamodels/Company';
import { SMELPOService } from './SMELPOService';
import { StateService } from './StateService';
import { ValidationResult } from '../utils/ValidationResult';
import { GuardService } from './GuardService';
import { CompanyService } from './CompanyService';
import { CaseService } from './CaseService';
import { IdService } from './IdService';
import { ApplicantService } from './ApplicantService';
import { BudgetService } from './BudgetService';

/*
Handles all logic for CompanyEconomy, Budget, BudgetYears.
*/
export class CompanyEconomyService extends BaseService {
	budgetService?: BudgetService;

	constructor(
		private stateService: StateService,
		private smelpoService: SMELPOService,
		private guardService: GuardService,
		private caseService: CaseService,
		private companyService: CompanyService,
		private idService: IdService,
		private applicantService: ApplicantService,
		
	) {
		super();
		this.stateService = stateService;
		this.smelpoService = smelpoService;
		this.guardService = guardService;
	}

	update(companyEconomy: CompanyEconomy | Case | Budget | BudgetYear): void {
		this.stateService.update(companyEconomy);
	}

	newCompanyEconomy(c: Case, company: Company): CompanyEconomy {
		let companyEconomy = {
			id: this.idService.newGuid(),
			companyId: company.id,
		};
		return companyEconomy;
	}

	addCompanyEconomy(c: Case, companyEconomy: CompanyEconomy): void {
		if (!c.companyEconomies) {
			c.companyEconomies = [];
		}
		c.companyEconomies.push(companyEconomy);
		this.update(c);
	}

	getCompanyEconomy(c: Case, company: Company | undefined): CompanyEconomy | undefined {
		if (!company || !company.id || company.id === '') return;
		let companyEconomy = this.getCompanyEconomyByCompanyId(c, company.id);
		return companyEconomy;
	}

	removeCompanyEconomy(c:Case, companyEconomy:CompanyEconomy) { 
		if( c.companyEconomies ) {
			this.stateService.listRemove(c.companyEconomies, companyEconomy);

			if( this.budgetService ) {
				let budget = this.budgetService.getBudgetByCompanyEconomy(c, companyEconomy);
				if( budget ) {
					this.budgetService.removeBudget(c, budget);
				}
			}

			this.update(c);
		}
	}

	getCompanyEconomyByCompanyId(c: Case, companyId: string): CompanyEconomy | undefined {
		if (!companyId || companyId === '') throw new Error('No company id.');
		let companyEconomy: CompanyEconomy | undefined;
		if (!c.companyEconomies) {
			c.companyEconomies = [];
		} else {
			let ces = c.companyEconomies.filter((x) => x.companyId === companyId);
			if (ces.length === 1) companyEconomy = ces[0];
		}
		return companyEconomy;
	}

	ensureCompanyEconomy(c: Case, company: Company): CompanyEconomy {
		let companyEconomy = this.getCompanyEconomyByCompanyId(c, company.id);
		if (!companyEconomy) {
			companyEconomy = this.newCompanyEconomy(c, company);
			this.addCompanyEconomy(c, companyEconomy);
		}
		return companyEconomy;
	}

	ensureRequiredRevenueYears(companyEconomy: CompanyEconomy) {
		this.getRequiredYearsOfRevenue().forEach((year) => {
			this.ensureRevenueYear(companyEconomy, year);
		});
	}

	setNoRevenueYears(companyEconomy: CompanyEconomy) {
		if( !companyEconomy.revenues || companyEconomy.revenues.length>0 ) {
			companyEconomy.revenues = [];
			this.update(companyEconomy);
		}
	}

	hasRevenue(companyEconomy: CompanyEconomy) : boolean|undefined {
		if( !companyEconomy.revenues ) {
			return; // no array.
		} else if( companyEconomy.revenues.length>0 ) { // array with item(s)
			return true;
		} else {
			return false; // Empty array.
		}
	}

	hasRevenueSelected(companyEconomy: CompanyEconomy) : boolean {
		let hasRevenue = this.hasRevenue(companyEconomy);
		return this.guardService.hasValue(hasRevenue);
	}

	
	private ensureRevenueYear(
		companyEconomy: CompanyEconomy,
		year: number
	): BudgetYear {
		let revenue: Revenue | undefined;
		if (!companyEconomy.revenues) companyEconomy.revenues = [];
		else revenue = this.getRevenueByYear(companyEconomy, year);
		if (!revenue) {
			revenue = {
				year: year,
				revenue: undefined
			};
			companyEconomy.revenues.push(revenue);
			this.update(companyEconomy);
		}
		return revenue;
	}

	getRevenueByYear(companyEconomy: CompanyEconomy, year: number): Revenue | undefined {
		if (!companyEconomy.revenues) return;
		let r = companyEconomy.revenues.filter((x) => x.year === year);
		if (r.length === 1) {
			return r[0];
		} else {
			return;
		}
	}

	getRequiredYearsOfRevenue(): number[] {
		const thisYear = new Date().getFullYear();
		const minYearsOfBudget = 2;
		const firstYear = thisYear - minYearsOfBudget + 1;
		const lastYear = thisYear;

		let years: number[] = [];
		for (let year = firstYear; year <= lastYear; year++) {
			years.push(year);
		}
		return years;
	}

	setRevenueValue(
		companyEconomy: CompanyEconomy,
		revenue: Revenue,
		reveneuValue: number | undefined
	) {
		revenue.revenue = reveneuValue;
		this.update(revenue);
		this.update(companyEconomy);
	}

	validateMySelectedCompanies(c: Case): ValidationResult {
		const myApplicant = this.applicantService.getMyApplicant(c);
		let companies = this.companyService.getMySelectedCompanies(c);
		let vr = new ValidationResult();
		if( companies && companies.length ) {
			companies.forEach(company => {
				vr.addResult(this.validateCompany(c, company, myApplicant));
			})
		}
		return vr;
	}

	validateCompany(c: Case, company:Company, applicant: Applicant): ValidationResult {
		let companyEconomy:CompanyEconomy|undefined;
		if( company ) {
			companyEconomy = this.getCompanyEconomy(c, company);
		}
		let vr = new ValidationResult();
		if( company && companyEconomy ) {
			vr.add({validator: this.hasRevenueSelected, object: companyEconomy, ok: this.hasRevenueSelected(companyEconomy) })

			let hasRevenue = companyEconomy && this.hasRevenue(companyEconomy);
			if( hasRevenue===true ) {
				vr.addResult(this.applicantService.validateHasSelectedHighRiskCategory(applicant));
				vr.addResult(this.validateRequiredRevenues(companyEconomy));
				vr.addResult(this.companyService.validateHasMinimumBussinessFocuses(company));
				vr.addResult(this.companyService.validateBussinessFocuses(company));
			}
		}
		return vr;
	}

	private getRequiredRevenues(companyEconomy: CompanyEconomy): 
		{ year: number; revenue: Revenue | undefined }[] {
		let years = this.getRequiredYearsOfRevenue();
		let r = years.map((year) => {
			return {
				year: year,
				revenue: this.getRevenueByYear(companyEconomy, year),
			};
		});
		return r;
	}

	private validateRequiredRevenues(companyEconomy: CompanyEconomy): ValidationResult {
		let vr = new ValidationResult();
		if (
			!companyEconomy ||
			!companyEconomy.revenues ||
			!companyEconomy.revenues
		)
			return vr;

		this.getRequiredRevenues(companyEconomy).forEach((x) => {
			if( x.revenue ) {
				vr.add({
					validator: this.revenueHasZeroOrHigherRevenue,
					object: x.revenue,
					ok: this.revenueHasZeroOrHigherRevenue(x.revenue),
				});
			} else {
				vr.add({
					validator: this.getRevenueByYear,
					object: companyEconomy,
					args: [x.year],
					ok: false,
				});
			}
		});
		return vr;
	}

	revenueHasZeroOrHigherRevenue(revenue: Revenue): boolean {
		const g = this.guardService;
		return g.isZeroOrGreater(revenue.revenue);
	}
	
}
