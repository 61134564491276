import React from 'react';
import { Case } from '../../../../../models/SelmaModels';
import { AppComponent } from '../../../../AppComponent';
import {
	FormUnit,
	FormUnitSize,
	FormUnitPadding,
} from '../../../../layout/FormUnit/FormUnit';

import { T } from '../../../../widget/display/T';
import Table from '../../../../widget/display/Table/Table';
import { Heading } from '../../../../widget/display/Heading/Heading';

interface Props {
	case: Case;
}

export class CollateralRealEstateSummary extends AppComponent<Props> {
	constructor(props: Props) {
		super(props);
	}

	render() {
		const c = this.props.case;
		if (!c.collaterals || !c.collaterals.length) return null;

		return (
			<FormUnit size={FormUnitSize.Normal} padding={FormUnitPadding.Normal}>
				<Heading level={3} labelTextkey="Summary_Collateral_RealEstate"/>
				<Table
					headingRow={[
						<T key={"A"} k="Summary_Collateral_Table_Name" />,
						<T key={"B"} k="Summary_Collateral_Table_Municipality" />,
					]}
					tableRows={c.collaterals.map(({ code, municipality }) => [
						code ? code : <T key={"A"} k="Summary_Collateral_Code_Missing" />,
						municipality,
					])}
				/>
			</FormUnit>
		);
	}
}
