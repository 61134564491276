// tslint:disable
/**
 * SelmaSME API
 * This definition is the API between customer SME-Gui and the persistence layer for the application NOT FOR V1 When customer logg-in to Selma-EN, we will create a processId with applicant CustomerId (from BankID). When a customer added attachment to the application, we don\'t have a CaseId i LP, should we add a LP-Service to create/reserve a caseId to be able to attach a document to the caseId in DM.
 *
 * The version of the OpenAPI document: 0.7.5
 * Contact: developer@landshypotek.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AimType,
    AimTypeFromJSON,
    AimTypeToJSON,
    DownPaymentType,
    DownPaymentTypeFromJSON,
    DownPaymentTypeToJSON,
    TakeoverLoanType,
    TakeoverLoanTypeFromJSON,
    TakeoverLoanTypeToJSON,
} from './';

/**
 * This represent the customer loan structure
 * @export
 * @interface LoanType
 */
export interface LoanType {
    /**
     * application model unique identification
     * @type {string}
     * @memberof LoanType
     */
    processId?: string;
    /**
     * Loan identity
     * @type {string}
     * @memberof LoanType
     */
    loanId: string;
    /**
     * This will only be a temporary loanNumber, when can we reserve a loan number in SAP
     * @type {string}
     * @memberof LoanType
     */
    loanNumber?: string;
    /**
     * Övertag från annan bank, fix för att övertag inte finns i LP (deprecated) moved to takeoverLoan
     * @type {boolean}
     * @memberof LoanType
     */
    loanTakeOver?: boolean;
    /**
     * Applicants want to loan this amount
     * @type {number}
     * @memberof LoanType
     */
    loanAmount?: number;
    /**
     * 
     * @type {DownPaymentType}
     * @memberof LoanType
     */
    downPaymentType?: DownPaymentType;
    /**
     * Om val Övrigt på downPaymentType ska text anges i GUI och hamna i detta fält.
     * @type {string}
     * @memberof LoanType
     */
    downPaymentOther?: string;
    /**
     * Kontantinsats
     * @type {number}
     * @memberof LoanType
     */
    downPaymentAmount?: number;
    /**
     * Ansvarig handläggare skriver en ärende- och ändamålsbeskrvning. Icke obligatoriskt.
     * @type {string}
     * @memberof LoanType
     */
    purposeDescription?: string;
    /**
     * Each loan can have it\'s own aim.
     * @type {Array<AimType>}
     * @memberof LoanType
     */
    aims?: Array<AimType>;
    /**
     * Array list of TakeoverLoanType (TakeoverLoan)
     * @type {Array<TakeoverLoanType>}
     * @memberof LoanType
     */
    takeoverloans?: Array<TakeoverLoanType>;
}

export function LoanTypeFromJSON(json: any): LoanType {
    return {
        'processId': !exists(json, 'processId') ? undefined : json['processId'],
        'loanId': json['loanId'],
        'loanNumber': !exists(json, 'loanNumber') ? undefined : json['loanNumber'],
        'loanTakeOver': !exists(json, 'loanTakeOver') ? undefined : json['loanTakeOver'],
        'loanAmount': !exists(json, 'loanAmount') ? undefined : json['loanAmount'],
        'downPaymentType': !exists(json, 'downPaymentType') ? undefined : DownPaymentTypeFromJSON(json['downPaymentType']),
        'downPaymentOther': !exists(json, 'downPaymentOther') ? undefined : json['downPaymentOther'],
        'downPaymentAmount': !exists(json, 'downPaymentAmount') ? undefined : json['downPaymentAmount'],
        'purposeDescription': !exists(json, 'purposeDescription') ? undefined : json['purposeDescription'],
        'aims': !exists(json, 'aims') ? undefined : (json['aims'] as Array<any>).map(AimTypeFromJSON),
        'takeoverloans': !exists(json, 'takeoverloans') ? undefined : (json['takeoverloans'] as Array<any>).map(TakeoverLoanTypeFromJSON),
    };
}

export function LoanTypeToJSON(value?: LoanType): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'processId': value.processId,
        'loanId': value.loanId,
        'loanNumber': value.loanNumber,
        'loanTakeOver': value.loanTakeOver,
        'loanAmount': value.loanAmount,
        'downPaymentType': DownPaymentTypeToJSON(value.downPaymentType),
        'downPaymentOther': value.downPaymentOther,
        'downPaymentAmount': value.downPaymentAmount,
        'purposeDescription': value.purposeDescription,
        'aims': value.aims === undefined ? undefined : (value.aims as Array<any>).map(AimTypeToJSON),
        'takeoverloans': value.takeoverloans === undefined ? undefined : (value.takeoverloans as Array<any>).map(TakeoverLoanTypeToJSON),
    };
}


