import React from 'react';
import { Case } from '../../models/SelmaModels';
import { CaseSteps } from './CaseSteps';
import { PageContent, PageStyle } from '../layout/PageContent/PageContent';
import { T } from '../widget/display/T';
import { StatusBar } from '../widget/display/StatusBar/StatusBar';
import { StatusIndicator } from '../widget/display/StatusIndicator/StatusIndicator';
import { AppComponent } from '../AppComponent';
import { StepDefinition } from '../../services/StepService';
import { StepState, CaseStepper } from './StepState';
import { RouteComponentProps, withRouter } from 'react-router';
import { ValidationResult } from '../../utils/ValidationResult';
import './CasePage.scss';

interface Props extends RouteComponentProps<any> {
	case: Case;
}

interface State {
	step?: StepDefinition;
}

class CasePage extends AppComponent<Props, State> {
	stepper: CaseStepper;

	constructor(props: Props) {
		super(props);

		this.stepper = new CaseStepper(
			this.application.services.stepService,
			this.props.case
		);
		this.stepper.onStepped = (stepper, c, step) => {
			this.stepStateChanged(stepper, c, step);
		};

		this.state = {
			step: undefined,
		};
	}

	componentDidMount() {
		const c = this.props.case;
		this.observeDeep(c, (x)=> {
			this.stepper.validateCurrent();
			this.refresh();
		});
	}

	stepStateChanged = (
		stepper: CaseStepper,
		c: Case,
		step?: StepDefinition
	): void => {
		this.setState({
			step: step,
		});
	}

	goToStep = (step: StepDefinition): void => {
		this.stepper.goTo(step);
	}

	goHome = () => {
		const c = this.props.case;
		const stepService = this.application.services.stepService;
		const step = this.stepper.definition;
		if( step ) {
			stepService.saveStep(step, c).then(result => {
				this.props.history.push("/");
			});
		}
	}

	validatedStep = (step:StepDefinition, result:ValidationResult) => {}

	render() {
		const c = this.props.case;
		const stepService = this.application.services.stepService;
		const currentStep = this.stepper.definition;
		const steps = this.stepper.states;

		return (
			<div className="Page CasePage">
				<PageContent style={PageStyle.Gray}>
					<aside className="Aside">
						<StatusBar>
							{ (props) => steps.map((stepState, ix) => {

									try {
										const step = stepState.step;
										const isCurrentStep = step === currentStep;
										const validationResult = this.stepper.getValidation(step);
										const progress = stepService.calcStepProgressForValidationResult(validationResult);
										const canGoToStep = stepService.canGoToStep(step, c);
										const warning = stepService.stepNeedsComplement(step, c);

										return (
											<div
												className={
													'StatusBarRow ' +
													(isCurrentStep ? 'CurrentStep ' : ' ') +
													(canGoToStep ? 'CanGoToStep ' : 'CanNotGoToStep ' )
												}
												key={'statusIndicator_'+step.type}
												onClick={(e) => {
													this.goToStep(step);
												}}>
												<span className="StatusLabel">
													<T k={step.nameTextKey} />
												</span>

												<StatusIndicator
													isCurrentStep={isCurrentStep}
													ordinal={ix+1}
													progress={progress}
													size={props.size}
													icon={warning?'warning':undefined}
												/>
											</div>
										);
									} catch {
										return null;
									}
								})
							}
						</StatusBar>
					</aside>
					<div className="Main">
						<CaseSteps
							case={this.props.case}
							stepper={this.stepper}
							step={currentStep}
							goHome={this.goHome}
							validatedStep={this.validatedStep}
						/>
					</div>
				</PageContent>
			</div>
		);
	}
}
export default withRouter(CasePage);
