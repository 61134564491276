import { StakeholderType } from "../../libs/SMELPOClient";

export interface KYC {
	id:string; // guid.

	highRiskCategoryCode?: string;
	isAmericanCitizen?: boolean;
	isCrsTaxObligated?: boolean;

	approvedInfoSharing?: boolean; // ucAware
	approvedCreditCheck?: boolean; // ucAccepted
	approvedInfoHandling?: boolean; // gdprAccepted

	approvedInfoSharingDB?: boolean; // ucAware saved in database
	approvedCreditCheckDB?: boolean; // ucAccepted saved in database
	approvedInfoHandlingDB?: boolean; // gdprAccepted saved in database

	isPep?: boolean;
	//isPepFunction?: string;
	isPepRelated?: boolean;
	//isPepRelatedFunction?: string;
}

export interface Applicant {
	id:string;
	//processId:string; // used by 
	customerId: string; // customerId is swedish ssn or corporate organisation number
	firstName?: string;
	lastName?: string;
	streetAddress?: string;
	postalAddress?: string;
	postalCode?: string;
	email?: string;
	emailConfirm?: string;
	mobilePhone?: string;
	notifyByEmail?: boolean;
	notifyByMobilePhone?: boolean;
	stakeholderType?: StakeholderType // StakeholderEnum;
	kyc?: KYC;
	willBuyAgriForestryProperty?: boolean;
}

export interface KycApplicant {
    customerId:string,
    role: string,
    questionnaireStatus: string
}

// export enum StakeholderEnum {
// 	BONDSMAN, // 'Borgensman'
// 	OPERATING_COMPANY, // 'Driftbolag'
// 	SPOUSE, // 'Maka/Make/regpartner'
// 	DOMESTIC_PARTNER, // 'sambo'
// 	BOND_OWNER, // 'ägarandel i pant'
// 	MAJORITY_OWNER, // 'ägare juridiskt över 50%'
// 	COMMUNITY_OF_INTERESTS, // 'övriga med ekonomisk intressegemenskap'
// 	APPLICANT // 'SOKANDE'
// }

export enum LPEmploymentEnum {
	//   employment mapping to Loan Process 
	PERMANENT, // - Anställd, tills-vidare-anställd 
	TEMPORARY, // - Tidbegränsad anställning 
	PROBATIONARY, // - Provanställning 
	UNEMPLOYMENT_INSURANCE, // - Arbetslöshetskassa 
	SELF_EMPLOYED, //- Eget företag 
	STUDENT_AID, // - Studiestöd 
	PENSION // - Inkomst av pension
}

/*
export enum SAPEmploymentEnum {
	//   employment mapping to Loan Process 
	PERMANENT, // - Anställd, tills-vidare-anställd 
	TEMPORARY, // - Tidbegränsad anställning 
	PROBATIONARY, // - Provanställning 
	UNEMPLOYMENT_INSURANCE, // - Arbetslöshetskassa 
	SELF_EMPLOYED, //- Eget företag 
	STUDENT_AID, // - Studiestöd 
	PENSION // - Inkomst av pension
}*/


/**
 * personal economy for KALP us
 * @export
 * @interface PersonalEconomy 
 */
export interface PersonalEconomy {
	id: string; // personalEconomyId
	//processId: string;
	customerId: string; // guid
	//sapEmployment?: SAPEmploymentEnum; // originally defined in Applicant
	//lpEmployment?: LPEmploymentEnum; // originally defined in Applicant
	income?: number; // // SEK / month //Inkomst av tjänst.
	yearlyIncome?: number; // SEK / year //Årlig inkomst från näringsverksamhet
	typeOfEmployment?: LPEmploymentEnum // EmployeementType;
	employer?: string; // name of company
	yearOfEmployment?: number;
	monthOfEmployment?: number;
}
