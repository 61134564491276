/* tslint:disable */
/* eslint-disable */
/**
 * SelmaSME API
 * This definition is the API between customer SME-Gui and the persistence layer for the application NOT FOR V1 When customer logg-in to Selma-EN, we will create a processId with applicant CustomerId (from BankID). When a customer added attachment to the application, we don\'t have a CaseId i LP, should we add a LP-Service to create/reserve a caseId to be able to attach a document to the caseId in DM.
 *
 * The version of the OpenAPI document: 0.7.4
 * Contact: developer@landshypotek.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * loanOwnerType
 * @export
 * @interface LoanOwnerType
 */
export interface LoanOwnerType {
    /**
     * customerId is swedish ssn or corporate organisation number
     * @type {string}
     * @memberof LoanOwnerType
     */
    customerId?: string;
    /**
     * Andel kund har i huset (MaintenanceType), gäller inte hyresrätt.
     * @type {number}
     * @memberof LoanOwnerType
     */
    ownershipPart?: number;
}

export function LoanOwnerTypeFromJSON(json: any): LoanOwnerType {
    return LoanOwnerTypeFromJSONTyped(json, false);
}

export function LoanOwnerTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): LoanOwnerType {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'customerId': !exists(json, 'customerId') ? undefined : json['customerId'],
        'ownershipPart': !exists(json, 'ownershipPart') ? undefined : json['ownershipPart'],
    };
}

export function LoanOwnerTypeToJSON(value?: LoanOwnerType | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'customerId': value.customerId,
        'ownershipPart': value.ownershipPart,
    };
}


