
import React, { Component } from 'react';
import { Case, Attachment } from '../../../../models/SelmaModels';
import { AppComponent } from '../../../AppComponent';
import { ProvidedAttachment } from './ProvidedAttachment';
import { T } from '../../../widget/display/T';

interface Props {
	case: Case;
	attachments: Attachment[];
	requestType?: string;
	customerId?: string;
	allowDelete?:boolean;
	showType?: boolean;
}

export class ProvidedAttachments extends AppComponent<Props> {

	constructor(props: Props) {
		super(props);
		this.observeUpdate(this.props.case);
	}


	render() {
		const c = this.props.case;
		let attachments = (this.props.requestType) ? this.props.attachments.filter((attachment:Attachment, index:number) => {
			return attachment.requestType === this.props.requestType;
		}):this.props.attachments;

		if(this.props.customerId)	{
			attachments = (this.props.customerId) ? this.props.attachments.filter((attachment:Attachment, index:number) => {
				return attachment.customerId === this.props.customerId;
			}):attachments;
		}

		return (
			(attachments && attachments.length > 0) ? (
			<div className="ProvidedAttachments">
				<table className="Table">
					<thead>
						<tr>
							{this.props.showType && <th><T k="Budget_ProvidedAttachments_Type"/></th>}
							<th><T k="Budget_ProvidedAttachments_File"/></th>
							<th className="align-right"><T k="Budget_ProvidedAttachments_Size"/></th>
							{ this.props.allowDelete && <th style={{width:"50px"}}></th> }
						</tr>
					</thead>
					<tbody>
					{ attachments.map((x:Attachment,ix:number) => {
						return <ProvidedAttachment key={ix} case={c} attachment={x} allowDelete={this.props.allowDelete} showType={this.props.showType} />
					})
					}
					</tbody>
				</table>
			</div>):null
		);
	}


}
