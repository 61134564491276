import React from 'react';
import { throttle } from 'lodash';
import ClassNames from 'classnames';
import { AppComponent } from '../../AppComponent';
import { Button } from '../../widget/input/Button/Button';
import { NavLink, withRouter, RouteComponentProps } from 'react-router-dom';
import { MobileNav } from '../../widget/display/MobileNav/MobileNav';
import { Media } from 'react-breakpoints';
import { T } from '../../widget/display/T';
import { SaveModal, ModalProps } from '../SaveModal/SaveModal';
import './Top.scss';
import { Row, Col } from 'react-flexbox-grid';
import { withAuth, AuthContextProps } from 'react-oidc-context';

interface Auth {
	auth: AuthContextProps
}

interface AuthProps {
	oidc: Auth; 
  }

interface Props extends AuthProps, RouteComponentProps<any> {}

interface State {
	logoutSaveModalIsOpen: boolean;
	navigateSaveModalIsOpen: boolean;
	compactTop: boolean;
}

class Top extends AppComponent<Props, State> {
	sessionService = this.application.services.sessionService;
	stateService = this.application.services.stateService;
	configurationService = this.application.services.configurationService;
	
	constructor(props) {
		super(props);

		this.state = {
			logoutSaveModalIsOpen: false,
			navigateSaveModalIsOpen: false,
			compactTop: false
		};

		this.observeUpdate(this.stateService.state.session);
		this.observeUpdate(this.stateService.state.session.login);
	}

	// Browser warning if user tries to close window or reload page (unload application)
	componentDidMount() {
		if (document.addEventListener as any) {
			window.addEventListener('beforeunload', this.handleBeforeUnload);
			window.addEventListener('scroll', throttle(this.handleWindowScroll, 150));
		}
	}

	handleBeforeUnload = (event) => {
		if (
			this.application.services.configurationService.protectAgainstPageLeave()
		) {
			if (this.props.location.pathname === '/case') {
				event.preventDefault();
				event.returnValue = '';
			}
		}
	};

	handleWindowScroll = (event) => {
		const scrollY =
			window.scrollY === undefined ? window.pageYOffset : window.scrollY;

		if (scrollY > 8) {
			this.setState({
				compactTop: true,
			});
		} else {
			this.setState({
				compactTop: false,
			});
		}
	};

	componentWillUnmount() {
		if (window.removeEventListener && this.handleBeforeUnload) {
			window.removeEventListener('beforeunload', this.handleBeforeUnload);

			window.removeEventListener(
				'scroll',
				throttle(this.handleWindowScroll, 150)
			);
		}
	}

	deleteAllCookies = () => {
		return new Promise((resolve) => {
			const cookies = document.cookie.split(";");
			for (let i = 0; i < cookies.length; i++) {
			const cookie = cookies[i];
			const eqPos = cookie.indexOf("=");
			const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
			document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/";
			}
			resolve(true);
		});
		}

	// Performs the actual logout
	logout = () => {
		this.sessionService.logout().then(() => {
			this.deleteAllCookies().then(_ => {
				this.props.oidc.auth.signoutRedirect({"post_logout_redirect_uri" : this.application.services.configurationService.oidcSettings().postLogoutRedirectUri})
			})
		});
	};

	// User clicks logout button
	handleClickOnLogout = () => {
		// Only show modal if user has an open case
		if (this.props.location.pathname === '/case') {
			this.setState({
				logoutSaveModalIsOpen: true,
			});
		} else {
			this.logout();
		}
	};

	// User clicks home link
	handleClickOnHome = (event) => {
		// Only show modal if user has an open case
		let askBeforeGoToHome = this.props.location.pathname === '/case';
		if (askBeforeGoToHome) {
			let c = this.application.services.caseService.getCurrentCase();
			if (c && c.status === 'STARTEDBYAPPLICANT') {
				askBeforeGoToHome = true;
			} else {
				askBeforeGoToHome = false;
			}
		}

		if (askBeforeGoToHome) {
			event.preventDefault();
			this.setState({
				navigateSaveModalIsOpen: true,
			});
		} else {
			this.props.history.push('/');
		}
	};

	// Logout modal - user clicks yes
	handleLogoutSaveClickYes = (event) => {
		this.setState({ logoutSaveModalIsOpen: false }, () => this.logout());
	};

	// Logout modal - user clicks no
	handleLogoutSaveClickNo = (event) => {
		this.setState({
			logoutSaveModalIsOpen: false,
		});
	};

	// Navigate modal - user clicks yes
	handleNavigateSaveClickYes = (event) => {
		this.setState({ navigateSaveModalIsOpen: false }, () =>
			this.props.history.push('/')
		);
	};

	// Navigate modal - user clicks no
	handleNavigateSaveClickNo = (event) => {
		this.setState({
			navigateSaveModalIsOpen: false,
		});
	};

	// Common method for just closing the modal
	handleClose = (event) => {
		this.setState({
			logoutSaveModalIsOpen: false,
			navigateSaveModalIsOpen: false,
		});
	};

	render() {
		const sessionService = this.sessionService;
		const stateService = this.stateService;
		
		const isLoggedIn = sessionService && sessionService.isLoggedIn();
		const login =
			isLoggedIn && stateService && stateService.state.session.login
				? stateService.state.session.login
				: undefined;
		const maintenance =
			stateService.state.configuration &&
			stateService.state.configuration.maintenanceMode
				? true
				: false;

		const pathname = this.props.location.pathname;
		const isOnHomePage = pathname === '' || pathname === '/';
		const isOnLoginPage = pathname === '/login';

		// Props for the logout save modal
		const logoutSaveModal: ModalProps = {
			isOpen: this.state.logoutSaveModalIsOpen,
			titleTextkey: 'SaveModal_Logout_Title',
			questionTextkey: 'SaveModal_Logout_Question',
			handleClickNo: this.handleLogoutSaveClickNo,
			handleClickYes: this.handleLogoutSaveClickYes,
			handleClose: this.handleClose,
		};

		// Props for the home save modal
		const navigateSaveModal: ModalProps = {
			isOpen: this.state.navigateSaveModalIsOpen,
			titleTextkey: 'SaveModal_Navigate_Title',
			questionTextkey: 'SaveModal_Navigate_Question',
			handleClickNo: this.handleNavigateSaveClickNo,
			handleClickYes: this.handleNavigateSaveClickYes,
			handleClose: this.handleClose,
		};

		// ClassNames
		const classNames = ClassNames('Header', {
			Compact: this.state.compactTop,
		});

		// Logo
		const logo = this.state.compactTop
			? '/images/logo-small.svg'
			: '/images/logo-large.svg';

		return (
			<Row>
				<Col xs={12}>
					<div className="Top">
						<header className={classNames}>
							<div className="LogoContainer">
								<a href="https://landshypotek.se/">
									<img src={logo} className="Logo" alt="Landshypotek Bank" />
								</a>
							</div>
							<Media>
								{({ breakpoints, currentBreakpoint }) => {
									if (breakpoints[currentBreakpoint] > breakpoints.smxs) {
										if (isLoggedIn && login) {
											return (
												<div className="NavigationContainer">
													<div className="Links">
														{!maintenance && isLoggedIn && !isOnHomePage && (
															<NavLink
																to="/"
																onClick={(event) => {
																	this.handleClickOnHome(event);
																}}>
																<T k="Home_Link" />
															</NavLink>
														)}
														{!maintenance &&
															isLoggedIn &&
															login &&
															login.admin && (
																<NavLink to="/admin">
																	<T k="Admin_Link" />
																</NavLink>
															)}
														{!maintenance && !isLoggedIn && !isOnLoginPage && (
															<NavLink to="/login">
																<T k="Login_LoginLink" />
															</NavLink>
														)}
													</div>
													<span className="LoggedInText">
														<span className="UserName">
															{login.firstName} {login.lastName}
														</span>
													</span>
													<Button
														className="Button LogoutButton beige lock-icon"
														labelTextkey="Login_Logout"
														onClick={this.handleClickOnLogout}
													/>
												</div>
											);
										}
									}
								}}
							</Media>
							<MobileNav
								logout={this.handleClickOnLogout}
								login={login}
								isLoggedIn={isLoggedIn}
								isOnHomePage={isOnHomePage}
								handleClickOnHome={this.handleClickOnHome}
								compactTop={this.state.compactTop}
							/>
						</header>
						<SaveModal modal={logoutSaveModal} />
						<SaveModal modal={navigateSaveModal} />
					</div>
				</Col>
			</Row>
		);
	}
}

function withAuthWrapped<P extends AuthProps>(Component: React.ComponentType<P>) {
	return function WithAuth(props: Omit<P, keyof AuthProps>) {
		return <Component {...props as P} oidc={props} />;
	};
  }

export default withAuth(withAuthWrapped(withRouter(Top)));
