import React from 'react';
import './Messages.scss';
import { T } from '../T';
import { Application } from '../../../../models/AppModels';

export interface MessageItem {
	textkey?: string;
	text?: string;
	ok?:boolean;
}

interface Props {
	className?: string;
	items: MessageItem[];
	lineBreak?:boolean;
	visible?:boolean;
}

export const Messages: React.FunctionComponent<Props> = (props) => {
	if(!props.items) 
		return null;

	if(props.visible === false)
		return null;

	const uq = 'message_'+Application.instance.services.idService.newIdString();

	return (
		<div className={props.className}>
		{
			props.items.map((x, ix) => {
				const id = uq+'_'+ix;
				return (
					<span key={id} className={x.ok===true?"OkMessage":"ErrorMessage"}>{x.textkey && <T k={x.textkey}/>} {x.text} {props.lineBreak && <br/>}</span>
				);
			})
		}
		</div>
	);
};

// Default props
Messages.defaultProps = {
	className: 'Messages',
};
