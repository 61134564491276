/* tslint:disable */
/* eslint-disable */
/**
 * SelmaSME API
 * This definition is the API between customer SME-Gui and the persistence layer for the application NOT FOR V1 When customer logg-in to Selma-EN, we will create a processId with applicant CustomerId (from BankID). When a customer added attachment to the application, we don\'t have a CaseId i LP, should we add a LP-Service to create/reserve a caseId to be able to attach a document to the caseId in DM.
 *
 * The version of the OpenAPI document: 0.7.4
 * Contact: developer@landshypotek.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CollateralAge,
    CollateralAgeFromJSON,
    CollateralAgeFromJSONTyped,
    CollateralAgeToJSON,
    TaxedOwnerType,
    TaxedOwnerTypeFromJSON,
    TaxedOwnerTypeFromJSONTyped,
    TaxedOwnerTypeToJSON,
    TypeOfCollateral,
    TypeOfCollateralFromJSON,
    TypeOfCollateralFromJSONTyped,
    TypeOfCollateralToJSON,
} from './';

/**
 * Säkerheter för lånet, om köp, även andra fastigheter kan användas som säkerhet för lånet
 * @export
 * @interface CollateralType
 */
export interface CollateralType {
    /**
     * application model unique identification
     * @type {string}
     * @memberof CollateralType
     */
    processId: string;
    /**
     * customerId is swedish ssn or corporate organisation number
     * @type {string}
     * @memberof CollateralType
     */
    customerId?: string;
    /**
     * 
     * @type {TypeOfCollateral}
     * @memberof CollateralType
     */
    typeOfCollateral?: TypeOfCollateral;
    /**
     * Collateral identity
     * @type {string}
     * @memberof CollateralType
     */
    collateralId?: string;
    /**
     * TBD - Fastighetskod (ska denna användas?)
     * @type {string}
     * @memberof CollateralType
     */
    collateralCode?: string;
    /**
     * Fastighetbeteckning
     * @type {string}
     * @memberof CollateralType
     */
    collateralName?: string;
    /**
     * En lista av taxerade fastighetsägare
     * @type {Array<TaxedOwnerType>}
     * @memberof CollateralType
     */
    taxedOwners?: Array<TaxedOwnerType>;
    /**
     * Kommun där fastigheten och/eller säkerheten finns registrerad
     * @type {string}
     * @memberof CollateralType
     */
    collateralMunicipality?: string;
    /**
     * Street address of the collateral
     * @type {string}
     * @memberof CollateralType
     */
    collateralStreet?: string;
    /**
     * Areal för skogen
     * @type {number}
     * @memberof CollateralType
     */
    collateralAreal?: number;
    /**
     * 
     * @type {CollateralAge}
     * @memberof CollateralType
     */
    collateralAge?: CollateralAge;
    /**
     * Use this collateral as security in this application
     * @type {boolean}
     * @memberof CollateralType
     */
    useAsCollateral?: boolean;
    /**
     * Applicant has intention to by this collateral
     * @type {boolean}
     * @memberof CollateralType
     */
    buyCollateral?: boolean;
}

export function CollateralTypeFromJSON(json: any): CollateralType {
    return CollateralTypeFromJSONTyped(json, false);
}

export function CollateralTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): CollateralType {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'processId': json['processId'],
        'customerId': !exists(json, 'customerId') ? undefined : json['customerId'],
        'typeOfCollateral': !exists(json, 'typeOfCollateral') ? undefined : TypeOfCollateralFromJSON(json['typeOfCollateral']),
        'collateralId': !exists(json, 'collateralId') ? undefined : json['collateralId'],
        'collateralCode': !exists(json, 'collateralCode') ? undefined : json['collateralCode'],
        'collateralName': !exists(json, 'collateralName') ? undefined : json['collateralName'],
        'taxedOwners': !exists(json, 'taxedOwners') ? undefined : ((json['taxedOwners'] as Array<any>).map(TaxedOwnerTypeFromJSON)),
        'collateralMunicipality': !exists(json, 'collateralMunicipality') ? undefined : json['collateralMunicipality'],
        'collateralStreet': !exists(json, 'collateralStreet') ? undefined : json['collateralStreet'],
        'collateralAreal': !exists(json, 'collateralAreal') ? undefined : json['collateralAreal'],
        'collateralAge': !exists(json, 'collateralAge') ? undefined : CollateralAgeFromJSON(json['collateralAge']),
        'useAsCollateral': !exists(json, 'useAsCollateral') ? undefined : json['useAsCollateral'],
        'buyCollateral': !exists(json, 'buyCollateral') ? undefined : json['buyCollateral'],
    };
}

export function CollateralTypeToJSON(value?: CollateralType | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'processId': value.processId,
        'customerId': value.customerId,
        'typeOfCollateral': TypeOfCollateralToJSON(value.typeOfCollateral),
        'collateralId': value.collateralId,
        'collateralCode': value.collateralCode,
        'collateralName': value.collateralName,
        'taxedOwners': value.taxedOwners === undefined ? undefined : ((value.taxedOwners as Array<any>).map(TaxedOwnerTypeToJSON)),
        'collateralMunicipality': value.collateralMunicipality,
        'collateralStreet': value.collateralStreet,
        'collateralAreal': value.collateralAreal,
        'collateralAge': CollateralAgeToJSON(value.collateralAge),
        'useAsCollateral': value.useAsCollateral,
        'buyCollateral': value.buyCollateral,
    };
}


