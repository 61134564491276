/* eslint-disable no-undef */
import React from 'react';
import { Loan, TakeoverLoan } from '../../../../../models/SelmaModels';
import { AppComponent } from '../../../../AppComponent';
import {
	FormUnit,
	FormUnitSize,
	FormUnitPadding,
} from '../../../../layout/FormUnit/FormUnit';

import { T } from '../../../../widget/display/T';
import { Heading } from '../../../../widget/display/Heading/Heading';
import Table from '../../../../widget/display/Table/Table';
import NumberFormat from 'react-number-format';

interface Props {
	loan: Loan;
}

export class LoanTakeOverSummary extends AppComponent<Props> {
	constructor(props: Props) {
		super(props);
	}

	createTakeoverLoanRows = (x:TakeoverLoan) : (string|JSX.Element)[][] => {
		const loanService = this.application.services.loanService;
		const {aimCategory, aimDetail, debtAmount, loanNumber, creditInstitute} = x;

		let tableRows:(string|JSX.Element)[][] = [];

		const row1:(string|JSX.Element)[] = [
			loanService.getAimCategoryName(aimCategory),
			loanService.getAimDetailName(aimCategory, aimDetail),
			<NumberFormat
				key="debtAmount"
				thousandSeparator=" "
				suffix=" kr"
				displayType="text"
				value={debtAmount}
			/>
		];
		tableRows.push(row1)

		if( loanService.hasTakeoverLoanDetails(x) ) {
			const header2:(string|JSX.Element)[] = [
				<b key="Loan_BankName"><T k="Loan_BankName"/></b>,
				<b key="Loan_LoanNumber"><T k="Loan_LoanNumber"/></b>,
				""
			];
			const row2:(string|JSX.Element)[] = [
				creditInstitute||"",
				loanNumber||"",
				""
			];
			tableRows.push(header2);
			tableRows.push(row2);
		}

		return tableRows;
	}

	render() {
		const loanService = this.application.services.loanService;
		const loan = this.props.loan;

		if ( !loan.takeoverLoans || !loanService.hasTakeoverLoans(loan) ) {
			return null;
		}

		// List of table row arrays. One per table.
		let tables:(string|JSX.Element)[][][] = [];
		loan.takeoverLoans.forEach(x => {
			let rows = this.createTakeoverLoanRows(x);
			tables.push(rows);
		});

		return (
			<FormUnit size={FormUnitSize.Large} padding={FormUnitPadding.Small}>
				<Heading level={3} labelTextkey="Summary_Loan_TakeOver_Heading"/>
				{ tables.map( (x,ix) => {
					return (
						<div key={ix}>
						<Table
							
							headingRow={[
								<T key="Summary_Loan_PurposeCategory" k="Summary_Loan_PurposeCategory" />,
								<T key="Summary_Loan_Purpose" k="Summary_Loan_Purpose" />,
								<T key="Summary_Loan_TakeOverDebt" k="Summary_Loan_TakeOverDebt" />,
							]}
							tableRows={x}
						/>
						<br/>
						</div>
					)
				})}
				
			</FormUnit>
		);
	}
}
