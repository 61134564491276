import React from 'react';
import { Child, Case } from '../../../../models/SelmaModels';
import { AppComponent } from '../../../AppComponent';
import { Label } from '../../../widget/display/Label/Label';
import { RadiobuttonGroup, RadioOption } from '../../../widget/input/RadiobuttonGroup/RadiobuttonGroup';
import { Validation } from '../../../widget/display/Validation/Validation';

interface Props {
	case: Case;
	child: Child;
	timePartChanged: (value:boolean|undefined, child: Child) => void;
}

const timePartOptions:RadioOption[] = [
	{
		group: 'timePart',
		label: 'Heltid',
		value: 'Heltid',
	},
	{
		group: 'timePart',
		label: 'Deltid',
		value: 'Deltid',
	},
];


export class ChildTimePart extends AppComponent<Props> {
	timePartOptions:RadioOption[];

	constructor(props: Props) {
		super(props);
		
		let child = this.props.child;
		this.observeUpdate(child);

		const uq = this.application.services.idService.newIdString();

		this.timePartOptions = timePartOptions.map(x => {
			return {
				group: uq+x.group,
				label:x.label,
				value:x.value
			}
		})
	}

	timePartChanged = (value: string) => {
		const timePartHeltid = value === 'Heltid';
		const timePartDeltid = value === 'Deltid';
		let timePart:boolean|undefined = undefined;
		if( timePartHeltid)
			timePart = false;
		else if( timePartDeltid)
			timePart = true;

		if( this.props.timePartChanged)
			this.props.timePartChanged(timePart, this.props.child);
	};

	render() {
		let child = this.props.child;

		let timePartValue: string | undefined;
		if (child.partInHousehold === true) {
			timePartValue = 'Deltid';
		} else if (child.partInHousehold === false) {
			timePartValue = 'Heltid';
		}

		return (
			<div className="FormUnitRow_Section">
				<Label
					labelTextkey="Household_ChildTimePart"
					required={true}
				/>
				<Validation
					forObject={child}
					errors={[
						{
							validator: this.application.services.householdService.childHasPartial,
							textkey: 'Error_SelectValue',
						},
					]}>
					<RadiobuttonGroup
						handleChange={this.timePartChanged}
						options={this.timePartOptions}
						value={timePartValue}
					/>
				</Validation>
			</div>
		);
	}
}
