import React from 'react';
import { Case } from '../../../../models/SelmaModels';
import { AppComponent } from '../../../AppComponent';
import { StepHeader } from '../../StepHeader';
import { StepContext } from '../../CaseSteps';
import { StepFooter } from '../../StepFooter';
import { CheckboxGroup } from '../../../widget/input/CheckboxGroup/CheckboxGroup';
import { FormUnit, FormUnitSize } from '../../../layout/FormUnit/FormUnit';
import { THtml } from '../../../widget/display/THtml';
// import { AddedCollaterals } from './AddedCollaterals';
import { CollateralCollaterals } from './CollateralCollaterals';
import { CollateralEUSupports } from './CollateralEUSupports';
// import { AddCollaterals } from './AddCollaterals';
import { CollateralGuarantors } from './CollateralGuarantors';
import { T } from '../../../widget/display/T';
import { Heading } from '../../../widget/display/Heading/Heading';
import { Validation } from '../../../widget/display/Validation/Validation';
import { StepForm } from '../../../widget/display/StepForm/StepForm';

import './StepCollateral.scss';
import { Modal, OverlayStyles } from '../../../layout/Modal/Modal';
import {
	ButtonGroup,
	ButtonGroupLayout,
} from '../../../widget/input/ButtonGroup/ButtonGroup';
import { Button } from '../../../widget/input/Button/Button';

interface Props {
	case: Case;
	context: StepContext;
}

interface State {
	collateralTypes: string[];
	modal: ModalProps;
	hasRemovedCollateral: boolean;
	immidiateAllValidatorsVisibleBefore?:Date;
}

enum collateralTypes {
	'FASTIGHET' = 'FASTIGHET',
	'EUSTOD' = 'EUSTOD',
	'BORGENSMAN' = 'BORGENSMAN',
}

interface ModalProps {
	isOpen: boolean;
	collateralType: collateralTypes | undefined;
	modalTitleTextkey: string;
	modalQuestionTextkey: string;
	values: string[];
}


export class StepCollateral extends AppComponent<Props, State> {
	constructor(props: Props) {
		super(props);

		const c = this.props.case;

		this.observeUpdate(c);

		if (!c.collaterals) c.collaterals = [];

		let initialCollateralTypes: string[] = [];
		if (c.collaterals && c.collaterals.length > 0)
			initialCollateralTypes.push(collateralTypes.FASTIGHET);
		if (c.euSupports && c.euSupports.length > 0)
			initialCollateralTypes.push(collateralTypes.EUSTOD);
		if (c.guarantors && c.guarantors.length > 0)
			initialCollateralTypes.push(collateralTypes.BORGENSMAN);

		this.state = {
			collateralTypes: initialCollateralTypes,
			hasRemovedCollateral:false,
			immidiateAllValidatorsVisibleBefore:undefined,
			modal: {
				isOpen: false,
				collateralType: undefined,
				modalTitleTextkey: '',
				modalQuestionTextkey: '',
				values: [],
			},
		};
	}

	componentDidMount() {
		const caseService = this.application.services.caseService;
		caseService.update(this.props.case); // Trigger re-validation.
	}

	handleCollateralTypeChange = (values: string[]) => {
		const c = this.props.case;
		const colTypes = this.state.collateralTypes;
		let modalProps;

		let addedCollateral, removedCollateral;

		if (values.length > colTypes.length) {
			addedCollateral = values
				.filter(function(el) {
					return colTypes.indexOf(el) < 0;
				})
				.join();
		} else if (values.length < colTypes.length) {
			removedCollateral = colTypes
				.filter(function(el) {
					return values.indexOf(el) < 0;
				})
				.join();
		}

		// FASTIGHET
		if (addedCollateral === collateralTypes.FASTIGHET) {
			this.application.services.collateralService.ensureMinimumCollaterals(c);
			this.setState({
				collateralTypes: values,
			});
		} else if (removedCollateral === collateralTypes.FASTIGHET) {
			modalProps = {
				isOpen: true,
				collateralType: collateralTypes.FASTIGHET,
				modalTitleTextkey: 'Collateral_RemoveCollateral',
				modalQuestionTextkey: 'Collateral_RemoveCollateral_Q',
				values: values,
			};
		}

		// EUSTOD
		if (addedCollateral === collateralTypes.EUSTOD) {
			this.application.services.euSupportService.ensureMinimumEuSupports(c);
			this.setState({
				collateralTypes: values,
			});
		} else if (removedCollateral === collateralTypes.EUSTOD) {
			modalProps = {
				isOpen: true,
				collateralType: collateralTypes.EUSTOD,
				modalTitleTextkey: 'Collateral_EuSupport_Remove',
				modalQuestionTextkey: 'Collateral_EuSupport_Remove_Q',
				values: values,
			};
		}

		// BORGENSMAN
		if (addedCollateral === collateralTypes.BORGENSMAN) {
			this.application.services.guarantorService.ensureMinimumGuarantors(c);
			this.setState({
				collateralTypes: values,
			});
		} else if (removedCollateral === collateralTypes.BORGENSMAN) {
			modalProps = {
				isOpen: true,
				collateralType: collateralTypes.BORGENSMAN,
				modalTitleTextkey: 'Collateral_Guarantor_Remove',
				modalQuestionTextkey: 'Collateral_Guarantor_Remove_Q',
				values: values,
			};
		}

		this.openModal(modalProps);
	};

	openModal = (modalProps: ModalProps | undefined) => {
		if (modalProps) this.setState({ modal: { ...modalProps } });
	};

	handleClickYes = (
		event: React.MouseEvent<HTMLButtonElement, MouseEvent>
	): void => {
		const c = this.props.case;

		switch (this.state.modal.collateralType) {
			case collateralTypes.FASTIGHET:
				this.application.services.collateralService.removeAllCollaterals(c);
				break;
			case collateralTypes.EUSTOD:
				this.application.services.euSupportService.removeAllEuSupports(c);
				break;
			case collateralTypes.BORGENSMAN:
				this.application.services.guarantorService.removeAllGuarantors(c);
				break;
		}

		this.setState({hasRemovedCollateral: true});

		this.setState({
			modal: { ...this.state.modal, isOpen: false },
			collateralTypes: this.state.modal.values,
		});
	};

	handleClickNo = (
		event:
			| React.MouseEvent<HTMLButtonElement, MouseEvent>
			| React.MouseEvent<HTMLDivElement, MouseEvent>
	): void => {
		this.setState({
			modal: { ...this.state.modal, isOpen: false },
		});
	};

	triggerImmidiateValidation():void {
		this.setState({ immidiateAllValidatorsVisibleBefore : new Date() })
	}

	render() {
		const c = this.props.case;
		const validationResult = this.props.context.getValidation();
		const canGoToNext = validationResult.ok();

		const collateralTypeCheckboxes = [
			{
				value: collateralTypes.FASTIGHET,
				checked:
					this.state.collateralTypes.indexOf(collateralTypes.FASTIGHET) > 0,
				labelTextkey: 'Collateral_CollateralTypes_FASTIGHET',
			},
			{
				value: collateralTypes.EUSTOD,
				checked: this.state.collateralTypes.indexOf(collateralTypes.EUSTOD) > 0,
				labelTextkey: 'Collateral_CollateralTypes_EUSTOD',
			},
		];

		// "Borgensman" should not be available unless it was selected in a
		// previously saved application. This is to prevent bugs in existing
		// applications where borgensman was used. See DISME-593/LSSL-210.
		if(this.state.collateralTypes.indexOf(collateralTypes.BORGENSMAN) > -1) {
			collateralTypeCheckboxes.push(
				{
					value: collateralTypes.BORGENSMAN,
					checked:
						this.state.collateralTypes.indexOf(collateralTypes.BORGENSMAN) > 0,
					labelTextkey: 'Collateral_CollateralTypes_BORGENSMAN',
				}
			)
		}

		const immidiateAllValidatorsVisibleBefore = this.state.immidiateAllValidatorsVisibleBefore;

		return (
			<div className="StepCollateral Step">
				<StepHeader
					context={this.props.context}
					titleTextkey="Collateral_Title"
					descriptionTextkey="Collateral_Description"
				/>
				<StepForm context={this.props.context} immidiateAllValidatorsVisibleBefore={immidiateAllValidatorsVisibleBefore}>
					<FormUnit>
						<Heading level={2} labelTextkey="Collateral_CollateralTypes" />

						<Validation
							//validate={this.state.hasRemovedCollateral}
							forObject={c}
							errors={[
								{
									validator: this.application.services.stepService
										.validateStepCollateral,
									textkey: 'Collateral_MustSelectOne',
								},
							]}>
							<CheckboxGroup
								options={collateralTypeCheckboxes}
								handleChange={this.handleCollateralTypeChange}
								value={this.state.collateralTypes}
							/>
						</Validation>
					</FormUnit>

					{this.state.collateralTypes.indexOf(collateralTypes.FASTIGHET) >
						-1 && (
						<>
							<FormUnit size={FormUnitSize.Large}>
								<Heading
									level={3}
									labelTextkey="Collateral_AddCollateral"
								/>
								<p>
									<THtml k="Collateral_AddCollateral_Description" />
								</p>
								<CollateralCollaterals case={c}  />
							</FormUnit>
						</>
					)}

					{this.state.collateralTypes.indexOf(collateralTypes.EUSTOD) > -1 && (
							<CollateralEUSupports case={c} />
					)}

					{this.state.collateralTypes.indexOf(collateralTypes.BORGENSMAN) >
						-1 && (
							<CollateralGuarantors case={c} />
					)}

					<StepFooter
						context={this.props.context}
						canGoHome={true}
						onGoHome={this.props.context.goHome}
						canGoToNext={canGoToNext}
						onGoToNext={this.props.context.goNext}
						onTriggerImmidiateValidation={() => this.triggerImmidiateValidation() }
					/>
				</StepForm>
				{this.state.modal && this.state.modal.isOpen ? (
					<Modal
						overlayCloseOnClick={true}
						handleClose={this.handleClickNo}
						overlayStyle={OverlayStyles.Darken}>
						<div className="ModalContent">
							<h1>
								<T k={this.state.modal.modalTitleTextkey} />
							</h1>
							<p>
								{this.state.modal.modalTitleTextkey ? (
									<T k={this.state.modal.modalQuestionTextkey} />
								) : (
									<T k="Dialog_AreYouReallySure" />
								)}
							</p>
						</div>
						<div className="ModalFooter">
							<ButtonGroup layout={ButtonGroupLayout.Stretch}>
								<Button
									style="normal"
									labelTextkey="Yes"
									onClick={this.handleClickYes}
								/>
								<Button
									style="normal"
									labelTextkey="No"
									onClick={this.handleClickNo}
								/>
							</ButtonGroup>
						</div>
					</Modal>
				) : null}
			</div>
		);
	}
}