import React from 'react';
import ClassNames from 'classnames';
import './HeaderButton.scss';

export enum HeaderButtonLayout {
	'Stretch' = 'Stretch',
	'Left' = 'Left',
	'Right' = 'Right'
}

interface Props {
	layout?: HeaderButtonLayout;
	className?: string;
}

export const HeaderButton: React.FunctionComponent<Props> = (props) => {

	const classNames = ClassNames(props.className, {
		Stretch: props.layout === 'Stretch',
		Left: props.layout === 'Left',
		Right: props.layout === 'Right',
	});

  return <div className={classNames}>{props.children}</div>;
};

HeaderButton.defaultProps = {
	layout: undefined,
	className: 'HeaderButton',
};
