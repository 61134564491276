import React from 'react';
import { Company } from '../../../../../models/SelmaModels';
import { AppComponent } from '../../../../AppComponent';
import {
	FormUnit,
	FormUnitSize,
	FormUnitPadding,
} from '../../../../layout/FormUnit/FormUnit';
import { T } from '../../../../widget/display/T';
import Table from '../../../../widget/display/Table/Table';
import { Percent } from '../../../../widget/input/Percent/Percent';
import { uiBusinessCategories } from '../../../../../models/UiModels';

interface Props {
	company?: Company;
}

export class CompanyBusinessFocusesSummary extends AppComponent<Props> {
	constructor(props: Props) {
		super(props);
	}

	// Returns a business category object
	// Move to a service?
	getBusinessCategory(businessCategoryCode: string | undefined) {
		if (!businessCategoryCode) return null;

		const businessCategoryObject = uiBusinessCategories.find(
			(uiCategory) => businessCategoryCode === uiCategory.BusinessCategoryID
		);

		return businessCategoryObject;
	}

	// Returns a business category name as a string
	getBusinessCategoryText(businessCategoryCode: string | undefined) {
		const businessCategoryObject = this.getBusinessCategory(businessCategoryCode);
		if (!businessCategoryObject) return null;

		return businessCategoryObject.BusinessCategory;
	}

	render() {
		const company = this.props.company;
		if (!company) return null;

		const needsMainDirection = this.application.services.companyService.needsToSpecifyMainDirectionForBusiniessFocuses(company);

		if( !company.businessFocuses )
			return null;

		let headingRow = [
			<T key="Summary_Company_BusinessCategory" k="Summary_Company_BusinessCategory" />,
			<T key="Summary_Company_PercentageOfBusiness" k="Summary_Company_PercentageOfBusiness" />
		];
		if( needsMainDirection )
			headingRow.push(<T k="Summary_Company_MainDirectionOfBusiness" />);

		let tableRows = company.businessFocuses.map((x) => {
			let row = [
				this.getBusinessCategoryText(x.businessCategoryCode),
				<Percent key="businessPart" displayType="text" value={x.businessPart} />
			];

			if( needsMainDirection )
				row.push(x.mainDirection?'Ja':'');

			return row;
		});

		return (
			<FormUnit size={FormUnitSize.Large} padding={FormUnitPadding.Small}>
				{company.businessFocuses && (
					<FormUnit size={FormUnitSize.Large} padding={FormUnitPadding.Normal}>
						<h3>
							<T k="Summary_Company_BusinessFocus" />
						</h3>
						<Table
							className="CompanySummary__Table"
							headingRow={headingRow}
							tableRows={tableRows}
						/>
					</FormUnit>
				)}
			</FormUnit>
		);
	}
}
