import { UIKeyed } from "./UIKeyed";
import { UINamed } from "./UINamed";

export interface UIBank extends UIKeyed, UINamed {
}

export const uiBanks: UIBank[] = [
// Lista enligt Daniel Stenman 2016-09-16
{ key: "Landshypotek Bank", name: "Landshypotek Bank", nameTextkey: "UIBank_LandshypotekBank" },
{ key: "Danske Bank" , name: "Danske Bank", nameTextkey: "UIBank_DanskeBank" },
{ key: "Handelsbanken"	, name: "Handelsbanken", nameTextkey: "UIBank_Handelsbanken" },
{ key: "Länsförsäkringar", name: "Länsförsäkringar", nameTextkey: "UIBank_Länsförsäkringar" },
{ key: "Nordea", name: "Nordea", nameTextkey: "UIBank_Nordea" },
{ key: "SEB", name: "SEB", nameTextkey: "UIBank_SEB" },
{ key: "Skandiabanken", name: "Skandiabanken", nameTextkey: "UIBank_Skandiabanken" },
{ key: "Stadshypotek", name: "Stadshypotek", nameTextkey: "UIBank_Stadshypotek" },
{ key: "Swedbank", name: "Swedbank", nameTextkey: "UIBank_Swedbank" },
{ key: "Annan bank", name: "Annan bank", nameTextkey: "UIBank_Other" }
];

