import { BaseService } from './BaseService';
import { Case, MaintenanceCost, MaintenanceHouse, Applicant, LoanOwner, Household } from '../models/SelmaModels';
import { SMELPOService } from './SMELPOService';
import { StateService } from './StateService';
import { ValidationResult } from '../utils/ValidationResult';
import { GuardService } from './GuardService';
import { ApplicantService } from './ApplicantService';
import { IdService } from './IdService';
import { TypeOfHouse } from '../libs/SMELPOClient';

/*
Handles all logic for MaintenanceCost.
*/
export class MaintenanceCostService extends BaseService {
	private stateService: StateService;
	private smelpoService: SMELPOService;
	private guardService: GuardService;

	constructor(stateService: StateService, smelpoService: SMELPOService, 
		guardService: GuardService, private applicantService:ApplicantService, private idService:IdService) {
		super();
		this.stateService = stateService;
		this.smelpoService = smelpoService;
		this.guardService = guardService;
	} 

	update(maintenanceCost: MaintenanceCost|MaintenanceHouse|Case|LoanOwner): void { 
		this.stateService.update(maintenanceCost);
	}

	newMaintenanceCost(c: Case, applicant:Applicant) : MaintenanceCost {
		return {
			id: this.idService.newGuid(), 
			customerId: applicant.customerId,
			typeofhouses: [
				this.newMaintenanceHouse(c)
			]
		};
	}

	newMaintenanceHouse(c: Case) : MaintenanceHouse {
		return {
		};
	}
	
	addMaintenanceCost(c: Case, maintenanceCost: MaintenanceCost) {
		if( !c.maintenanceCosts )
			c.maintenanceCosts = [];
		c.maintenanceCosts.push(maintenanceCost); 
		this.update(c);
	}

	removeMaintenanceCost(c: Case, maintenanceCost: MaintenanceCost): void {
		if (!c.maintenanceCosts) 
			return;
		else {
			this.stateService.listRemove(c.maintenanceCosts, maintenanceCost);
			this.update(c);
		}
	}

	removeMaintenanceCostsForHousehold(c: Case, household: Household) {
		// TODO: Only works as long as we have one household!
		if( c.maintenanceCosts ) {
			this.stateService.listRemoveAll(c.maintenanceCosts);
			this.update(c);
		}
	}

	
	removeAllMaintenanceCosts(c: Case) {
		if( c.maintenanceCosts ) {
			this.stateService.listRemoveAll(c.maintenanceCosts);
			this.update(c);
		}
	}


	ensureMaintenanceHouse(c:Case, maintenanceCost: MaintenanceCost) : MaintenanceHouse {
		if(  !maintenanceCost.typeofhouses)
			maintenanceCost.typeofhouses = [];

		if( maintenanceCost.typeofhouses.length===0 ) {
			maintenanceCost.typeofhouses.push(this.newMaintenanceHouse(c));
			this.update(maintenanceCost);
		}

		return maintenanceCost.typeofhouses[0];
	}

	getLoanOwnersOwnershipPartSum(maintenanceHouse: MaintenanceHouse):number {
		let sum = 0;
		if( maintenanceHouse.loanOwners ) {
			maintenanceHouse.loanOwners.forEach(x => {
				sum += (x.ownershipPart||0);
			});
		}
		return sum;
	}

	ensureLoanOwner(maintenanceHouse: MaintenanceHouse, customerId: string):LoanOwner {
		if (!maintenanceHouse.loanOwners)
			maintenanceHouse.loanOwners = [];
		
		const g = this.guardService;
		let loanOwner = maintenanceHouse.loanOwners.find(x => g.compareCustomerId(x.customerId, customerId));
		if( !loanOwner ) {
			const sum = this.getLoanOwnersOwnershipPartSum(maintenanceHouse);
			const rest = 100-sum;
			const ownershipPart = rest>0?rest:0;
			loanOwner = {
				customerId: customerId,
				ownershipPart: ownershipPart
			}
			maintenanceHouse.loanOwners.push(loanOwner);
			this.update(maintenanceHouse);
		}
		return loanOwner;
	}

	removeLoanOwnerForHousehold(c:Case, household:Household, customerId:string) : void {
		var maintenanceHouses = this.getMaintenanceHousesByCustomerId(c, /*household,*/ customerId);
		if( maintenanceHouses && maintenanceHouses.length ) {
			maintenanceHouses.forEach(x => {
				this.removeLoanOwnerByCustomerId(x, customerId);
			})
		}
	}

	getMaintenanceHousesByCustomerId(c:Case, customerId: string) : MaintenanceHouse[] {
		if( !c.maintenanceCosts )
			return [];
		
		var maintenanceHouses:MaintenanceHouse[] = c.maintenanceCosts
			.map(x => x.typeofhouses??[])
			.flat();

		const r = maintenanceHouses.filter(x => {
			return this.isLoanOwner(x, customerId)
		});
		return r;
	}

	isLoanOwner(maintenanceHouse:MaintenanceHouse, customerId:string) : boolean {
		if( !maintenanceHouse.loanOwners )
			return false;
		
		for( let loanOwner of maintenanceHouse.loanOwners ) {
			if( this.guardService.compareCustomerId(loanOwner.customerId, customerId) )
				return true;
		}
		return false;
	}

	removeLoanOwnerByCustomerId(maintenanceHouse: MaintenanceHouse, customerId:string) : void {
		if( maintenanceHouse.loanOwners ) {
			if( 0!==this.stateService.listRemoveWhere(maintenanceHouse.loanOwners, 
				x => this.guardService.compareCustomerId(x.customerId, customerId)) ) {
					this.update(maintenanceHouse);
			}
		}
	}

	removeLoanOwner(maintenanceHouse: MaintenanceHouse, loanOwner: LoanOwner):void {
		if( !maintenanceHouse.loanOwners )
			return;
		this.stateService.listRemove(maintenanceHouse.loanOwners, loanOwner);
		this.update(maintenanceHouse);
	}

	validateMaintenanceCost(c: Case, x: MaintenanceCost): ValidationResult {
		let vr = new ValidationResult();
		vr.add({validator:this.hasCustomerId, object:x, ok: this.hasCustomerId(x)});

		const hasTypesOfHouses = this.hasTypesOfHouses(x);
		vr.add({validator:this.hasTypesOfHouses, object:x, ok: hasTypesOfHouses});
		if( hasTypesOfHouses && x.typeofhouses ) {
			x.typeofhouses.forEach(h => {
				vr.addResult(this.validateMaintenanceHouse(c,h));
			});
		}
		return vr;
	}

	private validateMaintenanceHouse(c:Case, h: MaintenanceHouse) : ValidationResult {
		let vr = new ValidationResult();
		const hasSetHouseType = this.hasSetHouseType(h);
		vr.add({validator:this.hasSetHouseType, object:h, ok: hasSetHouseType});
		vr.add({validator:this.hasSetKeepHouse, object:h, ok: this.hasSetKeepHouse(h)});
		const keepHouse = this.hasKeepHouse(h);
		if( hasSetHouseType && keepHouse ) {
			if( h.houseType===TypeOfHouse.Hyresrtt ) {
				vr.add({validator:this.hasMaintenanceCost, object:h, ok: this.hasMaintenanceCost(h)});
			} else {
				if( h.houseType===TypeOfHouse.Bostadsrtt ) {
					vr.add({validator:this.hasMaintenanceCost, object:h, ok: this.hasMaintenanceCost(h)});
				}
				vr.add({validator:this.hasSetLoanInOtherInstitute, object:h, ok:this.hasSetLoanInOtherInstitute(h)});
				if( this.hasLoanInOtherInstitute(h) ) {
					vr.add({validator:this.hasSetRedeemHouseLoan, object:h, ok: this.hasSetRedeemHouseLoan(h)});
					vr.add({validator:this.hasHouseLoanAmount, object:h, ok: this.hasHouseLoanAmount(h)});
					vr.add({validator:this.hasHouseLoanOwners, object:h, ok: this.hasHouseLoanOwners(h)});
					if( h.loanOwners && h.loanOwners.length>0 ) {
						let partSum = 0;
						h.loanOwners.forEach(lo => {
							vr.add({validator:this.hasHouseLoanOwnersOwnershipPart, object:lo, ok: this.hasHouseLoanOwnersOwnershipPart(lo)});
							partSum += lo.ownershipPart||0;
							if( partSum > 100 ) {
								vr.add({validator:this.hasHouseLoanOwnersOwnershipPartBelow100InSum, object:lo, ok: false});
							}
						})
					}
				}
			}
		}
		return vr;
	}

	hasHouseLoanOwners(h: MaintenanceHouse): boolean {
		const g = this.guardService;
		return g.hasLengthGreaterThan(h.loanOwners, 0);
	}

	hasHouseLoanOwnersOwnershipPart(lo: LoanOwner): boolean {
		const g = this.guardService;
		return g.isInRange(lo.ownershipPart,0,100) && g.isGreaterThanZero(lo.ownershipPart);
	}

	hasHouseLoanOwnersOwnershipPartBelow100InSum(lo: LoanOwner, accumulatedSum:number): boolean {
		const g = this.guardService;
		return g.isInRange(lo.ownershipPart,0,100);
	}

	hasSetLoanInOtherInstitute(h: MaintenanceHouse): boolean {
		const g = this.guardService;
		return g.hasValue(h.loanInOtherInstitute);
	}

	hasLoanInOtherInstitute(h: MaintenanceHouse): boolean {
		const g = this.guardService;
		return g.hasValue(h.loanInOtherInstitute) && h.loanInOtherInstitute===true;
	}

	hasMaintenanceCost(h: MaintenanceHouse): boolean {
		const g = this.guardService;
		return g.isZeroOrGreater(h.maintenanceCost);
	}

	hasHouseLoanAmount(h: MaintenanceHouse): boolean {
		const g = this.guardService;
		return g.isZeroOrGreater(h.houseLoanAmount);
	}

	hasCustomerId(x: MaintenanceCost): boolean {
		const g = this.guardService;
		return x.customerId && g.isString(x.customerId) && g.isValidSsn(x.customerId) ? true : false;
	}

	hasTypesOfHouses(x: MaintenanceCost): boolean {
		const g = this.guardService;
		return g.hasLengthGreaterThan(x.typeofhouses, 0);
	}

	hasSetHouseType(x: MaintenanceHouse): boolean {
		const g = this.guardService;
		return g.hasValue(x.houseType);
	}

	hasSetKeepHouse(x: MaintenanceHouse): boolean {
		const g = this.guardService;
		return g.hasValue(x.keepHouse);
	}

	hasKeepHouse(x: MaintenanceHouse): boolean {
		const g = this.guardService;
		return g.hasValue(x.keepHouse) && x.keepHouse===true;
	}

	
	hasSetRedeemHouseLoan(x: MaintenanceHouse): boolean {
		const g = this.guardService;
		return g.hasValue(x.redeemLoan);
	}

	hasRedeemHouseLoan(x: MaintenanceHouse): boolean {
		const g = this.guardService;
		return g.hasValue(x.redeemLoan) && x.redeemLoan===true;
	}

/*
	getMaintenanceCostsByHouseholdId(c:Case, householdId: string) : MaintenanceCost[] {
		if( !c.maintenanceCosts )
			return [];

		const r = c.maintenanceCosts.filter(x=>{
			return this.isMaintenanceCostOwner(x, customerId)
		});
		return r;
	}

	isMaintenanceCostOwner(maintenanceCost:MaintenanceCost, customerId:string) : boolean {
		if( !maintenanceCost.loanOwners )
			return false;
		
		for( let maintenanceCostMember of maintenanceCost.householdId ) {
			if( maintenanceCostMember.customerId==customerId )
				return true;
		}
		return false;
	}*/
}
