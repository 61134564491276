import React, { Children } from 'react';
import { Case } from '../../../../models/SelmaModels';
import { Household, Child } from '../../../../models/selmamodels/Household';
import { AppComponent } from '../../../AppComponent';
import { SelectOption, Select } from '../../../widget/input/Select/Select';
import { FormUnit, FormUnitSize } from '../../../layout/FormUnit/FormUnit';
import { Label } from '../../../widget/display/Label/Label';
import { Money } from '../../../widget/input/Money/Money';
import { NumberFormatValues } from 'react-number-format';
import { FormUnitRow } from '../../../layout/FormUnitRow/FormUnitRow';
import { ChildTimePart } from './ChildTimePart';
import { ApplicantSelect } from './ApplicantSelect';
import { Applicant } from '../../../../models/selmamodels/Applicant';
import { Age } from '../../../widget/input/Age/Age';
import { Validation } from '../../../widget/display/Validation/Validation';

interface Props {
	case: Case;
	household: Household;
}

export class HouseholdEdit extends AppComponent<Props> {
	householdService = this.application.services.householdService;
	numberOfChildrenAtHomeOptions: SelectOption[];

	constructor(props: Props) {
		super(props);

		const h = this.props.household;

		this.observeUpdate(h);

		if (h.numberOfChildrenAtHome)
			this.householdService.setNumberOfChildrenAtHome(
				this.props.case,
				h,
				h.numberOfChildrenAtHome
			);

		this.numberOfChildrenAtHomeOptions = [];
		for (let i = 0; i <= 9; i++) {
			let o = {
				value: i.toString(),
				label: i.toString(),
			};
			this.numberOfChildrenAtHomeOptions.push(o);
		}
	}

	membersChanged = (values: string[]): void => {
		const household = this.props.household;
		if (values.length < 1) return; // Cant uncheck the last item.

		this.householdService.setHouseholdMembersByCustomerIds(this.props.case, household, values);
	};

	numberOfChildrenAtHomeChanged(e: string, household: Household) {
		const numberOfChildrenAtHome = parseInt(e);
		this.householdService.setNumberOfChildrenAtHome(
			this.props.case,
			household,
			numberOfChildrenAtHome
		);
	}

	childAgeChanged(e: NumberFormatValues, child: Child) {
		child.childsAge = e.floatValue;
		const household = this.props.household;
		this.householdService.update(household);
		this.householdService.update(child);
	}

	childTimePartChanged = (value: boolean | undefined, child: Child): void => {
		child.partInHousehold = value;
		const household = this.props.household;
		this.householdService.update(household);
		this.householdService.update(child);
	};

	childCareAmountChanged = (
		e: NumberFormatValues,
		household: Household
	): void => {
		household.childCareAmount = e.floatValue;
		this.householdService.update(household);
	};

	childMaintenanceCostChanged = (
		e: NumberFormatValues,
		household: Household
	): void => {
		if( e ) {
			household.childMaintenanceCost = e.floatValue!=undefined && e.floatValue!=null ? e.floatValue * 12 : undefined;
			this.householdService.update(household);
		}
	};

	childMaintenanceIncomeChanged = (
		e: NumberFormatValues,
		household: Household
	): void => {
		if( e ) {
			household.childMaintenanceIncome =  e.floatValue!=undefined && e.floatValue!=null ? e.floatValue * 12 : undefined;
			this.householdService.update(household);
		}
	};

	render() {
		const c = this.props.case;
		let h = this.props.household;

		let numberOfChildrenAtHomeValue = h.numberOfChildrenAtHome!==undefined
			? h.numberOfChildrenAtHome.toString()
			: '';
		let childCareAmount = h.childCareAmount
			? h.childCareAmount : undefined;
		let childMaintenanceCost = h.childMaintenanceCost
			? h.childMaintenanceCost / 12
			: undefined;
		let childMaintenanceIncome = h.childMaintenanceIncome
			? h.childMaintenanceIncome / 12
			: undefined;
		let householdDescription = this.householdService.getHouseholdDescription(
			c,
			h
		);
		let membersCustomerIds: string[] = [];
		if (h.members) {
			membersCustomerIds = h.members
				.map((x) => x.householdMember || '')
				.filter((x) => x.length > 0);
		}

		let availableMemberApplicants: Applicant[] = this.householdService.getApplicantsForHouseholds(c);

		const showChildMaintenanceIncome = (h.numberOfChildrenAtHome||0)>0?true:false;

		let ix = this.application.services.idService.newIdString();
		return (
			<div className="Household">
				<div>{householdDescription}</div>
				<FormUnit>
					<Label
						for={'householdMembers_' + ix}
						required={true}
						labelTextkey="Household_Members"
					/>
					<Validation
						forObject={h}
						errors={[
							{
								validator: this.householdService.hasValidMembers,
								textkey: 'Household_SelectOneOrTwoHouseholdMembers',
							},
						]}>
						<ApplicantSelect
							applicants={availableMemberApplicants}
							selectedCustomerIds={membersCustomerIds}
							max={2}
							selectionChanged={this.membersChanged}
						/>
					</Validation>
				</FormUnit>

				<FormUnit size={FormUnitSize.Small}>
					<Label
						for={'numberOfChildrenAtHomeValue_' + ix}
						required={true}
						labelTextkey="Household_NumberOfChildrenAtHome"
					/>
					<Validation
						forObject={this.props.household}
						errors={[
							{
								validator: this.householdService.hasNumberOfChildren,
								textkey: 'Error_SelectAmount',
							},
						]}>
						<Select
							id={'numberOfChildrenAtHomeValue_' + ix}
							value={numberOfChildrenAtHomeValue}
							options={this.numberOfChildrenAtHomeOptions}
							handleChange={(e) => {
								this.numberOfChildrenAtHomeChanged(e, h);
							}}
							placeholderTextkey="Household_SelectNumberOfChildrenAtHome"
							noSelectableOptionsTextkey="Select_NoOptions"
							isSearchable={true}
						/>
					</Validation>
				</FormUnit>
				{h.children &&
					h.children.map((x, ix) => {
						return (
							<FormUnit key={ix}>
								<FormUnitRow>
									<div className="FormUnitRow_Section">
										<Label
											for={'childage_' + ix}
											required={true}
											labelTextkey="Household_ChildAge"
										/>
										<Validation
											forObject={x}
											errors={[
												{
													validator: this.householdService.childHasAge,
													textkey: 'Error_EnterAge',
												},
											]}>
											<Age
												className="Age"
												id={'childage_' + ix}
												suffix=" år"
												min={0}
												max={99}
												value={x.childsAge}
												handleChange={(e) => {
													this.childAgeChanged(e, x);
												}}
											/>
										</Validation>
									</div>
									<ChildTimePart
										case={c}
										child={x}
										timePartChanged={this.childTimePartChanged}
									/>
								</FormUnitRow>
							</FormUnit>
						);
					})}
				{ h.children && h.children.length>0 &&
					<FormUnit size={FormUnitSize.Small}>
						<Label
							for={'childCareAmount_' + ix}
							labelTextkey="Household_ChildCareAmount"
						/>
						<Money
							id={'childCareAmount_' + ix}
							suffix=" kr/månad"
							min={0}
							value={childCareAmount}
							handleChange={(e) => this.childCareAmountChanged(e, h)}
						/>
					</FormUnit>
				}
				<FormUnit size={FormUnitSize.Small}>
					<Label
						for={'childMaintenanceCost_' + ix}
						labelTextkey="Household_ChildMaintenanceCost"
					/>
					<Money
						id={'childMaintenanceCost_' + ix}
						suffix=" kr/månad"
						min={0}
						value={childMaintenanceCost}
						handleChange={(e) => this.childMaintenanceCostChanged(e, h)}
					/>
				</FormUnit>
				{showChildMaintenanceIncome &&
				<FormUnit size={FormUnitSize.Small}>
					<Label
						for={'childMaintenanceIncome_' + ix}
						labelTextkey="Household_ChildMaintenanceIncome"
					/>
					<Money
						id={'childMaintenanceIncome_' + ix}
						suffix=" kr/månad"
						min={0}
						value={childMaintenanceIncome}
						handleChange={(e) => this.childMaintenanceIncomeChanged(e, h)}
					/>
				</FormUnit>
				}
			</div>
		);
	}
}
