import { BaseService } from './BaseService';
import { Case, CompanyEconomy } from '../models/SelmaModels';
import { ValidationResult } from '../utils/ValidationResult';
import { Application } from '../models/AppModels';
import { CaseIdStatus } from '../libs/SMELPOClient/models/CaseIdStatus';
import { TextService } from './TextService';
import { ApplicantService } from './ApplicantService';

export enum CaseStep {
	Company, // Lista företag som berörs av lånet.
	Loan, // Information om lånet som önskas.
	Applicant, // Information om sökande personer
	Collateral, // Val av säkerhet för lånet
	Household, // Beskriv hushållet för medsökande.
	Budget, // Beskrivning av företagets budget.
	Summary, // Sammanställning av ärendet innan det skickas in.
	Done // Ansökan inskickad.
}

export interface StepDefinition {
	type: CaseStep;
	//ordinal: number;
	nameTextKey: string;
	canGoHome: boolean;
}

export interface SaveStepResult {
	step: StepDefinition;
}

const stepDefinitions: StepDefinition[] = [
	{
		type: CaseStep.Company,
		//ordinal: 1,
		nameTextKey: 'Company_Title',
		canGoHome: true,
	},
	{
		type: CaseStep.Loan,
		//ordinal: 2,
		nameTextKey: 'Loan_Title',
		canGoHome: true,
	},
	{
		type: CaseStep.Applicant,
		//ordinal: 3,
		nameTextKey: 'Applicant_Title',
		canGoHome: true,
	},
	{
		type: CaseStep.Collateral,
		//ordinal: 4,
		nameTextKey: 'Collateral_Title',
		canGoHome: true,
	},
	{
		type: CaseStep.Household,
		//ordinal: 5,
		nameTextKey: 'Household_Title',
		canGoHome: true,
	},
	{
		type: CaseStep.Budget,
		//ordinal: 6,
		nameTextKey: 'Budget_Title',
		canGoHome: true,
	},
	{
		type: CaseStep.Summary,
		//ordinal: 7,
		nameTextKey: 'Summary_Title',
		canGoHome: true,
	},
	{
		type: CaseStep.Done,
		//ordinal: 8,
		nameTextKey: 'Done_StepTitle',
		canGoHome: false,
	}
];


export class StepService extends BaseService {
	constructor(private application: Application, private textService:TextService, applicantService:ApplicantService) {
		super(); 
	}

	getStepDefinitions(c: Case): StepDefinition[] {
		let steps = stepDefinitions;
		if( !c.companies || c.companies.length===0 ) {
			steps = steps.filter(x => x.type!==CaseStep.Budget);
		}
		if( !c.households || c.households.length===0 ) {
			steps = steps.filter(x => x.type!==CaseStep.Household);
		}

		if(!this.shouldShowBudgetStep(c)) {
			steps = steps.filter(x => x.type!==CaseStep.Budget);
		}

		return steps;
	}

	shouldShowBudgetStep(c: Case): boolean {
		const companyEconomyService = this.application.services.companyEconomyService;
		const selectedCompany = this.application.services.companyService.getMySelectedCompany(c);
		const selectedCompanyEconomy = companyEconomyService.getCompanyEconomy(c, selectedCompany);

		return !!companyEconomyService.hasRevenue(selectedCompanyEconomy || {} as CompanyEconomy);
	}

	getStepIndex(step: StepDefinition, c: Case): number {
		let steps = this.getStepDefinitions(c);
		let index = steps.indexOf(step);
		return index;
	}

	getStepOrdinal(step: StepDefinition, c: Case): number {
		let index = this.getStepIndex(step, c);
		return index+1;
	}

	getStepDefinitionByType(steps: StepDefinition[], type: CaseStep): StepDefinition | undefined {
		var r = steps.filter(x => { return x.type===type});
		if( r && r.length===1 ) return r[0]
		else return;
	}

	validateStepCompany(c: Case): ValidationResult {
		var vr = new ValidationResult();
		const companyService = this.application.services.companyService;
		const companyEconomyService = this.application.services.companyEconomyService;
		//const applicantService = this.application.services.applicantService;
		//const caseService = this.application.services.caseService;

		// Must have selected company, or promise that they will buy a company.
		vr.add({ validator:companyService.hasSelectedCompany, ok: companyService.hasSelectedCompany(c) });

		// Must have selected company economy.

		// Must have selected HasRevenue yes/no
		// If hasRevenue=true
		// Must have selected high risk businiess
		// Must have all required Revenues.
		// All Revenues must have value zero or higher.
		// Must have at least one business focus.
		// Sum of one business focus parts (percent) must be 100.
		// If the maxmimum business focus part exists two or more times, mainFocus must be set on one of them.
		// If hasRevenue=false
		// Nothing.
		vr.addResult(companyEconomyService.validateMySelectedCompanies(c));

		return vr;
	}

	validateStepLoan(c: Case): ValidationResult {
		var vr = new ValidationResult();

		const applicantService = this.application.services.applicantService;
		const loanService = this.application.services.loanService;

		// Validate loan and data under loan.
		vr.addResult(loanService.validateCaseLoan(c));

		// My applicant must have checked all KYC approvals.
		vr.addResult(applicantService.validateMyApplicantKycApproval(c));

		return vr;
	}

	validateStepApplicant(c: Case): ValidationResult {
		var vr = new ValidationResult();

		const applicantService = this.application.services.applicantService;
		const myApplicantHasGivenFullApproval = applicantService.myApplicantHasGivenFullApproval(c);
		if( !myApplicantHasGivenFullApproval )
			return vr;

		const personalEconomyService = this.application.services.personalEconomyService;
		const companyService = this.application.services.companyService;
		const companyEconomyService = this.application.services.companyEconomyService;

		let hasApplicants = applicantService.hasApplicants(c);
		vr.add({ validator: applicantService.hasApplicants, object:c, ok: hasApplicants});
		if( hasApplicants && c.applicants ) {
			const myApplicant = applicantService.getMyApplicant(c);

			// For every Person applicant...
			//   Must have mobile phone.
			//   Must have mobile email.
			//   Must have one notification type selected.
			c.applicants.forEach(applicant => {
				const isMe = applicant===myApplicant;
				vr.addResult(applicantService.validateApplicantContact(applicant));
				vr.addResult(applicantService.validateApplicantKycApproval(applicant));

				const isJuridicumCompany = applicantService.isJuridicumCompany(c, applicant);

				const isPhysicum = !isJuridicumCompany && applicantService.isPhysicum(c, applicant);
				if( isPhysicum ) {
					vr.addResult(personalEconomyService.validateApplicantPersonalEconomy(c, applicant));
					if(applicantService.showPepForApplicant(applicant)) {
						vr.addResult(applicantService.validateApplicantPep(applicant));
					}
					const isPersonalCompany = applicantService.isPersonalCompany(c, applicant);
					if( isPersonalCompany ) {
						if( !isMe ) {
							const company = companyService.getApplicantCompany(c, applicant);
							if( company ) {
								vr.addResult(companyEconomyService.validateCompany(c, company, applicant));
							}
						}
					} else { // Private Person.
						// Nothing.
					}

				} else {
					if( isJuridicumCompany ) {
						const company = companyService.getApplicantCompany(c, applicant);
						if( company ) {
							vr.addResult(companyEconomyService.validateCompany(c, company, applicant));
						}
					}
				}
			});
		}
		return vr;
	}

	validateStepCollateral(c: Case): ValidationResult {
		var vr = new ValidationResult();

		const applicantService = this.application.services.applicantService;
		const myApplicantHasGivenFullApproval = applicantService.myApplicantHasGivenFullApproval(c);
		if( !myApplicantHasGivenFullApproval )
			return vr;

		const collateralService = this.application.services.collateralService;

		// Must have at least one collateral.
		vr.add({validator:this.validateStepCollateral, object:c, 
			ok: ((c.collaterals && c.collaterals.length > 0) ? true:false) 
			|| ((c.euSupports && c.euSupports.length > 0) ? true:false)
			|| ((c.guarantors && c.guarantors.length > 0) ? true:false) 
		});

		// For every Estate (type Collateral)...
		//   Must have municipality
		//   Must have house code.
		if( c.collaterals ) {
			c.collaterals.forEach(x => {
				vr.addResult(collateralService.validateCollateral(c, x));
			})
		}

		// For every EU-support ...
		//   Must have type
		//   Must have amount.
		if( c.euSupports ) {
			const euSupportService = this.application.services.euSupportService;
			c.euSupports.forEach(x => {
				vr.addResult(euSupportService.validateEuSupport(c, x));
			})
		}

		// For every Guarantor ...
		//   Must have name
		//   Must have phone.
		//   Must have ssn.
		if( c.guarantors ) {
			const guarantorService = this.application.services.guarantorService;
			vr.addResult(guarantorService.validateGuarantors(c));
		}
		return vr;
	}

	validateStepHousehold(c: Case): ValidationResult {
		var vr = new ValidationResult();

		const applicantService = this.application.services.applicantService;
		const myApplicantHasGivenFullApproval = applicantService.myApplicantHasGivenFullApproval(c);
		if( !myApplicantHasGivenFullApproval )
			return vr;

		const householdService = this.application.services.householdService;

		// Must have at least one household.
		const mustHaveHousehold = householdService.shouldHaveHouseholds(c);
		if( !mustHaveHousehold ) {
			vr.add({validator:this.validateStepHousehold, object:c, ok: true });
		}
		else //if( mustHaveHousehold )
		{
			vr.add({validator:this.validateStepHousehold, object:c, 
				ok: (c.households && c.households.length>0?true:mustHaveHousehold) 
			});

			// For every Person or EF applicant...
			//   Must be part of household.

			// For every Household...
			//   Must have at least one member.
			if( c.households ) {
				c.households.forEach(x => {
					vr.addResult(householdService.validateHousehold(c, x));
				})
			}

			// For every Household...
			//   Must have at least one member.
			//   Must have selected child count.
			//   Must have selected car count.
			//   If child count > 0 ...
			//     Age must be 0 or higher.
			//     Partial must be selected.

			// For every Maintenance cost
			//   TODO: Must be valid.
			if( c.maintenanceCosts ) {
				const maintenanceCostService = this.application.services.maintenanceCostService;
				c.maintenanceCosts.forEach(x => {
					vr.addResult(maintenanceCostService.validateMaintenanceCost(c, x));
				})
			}

			// For every ExtLoan 
			//   TODO: Must be valid.
			if( c.extLoans ) {
				const extLoanService = this.application.services.extLoanService;
				c.extLoans.forEach(x => {
					vr.addResult(extLoanService.validateExtLoan(c, x));
				})
			}
		}
		return vr;
	}

	validateStepBudget(c: Case): ValidationResult {
		var vr = new ValidationResult();

		const applicantService = this.application.services.applicantService;
		const myApplicantHasGivenFullApproval = applicantService.myApplicantHasGivenFullApproval(c);
		if( !myApplicantHasGivenFullApproval )
			return vr;

		// For every required attachement request...
		//    Must have matching attachment.
		const attachmentService = this.application.services.attachmentService;
		vr.addResult(attachmentService.validateAttachmentRequestsByRequestType(c, "COMPANYBUDGET"));

		const budgetService = this.application.services.budgetService;
		vr.addResult(budgetService.validateBudgets(c));

		if( vr.getItems().length===0 ) {
			// Add a single ok item if no items to signal that step is finished.
			let v = this.validateStepBudget;
			vr.add({validator: v, object:c, ok: true});
		}

		return vr;
	}

	validateStepSummary(c: Case): ValidationResult {
		var vr = new ValidationResult();

		const applicantService = this.application.services.applicantService;
		const myApplicantHasGivenFullApproval = applicantService.myApplicantHasGivenFullApproval(c);
		if( !myApplicantHasGivenFullApproval )
			return vr;

		const summaryService = this.application.services.summaryService;
		vr.addResult(summaryService.validateSummary(c));

		// Validate all previous steps.
		let v = this.validateStepSummary;
		vr.add({validator: v, object:c, ok: this.validateStepCompany(c).ok()});
		vr.add({validator: v, object:c, ok: this.validateStepLoan(c).ok()});
		vr.add({validator: v, object:c, ok: this.validateStepApplicant(c).ok()});
		vr.add({validator: v, object:c, ok: this.validateStepCollateral(c).ok()});
		vr.add({validator: v, object:c, ok: this.validateStepHousehold(c).ok()});

		if (this.shouldShowBudgetStep(c)) {
			vr.add({validator: v, object:c, ok: this.validateStepBudget(c).ok()});
		}

		return vr;
	}
	
	validateStepDone(c: Case): ValidationResult {
		var vr = new ValidationResult();

		if( c.status===CaseIdStatus.READYFOROFFICER || c.status===CaseIdStatus.CLOSEDBYOFFICER ) {
			// Add one ok for the fact that we have reached this step.
			vr.add({ validator: this.validateStepDone, object:c, ok:true});

			// For every required attachement request...
			//    Must have matching attachment.
			const attachmentService = this.application.services.attachmentService;
			vr.addResult(attachmentService.validateAttachmentRequests(c));

			// For every active kyc in case, must have status done
			const kycService = this.application.services.kycService;
			if(kycService.showKycForCase(c)) {
				vr.addResult(kycService.validateCaseKyc(c));
			}			
		}

		return vr;
	}

	validateStep(step: StepDefinition, c: Case) : ValidationResult {

		if( c.status===CaseIdStatus.STARTEDBYAPPLICANT ) {
			if( step.type===CaseStep.Company) {
				return this.validateStepCompany(c);
			} else if( step.type===CaseStep.Loan ) {
				return this.validateStepLoan(c);
			} else if( step.type===CaseStep.Applicant ) {
				return this.validateStepApplicant(c);
			} else if( step.type===CaseStep.Collateral ) {
				return this.validateStepCollateral(c);
			} else if( step.type===CaseStep.Household ) {
				return this.validateStepHousehold(c);
			} else if( step.type===CaseStep.Budget ) {
				return this.validateStepBudget(c);
			} else if( step.type===CaseStep.Summary ) {
				return this.validateStepSummary(c);
			} 
		}

		// Application sent in and not last step.
		else if( c.status===CaseIdStatus.READYFOROFFICER || c.status===CaseIdStatus.CLOSEDBYOFFICER ) {
			
			if(step.type===CaseStep.Done) {
				return this.validateStepDone(c);
			} else {
				var vr = new ValidationResult();
				vr.add({ validator: this.validateStep, object: c, ok: true})
				return vr;
			}
		}
		// else if( c.status==CaseIdStatus.CLOSEDBYOFFICER ) {
		// 	var vr = new ValidationResult();
		// 	vr.add({ validator: this.validateStep, object: c, ok: true})
		// 	return vr;
		// }
		else if( c.status===CaseIdStatus.CLOSEDBYAPPLICANT || c.status===CaseIdStatus.CLOSEDBYTHINNING ) {
			var vr2 = new ValidationResult();
			vr2.add({ validator: this.validateStep, object: c, ok: false})
			return vr2;
		}

		return new ValidationResult(); // Should never happen.
	}

	saveStep(step: StepDefinition, c: Case) : Promise<SaveStepResult> {

		if( step.type===CaseStep.Summary || step.type===CaseStep.Done )
			return Promise.resolve({step: step}); // dont save these steps.

		const canSaveCase = this.application.services.caseService.canSaveCase(c);
		if( !canSaveCase ) {
			return Promise.resolve({step: step});
		}
		
		return this.application.services.caseService
		.saveCase(c)
		.then(result => {
			return {
				step: step
			}
		});
	}

	// The first step that needs more info.
	getStartStep(c: Case): StepDefinition|undefined {
		const steps = this.getStepDefinitions(c);
		if( c.status===CaseIdStatus.READYFOROFFICER ) {
			return this.getStepDefinitionByType(steps, CaseStep.Done);
		}
		let currentStep:StepDefinition|undefined;
		for(let i=0; i<steps.length; i++ ) {
			let step = steps[i];
			if( this.canGoToStep(step, c) ) {
				currentStep = step;
				if( this.validateStep(step, c).failed() ) {
					break;
				}
			}
		}
		return currentStep;
	}


	canGoToStep(step: StepDefinition, c: Case): boolean {
		// For cases that have been sent in.
		if( c.status===CaseIdStatus.STARTEDBYAPPLICANT) {

			if( step.type===CaseStep.Done )
				return false;

			if( step.type===CaseStep.Company )
				return true;

			const companyService = this.application.services.companyService;
			const hasSelectedCompany = companyService.hasSelectedCompany(c);
			if( !hasSelectedCompany )
				return false;
				
			if( step.type===CaseStep.Loan )
				return true;

			const caseService = this.application.services.caseService;
			const loanService = this.application.services.loanService;
			const isNew = !caseService.isServerCase(c);
			const hasValidLoan = c.loan && loanService.hasSubLoans(c.loan);
			if( isNew && !hasValidLoan )
				return false;
			
			const applicantService = this.application.services.applicantService;
			const myApplicantHasGivenFullApproval = applicantService.myApplicantHasGivenFullApproval(c);
			if( !myApplicantHasGivenFullApproval )
				return false;

			return true;
		}
		else if( c.status===CaseIdStatus.READYFOROFFICER || c.status===CaseIdStatus.CLOSEDBYOFFICER ) {
			return step.type===CaseStep.Done || step.type===CaseStep.Summary;
		}
		else if( c.status===CaseIdStatus.CLOSEDBYTHINNING ) {
			return false;
		}
		else
			return false;
	}

	canLeaveStep(step: StepDefinition, c: Case): boolean {
		// For cases that have been sent in.
		if( c.status===CaseIdStatus.READYFOROFFICER && step.type!==CaseStep.Summary && step.type!==CaseStep.Done ) {
			return false;
		} 

		return true;
	}

	calcStepProgress(step: StepDefinition, c: Case) : number {
		// For cases that have been sent in.
		if( c.status===CaseIdStatus.READYFOROFFICER || c.status===CaseIdStatus.CLOSEDBYOFFICER ) {
			if( step.type!==CaseStep.Done)
				return 1;
		}

		//return 0.9999; // show green ring.

		const r = this.validateStep(step, c);
		const progress = this.calcStepProgressForValidationResult(r);
		return progress;
	}

	stepNeedsComplement(step: StepDefinition, c: Case) : boolean {
		if( c.status===CaseIdStatus.READYFOROFFICER || c.status===CaseIdStatus.CLOSEDBYOFFICER ) {
			if( step.type===CaseStep.Done) {
				const progress = this.calcStepProgress(step, c);
				return progress<1;
			}
		}
		return false;
	}


	calcStepProgressForValidationResult(result:ValidationResult) : number {
		let r = result;
		if( r.getOkCount()===0 )
			return 0;
		return r.getOkRate();
	}

	getStepStatusText(step: StepDefinition): string {
		if( !step.nameTextKey )
			return '';
		let text = this.textService.text(step.nameTextKey);
		return text||'';
	}
}
