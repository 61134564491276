import React, { ReactNode, ReactNodeArray } from "react";
import Classnames from 'classnames'
import AccordionComponent, { Icon } from './AccordionElements';

// Style
import './Accordion.scss';

interface AccordionProps {
	open?: boolean;
	children: ReactNode|ReactNodeArray;
	className?: string;
	title?: string|ReactNode;
};

export default class Accordion extends React.Component<AccordionProps, { open: boolean, active: boolean }> {
	constructor(props) {
		super(props);
		const { open } = this.props;

		this.state = { 
			open: open ? true : false,
			active: false,
		};

		this.toggleAccordion = this.toggleAccordion.bind(this);
		this.toggleActive = this.toggleActive.bind(this);
	}

	toggleAccordion() {
		return this.setState(
			prev => ({ open: !prev.open }),
			() => this.toggleActive(true), 
		)
	}

	toggleActive(setActive: boolean) {
		const { active } = this.state;
		if (active === setActive) {
			return null;
		}

		return this.setState({
			active: setActive,
		})
	}

	render() {
		const { children, className, title } = this.props;
		const { open, active } = this.state;
		const classNames = Classnames([
			'Accordion',
			open ? 'Accordion--open' : 'Accordion--closed',
			active ? 'Accordion--active' : undefined,
			className,
		]);

		return (
			<div className={classNames}>
				<button
					className="Accordion__header"
					onClick={this.toggleAccordion}>
					{title}
					<Icon flipped={this.state.open} />
				</button>
				<AccordionComponent
					onEnd={() => this.toggleActive(false)}
					active={this.state.open}>
					<div className="Accordion_inner">{children}</div>
				</AccordionComponent>
			</div>
		);
	}
}
