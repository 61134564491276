/* tslint:disable */
/* eslint-disable */
/**
 * SelmaSME API
 * This definition is the API between customer SME-Gui and the persistence layer for the application NOT FOR V1 When customer logg-in to Selma-EN, we will create a processId with applicant CustomerId (from BankID). When a customer added attachment to the application, we don\'t have a CaseId i LP, should we add a LP-Service to create/reserve a caseId to be able to attach a document to the caseId in DM.
 *
 * The version of the OpenAPI document: 0.7.4
 * Contact: developer@landshypotek.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EUType,
    EUTypeFromJSON,
    EUTypeFromJSONTyped,
    EUTypeToJSON,
} from './';

/**
 * Type of EUSupport instance
 * @export
 * @interface EUSupportType
 */
export interface EUSupportType {
    /**
     * application model unique identification
     * @type {string}
     * @memberof EUSupportType
     */
    processId: string;
    /**
     * Unique identifier for EU-Support
     * @type {string}
     * @memberof EUSupportType
     */
    euId: string;
    /**
     * 
     * @type {EUType}
     * @memberof EUSupportType
     */
    euType?: EUType;
    /**
     * summerat EU-stöd
     * @type {number}
     * @memberof EUSupportType
     */
    supportAmount?: number;
    /**
     * EU-stödet gäller år
     * @type {string}
     * @memberof EUSupportType
     */
    supportYear?: string;
}

export function EUSupportTypeFromJSON(json: any): EUSupportType {
    return EUSupportTypeFromJSONTyped(json, false);
}

export function EUSupportTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): EUSupportType {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'processId': json['processId'],
        'euId': json['euId'],
        'euType': !exists(json, 'euType') ? undefined : EUTypeFromJSON(json['euType']),
        'supportAmount': !exists(json, 'supportAmount') ? undefined : json['supportAmount'],
        'supportYear': !exists(json, 'supportYear') ? undefined : json['supportYear'],
    };
}

export function EUSupportTypeToJSON(value?: EUSupportType | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'processId': value.processId,
        'euId': value.euId,
        'euType': EUTypeToJSON(value.euType),
        'supportAmount': value.supportAmount,
        'supportYear': value.supportYear,
    };
}


