/* eslint-disable react/prop-types */
import React from 'react';
import ClassNames from 'classnames';
import { Application } from '../../../../models/AppModels';
import './Button.scss';

interface Props {
	labelTextkey?: string;
	label?:string;
	className?: string;
	id?: string;
	name?: string;
	onClick?: React.MouseEventHandler<HTMLButtonElement>; //(React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
	type?: 'submit' | 'reset' | 'button';
	value?: string | number;
	style?: 'normal' | 'round' | 'link' | 'icon';
	enabled?: boolean;
	title?: string | undefined;
	autofocus?: boolean;
}

export const Button: React.FunctionComponent<Props> = (props) => {
	const className = ClassNames(
		props.className, {
		round: props.style === 'round',
		link: props.style === 'link',
		icon: props.style === 'icon'
	});

	let label = (props.labelTextkey?Application.instance.services.textService.textOrEmpty(props.labelTextkey):'');
	if( props.label ) {
		if( label.length===0)
			label = props.label;
		else
			label += ' ' + props.label;
	}

	return (
		<button
			className={className}
			id={props.id}
			name={props.name}
			disabled={!props.enabled}
			onClick={props.onClick}
			type={props.type}
			value={props.value}
			autoFocus={props.autofocus}
			title={props.style === 'round' ? label : undefined}>
			{props.style === 'round' ? null : (
				<span>{label}</span>
			)}
		</button>
	);
};



// Default props
Button.defaultProps = {
	enabled: true,
	className: 'Button',
	type: 'button',
	style: 'normal',
	autofocus: false,
};
