import React from 'react';
import { AppComponent } from '../../../AppComponent';
import { AlignItems, FormUnitRow, Justify } from '../../../layout/FormUnitRow/FormUnitRow';
import { Money } from '../../../widget/input/Money/Money';
import { Label } from '../../../widget/display/Label/Label';
import { DeleteButton } from '../../../widget/input/DeleteButton/DeleteButton';
import { Validation } from '../../../widget/display/Validation/Validation';
import { Loan, TakeoverLoan } from '../../../../models/SelmaModels';
import { LoanService } from '../../../../services/LoanService';
import { SelectOption, Select } from '../../../widget/input/Select/Select';
import { NumberFormatValues } from 'react-number-format';
import { Textbox } from '../../../widget/input/Textbox/Textbox';
import { Checkbox } from '../../../widget/input/Checkbox/Checkbox';
import { Help } from '../../../widget/display/Help/Help';
import { T } from '../../../widget/display/T';

interface Props {
	bankOptions: SelectOption[];
	canDelete: boolean;
	index: number;
	loan: Loan;
	aimCategoryOptions: SelectOption[];
	takeoverLoan: TakeoverLoan;
}

interface State {
	showTakeoverLoanDetails?: boolean;
}

export class LoanTakeOver extends AppComponent<Props, State> {
	loanService: LoanService;

	constructor(props: Props) {
		super(props);

		this.loanService = this.application.services.loanService;

		this.state = { showTakeoverLoanDetails: this.loanService.hasTakeoverLoanDetails(this.props.takeoverLoan) };
	}

	loanNumberChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
		const loanNumber = event.target.value ? event.target.value : '';
		this.loanService.setLoanTakeOverLoanNumber(this.props.loan, this.props.takeoverLoan, loanNumber);
	}

	loanAmountChanged = (values: NumberFormatValues) => {
		let value: number | undefined = values.floatValue;
		if (value === undefined || values.value === '') {
			value = undefined;
		}
		this.loanService.setLoanTakeOverDebtAmount(
			this.props.loan,
			this.props.takeoverLoan,
			value
		);
	}

	initialAimCategoryChanged = (aim: string): void => {
		this.props.takeoverLoan.aimCategory = aim;
		this.props.takeoverLoan.aimDetail = '';
		this.loanService.updateLoanTakeOver(this.props.loan, this.props.takeoverLoan);
	}

	initialAimDetailChanged = (aim: string) => {
		this.props.takeoverLoan.aimDetail = aim;
		this.loanService.updateLoanTakeOver(this.props.loan, this.props.takeoverLoan);
	}

	loanBankChanged = (creditInstitute: string) => {
		this.loanService.setLoanTakeOverBank(this.props.loan, this.props.takeoverLoan, creditInstitute);
	}

	deleteLoan = () => {
		this.loanService.removeLoanTakeOver(this.props.loan, this.props.takeoverLoan);
	}

	hasTakeoverLoanDetailsChanged = (event: React.ChangeEvent<HTMLInputElement>): void => {
		const checked = event.target.checked;
		if (!checked) {
			this.loanService.clearTakeoverLoanDetails(this.props.takeoverLoan);
			this.setState({ showTakeoverLoanDetails: false });
		} else {
			this.setState({ showTakeoverLoanDetails: true });
		}
	}


	render() {
		const loanService = this.loanService;
		const takeoverLoan = this.props.takeoverLoan;
		const index = this.props.index;
		const aimCategoryCode = takeoverLoan.aimCategory || '';
		const hasAimCategoryCode = takeoverLoan.aimCategory ? true : false;
		const aimDetailCode = takeoverLoan.aimDetail || '';
		const maxAmount = this.loanService.getMaximumTakeoverLoanDebtAmount();

		const loanCounter = (index + 1).toString();

		let aimCategoryOption;
		let detailOptions;
		if (hasAimCategoryCode) {
			const aimCategoryOptionResult = this.props.aimCategoryOptions.filter(
				(x) => x.value === aimCategoryCode
			);
			if (aimCategoryOptionResult.length === 1) {
				aimCategoryOption = aimCategoryOptionResult[0];
				detailOptions = aimCategoryOption.detailOptions.filter((x) => {
					// TODO: remove items that have aleady been selected.
					return true;
				});
			}
		}

		const creditInstitute = takeoverLoan.creditInstitute || '';
		const loanNumber = takeoverLoan.loanNumber || '';

		const hasTakeoverLoanDetails = this.state.showTakeoverLoanDetails === true || this.loanService.hasTakeoverLoanDetails(takeoverLoan);

		return (
			<div className="LoanTakeOver">
				
					{this.props.canDelete && (
						<FormUnitRow layout={Justify.SpaceBetween} alignItems={AlignItems.Center}>
							<h3>
								<T k="Household_ExtLoan"></T> {loanCounter}
								</h3>
							<div><DeleteButton
								labelTextkey="Loan_RemoveLoanTakeOver"
								onClick={this.deleteLoan}
							/></div>
						</FormUnitRow>
					)}

					<FormUnitRow>
						<div className="FormUnitRow_Section">
							<Label for="loanInitialTOLCategory_${index}" labelTextkey="Loan_InitialAimCategory" />
							<Validation
								forObject={takeoverLoan}
								errors={[
									{
										validator: loanService.takeoverLoanHasCategory,
										textkey: 'Error_SelectValue',
									},
								]}>
								<Select
									handleChange={this.initialAimCategoryChanged}
									id={`loanInitialTOLCategory_${index}`}
									isSearchable={true}
									noSelectableOptionsTextkey="Select_NoOptions"
									options={this.props.aimCategoryOptions}
									placeholderTextkey="Loan_SelectInitialAimCategory"
									value={aimCategoryCode}
								/>
							</Validation>
						</div>
						<div className="FormUnitRow_Section">
							<Label for="loanInitialTOLDetail_${index}" labelTextkey="Loan_InitialAimDetail" />
							<Validation
								forObject={takeoverLoan}
								errors={[
									{
										validator: loanService.takeoverLoanHasDetail,
										textkey: 'Error_SelectValue',
									},
								]}>
								<Select
									enabled={hasAimCategoryCode}
									handleChange={this.initialAimDetailChanged}
									id={`loanInitialTOLDetail_${index}`}
									isSearchable={true}
									noSelectableOptionsTextkey="Select_NoOptions"
									options={detailOptions}
									placeholderTextkey="Loan_SelectInitialAimDetail"
									value={aimDetailCode}
								/>
							</Validation>
						</div>
						<div className="FormUnitRow_Section">
							<Label for={`TOLdebtAmount_${index}`} labelTextkey="Loan_LoanAmount" />
							<Validation
								forObject={takeoverLoan}
								errors={[
									{
										validator: loanService.takeoverLoanHasAmount,
										textkey: 'Error_EnterValue',
									},
									{
										validator: loanService.takeoverLoanHasValidAmount,
										textkey: 'Loan_TakeoverLoan_Amount_Invalid',
									}
								]}>
								<Money
									id={`TOLdebtAmount_${index}`}
									handleChange={this.loanAmountChanged}
									min={0}
									max={maxAmount}
									enabled={true}
									value={takeoverLoan.debtAmount}
								/>
							</Validation>
						</div>

					</FormUnitRow>
					<FormUnitRow layout={Justify.FlexStart}>
						<Checkbox checked={hasTakeoverLoanDetails} labelTextkey="Loan_HasTakeOverDetails" handleChange={this.hasTakeoverLoanDetailsChanged}>
							<Help helpTextkey="Loan_HasTakeOverDetails_Help" />
						</Checkbox>

					</FormUnitRow>
					{hasTakeoverLoanDetails &&
						<FormUnitRow>
							<div className="FormUnitRow_Section">
								<Label for={`TOLbank_${this.props.index}`} labelTextkey="Loan_BankName" />
								<Select
									id={`TOLbank_${this.props.index}`}
									value={creditInstitute}
									options={this.props.bankOptions}
									handleChange={this.loanBankChanged}
									placeholderTextkey="Loan_SelectBank"
									noSelectableOptionsTextkey="Select_NoOptions"
									isSearchable={true}
								/>
							</div>
							<div className="FormUnitRow_Section">
								<Label for={`TOLloanNumber_${this.props.index}`} labelTextkey="Loan_LoanNumber" />
								<Textbox
									id={`TOLloanNumber_${this.props.index}`}
									value={loanNumber}
									handleChange={this.loanNumberChanged}
									maxlength={30}
								/>
							</div>
						</FormUnitRow>
					}
			</div>
		);
	}
}
