import React from 'react';
import { AppComponent } from '../../../../AppComponent';
import Accordion from '../../../../layout/Accordion/Accordion';
import { Applicant, Case } from '../../../../../models/SelmaModels';
import {
	FormUnit,
	FormUnitSize,
	FormUnitPadding,
} from '../../../../layout/FormUnit/FormUnit';
import { Panel } from '../../../../layout/Panel/Panel';
import { Heading } from '../../../../widget/display/Heading/Heading';
import { ApplicantItemSummary } from './ApplicantItemSummary';

interface Props {
	case: Case;
	accordionOpen?: boolean;
	formUnitSize?: FormUnitSize;
	hideMyApplicant?:boolean;
}

export class ApplicantsSummary extends AppComponent<Props> {
	constructor(props: Props) {
		super(props);
	}

	static defaultProps = {
		accordionOpen: false,
		formUnitSize: FormUnitSize.Large
	};

	render() {
		const c = this.props.case;

		let myApplicant:Applicant|undefined;
		let applicants = c.applicants;
		if( applicants && this.props.hideMyApplicant===true ) {
			myApplicant = this.application.services.applicantService.getMyApplicant(c);
		}
		return (
			<div className="ApplicantsSummary">
				<FormUnit size={this.props.formUnitSize} padding={FormUnitPadding.Small}>
					<Accordion
						title={<Heading level={2} labelTextkey="Summary_Applicants_Title"/>}
						open={this.props.accordionOpen}>
						{ applicants && applicants.map((x, ix) => {
							const hideCompanyInformation = myApplicant && x===myApplicant;
							return (
								<Panel className="Panel Applicant" key={`applicant_${x.id}_${ix}`}>
									<ApplicantItemSummary case={c} applicant={x} hideCompanyInformation={hideCompanyInformation}/>
								</Panel>
							);
						})}
					</Accordion>
				</FormUnit>
			</div>
		);
	}
}
