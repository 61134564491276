/* tslint:disable */
/* eslint-disable */
/**
 * SelmaSME API
 * This definition is the API between customer SME-Gui and the persistence layer for the application NOT FOR V1 When customer logg-in to Selma-EN, we will create a processId with applicant CustomerId (from BankID). When a customer added attachment to the application, we don\'t have a CaseId i LP, should we add a LP-Service to create/reserve a caseId to be able to attach a document to the caseId in DM.
 *
 * The version of the OpenAPI document: 0.7.4
 * Contact: developer@landshypotek.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Syfte med respektive lånebelopp
 * @export
 * @interface AimType
 */
export interface AimType {
    /**
     * Unique identity for aims
     * @type {string}
     * @memberof AimType
     */
    aimId: string;
    /**
     * Ändamål och syfte ska sättas på respektive lån som går över till LP. I de fall där det inte specifiseras ska handläggaren göra det innan överföring till SAP. Detta för att de ska bli rätt i uppföljning ned i SAS och den finsiella rapporteringen.
     * @type {string}
     * @memberof AimType
     */
    purposeText?: string;
    /**
     * Delsyfte på respektive lån, läses från mainPurposes.json
     * @type {string}
     * @memberof AimType
     */
    aimText?: string;
    /**
     * Lånebelopp för part
     * @type {number}
     * @memberof AimType
     */
    loanAmountPart?: number;
}

export function AimTypeFromJSON(json: any): AimType {
    return AimTypeFromJSONTyped(json, false);
}

export function AimTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): AimType {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'aimId': json['aimId'],
        'purposeText': !exists(json, 'purposeText') ? undefined : json['purposeText'],
        'aimText': !exists(json, 'aimText') ? undefined : json['aimText'],
        'loanAmountPart': !exists(json, 'loanAmountPart') ? undefined : json['loanAmountPart'],
    };
}

export function AimTypeToJSON(value?: AimType | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'aimId': value.aimId,
        'purposeText': value.purposeText,
        'aimText': value.aimText,
        'loanAmountPart': value.loanAmountPart,
    };
}


