import React from 'react';
import { Loan } from '../../../../models/selmamodels/Loan';
import { AppComponent } from '../../../AppComponent';
import { Select, SelectOption } from '../../../widget/input/Select/Select';
import {
	uiLoanDownpaymentTypes,
} from '../../../../models/UiModels';
import { LoanService } from '../../../../services/LoanService';
import { Textbox } from '../../../widget/input/Textbox/Textbox';
import { Label } from '../../../widget/display/Label/Label';
import { FormUnit } from '../../../layout/FormUnit/FormUnit';
import { getUIName } from '../../../../models/uimodels/UINamed';

interface Props {
	loan: Loan;
}

export class LoanDownpayment extends AppComponent<Props> {
	loanService: LoanService;
	downpaymentTypeOptions: SelectOption[];

	constructor(props: Props) {
		super(props);
		this.observeUpdate(this.props.loan);

		this.loanService = this.application.services.loanService;
		const textService = this.application.services.textService;
		this.downpaymentTypeOptions = uiLoanDownpaymentTypes.map((x) => {
			return {
				value: x.key,
				label: getUIName(x, textService),
			};
		});
	}

	downpaymentSourceChanged = (value: string): void => {
		let r = uiLoanDownpaymentTypes.filter(x=> x.key===value);
		if( r.length ) {
			this.props.loan.downpaymentType = r[0].type;
			this.loanService.update(this.props.loan);
		}
	};

	downpaymentOtherChanged = (
		event: React.ChangeEvent<HTMLInputElement>
	): void => {
		this.props.loan.downpaymentOther = event.target.value;
		this.loanService.update(this.props.loan);
	};

	render() {
		const loan = this.props.loan;
		let downpaymentType: string = '';
		if( loan.downpaymentType!==undefined ) {
			let r = uiLoanDownpaymentTypes.filter(x=> x.type===loan.downpaymentType);
			if( r.length )
				downpaymentType = r[0].key;
		}
		let downpaymentOther: string = loan.downpaymentOther||'';
		const askAboutOther = this.loanService.askAboutDownpaymentOther(
			this.props.loan
		);
		return (
			<div className="LoanDownpayment">
				<Select
					id="downpaymentType"
					value={downpaymentType}
					options={this.downpaymentTypeOptions}
					handleChange={this.downpaymentSourceChanged}
					placeholderTextkey=""
					noSelectableOptionsTextkey=""
					isSearchable={true}
				/>
				{askAboutOther && (
					<FormUnit>
						<Label labelTextkey="Loan_Downpayment_Other" required={true}/>
						<Textbox
							id="downpaymentOther"
							value={downpaymentOther}
							handleChange={this.downpaymentOtherChanged}
							maxlength={50}
						/>
					</FormUnit>
				)}
			</div>
		);
	}
}
