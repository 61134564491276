import { UiLanguageService, UiLanguage } from './UiLanguageService';
import { BaseService } from './BaseService';
import { StateService } from './StateService';

export class TextService extends BaseService {

	private stateService: StateService;
	private uiLanguageService: UiLanguageService;

	constructor(stateService: StateService, uiLanguageService: UiLanguageService) {
		super();
		this.stateService = stateService;
		this.uiLanguageService = uiLanguageService;
	}

	text(key: string): string | undefined {
		let l = this.stateService.state.language;
		if (l)
			return l.texts[key];
		return undefined;
	}

	texts(keys: string[]): (string|undefined)[] {
		let l = this.stateService.state.language;
		if (!l) {
			return [];
		}
		let ll = l;
		return keys.map(key => { return ll.texts[key];});
	}

	textsOrEmpty(keys: string[]): string[] {
		return this.texts(keys).map(str => { return str?str:''; });
	}

	textOrKey(key: string): string {
		return this.textOrDefault(key,key);
	}

	textOrEmpty(key: string): string {
		return this.textOrDefault(key,'');
	}

	formattedTextOrEmpty(key: string, data:any): string {
		const t = this.textOrDefault(key,'');
		const ft = this.format(t, data);
		return ft;
	}

	// Takes a string such as "foo bar {paramA}, {paramB} xxx yyy"
	// and formats with data from object such as {paramA:"Patrik", paramB:"Awesome"}
	// into a string.
	format(text:string, data:any) : string {
		for (var property in data) {
			// eslint-disable-next-line no-prototype-builtins
			if (data.hasOwnProperty(property)) {
				let r = new RegExp("{"+property+"}","g")
				let val = data[property];
				text = text.replace(r, val);
			}
		}
		return text;
	}

	textOrDefault(key: string, defaultText:string): string {
		var t = this.text(key);
		if (t)
			return t;
		return defaultText;
	}

	/**
	 * Nils never return empty values. If empty, the value==key, ex "lang|sv-SE|SME.Settings_MaintenanceMode": "lang|sv-SE|SME.Settings_MaintenanceMode"
	 * Editors in nils can "empty" the value by setting a whitespace " " which is returned by Nils Service
	 * this method returns defaultText if key==value or value.lengt <3 chars
	 * @param key Text key
	 * @param defaultText Default value
	 */
	nilsEmptyOrDefault(key:string, defaultText:string): string {
		let value = this.text(key);

		if (value === undefined)
			return defaultText;

		// keys in SME api are stripped from 'lang|sv-SE|SME.' so just compare last part of value against 'key'
		if (value.toLowerCase().endsWith(key.toLocaleLowerCase()))
			return defaultText;

		if (value.length < 3)
			return defaultText;

		return value;
	}

	useLanguage(languageCode: string): Promise<UiLanguage> {
		return this.uiLanguageService
			.load(languageCode)
			.then((result) => {
				let oldLang = this.stateService.state.language;
				this.stateService.state.language = result;
				this.stateService.replace(oldLang, result);
				return result;
			})
	}
}
