import { BaseService } from './BaseService';
import { Case, HouseholdMember } from '../models/SelmaModels';
import { Household, Child } from '../models/selmamodels/Household';
import { Applicant } from "../models/selmamodels/Applicant";
import { SMELPOService } from './SMELPOService';
import { StateService } from './StateService';
import { ValidationResult } from '../utils/ValidationResult';
import { GuardService } from './GuardService';
import { ApplicantService } from './ApplicantService';
import { IdService } from './IdService';
import { CompanyService } from './CompanyService';
import { ExtLoanService } from './ExtLoanService';
import { MaintenanceCostService } from './MaintenanceCostService';

/*
Handles all logic for Household.
*/
export class HouseholdService extends BaseService {

	private stateService: StateService;
	private smelpoService: SMELPOService;
	private guardService: GuardService;

	public companyService?:CompanyService; 
	public extLoanService?:ExtLoanService;
	public maintenanceCostService?:MaintenanceCostService;

	constructor(stateService: StateService, smelpoService: SMELPOService, 
		guardService: GuardService, private applicantService:ApplicantService,
		private idService:IdService
		) {
		super();
		this.stateService = stateService;
		this.smelpoService = smelpoService;
		this.guardService = guardService;
	} 

	update(household: Household|Case|Child): void {
		this.stateService.update(household);
	}

	getHouseholdDescription(c: Case, household: Household) {
		if( !household.members || household.members.length===0 )
			return "Tomt hushåll";

		let desc:string = "";

		household.members.forEach(x => {
			if( x.householdMember ) {
				let applicant = this.applicantService.getApplicantByCustomerId(c, x.householdMember);
				if( applicant ) {
					let name = this.applicantService.getPersonFullName(applicant);
					if( name ) {
						if( desc.length>0 )
							desc += " och "
						desc += name;
					}
				}
			}
		});
	}

	// Creates all missing sure there is at least one household for this applicant
	ensureHouseholds(c:Case):void {
		var applicansThatShouldHaveHouseholds = this.getApplicantsForHouseholds(c);
		applicansThatShouldHaveHouseholds.forEach(x => {
			this.ensureHouseholdForApplicant(c, x);
		})
	}

	private ensureHouseholdForApplicant(c:Case, applicant:Applicant):void {
		if( !applicant.customerId )
			return;
		if( !this.applicantService.isPhysicum(c,applicant))
			return;

		if( this.hasHouseholds(c))
			return; // TODO: Fix this in the future by convincing LHB that they should have multiple households.

		let households = this.getHouseholdsByCustomerId(c, applicant.customerId);
		if( households.length===0 ) {
			let household = this.newHousehold(applicant);
			this.addHousehold(c, household);
		}
	}

	newHousehold(applicant: Applicant) : Household {
		if( !applicant.customerId )
			throw Error('No applicant customer id');

		let hhMember:HouseholdMember = {
			//id: this.idService.newGuid(),
			householdMember: applicant.customerId||''
		}
		let hh:Household = {
			id: this.idService.newGuid(),
			members: [
				hhMember
			]
		}
		return hh;
	}

	removeHousehold(c:Case, household:Household):void {
		if( !c.households ) 
			return;

		this.removeHouseholdExtLoans(c, household);
		this.removeHouseholdMaintenanceCosts(c, household);
				
		this.stateService.listRemove(c.households, household);

		this.update(c);
	}

	removeHouseholdExtLoans(c: Case, household: Household):void {
		if( this.extLoanService ) {
			this.extLoanService.removeExtLoansForHousehold(c, household);
		}
	}

	removeHouseholdMaintenanceCosts(c: Case, household: Household):void {
		if( this.maintenanceCostService ) {
			this.maintenanceCostService.removeMaintenanceCostsForHousehold(c, household);
		}
	}



	removeAllHouseholds(c: Case) {
		if( !c.households ) 
			return;

		if( this.extLoanService)
			this.extLoanService.removeAllExtLoans(c);
		if( this.maintenanceCostService )
			this.maintenanceCostService.removeAllMaintenanceCosts(c);
			
		this.stateService.listRemoveAll(c.households);
		c.households = undefined;
		this.update(c);
	}

	shouldHaveHouseholds(c: Case):boolean {
		const r = this.getApplicantsForHouseholds(c);
		return r.length>0;
	}

	getApplicantsForHouseholds(c: Case):Applicant[] {
		const r = this.applicantService.getPhysicumApplicants(c);
		return r;
	}

	getFirstHousehold(c:Case) : Household|undefined {
		if( c.households && c.households.length>0 ) {
			return c.households[0];
		} else {
			return;
		}
	}

	getHouseholdMembersApplicants(c: Case, household:Household|undefined):Applicant[]|undefined {
		if( !household || !household.members )
			return;
		
		var applicants = household.members
			.map(x => x.householdMember?this.applicantService.getApplicantByCustomerId(c, x.householdMember) : undefined)
			.filter(x => x!==undefined) as Applicant[];
		return applicants;
	}

	hasHouseholds(c: Case):boolean {
		return c.households && c.households.length>0 ? true:false;
	}
	
	addHousehold(c: Case, household: Household) {
		if( !c.households )
			c.households = [];
		c.households.push(household);
		this.update(c);
	}

	getHouseholdsByCustomerId(c:Case, customerId: string) : Household[] {
		if( !c.households )
			return [];

		const r = c.households.filter(x=>{
			return this.isHouseholdMember(x, customerId)
		});
		return r;
	}

	isHouseholdMember(household:Household, customerId:string) : boolean {
		if( !household.members )
			return false;
		
		for( let householdMember of household.members ) {
			if( householdMember.householdMember && this.guardService.compareCustomerId(householdMember.householdMember, customerId) )
				return true;
		}
		return false;
	}

	setHouseholdMembersByCustomerIds(c:Case, household:Household, customerIds:string[]) : void {

		var customerIdsToAdd = customerIds.filter(customerId => !this.getMember(household, customerId));
		
		var householdMembersToRemove:HouseholdMember[] = [];
		if( household.members ) {
			householdMembersToRemove = household.members.filter(member => customerIds.findIndex(x=>this.guardService.compareCustomerId(x,member.householdMember||''))<0 )
		}

		if( customerIdsToAdd.length ) {
			customerIdsToAdd.forEach(x => {
				this.addHouseholdMember(household, x);
			})
		}

		if( householdMembersToRemove.length ) {
			householdMembersToRemove.forEach(x => {
				if( x.householdMember )
					this.removeHouseholdMember(c, household, x.householdMember);
			})
		}
	}

	removeCustomerFromAllHouseholds(c:Case, customerId:string) : void {
		const households = this.getHouseholdsByCustomerId(c, customerId);
		households.forEach(hh => {this.removeHouseholdMember(c, hh, customerId)});
		this.update(c);
	}

	addHouseholdMember(household:Household, customerId:string) : void {
		if( !household.members )
			household.members = [];

		var member = {
			id: this.idService.newGuid(),
			householdMember: customerId
		};
		this.stateService.listAdd(household.members, member);
		this.update(household);
	}

	removeHouseholdMember(c: Case, household:Household, customerId:string) : void {
		if( !household.members )
			return;

		var member = this.getMember(household, customerId);
		if( member ) {

			// Remove member from extloans
			if( this.extLoanService ) {
				this.extLoanService.removeLoanOwnerForHousehold(c,  household, customerId);
			}

			// Remove member from maintenance costs.
			if( this.maintenanceCostService ) {
				this.maintenanceCostService.removeLoanOwnerForHousehold(c, household, customerId);
			}

			this.stateService.listRemove(household.members, member);
			this.update(household);
		}
	}

	getMember(household: Household, customerId: string) : HouseholdMember|undefined {
		if( !household.members )
			return;
		
		for( let householdMember of household.members ) {
			if( householdMember.householdMember && this.guardService.compareCustomerId(householdMember.householdMember, customerId) )
				return householdMember;
		}
		return;
	}

	setNumberOfChildrenAtHome(c: Case, household: Household, numberOfChildrenAtHome: number) {
		if( household.numberOfChildrenAtHome !== numberOfChildrenAtHome ) {
			if( numberOfChildrenAtHome<=0 ) {
				household.numberOfChildrenAtHome = 0;
				household.children = undefined;
			} else {
				if( !household.children )
					household.children = [];
				else 
					household.children.length = numberOfChildrenAtHome; 
				for( let i=0; i<numberOfChildrenAtHome; i++ ) {
					if( !household.children[i] ) {
						household.children[i] = this.newChild()
					}
				}
			}

			household.numberOfChildrenAtHome = numberOfChildrenAtHome;
			this.update(household);
		}
	}

	newChild(): Child {
		return {
			id: this.idService.newGuid()
		}
	}

	validateHousehold(c: Case, x: Household): ValidationResult {
		let vr = new ValidationResult();
		vr.add({validator:this.hasValidMembers, object:x, ok: this.hasValidMembers(x)});
		const hasNumberOfChildren = this.hasNumberOfChildren(x);
		vr.add({validator:this.hasNumberOfChildren, object:x, ok: hasNumberOfChildren});
		if( hasNumberOfChildren && x.children ) {
			x.children.forEach(child => {
				vr.add({validator:this.childHasAge, object:child, ok: this.childHasAge(child)});
				vr.add({validator:this.childHasPartial, object:child, ok: this.childHasPartial(child)});
			})
		}
		return vr;
	}

	childHasAge(child: Child): boolean {
		const g = this.guardService;
		return g.isNumber(child.childsAge) && g.isZeroOrGreater(child.childsAge);
	}

	childHasPartial(child: Child): boolean {
		const g = this.guardService;
		return g.hasValue(child.partInHousehold);
	}

	hasValidMembers(x: Household): boolean {
		const g = this.guardService;
		return g.hasLengthGreaterThan(x.members,0) && g.hasLengthLessThan(x.members,3);
	}

	hasNumberOfChildren(x: Household): boolean {
		const g = this.guardService;
		return g.isNumber(x.numberOfChildrenAtHome) 
			&& (
				x.numberOfChildrenAtHome===0 
				|| (x.children && x.children.length===x.numberOfChildrenAtHome ? true:false)
			);
	}

}
