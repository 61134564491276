import { BudgetYear } from '../SelmaModels';
import { UINamed } from './UINamed';

export interface UIBudgetYearItem extends UINamed {
	ordinal: number;
	nameTextkey: string;
	name: string;
	valueType: ValueType;
	calculation?: (budgetYear: BudgetYear) => number;
}

export enum ValueType {
	'positive' = 'positive',
	'negative' = 'negative',
	'both' = 'both',
}

export const uiBudgetYearItems = [
	{
		ordinal: 1,
		nameTextkey: 'Budget_Value_Value1',
		name: 'Skog',
		valueType: ValueType.positive,
	}, // Resultaträkning; Skog
	{
		ordinal: 2,
		nameTextkey: 'Budget_Value_Value2',
		name: 'Växtodling',
		valueType: ValueType.positive,
	}, // Resultaträkning; Växtodling
	{
		ordinal: 3,
		nameTextkey: 'Budget_Value_Value3',
		name: 'EU-stöd',
		valueType: ValueType.positive,
	}, // Resultaträkning; EU-stöd
	{
		ordinal: 4,
		nameTextkey: 'Budget_Value_Value4',
		name: 'Övrig djurproduktion',
		valueType: ValueType.positive,
	}, // Resultaträkning; Övrig djurproduktion
	{
		ordinal: 5,
		nameTextkey: 'Budget_Value_Value5',
		name: 'Förändring av lager produktion',
		valueType: ValueType.positive,
	}, // Resultaträkning; Förändring av lager produktion
	{
		ordinal: 6,
		nameTextkey: 'Budget_Value_Value6',
		name: 'Mjölk',
		valueType: ValueType.positive,
	}, // Resultaträkning; Mjölk
	{
		ordinal: 7,
		nameTextkey: 'Budget_Value_Value7',
		name: 'Övriga intäkter',
		valueType: ValueType.positive,
	}, // Resultaträkning; Övriga intäkter
	{
		ordinal: 8,
		nameTextkey: 'Budget_Value_Value8',
		name: 'Omsättning totalt (Totala intäkter)',
		valueType: ValueType.positive,
	}, // Omsättning totalt (Totala intäkter) summa value1-7
	{
		ordinal: 9,
		nameTextkey: 'Budget_Value_Value9',
		name: 'Inköp (Råvaror och förnödenheter)',
		valueType: ValueType.negative,
	}, //
	{
		ordinal: 10,
		nameTextkey: 'Budget_Value_Value10',
		name: 'Arrendekostnader',
		valueType: ValueType.negative,
	}, //
	{
		ordinal: 11,
		nameTextkey: 'Budget_Value_Value11',
		name: 'Personalkostnader',
		valueType: ValueType.negative,
	}, //
	{
		ordinal: 12,
		nameTextkey: 'Budget_Value_Value12',
		name: 'Övriga rörelsekostnader',
		valueType: ValueType.negative,
	}, //
	{
		ordinal: 13,
		nameTextkey: 'Budget_Value_Value13',
		name: 'S:a kostnader',
		valueType: ValueType.negative,
	}, // S:a kostnader (summa value9-12)"
	{
		ordinal: 14,
		nameTextkey: 'Budget_Value_Value14',
		name: 'Resultat före avskrivningar',
		valueType: ValueType.both,
	}, // Resultat före avskrivningar (value8 + (-value13))
	{
		ordinal: 15,
		nameTextkey: 'Budget_Value_Value15',
		name: 'Avskrivning inventarier (exkl. byggnadsinventarier)',
		valueType: ValueType.negative,
	}, //
	{
		ordinal: 16,
		nameTextkey: 'Budget_Value_Value16',
		name: 'Övriga avskrivningar',
		valueType: ValueType.negative,
	}, //
	{
		ordinal: 17,
		nameTextkey: 'Budget_Value_Value17',
		name: 'S:a avskrivningar',
		valueType: ValueType.negative,
	}, // S:a avskrivningar (-value15) + (-value16)
	{
		ordinal: 18,
		nameTextkey: 'Budget_Value_Value18',
		name: 'Resultat efter avskrivningar',
		valueType: ValueType.both,
	}, // Resultat före avskrivningar value14 + (-value17)
	{
		ordinal: 19,
		nameTextkey: 'Budget_Value_Value19',
		name: 'Finansiella intäkter',
		valueType: ValueType.positive,
	}, //
	{
		ordinal: 20,
		nameTextkey: 'Budget_Value_Value20',
		name: 'Finansiella kostnader',
		valueType: ValueType.negative,
	}, //
	{
		ordinal: 21,
		nameTextkey: 'Budget_Value_Value21',
		name: 'Resultat efter finansiella poster',
		valueType: ValueType.both,
	}, // Resultat finansiella poster (value18) + value19 + (-value20)
	{
		ordinal: 22,
		nameTextkey: 'Budget_Value_Value22',
		name: 'Extraordinära intäkter och kostnader',
		valueType: ValueType.both,
	}, //
	{
		ordinal: 23,
		nameTextkey: 'Budget_Value_Value23',
		name: 'Bokslutsdispositioner',
		valueType: ValueType.both,
	}, //
	{
		ordinal: 24,
		nameTextkey: 'Budget_Value_Value24',
		name: 'Skatt (ägaruttag prognosår EF)',
		valueType: ValueType.negative,
	}, //
	{
		ordinal: 25,
		nameTextkey: 'Budget_Value_Value25',
		name: 'Årets resultat',
		valueType: ValueType.both,
	}, // Åretsresultat (value21) sum (value22+value23+value24)
];
