
import React, { ReactElement } from 'react';
import { Case } from '../../../../models/SelmaModels';
import { AppComponent } from '../../../AppComponent';
import { StepHeader } from '../../StepHeader';
import { StepContext } from '../../CaseSteps';
import { THtml } from '../../../widget/display/THtml';
import { FormUnit, FormUnitSize } from '../../../layout/FormUnit/FormUnit';
import { Heading } from '../../../widget/display/Heading/Heading';
import { RequestedAttachments } from '../StepBudget/RequestedAttachments';
import { ProvidedAttachments } from '../StepBudget/ProvidedAttachments';
import { Button } from '../../../widget/input/Button/Button';
import { ButtonGroup, ButtonGroupLayout } from '../../../widget/input/ButtonGroup/ButtonGroup';
import { StepFooter } from '../../StepFooter';
import Table from '../../../widget/display/Table/Table';
import { Icon, IconType } from '../../../widget/display/Icon/Icon';
import KycService from '../../../../services/KycService';
import { ApplicantService } from '../../../../services/ApplicantService';
import { TextService } from '../../../../services/TextService';
import { ConfigurationService } from '../../../../services/ConfigurationService';
import { GuardService } from '../../../../services/GuardService';

interface Props {
	case: Case;
	context: StepContext;
}

export class StepDone extends AppComponent<Props> {
	
	kycService: KycService;
	textService: TextService;
	configurationService: ConfigurationService;
	applicantService: ApplicantService;
	guardService: GuardService;
	

	constructor(props: Props) {
		super(props);
		this.kycService = this.application.services.kycService;
		this.textService = this.application.services.textService;
		this.configurationService = this.application.services.configurationService;
		this.applicantService = this.application.services.applicantService;
		this.guardService = this.application.services.guardService;
	}
	
	componentDidMount() {
		const attachmentService = this.application.services.attachmentService;
		const c = this.props.case;
		this.observeUpdate(c);
		
		attachmentService.ensureCorrectAttachmentRequests(c);
	}

	goHome = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
		this.props.context.goHome();
	}

	goPrev = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
		this.props.context.goPrev();
	}

	openTab = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
		window.open(this.configurationService.getConfiguration().akkaUrl ?? '');
	}

	showKycWarning = (c:Case):boolean => {
		var check = c.kycApplicantsStatus.filter(x => !this.kycService.validateStatus(x.questionnaireStatus))
		return check.length > 0;
	}

	getKycStatus = (status:string): ReactElement<any, any> => {
		return this.kycService.validateStatus(status) 
		? <><Icon iconType={IconType.Complete}/> { this.textService.textOrEmpty("Done_Kyc_Complete") }</> 
		: <><Icon iconType={IconType.Warning}/> { this.textService.textOrEmpty("Done_Kyc_Not_Complete") }</>
	}

	conditionalKycBtn = (c:Case): ReactElement<any, any> => {
		const currentUser = this.applicantService.getMyApplicant(c);
		const kycStatusForUser = c.kycApplicantsStatus?.filter(x => this.guardService.compareCustomerId(currentUser.customerId, x.customerId))[0]?.questionnaireStatus ?? '';
		return !this.kycService.validateStatus(kycStatusForUser) 
		? <ButtonGroup layout={ButtonGroupLayout.Nowrap}>
		<Button
				id="openKyc"
				className="Button big orange external-link-icon"
				labelTextkey="Done_Kyc_Btn_Text"
				onClick={this.openTab}
			/>
		</ButtonGroup>
		: <></>
	}

	mapCaseCustomersToKycResponse(c:Case) {
		if(c.applicants) {
			return c.applicants?.map(applicant => {
				return {
					name: `${applicant.firstName} ${applicant.lastName}`,
					status: c.kycApplicantsStatus?.filter(x => this.guardService.compareCustomerId(x.customerId, applicant.customerId))[0]?.questionnaireStatus ?? 'Not Completed'
				}
			})
		}
		return [];
	}

	render() {
		const c = this.props.case;
		const attachmentService = this.application.services.attachmentService;
		let attachmentRequests = c.attachmentRequests;
		let attachments = c.attachments||[];
		const filterOutBudget = attachmentService.getAttachmentsByType(c, 'COMPANYBUDGET').length>0;
		if( filterOutBudget ) {
			attachmentRequests = attachmentService.filterOutAttachmentRequestsByRequestType(attachmentRequests, 'COMPANYBUDGET');
			attachments = attachmentService.filterOutAttachmentsByRequestType(attachments, 'COMPANYBUDGET')||[];
		}
		let requiredAttachmentRequests = attachmentRequests?.filter(x => !!x.required)
		let nonRequiredAttachmentRequests = attachmentRequests?.filter(x => !x.required)

		return (
			<div className="StepDone Step">
				<StepHeader  context={this.props.context}
					titleTextkey="Done_Title" descriptionTextkey="Done_Description" />
				<FormUnit size={FormUnitSize.Large}>
					<Heading level={2} labelTextkey="Done_Complement_Title"/>
					<p>
						<THtml k="Done_Complement_Description"/>
					</p>
					<p>
						<THtml k="Done_Complement_Documents"/>
					</p>

					<br/>

					<Heading level={2} labelTextkey="Done_Mandatory_Additions_Heading"/>

					<p>
						<THtml k="Done_Mandatory_Additions_Text"/>
					</p>

					<br/>

						<>
							<h3>{ this.showKycWarning(c) && <Icon iconType={IconType.Warning}/> } <THtml k="Done_Kyc_Heading"/></h3>
							<p>
								<THtml k="Done_Kyc_Description"/>
							</p>
							<Table tableRows={this.mapCaseCustomersToKycResponse(c).map(x => [x.name, this.getKycStatus(x.status)] )}/>
							<br/>
							{ 
								this.conditionalKycBtn(c) 
							} 
							<br/>
						</>

					{requiredAttachmentRequests && requiredAttachmentRequests.length>0 && 
						<RequestedAttachments case={c} attachmentRequests={requiredAttachmentRequests} attachments={attachments}/>	
					}
					{nonRequiredAttachmentRequests && nonRequiredAttachmentRequests.length>0 && 
						<>
							<br/>
							<Heading level={2} labelTextkey="Done_Additional_Additions_Heading"/>
							<p>
								<THtml k="Done_Additional_Additions_Text"/>
							</p>
							<RequestedAttachments case={c} attachmentRequests={nonRequiredAttachmentRequests} attachments={attachments}/>
						</>		
					}
					<br/>
				</FormUnit>

				<FormUnit size={FormUnitSize.Large}>
					<p>
						<THtml k="Done_Last_Description"/>
					</p>
					<ButtonGroup layout={ButtonGroupLayout.Stretch}>
						<Button
							id="goPrev"
							className="Button outline big green"
							labelTextkey="Summary_Title"
							onClick={this.goPrev}
						/>
						<Button
							id="goHome"
							className="Button big green"
							labelTextkey="Done_Home_Link"
							onClick={this.goHome}
						/>
					</ButtonGroup>
				</FormUnit>

				{/* <StepFooter
					context={this.props.context}
					canGoHome={true}
					canGoToNext={false}
					onGoHome={this.props.context.goHome}
					onGoToNext={this.props.context.goNext}
				/> */}
			</div>
		);
	}

}
