import { BaseService } from './BaseService';
import { Case, EUSupport, EUSupportEnum } from '../models/SelmaModels';
import { SMELPOService } from './SMELPOService';
import { StateService } from './StateService';
import { ValidationResult } from '../utils/ValidationResult';
import { GuardService } from './GuardService';
import { IdService } from './IdService';
import { uiEUSupports } from '../models/UiModels';
import { TextService } from './TextService';
import { getUIName } from '../models/uimodels/UINamed';

/*
Handles all logic for EuSupport.
*/
export class EuSupportService extends BaseService {


	private stateService: StateService;
	private smelpoService: SMELPOService;
	private guardService: GuardService;

	constructor(stateService: StateService, smelpoService: SMELPOService, 
		guardService: GuardService, private idService:IdService, private textService: TextService) {
		super();
		this.stateService = stateService;
		this.smelpoService = smelpoService;
		this.guardService = guardService;
	}

	update(euSupport: EUSupport|Case): void {
		this.stateService.update(euSupport);
	}

	getThisYearsEuSupports(c: Case): EUSupport[] {
		if( !c.euSupports )
			return [];

		const thisYear = new Date().getFullYear().toString();
		return c.euSupports.filter(x=> {return x.supportYear && x.supportYear===thisYear})
	}

	ensureMinimumEuSupports(c: Case): void {
		if( !c.euSupports )
			c.euSupports = [];

		const euSupports = this.getThisYearsEuSupports(c);
		if( euSupports.length<1 ) {
			let euSupport = this.newEuSupport();
			c.euSupports.push(euSupport);
			this.update(c);
		}
	}

	hasEuSupport(c: Case): boolean {
		return this.guardService.hasLengthGreaterThanZero(c.euSupports);
	}

	removeAllEuSupports(c: Case): void {
		if( !c.euSupports || c.euSupports.length===0 )
			return;
		c.euSupports = undefined;
		this.update(c);
	}

	newEuSupport() : EUSupport {
		const thisYear = new Date().getFullYear().toString();
		return {
			id: this.idService.newGuid(),
			supportYear: thisYear
		}
	}

	validateEuSupport(c: Case, x: EUSupport): ValidationResult {
		let vr = new ValidationResult();
		vr.add({validator:this.hasType, object:x, ok: this.hasType(x)});
		vr.add({validator:this.hasAmount, object:x, ok: this.hasAmount(x)});
		vr.add({validator:this.hasValidYear, object:x, ok: this.hasValidYear(x)});
		return vr;
	}

	hasType(x: EUSupport): boolean {
		return this.guardService.hasValue(x.euType);
	}

	hasAmount(x: EUSupport): boolean {
		return this.guardService.isGreaterThanZero(x.supportAmount);
	}

	hasValidYear(x: EUSupport): boolean {
		const validYear = new Date().getFullYear().toString();
		return x.supportYear===validYear;
	}

	getEuTypeDisplayName(euType: EUSupportEnum | undefined): string {
		var r = uiEUSupports.find(x => x.type===euType);
		if( r)
			return getUIName(r, this.textService);
		else 
			return "";
	}
}
