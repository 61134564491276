/* tslint:disable */
/* eslint-disable */
/**
 * SelmaSME API
 * This definition is the API between customer SME-Gui and the persistence layer for the application NOT FOR V1 When customer logg-in to Selma-EN, we will create a processId with applicant CustomerId (from BankID). When a customer added attachment to the application, we don\'t have a CaseId i LP, should we add a LP-Service to create/reserve a caseId to be able to attach a document to the caseId in DM.
 *
 * The version of the OpenAPI document: 0.7.4
 * Contact: developer@landshypotek.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Array of BudgetType, Budget records, minusposter registreras som minusposter value1; Resultaträkning; Skog value2; Resultaträkning; Växtodling value3; Resultaträkning; EU-stöd value4; Resultaträkning; Övrig djurproduktion value5; Resultaträkning; Förändring av lager produktion value6; Resultaträkning; Mjölk value7; Resultaträkning; Övriga intäkter value8; Omsättning totalt (Totala intäkter) summa value1-7 value9; Inköp (Råvaror och förnödenheter) value10; Arrendekostnader value11; Personalkostnader value12; Övriga rörelsekostnader value13; S:a kostnader (summa value9-12) value14; Resultat före avskrivningar (value8 + (-value13)) value15; Avskrivning inventarier (exkl. byggnadsinventerier) value16; Övriga avskrivningar value17; S:a avskrivningar (-value15) + (-value16) value18; Resultat före avskrivningar value14 + (-value17) value19; Finansiella intäkter value20; Finansiellakonstnader value21; Resultat finansiella poster (value18) + value19 + (-value20) value22; Extraordinärar intäkter och kostnader value23; Bokslutsdispositioner value24; Skatt (ägaruttag prognosår EF) value25; Åretsresultat (value21) sum (value22+value23+value24)
 * @export
 * @interface ValueType
 */
export interface ValueType {
    /**
     * Intäkter skog
     * @type {number}
     * @memberof ValueType
     */
    value1?: number;
    /**
     * Intäkter växtodling
     * @type {number}
     * @memberof ValueType
     */
    value2?: number;
    /**
     * Intäkter EU-Stöd
     * @type {number}
     * @memberof ValueType
     */
    value3?: number;
    /**
     * Intäkter Övrig djurproduktion
     * @type {number}
     * @memberof ValueType
     */
    value4?: number;
    /**
     * Intäkter Förändring av lager produktion
     * @type {number}
     * @memberof ValueType
     */
    value5?: number;
    /**
     * Intäkter Mjölk
     * @type {number}
     * @memberof ValueType
     */
    value6?: number;
    /**
     * Övriga intäkter
     * @type {number}
     * @memberof ValueType
     */
    value7?: number;
    /**
     * Bruttoomsättning totalt (totala intäkter)
     * @type {number}
     * @memberof ValueType
     */
    value8?: number;
    /**
     * Kostnader, inköp (Råvaror och förnödenheter)
     * @type {number}
     * @memberof ValueType
     */
    value9?: number;
    /**
     * Kostnader, arrendekostnader
     * @type {number}
     * @memberof ValueType
     */
    value10?: number;
    /**
     * Kostnader, personalkostnader
     * @type {number}
     * @memberof ValueType
     */
    value11?: number;
    /**
     * Kostnader, övriga rörelsekostnader
     * @type {number}
     * @memberof ValueType
     */
    value12?: number;
    /**
     * Kostnader, summa kostnader
     * @type {number}
     * @memberof ValueType
     */
    value13?: number;
    /**
     * Resultat för avskrivningar
     * @type {number}
     * @memberof ValueType
     */
    value14?: number;
    /**
     * Avskrivningar inventarier (exkl byggn.inventarier)
     * @type {number}
     * @memberof ValueType
     */
    value15?: number;
    /**
     * Avskrivningar, övriga avskrivningar och nedskrivningar
     * @type {number}
     * @memberof ValueType
     */
    value16?: number;
    /**
     * Summa Avskrivningar
     * @type {number}
     * @memberof ValueType
     */
    value17?: number;
    /**
     * Resultat efter avskrivningar
     * @type {number}
     * @memberof ValueType
     */
    value18?: number;
    /**
     * Finansiella intäkter
     * @type {number}
     * @memberof ValueType
     */
    value19?: number;
    /**
     * Finansiella kostnader
     * @type {number}
     * @memberof ValueType
     */
    value20?: number;
    /**
     * Resultat efter finansiella poster
     * @type {number}
     * @memberof ValueType
     */
    value21?: number;
    /**
     * Extraordinärar intäkter och kostnader
     * @type {number}
     * @memberof ValueType
     */
    value22?: number;
    /**
     * Bokslutdispositioner
     * @type {number}
     * @memberof ValueType
     */
    value23?: number;
    /**
     * Skatt (Ägaruttag prognosår EF)
     * @type {number}
     * @memberof ValueType
     */
    value24?: number;
    /**
     * Årets resultat
     * @type {number}
     * @memberof ValueType
     */
    value25?: number;
}

export function ValueTypeFromJSON(json: any): ValueType {
    return ValueTypeFromJSONTyped(json, false);
}

export function ValueTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ValueType {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'value1': !exists(json, 'value1') ? undefined : json['value1'],
        'value2': !exists(json, 'value2') ? undefined : json['value2'],
        'value3': !exists(json, 'value3') ? undefined : json['value3'],
        'value4': !exists(json, 'value4') ? undefined : json['value4'],
        'value5': !exists(json, 'value5') ? undefined : json['value5'],
        'value6': !exists(json, 'value6') ? undefined : json['value6'],
        'value7': !exists(json, 'value7') ? undefined : json['value7'],
        'value8': !exists(json, 'value8') ? undefined : json['value8'],
        'value9': !exists(json, 'value9') ? undefined : json['value9'],
        'value10': !exists(json, 'value10') ? undefined : json['value10'],
        'value11': !exists(json, 'value11') ? undefined : json['value11'],
        'value12': !exists(json, 'value12') ? undefined : json['value12'],
        'value13': !exists(json, 'value13') ? undefined : json['value13'],
        'value14': !exists(json, 'value14') ? undefined : json['value14'],
        'value15': !exists(json, 'value15') ? undefined : json['value15'],
        'value16': !exists(json, 'value16') ? undefined : json['value16'],
        'value17': !exists(json, 'value17') ? undefined : json['value17'],
        'value18': !exists(json, 'value18') ? undefined : json['value18'],
        'value19': !exists(json, 'value19') ? undefined : json['value19'],
        'value20': !exists(json, 'value20') ? undefined : json['value20'],
        'value21': !exists(json, 'value21') ? undefined : json['value21'],
        'value22': !exists(json, 'value22') ? undefined : json['value22'],
        'value23': !exists(json, 'value23') ? undefined : json['value23'],
        'value24': !exists(json, 'value24') ? undefined : json['value24'],
        'value25': !exists(json, 'value25') ? undefined : json['value25'],
    };
}

export function ValueTypeToJSON(value?: ValueType | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'value1': value.value1,
        'value2': value.value2,
        'value3': value.value3,
        'value4': value.value4,
        'value5': value.value5,
        'value6': value.value6,
        'value7': value.value7,
        'value8': value.value8,
        'value9': value.value9,
        'value10': value.value10,
        'value11': value.value11,
        'value12': value.value12,
        'value13': value.value13,
        'value14': value.value14,
        'value15': value.value15,
        'value16': value.value16,
        'value17': value.value17,
        'value18': value.value18,
        'value19': value.value19,
        'value20': value.value20,
        'value21': value.value21,
        'value22': value.value22,
        'value23': value.value23,
        'value24': value.value24,
        'value25': value.value25,
    };
}


