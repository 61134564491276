/* tslint:disable */
/* eslint-disable */
/**
 * SelmaSME API
 * This definition is the API between customer SME-Gui and the persistence layer for the application NOT FOR V1 When customer logg-in to Selma-EN, we will create a processId with applicant CustomerId (from BankID). When a customer added attachment to the application, we don\'t have a CaseId i LP, should we add a LP-Service to create/reserve a caseId to be able to attach a document to the caseId in DM.
 *
 * The version of the OpenAPI document: 0.7.4
 * Contact: developer@landshypotek.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Ålder på skogen för säkerheten, behöver ses över, finns inte i GUI idag.
 * @export
 * @enum {string}
 */
export enum CollateralAge {
    _020R = '0-20 år',
    _2140R = '21-40 år',
    _4160R = '41-60 år',
    _6180R = '61-80 år',
    _81100R = '81-100 år'
}

export function CollateralAgeFromJSON(json: any): CollateralAge {
    return CollateralAgeFromJSONTyped(json, false);
}

export function CollateralAgeFromJSONTyped(json: any, ignoreDiscriminator: boolean): CollateralAge {
    return json as CollateralAge;
}

export function CollateralAgeToJSON(value?: CollateralAge | null): any {
    return value as any;
}

