import { UIKeyed } from "./UIKeyed";
import { UINamed } from "./UINamed";
import { TypeOfHouse } from "../../libs/SMELPOClient";

export interface UIEstateType extends UIKeyed, UINamed {
	id:number;
	type: TypeOfHouse;
}

export const uiEstateTypes:UIEstateType[] = [
	{ id: 1, key: 'HOUSE', name: "Villa", nameTextkey: "EstateType_HOUSE", type:TypeOfHouse.Villa }, 
	{ id: 2, key: 'VACATIONHOUSE', name: "Fritidshus", nameTextkey: "EstateType_VACATIONHOUSE", type:TypeOfHouse.Fritidshus },
	{ id: 3, key: 'TENANCY', name: "Hyresrätt", nameTextkey: "EstateType_TENANCY", type:TypeOfHouse.Hyresrtt },
	{ id: 4, key: 'APARTMENT', name: "Bostadsrätt", nameTextkey: "EstateType_APARTMENT", type:TypeOfHouse.Bostadsrtt },
	{ id: 6, key: 'FARM', name: "Lantbruksfastighet", nameTextkey: "EstateType_FARM", type:TypeOfHouse.Landbruksfastighet },
	{ id: 5, key: 'OTHER', name: "Övrigt boende", nameTextkey: "EstateType_OTHER", type:TypeOfHouse.VrigtBoende }
];