import React from 'react';
import { AppComponent } from '../../../AppComponent';
import './ScrollOverflowIndicator.scss';

interface Props {}

export class ScrollOverflowIndicator extends AppComponent<Props> {
	constructor(props: Props) {
		super(props);
	}

	render() {

	const titleText = this.application.services.textService.textOrEmpty(
		'TableScrollsHorizontally'
	);

		return (
			<div
				className="ScrollOverflowIndicator"
				title={titleText}
			/>
		);
	}
}
