import React from 'react';
import { Applicant } from "../../../../models/selmamodels/Applicant";
import { AppComponent } from '../../../AppComponent';
import { FormUnit, FormUnitSize } from '../../../layout/FormUnit/FormUnit';
import { Label } from '../../../widget/display/Label/Label';
import { Select } from '../../../widget/input/Select/Select';
import { uiHighRiskCategories } from '../../../../models/UiModels';
import { Validation } from '../../../widget/display/Validation/Validation';
import { THtml } from '../../../widget/display/THtml';
import { T } from '../../../widget/display/T';
import { getUIName } from '../../../../models/uimodels/UINamed';

interface Props {
	applicant: Applicant;
	id: string;
}

export class ApplicantRiskBusiness extends AppComponent<Props> {
	highRiskBusinessesList: any[];

	constructor(props: Props) {
		super(props);
		this.observeUpdate(this.props.applicant);
		const textService = this.application.services.textService;
		this.highRiskBusinessesList = uiHighRiskCategories
			.filter(x => {
				if (this.props.applicant.kyc?.highRiskCategoryCode === x.id.toString()) {
					return true;
				}
				return x.id >= 101;
			})
			.sort((x, y) => {
				return x.name.toLowerCase().localeCompare(y.name.toLowerCase());
			})
			.map((x) => {
				return {
					value: x.id.toString(),
					label: getUIName(x, textService),
				};
			});

		const empty = {
			value: uiHighRiskCategories[0].id.toString(),
			label: getUIName(uiHighRiskCategories[0], textService)
		};
		
		this.highRiskBusinessesList.unshift(empty);
	}

	static defaultProps = {
		id: 'ApplicantRiskBusiness',
	};

	highRiskBusinessChanged = (value: string) => {
		const applicantService = this.application.services.applicantService;
		applicantService.selectHighRiskCategory(this.props.applicant, value);
	};

	render() {
		const applicantService = this.application.services.applicantService;
		const applicant = this.props.applicant;
		let highRiskCategoryCode;
		if (
			applicant.kyc &&
			applicant.kyc.highRiskCategoryCode
		) {
			highRiskCategoryCode = applicant.kyc.highRiskCategoryCode;
		} else {
			highRiskCategoryCode = '';
		}

		return (
			<>
				<Label
					for="highRiskBusiness" required={true}
					labelTextkey="Company_HighRiskBusiness"
				/>
				<p>
					<THtml k="Company_HighRiskBusiness_Description" />
				</p>
				<div><T k="Company_HighRiskBusiness_Question" /></div>
				<FormUnit size={FormUnitSize.Large}>
					<Validation forObject={applicant} errors={[{ validator: applicantService.hasSelectedHighRiskCategory, textkey: "Error_SelectValue" }]}>
						<Select
							id="highRiskBusiness"
							value={highRiskCategoryCode}
							options={this.highRiskBusinessesList}
							handleChange={this.highRiskBusinessChanged}
							placeholderTextkey="Company_SelectBusiness"
							noSelectableOptionsTextkey="Select_NoOptions"
							isSearchable={true}
						/>
					</Validation>
				</FormUnit>
			</>
		);
	}
}
