/* tslint:disable */
/* eslint-disable */
/**
 * SelmaSME API
 * This definition is the API between customer SME-Gui and the persistence layer for the application NOT FOR V1 When customer logg-in to Selma-EN, we will create a processId with applicant CustomerId (from BankID). When a customer added attachment to the application, we don\'t have a CaseId i LP, should we add a LP-Service to create/reserve a caseId to be able to attach a document to the caseId in DM.
 *
 * The version of the OpenAPI document: 0.7.4
 * Contact: developer@landshypotek.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CaseIdStatus,
    CaseIdStatusFromJSON,
    CaseIdStatusFromJSONTyped,
    CaseIdStatusToJSON,
    CustomerIdType,
    CustomerIdTypeFromJSON,
    CustomerIdTypeFromJSONTyped,
    CustomerIdTypeToJSON,
} from './';

/**
 * Process definition
 * @export
 * @interface ProcessType
 */
export interface ProcessType {
    /**
     * application model unique identification
     * @type {string}
     * @memberof ProcessType
     */
    processId: string;
    /**
     * Array of customer in a application (processid)
     * @type {Array<CustomerIdType>}
     * @memberof ProcessType
     */
    customers?: Array<CustomerIdType>;
    /**
     * reserved caseId in Loan Process (ignoreras i V1)
     * @type {string}
     * @memberof ProcessType
     */
    caseId?: string;
    /**
     * 
     * @type {CaseIdStatus}
     * @memberof ProcessType
     */
    caseIdStatus?: CaseIdStatus;
    /**
     * model, processId created date
     * @type {string}
     * @memberof ProcessType
     */
    processCreatedDate?: string;
    /**
     * updated timestamp when customer login to the site
     * @type {string}
     * @memberof ProcessType
     */
    lastAccessed?: string;
    /**
     * Unique identity for each application to be use for conversation LH-->customer short number serie starting at 1000 and incrementing
     * @type {number}
     * @memberof ProcessType
     */
    referenceId?: number;
    /**
     * Version of the current process
     * @type {string}
     * @memberof ProcessType
     */
    processVersion?: string;
}

export function ProcessTypeFromJSON(json: any): ProcessType {
    return ProcessTypeFromJSONTyped(json, false);
}

export function ProcessTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProcessType {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'processId': json['processId'],
        'customers': !exists(json, 'Customers') ? undefined : ((json['Customers'] as Array<any>).map(CustomerIdTypeFromJSON)),
        'caseId': !exists(json, 'caseId') ? undefined : json['caseId'],
        'caseIdStatus': !exists(json, 'caseIdStatus') ? undefined : CaseIdStatusFromJSON(json['caseIdStatus']),
        'processCreatedDate': !exists(json, 'processCreatedDate') ? undefined : json['processCreatedDate'],
        'lastAccessed': !exists(json, 'lastAccessed') ? undefined : json['lastAccessed'],
        'referenceId': !exists(json, 'referenceId') ? undefined : json['referenceId'],
        'processVersion': !exists(json, 'processVersion') ? undefined : json['processVersion'],
    };
}

export function ProcessTypeToJSON(value?: ProcessType | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'processId': value.processId,
        'Customers': value.customers === undefined ? undefined : ((value.customers as Array<any>).map(CustomerIdTypeToJSON)),
        'caseId': value.caseId,
        'caseIdStatus': CaseIdStatusToJSON(value.caseIdStatus),
        'processCreatedDate': value.processCreatedDate,
        'lastAccessed': value.lastAccessed,
        'referenceId': value.referenceId,
        'processVersion': value.processVersion,
    };
}


