import React from 'react';
import { Case, Company } from '../../../../models/SelmaModels';
import { Applicant } from '../../../../models/selmamodels/Applicant';
import { ApplicantItem } from './ApplicantItem';
import { AppComponent } from '../../../AppComponent';
import { StepHeader } from '../../StepHeader';
import { StepContext } from '../../CaseSteps';
import { StepFooter } from '../../StepFooter';
import { AddApplicant } from './AddApplicant';
import { ApplicantService } from '../../../../services/ApplicantService';
import {
	FormUnit,
	FormUnitSize,
	FormUnitPadding,
} from '../../../layout/FormUnit/FormUnit';
import { CompanyService } from '../../../../services/CompanyService';
import './StepApplicant.scss';
import { StepForm } from '../../../widget/display/StepForm/StepForm';
import { TabPanels } from '../../../widget/display/TabPanels/TabPanels';

interface Props {
	case: Case;
	context: StepContext;
}

interface State {
	immidiateAllValidatorsVisibleBefore?:Date;
}

export class StepApplicant extends AppComponent<Props, State> {
	applicantService: ApplicantService;
	//myApplicant: Applicant | undefined;
	companyService: CompanyService;

	constructor(props: Props) {
		super(props);

		const c = this.props.case;
		this.observeUpdate(c);

		if (c.applicants) {
			c.applicants.forEach((x) => {
				this.observeUpdate(x);
			});
		}

		this.applicantService = this.application.services.applicantService;
		this.companyService = this.application.services.companyService;

		this.state = { immidiateAllValidatorsVisibleBefore: undefined };
	}

	onAddApplicant = (
		applicant: Applicant,
		company: Company | undefined,
		persOrgNumber: string
	): void => {
		const c = this.props.case;
		const applicantService = this.applicantService;
		const companyService = this.companyService;
		if (company) {
			if (!company.orgNumber) return;
			if (companyService.isPhysicum(company)) {
				applicantService.addApplicant(c, applicant);
				this.observeUpdate(applicant);
				companyService.addCompany(c, company);
				companyService.addPrincipalFromApplicant(company, applicant);
			} else if (companyService.isJuridicum(company)) {
				if (applicant.customerId != company.orgNumber) {
					companyService.addPrincipalFromApplicant(company, applicant);
				}

				applicant.customerId = company.orgNumber;
				applicant.firstName = company.name;
				applicant.lastName = undefined;

				applicantService.addApplicant(c, applicant);
				this.observeUpdate(applicant);
				companyService.addCompany(c, company);
			}
		} else {
			this.applicantService.addApplicant(c, applicant);
			this.observeUpdate(applicant);
		}
		this.props.context.updateSteps(); // Adjusts number of steps.
	};

	onRemoveApplicant = (applicant: Applicant) : void => {
		this.applicantService.removeApplicant(this.props.case, applicant);
		this.props.context.updateSteps(); // Adjusts number of steps.
	};

	triggerImmidiateValidation():void {
		this.setState({ immidiateAllValidatorsVisibleBefore : new Date() })
	}

	render() {
		const applicants = this.props.case.applicants;
		const applicantService = this.applicantService;
		const maxApplicants = 20;
		const applicantCount = applicants ? applicants.length : 0;
		const c = this.props.case;

		const validationResult = this.props.context.getValidation();
		const canGoToNext = validationResult.ok();
		const immidiateAllValidatorsVisibleBefore = this.state.immidiateAllValidatorsVisibleBefore;

		const tabs =
			applicants &&
			applicants.map((x, ix) => {
				return {
					id: x.id,
					label: applicantService.getFullName(c, x)
				};
			});

		return (
			<div className="StepApplicant Step">
				<StepHeader
					context={this.props.context}
					titleTextkey="Applicant_Title"
					descriptionTextkey="Applicant_Description"
				/>

				<StepForm context={this.props.context} immidiateAllValidatorsVisibleBefore={immidiateAllValidatorsVisibleBefore}>
					<FormUnit size={FormUnitSize.Large}>
						<a id="ApplicantTabsAnchor"></a>
						<TabPanels tabs={tabs}>
							{applicants &&
								applicants.map((x, ix) => {
									return (
										<div className="Panel" key={'applicant_' + ix}>
											<ApplicantItem
												applicant={x}
												case={this.props.case}
												removeApplicant={this.onRemoveApplicant}
											/>
										</div>
									);
								})}
						</TabPanels>
					</FormUnit>
					{applicantCount < maxApplicants && (
						<AddApplicant case={c} addApplicant={this.onAddApplicant} />
					)}
				</StepForm>

				<StepFooter
					context={this.props.context}
					canGoHome={true}
					canGoToNext={canGoToNext}
					onGoHome={this.props.context.goHome}
					onGoToNext={this.props.context.goNext}
					onTriggerImmidiateValidation={() => this.triggerImmidiateValidation() }
					/>
			</div>
		);
	}
}
