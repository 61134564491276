import React, { useState } from 'react';
import { T } from '../../display/T';
import { Application } from '../../../../models/AppModels';
import { Button } from '../Button/Button';
import { Modal, OverlayStyles } from '../../../layout/Modal/Modal';
import { ButtonGroup, ButtonGroupLayout } from '../ButtonGroup/ButtonGroup';

import './DeleteButton.scss';

interface Props {
	labelTextkey: string;
	label?: string;
	questionTextkey?: string;
	onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
	enabled?: boolean;
}

export const DeleteButton: React.FunctionComponent<Props> = (props) => {
	let label = Application.instance.services.textService.textOrEmpty(
		props.labelTextkey
	);

	if (props.label) {
		label += props.label;
	}

	const [modal, setOpen] = useState({ isOpen: false });

	const onClick = (
		event: React.MouseEvent<HTMLButtonElement, MouseEvent>
	): void => {
		setOpen((s) => {
			return { isOpen: !s.isOpen };
		});
	};

	const handleClickYes = (
		event: React.MouseEvent<HTMLButtonElement, MouseEvent>
	): void => {
		setOpen({ isOpen: false });
		if (props.onClick) props.onClick(event);
	};

	const handleClickNo = (
		event:
			| React.MouseEvent<HTMLButtonElement, MouseEvent>
			| React.MouseEvent<HTMLDivElement, MouseEvent>
	): void => {
		setOpen({ isOpen: false });
	};

	const questionTextkey = props.questionTextkey;

	const id =
		'deleteButton_' + Application.instance.services.idService.newIdString();

	return (
		<>
			<Button
				className="Button brown trash-icon"
				id={id}
				name={id}
				label={label}
				enabled={props.enabled}
				onClick={onClick}
				style="round"
			/>
			{modal.isOpen ? (
				<Modal
					overlayCloseOnClick={true}
					handleClose={handleClickNo}
					overlayStyle={OverlayStyles.Darken}>
					<div className="ModalContent">
						<h1>{label}</h1>
						<p>
							{questionTextkey ? (
								<T k={questionTextkey} />
							) : (
								<T k="Dialog_AreYouReallySure" />
							)}
						</p>
					</div>
					<div className="ModalFooter">
						<ButtonGroup layout={ButtonGroupLayout.Stretch}>
							<Button
								style="normal"
								labelTextkey="Yes"
								onClick={handleClickYes}
							/>
							<Button
								style="normal"
								labelTextkey="No"
								onClick={handleClickNo}
								autofocus={true}
							/>
						</ButtonGroup>
					</div>
				</Modal>
			) : null}
		</>
	);
};
