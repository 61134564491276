import React from 'react';
import { CompanyEconomy } from '../../../../models/SelmaModels';
import { Revenue } from "../../../../models/selmamodels/Company";
import { AppComponent } from '../../../AppComponent';
import { TextboxType } from '../../../widget/input/Textbox/Textbox';
import { Money } from '../../../widget/input/Money/Money';
import { NumberFormatValues } from 'react-number-format';
import { T } from '../../../widget/display/T';
import { Validation } from '../../../widget/display/Validation/Validation';

interface Props {
	companyEconomy: CompanyEconomy;
}

export class CompanyRevenue extends AppComponent<Props> {
	constructor(props: Props) {
		super(props);
		this.observeUpdate(this.props.companyEconomy);
	}

	componentDidMount() {
		const companyEconomyService = this.application.services.companyEconomyService;
		companyEconomyService.ensureRequiredRevenueYears(this.props.companyEconomy);
	}

	budgetYearChanged = (e: NumberFormatValues, revenue: Revenue) => {
		let value = e.floatValue;
		const companyEconomyService = this.application.services.companyEconomyService;
		companyEconomyService.setRevenueValue(this.props.companyEconomy, revenue, value);
	}

	render() {
		const companyEconomy = this.props.companyEconomy;
		const companyEconomyService = this.application.services.companyEconomyService;
		return (
			<table className="Table">
			<thead>
				<tr>
					<th><T k="Company_Budget_Year"/></th>
					<th><T k="Company_Budget_Revenue"/></th>
				</tr>
			</thead>
			<tbody>
				{ companyEconomy.revenues &&
					companyEconomy.revenues.map(x=>
						<tr key={x.year}>
							<td>{x.year}</td>
							<td>
								<Validation
									forObject={x}
									errors={[
										{
											validator: companyEconomyService.revenueHasZeroOrHigherRevenue,
											textkey: 'Error_EnterAmount'
										}
									]}>
									<Money
										id={'revenueYear_'+x.year}
										min={0}
										value={x.revenue}
										handleChange={(e) => {this.budgetYearChanged(e, x);}}
										type={TextboxType.number}
									/>
								</Validation>
							</td>
						</tr>
					)}
			</tbody>
		</table>

		);
	}
}
