import React from 'react';
import ClassNames from 'classnames';
import { BaseComponent } from '../../../BaseComponent';
import { Button } from '../../input/Button/Button';
import './TabPanels.scss';

export interface Tab {
	id: string;
	label: string;
}

interface Props {
	className?: string;
	tabs?: Tab[];
}

export class TabPanels extends BaseComponent<Props> {
	static defaultProps = {
		className: 'TabPanels',
	};

	constructor(props: Props) {
		super(props);

		this.state = {
			selectedTab: 0,
		};
	}

	componentDidUpdate = (prevProps) => {
		if (prevProps.children && this.props.children) {

			const prevNumOfChildren = Object.keys(prevProps.children).length;
			const nextNumOfChildren = Object.keys(this.props.children).length;

			if (prevNumOfChildren !== nextNumOfChildren) {
				this.setState({
					selectedTab: nextNumOfChildren - 1,
				});
			}
		}
	}

	handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		this.setState({
			selectedTab: parseInt(event.currentTarget.value),
		});
	}

	render() {
		const classNames = ClassNames(this.props.className);

		const children = this.props.children;

		return (
			<div className={classNames}>
				{this.props.tabs && this.props.tabs.length > 1 ? (
					<div className="TabsWrapper">
					<div className="Tabs">
						{this.props.tabs &&
							this.props.tabs.map((x, ix) => {
								return (
									<Button
										className={ClassNames('Button', 'Tab', {
											Selected: ix === this.state.selectedTab,
										})}
										label={x.label}
										value={ix}
										id={x.id}
										key={'tab_' + ix}
										onClick={this.handleClick}
									/>
								);
							})}
					</div>
					</div>
				) : null}
				{children &&
					React.Children.map(children, (x, ix) =>
						ix === this.state.selectedTab ? x : null
					)}
			</div>
			
		);
	}
}
