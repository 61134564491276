import React from 'react';
import { Case } from '../../../../models/SelmaModels';
import { AppComponent } from '../../../AppComponent';
import { HouseholdEdit } from './HouseholdEdit';
import { THtml } from '../../../widget/display/THtml';

interface Props {
	case: Case;
}

export class Households extends AppComponent<Props> {

	constructor(props: Props) {
		super(props);
	}

	render() {
		const c = this.props.case;
		const hasHouseholds = c.households && c.households.length>0;
		return (
			<div className="Households">
			{ hasHouseholds && c.households && c.households.map((x,ix) => {
				return <HouseholdEdit key={ix} case={c} household={x}/> 
			})}
			</div>
		);
	}


}

