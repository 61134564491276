import React from 'react';
import { Applicant as SelmaApplicant, Case, Applicant } from '../../../../models/SelmaModels';
import { AppComponent } from '../../../AppComponent';
import { PersonApplicantItem } from './PersonApplicantItem';
import { PersonalCompanyApplicantItem } from './PersonalCompanyApplicantItem';
import { IncorporatedCompanyApplicantItem } from './IncorporatedCompanyApplicantItem';

interface Props {
	case: Case;
	applicant: SelmaApplicant;
	removeApplicant: (applicant: Applicant) => void;
}

export class ApplicantItem extends AppComponent<Props> {
	myApplicant: SelmaApplicant | undefined;

	constructor(props: Props) {
		super(props);
		this.observeUpdate(this.props.applicant);
	}

	render() { 
		const applicantService = this.application.services.applicantService;
		const companyService = this.application.services.companyService;
		const c = this.props.case;
		const applicant = this.props.applicant;

		// Company
		const applicantCompany = companyService.getApplicantCompany(c, applicant);
		const isPrivatePerson = applicantCompany ? false : true;
		const isJuridicumCompany = applicantService.isJuridicumCompany(c, applicant);
		const isPersonalCompany = applicantService.isPersonalCompany(c, applicant);

		return (
			<div className="ApplicantItem">
					{isPrivatePerson && (
						<PersonApplicantItem
							case={c}
							applicant={applicant}
							removeApplicant={this.props.removeApplicant}
						/>
					)}
					{isPersonalCompany && applicantCompany && (
						<PersonalCompanyApplicantItem
							case={c}
							applicant={applicant}
							company={applicantCompany}
							removeApplicant={this.props.removeApplicant}
						/>
					)}
					{isJuridicumCompany && applicantCompany && (
						<IncorporatedCompanyApplicantItem
							case={c}
							applicant={applicant}
							company={applicantCompany}
							removeApplicant={this.props.removeApplicant}
						/>
					)}
			</div>
		);
	}
}
