import React from 'react';
import { AppComponent } from '../../../AppComponent';
import { AlignItems, FormUnitRow, Justify } from '../../../layout/FormUnitRow/FormUnitRow';
import { Money } from '../../../widget/input/Money/Money';
import { Label } from '../../../widget/display/Label/Label';
import { DeleteButton } from '../../../widget/input/DeleteButton/DeleteButton';
import { Validation } from '../../../widget/display/Validation/Validation';
import { Aim, Loan } from '../../../../models/SelmaModels';
import { LoanService } from '../../../../services/LoanService';
import { SelectOption, Select } from '../../../widget/input/Select/Select';
import { NumberFormatValues } from 'react-number-format';
import { T } from '../../../widget/display/T';

interface Props {
	aim: Aim;
	loan: Loan;
	index: number;
	canEditAmountPart: boolean;
	canDelete: boolean;
	aimCategoryOptions: SelectOption[];
}

export class LoanNewAim extends AppComponent<Props> {
	loanService: LoanService;

	constructor(props: Props) {
		super(props);

		this.loanService = this.application.services.loanService;

	}

	removeAimClicked = (): void => {
		this.loanService.removeAim(this.props.loan, this.props.aim);
	}

	aimCategoryCodeChanged = (value: string): void => {
		this.props.aim.aimCategory = value;
		this.props.aim.aimDetail = '';
		this.loanService.updateAim(this.props.loan, this.props.aim);
	}

	aimDetailCodeChanged = (value: string): void => {
		this.props.aim.aimDetail = value;
		this.loanService.updateAim(this.props.loan, this.props.aim);
	}

	loanAmountPartChanged = (values: NumberFormatValues): void => {
		let value: number | undefined = values.floatValue;
		if (value === undefined || values.value === '') {
			value = undefined;
		}
		this.loanService.setAimLoanAmountPart(
			this.props.loan,
			this.props.aim,
			value
		);
	}

	render() {
		const loanService = this.loanService;
		const aim = this.props.aim;
		const index = this.props.index;
		const aimCategoryCode = this.props.aim.aimCategory || '';
		const hasAimCategoryCode = this.props.aim.aimCategory ? true : false;
		const aimDetailCode = this.props.aim.aimDetail || '';
		const maxAmount = this.loanService.getMaximumAimAmount();

		const aimCounter = (index + 1).toString();

		let aimCategoryOption;
		let detailOptions;
		if (hasAimCategoryCode) {
			const aimCategoryOptionResult = this.props.aimCategoryOptions.filter(
				(x) => x.value === aimCategoryCode
			);
			if (aimCategoryOptionResult.length === 1) {
				aimCategoryOption = aimCategoryOptionResult[0];
				detailOptions = aimCategoryOption.detailOptions.filter((x) => {
					// TODO: remove items that have aleady been selected.
					return true;
				});
			}
		}

		return (
			<div className="LoanNewAim">

				{this.props.canDelete && (
					<FormUnitRow layout={Justify.SpaceBetween} alignItems={AlignItems.Center}>
						<h3>
							<T k="Loan_PurposeCategory"></T> {aimCounter}
						</h3>
						<div>
							<DeleteButton
								labelTextkey="Loan_RemoveAim"
								onClick={this.removeAimClicked}
							/>
						</div>
					</FormUnitRow>
				)}

				<FormUnitRow>
					<div className="FormUnitRow_Section">
						<Label
							for={`aimCategoryCode_${index}`}
							labelTextkey="Loan_PurposeCategory"
						/>
						<Validation
							forObject={aim}
							errors={[
								{
									validator: loanService.aimHasCategory,
									textkey: 'Error_SelectValue',
								},
							]}>
							<Select
								id={`aimCategoryCode_${index}`}
								value={aimCategoryCode}
								options={this.props.aimCategoryOptions}
								handleChange={this.aimCategoryCodeChanged}
								placeholderTextkey="Loan_SelectAimCategory"
								noSelectableOptionsTextkey="Select_NoOptions"
								isSearchable={true}
							/>
						</Validation>
					</div>

					<div className="FormUnitRow_Section">
						<Label
							for={`aimDetailCode_${index}`}
							labelTextkey="Loan_Purpose"
						/>
						<Validation
							forObject={aim}
							errors={[
								{
									validator: loanService.aimHasDetail,
									textkey: 'Error_SelectValue',
								},
							]}>
							<Select
								id={`aimDetailCode_${index}`}
								value={aimDetailCode}
								options={detailOptions}
								handleChange={this.aimDetailCodeChanged}
								enabled={hasAimCategoryCode}
								placeholderTextkey="Loan_SelectAimDetail"
								noSelectableOptionsTextkey="Select_NoOptions"
								isSearchable={true}
							/>
						</Validation>
					</div>
					<div className="FormUnitRow_Section ">
						<Label
							for={`loanAmountPart_${index}`}
							labelTextkey="Loan_PartOfSum"
						/>
						<Validation
							forObject={aim}
							errors={[
								{
									validator: this.loanService.aimHasAmount,
									textkey: 'Error_EnterValue',
								},
								{
									validator: this.loanService.aimHasValidAmount,
									textkey: 'Loan_Aim_Amount_Invalid',
								}
							]}>
							<Money
								value={aim.loanAmountPart}
								id={`loanAmountPart_${index}`}
								min={0}
								max={maxAmount}
								enabled={this.props.canEditAmountPart}
								handleChange={this.loanAmountPartChanged}
							/>
						</Validation>
					</div>
				</FormUnitRow>
			</div>
		);
	}
}
