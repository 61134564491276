/* tslint:disable */
/* eslint-disable */
/**
 * SelmaSME API
 * This definition is the API between customer SME-Gui and the persistence layer for the application NOT FOR V1 When customer logg-in to Selma-EN, we will create a processId with applicant CustomerId (from BankID). When a customer added attachment to the application, we don\'t have a CaseId i LP, should we add a LP-Service to create/reserve a caseId to be able to attach a document to the caseId in DM.
 *
 * The version of the OpenAPI document: 0.7.4
 * Contact: developer@landshypotek.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Värdeföråd för säkerhet (Fastighet, EU-stöd, borgensman)
 * @export
 * @enum {string}
 */
export enum TypeOfCollateral {
    FASTIGHET = 'FASTIGHET',
    EUSTOD = 'EUSTOD',
    BORGENSMAN = 'BORGENSMAN'
}

export function TypeOfCollateralFromJSON(json: any): TypeOfCollateral {
    return TypeOfCollateralFromJSONTyped(json, false);
}

export function TypeOfCollateralFromJSONTyped(json: any, ignoreDiscriminator: boolean): TypeOfCollateral {
    return json as TypeOfCollateral;
}

export function TypeOfCollateralToJSON(value?: TypeOfCollateral | null): any {
    return value as any;
}

