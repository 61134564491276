/* tslint:disable */
/* eslint-disable */
/**
 * SelmaSME API
 * This definition is the API between customer SME-Gui and the persistence layer for the application NOT FOR V1 When customer logg-in to Selma-EN, we will create a processId with applicant CustomerId (from BankID). When a customer added attachment to the application, we don\'t have a CaseId i LP, should we add a LP-Service to create/reserve a caseId to be able to attach a document to the caseId in DM.
 *
 * The version of the OpenAPI document: 0.7.4
 * Contact: developer@landshypotek.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Omsättning sker på föråegående år och två år tillbaka
 * @export
 * @interface RevenueType
 */
export interface RevenueType {
    /**
     * year of revenue (bruttoomsättning), föregående år och 2 år tillbaka. Även om resultat inte fastställt så ber vi kunden registrera uppskattat. När vi väl kommer till LP så kommer handläggaren ändå verifiera bokslutet. Detta fält är read-only i användargränssnittet
     * @type {number}
     * @memberof RevenueType
     */
    revenueYear: number;
    /**
     * Företagets bruttoomsättning för året angivet i revenueYear (2 år)
     * @type {number}
     * @memberof RevenueType
     */
    revenue: number;
}

export function RevenueTypeFromJSON(json: any): RevenueType {
    return RevenueTypeFromJSONTyped(json, false);
}

export function RevenueTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): RevenueType {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'revenueYear': json['revenueYear'],
        'revenue': json['revenue'],
    };
}

export function RevenueTypeToJSON(value?: RevenueType | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'revenueYear': value.revenueYear,
        'revenue': value.revenue,
    };
}


