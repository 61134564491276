/* tslint:disable */
/* eslint-disable */
/**
 * SelmaSME API
 * This definition is the API between customer SME-Gui and the persistence layer for the application NOT FOR V1 When customer logg-in to Selma-EN, we will create a processId with applicant CustomerId (from BankID). When a customer added attachment to the application, we don\'t have a CaseId i LP, should we add a LP-Service to create/reserve a caseId to be able to attach a document to the caseId in DM.
 *
 * The version of the OpenAPI document: 0.7.4
 * Contact: developer@landshypotek.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Contact information definitions
 * @export
 * @interface ContactInformationType
 */
export interface ContactInformationType {
    /**
     * applicant enter email for notification
     * @type {string}
     * @memberof ContactInformationType
     */
    applicanteMail?: string;
    /**
     * applicant entered mobile number for notification
     * @type {string}
     * @memberof ContactInformationType
     */
    applicantMobileNumber?: string;
}

export function ContactInformationTypeFromJSON(json: any): ContactInformationType {
    return ContactInformationTypeFromJSONTyped(json, false);
}

export function ContactInformationTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContactInformationType {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'applicanteMail': !exists(json, 'applicanteMail') ? undefined : json['applicanteMail'],
        'applicantMobileNumber': !exists(json, 'applicantMobileNumber') ? undefined : json['applicantMobileNumber'],
    };
}

export function ContactInformationTypeToJSON(value?: ContactInformationType | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'applicanteMail': value.applicanteMail,
        'applicantMobileNumber': value.applicantMobileNumber,
    };
}


