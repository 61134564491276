import React from 'react';
import { Case } from '../../../../models/SelmaModels';
import { Company } from '../../../../models/selmamodels/Company';
import { Applicant } from '../../../../models/selmamodels/Applicant';
import { AppComponent } from '../../../AppComponent';
import { StepHeader } from '../../StepHeader';
import { Label } from '../../../widget/display/Label/Label';
import { StepContext } from '../../CaseSteps';
import { StepFooter } from '../../StepFooter';
import { FormUnit, FormUnitSize, FormLeftMargin, FormUnitPadding } from '../../../layout/FormUnit/FormUnit';
import { CompanySelect } from './CompanySelect';
import { CompanyHasRevenue } from './CompanyHasRevenue';
import { CompanyRevenueChunk } from './CompanyRevenueChunk';
import { THtml } from '../../../widget/display/THtml';
import { Loader } from '../../../widget/display/Loader/Loader';
import { StepForm } from '../../../widget/display/StepForm/StepForm';
import { Checkbox } from '../../../widget/input/Checkbox/Checkbox';
import { Button } from '../../../widget/input/Button/Button';


interface Props {
	case: Case;
	context: StepContext;
}

interface State {
	hasSearchedForCompanies: boolean;
	searchingForCompanies: boolean;
	searchForCompaniesFailed: boolean;
	availableCompanies: Company[];
	companyHasRevenue?: boolean;
	highRiskBusiness?: string;
	immidiateAllValidatorsVisibleBefore?:Date;
}

export class StepCompany extends AppComponent<Props, State> {
	caseService = this.application.services.caseService;
	companyService = this.application.services.companyService;
	companyEconomyService = this.application.services.companyEconomyService;
	applicantService = this.application.services.applicantService;
	guardService = this.application.services.guardService;
	myApplicant: Applicant;

	constructor(props: Props) {
		super(props);
		this.observeUpdate(this.props.case);

		this.myApplicant = this.applicantService.getMyApplicant(this.props.case);
		this.observeUpdate(this.myApplicant);

		if (this.props.case.companies)
			this.props.case.companies.forEach((x) => this.observeUpdate(x));

		this.state = {
			hasSearchedForCompanies: false,
			searchingForCompanies: false,
			searchForCompaniesFailed: false,
			availableCompanies: [],
			companyHasRevenue: undefined,
			highRiskBusiness: undefined,
			immidiateAllValidatorsVisibleBefore: undefined
		};
	}

	componentDidMount() {
		this.findCompanies();
	}

	findCompanies = () :void => {
		this.setState({ searchingForCompanies: true, searchForCompaniesFailed: false });

		this.application.services.applicantService
			.searchForApplicant(this.props.case, this.myApplicant.customerId)
			.then((result) => {
				if (result.companies) {
					this.setState({
						availableCompanies: result.companies,
					});
				}
				this.setState({ hasSearchedForCompanies: true, searchingForCompanies: false });
				return result;
			}, error => {
				this.setState({ hasSearchedForCompanies: false, searchingForCompanies: false, searchForCompaniesFailed: true });
			});
	}

	willBuyAgriForestryProperty = (applicant:Applicant) : void => {
		const c = this.props.case;
		const checked = applicant?true:false;
		if( !checked )
			return; // Can only be checked, not unchecked.
		const applicantService = this.application.services.applicantService;
		applicantService.setWillBuyAgriForestryProperty(c, this.myApplicant, checked); 
		this.props.context.updateSteps(); // Adjusts number of steps.
	}

	companySelected = (company: Company): void => {
		if (company) {
			const c = this.props.case;
			const companyService = this.application.services.companyService;
			
			const selectedCompany = companyService.getMySelectedCompany(c);
			if( selectedCompany ) {
				if( selectedCompany===company )
					return;
				
				this.companyService.removeCompany(c, selectedCompany);
			}

			company = companyService.addCompany(c, company);
			this.observeUpdate(company);
			companyService.addPrincipalFromApplicant(company, this.myApplicant);
			const companyEconomy = this.companyEconomyService.ensureCompanyEconomy(c,company);
			this.observeUpdate(companyEconomy);
			companyService.update(company);

			// Remove applicant from households since he/she now represtents a company.
			if( companyService.isJuridicum(company)) {
				const householdService = this.application.services.householdService;
				householdService.removeCustomerFromAllHouseholds(c, this.myApplicant.customerId);
			}

			// Clear willBuyAgriForestryProperty
			const applicantService = this.application.services.applicantService;
			applicantService.setWillBuyAgriForestryProperty(c, this.myApplicant, false);
			this.applicantService.update(this.myApplicant);
		}
		this.props.context.updateSteps(); // Adjusts number of steps.
	};

	hasRevenueChanged = (value:boolean|undefined) => {
		this.setState({
			companyHasRevenue: value,
		});
		this.caseService.update(this.props.case);

		this.props.context.updateSteps();
	};

	highRiskBusinessChanged = (value: string) => {
		this.setState({
			highRiskBusiness: value,
		});
	};

	triggerImmidiateValidation():void {
		this.setState({ immidiateAllValidatorsVisibleBefore: new Date() })
	}

	render() {
		// Case.
		const c = this.props.case;

		// Applicant.
		const myApplicant = this.myApplicant;

		// Block unnecessary render
		if (!myApplicant) return null;
		const fullName = this.applicantService.getFullName(c, myApplicant); 
		const willBuyAgriForestryProperty = myApplicant.willBuyAgriForestryProperty || false;

		// Find selected company.
		const selectedCompany = this.companyService.getMySelectedCompany(c);

		// Find data from selected company.
		const selectedCompanyEconomy = selectedCompany
			? this.companyEconomyService.getCompanyEconomyByCompanyId(
				c,
				selectedCompany.id
			)
			: undefined;

		let hasRevenue = selectedCompanyEconomy && this.companyEconomyService.hasRevenue(selectedCompanyEconomy) ? true:false;

		let companies = this.state.availableCompanies||[];
		companies.sort((a, b) => {
			return (a.orgNumber || '') > (b.orgNumber || '') ? 1 : -1;
		});

		let allowPromiseOfBuyinAgriForestryProperty = true;
		if( companies.length>0 ) 
			allowPromiseOfBuyinAgriForestryProperty = !this.companyService.anyPhysicumCompanies(companies);

		const searchingForCompanies = this.state.searchingForCompanies;
		const hasSearchedForCompanies = this.state.hasSearchedForCompanies;

		const validationResult = this.props.context.getValidation();

		let registeredOnText = this.application.services.textService.formattedTextOrEmpty("Company_SelectCompany_RegisterdOn", {name:fullName});
		const immidiateAllValidatorsVisibleBefore = this.state.immidiateAllValidatorsVisibleBefore;

		return (
			<div className="StepCompany Step">
				<StepHeader
					context={this.props.context}
					titleTextkey="Company_Title"
					descriptionTextkey="Company_Description"
				/>

				<StepForm 
					context={this.props.context} 
					immidiateAllValidatorsVisibleBefore={immidiateAllValidatorsVisibleBefore}>

					{searchingForCompanies && (
						<FormUnit size={FormUnitSize.Large}>
							<Loader loaderTextKey="Company_SearchingForCompanies" />
						</FormUnit>
					)}

					{!searchingForCompanies &&
						(!companies || companies.length === 0) && (
						<>
							{this.state.searchForCompaniesFailed &&
								<div>
									<p>
										Ett fel uppstod under sökningen.
									</p> 
									<p>
										<Button labelTextkey="Company_RetrySearch" onClick={this.findCompanies}></Button>
									</p>
								</div>
							}
						</>
					)}

					{!searchingForCompanies && hasSearchedForCompanies && (
						<>
						<FormUnit size={FormUnitSize.Large}>
							<Label labelTextkey="Company_SelectCompany" />
							<div className="Paragraph" dangerouslySetInnerHTML={{__html: registeredOnText}} />
							<CompanySelect
								companies={companies}
								applicant={this.myApplicant}
								selectedCompany={selectedCompany}
								selectedApplicant={willBuyAgriForestryProperty?myApplicant:undefined}
								companySelected={this.companySelected}
								applicantSelected={this.willBuyAgriForestryProperty}
							/>
						</FormUnit>
						</>
					)}

					{/*!searchingForCompanies && hasSearchedForCompanies && allowPromiseOfBuyinAgriForestryProperty &&
						<FormUnit size={FormUnitSize.Xlarge} padding={FormUnitPadding.Small} leftMargin={FormLeftMargin.Normal} >
							<Checkbox labelTextkey="Company_WillBuyAgriForestryProperty" 
								handleChange={this.willBuyAgriForestryProperty} checked={willBuyAgriForestryProperty} />
						</FormUnit>
					*/}

					{selectedCompany && selectedCompanyEconomy && (
						<FormUnit padding={FormUnitPadding.Large}>
							<CompanyHasRevenue
								case={c}
								company={selectedCompany}
								companyEconomy={selectedCompanyEconomy}
								hasRevenueChanged={this.hasRevenueChanged}
							/>
						</FormUnit>
					)}

					{selectedCompany &&
						selectedCompanyEconomy &&
						hasRevenue &&
						myApplicant && (
						<CompanyRevenueChunk
							applicant={myApplicant}
							company={selectedCompany}
							companyEconomy={selectedCompanyEconomy}
						/>
					)}

				</StepForm>

				<StepFooter
					context={this.props.context}
					canGoHome={true}
					canGoToNext={validationResult.ok()}
					onGoHome={this.props.context.goHome}
					onGoToNext={this.props.context.goNext}
					onTriggerImmidiateValidation={() => this.triggerImmidiateValidation() }
				/>
			</div>
		);
	}
}
