import { BaseService } from './BaseService';
import { Configuration, RequestContext, FetchParams } from '../libs/SMELPOClient';
import { ProcessesApi, UpdateProcessStatusCaseIdStatusEnum} from '../libs/SMELPOClient/apis';
import { ProcessType,  LinksAll, ProcessesType } from '../libs/SMELPOClient/models';
import { SessionService } from './SessionService';
import { AddProcessRequest } from '../libs/SMELPOClient/apis/ProcessesApi';
import { CaseIdStatus } from '../libs/SMELPOClient/models/CaseIdStatus';
import { AdminCasesSearch, AdminCasesSearchResult } from '../models/extendedlpo/Admin';
import { FullProcess } from '../models/extendedlpo/FullProcess';
import { SearchApplicantResult } from '../models/extendedlpo/ApplicantSearch';

export interface SMELPOResult<T> {
	resultTime: Date;
	data:T;
}

export class SMELPOService extends BaseService {

	private url: string;
	private apiConfiguration: Configuration;

	constructor(url: string, private sessionService: SessionService) {
		super();
		this.url = url;

		this.apiConfiguration = new Configuration({
			basePath: this.url,
			middleware: [{
				//pre?(context: RequestContext): Promise<FetchParams | void>;
				pre: this.preRequest
				//post?(context: ResponseContext): Promise<Response | void>;
			}]
			//accessToken: "token"
		});
	}

	preRequest = (context: RequestContext): Promise<void|FetchParams> => {
		let init = context.init;
		init.credentials = "include";
		let params:FetchParams = {
			url: context.url,
			init: init
		};
		return Promise.resolve(params);
	}


	// Gets a an API with interface of type T. func is the factory for the type.
	private api<T>(func): T {
		//console.log("SMELPOService api " + func.name);
		var apiObj = new func(this.apiConfiguration /*, fetch, basePath*/);
		return apiObj;
	}

	private result<T>(promise: Promise<T>): Promise<SMELPOResult<T>> {
		return promise.then(result => {
			//console.log("SMELPOService 200OK");
			return {
				resultTime: new Date(),
				data: result
			}
		});
	}

	okResult() {
		return Promise.resolve({
			resultTime: new Date()
		});
	}

	private mySsn(): string {
		let login = this.sessionService.getLogin();
		return login.ssn;
	}

	private post<TResult>(op:string, body:any) : Promise<TResult> {
		let bodyJson = JSON.stringify(body);
		return fetch(this.url+'/'+op, {
			method:"POST",
			cache: 'no-cache',
			credentials: "include",
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			body: bodyJson
		})
		.then(result => {
			return result.json();
		})
		.then((result) => {
			let bidResult = result;
			return bidResult;
		});
	}

	// ==== PROCESS ======================

	getProcess(processId: string): Promise<SMELPOResult<LinksAll>> {
		return this.result(
			this.api<ProcessesApi>(ProcessesApi).getProcessAll({
				xProcessID: processId
			})
		);
	}

	getProcesses(customerId?: string): Promise<SMELPOResult<ProcessesType>> {
		if (!customerId)
			customerId = this.mySsn();
		return this.result(
			this.api<ProcessesApi>(ProcessesApi).getProcesses({
				customerId: customerId
			})
		);
	}

	addProcess(process: ProcessType, customerId?: string): Promise<SMELPOResult<ProcessType>> {
		if (!customerId)
			customerId = this.mySsn();

		let arg:AddProcessRequest = {
			process: {
				processId: process.processId,
				caseIdStatus: CaseIdStatus.STARTEDBYAPPLICANT, //  'STARTEDBYAPPLICANT', //AddProcessCaseIdStatusEnum.STARTEDBYAPPLICANT,
				customers: [{ customerId: customerId, customerAdded: new Date().toISOString() }]
			}
		}
		return this.result(
			this.api<ProcessesApi>(ProcessesApi).addProcess(arg)
			.then(result => { 
				return result;
			})
		);
	}

	saveProcess(process: FullProcess): Promise<SMELPOResult<any>> {
		return this.result(
			this.api<ProcessesApi>(ProcessesApi).saveProcessAll({
				processSaveAll: process
			})
		); 
	}

	// ==== CASE ======================

	deleteCase(processId: string, caseId: string, status:UpdateProcessStatusCaseIdStatusEnum): Promise<SMELPOResult<any>> {
		return this.setCaseStatus(processId, caseId, status);
	}

	setCaseStatus(processId: string, caseId: string, //caseStatus: 'CLOSEDBYAPPLICANT' | 'STARTEDBYAPPLICANT' | 'CLOSEDBYOFFICER' | 'CLOSEDBYTHINNING' | 'READYFOROFFICER'
		caseStatus: UpdateProcessStatusCaseIdStatusEnum
	): Promise<SMELPOResult<any>> {
		return this.result(
			this.api<ProcessesApi>(ProcessesApi).updateProcessStatus({ xProcessID: processId, caseIdStatus: caseStatus, caseId: caseId })
		);
	}

	// ==== ADMIN ======================

	adminSearchProcesses(search:AdminCasesSearch) : Promise<AdminCasesSearchResult> {
		return this.post<AdminCasesSearchResult>('Admin/SearchAdminCases', search); 
	}

	downloadFetchResult(result:Promise<Response>) {
		var httpResponse;
		return result.then(response => {
			httpResponse = response;
			return response.blob()
		})
		.then(blob => {
			var cd = httpResponse.headers["Content-Disposition"];
			var fileName = cd;

			var url = URL.createObjectURL(blob);
			var a = document.createElement('a');
			a.href = url;
			a.download = fileName;
			document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
			a.click();    
			a.remove();  //afterwards we remove the element again         
		})
		// .then(blob => URL.createObjectURL(blob))
		// .then(url => {
		// 	window.open(url, '_blank');
		// 	URL.revokeObjectURL(url);
		// });
	}


	// ==== APPLICANT ======================

	searchApplicant(processId: string, customerId:string): Promise<SearchApplicantResult> {
		// return fetch(this.url+'/Search/searchapplicant', {
		// 	method: 'POST',
		// 	credentials: "include",
		// 	headers: {
		// 		'Accept': 'application/json',
		// 		'Content-Type': 'application/json'
		// 	},
		// 	body: JSON.stringify({
		// 		ProcessId:processId,
		// 		CustomerId:customerId
		// 	})
		// })
		// .then(result => {
		// 	return result.json();
		// });
		return this.post<SearchApplicantResult>('Search/searchapplicant', {
			ProcessId:processId,
			CustomerId:customerId
		});
	}


	// addApplicant(processId: string, applicant: Applicant): Promise<SMELPOResult> {
	// 	return this.result(
	// 		this.api<ApplicantsApi>(ApplicantsApi).addApplicant({processId, applicant})
	// 	);
	// }

	// updateApplicant(processId: string, updateApplicant: UpdateApplicantType): Promise<SMELPOResult> {
	// 	return this.result(
	// 		this.api<ApplicantsApi>(ApplicantsApi).updateApplicant(processId, updateApplicant)
	// 	);
	// }

	// deleteApplicant(processId: string, customerId: string): Promise<SMELPOResult> {
	// 	return this.result(
	// 		this.api<ApplicantsApi>(ApplicantsApi).deleteApplicant(processId, customerId)
	// 	);
	// }


	// ==== COMPANIES ======================

	// addCompany(processId:string, applicant:Applicant) : Promise<SMELPOResult> {
	//     return this.result(
	//         this.api<ApplicantsApi>(ApplicantsApi).addApplicant(processId, applicant)
	//     );
	// }

	// updateCompany(processId:string, updateApplicant:UpdateApplicantType) : Promise<SMELPOResult> {
	//     return this.result(
	//         this.api<ApplicantsApi>(ApplicantsApi).updateApplicant(processId, updateApplicant)
	//     );
	// }

	// deleteCompany(processId:string, customerId:string) : Promise<SMELPOResult> {
	//     return this.result(
	//         this.api<ApplicantsApi>(ApplicantsApi).deleteApplicant(processId, customerId)
	//     );
	// } 


}
