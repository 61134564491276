import React from 'react';
import { useSpring, animated } from 'react-spring';
import ClassNames from 'classnames';

import './StatusIndicator.scss';

interface ProgressCircleProps {
	children: object;
	flipped: boolean;
};

interface Props {
	className?: string;
	ordinal: number;
	progress: number;
	icon?: string;
	size: number;
	isCurrentStep?: boolean;
}


function stepCompleteIcon(progress): React.ReactNode {
	if (progress >= 1) {
		return <div className="icon complete" />;
	}
	return null;
}

function round(value:number, precision:number):number {
	if (Number.isInteger(precision)) {
		var shift = Math.pow(10, precision);
		return Math.round(value * shift) / shift;
	} else {
		return Math.round(value);
	}
}

export const StatusIndicator: React.FunctionComponent<Props> = (props) => {
	const strokeWidth = round(props.size * 0.08, 0);
	const circleRadius = props.size / 2 - strokeWidth;
	const statusIndicatorSizePx = `${props.size}px`;
	const circleCenter = props.size / 2;
	const circleCenterPx = `${circleCenter}px`;
	const ordinalFontSize = `${round(circleCenter * 0.92, 0)}px`;
	const circleRadiusPx = `${circleRadius}px`;

	const max = round(Math.PI * circleRadius * 2, 2);
	const current = round(max - max * props.progress, 2);

	const StatusIndicatorClassName = ClassNames(props.className);

	const iconClassName = ClassNames('icon', props.icon);

	const isComplete = props.progress >= 1;
	const isCurrent = props.isCurrentStep;

	const ordinalClassName = ClassNames('ordinal', {
		complete: isComplete,
		current: isCurrent
	});

	const circleClassName = ClassNames('circle', {
		complete: isComplete,
		current: isCurrent
	});

	// Other animations are handled using CSS
	const animationProps = useSpring({ x: current, from: {x: max} });

	return (
		<div
			className={StatusIndicatorClassName}
			style={{ width: statusIndicatorSizePx, height: statusIndicatorSizePx }}>
			<div className="ball">
				<svg className="indicatorSvg">
					<circle
						className={circleClassName}
						cx={circleCenterPx}
						cy={circleCenterPx}
						r={circleRadiusPx}
						strokeWidth={strokeWidth}
					/>
					<animated.circle
						cx={circleCenterPx}
						cy={circleCenterPx}
						r={circleRadiusPx}
						className="progress"
						strokeDashoffset={(animationProps as any).x as number}
						strokeDasharray={max}
						strokeWidth={strokeWidth}
					/>
				</svg>
				<span
					className={ordinalClassName}
					style={{ fontSize: ordinalFontSize, lineHeight: ordinalFontSize }}>
					{props.ordinal}
				</span>
			</div>
			{props.icon ? (
				<div className={iconClassName} />
			) : (
				stepCompleteIcon(props.progress)
			)}
		</div>
	);
};


// Default props
StatusIndicator.defaultProps = {
	className: 'StatusIndicator',
	icon: '',
	size: 48,
	isCurrentStep: false,
};
