import React from 'react';
import Dropzone, { FileRejection } from 'react-dropzone';
import ClassNames from 'classnames';
import { BaseComponent } from '../../../BaseComponent';
import { T } from '../../display/T';
import './Upload.scss';


interface Props {
	minSize?: number;
	maxSize?: number;
	accept?: string[];
	multiple?: boolean;
	enabled?: boolean;
	clickUpload?: boolean;
	dragUpload?: boolean;

	onUpload: (files:File[]) => void;
	onRejected: (fileRejections:FileRejection[]) => void;
}

export class Upload extends BaseComponent<Props> {
	constructor(props: Props) {
		super(props);
	}

	static defaultProps = {
		accept: [
			'application/msword',
			'application/oxps',
			'application/pdf',
			'application/vnd.ms-excel',
			'application/vnd.ms-xpsdocument',
			'application/vnd.oasis.opendocument.spreadsheet',
			'application/vnd.oasis.opendocument.text',
			'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
			'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
			'image/gif',
			'image/jpeg',
			'image/png',
			'image/tiff',
			'text/plain',
		],
		minSize: 100,
		maxSize: 20100100,
		multiple: true,
		enabled: true,
		clickUpload: true,
		dragUpload: true,
	};

	handleDrop = (acceptedFiles: File[]) => {
		this.props.onUpload(acceptedFiles);
	};

	handleReject = (rejectedFiles:FileRejection[]) => {
		this.props.onRejected(rejectedFiles);
	}

	render() {
		const classNames = ClassNames();

		// noClick={!this.props.clickUpload} noDrag={!this.props.dragUpload}		 

		return ( 
			<Dropzone
				onDropAccepted={this.handleDrop}
				onDropRejected={this.handleReject}
				minSize={this.props.minSize}
				maxSize={this.props.maxSize}
				accept={this.props.accept}
				disabled={!this.props.enabled} 
				>
				{({
					getRootProps,
					getInputProps,
					isDragActive,
					isDragAccept,
					isDragReject,
				}) => (
					<div
						className={ClassNames(
							'Upload',
							{ disabled: !this.props.enabled },
							{ DragAccept: isDragAccept },
							{ DragReject: isDragReject }
						)}
						{...getRootProps()}>
						<input {...getInputProps()} />
						<span className="UploadText">
							{!isDragActive && <T k={'Upload_NotActive'} />}
							{isDragActive && !isDragReject && <T k={'Upload_Active'} />}
							{isDragReject && <T k={'Upload_Rejected'} />}
							<img
								src="/images/upload-cloud-blue.svg"
								className="UploadIcon"
							/>
						</span>
					</div>
				)}
			</Dropzone>
		);
	}
}
