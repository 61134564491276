import React from 'react';
import { Loan } from '../../../../models/selmamodels/Loan';
import { AppComponent } from '../../../AppComponent';
import { Button } from '../../../widget/input/Button/Button';
import { LoanService } from '../../../../services/LoanService';
import { Validation } from '../../../widget/display/Validation/Validation';
import { LoanNewAim } from './LoanNewAim';
import { SelectOption } from '../../../widget/input/Select/Select';
import { uiLoanAimCategories } from '../../../../models/UiModels';

interface Props {
	loan: Loan;
}

export class LoanNewAims extends AppComponent<Props> {
	loanService: LoanService;
	aimCategoryOptions: SelectOption[];

	constructor(props: Props) {
		super(props);
		this.observeUpdate(this.props.loan);

		this.loanService = this.application.services.loanService;

		// Create aim drop down options in two levels.
		this.aimCategoryOptions = uiLoanAimCategories.map((x) => {
			const bcOption = {
				label: x.name,
				value: x.id,
			};
			(bcOption as any).detailOptions = x.details.map((y) => {
				const bdOption = {
					label: y.name,
					value: y.id,
				};
				return bdOption;
			});
			return bcOption;
		});
	}

	touchValidationMatchingSums = (stepFormData) => {
		let allTouched = true;

		const requiredTouches = ['amount', 'loanAmountPart_0'];
		requiredTouches.map((x, ix) => {
			// eslint-disable-next-line no-prototype-builtins
			if (!stepFormData.touches.hasOwnProperty(x)) allTouched = false;
		});

		return allTouched;
	}

	addAimClicked = (): void => {
		this.loanService.addNewAim(this.props.loan);
	}

	render() {
		const loanService = this.loanService;
		const loan = this.props.loan;
		const aims = loan.aims || [];
		const canDelete = true;

		const canEditAmountPart = true; //aims.length > 1 || (loan.amount || 0) <= 0;
		const canAddNewLoanAim = loanService.validateAimsAndDownpayment(loan).ok();

		return (
			<div className="LoanNewAims">
				{aims.map((aim, ix) => {
					return (
						<LoanNewAim
							aim={aim}
							loan={loan}
							index={ix}
							key={`loanAim_${ix}_${aim.id}`}
							aimCategoryOptions={this.aimCategoryOptions}
							canEditAmountPart={canEditAmountPart}
							canDelete={canDelete}
						/>
					);
				})}
				<Button
					id="addAimCategory"
					className="Button plus-icon"
					name="addAimCategory"
					labelTextkey="Loan_AddAim"
					onClick={this.addAimClicked}
					style='link'
					enabled={canAddNewLoanAim}
				/>
			</div>
		);
	}
}
