import React from 'react';
import { AppComponent } from '../../AppComponent';

interface Props {
	k: string;
	className?: string;
}

// Displays a translated HTML fragment in the DOM.
export class THtml extends AppComponent<Props> {
	static defaultProps = {
		className: 'Thtml',
	};

	constructor(props: Props) {
		super(props);
	}
	render() {
		const textService = this.application.services.textService;
		const t = textService && textService.text(this.props.k);
		if (t && t.length > 0) {
			return (
				<span className={this.props.className} dangerouslySetInnerHTML={{ __html: t }} />
			);
		} else {
			return null;
		}
	}
}
