/* tslint:disable */
/* eslint-disable */
/**
 * SelmaSME API
 * This definition is the API between customer SME-Gui and the persistence layer for the application NOT FOR V1 When customer logg-in to Selma-EN, we will create a processId with applicant CustomerId (from BankID). When a customer added attachment to the application, we don\'t have a CaseId i LP, should we add a LP-Service to create/reserve a caseId to be able to attach a document to the caseId in DM.
 *
 * The version of the OpenAPI document: 0.7.4
 * Contact: developer@landshypotek.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * employment mapping to Loan Process PERMANENT - Anställd, tills-vidare-anställd TEMPORARY - Tidbegränsad anställning PROBATIONARY - Provanställning UNEMPLOYMENT_INSURANCE - Arbetslöshetskassa SELF_EMPLOYED - Eget företag STUDENT_AID - Studiestöd PENSION - Inkomst av pension
 * @export
 * @enum {string}
 */
export enum ApplicantLPEmployment {
    PERMANENT = 'PERMANENT',
    TEMPORARY = 'TEMPORARY',
    PROBATIONARY = 'PROBATIONARY',
    UNEMPLOYMENTINSURANCE = 'UNEMPLOYMENT_INSURANCE',
    SELFEMPLOYED = 'SELF_EMPLOYED',
    STUDENTAID = 'STUDENT_AID',
    PENSION = 'PENSION'
}

export function ApplicantLPEmploymentFromJSON(json: any): ApplicantLPEmployment {
    return ApplicantLPEmploymentFromJSONTyped(json, false);
}

export function ApplicantLPEmploymentFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApplicantLPEmployment {
    return json as ApplicantLPEmployment;
}

export function ApplicantLPEmploymentToJSON(value?: ApplicantLPEmployment | null): any {
    return value as any;
}

