import React, { ReactNode, FunctionComponent, ReactElement } from 'react';
import Classnames from 'classnames';
import './Table.scss'

export interface TableProps {
	className?: string;
	headingRow?: (HTMLElement | ReactNode | String)[];
	tableRows: any[][];
}

const Table: FunctionComponent<TableProps> = ({ className, headingRow, tableRows }) => {
	const classNames = Classnames([
		'Table',
		className,
	]);

	return (
		<table className={classNames}>
			{headingRow && typeof headingRow === 'string' && (
				<thead>
					<tr>
						<th>{headingRow}</th>
					</tr>
				</thead>
			)}
			{headingRow && typeof headingRow !== 'string' && headingRow.length > 0 && (
				<thead>
					<tr>
						{headingRow.map((heading, key) => <th key={`table-heading-${key}`} >{heading}</th>)}
					</tr>
				</thead>
			)}
			<tbody>
				{tableRows && tableRows.length > 0 && (
					tableRows.map(((row, rowKey) => (
						<tr key={`table-row-${rowKey}`} >
							{row.map((col, colKey) => (
								<td key={`table-row-col-${colKey}`}>{ col }</td>
							))}
						</tr>
					)))
				)}
			</tbody>
		</table>
	)
}

export default Table
