import React from 'react';
import { Case } from '../../../../../models/SelmaModels';
import { Company } from '../../../../../models/selmamodels/Company';
import { AppComponent } from '../../../../AppComponent';
import {
	FormUnit,
	FormUnitSize,
	FormUnitPadding,
} from '../../../../layout/FormUnit/FormUnit';

import { T } from '../../../../widget/display/T';
import Table from '../../../../widget/display/Table/Table';
import NumberFormat from 'react-number-format';

interface Props {
	case: Case;
	company: Company | undefined;
}

export class CompanyRevenueSummary extends AppComponent<Props> {
	private companyEconomyService = this.application.services.companyEconomyService;

	constructor(props: Props) {
		super(props);
	}

	render() {
		const companyEconomy = this.companyEconomyService.getCompanyEconomy(
			this.props.case,
			this.props.company
		);

		const hasRevenue = companyEconomy && this.companyEconomyService.hasRevenue(companyEconomy) ? true : false;

		return (
			hasRevenue &&
			companyEconomy &&
			companyEconomy.revenues && (
				<FormUnit size={FormUnitSize.Normal} padding={FormUnitPadding.Small}>
					<h3>
						<T k="Company_Revenue" />{' '}
						{this.props.company && this.props.company.name}
					</h3>
					<Table
						headingRow={[
							<T key="Company_Budget_Year" k="Company_Budget_Year" />,
							<T key="Company_Budget_Revenue" k="Company_Budget_Revenue" />,
						]}
						tableRows={companyEconomy.revenues.map((x, ix) => [
							x.year,
							<NumberFormat key="revenue" thousandSeparator=" " suffix=" kr" displayType="text" value={x.revenue} />,
						])}
					/>
				</FormUnit>
			)
		);
	}
}
