

export interface ServerSession {
	isLoggedIn: boolean;
	isAdmin: boolean;
	ssn: string;
	firstName: string;
	lastName: string;
	canSignIn: boolean,
	isUnderAge: boolean
}

export default class OidcService {

    private url: string;

	constructor(url: string) {
		this.url = url;
	}

	// Log out current session.
	logout(): Promise<any> {
		return this.post<any>('Logout', {
		}).then(result => {
			return result;
		});
	}

	// Get session info from server.
	getSession(): Promise<ServerSession> {
		return this.get<ServerSession>('Session')
			.then(result => {
				return result;
			}).catch(_ => {
				return {
					isLoggedIn: false,
					isAdmin: false,
					canSignIn: false,
					isUnderAge: false,
					firstName: '',
					lastName: '',
					ssn: ''
				} as ServerSession;
			});
	}

	// Get user login status.
	canLogIn(): Promise<boolean> {
		return this.get<boolean>('CanLogIn')
			.then(result => {
				return result;
			});
	}


	private post<T>(op: string, body: any): Promise<T> {
		let bodyJson = JSON.stringify(body);
		return fetch(this.url + '/' + op, {
			method: "POST",
			cache: 'no-cache',
			credentials: 'include', //include cookies
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			body: bodyJson
		})
			.then(result => {
				return result.json();
			})
			.then((result) => {
				let bidResult: T = result;
				return bidResult;
			});
	}


	private get<T>(op: string): Promise<T> {
		return fetch(this.url + '/' + op, {
			method: "GET",
			cache: 'no-cache',
			credentials: 'include', //include cookies
			headers: {
				'Accept': 'application/json'
			},
		})
			.then(result => {
				return result.json();
			})
			.then((result) => {
				let bidResult: T = result;
				return bidResult;
			});
	}
}