import { BaseService } from './BaseService';
import { Guarantor, Case } from '../models/SelmaModels';
import { SMELPOService } from './SMELPOService';
import { StateService } from './StateService';
import { ValidationResult } from '../utils/ValidationResult';
import { GuardService } from './GuardService';
import { IdService } from './IdService';

/*
Handles all logic for Guarantor.
*/
export class GuarantorService extends BaseService {

	private stateService: StateService;
	private smelpoService: SMELPOService;
	private guardService: GuardService;

	constructor(stateService: StateService, smelpoService: SMELPOService,
		guardService: GuardService, private idService:IdService) {
		super();
		this.stateService = stateService;
		this.smelpoService = smelpoService;
		this.guardService = guardService;
	}

	update(guarantor: Guarantor|Case): void {
		this.stateService.update(guarantor);
	}

	ensureMinimumGuarantors(c: Case): void {
		if( !c.guarantors || c.guarantors.length<1 ) {
			let guarantor = this.newGuarantor();
			this.addGuarantor(c, guarantor);
		}
	}

	addGuarantor(c: Case, guarantor:Guarantor): void {
		if( !c.guarantors )
			c.guarantors = [];

		c.guarantors.push(guarantor);
		this.update(c);
	}

	newGuarantor() : Guarantor {
		return {
			id: this.idService.newGuid()
		}
	}

	hasGuarantor(c: Case): boolean {
		return this.guardService.hasLengthGreaterThanZero(c.guarantors);
	}

	removeGuarantor(c: Case, guarantor: Guarantor) {
		if(!c.guarantors)
			return;
		this.stateService.listRemove(c.guarantors, guarantor)
		this.update(guarantor);
		this.update(c);
	}

	removeAllGuarantors(c: Case): void {
		if( !c.guarantors || c.guarantors.length===0 )
			return;
		c.guarantors = undefined;
		this.update(c);
	}

	validateGuarantor(c: Case, x: Guarantor): ValidationResult {
		let vr = new ValidationResult();
		vr.add({validator:this.hasName, object:x, ok: this.hasName(x)});
		vr.add({validator:this.hasPhone, object:x, ok: this.hasPhone(x)});
		vr.add({validator:this.hasCustomerId, object:x, ok: this.hasCustomerId(x)});
		return vr;
	}

	validateGuarantors(c:Case): ValidationResult {
		let vr = new ValidationResult();
		if (c.guarantors) {
			c.guarantors?.forEach(x => {
				vr.addResult(this.validateGuarantor(c, x));
			})
		}
		return vr;
	}

	hasName(x: Guarantor): boolean {
		const g = this.guardService;
		return g.isString(x.name) && this.guardService.hasLengthGreaterThan(x.name, 1);
	}

	hasPhone(x: Guarantor): boolean {
		const g = this.guardService;
		return g.hasValue(x.phone) && this.guardService.hasLengthGreaterThan(x.phone, 5);
	}

	hasCustomerId(x: Guarantor): boolean {
		const g = this.guardService;
		const ret = x.guarantorCustomerId && g.isString(x.guarantorCustomerId) && g.isValidSsn(x.guarantorCustomerId) ? true : false;
		return ret;
	}
}
