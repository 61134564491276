import React from 'react';

import './Icon.scss';

export enum IconType {
	Check = "check",
	Warning = "warning",
	Complete = "complete",
	ExternalLink = "extern-link-icon"
}

interface Props {
	iconType: IconType;
}

export const Icon: React.FunctionComponent<Props> = (props) => {

	return (
		<div className="widget-icon">
		<div className={"widget icon "+props.iconType}/>
		</div>
	);
};
