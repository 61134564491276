import React from 'react';
import { PersonalEconomy, Case } from '../../../../../models/SelmaModels';
import { Applicant, LPEmploymentEnum } from '../../../../../models/selmamodels/Applicant';
import { AppComponent } from '../../../../AppComponent';
import { T } from '../../../../widget/display/T';
import {
	FormUnit,
	FormUnitSize,
	FormUnitPadding,
} from '../../../../layout/FormUnit/FormUnit';
import { ApplicantService } from '../../../../../services/ApplicantService';
import { PersonalEconomyService } from '../../../../../services/PersonalEconomyService';
import KeyValueList, { KeyValue } from '../../../../widget/display/KeyValueList/KeyValueList';
import { uiEmploymentType } from '../../../../../models/uimodels/UIEmploymentType';
import { uiLongMonths } from '../../../../../models/uimodels/UIMonths';
import { Heading } from '../../../../widget/display/Heading/Heading';
import NumberFormat from 'react-number-format';

interface Props {
	case: Case;
	applicant: Applicant;
}

export class ApplicantIncomeSummary extends AppComponent<Props> {
	applicantService: ApplicantService;
	personalEconomy?: PersonalEconomy;
	personalEconomyService: PersonalEconomyService;

	constructor(props: Props) {
		super(props);

		this.applicantService = this.application.services.applicantService;
		this.personalEconomyService = this.application.services.personalEconomyService;

		this.personalEconomy = this.personalEconomyService.getPersonalEconomy(this.props.case, this.props.applicant);
	}

	render() {
		if( !this.personalEconomy )
			return null;

		const pe: PersonalEconomy = this.personalEconomy; 

		const yearOfEmployment = pe.yearOfEmployment
			? pe.yearOfEmployment.toString()
			: '';
		const needsIncomeOfEmployment = this.personalEconomyService.needsIncomeOfEmployment(pe);
		const mayHaveIncomeOfEmployment = this.personalEconomyService.mayHaveIncomeOfEmployment(pe);
		const showIncomeOfEmployment = needsIncomeOfEmployment || mayHaveIncomeOfEmployment;
		const needsEmployerInformation = this.personalEconomyService.needsEmployerInformation(pe);
		const needsMonthOfEmployment = this.personalEconomyService.needsMonthOfEmployment(pe);
		const peTypeOfEmployment = pe.typeOfEmployment;
		const uiTypeOfEmployment = uiEmploymentType.find(x => x.type===peTypeOfEmployment);
		const typeOfEmployment = uiTypeOfEmployment
			? uiTypeOfEmployment.name
			: '';
		const monthOfEmployment = needsMonthOfEmployment && pe.monthOfEmployment!==undefined
			? uiLongMonths[pe.monthOfEmployment-1].name
			: '';


		let kvList:KeyValue[] = [];

		if( uiTypeOfEmployment ) {
			kvList.push({
				key: <T k="Summary_Applicant_Income_Source" />,
				value: typeOfEmployment,
			});
		}

		if( showIncomeOfEmployment && pe.income ) {
			let labelTextkey = "Summary_Applicant_IncomeAmount";
			if( peTypeOfEmployment===LPEmploymentEnum.SELF_EMPLOYED )
				labelTextkey = "Summary_Applicant_IncomeAmountMyCompany";
			else if( !needsEmployerInformation ) 
				labelTextkey = "Summary_Applicant_IncomeAmountMonth";

			kvList.push({
				key: <T k={labelTextkey} />,
				value: (
					<NumberFormat
						thousandSeparator=" "
						suffix=" kr"
						displayType="text"
						value={pe.income}
					/>
				)
			});
		}

		if( showIncomeOfEmployment && needsEmployerInformation ) {
			if( pe.employer ) {
				let labelTextkey = "Summary_Applicant_Employer";
				if( peTypeOfEmployment===LPEmploymentEnum.SELF_EMPLOYED )
					labelTextkey = "Summary_Applicant_EmployerMyCompany";

				kvList.push({
					key: <T k={labelTextkey} />,
					value: pe.employer,
				});
			}

			if( pe.yearOfEmployment ) {
				let labelTextkey = "Summary_Applicant_EmploymentYear";
				if( peTypeOfEmployment===LPEmploymentEnum.SELF_EMPLOYED )
					labelTextkey = "Summary_Applicant_EmploymentYearMyCompany";

				kvList.push({
					key: <T k={labelTextkey} />,
					value: yearOfEmployment
				});
			}

			if( pe.monthOfEmployment ) {
				let labelTextkey = "Summary_Applicant_EmploymentMonth";
				if( peTypeOfEmployment===LPEmploymentEnum.SELF_EMPLOYED )
					labelTextkey = "Summary_Applicant_EmploymentMonthMyCompany";

				kvList.push({
					key: <T k={labelTextkey} />,
					value: monthOfEmployment
				});
			}
		}

		if( kvList.length===0 )
			return null;

		return (
			<FormUnit size={FormUnitSize.Large} padding={FormUnitPadding.Normal}>
				<Heading level={3} labelTextkey="Summary_Applicant_Income"/>
				<KeyValueList>
					{kvList}
				</KeyValueList>
			</FormUnit>
		);
	}
}
