import React from 'react';
import { Case } from '../../../../../models/SelmaModels';
import { AppComponent } from '../../../../AppComponent';
import {
	FormUnit,
	FormUnitSize,
	FormUnitPadding,
} from '../../../../layout/FormUnit/FormUnit';

import { T } from '../../../../widget/display/T';
import Table from '../../../../widget/display/Table/Table';
import { Heading } from '../../../../widget/display/Heading/Heading';
import NumberFormat from 'react-number-format';

interface Props {
	case: Case;
}

export class CollateralEUSupportSummary extends AppComponent<Props> {
	constructor(props: Props) {
		super(props);
	}

	render() {
		const c = this.props.case;
		const euSupportService = this.application.services.euSupportService;
		const hasEuSupport = euSupportService.hasEuSupport(c);
		if (!c.euSupports || !hasEuSupport) return null;

		return (
			<FormUnit size={FormUnitSize.Large} padding={FormUnitPadding.Normal}>
				<Heading level={3} labelTextkey="Summary_Collateral_EUSupport"/>
				<Table
					headingRow={[
						<T key="Summary_Collateral_EUSupport_SupportType" k="Summary_Collateral_EUSupport_SupportType" />,
						<T key="Summary_Collateral_EUSupport_SupportAmount" k="Summary_Collateral_EUSupport_SupportAmount" />,
						<T key="Summary_Collateral_EuSupport_SupportYear" k="Summary_Collateral_EuSupport_SupportYear" />,
					]}
					tableRows={c.euSupports.map(
						({ euType, supportAmount, supportYear }) => [
							euSupportService.getEuTypeDisplayName(euType),
							<NumberFormat
								key="supportAmount"
								thousandSeparator=" "
								suffix=" kr"
								displayType="text"
								value={supportAmount}
							/>,
							supportYear,
						]
					)}
				/>
			</FormUnit>
		);
	}
}
