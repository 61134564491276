import React from 'react';
import { Applicant } from '../../../../../models/selmamodels/Applicant';
import { AppComponent } from '../../../../AppComponent';
import { T } from '../../../../widget/display/T';
import {
	FormUnit,
	FormUnitSize,
	FormUnitPadding,
} from '../../../../layout/FormUnit/FormUnit';
import KeyValueList from '../../../../widget/display/KeyValueList/KeyValueList';
import { Heading } from '../../../../widget/display/Heading/Heading';
import { UiModelConverter } from '../../../../../models/UiModelConverter';
import { Case } from '../../../../../models/SelmaModels';

interface Props {
	case: Case;
	applicant: Applicant;
}

export class ApplicantContactSummary extends AppComponent<Props> {
	constructor(props: Props) {
		super(props);
	}

	render() {
		const applicantService = this.application.services.applicantService;
		const companyService = this.application.services.companyService;

		const c = this.props.case;
		const applicant = this.props.applicant;

		const applicantCompany = companyService.getApplicantCompany(c,applicant);
		const isJuridicumCompany = applicantCompany && applicantService.isJuridicumCompany(c,applicant);

		let name = (isJuridicumCompany && applicantCompany)?companyService.getDisplayName(applicantCompany):applicantService.getPersonFullName(applicant);

		let kvList = [
			{
				key: isJuridicumCompany?<T k="Summary_Applicant_OrgNr" />:<T k="Summary_Applicant_SSN" />,
				value: (isJuridicumCompany && applicantCompany)?UiModelConverter.prettyOrgNr(applicantCompany.orgNumber):UiModelConverter.prettySsn(applicant.customerId)
			},
			{
				key: isJuridicumCompany?<T k="Summary_Applicant_Name" />:<T k="Summary_Applicant_FirstName" />,
				value: name
			}
		];

		if( !isJuridicumCompany ) {
			kvList.push({
				key: <T k="Summary_Applicant_LastName" />,
				value: applicant.lastName||''
			});
		}

		kvList.push({
			key: <T k="Summary_Applicant_EmailAddress" />,
			value: applicant.email||''
		});
		kvList.push({
			key: <T k="Summary_Applicant_MobilePhone" />,
			value: applicant.mobilePhone||''
		});

		return (
			<FormUnit size={FormUnitSize.Large} padding={FormUnitPadding.Normal}>
				{ (applicant.firstName || applicant.lastName) &&
					<Heading level={2} labelText={name}/>
				}
				<KeyValueList>
					{kvList}
				</KeyValueList>
			</FormUnit>
		);
	}
}
