import React, { Component } from 'react';
import { Case } from '../../../models/SelmaModels';
import { AppComponent } from '../../AppComponent';
import { Button } from '../../widget/input/Button/Button';
import { PageContent, PageStyle } from '../../layout/PageContent/PageContent';
import LoanSummary from '../../case/steps/StepSummary/LoanSummary/LoanSummary';
import { ApplicantsSummary } from '../../case/steps/StepSummary/ApplicantsSummary/ApplicantsSummary';
import { CollateralSummary } from '../../case/steps/StepSummary/CollateralSummary/CollateralSummary';
import { HouseholdSummary } from '../../case/steps/StepSummary/HouseholdSummary/HouseholdSummary';
import { BudgetSummary } from '../../case/steps/StepSummary/BudgetSummary/BudgetSummary';

import './AdminCasePage.scss';
import { T } from '../../widget/display/T';
import { THtml } from '../../widget/display/THtml';
import { ButtonGroup } from '../../widget/input/ButtonGroup/ButtonGroup';
import { FormUnitSize, FormUnit } from '../../layout/FormUnit/FormUnit';
import { FormUnitRow } from '../../layout/FormUnitRow/FormUnitRow';
import { Heading } from '../../widget/display/Heading/Heading';
import { AttachmentSection } from '../AttachmentSection/AttachmentSection';
import { CaseIdStatus } from '../../../libs/SMELPOClient';

interface Props {
	cases: Case[];
	case: Case;
	leaveCase:(c:Case) => void;
}


export class AdminCasePage extends AppComponent<Props> {
	constructor(props: Props) {
		super(props);
		this.state = {};
	}

	leaveCase = () => {
		this.props.leaveCase(this.props.case);
	}
	
	markCaseAsHandled = (c:Case) => {
		this.application.services.caseService
			.adminMarkCaseAsHandled(c)
			.then((c: Case) => {
				this.leaveCase();
			});
	}

	deleteCase = (c: Case): void => {
		if( !window.confirm("Är du säker på att du vill ta bort ärendet?") )
			return;

		this.application.services.caseService
			.adminDeleteCase(this.props.cases, c)
			.then((c: Case) => {
				this.leaveCase();
			});
	};

	render() {
		const caseService = this.application.services.caseService;

		const c = this.props.case;

		const label = " : "+c.number;

		const status = c.status;

		return (
			<div className="Page AdminCasePage">
				<PageContent style={PageStyle.Transparent}>
					<div className="AdminCasePage_Header">
						<Heading level={1} labelTextkey="Admin_Case_Title" labelText={label} />
						<div>
							<span className="CaseAttr">
								<b><T k="Admin_Case_Status" />:</b>{' '}{c.status}
							</span>
							<span className="CaseAttr">
								<b><T k="Admin_Case_Created" />:</b>{' '}{c.createdDate.toLocaleDateString()}
							</span>
							<span className="CaseAttr">
								<b><T k="Admin_Case_Changed" />:</b>{' '}{c.lastAccessedDate.toLocaleDateString()}
							</span>
							{c.lpCaseId && c.lpCaseId.length>0 &&
								<span className="CaseAttr">
									<b><T k="Admin_Case_LPCaseId" />:</b>{' '}{c.lpCaseId}
								</span>
							}
						</div> 
						<p>
							<THtml k="Admin_Case_Description" />
						</p>
					</div>

					{c ? (
						<div className="AdminCasePage_Main">
							<FormUnitRow>
								<ButtonGroup>
									<Button
										id="leaveCase"
										labelTextkey="Admin_Case_Leave"
										onClick={() => this.leaveCase()}
										enabled={true}
									/>
									{ status==CaseIdStatus.READYFOROFFICER &&
									<Button
										id="markCaseAsHandled"
										labelTextkey="Admin_Case_MarkAsHandled"
										onClick={() => this.markCaseAsHandled(c)}
										enabled={true}
									/>
									}
									{ status!=CaseIdStatus.CLOSEDBYOFFICER &&
									<Button
										id="deleteCase"
										labelTextkey="Admin_Case_Delete"
										onClick={() => this.deleteCase(c)}
										enabled={true}
									/>
									}
								</ButtonGroup>
							</FormUnitRow> 
							<LoanSummary
								case={c}
								accordionOpen={true}
								formUnitSize={FormUnitSize.Xlarge}
							/>
							<ApplicantsSummary
								case={c}
								accordionOpen={true}
								formUnitSize={FormUnitSize.Xlarge}
							/>
							<CollateralSummary
								case={c}
								accordionOpen={true}
								formUnitSize={FormUnitSize.Xlarge}
							/>
							<HouseholdSummary
								case={c}
								accordionOpen={true}
								formUnitSize={FormUnitSize.Xlarge}
							/>
							<BudgetSummary
								case={c}
								accordionOpen={true}
								formUnitSize={FormUnitSize.Xlarge}
							/>
							<AttachmentSection
								case={c}
								accordionOpen={true}
								formUnitSize={FormUnitSize.Xlarge}
							/>
						</div>
					) : (
						<div className="AdminCasePage_Main">
							<p>
								<THtml k="Admin_Case_NoCaseData" />
							</p>
						</div>
					)}
				</PageContent>
			</div>
		);
	}
}

