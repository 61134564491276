import React from 'react';
import { Loan } from '../../../../../models/SelmaModels';
import { AppComponent } from '../../../../AppComponent';
import {
	FormUnit,
	FormUnitSize,
	FormUnitPadding,
} from '../../../../layout/FormUnit/FormUnit';

import NumberFormat from 'react-number-format';
import { T } from '../../../../widget/display/T';
import Table from '../../../../widget/display/Table/Table';
import { Heading } from '../../../../widget/display/Heading/Heading';

interface Props {
	loan: Loan;
}

export class LoanAimsSummary extends AppComponent<Props> {
	constructor(props: Props) {
		super(props);
	}

	render() {
		const loanService = this.application.services.loanService;
		const loan = this.props.loan;

		if ( !loan.aims || !loanService.hasAims(loan) ) {
			return null;
		}

		return (
			<FormUnit size={FormUnitSize.Large} padding={FormUnitPadding.Small}>
				<Heading level={3} labelTextkey="Summary_Loan_Purpose_Heading"/>
				<Table
					headingRow={[
						<T key="Summary_Loan_PurposeCategory" k="Summary_Loan_PurposeCategory" />,
						<T key="Summary_Loan_Purpose" k="Summary_Loan_Purpose" />,
						<T key="Summary_Loan_PartOfSum" k="Summary_Loan_PartOfSum" />,
					]}
					tableRows={loan.aims.map(
						({ aimCategory, aimDetail, loanAmountPart }) => [
							loanService.getAimCategoryName(aimCategory),
							loanService.getAimDetailName(aimCategory, aimDetail),
							<NumberFormat
								key="loanAmountPart"
								thousandSeparator=" "
								suffix=" kr"
								displayType="text"
								value={loanAmountPart}
							/>,
						]
					)}
				/>
			</FormUnit>
		);
	}
}
