import React from 'react';
import './Bottom.scss';
import { THtml } from '../../widget/display/THtml';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Row, Col } from 'react-flexbox-grid';
import { T } from '../../widget/display/T';

interface Props extends RouteComponentProps<any> { }

const Bottom: React.FunctionComponent<Props> = (props) => {
	// No footer on case or admin pages
	const path = props.location.pathname;
	if (path === "/case" || path === "/admin") return null;

	return (
		<Row>
			<Col xs={12}>
				<div className="Bottom">
					<footer className="Footer">
						<div className="Footer_row">
							<div className="Footer_col">
								<h2><T k="Footer_About"/></h2>
							</div>
							<div className="Footer_col">
								<THtml k="Footer_Contact" className="footer-contact" />
							</div>
						</div>
					</footer>
				</div>
			</Col>
		</Row>
	);
};

export default withRouter(Bottom);
