import React from 'react';
import ReactDOM from 'react-dom';
import Classnames from 'classnames';
import './Modal.scss';
import { Button } from '../../widget/input/Button/Button';

export enum OverlayStyles {
	'Transparent' = 'Transparent',
	'Darken' = 'Darken',
}

export interface Props {
	modalClassName?: string;
	overlayClassName?: string;
	overlayStyle?: OverlayStyles;
	overlayCloseOnClick?: boolean;
	handleClose?: (event: React.MouseEvent<HTMLDivElement, MouseEvent> | React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export const Modal: React.FunctionComponent<Props> = (props) => {
	const modalDomHandle = document.getElementById('modal-root');

	const overlayClassNames = Classnames(
		props.overlayClassName,
		props.overlayStyle
	);

	const handleOverlayClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		if (props.overlayCloseOnClick && props.handleClose) {
			props.handleClose(event);
		}
	};

	const handleCloseButtonClick = (
		event: React.MouseEvent<HTMLButtonElement, MouseEvent>
	) => {
		if (props.handleClose) {
			props.handleClose(event);
		}
	};

	return ReactDOM.createPortal(
		<div className={overlayClassNames} onClick={handleOverlayClick}>
			<div className={props.modalClassName}>
				<Button
					style="icon"
					className="Button ModalClose close-icon transparent"
					onClick={handleCloseButtonClick}
				/>
				{props.children}
			</div>
		</div>,
		modalDomHandle
	);
};

Modal.defaultProps = {
	modalClassName: 'Modal',
	overlayClassName: 'ModalOverlay',
	overlayStyle: OverlayStyles.Darken,
	overlayCloseOnClick: true,
};
