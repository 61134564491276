import React from 'react';
import { BusinessCategory } from '../../../../models/SelmaModels';
import { Company } from "../../../../models/selmamodels/Company";
import { AppComponent } from '../../../AppComponent';
import { Select, SelectOption } from '../../../widget/input/Select/Select';
import { uiBusinessCategories } from '../../../../models/UiModels';
import { Button } from '../../../widget/input/Button/Button';
import { CompanyService } from '../../../../services/CompanyService';
import { Percent } from '../../../widget/input/Percent/Percent';
import { NumberFormatValues } from 'react-number-format';
import { FormUnitRow } from '../../../layout/FormUnitRow/FormUnitRow';
import { Label } from '../../../widget/display/Label/Label';
import { DeleteButton } from '../../../widget/input/DeleteButton/DeleteButton';
import { Checkbox } from '../../../widget/input/Checkbox/Checkbox';
import { Validation } from '../../../widget/display/Validation/Validation';

interface Props {
	company: Company;
}

interface BCSelectOption extends SelectOption {
}

export class CompanyBusinessFocuses extends AppComponent<Props> {
	businessCategoryOptions: BCSelectOption[];
	companyService: CompanyService;

	constructor(props: Props) {
		super(props);
		this.observeUpdate(this.props.company);

		this.companyService = this.application.services.companyService;

		this.businessCategoryOptions = uiBusinessCategories
			.filter(x => {
				if (this.props.company.businessFocuses && this.props.company.businessFocuses
					.map(y => y.businessCategoryCode)
					.includes(x.BusinessCategoryID))
				{
					return true;
				}

				return parseInt(x.BusinessCategoryID) >= 101;
			})
			.sort(x => parseInt(x.BusinessCategoryID))
			.map(x => {
				let bcOption = {
					label: x.BusinessCategory,
					value: x.BusinessCategoryID
				};
				return bcOption;
			});
	}

	addBusinessCategoryClicked = (): void => {
		this.companyService.addNewBusinessFocus(this.props.company);
	}

	removeBusinessCategoryClicked = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, businessCategory: BusinessCategory): void => {
		this.companyService.removeBusinessFocus(this.props.company, businessCategory);
	}


	businessCategoryCodeChanged = (value: string, businessCategory: BusinessCategory): void => {
		businessCategory.businessCategoryCode = value;
		businessCategory.businessCategoryText = undefined;
		this.companyService.updateBusinessFocus(this.props.company, businessCategory);
	}

	businessPartChanged = (values: NumberFormatValues, businessCategory: BusinessCategory): void => {
		const value = values.floatValue;
		this.companyService.setBusiniessFocusesPart(this.props.company, businessCategory, value);
	}

	mainDirectionChanged(e: React.ChangeEvent<HTMLInputElement>, x: BusinessCategory): void {
		const company = this.props.company;
		this.companyService.setBusinessFocusMainDirection(company, x);
	}

	showMainDirection(mainDirectionBusinessFocuses: BusinessCategory[], testBusinessFocus: BusinessCategory): boolean {
		return mainDirectionBusinessFocuses.findIndex(x => x === testBusinessFocus) >= 0;
	}

	render() {
		const maxNumberOfBusinessCategories = 5;
		const companyService = this.application.services.companyService;
		const company = this.props.company;
		const fs = company.businessFocuses || [];

		const mainDirectionBusinessFocuses = this.companyService.getPossibleMainDirectionBusinessFocuses(company);
		const isAllAddedBusinessFocusesValid = companyService.validateBussinessFocuses(company).ok();
		const enableAddBusinessFocus = fs.length < maxNumberOfBusinessCategories && isAllAddedBusinessFocusesValid;

		return (
			<div className="CompanyBusinessFocuses">
				{
					fs.map((x, ix) => {
						const businessCategoryCode = x.businessCategoryCode || '';
						const hasBusinessCategoryCode = businessCategoryCode && businessCategoryCode.length > 0 ? true : false;

						let businessCategoryOption;
						if (hasBusinessCategoryCode) {
							let businessCategoryOptionResult = this.businessCategoryOptions.filter(x => x.value === businessCategoryCode);
							if (businessCategoryOptionResult.length === 1) {
								businessCategoryOption = businessCategoryOptionResult[0];
							}
						}

						const showMainDirection = this.showMainDirection(mainDirectionBusinessFocuses, x);

						const showLabels = true;
						const canDelete = fs.length > 1;

						return (
							<div className="CompanyBusinessFocus" key={ix}>
								<FormUnitRow>
									<div className="FormUnitRow_Section">
										{showLabels && <Label labelTextkey="Company_SelectBusinessCategory" />}
										<Validation forObject={x} errors={[{ validator: companyService.businessFocusHasCategory, textkey: "Error_SelectValue" }]}>
											<Select
												id={'businessCategoryCode_' + ix}
												value={businessCategoryCode}
												options={this.businessCategoryOptions}
												handleChange={(e) => {
													this.businessCategoryCodeChanged(e, x);
												}}
												placeholderTextkey="Company_SelectBusinessCategory"
												noSelectableOptionsTextkey="Select_NoOptions"
												isSearchable={false}
											/>
										</Validation>
									</div>
									{(true || hasBusinessCategoryCode) && (
										<>
											<div className="FormUnitRow_Section Small">
												{showLabels && <Label labelTextkey="Company_PercentageOfBusiness" />}
												<Validation forObject={x} errors={[{ validator: companyService.businessFocusHasBusinessPart, textkey: "Error_EnterValue" }]}>
													<Percent
														id={'businessPart_' + businessCategoryCode + '_' + ix}
														value={x.businessPart}
														min={1}
														enabled={hasBusinessCategoryCode}
														handleChange={(e) =>
															this.businessPartChanged(e, x)
														}
													/>
												</Validation>
											</div>
											{showMainDirection &&
												<div className="FormUnitRow_Section Small">
													{showLabels && <Label labelTextkey="Company_MainDirectionOfBusiness" />}
													<Validation forObject={x} errors={[{ validator: companyService.hasSpecifiedMainDirectionForBusiniessFocuses, textkey: "Company_SelectMainDirectionOfBusiness" }]}>
														<Checkbox checked={x.mainDirection ? true : false} handleChange={(e) => this.mainDirectionChanged(e, x)} />
													</Validation>
												</div>
											}
										</>
									)}
									<div className="FormUnitRow_Section Button">
										{canDelete &&
											<DeleteButton labelTextkey="Company_RemoveBusinessCategory" questionTextkey="Company_RemoveBusinessCategory_Q" onClick={(e) => this.removeBusinessCategoryClicked(e, x)} />
										}
									</div>
								</FormUnitRow>
							</div>
						);
					})
				}
				<div>
					<Validation forObject={company} immediate={true}
						errors={[{ validator: companyService.companyBusinessFocusBusinessPartSumIsCorrect, textkey: "Company_BusinessCategory_BadSum" }]} />
				</div>

				<div>
					<Button id="addBusinessCategory" className="Button plus-icon"
						name="addBusinessCategory" labelTextkey="Company_AddBusinessCategory" enabled={enableAddBusinessFocus}
						onClick={(e) => this.addBusinessCategoryClicked()} style='link' />
				</div>
			</div>
		);
	}

}
