import React from 'react';
import { Case } from '../../../models/SelmaModels';
import { T } from '../../widget/display/T';
import { AppComponent } from '../../AppComponent';
import { HomeCase } from '../HomeCase/HomeCase';
import { PageContent, PageStyle } from '../../layout/PageContent/PageContent';
import { THtml } from '../../widget/display/THtml';
import { Button } from '../../widget/input/Button/Button';
import { RouteComponentProps, withRouter } from 'react-router';

import './HomePage.scss';
import { Heading } from '../../widget/display/Heading/Heading';
import { UiModelConverter } from '../../../models/UiModelConverter';

interface Props extends RouteComponentProps<any> {
	cases: Case[];
	case?: Case;
	selectedCase: (c: Case) => void;
}

class HomePage extends AppComponent<Props> {
	cases: Case[];

	constructor(props: Props) {
		super(props);
		this.cases = this.props.cases;
		this.observeUpdate(this.cases);

		const caseService = this.application.services.caseService;
		if (!this.props.cases || !this.props.cases.length) {
			caseService.loadCases().then(cases => {
				caseService.appendKycStatusToCases(cases).then(cases => {
					// nothing.
				})
			})
		}
	}

	selectedCase = (c: Case): void => {
		this.application.services.caseService.setCurrentCase(c);
		this.redirectToCase(c);
	};

	redirectToCase(c: Case): any {
		this.props.history.push('/case');
	}

	addCase = (event: React.MouseEvent<Element, MouseEvent>): void => {
		this.application.services.caseService
			.addNewCase()
			.then((c: Case) => {
				return this.selectedCase(c);
			});
	};

	deleteCase = (c: Case): void => {
		this.application.services.caseService
			.deleteCase(this.props.cases, c);
	};

	render() {
		const caseService = this.application.services.caseService;
		const c = this.props.case;
		let cases = this.props.cases; // this.cases; 
		const hasCases = cases && cases.length > 0;

		const hasOngoingCase = caseService.hasOngoingCase(cases);
		const tooYoung = UiModelConverter.getAgeBySsn(this.application.services.sessionService.getSsn()) < 18;
		const canAddCase = !hasOngoingCase && !tooYoung;

		return (
			<div className="Page HomePage">
				<PageContent style={PageStyle.Transparent}>
					<div className="Content">
						<Heading level={1} labelTextkey="Home_Title" />
						<p>
							<THtml k="Home_Description" />
						</p>
					</div>
					<div className="Cases">
						{hasCases ? (
							<Heading level={2} labelTextkey="Home_YourCases" />
						) : (
							<Heading level={2} labelTextkey="Home_YouHaveNoCases" />
						)}
						{hasCases &&
							this.props.cases.map((x) => {
								try {
									return (
										<div
											key={x.id}
											className={
												'Case ' + (c && c.id === x.id ? 'Highlight' : '')
											}>
											<HomeCase
												case={x}
												selectedCase={this.selectedCase}
												deletedCase={this.deleteCase}
											/>
										</div>
									);
								} catch {
									return null;
								}
							})
						}
						<Button
							onClick={this.addCase}
							labelTextkey="Home_AddCase"
							className="Button big full-width plus-icon"
							enabled={canAddCase}
						/>
						{hasOngoingCase &&
							<p className={"MessagePanel"}>
								<T k="Home_AddCase_AlreadyOngoingCase" />
							</p>
						}
						{tooYoung &&
							<p className={"MessagePanel"}>
								<T k="Home_AddCase_TooYoung" />
							</p>
						}
					</div>
				</PageContent>
			</div>
		);
	}
}

export default withRouter(HomePage);
