import React from 'react';
import { Case } from '../../../../../models/SelmaModels';
import { AppComponent } from '../../../../AppComponent';
import Accordion from '../../../../layout/Accordion/Accordion';
import {
	FormUnit,
	FormUnitSize,
	FormUnitPadding,
} from '../../../../layout/FormUnit/FormUnit';
import { SelectedCompanySummary } from './SelectedCompanySummary';
import { CompanyHasRevenueSummary } from './CompanyHasRevenueSummary';
import { CompanyHighRiskBusinessSummary } from './CompanyHighRiskBusinessSummary';
import { CompanyRevenueSummary } from './CompanyRevenueSummary';
import { CompanyBusinessFocusesSummary } from './CompanyBusinessFocusesSummary';
import { Heading } from '../../../../widget/display/Heading/Heading';

interface Props {
	case: Case;
	accordionOpen?: boolean;
	formUnitSize?: FormUnitSize;
}

export class CompanySummary extends AppComponent<Props> {
	constructor(props: Props) {
		super(props);
	}

	static defaultProps = {
		accordionOpen: false,
		formUnitSize: FormUnitSize.Large
	};

	render() {
		const c = this.props.case;
		if (!c.companies) return null;

		const companyEconomyService = this.application.services.companyEconomyService;
		const company = this.application.services.companyService.getMySelectedCompany(c);
		if( !company ) return null;

		let companyEconomy = companyEconomyService.getCompanyEconomy(c, company);
		let hasRevenue = companyEconomy && companyEconomyService.hasRevenue(companyEconomy)? true : false;

		const applicantService = this.application.services.applicantService;
		let applicant = applicantService.getMyApplicant(c);

		return (
			<div className="CompanySummary">
				<FormUnit
					size={this.props.formUnitSize}
					padding={FormUnitPadding.Small}>
					<Accordion
						open={this.props.accordionOpen}
						title={<Heading level={2} labelTextkey="Summary_Company_Title"/>}>
						<SelectedCompanySummary case={c} />
						{ companyEconomy && <CompanyHasRevenueSummary companyEconomy={companyEconomy} /> }
						{ hasRevenue &&
							<>
							<CompanyHighRiskBusinessSummary case={c} applicant={applicant}/>
							<CompanyRevenueSummary case={c} company={company} />
							<CompanyBusinessFocusesSummary company={company} />
							</>
						}
					</Accordion>
				</FormUnit>
			</div>
		);
	}
}
