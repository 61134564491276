import { BaseService } from './BaseService';
import { Case, ExtLoan, Household } from '../models/SelmaModels';
import { SMELPOService } from './SMELPOService';
import { StateService } from './StateService';
import { ValidationResult } from '../utils/ValidationResult';
import { GuardService } from './GuardService';
import { ApplicantService } from './ApplicantService';
import { IdService } from './IdService';
import { TypeOfLoan } from '../libs/SMELPOClient';

/*
Handles all logic for ExtLoan.
*/
export class ExtLoanService extends BaseService {
	private stateService: StateService;
	private smelpoService: SMELPOService;
	private guardService: GuardService;

	constructor(stateService: StateService, smelpoService: SMELPOService, 
		guardService: GuardService, private applicantService:ApplicantService, private idService:IdService) {
		super();
		this.stateService = stateService;
		this.smelpoService = smelpoService;
		this.guardService = guardService;
	} 

	update(extLoan: ExtLoan|Case): void {
		this.stateService.update(extLoan);
	}

	newExtLoan() : ExtLoan {
		return {
			id: this.idService.newGuid()
		};
	}
	
	addExtLoan(c: Case, extLoan: ExtLoan) {
		if( !c.extLoans )
			c.extLoans = [];
		c.extLoans.push(extLoan); 
		this.update(c);
	}

	removeExtLoan(c: Case, extLoan: ExtLoan): void {
		if (!c.extLoans) 
			return;
		else {
			this.stateService.listRemove(c.extLoans, extLoan);
			this.update(c);
		}
	}


	getExtLoansByCustomerId(c:Case, customerId: string) : ExtLoan[] {
		if( !c.extLoans )
			return [];

		const r = c.extLoans.filter(x=>{
			return this.isExtLoanOwner(x, customerId)
		});
		return r;
	}

	removeExtLoansForHousehold(c: Case, household: Household) {
		// TODO: Only works as long as we have one household!
		if( c.extLoans ) {
			this.stateService.listRemoveAll(c.extLoans);
			this.update(c);
		} 
	}

	removeAllExtLoans(c:Case):void {
		if( c.extLoans ) {
			this.stateService.listRemoveAll(c.extLoans);
			this.update(c);
		} 
	}

	removeLoanOwnerForHousehold(c:Case, household:Household, customerId:string) : void {
		var extLoans = this.getExtLoansByCustomerId(c, /*household,*/ customerId);
		if( extLoans ) {
			extLoans.forEach(x => {
				this.removeLoanOwner(x, customerId);
			})
		}
	}

	removeLoanOwner(extLoan:ExtLoan, customerId:string) : void {
		if( extLoan.loanOwners ) {
			if( 0!==this.stateService.listRemoveWhere(extLoan.loanOwners, 
				x => this.guardService.compareCustomerId(x.customerId, customerId)) ) {
					this.update(extLoan);
			}
		}
	}

	isExtLoanOwner(extLoan:ExtLoan, customerId:string) : boolean {
		if( !extLoan.loanOwners )
			return false;
		
		for( let extLoanMember of extLoan.loanOwners ) {
			if( this.guardService.compareCustomerId(extLoanMember.customerId, customerId) )
				return true;
		}
		return false;
	}

	validateExtLoan(c: Case, x: ExtLoan): ValidationResult {
		let vr = new ValidationResult();
		const hasType = this.hasType(x);
		vr.add({validator:this.hasType, object:x, ok: hasType});
		if( hasType ) {
			vr.add({validator:this.hasValidLoanOwners, object:x, ok: this.hasValidLoanOwners(x)});

			const isStudentLoan = this.isStudentLoan(x);
			if( isStudentLoan ) {
				vr.add({validator:this.hasMonthlyCost, object:x, ok: this.hasMonthlyCost(x)});
			} else {
				vr.add({validator:this.hasAmount, object:x, ok: this.hasAmount(x)});
				vr.add({validator:this.hasRedeem, object:x, ok: this.hasRedeem(x)});
			}
		}
		return vr;
	}

	hasType(x: ExtLoan): boolean {
		const g = this.guardService;
		return g.hasValue(x.type);
	}

	isStudentLoan(x: ExtLoan):boolean {
		return x.type==TypeOfLoan.Studieln; // see uiExtLoanTypes
	}

	// Used for student loans. 
	hasMonthlyCost(x: ExtLoan): boolean {
		const g = this.guardService;
		return g.isGreaterThanZero(x.monthlyCost);
	}

	// Used for everything except student loans. 
	hasAmount(x: ExtLoan): boolean {
		const g = this.guardService;
		return g.isGreaterThanZero(x.amount);
	}

	hasRedeem(x: ExtLoan): boolean {
		const g = this.guardService;
		return g.hasValue(x.redeemLoan);
	}

	hasValidLoanOwners(x: ExtLoan): boolean {
		const g = this.guardService;
		return g.hasLengthGreaterThan(x.loanOwners,0);
	}
}
