import React, { useState } from 'react';
import { Application } from '../../../models/AppModels';
import { useHistory } from 'react-router-dom';
import './Locallogin.scss';
import { Button } from '../../widget/input/Button/Button';
import { Textbox } from '../../widget/input/Textbox/Textbox';

const Locallogin = () => {
	const application = Application.instance;
	const history = useHistory();
	const [ssn, setSsn] = useState<string>('');
	const sessionService = application.services.sessionService;

	const setCookie = (name): Promise<boolean> => {
		return new Promise((resolve) => {
			const d = new Date();
			d.setTime(d.getTime() + 1 * 60 * 60 * 1000);
			document.cookie =
				name + '=' + ssn + ';' + 'expires=' + d.toUTCString() + ';path=/';
			resolve(true);
		});
	};

	const logIn = () => {
		if (ssn.length < 12 || ssn.length > 12) {
			alert('Personnummer måste vara 12 tecken');
		} else {
			setCookie('sme_local_login').then((x) => {
				sessionService.loadSession().then((result) => {
					if (result.canSignIn) history.push('/');
					else {
						history.push('/accessdenied');
					}
				});
			});
		}
	};

	return (
		<div className="localLogin">
      <p>Du har lokal login aktiverat. Ändra config värde &apos;useLocalLogin&apos; till false för att använda sso.</p>
			<p>Ange personnummer:</p>
			<div className='actions'>
			<Textbox maxlength={12} handleChange={x => setSsn(x.target.value)}/>
			<Button onClick={logIn} label='Logga in'></Button>
			</div>
		</div>
	);
};

export default Locallogin;
