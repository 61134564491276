import React, { useRef, FunctionComponentElement, ReactNode } from "react";
import { animated, useSpring } from "react-spring";
import useMeasure from "use-measure";

interface IconProps {
	children: object;
	flipped: boolean;
};

export function Icon({ flipped }): FunctionComponentElement<IconProps> {
	const props = useSpring({
		transform: `rotate(${flipped ? 180 : 0}deg)`,
		config: { mass: 1, tension: 250, friction: 30 }
	});

	return (
		<animated.div className="Accordion__icon" style={props}>
		</animated.div>
	);
}

interface AccordionComponentProps {
	active: boolean;
	children: ReactNode;
	onEnd?: () => void|null;
};

export default function AccordionComponent({
	active,
	children,
	onEnd,
}): FunctionComponentElement<AccordionComponentProps> {
	const nodeRef = useRef<HTMLInputElement>(null);
	const { height, ...rest } = useMeasure(nodeRef);
	const props = useSpring({
		overflow: "hidden",
		height: active ? height : 0,
		config: { tension: 2000, friction: 100, precision: 1 },
		onRest: onEnd,
	});

	const hidden = !active;

	return (
		<animated.div aria-hidden={hidden} style={props} >
			<div className="accordion-component" ref={nodeRef}>{children}</div>
		</animated.div>
	);
}

AccordionComponent.defaultProps = {
	active: false,
	onEnd: null,
};
