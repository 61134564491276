import React from 'react';
import { CompanyEconomy } from '../../../../models/SelmaModels';
import { Company } from "../../../../models/selmamodels/Company";
import { Applicant } from "../../../../models/selmamodels/Applicant";
import { AppComponent } from '../../../AppComponent';
import { FormUnit, FormUnitSize } from '../../../layout/FormUnit/FormUnit';
import { Label } from '../../../widget/display/Label/Label';
import { ApplicantRiskBusiness } from './ApplicantRiskBusiness';
import { CompanyRevenue } from './CompanyRevenue';
import { CompanyBusinessFocuses } from './CompanyBusinessFocuses';

interface Props {
	company: Company;
	companyEconomy: CompanyEconomy;
	applicant: Applicant;
}

export class CompanyRevenueChunk extends AppComponent<Props> {
	constructor(props: Props) {
		super(props);
		this.observeUpdate(this.props.companyEconomy);
	}

	render() {
		const applicant = this.props.applicant;
		const company = this.props.company;
		const companyEconomy = this.props.companyEconomy;

		return (
			<>
			<FormUnit size={FormUnitSize.Large}>
				<ApplicantRiskBusiness applicant={applicant} />
			</FormUnit>

			<FormUnit>
				<Label labelTextkey="Company_Revenue" />
				<p>{company.name}</p>
				<CompanyRevenue companyEconomy={companyEconomy} />
			</FormUnit>

			<FormUnit size={FormUnitSize.Large}>
				<Label labelTextkey="Company_BusinessFocus" />
				<CompanyBusinessFocuses company={company} />
			</FormUnit>
			</>
		);
	}
}
