import React from 'react';
import { ApplicantContactSummary } from './ApplicantContactSummary';
import { ApplicantIncomeSummary } from './ApplicantIncomeSummary';
import { ApplicantPepSummary } from './ApplicantPepSummary';
import { Case, Applicant } from '../../../../../models/SelmaModels';
import { AppComponent } from '../../../../AppComponent';
import { T } from '../../../../widget/display/T';

interface Props {
	case: Case;
	applicant: Applicant;
}

export class PersonApplicantItemSummary extends AppComponent<Props> {

	constructor(props: Props) {
		super(props);
	}

	render() {
		const personalEconomyService = this.application.services.personalEconomyService;
		const applicantService = this.application.services.applicantService;
		const c = this.props.case;
		const applicant = this.props.applicant;
		const personalEconomy = personalEconomyService.getPersonalEconomy(c, applicant);
		const willBuyAgriForestryProperty = applicant.willBuyAgriForestryProperty===true;

		return (
			<>
				<ApplicantContactSummary case={c} applicant={applicant} />

				{ willBuyAgriForestryProperty &&
				<p>
					<T k="Summary_Applicant_WillBuyAgriForestryProperty"/>
				</p>
				}

				{personalEconomy && 
				<ApplicantIncomeSummary case={c} applicant={applicant} />
				}
				{	
					applicantService.showPepForApplicant(applicant) && <ApplicantPepSummary applicant={applicant} />
				}
			</>
		);
	}
}
