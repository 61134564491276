import React, { useEffect } from 'react';
import ClassNames from 'classnames';
import NumberFormat, { NumberFormatValues } from 'react-number-format';
import './SSN.scss';
import { Application } from '../../../../models/AppModels';


interface Props {
	className?: string;
	id?: string;
	name?: string;
	handleBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
	handleChange: (values: NumberFormatValues) => void;
	type?: string;
	value?: string;
	enabled?: boolean;
	displayType?: 'input' | 'text';
	format?: string;
	placeholder?: string;
	mask?: string[];
	hasErrors?: boolean;
	touched?: boolean;
	dirty?: boolean;
}

export const SSN: React.FunctionComponent<Props> = (props) => {

	const classNames = ClassNames(props.className, {
		error: props.touched && props.hasErrors,
	});

	const onPaste = (event: React.ClipboardEvent<HTMLInputElement>) : void => {
		// useEffect(() => {
		// 	const timer = setTimeout(() => {
		// 		const g = Application.instance.services.guardService;
		// 		const cs = Application.instance.services.convertService;
		// 		if( props.value )
		// 			props.value = cs.toPrettyNormalizedSsn(props.value);
		// 	}, 100);
		// 	return () => clearTimeout(timer);
		//   }, []);
	}
	
	return (
		<NumberFormat
			className={classNames}
			id={props.id}
			value={props.value}
			onValueChange={props.handleChange}
			onBlur={props.handleBlur}
			onPaste={onPaste}
			displayType={props.displayType}
			format={props.format}
			placeholder={props.placeholder}
			mask={props.mask}
			disabled={props.enabled===false}
		/>
	);
};


// Default props
SSN.defaultProps = {
	className: 'SSN',
	type: 'text',
	displayType: 'input',
	format: '########-####',
	placeholder: 'ÅÅÅÅMMDD-NNNN',
	mask: ['Å', 'Å', 'Å', 'Å', 'M', 'M', 'D', 'D', 'N', 'N', 'N', 'N'],
};
