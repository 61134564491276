import { useEffect } from "react";

declare global {
	interface Window {
		Ebbot: {
			botId: string;
		}
	}
}

interface EbbotProps {
	botId: string;
	enabled: boolean;
}
const EbbotChat = (props: EbbotProps) => {
	const { botId, enabled } = props;
	const show = enabled && botId.length > 0;

	useEffect(() => {

		if (!show)
			return;

		window.Ebbot = window.Ebbot || { botId: botId }

		/* Is the chat script tag already present in the DOM? */
		const chatTagId = 'init-js-widget';
		let js = document.getElementById(chatTagId) as HTMLScriptElement;
		/* If no chat script tag is present in the DOM, create it */
		if (!js) {
			js = document.createElement('script') as HTMLScriptElement;
			js.src = 'https://ebbot-v2.storage.googleapis.com/ebbot-web/init.js?t=' + Math.random();
			js.id = chatTagId;
			var tag = document.getElementsByTagName('script')[0];
			if (tag) {
				tag.parentNode?.insertBefore(js, tag);
			}
		}
	}, []);

	return null;
}

export default EbbotChat;