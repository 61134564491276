import React from 'react';
import ClassNames from 'classnames';
import './ButtonGroup.scss';

export enum ButtonGroupLayout {
	'Stretch' = 'Stretch',
	'Nowrap' = 'Nowrap',
}

interface Props {
	className?: string;
	layout?: ButtonGroupLayout;
}

export const ButtonGroup: React.FunctionComponent<Props> = (props) => {
	const classNames = ClassNames(props.className, {
		Stretch: props.layout === 'Stretch',
		Nowrap: props.layout === 'Nowrap',
	});

	return <div className={classNames}>{props.children}</div>;
};

ButtonGroup.defaultProps = {
	layout: undefined,
	className: 'ButtonGroup',
};
