/* tslint:disable */
/* eslint-disable */
/**
 * SelmaSME API
 * This definition is the API between customer SME-Gui and the persistence layer for the application NOT FOR V1 When customer logg-in to Selma-EN, we will create a processId with applicant CustomerId (from BankID). When a customer added attachment to the application, we don\'t have a CaseId i LP, should we add a LP-Service to create/reserve a caseId to be able to attach a document to the caseId in DM.
 *
 * The version of the OpenAPI document: 0.7.4
 * Contact: developer@landshypotek.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Typ av eu-stöd
 * @export
 * @enum {string}
 */
export enum EUType {
    EUStd = 'EU-stöd',
    EUStdOchNationelltStd = 'EU-stöd och nationellt stöd',
    EUStdOchNationelltStdUtanMjlk = 'EU-stöd och nationellt stöd, utan mjölk',
    NationellStdUtanMjlk = 'Nationell stöd, utan mjölk'
}

export function EUTypeFromJSON(json: any): EUType {
    return EUTypeFromJSONTyped(json, false);
}

export function EUTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): EUType {
    return json as EUType;
}

export function EUTypeToJSON(value?: EUType | null): any {
    return value as any;
}

