import React from 'react';
import { AppComponent } from '../../AppComponent';

interface Props {
	k: string;
}

// Displays a translated text in the DOM.
export class T extends AppComponent<Props> {
	constructor(props: Props) {
		super(props);
	}
	render() {
		let textService = this.application.services.textService;
		return (
			<>{textService && textService.textOrEmpty(this.props.k)}</>
		);
	}
}

