/* tslint:disable */
/* eslint-disable */
/**
 * SelmaSME API
 * This definition is the API between customer SME-Gui and the persistence layer for the application NOT FOR V1 When customer logg-in to Selma-EN, we will create a processId with applicant CustomerId (from BankID). When a customer added attachment to the application, we don\'t have a CaseId i LP, should we add a LP-Service to create/reserve a caseId to be able to attach a document to the caseId in DM.
 *
 * The version of the OpenAPI document: 0.7.4
 * Contact: developer@landshypotek.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Enumeration of type of loans
 * @export
 * @enum {string}
 */
export enum TypeOfLoan {
    Studieln = 'Studielån',
    KontoKortkredit = 'Konto/kortkredit',
    AvbetalningskreditBillnBtln = 'Avbetalningskredit/Billån/båtlån',
    Borgenstagande = 'Borgensåtagande',
    Garantier = 'Garantier',
    Nringskredit = 'Näringskredit',
    BlancokreditVrigaLn = 'Blancokredit/övriga lån'
}

export function TypeOfLoanFromJSON(json: any): TypeOfLoan {
    return TypeOfLoanFromJSONTyped(json, false);
}

export function TypeOfLoanFromJSONTyped(json: any, ignoreDiscriminator: boolean): TypeOfLoan {
    return json as TypeOfLoan;
}

export function TypeOfLoanToJSON(value?: TypeOfLoan | null): any {
    return value as any;
}

