import React from 'react';
import {
	Case,
	AttachmentRequest,
	Attachment,
} from '../../../../models/SelmaModels';
import { AppComponent } from '../../../AppComponent';
import { StepHeader } from '../../StepHeader';
import { StepContext } from '../../CaseSteps';
import { StepFooter } from '../../StepFooter';
import { RequestedAttachments } from './RequestedAttachments';
import { ProvidedAttachments } from './ProvidedAttachments';
import { ManualBudget } from './ManualBudget';
import Accordion from '../../../layout/Accordion/Accordion';
import {
	FormUnit,
	FormUnitSize,
	FormUnitPadding,
} from '../../../layout/FormUnit/FormUnit';
import { Heading } from '../../../widget/display/Heading/Heading';
import { UiModelConverter } from '../../../../models/UiModelConverter';

interface Props {
	case: Case;
	context: StepContext;
}

export class StepBudget extends AppComponent<Props> {
	constructor(props: Props) {
		super(props);
	}

	componentDidMount() {
		const c = this.props.case;
		this.observeUpdate(c);

		const companyService = this.application.services.companyService;
		const budgetService = this.application.services.budgetService;
		const companyEconomyService = this.application.services.companyEconomyService;

		if( c.companies ) {
			c.companies.forEach(company => {
				companyEconomyService.ensureCompanyEconomy(c,company);
				budgetService.ensureBudgetsForAllCompanyEconomies(c);
				})
		}

		budgetService.ensureBudgetAttachmentRequests(c);
	}

	render() {
		const c = this.props.case;
		const attachmentService = this.application.services.attachmentService;

		const attachmentRequests: AttachmentRequest[] = attachmentService.getAttachmentRequestsByType(c, "COMPANYBUDGET") || [];
		const attachments: Attachment[] = attachmentService.getAttachmentsByType(c, "COMPANYBUDGET") || [];

		const showAttachmentReqs = attachmentRequests.length>0;
		const showAttachments = attachments.length>0;
		const showManualBudgets = true;

		const validationResult = this.props.context.getValidation();
		const canGoToNext = validationResult.ok();

		return (
			<div className="StepBudget Step">
				<StepHeader
					context={this.props.context}
					titleTextkey="Budget_Title"
					descriptionTextkey="Budget_Description"
				/>

				{(showAttachmentReqs || showAttachments) && (
					<FormUnit size={FormUnitSize.Large} padding={FormUnitPadding.Large}>
						<FormUnit size={FormUnitSize.Large} padding={FormUnitPadding.Small}>
							<Heading level={2} labelTextkey="Budget_RequestedAttachments_Title"/>
						</FormUnit>
						<FormUnit size={FormUnitSize.Large} padding={FormUnitPadding.Small}>
							<Accordion title={<Heading level={2} labelTextkey="Budget_Attachments_Title"/>}>
							{ showAttachmentReqs &&
								<RequestedAttachments case={c} attachmentRequests={attachmentRequests} attachments={attachments} />
							}
							{/* showAttachments && 
								<>
									<Heading level={3} labelTextkey="Budget_ProvidedAttachments_Title"/>
									<ProvidedAttachments case={c} attachments={attachments} allowDelete={true} />
								</>
							*/}
							</Accordion>
						</FormUnit>
					</FormUnit>
				)}

				{showManualBudgets && (
					<Heading level={2} labelTextkey="Budget_ManualBudget_Title"/>
				)}

				{showManualBudgets &&
					c.companyEconomies &&
					c.companyEconomies.map((x, ix) => {
						var company = this.application.services.companyService.getCompanyById(
							c,
							x.companyId
						);
						if (!company) return undefined;
						var budget = this.application.services.budgetService.getBudgetByCompanyEconomy(
							c,
							x
						); // GOOD IDEA?
						if (!budget) return undefined;

					return (
						<FormUnit
							size={FormUnitSize.Xlarge}
							padding={FormUnitPadding.Small}
							key={'companyBudget_' + x.companyId}>
							<Accordion
								title={
									<h2>{company.name}, {UiModelConverter.prettyCustomerId(company.orgNumber)}</h2>
								}>
								<ManualBudget
									case={c}
									company={company}
									budget={budget}
									id={ix}
								/>
							</Accordion>
						</FormUnit>
					);
				})}

				<StepFooter
					context={this.props.context}
					canGoHome={true}
					canGoToNext={canGoToNext}
					onGoHome={this.props.context.goHome}
					onGoToNext={this.props.context.goNext}
				/>
			</div>
		);
	}
}
