/* tslint:disable */
/* eslint-disable */
/**
 * SelmaSME API
 * This definition is the API between customer SME-Gui and the persistence layer for the application NOT FOR V1 When customer logg-in to Selma-EN, we will create a processId with applicant CustomerId (from BankID). When a customer added attachment to the application, we don\'t have a CaseId i LP, should we add a LP-Service to create/reserve a caseId to be able to attach a document to the caseId in DM.
 *
 * The version of the OpenAPI document: 0.7.4
 * Contact: developer@landshypotek.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * type of downpayment \'Övrigt\', \'Försäljning bostad\', \'Blanco\', \'Sparande\'
 * @export
 * @enum {string}
 */
export enum DownPaymentType {
    Vrigt = 'Övrigt',
    FrsljningBostad = 'Försäljning bostad',
    Blanco = 'Blanco',
    Sparande = 'Sparande'
}

export function DownPaymentTypeFromJSON(json: any): DownPaymentType {
    return DownPaymentTypeFromJSONTyped(json, false);
}

export function DownPaymentTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): DownPaymentType {
    return json as DownPaymentType;
}

export function DownPaymentTypeToJSON(value?: DownPaymentType | null): any {
    return value as any;
}

