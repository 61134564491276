import React from 'react';
import { BaseComponent } from '../BaseComponent';
import {
	BirdLoader,
	gifBackground,
} from '../widget/display/BirdLoader/BirdLoader';
import './LoadingPage.scss';

interface Props {}

export class LoadingPage extends BaseComponent<Props> {
	interval;

	constructor(props: Props) {
		super(props);

		this.state = { showLoadingAnimation: false };
	}

	componentDidMount() {
		this.interval = setTimeout(() => {
			this.setState({
				showLoadingAnimation: true,
			});
		}, 500);
	}

	componentWillUnmount() {
		clearTimeout(this.interval);
	}

	render() {
		return (
			<div className="LoadingPage">
				{this.state.showLoadingAnimation ? (
					<BirdLoader gifBackground={gifBackground.white} />
				) : null}
			</div>
		);
	}
}
