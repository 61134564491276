import { BaseService } from './BaseService';
import { UiLanguage } from './UiLanguageService';
import { Objserver } from '../utils/Objserver';
import { Case as SelmaCase } from '../models/SelmaModels';
import { Session } from './SessionService';
import { Configuration } from './ConfigurationService';

export interface ApplicationState {
	loaded: boolean;
	configuration?: Configuration;
	language?: UiLanguage;
	session: Session;
	cases?: SelmaCase[];
	case?: SelmaCase;
}

export class StateService extends BaseService {

	public state: ApplicationState; // TODO: dont expose this.
	public stateObjserver: Objserver; // should be private.

	constructor() {
		super();
		this.state = {
			loaded: false,
			session: {
			}
		}
		this.stateObjserver = new Objserver();
	}

	update(obj: any) {
		this.stateObjserver.update(obj);
		this.writeStateToStorage();
	}

	updateState() {
		this.update(this.state);
	}

	replace(oldObj: any, newObj: any) {
		this.stateObjserver.replace(oldObj, newObj);
	}

	// Mutates list so that (first) item is removed.
	listRemove<T>(list:Array<T>, item:T):void {
		const ix = list.indexOf(item);
		if( ix<0 )
			return;
		else {
			list.splice(ix,1);
			this.update(list);
		}
	}

	// Mutates list so that (first) item is removed.
	listRemoveWhere<T>(list:Array<T>, match:(x:T) => boolean):number {
		var toRemove = list.filter(match);
		if( toRemove.length===0 ) {
			return 0;
		}

		var cnt = 0;
		toRemove.forEach(item => {
			const ix = list.indexOf(item);
			if( ix>=0 ) {
				list.splice(ix,1);
				cnt++;
			}
		});

		this.update(list);
		return cnt;
	}

	listRemoveAll<T>(list:Array<T>):void  {
		const len = list.length;
		if( len<=0 )
			return;
		else {
			const deleted = list.splice(0);
			deleted.forEach(x => { this.update(x)});
			this.update(list);
		}
	}

	listAdd<T>(list:Array<T>, item:T) {
		list.push(item);
		this.update(list);
	}

	loadState(state: string, replace: boolean = false): Promise<ApplicationState> {
		if (state.startsWith('{'))
			return this.setState(JSON.parse(state), replace); // Assume 'state' is json.
		else if (state.startsWith('http'))
			return this.loadStateByUrl(state, replace); // Assume 'state' is URL.
		else
			return this.loadStateByName(state, replace); // Assume 'state' is name of state file.
	}

	private fixStateAfterLoad(state: ApplicationState) {
		if (state && state.case && state.case.id && state.cases) {
			const caseId = state.case.id;
			const r = state.cases.filter(x => x.id === caseId);
			if( r.length===1 )
				state.case = r[0];
		}
	}

	setState(state: ApplicationState, replace: boolean = false): Promise<ApplicationState> {
		if (replace) {
			let prevState = this.state;
			this.state = state;
			this.stateObjserver.replace(prevState, this.state);
		}
		else {
			Object.assign(this.state, state);
			this.stateObjserver.update(this.state);
		}
		return Promise.resolve(this.state);
	}

	loadStateByName(name: string, replace: boolean = false): Promise<ApplicationState> {
		let url = "state/" + name + ".appstate.json";
		let promise = this.loadStateByUrl(url, replace);
		return promise;
	}

	loadStateByUrl(url: string, replace: boolean = false): Promise<ApplicationState> {
		let promise = fetch(url)
			.then((result) => {
				return result.json();
			}).then((state: ApplicationState) => {
				this.fixStateAfterLoad(state);
				return this.setState(state, replace);
			});
		return promise;
	}

	private writeStateToStorage() {
		window.sessionStorage.setItem('smeState', JSON.stringify(this.state));
	}

	loadStateFromStorage(replace: boolean = false): ApplicationState|undefined {
		let stateString = window.sessionStorage.getItem('smeState');
		if( !stateString )
			return undefined;
		let state = JSON.parse(stateString);
		this.setState(state, replace);
		return state;
	}
}
