import React, { Component } from 'react';
import { Case } from '../../models/SelmaModels';
import { StepLoan } from './steps/StepLoan/StepLoan';
import { StepApplicant } from './steps/StepApplicant/StepApplicant';
import { StepCompany } from './steps/StepCompany/StepCompany';
import { StepCollateral } from './steps/StepCollateral/StepCollateral';
import { StepHousehold } from './steps/StepHousehold/StepHousehold';
import { StepDefinition, CaseStep } from '../../services/StepService';
import { BirdLoader } from '../widget/display/BirdLoader/BirdLoader';
import { AppComponent } from '../AppComponent';
import { StepBudget } from './steps/StepBudget/StepBudget';
import { StepSummary } from './steps/StepSummary/StepSummary';
import { StepDone } from './steps/StepDone/StepDone'; 
import { StepState, CaseStepper } from './StepState';
import { ValidationResult } from '../../utils/ValidationResult';

interface Props  {
	case: Case;
	stepper: CaseStepper;
	step?: StepDefinition;
	goHome: () => void;
	validatedStep: (step:StepDefinition, result:ValidationResult) => void;
}

export interface StepContext {
	step: StepDefinition;
	case: Case;

	getValidation: () => ValidationResult; // Returns the most recent validation of the current step of the current case.
	goHome: () => void;
	goPrev: () => void;
	goNext: () => void;
	canSave();
	updateSteps();
}

export class CaseSteps extends AppComponent<Props> {
	constructor(props: Props) {
		super(props);
	}

	makeStepContext() : StepContext {
		if( !this.props.step ) {
			throw 'No step';
		} else {
			const caseService = this.application.services.caseService;
			const step:StepDefinition = this.props.step;
			const c:Case = this.props.case;
			let stepContext:StepContext = {
				step: step,
				case: c,
				goHome: ():void => {
					this.props.goHome();
				},
				goPrev: ():void => {
					this.props.stepper.goPrev();
				},
				goNext: ():void => {
					this.props.stepper.goNext();
				},
				getValidation: ():ValidationResult => {
					let vr = this.props.stepper.getCurrentValidation();
					if( vr )
						return vr;
					else
						return new ValidationResult();
				},
				canSave: ():boolean => {
					return caseService.canSaveCase(c);
				},
				updateSteps: ():void => {
					this.props.stepper.initStepDefinitions();
				},
			};

			return stepContext;
		}
	}

	render() {
		let loading = this.props.step?false:true;
		if (loading) return <BirdLoader />;

		let context = this.makeStepContext();
		let step: CaseStep = context.step.type;

		return (
			<div className="CaseSteps">
				{step === CaseStep.Company && (
					<StepCompany case={this.props.case} context={context} />
				)}
				{step === CaseStep.Loan && (
					<StepLoan case={this.props.case} context={context} />
				)}
				{step === CaseStep.Applicant && (
					<StepApplicant case={this.props.case} context={context} />
				)}
				{step === CaseStep.Collateral && (
					<StepCollateral case={this.props.case} context={context} />
				)}
				{step === CaseStep.Household && (
					<StepHousehold case={this.props.case} context={context} />
				)}
				{step === CaseStep.Budget && (
					<StepBudget case={this.props.case} context={context} />
				)}
				{step === CaseStep.Summary && ( 
					<StepSummary case={this.props.case} context={context} />
				)}
				{step === CaseStep.Done && ( 
					<StepDone case={this.props.case} context={context} />
				)}
			</div>
		);
	}
}
