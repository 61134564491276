import React from 'react';
import {
	Case, ExtLoanOwner,
} from '../../../../../models/SelmaModels';
import { AppComponent } from '../../../../AppComponent';
import { T } from '../../../../widget/display/T';
import {
	FormUnit,
	FormUnitSize,
	FormUnitPadding,
} from '../../../../layout/FormUnit/FormUnit';
import KeyValueList from '../../../../widget/display/KeyValueList/KeyValueList';
import { Panel } from '../../../../layout/Panel/Panel';
import NumberFormat from 'react-number-format';
import { uiExtLoanTypes } from '../../../../../models/uimodels/UIExtLoanType';
import { Heading } from '../../../../widget/display/Heading/Heading';
import { TypeOfLoan } from '../../../../../libs/SMELPOClient';
import { UiModelConverter } from '../../../../../models/UiModelConverter';

interface Props {
	case: Case;
}

export class HouseholdExtLoansSummary extends AppComponent<Props> {
	constructor(props: Props) {
		super(props);
	}

	getLoanOwners(loanOwners:ExtLoanOwner[], c:Case) {
		const lo = loanOwners.map((o, ix) => {
			if (!c.applicants) return null;
			return c.applicants.find(a => a.customerId === o.customerId)
		})

		let applicantString = '';

		lo.map((x, ix) => {
			if (!x) return null;
			applicantString += `${x.firstName||''} ${x.lastName||''} (${UiModelConverter.prettySsn(x.customerId)})`;
			if ((lo.length - 1) > ix) applicantString += ', '
		})

		return applicantString;
	}

	getLoanTypeTextByUiId(loanTypeId: string | undefined): string {
		if (!loanTypeId) return "";
		const loanTypeObject = uiExtLoanTypes.filter((uiType) => loanTypeId === uiType.key);
		if( !loanTypeObject.length)
			return '';
		return loanTypeObject[0].name;
	}

	getLoanTypeName(typeOfLoan: TypeOfLoan | undefined) : string {
		if (!typeOfLoan) return "";
		const loanTypeObject = uiExtLoanTypes.filter((uiType) => typeOfLoan === uiType.type);
		if( !loanTypeObject.length)
			return '';
		return loanTypeObject[0].name;
	}

	render() {
		const c = this.props.case;
		if (!c.extLoans) return null;

		const extLoanService = this.application.services.extLoanService;

		return (
			<FormUnit size={FormUnitSize.Large} padding={FormUnitPadding.Normal}>
				<Heading level={3} labelTextkey="Summary_Household_ExtLoan_Title"/>
				{c.extLoans.map(
					(x, ix) => {

						let keyValueList = [
							{
								key: <T k="Summary_Household_ExtLoan_Type" />,
								value: this.getLoanTypeName(x.type),
							},
							{
								key: <T k="Summary_Household_ExtLoan_Amount" />,
								value: (
									<NumberFormat
										thousandSeparator=" "
										suffix=" kr"
										displayType="text"
										value={x.amount}
									/>
								),
							},
							{
								key: (
									<T k="Summary_Household_ExtLoan_RedeemLoan" />
								),
								value: x.redeemLoan ? <T k="Yes" /> : <T k="No" />,
							},
							{
								key: (
									<T k="Summary_Household_ExtLoan_LoanOwners" />
								),
								value: this.getLoanOwners(x.loanOwners || [], c),
							},

						];

						const isStudentLoan = extLoanService.isStudentLoan(x);

						if( isStudentLoan ) {
							keyValueList = [
								{
									key: <T k="Summary_Household_ExtLoan_Type" />,
									value: this.getLoanTypeName(x.type),
								},
								{
									key: <T k="Summary_Household_ExtLoan_MonthlyCost" />,
									value: (
										<NumberFormat
											thousandSeparator=" "
											suffix=" kr/månad"
											displayType="text"
											value={x.monthlyCost}
										/>
									),
								},
								{
									key: (
										<T k="Summary_Household_ExtLoan_LoanOwners" />
									),
									value: this.getLoanOwners(x.loanOwners || [], c),
								},
							];
						}

						return (
							<Panel key={`extLoan_${x.id}_${ix}`}>
								<KeyValueList>
									{keyValueList}
								</KeyValueList>
							</Panel>
						);
						}
				)}
			</FormUnit>
		);
	}
}
