import React from 'react';
import { Case, CompanyEconomy } from '../../../../../models/SelmaModels';
import { AppComponent } from '../../../../AppComponent';
import {
	FormUnit,
	FormUnitSize,
	FormUnitPadding,
} from '../../../../layout/FormUnit/FormUnit';
import KeyValueList from '../../../../widget/display/KeyValueList/KeyValueList';
import { T } from '../../../../widget/display/T';

interface Props {
	companyEconomy: CompanyEconomy;
}

export class CompanyHasRevenueSummary extends AppComponent<Props> {
	companyEconomyService = this.application.services.companyEconomyService;

	constructor(props: Props) {
		super(props);
	}

	render() {
		const companyEconomy = this.props.companyEconomy;
		const hasRevenue = this.companyEconomyService.hasRevenue(companyEconomy) ? true : false;

		return (
			<FormUnit size={FormUnitSize.Large} padding={FormUnitPadding.Small}>
				<KeyValueList>
					{[
						{
							key: <T k="Summary_Company_HasRevenue" />,
							value: hasRevenue ? (
								<T k="Yes" />
							) : (
								<T k="No" />
							),
						},
					]}
				</KeyValueList>
			</FormUnit>
		);
	}
}
