
export interface UILoanAimCategory {
	id: string;
	name: string;
	details: UILoanAimDetail[];
}

export interface UILoanAimDetail {
	id: string;
	name: string;
}


// This array is provided as-is from LHB. See mapper function at the end.
export const uiLoanAimCategories: UILoanAimCategory[] =
[
	{
		id: "01",
		mainText: "Nybyggnad",
		mainDisplayText: "Nybyggnad",
		aims: [
			{
				aimText: "Bostad",
				aimDisplayText: "Bostad"
			},
			{
				aimText: "Fjäderfä",
				aimDisplayText: "Stall fjäderfä"
			},
			{
				aimText: "Maskinhall",
				aimDisplayText: "Maskinhall"
			},
			{
				aimText: "Mjölkstall",
				aimDisplayText: "Mjölkstall"
			},
			{
				aimText: "Nötstall",
				aimDisplayText: "Nötstall"
			},
			{
				aimText: "Svinstall",
				aimDisplayText: "Svinstall"
			},
			{
				aimText: "Övr. djurslag",
				aimDisplayText: "Övriga djurslag"
			},
			{
				aimText: "Övr. ekonomibyggnad",
				aimDisplayText: "Övrig ekonomibyggnad"
			}
		]
	},
	{
		id: "02",
		mainText: "Renovering",
		mainDisplayText: "Renovering",
		aims: [
			{
				aimText: "Bostad",
				aimDisplayText: "Bostad"
			},
			{
				aimText: "Fjäderfä",
				aimDisplayText: "Stall fjäderfä"
			},
			{
				aimText: "Maskinhall",
				aimDisplayText: "Maskinhall"
			},
			{
				aimText: "Mjölkstall",
				aimDisplayText: "Mjölkstall"
			},
			{
				aimText: "Nötstall",
				aimDisplayText: "Nötstall"
			},
			{
				aimText: "Svinstall",
				aimDisplayText: "Svinstall"
			},
			{
				aimText: "Övr. djurslag",
				aimDisplayText: "Övriga djurslag"
			},
			{
				aimText: "Övr. ekonomibyggnad",
				aimDisplayText: "Övrig ekonomibyggnad"
			}
		]
	},
	{
		id: "03",
		mainText: "Tillbyggnad",
		mainDisplayText: "Tillbyggnad",
		aims: [
			{
				aimText: "Bostad",
				aimDisplayText: "Bostad"
			},
			{
				aimText: "Fjäderfä",
				aimDisplayText: "Stall fjäderfä"
			},
			{
				aimText: "Maskinhall",
				aimDisplayText: "Maskinhall"
			},
			{
				aimText: "Mjölkstall",
				aimDisplayText: "Mjölkstall"
			},
			{
				aimText: "Nötstall",
				aimDisplayText: "Nötstall"
			},
			{
				aimText: "Svinstall",
				aimDisplayText: "Svinstall"
			},
			{
				aimText: "Övr. djurslag",
				aimDisplayText: "Övriga djurslag"
			},
			{
				aimText: "Övr. ekonomibyggnad",
				aimDisplayText: "Övrig ekonomibyggnad"
			}
		]
	},
	{
		id: "04",
		mainText: "Maskininvestering",
		mainDisplayText: "Maskininvestering",
		aims: [
			{
				aimText: "Maskiner",
				aimDisplayText: "Maskiner"
			}
		]
	},
	{
		id: "05",
		mainText: "Fastighetsköp",
		mainDisplayText: "Fastighetsköp",
		aims: [
			{
				aimText: "Jordbruk",
				aimDisplayText: "Jordbruk"
			},
			{
				aimText: "Annan fastighet",
				aimDisplayText: "Annan fastighet"
			}
		]
	},
	{
		id: "06",
		mainText: "Rörelsekapital",
		mainDisplayText: "Rörelsekapital",
		aims: [
			{
				aimText: "Djur",
				aimDisplayText: "Djur"
			},
			{
				aimText: "Lager",
				aimDisplayText: "Lager"
			},
			{
				aimText: "Likvid förstärkning",
				aimDisplayText: "Likvid förstärkning"
			},
			{
				aimText: "Rekonstruktion",
				aimDisplayText: "Rekonstrukt"
			}
		]
	},
	{
		id: "07",
		mainText: "Energiproduktion",
		mainDisplayText: "Energiproduktion",
		aims: [
			{
				aimText: "Biogas",
				aimDisplayText: "Biogas"
			},
			{
				aimText: "Vindkraft",
				aimDisplayText: "Vindkraft"
			}
		]
	}
].map(x => {
	let cat: UILoanAimCategory = {
		id: x.mainText,
		name: x.mainDisplayText,
		details: x.aims.map(y => {
			let aim: UILoanAimDetail = {
				id: y.aimText,
				name: y.aimDisplayText
			};
			return aim;
		})
	}
	return cat;
});
