/* tslint:disable */
/* eslint-disable */
/**
 * SelmaSME API
 * This definition is the API between customer SME-Gui and the persistence layer for the application NOT FOR V1 When customer logg-in to Selma-EN, we will create a processId with applicant CustomerId (from BankID). When a customer added attachment to the application, we don\'t have a CaseId i LP, should we add a LP-Service to create/reserve a caseId to be able to attach a document to the caseId in DM.
 *
 * The version of the OpenAPI document: 0.7.4
 * Contact: developer@landshypotek.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * KYC-Information
 * @export
 * @interface KycInformationType
 */
export interface KycInformationType {
    /**
     * application model unique identification
     * @type {string}
     * @memberof KycInformationType
     */
    processId: string;
    /**
     * customerId is swedish ssn or corporate organisation number
     * @type {string}
     * @memberof KycInformationType
     */
    customerId: string;
    /**
     * Kyc-identity key
     * @type {string}
     * @memberof KycInformationType
     */
    kycId?: string;
    /**
     * Jag är medveten om att Landshypotek Bank inhämtar en kreditupplysning på mig och att det sker när jag går vidare från denna sida i nästa steg i ansökan.
     * @type {boolean}
     * @memberof KycInformationType
     */
    kycAcceptUC?: boolean;
    /**
     * Jag är medveten om att Landshypotek Bank behandlar mina personuppgifter i och med ansökan och att jag kan läsa mer om Landshypotek Banks personuppgiftsbehandling.
     * @type {boolean}
     * @memberof KycInformationType
     */
    kycAcceptGDPR?: boolean;
    /**
     * Jag är medveten om att de uppgifter jag lämnar och resultatet av kreditansökan även visas för medsökande, om en sådan läggs till senare i ansökan. Om jag äger andra fastigheter, som banken hämtar uppgifter om från externa källor, visas även de uppgifterna för medsökande.
     * @type {boolean}
     * @memberof KycInformationType
     */
    kycUCAware?: boolean;
    /**
     * Innehar eller har du de 18 senaste månaderna innehaft en viktig offentlig funnktion?
     * @type {boolean}
     * @memberof KycInformationType
     */
    kycPublicFunction?: boolean;
    /**
     * Är du familjemedlem eller medarbetare till en person som har, eller som under de senaste 18 månaderna innehaft en viktig offentlig funktion?
     * @type {boolean}
     * @memberof KycInformationType
     */
    kycRelatedPublicFunction?: boolean;
    /**
     * High risk industry, högriskbransch (true/false)
     * @type {boolean}
     * @memberof KycInformationType
     */
    kycHighRiskIndustry?: boolean;
    /**
     * High risk industry entered by customer
     * @type {string}
     * @memberof KycInformationType
     */
    kycHighRiskIndustryText?: string;
    /**
     * Är du skatteskyldig i USA genom medborgarskap eller annan anknytning till USA?
     * @type {boolean}
     * @memberof KycInformationType
     */
    kycAmericanCitizen?: boolean;
    /**
     * Är kund skattskyldig i ett annat land än Sverige och USA?
     * @type {boolean}
     * @memberof KycInformationType
     */
     kycCrsTaxObligation?: boolean;    
}

export function KycInformationTypeFromJSON(json: any): KycInformationType {
    return KycInformationTypeFromJSONTyped(json, false);
}

export function KycInformationTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): KycInformationType {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'processId': json['processId'],
        'customerId': json['customerId'],
        'kycId': !exists(json, 'kycId') ? undefined : json['kycId'],
        'kycAcceptUC': !exists(json, 'kycAcceptUC') ? undefined : json['kycAcceptUC'],
        'kycAcceptGDPR': !exists(json, 'kycAcceptGDPR') ? undefined : json['kycAcceptGDPR'],
        'kycUCAware': !exists(json, 'kycUCAware') ? undefined : json['kycUCAware'],
        'kycPublicFunction': !exists(json, 'kycPublicFunction') ? undefined : json['kycPublicFunction'],
        'kycRelatedPublicFunction': !exists(json, 'kycRelatedPublicFunction') ? undefined : json['kycRelatedPublicFunction'],
        'kycHighRiskIndustry': !exists(json, 'kycHighRiskIndustry') ? undefined : json['kycHighRiskIndustry'],
        'kycHighRiskIndustryText': !exists(json, 'kycHighRiskIndustryText') ? undefined : json['kycHighRiskIndustryText'],
        'kycAmericanCitizen': !exists(json, 'kycAmericanCitizen') ? undefined : json['kycAmericanCitizen'],
        'kycCrsTaxObligation': !exists(json, 'kycCrsTaxObligation') ? undefined : json['kycCrsTaxObligation'],
    };
}

export function KycInformationTypeToJSON(value?: KycInformationType | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'processId': value.processId,
        'customerId': value.customerId,
        'kycId': value.kycId,
        'kycAcceptUC': value.kycAcceptUC,
        'kycAcceptGDPR': value.kycAcceptGDPR,
        'kycUCAware': value.kycUCAware,
        'kycPublicFunction': value.kycPublicFunction,
        'kycRelatedPublicFunction': value.kycRelatedPublicFunction,
        'kycHighRiskIndustry': value.kycHighRiskIndustry,
        'kycHighRiskIndustryText': value.kycHighRiskIndustryText,
        'kycAmericanCitizen': value.kycAmericanCitizen,
        'kycCrsTaxObligation': value.kycCrsTaxObligation
    };
}


