import React from 'react';
import './Panel.scss';

interface Props {
	className?: string;
}

export const Panel: React.FunctionComponent<Props> = (props) => {

	return (
		<div className={props.className} >
			{props.children}
		</div>
	);
};

Panel.defaultProps = {
	className: 'Panel',
};
