import React, { Component } from 'react';
import { Applicant as SelmaApplicant, Case, Applicant, Company } from '../../../../models/SelmaModels';
import { AppComponent } from '../../../AppComponent';
import { FormUnit, FormUnitSize } from '../../../layout/FormUnit/FormUnit';
import { ApplicantContact } from './ApplicantContact';
import { ApplicantPep } from './ApplicantPep';
import { ApplicantApproval } from '../StepLoan/ApplicantApproval';
import { ApplicantIncome } from './ApplicantIncome';
import { CompanyHasRevenue } from '../StepCompany/CompanyHasRevenue';
import { CompanyRevenueChunk } from '../StepCompany/CompanyRevenueChunk';
import { UiModelConverter } from '../../../../models/UiModelConverter';
import { DeleteButton } from '../../../widget/input/DeleteButton/DeleteButton';
import { HeaderButton, HeaderButtonLayout } from '../../../layout/HeaderButton/HeaderButton';
import { Heading } from '../../../widget/display/Heading/Heading';

interface Props {
	case: Case;
	applicant: SelmaApplicant;
	company: Company;
	removeApplicant: (applicant: Applicant) => void;
}

interface State {
	companyHasRevenue?: boolean;
}

export class PersonalCompanyApplicantItem extends AppComponent<Props, State> {
	myApplicant: SelmaApplicant;

	constructor(props: Props) {
		super(props);
		this.observeUpdate(this.props.applicant);

		this.state = {
			companyHasRevenue: undefined
		}

		this.myApplicant = this.application.services.applicantService.getMyApplicant(this.props.case);
	}

	componentDidMount() {
		const personalEconomyService = this.application.services.personalEconomyService;
		const personalEconomy = personalEconomyService.ensurePersonalEconomy(
			this.props.case,
			this.props.applicant
		);

		const c = this.props.case;
		const company = this.props.company;
		const companyEconomyService = this.application.services.companyEconomyService;
		const companyEconomy = companyEconomyService.ensureCompanyEconomy(c, company);
	}

	hasRevenueChanged = (e, companyEconomy) => {
		this.setState({
			companyHasRevenue: e
		});
	};

	render() {
		const applicantService = this.application.services.applicantService;
		const companyService = this.application.services.companyService;
		const personalEconomyService = this.application.services.personalEconomyService;
		const companyEconomyService = this.application.services.companyEconomyService;

		const c = this.props.case;
		const applicant = this.props.applicant;
		const company = this.props.company;

		const personalEconomy = personalEconomyService.getPersonalEconomy(c, applicant);

		const companyEconomy = companyEconomyService.getCompanyEconomy(c, company);

		let hasRevenue:boolean|undefined;
		if( companyEconomy )
			hasRevenue = companyEconomyService.hasRevenue(companyEconomy);
		const showRevenue = hasRevenue===true?true:false;

		const canRemove = applicantService.canRemoveApplicant(c, applicant); 
		const isMe = this.props.applicant==this.myApplicant;

		const companyDisplayName = companyService.getDisplayName(company);

		return (
			<div className="PrivateCompanyApplicantItem">
				<HeaderButton layout={HeaderButtonLayout.Stretch}>
					<Heading level={2} labelText={companyDisplayName}/>
					{ canRemove &&
						<DeleteButton labelTextkey="Applicant_RemoveApplicant"onClick={() => {this.props.removeApplicant(applicant);}}/>
					}
				</HeaderButton>

				<div className="HeaderButton Stretch">
					<Heading level={3} labelText={UiModelConverter.prettyOrgNr(company.orgNumber)}/>
				</div>

				{ !isMe && companyEconomy && 
					<FormUnit>
						<CompanyHasRevenue
							case={c}
							company={company}
							companyEconomy={companyEconomy}
							hasRevenueChanged={this.hasRevenueChanged}
						/>
					</FormUnit>
				}

				{ !isMe && showRevenue && companyEconomy &&
					<CompanyRevenueChunk applicant={applicant} company={company} companyEconomy={companyEconomy} />
				}

				<ApplicantContact applicant={this.props.applicant}/>
				
				{ personalEconomy && 
				<ApplicantIncome applicant={this.props.applicant} case={this.props.case} personalEconomy={personalEconomy}/> 
				}

				{
					(applicantService.showPepForApplicant(this.props.applicant) && <ApplicantPep applicant={this.props.applicant}/>)
				}
				
				{ !isMe && 
					<ApplicantApproval applicant={this.props.applicant}/>
				}
			</div>
		);
	}
}
