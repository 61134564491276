/* eslint-disable react/display-name */
import React from 'react';
import BrowserDetection from 'react-browser-detection';
import './BirdLoader.scss';

export enum gifBackground {
	'white' = 'white',
	'hintOfRedGray' = 'hintOfRedGray',
	'waferGray' = 'waferGray',
	'botticelliBlue' = 'botticelliBlue'
}

interface Props {
	gifBackground?: gifBackground;
}

export const BirdLoader: React.FunctionComponent<Props> = (props) => {
	const browserHandler = {
		ie: () => {
			switch (props.gifBackground) {
				case 'hintOfRedGray':
					return (
						<img
							src="/images/bird-animation-hint-of-red-gray.gif"
							className="BirdGif"
						/>
					);
				case 'waferGray':
					return (
						<img
							src="/images/bird-animation-wafer-gray.gif"
							className="BirdGif"
						/>
					);
				case 'botticelliBlue':
					return (
						<img
							src="/images/bird-animation-botticelli-blue.gif"
							className="BirdGif"
						/>
					);
				default:
					return (
						<img
							src="/images/bird-animation-white.gif"
							className="BirdGif"
						/>
					);
			}
		},
		default: () => <div className="Bird" />,
	};

	return (
		<div className="BirdLoader">
			<BrowserDetection>{browserHandler}</BrowserDetection>
		</div>
	);
};

BirdLoader.defaultProps = {
	gifBackground: gifBackground.white,
};
