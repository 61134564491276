import React, { Component } from 'react';
import { Case, AttachmentRequest } from '../../../../models/SelmaModels';
import { AppComponent } from '../../../AppComponent';
import { Upload } from '../../../widget/input/Upload/Upload';
import { Messages, MessageItem } from '../../../widget/display/Messages/Messages';
import { FileRejection } from 'react-dropzone';

interface Props {
	case: Case;
	attachmentRequest: AttachmentRequest;
}

interface State {
	rejectedFiles: File[];
}

export class RequestedAttachment extends AppComponent<Props, State> {
	attachmentService = this.application.services.attachmentService;

	constructor(props: Props) {
		super(props);
		this.state = {
			rejectedFiles: [] as File[],
		};
	}

	onUpload = (files: File[]): void => {
		this.clearRejectedFiles();

		const attachmentRequest = this.props.attachmentRequest;
		files.forEach((x) => {
			this.application.services.attachmentService.uploadAttachment(
				this.props.case,
				attachmentRequest,
				x
			);
		});
	};

	clearRejectedFiles() {
		this.setState({
			rejectedFiles: [],
		});
	}

	onRejected = (fileRejections: FileRejection[]): void => {
		if( !fileRejections )
			return;
		const files = fileRejections.map(x => x.file);
		this.setState({
			rejectedFiles: this.state.rejectedFiles.concat(files),
		});
	};



	render() {
		const maxFileSize = 20100100;
		const c = this.props.case;
		const attachmentRequest = this.props.attachmentRequest;

		let errors: MessageItem[] | undefined;
		if( this.state.rejectedFiles && this.state.rejectedFiles.length > 0 ) {
			const textService = this.application.services.textService;
			
			errors = this.state.rejectedFiles.map((x) => {
				var t = textService.textOrEmpty("Upload_Of") + " " + x.name + " " + textService.textOrEmpty("Failed") + ".";
				if( x.size>maxFileSize )
					t += " " + textService.textOrEmpty("Upload_TooLarge");
				return {
					ok: false,
					text: t,
				};
			}); 
		}

		return (
			<div className="RequestedAttachment">
				{ attachmentRequest.message && attachmentRequest.message.length &&
				<div>
					{attachmentRequest.message}
				</div>
				}
				<Upload onUpload={this.onUpload} onRejected={this.onRejected} />
				{ errors &&
					<Messages items={errors}/>
				}
			</div>
		);
	}
}
