import React from 'react';
import { Case, ExtLoan, Applicant } from '../../../../models/SelmaModels';
import { AppComponent } from '../../../AppComponent';
import { SelectOption, Select } from '../../../widget/input/Select/Select';
import { FormUnit, FormUnitSize, FormUnitPadding } from '../../../layout/FormUnit/FormUnit';
import { Label } from '../../../widget/display/Label/Label';
import { Money } from '../../../widget/input/Money/Money';
import { NumberFormatValues } from 'react-number-format';
import { RadiobuttonGroup, RadioOption } from '../../../widget/input/RadiobuttonGroup/RadiobuttonGroup';
import { CheckboxOption } from '../../../widget/input/CheckboxGroup/CheckboxGroup';
import { uiExtLoanTypes, UIExtLoanType } from '../../../../models/UiModels';
import { ApplicantSelect } from './ApplicantSelect';
import { Validation } from '../../../widget/display/Validation/Validation';
import { getUIName } from '../../../../models/uimodels/UINamed';

interface Props {
	case: Case;
	extLoan: ExtLoan;
}


const redeemOptions:RadioOption[] = [
	{
		group: 'redeem',
		label: 'Ja',
		value: 'yes',
	},
	{
		group: 'redeem',
		label: 'Nej',
		value: 'no',
	},
];




export class HouseholdExtLoan extends AppComponent<Props> {
	householdService = this.application.services.householdService;
	extLoanService = this.application.services.extLoanService;
	extLoanTypeOptions:SelectOption[];
	//loanOwnerOptions:CheckboxOption[];
	uqid: string;
	redeemOptions:RadioOption[];

	constructor(props: Props) {
		super(props);

		this.observeUpdate(this.props.extLoan);

		this.uqid = this.application.services.idService.newIdString();
		let uq = this.uqid+"_";

		const textService = this.application.services.textService;
		this.extLoanTypeOptions = uiExtLoanTypes.map(x => {
			return {
				value: x.key,
				label: getUIName(x, textService)
			}
		});

		this.redeemOptions = redeemOptions.map(x => {
			return {
				group: uq+x.group,
				label:x.label,
				value:x.value
			}
		});

		let applicants = this.props.case.applicants||[];

		// this.loanOwnerOptions = applicants.map(() =>  {
		// 	return {
		// 		id: this.uqid + 'cb1',
		// 		value: 'Renovering',
		// 		label: 'Renovering'
		// 	};
		// });
		

	}

	extLoanTypeChanged(value: string, extLoan: ExtLoan) {
		let r = uiExtLoanTypes.filter(x => x.key==value);
		if( r.length ) {
			extLoan.type = r[0].type;
			this.ensureMaxNumberOfLoanOwners(extLoan);
			this.extLoanService.update(extLoan);
		}
	}
	
	amountChanged = (e: NumberFormatValues, extLoan: ExtLoan): void => {
		extLoan.amount = e.floatValue;
		this.extLoanService.update(extLoan);
	}

	monthlyCostChanged = (e: NumberFormatValues, extLoan: ExtLoan): void => {
		extLoan.monthlyCost = e.floatValue;
		this.extLoanService.update(extLoan);
	}

	redeemChanged = (value: string) => {
		let extLoan = this.props.extLoan;
		const isChecked = value == 'yes';
		extLoan.redeemLoan = isChecked;
		this.extLoanService.update(extLoan);
	}

	ownersSelectionChanged = (values: string[]) : void => {
		let extLoan = this.props.extLoan;
		if( values.length>0 ) {
			extLoan.loanOwners = values.map(x => {
				return {
					customerId: x
				}
			});
			this.ensureMaxNumberOfLoanOwners(extLoan);
		}
		else {
			extLoan.loanOwners = undefined;
		}
		this.extLoanService.update(extLoan);
	}

	getMaxNumberOfLoanOwners = (extLoan:ExtLoan) : number|undefined => {
		let maxNumberOfLoanOwners:number|undefined = undefined;
		if( extLoan.type && extLoan.type=="Studielån" ) {
			maxNumberOfLoanOwners = 1;
		}
		return maxNumberOfLoanOwners;
	}

	private ensureMaxNumberOfLoanOwners(extLoan: ExtLoan) {
		let maxNumberOfLoanOwners = this.getMaxNumberOfLoanOwners(extLoan);
		if (extLoan.loanOwners && maxNumberOfLoanOwners && maxNumberOfLoanOwners < extLoan.loanOwners.length) {
			extLoan.loanOwners.length = maxNumberOfLoanOwners;
		}
	}

	render() {
		const extLoanService = this.application.services.extLoanService;
		const c = this.props.case;
		const extLoan = this.props.extLoan;

		let uiExtLoan:UIExtLoanType|undefined;
		let extLoanTypeValue = "";
		if( extLoan.type!==undefined ) {
			uiExtLoan = uiExtLoanTypes.find(x => x.type==extLoan.type);
			if( uiExtLoan ) {
				extLoanTypeValue = uiExtLoan.key;
			}
		}
		const isStudentLoan = this.extLoanService.isStudentLoan(extLoan);

		let redeemValue;
		if( extLoan.redeemLoan===true )
			redeemValue = 'yes';
		else if( extLoan.redeemLoan===false )
			redeemValue = 'no';

		const household = this.householdService.getFirstHousehold(c);
		let ownersApplicants:Applicant[] = this.householdService.getHouseholdMembersApplicants(c, household)||[];
		let ownersCustomerIds:string[] = extLoan.loanOwners?extLoan.loanOwners.map(x=>{
			return x.customerId;
		}):[];

		const maxApplicantSelect:number|undefined = this.getMaxNumberOfLoanOwners(extLoan);
		const hasSingleOwnersApplicant = ownersApplicants.length === 1;

		return (
			<div className="ExtLoan">
				<FormUnit>
					<Label
						for={`extLoanType_${this.uqid}`}
						required={true}
						labelTextkey="Household_ExtLoan_Type"
					/>
					<Validation
						forObject={extLoan}
						errors={[
							{
								validator: extLoanService.hasType,
								textkey: 'Household_ExtLoan_SelectExtLoanType',
							},
						]}>
						<Select
							id={'extLoanType_' + this.uqid}
							value={extLoanTypeValue}
							options={this.extLoanTypeOptions}
							handleChange={(e) => {
								this.extLoanTypeChanged(e, extLoan);
							}}
							placeholderTextkey="Household_ExtLoan_SelectType"
							noSelectableOptionsTextkey="Select_NoOptions"
						/>
					</Validation>
				</FormUnit>
				{uiExtLoan && isStudentLoan && (
					<>
					<FormUnit>
						<Label
							for={`monthlyCost_${this.uqid}`}
							required={true}
							labelTextkey="Household_ExtLoan_MonthlyCost"
						/>
						<FormUnit size={FormUnitSize.Small} padding={FormUnitPadding.None}>
							<Validation
								forObject={extLoan}
								errors={[
									{
										validator: extLoanService.hasMonthlyCost,
										textkey: 'Household_ExtLoan_EnterExtStudentLoanMoCost',
									},
								]}>
									<Money
									id={`monthlyCost_${this.uqid}`}
									value={extLoan.monthlyCost}
									suffix=" kr/månad"
									min={0}
									handleChange={(e) => this.monthlyCostChanged(e, extLoan)}
								/>
							</Validation>
						</FormUnit>
					</FormUnit>
					</>
				)}
				{ uiExtLoan && !isStudentLoan && (
					<>
						<FormUnit>
							<Label
								for={`amount_${this.uqid}`}
								required={true}
								labelTextkey="Household_ExtLoan_Amount"
							/>
							<FormUnit size={FormUnitSize.Small} padding={FormUnitPadding.None}>
								<Validation
									forObject={extLoan}
									errors={[
										{
											validator: extLoanService.hasAmount,
											textkey: 'Household_ExtLoan_EnterExtLoanAmount',
										},
									]}>
									<Money
										id={`amount_${this.uqid}`}
										value={extLoan.amount}
										min={0}
										handleChange={(e) => this.amountChanged(e, extLoan)}
									/>
								</Validation>
							</FormUnit>
						</FormUnit>
						<FormUnit>
							<Label
								for={`redeem_${this.uqid}`}
								required={true}
								labelTextkey="Household_ExtLoan_Redeem"
							/>
							<Validation
								forObject={extLoan}
								errors={[
									{
										validator: extLoanService.hasRedeem,
										textkey: 'Household_ExtLoan_SelectExtLoanRedeem',
									},
								]}>
								<RadiobuttonGroup
									handleChange={this.redeemChanged}
									options={this.redeemOptions}
									value={redeemValue}
								/>
							</Validation>
						</FormUnit>
					</>
				)}
				{ uiExtLoan &&
				<FormUnit className={hasSingleOwnersApplicant ? 'FormUnit Hidden' : undefined}>
					<Label
						for={`owners_${this.uqid}`}
						required={true}
						labelTextkey="Household_ExtLoan_Owners"
					/>
					<Validation
						forObject={extLoan}
						errors={[
							{
								validator: extLoanService.hasValidLoanOwners,
								textkey: 'Household_ExtLoan_SelectExtLoanOwners',
							},
						]}>
						<ApplicantSelect
							applicants={ownersApplicants}
							max={maxApplicantSelect}
							selectedCustomerIds={ownersCustomerIds}
							selectionChanged={this.ownersSelectionChanged}
							autoSelectIfSingleApplicant={true}
						/>
					</Validation>
				</FormUnit>
				}
			</div>
		);
	}


}
