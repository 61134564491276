

export interface TaxedOwner {
	id: string; // guid
	customerId: string; // customerId of taxedOwner
}

/**
 * Säkerheter för lånet, om köp, även andra fastigheter kan användas som säkerhet för lånet
 * @export
 * @interface CollateralType
 */
export interface Collateral {
	//processId: ProcessId;
	id?: string; // collateralId, guid
	customerId?: string; // guid
	code?: string; // collateralCode
	name?: string; // collateralName
	taxedOwners?: TaxedOwner[];
	municipality?: string;
	street?: string;
	areal?: number;
	age?: number;
	useAsCollateral?: boolean;
	buyCollateral?: boolean;
	postponeCollateralCode?: boolean;
}



export interface Guarantor {
	//processId: string;
	/**
	 * TBD
	 * @type {string}
	 * @memberof GuarantorType
	 */
	id: string; //gaurantorId?: string;
	/**
	 * TBD
	 * @type {string}
	 * @memberof GuarantorType
	 */
	name?: string;
	/**
	 * TBD
	 * @type {string}
	 * @memberof GuarantorType
	 */
	phone?: string;
	/**
	 * TBD
	 * @type {string}
	 * @memberof GuarantorType
	 */
	guarantorCustomerId?: string;
}

export enum EUSupportEnum {
	EU = 1, // ', name:'EU-stöd'},
	EUNAT = 2, // ', name:'EU-stöd och nationellt stöd'},
	EUNAT_NOMILK = 3, //', name:'EU-stöd och nationellt stöd, utan mjölk'},
	NAT_NOMILK = 4, //', name:'Nationellt stöd, utan mjölk'},
};

export interface EUSupport {
	//processId: string;
	/**
	 * Unique identifier for EU-Support
	 * @type {string}
	 * @memberof EUSupportType
	 */
	id: string;//euId: string; 
	/**
	 * 
	 * @type {EUType}
	 * @memberof EUSupportType
	 */
	euType?: EUSupportEnum;
	/**
	 * summerat EU-stöd
	 * @type {number}
	 * @memberof EUSupportType
	 */
	supportAmount?: number;
	/**
	 * EU-stödet gäller år
	 * @type {string}
	 * @memberof EUSupportType
	 */
	supportYear?: string;
}



