import { BaseService } from './BaseService';
import { object } from 'prop-types';
import { ConfigurationService } from './ConfigurationService';

export class UiLanguage {
	code: string;
	texts: Map<string, string>;

	constructor(code: string) {
		this.code = code;
		this.texts = new Map<string, string>();
	}
}

export class UiLanguageService extends BaseService {
	url: string; // override
	underrideUrl: string; // underride

	constructor(private configurationService: ConfigurationService) {
		super();

		const config = configurationService.getConfiguration();

		this.url = config.smeBaseUrl + "/System/GetUiTexts?lang={CODE}";
		this.underrideUrl = "{CODE}.json";
	}

	private mapDataToUiLanguage = (code: string, result: any): UiLanguage => {
		let uiLanguage = new UiLanguage(code);
		uiLanguage.texts = result.texts;
		return uiLanguage;
	};

	load(code: string): Promise<UiLanguage> {
		return Promise.all([
			this.loadTexts(this.url, code),
			this.loadTexts(this.underrideUrl, code)
		]).then(result => {
			let uiLanguage: UiLanguage = result[1];
			if (result[0].texts) {
				Object.assign(uiLanguage.texts, result[0].texts);
			}
			return uiLanguage;
		})
	}

	loadTexts(urlPattern: string, code: string): Promise<UiLanguage> {
		let url = urlPattern.replace("{CODE}", code);
		let p = fetch(url);
		let p2 = p.then((result) => {
			return result.json();
		});
		let p3 = p2.then((result) => {
			return this.mapDataToUiLanguage(code, result);
		});
		return p3;
	}


}
