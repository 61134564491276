import React, { useState } from 'react';
import { ValidationResult } from '../../../../utils/ValidationResult';
import { MessageItem, Messages } from '../Messages/Messages';
import StepFormContext, { StepFormData } from '../StepForm/StepForm';
// import { eventNames } from 'cluster';

interface ErrorDefinition {
	validator: Function;
	textkey: string;
}

const createErrorItems = (
	result: ValidationResult,
	errors: ErrorDefinition[],
	forObject: any
): MessageItem[] => {
	// Get validation errors
	var errorItems: MessageItem[] = [];

	result.getItems().reduce((agg: MessageItem[], x) => {
		if (!x.ok) {
			let r = errors.filter(
				(y) => y.validator === x.validator && x.object === forObject
			);
			if (r.length > 0) {
				agg.push(r[0]);
			}
		}
		return agg;
	}, errorItems);

	return errorItems;
};

interface Props {
	validate?: boolean;
	forObject: any;
	localValidationResult?:ValidationResult;
	errors: ErrorDefinition[];
	immediate?: boolean; // show errors directly, dont wait for touch.
	touchValidation?: (stepFormData: StepFormData) => boolean;
}

export const Validation: React.FunctionComponent<Props> = (props: Props) => {
	// State
	const [touched, setTouched] = useState(false);
	const [dirty, setDirty] = useState(false);
	const [rendered, setRendered] = useState(new Date())

	const children = (props as any).children;

	if( props.validate===false) {
		return children; // dont perform validation.
	}

	// Event handlers
	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setDirty(true);

		if (children && (children as any).props.handleChange) {
			let dynprops = children as any;
			dynprops.props.handleChange(event);
		} else return false;
	};

	const handleBlurInner = (
		event: React.FocusEvent<HTMLInputElement>,
		stepFormData: StepFormData
	) => {
		setTouched(true);
		
		// Add component to object with touched elements
		stepFormData.touches[event.target.id]
			? (stepFormData.touches[event.target.id] = true)
			: (stepFormData.touches = {
					...stepFormData.touches,
					[event.target.id]: true,
			});

		// Run the childs own handleBlur event handler
		if (children && (children as any).props.handleBlur) {
			let dynprops = children as any;
			dynprops.props.handleBlur(event);
		} else return false;
	};

	return (
		<StepFormContext.Consumer>
			{(stepFormData) => {
				// Construct messages.
				let errorItems: MessageItem[] = [];

				if (stepFormData.context) {
					let validationResult = stepFormData.context.getValidation();
					if( props.localValidationResult ) {
						let vr = new ValidationResult();
						vr.addResult(validationResult);
						vr.addResult(props.localValidationResult);
						validationResult = vr;
					}
					errorItems = createErrorItems(
						validationResult,
						props.errors,
						props.forObject
					);
				}

				// Do we have any messages.
				let shouldBeImmidiated = stepFormData.immidiateAllValidatorsVisibleBefore != undefined 
					&& stepFormData.immidiateAllValidatorsVisibleBefore > rendered;
				let isTouchedOrImmidiated = touched || props.immediate === true || shouldBeImmidiated;

				const hasErrors = errorItems.length > 0 ? true : false;
				const showMessages =
					hasErrors && isTouchedOrImmidiated &&
					(!props.touchValidation || props.touchValidation(stepFormData));

				let handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
					handleBlurInner(event, stepFormData);
				};
				
				const cloneChildren = children as any;
				return (
					<>
						{children &&
							React.cloneElement(cloneChildren, {
								...cloneChildren.props,
								handleChange,
								handleBlur,
								touched : isTouchedOrImmidiated,
								dirty,
								hasErrors,
							})}
						
						<Messages items={errorItems} visible={showMessages} />
						
					</>
				);
			}}
		</StepFormContext.Consumer>
	);
};
