import { BaseService } from './BaseService';

export class IdService extends BaseService {

	private id: number;

	constructor() {
		super();
		this.id = 1;
	}

	newId(): number {
		return ++this.id;
	}

	newIdString(): string {
		return this.newId().toString();
	}

	newGuid() : string {
		return this.generateUUID();
	}

	//// Public Domain/MIT
	// From https://stackoverflow.com/questions/105034/create-guid-uuid-in-javascript
	private generateUUID() { 
		var d = new Date().getTime();
		if (typeof performance !== 'undefined' && typeof performance.now === 'function'){
			d += performance.now(); //use high-precision timer if available
		}
		return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
			var r = (d + Math.random() * 16) % 16 | 0;
			d = Math.floor(d / 16);
			return (c === 'x' ? r : ((r & 0x3) | 0x8)).toString(16);
		});
	}
}
