/* tslint:disable */
/* eslint-disable */
/**
 * SelmaSME API
 * This definition is the API between customer SME-Gui and the persistence layer for the application NOT FOR V1 When customer logg-in to Selma-EN, we will create a processId with applicant CustomerId (from BankID). When a customer added attachment to the application, we don\'t have a CaseId i LP, should we add a LP-Service to create/reserve a caseId to be able to attach a document to the caseId in DM.
 *
 * The version of the OpenAPI document: 0.7.4
 * Contact: developer@landshypotek.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MaintenanceHouseType,
    MaintenanceHouseTypeFromJSON,
    MaintenanceHouseTypeFromJSONTyped,
    MaintenanceHouseTypeToJSON,
} from './';

/**
 * Driftkostnader i övriga boenden
 * @export
 * @interface MaintenanceCostType
 */
export interface MaintenanceCostType {
    /**
     * application model unique identification
     * @type {string}
     * @memberof MaintenanceCostType
     */
    processId: string;
    /**
     * Unique identifier for maintenace cost
     * @type {string}
     * @memberof MaintenanceCostType
     */
    maintenanceCostId: string;
    /**
     * customerId is swedish ssn or corporate organisation number
     * @type {string}
     * @memberof MaintenanceCostType
     */
    customerId: string;
    /**
     * TBD
     * @type {Array<MaintenanceHouseType>}
     * @memberof MaintenanceCostType
     */
    typeofhouses?: Array<MaintenanceHouseType>;
}

export function MaintenanceCostTypeFromJSON(json: any): MaintenanceCostType {
    return MaintenanceCostTypeFromJSONTyped(json, false);
}

export function MaintenanceCostTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): MaintenanceCostType {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'processId': json['processId'],
        'maintenanceCostId': json['maintenanceCostId'],
        'customerId': json['customerId'],
        'typeofhouses': !exists(json, 'typeofhouses') ? undefined : ((json['typeofhouses'] as Array<any>).map(MaintenanceHouseTypeFromJSON)),
    };
}

export function MaintenanceCostTypeToJSON(value?: MaintenanceCostType | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'processId': value.processId,
        'maintenanceCostId': value.maintenanceCostId,
        'customerId': value.customerId,
        'typeofhouses': value.typeofhouses === undefined ? undefined : ((value.typeofhouses as Array<any>).map(MaintenanceHouseTypeToJSON)),
    };
}


