import React from 'react';
import { Applicant as SelmaApplicant, Case, Applicant } from '../../../../models/SelmaModels';
import { AppComponent } from '../../../AppComponent';
import { ApplicantContact } from './ApplicantContact';
import { ApplicantPep } from './ApplicantPep';
import { ApplicantApproval } from '../StepLoan/ApplicantApproval';
import { ApplicantIncome } from './ApplicantIncome';
import { UiModelConverter } from '../../../../models/UiModelConverter';
import { DeleteButton } from '../../../widget/input/DeleteButton/DeleteButton';
import { Heading } from '../../../widget/display/Heading/Heading';

interface Props {
	case: Case;
	applicant: SelmaApplicant;
	removeApplicant: (applicant: Applicant) => void;
}

export class PersonApplicantItem extends AppComponent<Props> {
	myApplicant: SelmaApplicant;

	constructor(props: Props) {
		super(props);
		this.observeUpdate(this.props.applicant);

		this.myApplicant = this.application.services.applicantService.getMyApplicant(this.props.case);
	}

	componentDidMount() {
		const personalEconomyService = this.application.services.personalEconomyService;
		personalEconomyService.ensurePersonalEconomy(
			this.props.case,
			this.props.applicant
		);
	}

	render() {
		const personalEconomyService = this.application.services.personalEconomyService;
		const applicantService = this.application.services.applicantService;
		const c = this.props.case;
		const applicant = this.props.applicant;
		const personalEconomy = personalEconomyService.getPersonalEconomy(c, applicant);
		const canRemove = applicantService.canRemoveApplicant(c, applicant); 
		const isMe = this.props.applicant===this.myApplicant;
		
		return (
			<div className="PersonApplicantItem">
				<div className="HeaderButton Stretch">
					<Heading level={2} labelText={`${applicant.firstName||''} ${applicant.lastName||''}`}/>
					{ canRemove && 
						<DeleteButton labelTextkey="Applicant_RemoveApplicant"onClick={() => {this.props.removeApplicant(applicant);}}/> 
					}
				</div>

				<div className="HeaderButton Stretch">
					<Heading level={3} labelText={UiModelConverter.prettySsn(applicant.customerId)}/>
				</div>

				<ApplicantContact applicant={this.props.applicant}/>
				{personalEconomy && 
					<ApplicantIncome applicant={this.props.applicant} case={this.props.case} personalEconomy={personalEconomy}/> 
				}
				{
					(applicantService.showPepForApplicant(this.props.applicant) && <ApplicantPep applicant={this.props.applicant}/>)
				}
				{ !isMe && 
					<ApplicantApproval applicant={this.props.applicant}/>
				}
			</div>
		);
	}
}
