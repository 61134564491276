
import React from 'react';
import { Case } from '../../../../models/SelmaModels';
import { AppComponent } from '../../../AppComponent';
import { THtml } from '../../../widget/display/THtml';
import { CheckboxText } from '../../../widget/input/CheckboxText/CheckboxText';
import { FormUnit, FormUnitSize } from '../../../layout/FormUnit/FormUnit';
import { Messages } from '../../../widget/display/Messages/Messages';
import { Validation } from '../../../widget/display/Validation/Validation';

interface Props {
	case: Case;
}

export class ApproveApplication extends AppComponent<Props> {
	constructor(props: Props) {
		super(props);
	}

	approveInformationHandlingChanged = (checked:boolean): void => {
		const c = this.props.case;
		const summaryService = this.application.services.summaryService;
		let summary = summaryService.ensureSummary(c);
		summary.approveInformationHandling = checked;
		summaryService.update(summary);
		summaryService.update(c);
	};

	guaranteeCorrectInformationChanged = (checked:boolean): void => {
		const c = this.props.case;
		const summaryService = this.application.services.summaryService;
		let summary = summaryService.ensureSummary(c);
		summary.guaranteeCorrectInformation = checked;
		summaryService.update(summary);
		summaryService.update(c);
	};

	render() {
		const c = this.props.case;
		const summaryService = this.application.services.summaryService;
		const approveInformationHandling = summaryService.hasCheckedSummaryApproveInformationHandling(c);
		const guaranteeCorrectInformation = summaryService.hasCheckedSummaryGuranteeCorrectInformation(c);
		return (
			<div className="ApproveApplication">
				<FormUnit size={FormUnitSize.Large}>
					<p><THtml k="Summary_Approve_Description"/></p>
					<Validation forObject={c} errors={[
						{ 
							textkey: "Summary_Approve_InformationHandling_Required", 
							validator: summaryService.hasCheckedSummaryApproveInformationHandling 
							}]}>
						<CheckboxText
							checked={approveInformationHandling}
							handleCheckedChange={this.approveInformationHandlingChanged}
							textKey="Summary_Approve_InformationHandling" />
					</Validation>
					<Validation forObject={c} errors={[
						{ 
							textkey: "Summary_Approve_CorrectInformation_Required", 
							validator: summaryService.hasCheckedSummaryGuranteeCorrectInformation 
						}]}>
						<CheckboxText
							checked={guaranteeCorrectInformation}
							handleCheckedChange={this.guaranteeCorrectInformationChanged}
							textKey="Summary_Approve_CorrectInformation" />
					</Validation>
				</FormUnit>
			</div>
		);
	}
}
