import React from 'react';
import './Loader.scss';
import { T } from '../T';
import { BirdLoader, gifBackground } from '../BirdLoader/BirdLoader';

interface Props {
	loaderTextKey?: string;
}
export const Loader: React.FunctionComponent<Props> = (props) => {

	return (
		<div className="Loader">
			<div className="LoaderAnimation">
				<BirdLoader gifBackground={gifBackground.botticelliBlue} />
			</div>
			{props.loaderTextKey ? (
				<div className="LoaderText">
					<T k={props.loaderTextKey} />
				</div>
			) : null}
		</div>
	);
};

// Default props
Loader.defaultProps = {
	loaderTextKey: undefined,
};
