import React, { Component } from 'react';
import { KYC } from '../../../../models/SelmaModels';
import { Applicant as SelmaApplicant } from "../../../../models/selmamodels/Applicant";
import { AppComponent } from '../../../AppComponent';
import { FormUnit, FormUnitSize } from '../../../layout/FormUnit/FormUnit';
import { CheckboxText } from '../../../widget/input/CheckboxText/CheckboxText';
import { Heading } from '../../../widget/display/Heading/Heading';
import { Validation } from '../../../widget/display/Validation/Validation';

interface Props {
	applicant: SelmaApplicant;
}

export class ApplicantApproval extends AppComponent<Props> {
	constructor(props: Props) {
		super(props);
		this.observeUpdate(this.props.applicant);
	}

	componentDidMount() {
		this.ensureKyc();
	}

	// ensureKyc() : KYC {
	// 	if (!this.props.applicant.kyc) {
	// 		this.props.applicant.kyc = {
	// 			id: this.application.services.idService.newGuid(),
	// 			approvedCreditCheck: false,
	// 			approvedInfoHandling: false,
	// 			approvedInfoSharing: false
	// 		};
	// 		this.observeUpdate(this.props.applicant.kyc);
	// 	}
	// 	return this.props.applicant.kyc;
	// }

	ensureKyc() : KYC {
		const applicantService = this.application.services.applicantService; 
		const kyc = applicantService.ensureKyc(this.props.applicant);
		return kyc;
	}

	onCreditCheckChange = (checked:boolean): void => {
		const kyc = this.ensureKyc();
		kyc.approvedCreditCheck = checked;
		this.application.services.applicantService.update(this.props.applicant);
	};
	onInfoHandlingChange = (checked: boolean): void => {
		const kyc = this.ensureKyc();
		kyc.approvedInfoHandling = checked;
		this.application.services.applicantService.update(this.props.applicant);
	};
	onInfoSharingChange = (checked: boolean): void => {
		const kyc = this.ensureKyc();
		kyc.approvedInfoSharing = checked;
		this.application.services.applicantService.update(this.props.applicant);
	};
	render() {
		const applicant = this.props.applicant;
		const kyc = applicant.kyc;
		const applicantService = this.application.services.applicantService;
		let canEdit = true;
		if( kyc ) {
			if( kyc.approvedCreditCheckDB===true
				&& kyc.approvedInfoHandlingDB===true
				&& kyc.approvedInfoSharingDB===true) {
					canEdit = false;
				}
		}

		return (
			<div className="ApplicantApproval">
				{ kyc &&
				<FormUnit size={FormUnitSize.Large}>
					<Heading level={2} labelTextkey="Loan_ApplicantApproval"/>
					<br/>
					<Validation forObject={applicant}
						errors={[
							{
								validator: applicantService.hasKycApprovedCreditCheck ,
								textkey: 'Applicant_Approval_CreditCheck_Required',
							}
						]}>
						<CheckboxText
							checked={kyc.approvedCreditCheck}
							handleCheckedChange={this.onCreditCheckChange}
							textIsHtml={true}
							enabled={canEdit}
							textKey="Applicant_Approval_CreditCheck" />
					</Validation>
					<Validation forObject={applicant} 
						errors={[
							{
								validator: applicantService.hasKycApprovedInfoHandling ,
								textkey: 'Applicant_Approval_CreditCheck_Required',
							}
						]}>
						<CheckboxText
							checked={kyc.approvedInfoHandling}
							handleCheckedChange={this.onInfoHandlingChange}
							textIsHtml={true}
							enabled={canEdit}
							textKey="Applicant_Approval_InfoHandling" />
						</Validation>
					<Validation forObject={applicant} 
						errors={[
							{
								validator: applicantService.hasKycApprovedInfoSharing ,
								textkey: 'Applicant_Approval_CreditCheck_Required',
							}
						]}>
						<CheckboxText
							checked={kyc.approvedInfoSharing}
							handleCheckedChange={this.onInfoSharingChange}
							textIsHtml={true}
							enabled={canEdit}
							textKey="Applicant_Approval_InfoSharing" />
					</Validation>
				</FormUnit>
				}
			</div>
		);
	}
}
