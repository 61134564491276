/* eslint-disable no-control-regex */
import { BaseService } from './BaseService';
import { ConvertService } from './ConvertService';

export class GuardService extends BaseService {

	convertService?:ConvertService;

	// http://emailregex.com/ General Email Regex (RFC 5322 Official Standard)
	emailPattern = /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i;

	// https://sv.wikipedia.org/wiki/Diakritiskt_tecken
	latinUnicodeDiacritics = "ÀÁÂÃÄÅÇÈÉÊËÌÍÎÏÑÒÓÔÕÖØÙÚÛÜÝàáâãäåçèéêëìíîïñòóôõöøùúûüýÿĀāĂăĄąĆćĈĉĊċČčĎďĐđĒēĔĕĖėĘęĚěĜĝĞğĠġĢģĤĥĦħĨĩĪīĬĭĮįİĴĵĶķĹĺĻļĽľĿŀŁłŃńŅņŇňŉŌōŎŏŐőŔŕŖŗŘřŚśŜŝŞşŠšŢţŤťŦŧŨũŪūŬŭŮůŰűŲųŴŵŶŷŸŹźŻżŽŽƂƃƇƈƋƌƑƒƓƘƙƝƠơƣƤƥƨƫƬƭƮƯưƳƴƵƶǍǎǏǐǑǒǓǔǕǖǗǘǙǚǛǜǝǞǟǠǡǤǥǦǧǨǩǪǫǬǭǰǴǵǸǹǺǻǾǿȀȁȂȃȄȅȆȇȈȉȊȋȌȍȎȏȐȑȒȓȔȕȖȗȘșȚțȞȟȤȥȦȧȨȩȪȫȬȭȮȯȰȱȲȳḀḁḂḃḄḅḆḇḈḉḊḋḌḍḎḏḐḑḒḓḔḕḖḗḘḙḚḛḜḝḞḟḠḡḢḣḤḥḦḧḨḩḪḫḬḭḮḯḰḱḲḳḴḵḶḷḸḹḺḻḼḽḾḿṀṁṂṃṄṅṆṇṈṉṊṋṌṍṎṏṐṑṒṓṔṕṖṗṘṙṚṛṜṝṞṟṠṡṢṣṤṥṦṧṨṩṪṫṬṭṮṯṰṱṲṳṴṵṶṷṸṹṺṻṼṽṾṿẀẁẂẃẄẅẆẇẈẉẊẋẌẍẎẏẐẑẒẓẔẕẖẗẘẙẚẠạẢảẤấẦầẨẩẪẫẬậẮắẰằẲẳẴẵẶặẸẹẺẻẼẽẾếỀềỂểỄễỆệỈỉỊịỌọỎỏỐốỒồỔổỖỗỘộỚớỜờỞởỠỡỢợỤụỦủỨứỪừỬửỮữỰựỲỳỴỵỶỷỸỹ";
	emailNegPattern = new RegExp('[' + this.latinUnicodeDiacritics + ']');

	// === Type checks =========
	isString(val: any): boolean {
		return typeof(val)==="string" || val instanceof String;
	}
	isNumber(val: any): boolean {
		return typeof(val)==="number";
	}
	isObject(val: any): boolean  {
		return typeof(val)==="object";
	}

	// === Undefined (=undef) =========
	isUndefined(val: any): boolean {
		return typeof (val) === "undefined";
	}
	isUndefinedOrEmpty(val: string | Array<any> | undefined): boolean {
		return this.isUndefined(val) || !val || val.length === 0;
	}
	isUndefinedOrLessThanZero(val: number): boolean {
		return this.isUndefined(val) || val < 0;
	}
	isDefined(val: any): boolean {
		return typeof (val) !== "undefined";
	}

	// === Unvalued (=undef or =null) =========
	isUnvalued(val: any): boolean {
		return this.isUndefined(val) || val === null;
	}
	isUnvaluedOrFalse(val: boolean | undefined): boolean {
		return this.isUndefined(val) || val === null || val === false;
	}
	isUnvaluedOrTrue(val: boolean | undefined): boolean {
		return this.isUndefined(val) || val === null || val === true;
	}
	isUnvaluedOrEmpty(val: string | Array<any> | undefined): boolean {
		return this.isUndefined(val) || val === null || val===undefined || val.length === 0;
	}
	isUnvaluedOrLessThanZero(val: number | undefined): boolean {
		return this.isUndefined(val) || val === null || val===undefined || val < 0;
	}
	isUnvaluedOrLessThan(val: number | undefined, limit: number): boolean {
		return this.isUndefined(val) || val === null || val===undefined || val < limit;
	}
	isUnvaluedOrZero(val: any): boolean {
		return this.isUndefined(val) || val === null || val === 0;
	}

	hasLengthGreaterThan(list: string | Array<any> | undefined, limit: number): boolean {
		if( !list ) return false;
		if( this.isString(list) )
			return list.length>limit;
		else 
			return list.length>limit;
	}
	hasLengthGreaterThanZero(list: string | Array<any> | undefined): boolean {
		return this.hasLengthGreaterThan(list,0);
	}
	hasLengthLessThan(list: string | Array<any> | undefined, limit: number): boolean {
		if( !list ) return false;
		if( this.isString(list) )
			return list.length<limit;
		else 
			return list.length<limit;
	}

	// === Valued (!=undef and !=null) =========
	hasValue(val: any): boolean {
		return this.isDefined(val) && val !== null;
	}

	// === Compare =========
	isGreaterThan(val: number|undefined, limit: number): boolean {
		return this.isDefined(val) && val!==undefined && val !== null && val > limit;
	}
	isGreaterThanOrEqual(val: number|undefined, limit: number): boolean {
		return this.isDefined(val) && val!==undefined && val !== null && val >= limit;
	}
	isInRange(val: number|undefined, min: number, max: number): boolean {
		return this.isDefined(val) && val!==undefined&& val !== null && val >= min && val <= max;
	}
	isGreaterThanZero(val: number|undefined): boolean {
		return this.isDefined(val) && val!==undefined&& val !== null && val > 0;
	}
	isZeroOrGreater(val: number|undefined): boolean {
		return this.isDefined(val) && val!==undefined && val !== null && val >= 0;
	}

	// === Validations =====
	isValidEmail(str: string): boolean {
		if (this.isUnvaluedOrEmpty(str))
			return false;
		if (this.emailNegPattern.test(str))
			return false;
		return this.emailPattern.test(str);
	}
	isValidSsn(str: string): boolean {
		if (this.isUnvaluedOrEmpty(str))
			return false;

		// Remove non normalized chars.
		str = str.replace("-", "");

		// Check for invalid chars.
		// const badChars = (/\D/g).test(str);
		// if(badChars) {
		// 	return false;
		// }

		// Remove century and check number
		if (str.length === 12) {
			str = str.substr(2, 10);
		} else if (str.length === 10) {
			str = str.substr(0, 10);
		} else {
			return false;
		}

		var year = parseInt(str.substr(0, 2), 10);
		var month = parseInt(str.substr(2, 2), 10) - 1;
		var day = parseInt(str.substr(4, 2), 10);

		var date = new Date(year, month, day);
		var dateYear = parseInt(date.getFullYear().toString().substr(2, 2));
		if (dateYear !== year || date.getMonth() !== month || date.getDate() !== day) {
			return false;
		}

		// Remove check number
		var check = parseInt(str.substr(9, 1), 10);
		str = str.substr(0, 9);

		// Calculate check number
		var result = 0;
		for (var i = 0, len = str.length; i < len; i++) {

			var number = parseInt(str.substr(i, 1), 10);

			// Multiply every other number with two
			if ((i % 2) === 0) {
				number = (number * 2);
			}

			// If result is greater than 10, 'sum the digits' which is the same as 1 + (number mod 10)
			if (number > 9) {
				result += (1 + (number % 10));
			} else {
				result += number;
			}
		}

		return (((check + result) % 10) === 0);

	}
	isValidOrgNr(str: string): boolean {
		if (this.isUnvaluedOrEmpty(str))
			return false;

		// Remove non numeric
		str = str.replace(/\D/g, "");

		// Remove century and check number
		if (str.length !== 10) {
			return false;
		} 

		// Remove check number
		var check = parseInt(str.substr(9, 1), 10);
		str = str.substr(0, 9);

		// Calculate check number
		var result = 0;
		for (var i = 0, len = str.length; i < len; i++) {

			var number = parseInt(str.substr(i, 1), 10);

			// Multiply every other number with two
			if ((i % 2) === 0) {
				number = (number * 2);
			}

			// If result is greater than 10, 'sum the digits' which is the same as 1 + (number mod 10)
			if (number > 9) {
				result += (1 + (number % 10));
			} else {
				result += number;
			}
		}

		return (((check + result) % 10) === 0);

	}
	isValidMobileNumber(str: string): boolean {
		/*
		0701234567
		070-1234567
		*/
		if (this.isUnvaluedOrEmpty(str))
			return false;

		const pattern = /^(07\d{1}-\d{7})$|^(07\d{8})$/;

		return pattern.test(str);
	}

	isDate(value: any): boolean {
		if (value && value.getDate)
			return true;
		return false;
	}

	isValidDate(value: any): boolean {
		if (!this.isDate(value))
			return false;
		if (isNaN(value.getTime()))
			return false;
		return true;
	}

	isBeforeDate(date: Date, dateLimit: Date): boolean {
		if (!this.isDate(date))
			return false;
		if (!dateLimit)
			return true;
		return date < dateLimit;
	}

	isAfterDate(date: Date, dateLimit: Date): boolean {
		if (!this.isDate(date))
			return false;
		if (!dateLimit)
			return true;
		return date > dateLimit;
	}

	isBeforeNow(date: Date): boolean {
		return this.isBeforeDate(date, new Date());
	}

	isAfterNow(date: Date): boolean {
		return this.isAfterDate(date, new Date());
	}

	compareSsn(ssn1: string, ssn2: string): boolean {
		if (!ssn1 || !ssn2)
			return false;
		if (ssn1.replace("-", "") === ssn2.replace("-", ""))
			return true;
		return false;
	}

	normalizeAndCompareSsn(ssn1: string, ssn2: string): boolean {
		if (!ssn1 || !ssn2)
			return false;
		if( this.convertService ) {
			ssn1 = this.convertService.toNormalizedSsn(ssn1);
			ssn2 = this.convertService.toNormalizedSsn(ssn2);
		}

		if (ssn1===ssn2)
			return true;
		return false;
	}

	compareCustomerId(customerId1: string , customerId2: string): boolean {
		return this.normalizeAndCompareSsn(customerId1, customerId2);
	}
}