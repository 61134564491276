import { Case, Company, Loan, Aim, Applicant, ExtLoan, ExtLoanOwner, Household, EUSupport,
	EUSupportEnum as SmeEUSupportType, 
	BudgetYear as SmeBudgetYear,
	LPEmploymentEnum, HouseholdMember,
	CompanyEconomy,
	Budget,
	KYC,
	MaintenanceCost,
	MaintenanceHouse,
	Guarantor,
	Child,
	Revenue,
	Collateral,
	TaxedOwner,
	LoanOwner,
	DownpaymentSource,
	BusinessCategory,
	AttachmentRequest,
	Attachment,
	Principal} from "./SelmaModels";
import { CompanyType, LoanType, AimType, KycInformationType, ApplicantType, 
	ExtLoanType, HouseholdMemberType, EUSupportType, EUType, PersonalEconomyType, 
	TypeOfEmployeement, CompanyEconomyType, RevenueType, BudgetType,  
	MaintenanceCostType, MaintenanceHouseType, ChildType, 
	TaxedOwnerType, ProcessType, DownPaymentType, BusinessCategoryType, BudgetYearType, PrincipalType, TakeoverLoanType, TakeoverLoansType } from "../libs/SMELPOClient";
import { HouseholdType } from '../libs/SMELPOClient/models/HouseholdType';
import { CollateralType } from '../libs/SMELPOClient/models/CollateralType';
import { PersonalEconomy } from './selmamodels/Applicant';
import { GuarantorType } from '../libs/SMELPOClient/models/GuarantorType';
import { LoanOwnerType } from "../libs/SMELPOClient/models/LoanOwnerType";
import { FullProcess } from "./extendedlpo/FullProcess";
import { LpoAttachmentRequest, LpoAttachment } from "./extendedlpo/Attachment";
import { TakeoverLoan } from "./selmamodels/Loan";

// Takes Selma SME client models and converts to other models such as Lpo server API models.
export class SmeModelConverter {

	static toFullProcess(c:Case) : FullProcess {
		let fp:FullProcess = {
			processes: [this.toLpoProcess(c)],
			companies: this.toLpoCompanies(c, c.companies),
			loans: this.toLpoLoans(c, c.loan),
			applicants: this.toLpoApplicants(c, c.applicants),
			extloans: this.toLpoExtLoans(c, c.extLoans),
			households: this.toLpoHouseholds(c, c.households),
			collaterals: this.toLpoCollaterals(c, c.collaterals),
			eusupports: this.toLpoEuSupports(c, c.euSupports),
			personaleconomies: this.toLpoPersonalEconomies(c, c.personalEconomies),
			companyeconomies: this.toLpoCompanyEconomies(c, c.companyEconomies),
			budgets: this.toLpoBudgets(c, c.budgets),
			kycinformations: this.toLpoKycInfos(c, c.applicants),
			guarantors: this.toLpoGuarantors(c, c.guarantors),
			maintenancecosts: this.toLpoMaintenanceCosts(c, c.maintenanceCosts),
		};

		(fp as any).attachments = this.toLpoAttachments(c, c.attachments);
		(fp as any).attachmentRequests = this.toLpoAttachmentRequests(c, c.attachmentRequests);

		return fp;
	}
	static toLpoAttachmentRequests(c:Case, attachmentRequests: AttachmentRequest[]|undefined): LpoAttachmentRequest[] | undefined {
		if( !attachmentRequests) return;
		return attachmentRequests.map(x => {
			return {
				id: x.id,
				processId: c.id,
				customerId: x.customerId,
				requestType: x.requestType,
				requestVariation: x.requestVariation,
				required: x.required
			}
		})
	}
	static toLpoAttachments(c:Case, attachments: Attachment[]| undefined): LpoAttachment[] | undefined {
		if( !attachments) return;
		return attachments.map(x => {
			return {
				id: x.id,
				processId: c.id,
				customerId: x.customerId,
				requestType: x.requestType,
				requestVariation: x.requestVariation,
				mimeType: x.mimeType,
				fileName: x.fileName,
				fileSize: x.fileSize
			}
		})
	}
	static toLpoMaintenanceCosts(c: Case, maintenanceCosts: MaintenanceCost[] | undefined): MaintenanceCostType[] | undefined {
		if( !maintenanceCosts ) return;
		return maintenanceCosts.map(x => {
			return this.toLpoMaintenanceCost(c, x);
		})
	}
	static toLpoMaintenanceCost(c: Case, x: MaintenanceCost): MaintenanceCostType {
		return {
			processId: c.id,
			maintenanceCostId: x.id,
			customerId: x.customerId,
			typeofhouses: this.toLpoMaintenanceHouses(c, x.typeofhouses)
		}
	}
	static toLpoMaintenanceHouses(c:Case, maintenanceHouses: MaintenanceHouse[] | undefined):MaintenanceHouseType[] | undefined {
		if( !maintenanceHouses ) return;
		return maintenanceHouses.map(x => {
			return this.toLpoMaintenanceHouse(c, x);
		})
	}
	static toLpoMaintenanceHouse(c: Case, x: MaintenanceHouse): MaintenanceHouseType {
		return {
			typeOfHouse: x.houseType, // this.toLpoTypeOfHouse(x.houseType),
			keepHouse: x.keepHouse,
			loanInOtherInstitute: x.loanInOtherInstitute,
			redeemLoan: x.redeemLoan,
			// creditInstitut: x. // NOT IN USE
			// loanClearing: x // NOT IN USE
			//institutLoanNumber?: string; // NOT IN USE
			loanOwners: this.toLpoLoanOwners(x.loanOwners),
			houseLoanAmount: x.houseLoanAmount,
			maintenanceCost: x.maintenanceCost
		}
	}
	static toLpoLoanOwners(loanOwners: LoanOwner[] | undefined): LoanOwnerType[] | undefined {
		if( !loanOwners ) return;
		return loanOwners.map(x => {
			return {
				customerId: x.customerId,
				ownershipPart: x.ownershipPart
			}
		})
	}
	// static toLpoTypeOfHouse(houseType: string | undefined): TypeOfHouse | undefined {
	// 	if( !houseType|| houseType=='') return;
	// 	if( houseType=='HOUSE') return TypeOfHouse.Villa;
	// 	if( houseType=='OTHER') return TypeOfHouse.VrigtBoende;
	// 	if( houseType=='TENANCY') return TypeOfHouse.Hyresrtt;
	// 	if( houseType=='FARM') return TypeOfHouse.Landbruksfastighet;
	// 	if( houseType=='APARTMENT') return TypeOfHouse.Bostadsrtt;
	// 	if( houseType=='VACATIONHOUSE') return TypeOfHouse.Fritidshus;
	// }
	static toLpoGuarantors(c: Case, guarantors: Guarantor[] | undefined): GuarantorType[] | undefined {
		if( !guarantors ) return;
		return guarantors.map(x => {
			return this.toLpoGuarantor(c, x);
		})
	}
	static toLpoGuarantor(c: Case, x: Guarantor): GuarantorType {
		return {
			processId: c.id,
			guarantorId: x.id,
			guarantorName: x.name,
			guarantorPhone: x.phone,
			guarantorCustomerId: x.guarantorCustomerId
		}
	}
	static toLpoKycInfos(c: Case, applicants: Applicant[] | undefined): KycInformationType[] | undefined {
		if( !applicants ) return;
		return applicants
		.filter(x => x.kyc?true:false)
		.map(x => {
			const kyc: KYC = x.kyc as KYC;
			return this.toLpoKycInfo(c, x, kyc);
		});
	}
	static toLpoKycInfo(c: Case, x: Applicant, kyc: KYC): KycInformationType {
		return {
			processId: c.id,
			customerId: x.customerId,
			kycId: kyc.id,
			kycAcceptUC: kyc.approvedCreditCheck,
			kycAcceptGDPR: kyc.approvedInfoHandling,
			kycUCAware: kyc.approvedInfoSharing,
			kycPublicFunction: kyc.isPep,
			kycRelatedPublicFunction: kyc.isPepRelated,
			kycAmericanCitizen: kyc.isAmericanCitizen,
			kycCrsTaxObligation: kyc.isCrsTaxObligated,
			kycHighRiskIndustry: (!kyc.highRiskCategoryCode || kyc.highRiskCategoryCode==='' || kyc.highRiskCategoryCode==='0')?false:true,
			kycHighRiskIndustryText: kyc.highRiskCategoryCode
		}
	}

	static toLpoBudgets(c: Case, budgets: Budget[] | undefined): BudgetType[] | undefined {
		if( !budgets) return;
		return budgets
		.map(x =>  {
			return this.toLpoBudget(c, x)
		})
	}
	static toLpoBudget(c: Case, budget: Budget): BudgetType {
		return {
			processId: c.id,
			companyEconomyId: budget.companyEconomyId,
			budgetYears: this.toLpoBudgetYears(c, budget.budgetYears)
		}
	}
	static toLpoBudgetYears(c: Case, budgetYears: SmeBudgetYear[] | undefined): BudgetYearType[]  | undefined {
		if( !budgetYears ) return;
		return budgetYears.map(x => {
			return this.toLpoBudgetYear(c, x);
		})
	}
	static toLpoBudgetYear(c: Case, x: SmeBudgetYear): BudgetYearType {
		return {
			budgetId: x.budgetId,
			budgetyear: x.year,
			budget: {
				value1:  x.value1,
				value2:  x.value2,
				value3:  x.value3,
				value4:  x.value4,
				value5:  x.value5,
				value6:  x.value6,
				value7:  x.value7,
				value8:  x.value8,
				value9:  x.value9,
				value10: x.value10,
				value11: x.value11,
				value12: x.value12,
				value13: x.value13,
				value14: x.value14,
				value15: x.value15,
				value16: x.value16,
				value17: x.value17,
				value18: x.value18,
				value19: x.value19,
				value20: x.value20,
				value21: x.value21,
				value22: x.value22,
				value23: x.value23,
				value24: x.value24,
				value25: x.value25
			}
		}
	}
	static toLpoCompanyEconomies(c: Case, companyEconomies: CompanyEconomy[] | undefined):
		CompanyEconomyType[] | undefined {
		if( !companyEconomies )
			return;
		return companyEconomies.map(x => {
			return this.toLpoCompanyEconomy(c, x);
		})
	}
	static toLpoCompanyEconomy(c: Case, x: CompanyEconomy): CompanyEconomyType {
		return {
			processId: c.id,
			companyId: x.companyId,
			companyEconomyId: x.id,
			revenues: this.toLpoRevenues(x.revenues), // TODO must be fixed by Kjell. NOT IN USE.
			customerCategory: x.customerCategory
		}
	}
	static toLpoRevenues(revenues: Revenue[] | undefined) : RevenueType[]|undefined {
		if( !revenues ) return;
		return revenues.map(x => {
			return {
				revenueYear: x.year,
				revenue: x.revenue||0
			};
		})
	}
	static toLpoPersonalEconomies(c: Case, personalEconomies: PersonalEconomy[] | undefined): PersonalEconomyType[] | undefined {
		if( !personalEconomies )
			return;
		return personalEconomies.map(x => {
			return this.toLpoPersonalEconomy(c, x);
		})
	}
	static toLpoPersonalEconomy(c: Case, x: PersonalEconomy): PersonalEconomyType {
		return {
			processId: c.id,
			customerId: x.customerId,
			personalEconomyId: x.id,
			yearlyIncome: x.yearlyIncome,
			income: x.income,
			typeOfEmployeement: this.toLpoLPEmploymentEnum(x.typeOfEmployment),
			employeer: x.employer,
			yearOfEmployment: x.yearOfEmployment,
			monthOfEmployment: x.monthOfEmployment
		}
	}

	static toLpoLPEmploymentEnum(typeOfEmployeement: LPEmploymentEnum | undefined): TypeOfEmployeement  | undefined {
		const t = typeOfEmployeement;
		if( t===undefined )
			return;

		if( t===LPEmploymentEnum.PERMANENT ) return TypeOfEmployeement.PERMANENT; // - Anställd, tills-vidare-anställd 
		if( t===LPEmploymentEnum.TEMPORARY ) return TypeOfEmployeement.TEMPORARY; // - Tidbegränsad anställning 
		if( t===LPEmploymentEnum.PROBATIONARY ) return TypeOfEmployeement.PROBATIONARY; // - Provanställning 
		if( t===LPEmploymentEnum.UNEMPLOYMENT_INSURANCE ) return TypeOfEmployeement.UNEMPLOYMENTINSURANCE; // - Arbetslöshetskassa 
		if( t===LPEmploymentEnum.SELF_EMPLOYED ) return TypeOfEmployeement.SELFEMPLOYED; //- Eget företag 
		if( t===LPEmploymentEnum.STUDENT_AID ) return TypeOfEmployeement.STUDENTAID; // - Studiestöd 
		if( t===LPEmploymentEnum.PENSION) return TypeOfEmployeement.PENSION; // - Inkomst av pension
	}
	static toLpoEuSupports(c:Case, euSupports: EUSupport[] | undefined): EUSupportType[] | undefined {
		if( !euSupports )
			return;
		return euSupports.map(x => {
			return this.toLpoEuSupport(c, x);
		})
	}
	static toLpoEuSupport(c:Case, euSupport: EUSupport): EUSupportType {
		const e = euSupport;
		return {
			euId: e.id,
			processId: c.id,
			euType: this.toEUSupportType(e.euType),
			supportAmount: e.supportAmount,
			supportYear: e.supportYear,
		}
	}
	static toEUSupportType(euType: SmeEUSupportType | undefined): EUType | undefined {
		const t = euType;
		if( t===undefined)
			return;
		if( t===SmeEUSupportType.EU)
			return EUType.EUStd;
		if( t===SmeEUSupportType.EUNAT)
			return EUType.EUStdOchNationelltStd;
		if( t===SmeEUSupportType.EUNAT_NOMILK)
			return EUType.EUStdOchNationelltStdUtanMjlk;
		if( t===SmeEUSupportType.NAT_NOMILK)
			return EUType.NationellStdUtanMjlk;
	}
	static toLpoCollaterals(c: Case, collaterals: Collateral[] | undefined): CollateralType[] | undefined {
		if( !collaterals) return;
		return collaterals.map(x => {
			return this.toLpoCollateral(c, x);
		})
	}
	static toLpoCollateral(c: Case, x: Collateral): CollateralType {
		return {
			processId: c.id,
			customerId: x.customerId,
			//typeOfCollateral: x.t;
			collateralId: x.id,
			collateralCode: x.code,
			collateralName: x.name,
			taxedOwners: this.toLpoTaxedOwners(x.taxedOwners),
			collateralMunicipality: x.municipality,
			collateralStreet: x.street,
			collateralAreal: x.areal,
			//collateralAge: x.age, // NOT IN USE.
			useAsCollateral: x.useAsCollateral,
			buyCollateral: x.buyCollateral
		}
	}
	static toLpoTaxedOwners(taxedOwners: TaxedOwner[] | undefined): TaxedOwnerType[] | undefined {
		if( !taxedOwners) return;
		return taxedOwners.map(x => {
			return {
				taxedOwnerId: x.id,
				taxedOwner: x.customerId
			}
		})
	}
	static toLpoHouseholds(c: Case, households: Household[] | undefined): HouseholdType[] | undefined {
		if( !households ) return;
		return households.map(x=>{
			return this.toLpoHousehold(c, x);
		})
	}
	static toLpoHousehold(c: Case, x: Household):HouseholdType {
		return  {
			processId: c.id,
			householdId: c.id,
			householdMembers: this.toLpoHouseholdMembers(c, x.members),
			numberOfChildsAtHome: x.numberOfChildrenAtHome,
			childs: this.toLpoChildren(x.children),
			numberOfCars: x.numberOfCars,
			childMaintenaceCost: x.childMaintenanceCost,
			receiveChildMaintenanceCost: x.childMaintenanceIncome,
			childCareAmount: x.childCareAmount
		}
	}
	static toLpoHouseholdMembers(c:Case, members: HouseholdMember[]|undefined): HouseholdMemberType[] | undefined {
		if( !members ) return;
		return members.map(x => {
			return this.toLpoHouseholdMember(x);
		})
	}
	static toLpoHouseholdMember(x: HouseholdMember): HouseholdMemberType {
		return {
			householdMember: x.householdMember
		}
	}
	static toLpoChildren(children: Child[] | undefined): ChildType[] | undefined {
		if( !children ) return;
		return children.map(x => {
			return this.toLpoChild(x);
		})
	}
	static toLpoChild(x: Child): ChildType {
		return {
			childId: x.id,
			childsAge: x.childsAge,
			partInHousehold: x.partInHousehold
		}
	}
	static toLpoExtLoans(c:Case, extLoans:ExtLoan[] | undefined): ExtLoanType[] | undefined {
		if( !extLoans) return;
		return extLoans.map(x => {
			return this.toLpoExtLoan(c, x);
		});
	}
	static toLpoExtLoan(c:Case, x:ExtLoan) : ExtLoanType {
		return {
			processId: c.id,
			extloanId: x.id,
			typeOfLoan: x.type,
			extLoanOwners: this.toExtLoanOwnersCustomerIds(x.loanOwners),
			extCreditInstitute: x.creditInstitute,
			extloanClearing: x.loanClearing,
			extloanNumber: x.loanNumber,
			extloanAmount: x.amount,
			extRedeemLoan: x.redeemLoan,
			extMonthlyCost: x.monthlyCost
		}
	}
	static toExtLoanOwnersCustomerIds(loanOwners: ExtLoanOwner[] | undefined): string[] | undefined {
		if( !loanOwners) return;
		return loanOwners.map(x=>{ return x.customerId});
	}
	static toLpoProcess(c: Case): ProcessType {
		return {
			processId: c.id,
			customers: c.customers, // DIRECT COPY.
			referenceId: c.number,
			processVersion: c.version,
			caseId: c.lpCaseId,
			caseIdStatus: c.status,
			processCreatedDate: this.toDateString(c.createdDate),
			lastAccessed: this.toDateString(c.lastAccessedDate)
		}
	}
	static toDateString(date: Date): string {
		if( !date || (date as any)==='' ) return '';
		return date.toISOString();
	}
	static toLpoCompanies(c: Case, companies: Company[] | undefined): CompanyType[] | undefined {
		if( !companies )
			return;
		let result = companies.map(co => {
			let company:CompanyType =  {
				processId: c.id,
				companyId: co.id,
				orgNumber: co.orgNumber,
				companyName: co.name,
				statusCode: co.statusCode,
				//statusTextHigh: co.statusTextHigh,
				created: this.toYYYY_MM_DD(co.created),
				businessFocuses: this.toLpoBusinessCategories(co.businessFocuses), // DIRECT COPY.
				industriCode: co.industriCode,
				industriText: co.industriText,
				leagalGroupCode: co.legalGroupCode,
				legalGroupText: co.legalGroupText,
				brokenFiscalYear: co.brokenFiscalYear,
				fiscalYearEndDate: co.fiscalYearEndDate,
				principals: this.toLpoPrincipals(co.principals)

				//streetAddress: co.streetAddress,
				//postalAddress: co.postalAddress,
				//postalCode: co.postalCode,
				//selectedCompany: co.selectedCompany,
			}
			return company;
		})
		return result;
	}
	static toYYYY_MM_DD(d:Date|undefined):string|undefined  {
		if( !d )
			return;

		return new Date(d.getTime() - (d.getTimezoneOffset() * 60000 ))
			.toISOString()
			.split("T")[0];
	}
	
	static toLpoPrincipals(principals: Principal[] | undefined): PrincipalType[] | undefined {
		if( !principals ) return;
		return principals.map(x => {
			return {
				principalName: x.name,
				customerId: x.customerId
			}
		});
	}
	static toLpoBusinessCategories(businessFocuses: BusinessCategory[] | undefined): BusinessCategoryType[] | undefined {
		if( !businessFocuses) return;
		return businessFocuses.map(x => {
			return {
				businessId: x.id,
				businessCategory:x.businessCategoryCode,
				businessPart: x.businessPart,
				mainBusiness: x.mainDirection 
			}
		})
	}
	static toLpoLoans(c:Case, loan: Loan | undefined):LoanType[]|undefined {
		if( !loan )
			return;
		let result:LoanType = {
			processId: c.id,
			loanId: loan.id,
			loanNumber: loan.number,
			loanTakeOver: loan.takeOver, // obsolete.
			//purposeOfLoan: loan.purposeOfLoan, // not in use.
			loanAmount: loan.amount, // obsolete.
			downPaymentAmount: loan.downPaymentAmount,
			downPaymentOther: loan.downpaymentOther,
			aims: this.toLpoAims(loan.aims),
			takeoverloans: this.toLpoTakeoverLoans(loan.takeoverLoans), // TODO.
			downPaymentType: this.toLpoDownpaymentType(loan.downpaymentType),
			purposeDescription: loan.purposeDescription,
		}
		return [result];
	}
	static toLpoDownpaymentType(downpaymentType: DownpaymentSource | undefined): DownPaymentType | undefined {
		const d = downpaymentType;
		if( d===DownpaymentSource.BLANCO_LOAN )
			return DownPaymentType.Blanco;
		else if( d===DownpaymentSource.OTHER )
			return DownPaymentType.Vrigt;
		else if( d===DownpaymentSource.SALE_OF_PROPERTY )
			return DownPaymentType.FrsljningBostad;
		else if( d===DownpaymentSource.SAVINGS )
			return DownPaymentType.Sparande;
		else 
			return;
	}
	static toLpoAims(aims: Aim[] | undefined): AimType[] | undefined  {
		if( !aims )
			return;
		const result:AimType[] = aims.map(aim => {
			const purposeText = aim.aimCategory;
			const aimText = aim.aimDetail;

			const a:AimType = {
				aimId: aim.id, 
				purposeText: purposeText,
				aimText: aimText, 
				loanAmountPart: aim.loanAmountPart
			}
			return a;
		})
		return result;
	}
	
	static toLpoTakeoverLoansType(takeoverLoans: TakeoverLoan[] | undefined): TakeoverLoansType | undefined  {
		if( !takeoverLoans )
			return undefined;
		else {
			const tolst:TakeoverLoansType = {
				takeoverloans: this.toLpoTakeoverLoans(takeoverLoans)
			};
			return tolst;
		}
	}
	static toLpoTakeoverLoans(takeoverLoans: TakeoverLoan[] | undefined): TakeoverLoanType[] | undefined  {
		if( !takeoverLoans )
			return;
		const result:TakeoverLoanType[] = takeoverLoans.map(x => {
			const purposeText = x.aimCategory;
			const aimText = x.aimDetail;

			const a:TakeoverLoanType = {
				takeoverLoanId: x.id, 
				purposeText: purposeText,
				aimText: aimText, 
				debtAmount: x.debtAmount,
				creditInstitute: x.creditInstitute,
				loanNumber: x.loanNumber,
				//interest: x.interest,
				//period: x.period
			}
			return a;
		})
		return result;
	}
	static toLpoApplicants(c:Case, applicants: Applicant[] | undefined) : ApplicantType[]|undefined {
		if( !applicants )
			return;
		return applicants.map(a => {
			let name = (a.firstName||'') + ' ' + (a.lastName||'');
			name = name.trim();

			const at:ApplicantType = {
				applicantId: a.id,
				processId: c.id,
				customerId: a.customerId,
				applicantName: name,
				applicantAddress: a.streetAddress,
				applicantPostAddress: a.postalAddress,
				stakeholderType: a.stakeholderType,
				contactInformation: {
					applicanteMail: a.email,
					applicantMobileNumber: a.mobilePhone,
				},
				applicantBySms: a.notifyByMobilePhone,
				applicantByeMail: a.notifyByEmail,
				applicantCollateralToBuy: a.willBuyAgriForestryProperty
			}
			return at;
		})
	}
}